export default {
  form: {
    backToHome: 'Voltar ao Início',
    createPatient: 'Eu sou um paciente',
    createPractitioner: 'Eu sou um profissional',
    emailField: 'E-mail',
    forgotPassword: 'Esqueceu sua senha',
    newPasswordField: 'Nova Senha',
    noAccount: 'Sem conta? Registrar-se',
    oldPasswordField: 'Senha Antiga',
    passwordConfirmField: 'Confirmação',
    passwordField: 'Senha',
    passwordsNotGoodEnough:
      'A senha deve ter pelo menos 8 caracteres, incluindo 1 capital, 1 minúsculo, 1 figura, 1 caractere especial e não conter nenhum espaço',
    passwordsNotMatch: 'As senhas não são idênticas',
    referrerCodeField: 'Código de patrocínio',
    rememberMe: 'Lembrar-se de mim',
    resetCredentialsNotOK: 'Solicitação expirada ou já concluída',
    resetPasswordButton: 'Mudar senha',
    resetPasswordEmailSent:
      'Se o seu endereço de email for válido e presente no banco de dados, um email será enviado para si brevemente. Clique no link dentro do email para redefinir sua senha.',
    resetPasswordFailTitle: 'Ocorreu um problema, senha inalterada',
    resetPasswordRequestButton: 'E-mail',
    resetPasswordRequestTitle: 'Redefinir senha',
    resetPasswordSuccessTitle: 'Senha alterada com sucesso',
    signInButton: 'Conectar',
    signInTitle: 'Conectar',
    signUpButton: 'Registro',
    yesAccount: 'eu tenho uma conta',
  },
  menu: {
    logout: 'Desconexão',
    profile: 'O meu perfil',
    tutorial: 'Tutorial',
  },
  onBoarding: {
    explain: {
      address:
        'Por favor, insira o endereço do seu local de consulta, ele será usado nos emails de confirmação para o paciente.',
      password:
        'Se você ainda não o fez, altere a sua senha por motivos de segurança.',
      practices:
        'As suas práticas são úteis para nós para fins estatísticos. No momento, estas s não estão ligadas a reuniões.',
    },
    save: {
      error:
        'Ocorreu um problema durante a gravação, por favor tente novamente',
      errorGDPR:
        'Você deve verificar a caixa GDPR para poder salvar suas informações',
      success: 'Modificações registradas',
    },
    steps: {
      address: {
        city: 'Cidade',
        country: 'País',
        countrySearch: 'Procure por um país',
        search: 'Procure uma morada',
        street: 'Rua',
        title: 'Localização da consulta',
        zipCode: 'Código postal',
      },
      changePassword: {
        title: 'Altere a sua senha',
      },
      personalData: {
        title: 'Informações pessoais',
      },
      practices: {
        title: 'Prática',
      },
    },
    subTitleInfo:
      'Esta informação é muito importante para nós e para o funcionamento adequado do aplicativo. Por favor, reserve 5 minutos parficar informado.',
    title: 'Conta-nos mais sobre ti!',
  },
  profile: {
    updateError: 'Ocorreu um erro, por favor tente novamente',
    updateSuccess: 'Modificações registradas',
  },
  register: {
    button: 'Criar o meu espaço',
    buttonResend: 'Reenviar o código',
    certifyLegalRepresentative:
      'Eu certifico estar acompanhado pelo meu representante legal *',
    disablePro:
      'O registro só é possível como paciente ou desde https://kiway.co para profissionais que desejam fazer parte do programa de co-criação',
    error: {
      birthDate: {
        ofAgePatient:
          'Você deve ter idade ou estar acompanhado pelo seu representante legal',
        ofAgePract: 'Você deve ter idade para usar o aplicativo',
      },
      email: 'Por favor insira um endereço de e-mail válido',
      emailExists: 'Já existe uma conta para este e -mail',
      emailVerif:
        'Um problema ocorreu durante o envio do código para verificar seu email. Por favor, tente novamente.',
      global: 'Ocorreu um erro, por favor tente novamente.',
      password: 'A senha não é válida',
      requiredFieldsEmpty:
        'Por favor, preencha os campos necessários relatados por um asterisco (*)',
      verifCode: 'Código incorreto',
    },
    stepper: {
      credentials: 'Identificante',
      emailVerification: 'Verificação do email',
      personalData: 'Dados pessoais',
    },
    success:
      'A sua conta foi criada. Você receberá um email no email para verificação. Você só precisará clicar no link para validar a sua conta e poderá conectar-se posteriormente.',
    title: 'Criação do seu espaço de {{app}}',
    validation: {
      error:
        'Ocorreu um erro durante a verificação, tente novamente. Você já deve ter verificado seu email.',
      missingParams:
        'O seu email não pôde ser verificado, mas faltam parâmetros para a sua solicitação. Verifique se você copiou o link recebido no email.',
      ok:
        'O seu email foi verificado. Agora você pode se conectar clicando no botão abaixo.',
      title: 'Verificando seu email',
    },
    verifCodeExplaination: 'Por favor, insira o código recebido por email',
  },
};
