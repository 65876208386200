import { CatalogProvider } from '../../core/use_cases/CatalogProvider';
import { gql, ApolloClient } from '@apollo/client';
import {
  CustomOptions,
  PaginationOptions,
  PaginatedResults,
} from '@kiway/shared/react-types';
import { Formula } from '../../core/entities/Formula';
import { Plant } from '../../core/entities/Plant';
import {
  formulaGqlAttributes,
  IFormulaAttributesData,
} from './FormulaGraphQLProvider';
import {
  IPlantAttributesData,
  plantGqlAttributes,
} from './PlantGraphQLProvider';
import { CatalogOptions } from '../../core/use_cases/GetCatalog';

export interface GetCatalogData {
  getCatalog: PaginatedResults<IPlantAttributesData | IFormulaAttributesData>;
}

export const GET_CATALOG = gql`
  query getCatalog($options: CatalogOptions, $page: Int, $perPage: Int) {
    getCatalog(options: $options, page: $page, perPage: $perPage) {
      items {
        ... on Plant {
          ${plantGqlAttributes}
          model
        }
        ... on Formula {
          ${formulaGqlAttributes}
          model
        }
      }
      currentPage
      totalItems
      totalPages
      prevPage
      nextPage
      hasPrevPage
      hasNextPage
    }
  }
`;

export class CatalogGraphQLProvider implements CatalogProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async getCatalog(
    options?: CatalogOptions,
    pagination?: PaginationOptions
  ): Promise<PaginatedResults<Plant | Formula>> {
    try {
      const result = await this.client?.query<
        GetCatalogData,
        { options?: CatalogOptions; page?: number; perPage?: number }
      >({
        query: GET_CATALOG,
        fetchPolicy: 'network-only',
        variables: {
          options,
          page: pagination?.page,
          perPage: pagination?.perPage,
        },
      });
      return {
        ...result.data.getCatalog,
        items: result.data.getCatalog.items.map((item) =>
          item.model === 'Formula' ? new Formula(item) : new Plant(item)
        ),
      };
    } catch (e) {
      console.log(e);
    }
  }
}
