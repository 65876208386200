import {
  TableRow,
  TableCell,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import * as React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Plant } from '../../../../core/entities/Plant';
import { useTranslation } from '@kiway/shared/utils/translation';
import { useStyles } from '../../shared/styles';

interface ItemRowProps {
  dosage: number;
  totalDosage: number;
  index?: number;
  plant: Plant;
  removePlant: (plantId: string) => void;
  setIsButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setDosage: (dosage: number) => void;
}

export const ItemRow = (props: ItemRowProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const pMin = props.plant.getPosologyMin();
  const pMax = props.plant.getPosologyMax();
  const percent = (props.dosage ?? 0) / (props.totalDosage ?? 1);

  return (
    <TableRow
      key={props.plant.getId()}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {props.plant.getPinYinName()}
      </TableCell>
      <TableCell align="center">
        <TextField
          type="number"
          size="small"
          margin="dense"
          autoFocus={props.index === 0}
          className={classes.noArrowInputNumber}
          error={!props.dosage || props.dosage < 1}
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: <InputAdornment position="end">g</InputAdornment>,
          }}
          value={
            props.dosage && !Number.isNaN(props.dosage) ? props.dosage : ''
          }
          onChange={(e) => props.setDosage(parseInt(e.target.value))}
        />
      </TableCell>
      <TableCell align="center">
        {`${
          percent ? parseFloat(`${percent * 100}`).toFixed(2) : ''
        } %`.replace(/\s/g, '\u00A0')}
      </TableCell>
      <TableCell align="center">
        {pMin && !pMax ? (
          <>
            {t('pharmaco:posologies.displayChip.min', { min: pMin }).replace(
              /\s/g,
              '\u00A0'
            )}
          </>
        ) : pMax && !pMin ? (
          <>
            {t('pharmaco:posologies.displayChip.max', { max: pMax }).replace(
              /\s/g,
              '\u00A0'
            )}
          </>
        ) : pMax && pMin ? (
          <>
            {t('pharmaco:posologies.displayChip.minMax', {
              min: pMin,
              max: pMax,
            }).replace(/\s/g, '\u00A0')}
          </>
        ) : null}
      </TableCell>
      <TableCell align="center">
        <IconButton onClick={() => props.removePlant(props.plant.getId())}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
