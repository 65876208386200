import { Carrier } from '../entities/Carrier';
import { CarrierProvider } from './CarrierProvider';

export class GetAllCarriers {
  private carrierProvider: CarrierProvider;

  public constructor(carrierProvider: CarrierProvider) {
    this.carrierProvider = carrierProvider;
  }

  public execute(): Promise<Carrier[]> {
    return this.carrierProvider.findAll();
  }
}
