import * as React from 'react';
import {
  ProductMetadata,
  ProductMetadataType,
} from '../../../core/entities/ProductMetadata';
import { useTranslation } from '@kiway/shared/utils/translation';
import { DatatablesController } from '@kiway/datatables';
import { deepen } from '@kiway/shared/utils/string';

const prepareToSave = (type: ProductMetadataType) => (item) => {
  return {
    ...deepen(item),
    id: item._id,
    _id: undefined,
    metadataType: type,
  };
};

export interface ListProductMetadatasDatatableProps {
  handleAddData?: () => void;
  handleSave?: any;
  productMetadatas: ProductMetadata[];
  loading?: boolean;
  refreshData?: () => void;
  type?: ProductMetadataType;
}

export function ListProductMetadatasDatatable({
  handleAddData,
  handleSave,
  loading,
  productMetadatas,
  refreshData,
  type,
}: React.PropsWithChildren<ListProductMetadatasDatatableProps>) {
  const { t } = useTranslation();
  return (
    <DatatablesController
      bulkEdit={true}
      columns={[
        {
          name: '_id',
          label: 'ID',
          type: 'text',
          options: {
            display: false,
            edit: false,
            filter: false,
          },
        },
        {
          name: 'name.fr',
          label: t(`pharmaco:productMetadatas.name`, { lngCode: 'FR' }),
          type: 'text',
        },
        {
          name: 'name.en',
          label: t(`pharmaco:productMetadatas.name`, { lngCode: 'EN' }),
          type: 'text',
        },
        {
          name: 'name.es',
          label: t(`pharmaco:productMetadatas.name`, { lngCode: 'ES' }),
          type: 'text',
        },
        {
          name: 'name.pt',
          label: t(`pharmaco:productMetadatas.name`, { lngCode: 'PT' }),
          type: 'text',
        },
      ]}
      data={productMetadatas?.map((item) => item.toDatatableRow())}
      defaultSortOrder={{ name: 'name.fr', direction: 'asc' }}
      handleAddData={handleAddData}
      handleSaveData={
        handleSave
          ? (updates) => {
              if (handleSave) {
                return handleSave(updates.updatedData.map(prepareToSave(type)));
              }
            }
          : undefined
      }
      id={`listProductMetadata-${type}`}
      loading={loading}
      options={{
        download: false,
        filter: false,
        print: false,
      }}
      refreshData={refreshData}
      title={
        t(`pharmaco:productMetadatas.${type}.cardTitle`) || 'ProductMetadatas'
      }
    />
  );
}
