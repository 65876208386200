export default {
  asterisks: {
    onReferralFirstPurchase: 'Cuando tu ahijado compra por primera vez ',
    onReferralPurchases: 'En todas las compras de tu ahijado',
    shareLinkExplanation:
      'Copie el enlace directamente haciendo clic en él. Tus ahijados deben registrarse através de este enlace para que el patrocinio funcione',
  },
  buttons: {
    invite: 'Invitar',
  },
  dataTable: {
    columns: {
      lastUpdate: 'última actualización',
      referral: 'Ahijado',
      rewardAmount: 'Montante oferecido',
      rewardedBy: 'Recompensado por',
      rewardType: 'Tipo de recompensa',
      status: 'Estado',
      subType: 'Tipo de subscrición',
    },
    enums: {
      rewardType: {
        balance: 'Créditos',
        cashback: 'Devolución de dinero',
        monthFree: '1 mes gratis',
      },
      status: {
        completed: 'Terminado',
        paid: 'Pagado',
        pending: 'En curso',
        sent: 'Enviado',
      },
    },
    yourReferrer: 'su padrino',
  },
  errors: {
    global:
      'Se produjo un error, gracias por intentarlo nuevamente o contactarnos',
    noFullName:
      'Su nombre y su nombre deben ingresarse para enviar invitaciones',
    noReferrerCode:
      'Su cuenta no tiene un código de patrocinio, contáctenos a través del chatbox o intente volver a conectar',
    noSub: 'Debe tener una suscripción activa para poder patrocinar',
  },
  inviteSuccess: '%X% de practicantes invitados',
  text: {
    affiliationInstructions: 'Para patrocinar, 2 soluciones:',
    caption: '¡Nos recomendas, te recompensamos!',
    costPerAcquisitionAmount: '%X%€ oferta',
    forYou: 'para usted',
    forYourReferral: 'Para tu ahijado',
    noSub: 'Para patrocinar, debes estar suscrito',
    oneMonthFree: '5 € oferta',
    revenueShareAmount: '%X%% oferta',
    sendMail: 'Enviar invitaciones directamente por correo electrónico',
    shareLink: 'Comparta su enlace de patrocinio',
  },
  titles: {
    affiliations: 'Patrocinio',
    myReferrals: 'Mis ahijados',
    myRewards: 'Mis premios',
    referrerCode: 'Mi código de patrocinio',
  },
};
