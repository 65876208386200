import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Modal } from '@kiway/shared/ui';

import { basicGetRequest, getBaseURL, runBasicFetch } from './utilsApi';

const BASE_URL = getBaseURL();

export const getRelease = (releaseId: string) => {
  const url = `${BASE_URL}/release/${releaseId}`;
  return runBasicFetch(basicGetRequest(url), true);
};

function ReleaseModal(props: any) {
  const [release, setRelease] = useState<any>({});
  const { handleClose, open, releaseId } = props;

  useEffect(() => {
    let isPending = true;
    try {
      if (isPending) {
        getRelease(releaseId).then((releaseContent: any) => {
          if (releaseContent) {
            setRelease(releaseContent);
          }
        });
      }
    } catch (error) {
      // DO NOTHING
    }
    return () => {
      isPending = false;
    };
  }, []);

  return (
    <Modal title={`${release.title}`} open={open} handleClose={handleClose}>
      <ReactMarkdown
        children={`## \\[${release.version}\\] - ${release.date}`}
      />
      <ReactMarkdown children={release.notes} />
    </Modal>
  );
}

export default ReleaseModal;
