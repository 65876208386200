export default {
  form: {
    backToHome: 'Back to Home',
    createPatient: 'I am a patient',
    createPractitioner: 'I am a practitioner',
    emailField: 'Email address',
    forgotPassword: 'Forgot password',
    newPasswordField: 'New password',
    noAccount: "Don't have an account? Sign Up",
    oldPasswordField: 'Old password',
    passwordConfirmField: 'Confirm Password',
    passwordField: 'Password',
    passwordsNotGoodEnough:
      'Password have to be 8 chars long at least, must contain 1 uppercase, 1 lowercase, 1 digit, 1 special char and must not contain any spaces',
    passwordsNotMatch: "Passwords don't match",
    referrerCodeField: 'Referrer code',
    rememberMe: 'Remember me',
    resetCredentialsNotOK: 'Request expired or already processed',
    resetPasswordButton: 'Change password',
    resetPasswordEmailSent:
      'If your email address is valid and found in our database, an email will be sent soon. Click on the link inside to reset your password',
    resetPasswordFailTitle: 'Something happened, password unchanged',
    resetPasswordRequestButton: 'Send reset link',
    resetPasswordRequestTitle: 'Reset password',
    resetPasswordSuccessTitle: 'Password successfully updated',
    signInButton: 'Sign in',
    signInTitle: 'Sign in',
    signUpButton: 'Sign up',
    yesAccount: 'I have an account',
  },
  menu: {
    logout: 'Sign out',
    profile: 'My profile',
    tutorial: 'Tutorial',
  },
  onBoarding: {
    explain: {
      address:
        'Please enter the address of your place of consultation, it will be used in confirmation emails intended for the patient.',
      password:
        'If you have not already done so, please change your password for security reasons.',
      practices:
        'Your practices are useful to us for statistical purposes. For the moment they are not linked to appointments.',
    },
    save: {
      error: 'Something went wrong, please try again',
      errorGDPR:
        'Please tick GDPR box for us to be able to register your information',
      success: 'Saved',
    },
    steps: {
      address: {
        city: 'City',
        country: 'Country',
        countrySearch: 'Search for a country',
        search: 'Search address',
        street: 'Street',
        title: 'Place of consultation',
        zipCode: 'Zip Code',
      },
      changePassword: {
        title: 'Change your password',
      },
      personalData: {
        title: 'Personal data',
      },
      practices: {
        title: 'Practices',
      },
    },
    subTitleInfo:
      'These information are very important for us and for the proper functioning of the application. Please take 5 minutes to complete them.',
    title: 'Tell us more about you!',
  },
  profile: {
    updateError: 'Something went wrong, please try again',
    updateSuccess: 'Saved',
  },
  register: {
    button: 'Create my space',
    buttonResend: 'Resend code',
    certifyLegalRepresentative:
      'I certified being represented by my legal guardian *',
    disablePro:
      'Subscription is only available for patients. If you want to enroll to our bêta program as a practitioner please sign up on https://kiway.co',
    error: {
      birthDate: {
        ofAgePatient:
          'You must be over the age of majority or be represented by a legal guardian',
        ofAgePract:
          'You must be over the age of majority to be allowed to use the application',
      },
      email: 'Please enter a valid email address',
      emailExists: 'An account already exists for this email',
      emailVerif:
        'A problem occurred while sending the code to verify your email. Please try again.',
      global: 'Something went wrong, please try again',
      password: 'Please enter a valid password',
      requiredFieldsEmpty:
        'Please fill in the required fields marked with an asterisk (*)',
      verifCode: 'Incorrect code',
    },
    stepper: {
      credentials: 'Credentials',
      emailVerification: 'Email verification',
      personalData: 'Personal data',
    },
    success:
      'Your account has been successfully created. You will receive an email soon to verify you have access to the email address you suscribed with.Just click on the provided link to validate your email and will be able to login to your account afterwards.',
    title: 'Creation of your {{app}} space',
    validation: {
      error:
        'Something went wrong during email verification, please try again. You might have already completed verification process.',
      missingParams:
        "We couldn't verified your email, there are missing parameters to your request. Please check that you have properly copied the received link.",
      ok:
        'Your email has been successfully verified. You can now login to your account by clicking on the button below.',
      title: 'Email verification',
    },
    verifCodeExplaination: 'Please enter the code received by email',
  },
};
