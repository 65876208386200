import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Payment } from '../../../core/entities/Payment';
import { useTranslation } from '@kiway/shared/utils/translation';

interface CheckInformationProps {
  selectedPayment: Payment;
}

export const CheckInformation = (props: CheckInformationProps) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Typography>
          <b>{t('pharmaco:orderDetails.payment.forms.lastName')} : </b>
          {props.selectedPayment.getCustom()?.check?.lastName}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography>
          <b>{t('pharmaco:orderDetails.payment.forms.firstName')} : </b>
          {props.selectedPayment.getCustom()?.check?.firstName}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography>
          <b>{t('pharmaco:orderDetails.payment.forms.address')} : </b>
          {props.selectedPayment.getCustom()?.check?.address}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography>
          <b>{t('pharmaco:orderDetails.payment.forms.checkNumber')} : </b>
          {props.selectedPayment.getCustom()?.check?.checkNumber}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography>
          <b>{t('pharmaco:orderDetails.payment.forms.bank')} : </b>
          {props.selectedPayment.getCustom()?.check?.bank}
        </Typography>
      </Grid>
    </Grid>
  );
};
