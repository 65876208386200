export interface ILoyaltyTransactionAttributes {
  nbPoints: number;
  comment: string;
  user: string;
}

export class LoyaltyTransaction {
  protected id: string;
  protected nbPoints: number;
  protected comment: string;
  protected user: string;

  public constructor(
    obj: Partial<ILoyaltyTransactionAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    this.nbPoints = obj?.nbPoints;
    this.comment = obj?.comment;
    this.user = obj?.user;
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): LoyaltyTransaction {
    this.id = id;
    return this;
  }

  public getNbPoints(): number {
    return this.nbPoints;
  }

  public getComment(): string {
    return this.comment;
  }

  public getUser(): string {
    return this.user;
  }
}
