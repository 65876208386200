import { mobileStepperClasses } from '@mui/material';
import { TFunction } from 'i18next';

interface IParams {
  setIsNextButtonEnabled: (isNextButtonEnabled: boolean) => void;
  t: TFunction;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  ReactEventEmitter?: any;
  history?: any;
}

export const tourSections = ({
  setIsNextButtonEnabled,
  t,
  setCurrentStep,
  ReactEventEmitter,
  history,
}: IParams) => [
  // {
  //   name: 'general',
  //   description: 'general',
  //   subSections: [
  //     {
  //       subSectionTitle: 'general',
  //       subSectionDescription: 'generalDescription',
  //       subSectionLink: 'dashboard',
  //       subSectionSteps: [
  //         {
  //           selector: '[data-tour="general-help"]',
  //           content: 'Commencer le Tour Guide !'
  //         },
  //         {
  //           selector: '[data-tour="general-user"]',
  //           content: 'Retrouvez ici vos informations utilsateur',
  //           action: () => setIsNextButtonEnabled(false)
  //         },
  //         {
  //           selector: '[data-tour="menu-user-editUserProfile"]',
  //           content: 'Votre profil',
  //           action: () => setIsNextButtonEnabled(true)
  //         },
  //         {
  //           selector: '[data-tour="menu-user-affiliation"]',
  //           content: 'Les informations de parrainage'
  //         },
  //         {
  //           selector: '[data-tour="menu-user-logoutUser"]',
  //           content: 'Et pour vous déconnecter'
  //         },
  //         {
  //           selector: '[data-tour="menu-user-logoutUser"]',
  //           content: 'Et pour vous déconnecter',
  //           action: () => { history.push({
  //             pathname: '/agenda',
  //           });
  //           ReactEventEmitter.dispatch('userMenuClose');
  //         }
  //         },
  //         {
  //           selector: '[data-tour="agenda-general"]',
  //           content: 'Bienvenue sur la page Agenda !',
  //         },
  //         {
  //           selector: '[data-tour="menu-main-files"]',
  //           content: 'Vers la page Dossier patient',
  //         },
  //         {
  //           selector: '[data-tour="menu-main-files"]',
  //           content: 'Vers la page Dossier patient',
  //           action: () => { history.push({
  //             pathname: '/files',
  //           });
  //         },
  //       },
  //       ],
  //     },
  //   ],
  // },
  {
    name: 'dashboard',
    description: 'dashboard',
    subSections: [
      {
        subSectionTitle: 'dashboardGeneral',
        subSectionDescription: 'dashboardGeneralDescription',
        subSectionLink: 'dashboard',
        subSectionSteps: [
          // {
          //   selector: '[data-tour="general-help"]',
          //   content: 'Commencer le Tour Guide !',
          // },
          {
            selector: '[data-tour="tour-first-step"]',
            content: t('common:tourGuide.content.dashboard.rdv'),
          },
          {
            selector: '[data-tour="tour-second-step"]',
            content: t('common:tourGuide.content.dashboard.plannedSessions'),
          },
          {
            selector: '[data-tour="tour-third-step"]',
            content: t('common:tourGuide.content.dashboard.doneSessions'),
          },
          {
            selector: '[data-tour="tour-fourth-step"]',
            content: t('common:tourGuide.content.dashboard.newPatients'),
          },
          {
            selector: '[data-tour="tour-fifth-step"]',
            content: t('common:tourGuide.content.dashboard.monthPrevision'),
          },
          {
            selector: '[data-tour="tour-sixth-step"]',
            content: t('common:tourGuide.content.dashboard.credit'),
          },
          {
            selector: '[data-tour="tour-seventh-step"]',
            content: t('common:tourGuide.content.dashboard.creditLeft'),
          },
        ],
      },
    ],
  },
  {
    name: 'agenda',
    description: 'agenda',
    subSections: [
      {
        subSectionTitle: 'agendaGeneral',
        subSectionDescription: 'agendaGeneralDescription',
        subSectionLink: 'agenda',
        subSectionSteps: [
          {
            selector: '[data-tour="agenda-general"]',
            content: t('common:tourGuide.content.agenda.welcome'),
          },
        ],
      },
      // {
      //   subSectionTitle: 'addEventAgenda',
      //   subSectionDescription: 'addEventAgendaDescription',
      //   subSectionLink: 'agenda',
      //   subSectionSteps: [
      //     {
      //       selector: '[data-tour="agenda-general"]',
      //       content: "Essayons d'ajouter un évènement à votre calendrier",
      //     },
      //     {
      //       selector: "td.fc-timegrid-slot.fc-timegrid-slot-lane",
      //       content: 'Cliquez sur cette ligne',
      //       action: () => {
      //         setIsNextButtonEnabled(false);
      //         const steps = document.querySelectorAll('td.fc-timegrid-slot.fc-timegrid-slot-lane');
      //         steps.forEach((step) => {
      //           step.addEventListener('click', () => {
      //             setTimeout(
      //               () => setCurrentStep((prev: any) => prev + 1),
      //               450
      //             );
      //           });
      //         });
      //       },
      //     },
      //     {
      //       selector: '[data-tour="modal-add-event"]',
      //       content: "Une page d'ajouter d'évènement s'ouvre",
      //     },
      //   ],
      // },
      {
        subSectionTitle: 'agendaSettings',
        subSectionDescription: 'agendaSettingsDescription',
        subSectionLink: 'agenda',
        subSectionSteps: [
          {
            selector: '.fc-settings-button',
            content: t('common:tourGuide.content.agenda.customAgenda'),
            action: () => {
              setIsNextButtonEnabled(false);
              const steps = document.querySelectorAll('.fc-settings-button');
              steps.forEach((step) => {
                step.addEventListener('click', () => {
                  setTimeout(
                    () => setCurrentStep((prev: any) => prev + 1),
                    450
                  );
                });
              });
            },
          },
          {
            selector: '[data-tour="display-weekends"]',
            content: t('common:tourGuide.content.agenda.displayWeekends'),
            action: () => setIsNextButtonEnabled(true),
          },
          {
            selector: '[name="slotMinTime"]',
            content: t('common:tourGuide.content.agenda.dayStart'),
          },
          {
            selector: '[name="slotMaxTime"]',
            content: t('common:tourGuide.content.agenda.dayEnd'),
          },
          {
            selector: '[data-tour="intervals"]',
            content: t('common:tourGuide.content.agenda.intervals'),
          },
          {
            selector: '[data-tour="apply"]',
            content: t('common:tourGuide.content.agenda.save'),
          },
          {
            selector: '[data-tour="cancel"]',
            content: t('common:tourGuide.content.agenda.cancel'),
            action: () => {
              setIsNextButtonEnabled(false);
              const steps = document.querySelectorAll("[data-tour='cancel']");
              steps.forEach((step) => {
                step.addEventListener('click', () => {
                  setTimeout(
                    () => setCurrentStep((prev: any) => prev + 1),
                    450
                  );
                });
              });
            },
          },
        ],
      },
    ],
  },
  {
    name: 'files',
    description: 'files',
    subSections: [
      {
        subSectionTitle: 'filesGeneral',
        subSectionDescription: 'filesGeneralDescription',
        subSectionLink: 'files',
        subSectionSteps: [
          {
            selector: '[data-tour="files-patient-step"]',
            content: t('common:tourGuide.content.files.patientsList'),
          },
          {
            selector: '[data-tour="files-add-step"]',
            content: t('common:tourGuide.content.files.addPatient'),
          },
        ],
      },
      {
        subSectionTitle: 'patientInfo',
        subSectionDescription: 'patientInfoDescription',
        subSectionLink: 'files',
        subSectionSteps: [
          {
            selector: `[data-tour="files-datatable-0"]`,
            content: t('common:tourGuide.content.files.seePatientFile'),
            action: () => {
              setIsNextButtonEnabled(false);
              const steps = document.querySelectorAll(
                '[data-tour="files-datatable-0"]'
              );
              steps.forEach((step) => {
                step.addEventListener('click', () => {
                  setTimeout(
                    () => setCurrentStep((prev: any) => prev + 1),
                    450
                  );
                });
              });
            },
          },
          {
            selector: '[data-tour="files-infos-signal"]',
            content: t('common:tourGuide.content.files.patientInfo'),
            action: () => setIsNextButtonEnabled(true),
          },
          {
            selector: '[data-tour="files-infos-orders"]',
            content: t('common:tourGuide.content.files.patientOrders'),
          },
          {
            selector: '[data-tour="files-infos-addresses"]',
            content: t('common:tourGuide.content.files.patientAddresses'),
          },
          {
            selector: '[data-tour="files-infos-background"]',
            content: t('common:tourGuide.content.files.patientBackground'),
          },
          {
            selector: '[data-tour="files-infos-sessions"]',
            content: t('common:tourGuide.content.files.patientSessions'),
          },
          {
            selector: '[data-tour="link"]',
            content: t('common:tourGuide.content.files.link'),
          },
          {
            selector: '[data-tour="edit"]',
            content: t('common:tourGuide.content.files.edit'),
          },
        ],
      },
      {
        subSectionTitle: 'addPatient',
        subSectionDescription: 'addPatientDescription',
        subSectionLink: 'files',
        subSectionSteps: [
          {
            selector: '[data-tour="files-add-step"]',
            content: t('common:tourGuide.content.files.addPatient'),
            action: () => {
              setIsNextButtonEnabled(false);
              const steps = document.querySelectorAll(
                '[data-tour="files-add-step"]'
              );
              steps.forEach((step) => {
                step.addEventListener('click', () => {
                  setTimeout(
                    () => setCurrentStep((prev: any) => prev + 1),
                    450
                  );
                });
              });
            },
          },
          {
            selector: '[data-tour="files-addFirstName-step"]',
            content: t('common:tourGuide.content.files.addFirstName'),
            action: () => setIsNextButtonEnabled(true),
          },
          {
            selector: '[data-tour="files-addLastName-step"]',
            content: t('common:tourGuide.content.files.addLastName'),
          },
          {
            selector: '[data-tour="files-addButton-step"]',
            content: t('common:tourGuide.content.files.savePatient'),
          },
        ],
      },
      {
        subSectionTitle: 'removePatient',
        subSectionDescription: 'removePatientDescription',
        subSectionLink: 'files',
        subSectionSteps: [
          {
            selector: `[data-tour="files-datatable-0"]`,
            content: t('common:tourGuide.content.files.seePatientFile'),
            action: () => {
              setIsNextButtonEnabled(false);
              const steps = document.querySelectorAll(
                '[data-tour="files-datatable-0"]'
              );
              steps.forEach((step) => {
                step.addEventListener('click', () => {
                  setTimeout(
                    () => setCurrentStep((prev: any) => prev + 1),
                    450
                  );
                });
              });
            },
          },
          {
            selector: `[data-tour="remove"]`,
            content: t('common:tourGuide.content.files.removePatient'),
            action: () => {
              setIsNextButtonEnabled(false);
              const steps = document.querySelectorAll('[data-tour="remove"]');
              steps.forEach((step) => {
                step.addEventListener('click', () => {
                  setTimeout(
                    () => setCurrentStep((prev: any) => prev + 1),
                    450
                  );
                });
              });
            },
          },
          {
            selector: `[data-tour="confirm-delete"]`,
            content: t('common:tourGuide.content.files.confirmRemove'),
          },
        ],
      },
    ],
  },
  {
    name: 'settings',
    description: 'settings',

    subSections: [
      {
        subSectionTitle: 'settingsGeneral',
        subSectionDescription: 'settingsGeneralDescription',
        subSectionLink: 'settings',
        subSectionSteps: [
          {
            selector: '[data-tour="settings-general"]',
            content: t('common:tourGuide.content.settings.welcome'),
          },
        ],
      },
      {
        subSectionTitle: 'settingsWorkplaces',
        subSectionDescription: 'settingsWorkplacesDescription',
        subSectionLink: 'settings/workplaces',
        subSectionSteps: [
          {
            selector: '[data-tour="settings-workplaces-management"]',
            content: t('common:tourGuide.content.settings.workplaces'),
          },
          {
            selector: '[data-tour="settings-workplaces-list"]',
            content: t('common:tourGuide.content.settings.workplacesList'),
          },
        ],
      },
      {
        subSectionTitle: 'settingsActivity',
        subSectionDescription: 'settingsActivityDescription',
        subSectionLink: 'settings/activity',
        subSectionSteps: [
          {
            selector: '[data-tour="settings-activity-management"]',
            content: t('common:tourGuide.content.settings.activities'),
          },
          {
            selector: '[data-tour="settings-activity-consultingManagement"]',
            content: t(
              'common:tourGuide.content.settings.consultingManagement'
            ),
          },
          {
            selector: '[data-tour="settings-activity-consultingList"]',
            content: t('common:tourGuide.content.settings.consultingList'),
          },
        ],
      },
      {
        subSectionTitle: 'settingsPublicPage',
        subSectionDescription: 'settingsPublicPageDescription',
        subSectionLink: 'settings/public-page',
        subSectionSteps: [
          {
            selector: '[data-tour="settings-public-settings"]',
            content: t('common:tourGuide.content.settings.publicPageSettings'),
          },
          {
            selector: '[data-tour="settings-public-url"]',
            content: t('common:tourGuide.content.settings.publicPageAddress'),
          },
          {
            selector: '[data-tour="settings-public-photos"]',
            content: t('common:tourGuide.content.settings.publicPagePhotos'),
          },
          {
            selector: '[data-tour="settings-public-content"]',
            content: t('common:tourGuide.content.settings.publicPageContent'),
          },
        ],
      },
      {
        subSectionTitle: 'settingsAgenda',
        subSectionDescription: 'settingsAgendaDescription',
        subSectionLink: 'settings/agenda',
        subSectionSteps: [
          {
            selector: '[data-tour="settings-agenda-typicalWeek-list"]',
            content: t('common:tourGuide.content.settings.typicalWeek'),
          },
          // {
          //   selector: '[data-tour="settings-agenda-typicalWeek-see"]',
          //   content: 'Voir votre semaine type',
          //   action: () => {
          //     setIsNextButtonEnabled(false);
          //     const steps = document.querySelectorAll(
          //       '[data-tour="files-datatable-0"]'
          //     );
          //     steps.forEach((step) => {
          //       step.addEventListener('click', () => {
          //         setTimeout(() => setCurrentStep((prev:any) => prev+1), 450)
          //       });
          //     });
          //   },
          // },
          // {
          //   selector: '[data-tour="settings-agenda-typicalWeekModal"]',
          //   content: 'Vous avez ici un résumé de votre semaine type', // TODO: à implémenter dans le code
          // },
          // {
          //   selector: '[data-tour="settings-agenda-typicalWeekModal-close"]', // TODO: à implémenter dans le code
          //   content:
          //     'Fermons maintenant cette fenêtre et continuons notre tour',
          // },
          {
            selector: '[data-tour="settings-agenda-addModel"]',
            content: t('common:tourGuide.content.settings.addWeekModel'),

            action: () => {
              setIsNextButtonEnabled(false);
              const steps = document.querySelectorAll(
                '[data-tour="settings-agenda-addModel"]'
              );
              steps.forEach((step) => {
                step.addEventListener('click', () => {
                  setTimeout(
                    () => setCurrentStep((prev: any) => prev + 1),
                    450
                  );
                });
              });
            },
          },
          {
            selector: '[data-tour="settings-agenda-addModel-weekName"]',
            content: t('common:tourGuide.content.settings.weekName'),

            action: () => setIsNextButtonEnabled(true),
          },
          {
            selector: '[data-tour="settings-agenda-addModel-toggle"]',
            content: t('common:tourGuide.content.settings.toggle'),
          },
          {
            selector: '[data-tour="settings-agenda-addModel-dateStart"]',
            content: t('common:tourGuide.content.settings.dateStart'),
          },
          {
            selector: '[data-tour="settings-agenda-addModel-dateEnd"]',
            content: t('common:tourGuide.content.settings.dateEnd'),
          },
          {
            selector: '[data-tour="settings-agenda-addModel-recurrence"]',
            content: t('common:tourGuide.content.settings.recurrence'),
          },
          // {
          //   selector: '[data-tour="settings-agenda-addModel-result"]', // TODO: à implémenter dans le code
          //   content:
          //     'Vous pouvez maintenant voir le résultat de votre création',
          // },
          // {
          //   selector: '[data-tour="settings-agenda-addModel-save"]', // TODO: à déclarer dans un composant plus bas niveau
          //   content: "Il ne reste plus qu'à enregistrer votre semaine type",
          // },
        ],
      },
      {
        subSectionTitle: 'settingsInvoicing',
        subSectionDescription: 'settingsInvoicingDescription',
        subSectionLink: 'settings/invoicing',
        subSectionSteps: [
          {
            selector: '[data-tour="settings-invoicing-headAddress"]',
            content: t('common:tourGuide.content.settings.headAddress'),
          },
          {
            selector: '[data-tour="settings-invoicing-invoicingAdress"]',
            content: t('common:tourGuide.content.settings.invoicingAdress'),
          },
          {
            selector: '[data-tour="settings-invoicing-legal"]',
            content: t('common:tourGuide.content.settings.legal'),
          },
          {
            selector: '[data-tour="settings-invoicing-invoiceNumber"]',
            content: t('common:tourGuide.content.settings.invoicingNumber'),
          },
          {
            selector: '[data-tour="settings-invoicing-save"]',
            content: t('common:tourGuide.content.settings.save'),
          },
        ],
      },
    ],
  },
  {
    name: 'invoicing',
    description: 'invoicing',
    subSections: [
      {
        subSectionTitle: 'invoicingGeneral',
        subSectionDescription: 'invoicingGeneralDescription',
        subSectionLink: 'invoicing',
        subSectionSteps: [
          {
            selector: '[data-tour="invoicing-general"]',
            content: t('common:tourGuide.content.invoicing.welcome'),
          },
        ],
      },
    ],
  },
  {
    name: 'services',
    description: 'services',
    subSections: [
      {
        subSectionTitle: 'servicesGeneral',
        subSectionDescription: 'servicesGeneralDescription',
        subSectionLink: 'services',
        subSectionSteps: [
          {
            selector: '[data-tour="services-general"]',
            content: t('common:tourGuide.content.services.welcome'),
          },
          {
            selector: '[data-tour="services-list"]',
            content: t('common:tourGuide.content.services.list'),
          },
          {
            selector: '[data-tour="services-payment"]',
            content: t('common:tourGuide.content.services.payment'),
          },
          {
            selector: '[data-tour="services-invoicing"]',
            content: t('common:tourGuide.content.services.invoicing'),
          },
        ],
      },
    ],
  },
  // {
  //   name: 'pharmaco',
  //   description: 'pharmaco',
  //   subSections: [
  //     {
  //       subSectionTitle: 'pharmacoGeneral',
  //       subSectionDescription: 'pharmacoGeneralDescription',
  //       subSectionLink: 'pharmaco/orders',
  //       subSectionSteps: [
  //         {
  //           selector: '[data-tour="ecommerce-general"]',
  //           content: 'Bienvenue sur la page Pharmaco !',
  //         },
  //       ],
  //     },
  //   ],
  // },
];
