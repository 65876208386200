import * as React from 'react';
import { Typography, Button } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Order } from '@kiway/ecommerce-react-compatible';
import { ListOrdersPrescriber } from '../ListOrders/ListOrdersPrescriber';
import { makeStyles } from '@material-ui/core/styles';
import { isGranted } from '@kiway/shared/utils/access-control-react';
import { AuthContext } from '@kiway/shared/features/authentication-react';

const useStyles = makeStyles({
  root: {
    '& .MuiToolbar-root.MuiToolbar-regular': { display: 'none !important' },
  },
});

export interface ListLastOrdersProps {
  handleNewClick?: () => void;
  handleClickSeeMore?: () => void;
  handleRemoveOrder?: (id: string) => void;
  handleRowClick?: (rowIndex: number) => void;
  orders: Order[];
  loyaltyPointsBalance?: number;
}

export function ListLastOrders(props: ListLastOrdersProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    authState: { userInfo },
  } = React.useContext(AuthContext);
  return (
    <div className={classes.root}>
      <Typography variant="h4">{t('pharmaco:lastOrders.title')}</Typography>
      {isGranted('ROLE_PRACTITIONER_PHARMACO', userInfo.roles) ? (
        <Typography variant="subtitle1">
          {t('loyaltyProgram:pointsBalance')}:{' '}
          {Number(props.loyaltyPointsBalance / 100).toFixed(2) ?? 0}
        </Typography>
      ) : null}
      <br />
      <ListOrdersPrescriber
        orders={props.orders}
        arrayId={'listOrders-lastOnDashboard'}
        hideArrayTitle={true}
        hideArrayPagination={true}
        handleNewClick={props.handleNewClick}
        handleRowClick={props.handleRowClick}
        handleRemoveOrder={props.handleRemoveOrder}
      >
        <Button
          onClick={props.handleClickSeeMore}
          variant="contained"
          color="inherit"
          sx={{ mr: 1 }}
        >
          {t('common:form.buttons.loadMore')}
        </Button>
      </ListOrdersPrescriber>
    </div>
  );
}
