import { kiwayLanguages } from '@kiway/shared/react-types';

export const getTranslations = () => {
  let translations = {};
  kiwayLanguages.forEach((element) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const lng = require(`./${element}`).default;
      translations = {
        ...translations,
        [element]: lng,
      };
    } catch (e) {
      // DO NOTHING
    }
  });
  return translations;
};
