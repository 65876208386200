import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import { MenuBuilder } from '@kiway/menu-builder';
import { Menu, Avatar, MenuItem } from '@material-ui/core';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, Grid, Box, Chip } from '@mui/material';
import { isGranted } from '@kiway/shared/utils/access-control-react';
import { useLazyQuery, useSubscription } from '@apollo/client';
import NotificationCard from './notifications/NotificationCard';
import { GET_USER_NOTIFICATIONS, NOTIFICATION_UPDATES } from './notifications';
import HelpIcon from '@mui/icons-material/Help';
import { IconButton } from '@mui/material';
import { ReactEventEmitter } from '@kiway/react-event-emitter';
import { CrispChatbox } from '@kiway/shared/chatbox';
import { gaEvents } from '@kiway/shared/utils-react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router';
import { useTranslation } from '@kiway/shared/utils/translation';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';

const drawerWidth = 240;
const drawerClosedWidth = 56;
const topbarHeight = 64;

const useStyles = makeStyles((theme: any) =>
  createStyles({
    customBadge: {
      backgroundColor: theme.palette.minoritary.main,
      color: theme.palette.minoritary.contrastText,
    },
    root: {
      display: 'flex',
      height: `calc(100% - ${topbarHeight}px)`,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      '& .active': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
      },
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    contentDrawerOpen: {
      flexGrow: 1,
      padding: theme.spacing(3),
      // minHeight: '100%',
      // width: `calc(100% - ${drawerWidth}px)`,
    },
    contentDrawerClosed: {
      flexGrow: 1,
      padding: theme.spacing(3),
      // minHeight: '100%',
      // width: `calc(100% - ${drawerClosedWidth}px)`,
    },
    customScrollbar: {
      scrollbarColor: `${theme.palette.secondary.main} #e0e0e0`,
      scrollbarWidth: 'thin',
      '-webkit-overflow-scrolling': 'auto !important',
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.main}`,
        '&:hover': {
          background: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
        },
      },
      '&::-webkit-scrollbar-track': {
        background: '#e0e0e0',
        border: `1px solid #e0e0e0`,
      },
    },
  })
);

type MenuSectionProps = {
  main?: boolean;
  open?: boolean;
};
function MenuSection({
  children,
  main,
  open,
}: React.PropsWithChildren<MenuSectionProps>) {
  const classes = useStyles({ open });
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: main ? 1 : undefined,
        overflowY: 'auto',
      }}
      className={classes.customScrollbar}
    >
      {children}
    </div>
  );
}

type AppShellProps = {
  links?: Array<{ text: string; url: string }>;
  logo?: any;
  userId?: string;
  userFirstName?: string;
  userLastName?: string;
  userImageUrl?: string;
  userRoles?: string[];
  version?: string;
};

export function AppShell({
  children,
  links,
  logo,
  userId,
  userFirstName,
  userLastName,
  userImageUrl,
  userRoles,
  version,
}: React.PropsWithChildren<AppShellProps>) {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorHelp, setAnchorHelp] = React.useState<any>(null);
  const classes = useStyles({ open: drawerOpen });

  const history = useHistory();
  const { currentLanguage, t } = useTranslation();

  React.useEffect(() => {
    ReactEventEmitter.subscribe('userMenuClose', handleCloseUserMenu);
    return () => ReactEventEmitter.unsubscribe('userMenuClose');
  }, []);

  const [anchorNotif, setAnchorNotif] = React.useState<any>(null);
  const [unreadNotif, setUnreadNotif] = React.useState<number | null>(null);
  const [notifications, setNotifications] = React.useState<any[]>([]);

  /**
   * Update the number of unread notifications
   */
  React.useEffect(() => {
    if (notifications !== null) {
      setUnreadNotif(notifications.length);
    }
    return () => {
      setUnreadNotif(0);
    };
  }, [notifications]);

  const removeNotif = (notificationId: string) => () => {
    const notificationsTmp = notifications.filter(
      (item: any) => item.id !== notificationId
    );
    // console.log(notifications, notificationsTmp)
    setNotifications(notificationsTmp);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorNotif(null);
    setAnchorHelp(null);
  };

  const [getNotifs, { called }] = useLazyQuery(GET_USER_NOTIFICATIONS, {
    onCompleted: (data) => {
      if (data.getUserNotifications && data.getUserNotifications?.length) {
        setNotifications(data.getUserNotifications);
      }
    },
  });

  React.useEffect(() => {
    if (!called) {
      if (process.env.NX_NOTIF_ENABLED === 'false') {
        return;
      }
      if (userRoles && isGranted('ROLE_DISPLAY_NOTIF', userRoles)) {
        getNotifs();
      }
    }
  }, [called, getNotifs, userRoles]);

  useSubscription(NOTIFICATION_UPDATES, {
    skip: !(
      process.env.NX_NOTIF_ENABLED === 'true' &&
      userRoles &&
      isGranted('ROLE_DISPLAY_NOTIF', userRoles)
    ),
    variables: {
      filter: {
        userId,
      },
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const { data } = subscriptionData;
      if (data?.notificationUpdates?.notification?.id) {
        // TODO
        const action = data?.notificationUpdates?.action;
        const notification = data?.notificationUpdates?.notification;
        // console.log(action, notification)
        switch (action) {
          case 'ADD':
            setNotifications((prev) => [notification, ...prev]);
            break;
          case 'READ':
            setNotifications((prev) => [
              ...prev.filter(({ id }) => id !== notification.id),
            ]);
            break;
        }
      }
    },
  });

  const userMenuBuilder = MenuBuilder.getBuilder('user');
  userMenuBuilder.setType('dropdown');

  const handleClick = (elem: string) => (event: any) => {
    if (elem === 'user') {
      setAnchorEl(event.currentTarget);
      ReactEventEmitter.dispatch('userMenuOpen', event);
    }
    if (elem === 'notif') {
      setAnchorNotif(event.currentTarget);
    }
    if (elem === 'help') {
      setAnchorHelp(event.currentTarget);
    }
  };

  const handleOpenTourGuide = () => {
    history.push({
      pathname: `/tutorials`,
      state: {
        from: 'tourguide',
      },
    });
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  function handleEventGA() {
    ReactGA.event({
      category: gaEvents.FAQ_CAT,
      action: gaEvents.OPEN_FAQ_ACTION,
    });
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: drawerOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ flex: 1 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {process.env.NX_APPBAR_LOGO_URL &&
              process.env.NX_APPBAR_LOGO_URL !== '' ? (
                <img
                  style={{ height: '40px', width: 'auto', margin: '0 8px' }}
                  src={process.env.NX_APPBAR_LOGO_URL}
                  alt=""
                />
              ) : null}
              <Typography variant="h6" noWrap>
                {process.env.NX_APPBAR_NAME ?? ''}
              </Typography>
              {process.env.NX_GUTTER_DISPLAY &&
              process.env.NX_GUTTER_DISPLAY !== '' ? (
                <Chip
                  label={
                    JSON.parse(process.env.NX_GUTTER_DISPLAY)?.[
                      currentLanguage?.code ?? getFallbackLanguage()
                    ] ??
                    JSON.parse(process.env.NX_GUTTER_DISPLAY)?.[
                      getFallbackLanguage()
                    ]
                  }
                  variant="filled"
                  color="error"
                  sx={{ ml: 1 }}
                />
              ) : null}
            </Box>
          </div>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            wrap="nowrap"
            sx={{ mr: 1 }}
          >
            {userRoles && isGranted('ROLE_ORDER_PICKER', userRoles) && (
              <Grid item sx={{ mr: 1 }}>
                <Typography>{`${userFirstName} ${userLastName}`}</Typography>
              </Grid>
            )}
            <Grid item>
              {process.env.NX_TUTORIAL_ENABLED === 'true' ||
              (process.env['NX_FAQ_URL'] && process.env['NX_FAQ_URL'] !== '') ||
              (process.env.NX_CRISP_WEBSITE_ID &&
                process.env.NX_CRISP_WEBSITE_ID !== '') ||
              (process.env.NX_SERVICE_DESK_MAIL &&
                process.env.NX_SERVICE_DESK_MAIL !== '') ? (
                <IconButton
                  onClick={handleClick('help')}
                  data-tour="general-help"
                >
                  <HelpIcon sx={{ color: 'primary.contrastText' }} />
                </IconButton>
              ) : null}
              <Menu
                style={{ marginTop: '12px' }}
                id="help-menu"
                anchorEl={anchorHelp}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorHelp)}
                onClose={handleClose}
              >
                {process.env.NX_TUTORIAL_ENABLED === 'true' && (
                  <MenuItem
                    onClick={() => {
                      handleOpenTourGuide();
                      handleClose();
                    }}
                  >
                    {t('common:menuToolbar.tutorials')}
                  </MenuItem>
                )}
                {process.env['NX_FAQ_URL'] && process.env['NX_FAQ_URL'] !== '' && (
                  <MenuItem
                    onClick={() => {
                      window.open(
                        // eslint-disable-next-line max-len
                        `${process.env['NX_FAQ_URL']}`,
                        '_blank'
                      );
                      handleEventGA();
                      handleClose();
                    }}
                  >
                    {t('common:menuToolbar.faq')}
                  </MenuItem>
                )}
                {process.env.NX_CRISP_WEBSITE_ID &&
                  process.env.NX_CRISP_WEBSITE_ID !== '' && (
                    <MenuItem
                      onClick={() => {
                        const crisp = CrispChatbox.getInstance();
                        crisp.open();
                        crisp.show();
                        handleClose();
                      }}
                    >
                      {t('common:menuToolbar.chatbox')}
                    </MenuItem>
                  )}
                {process.env.NX_SERVICE_DESK_MAIL &&
                  process.env.NX_SERVICE_DESK_MAIL !== '' && (
                    <MenuItem
                      onClick={() => {
                        window.location.href = `mailto:${process.env.NX_SERVICE_DESK_MAIL}`;
                        handleClose();
                      }}
                      // href={`mailto:${process.env.NX_SERVICE_DESK_MAIL}`}
                    >
                      {t('common:menuToolbar.serviceDesk')}
                    </MenuItem>
                  )}
              </Menu>
            </Grid>
            {process.env.NX_NOTIF_ENABLED === 'true' &&
              userRoles &&
              isGranted('ROLE_DISPLAY_NOTIF', userRoles) && (
                <Grid item>
                  <IconButton
                    style={{ color: 'white' }}
                    onClick={handleClick('notif')}
                  >
                    <Badge
                      classes={{ badge: classes.customBadge }}
                      badgeContent={unreadNotif}
                      invisible={(unreadNotif ?? 0) <= 0}
                    >
                      <NotificationsIcon style={{ color: 'white' }} />
                    </Badge>
                  </IconButton>
                  <Menu
                    style={{ marginTop: '12px' }}
                    MenuListProps={{ disablePadding: true }}
                    id="notif-menu"
                    anchorEl={anchorNotif}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorNotif)}
                    onClose={handleClose}
                  >
                    {notifications && notifications.length > 0 ? (
                      notifications.map((notification) => (
                        <NotificationCard
                          key={notification.id}
                          notification={notification}
                          handleClick={removeNotif(notification.id)}
                        />
                      ))
                    ) : (
                      <NotificationCard
                        notification={{ type: 'empty', metadata: {} }}
                        handleClick={() => handleClose()}
                      />
                    )}
                  </Menu>
                </Grid>
              )}
          </Grid>
          {userMenuBuilder.getItemsLength() ? (
            <Grid item data-tour="general-user">
              <Avatar
                style={{
                  color: theme.palette.secondary.main,
                  backgroundColor: 'white',
                }}
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={handleClick('user')}
                src={userImageUrl}
              >
                {!userImageUrl && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingTop: '4px',
                      boxSizing: 'border-box',
                      flex: 1,
                    }}
                  >
                    {`${userFirstName?.[0]?.toUpperCase() ?? ''}${
                      userLastName?.[0]?.toUpperCase() ?? ''
                    }`}
                  </div>
                )}
              </Avatar>
              <Menu
                style={{ marginTop: '12px' }}
                id="user-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={handleCloseUserMenu}
              >
                {userMenuBuilder.render(Boolean(anchorEl), handleCloseUserMenu)}
              </Menu>
            </Grid>
          ) : null}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <MenuSection main open={drawerOpen}>
          {MenuBuilder.renderStatic('main', drawerOpen)}
        </MenuSection>
        <Divider />
        {drawerOpen && (version || logo || links) ? (
          <List
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            {logo && (
              <ListItem
                style={{
                  textAlign: 'center',
                  width: '100%',
                  display: 'flex',
                  visibility: drawerOpen ? 'visible' : 'hidden',
                  flexDirection: 'column',
                }}
              >
                {logo}
              </ListItem>
            )}
            {version && (
              <ListItem
                style={{
                  textAlign: 'center',
                  width: '100%',
                  display: 'flex',
                  visibility: drawerOpen ? 'visible' : 'hidden',
                  flexDirection: 'column',
                }}
              >
                {version}
              </ListItem>
            )}
            {links && (
              <ListItem
                style={{
                  textAlign: 'center',
                  width: '100%',
                  display: 'flex',
                  visibility: drawerOpen ? 'visible' : 'hidden',
                  flexDirection: 'column',
                }}
              >
                {links.map((link) => (
                  <a
                    className="kiwayLink"
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link.text}
                  </a>
                ))}
              </ListItem>
            )}
          </List>
        ) : null}
      </Drawer>
      <main
        className={
          drawerOpen ? classes.contentDrawerOpen : classes.contentDrawerClosed
        }
      >
        <div className={classes.toolbar} />
        {children}
        <div style={{ height: theme.spacing(3) }}>&nbsp;</div>
      </main>
    </div>
  );
}
