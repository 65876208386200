import fr from './translations/fr';
import en from './translations/en';
import pt from './translations/pt';
import es from './translations/es';

export default {
  translations: {
    fr,
    en,
    pt,
    es,
  },
};
