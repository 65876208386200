import { AuthContext, useUsersGateway } from '../../../react';
import * as React from 'react';
import { useTranslation } from '@kiway/shared/utils/translation';
import * as ReactGA from 'react-ga';
import { gaEvents } from '@kiway/shared/utils-react';
import { Login } from './Login';
import { LoginResult } from '../../../core/use_cases/UserProvider';
import { useQuery } from '@kiway/routing-builder';
import { useLocation } from 'react-router-dom';
import ColoredBackgroundShell from './ColoredBackgroundShell';

const initState = {
  email: '',
  errors: {
    email: '',
    password: '',
    global: '',
  },
  loading: false,
  loginComplete: false,
  password: '',
  rememberMe: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMITTING':
      return { ...state, loading: true };
    case 'LOGIN_COMPLETE':
      return {
        ...state,
        loading: false,
        loginComplete: action.payload.success,
      };
    case 'CHANGE_DATA':
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case 'ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      throw new Error('Unhandled action type');
  }
};

type LoginControllerProps = {
  handleLoginSuccess: (...args: any[]) => any;
};

export function LoginController(props: LoginControllerProps) {
  const query = useQuery();
  const location: {
    [name: string]: any;
    state?: { [name: string]: any; from?: string };
  } = useLocation();
  const loginProps = {
    emailAutoFocus: true,
    redirectURI: query.get('redirectURI') || '/',
    from: location?.state?.from,
    type: 'green',
  };
  window.location.href = `${process.env.NX_FA_LOGIN_URI}?redirectURI=${loginProps.redirectURI}&customRegisterURI=${process.env.NX_FA_REGISTER_URI}`;
  return null;
  // const { isAuthenticated, setAuthState } = React.useContext(AuthContext);
  // const { signIn } = useUsersGateway();
  // const [state, dispatch] = React.useReducer(reducer, initState, () => ({
  //   ...initState,
  //   loginComplete: isAuthenticated(),
  // }));
  // const { t } = useTranslation();
  // const handleChange = (isCheckbox) => (event) => {
  //   const { name, value, checked } = event.target;
  //   dispatch({
  //     type: 'CHANGE_DATA',
  //     payload: {
  //       key: name,
  //       value: isCheckbox
  //         ? checked
  //         : name === 'email'
  //         ? value.toLowerCase()
  //         : value,
  //     },
  //   });
  // };
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   ReactGA.event({
  //     category: gaEvents.AUTH_CAT,
  //     action: gaEvents.LOGIN_ACTION,
  //   });
  //   const { email, password, rememberMe } = state;
  //   if (email === '') {
  //     dispatch({
  //       type: 'ERROR',
  //       payload: {
  //         key: 'email',
  //         value: t('validation:form.requiredField.empty'),
  //       },
  //     });
  //   } else {
  //     dispatch({ type: 'ERROR', payload: { key: 'email', value: '' } });
  //   }
  //   if (password === '') {
  //     dispatch({
  //       type: 'ERROR',
  //       payload: {
  //         key: 'password',
  //         value: t('validation:form.requiredField.empty'),
  //       },
  //     });
  //   } else {
  //     dispatch({ type: 'ERROR', payload: { key: 'password', value: '' } });
  //   }
  //   if (email === '' || password === '') {
  //     return;
  //   }
  //   dispatch({ type: 'SUBMITTING' });
  //   signIn(email, password, rememberMe)
  //     .then((result: LoginResult) => {
  //       const { user } = result;
  //       if (!user.getId()) {
  //         if (!user.isVerified()) {
  //           dispatch({
  //             type: 'ERROR',
  //             payload: {
  //               key: 'global',
  //               value: t('validation:form.login.accountNotVerified'),
  //             },
  //           });
  //         } else {
  //           dispatch({
  //             type: 'ERROR',
  //             payload: {
  //               key: 'global',
  //               value: t('validation:form.login.error'),
  //             },
  //           });
  //         }
  //       } else {
  //         dispatch({ type: 'ERROR', payload: { key: 'global', value: '' } });
  //         setAuthState({ expiresAt: result.expiresAt, userInfo: result.user });
  //         dispatch({ type: 'LOGIN_COMPLETE', payload: { success: true } });
  //         return result;
  //       }
  //       dispatch({ type: 'LOGIN_COMPLETE', payload: { success: false } });
  //     })
  //     .catch(() => {
  //       dispatch({
  //         type: 'ERROR',
  //         payload: { key: 'global', value: t('validation:form.login.error') },
  //       });
  //       dispatch({ type: 'LOGIN_COMPLETE', payload: { success: false } });
  //     });
  // };

  // return (
  //   <ColoredBackgroundShell>
  //     <Login
  //       state={state}
  //       handleChange={handleChange}
  //       handleSubmit={handleSubmit}
  //       {...loginProps}
  //     />
  //   </ColoredBackgroundShell>
  // );
}
