export default {
  asterisks: {
    onReferralFirstPurchase: 'Lors du premier achat de votre filleul',
    onReferralPurchases: 'Sur tous les achats de votre filleul',
    shareLinkExplanation:
      "Copiez le lien directement en cliquant dessus. Vos filleuls doivent obligatoirement s'inscrire via ce lien pour que le parrainage fonctionne",
  },
  buttons: {
    invite: 'Inviter',
  },
  dataTable: {
    columns: {
      lastUpdate: 'Dernière mise à jour',
      referral: 'Filleul',
      rewardAmount: 'Montant offert',
      rewardedBy: 'Récompensé par',
      rewardType: 'Type de récompense',
      status: 'Statut',
      subType: "Type d'abo",
    },
    enums: {
      rewardType: {
        balance: 'Crédits',
        cashback: 'Cashback',
        monthFree: '1 mois gratuit',
      },
      status: {
        completed: 'Complété',
        paid: 'Payé',
        pending: 'En cours',
        sent: 'Envoyé',
      },
    },
    yourReferrer: 'Votre parrain',
  },
  errors: {
    global:
      "Une erreur est survenue, merci d'essayer à nouveau ou contactez-nous",
    noFullName:
      'Vos nom et prénom doivent être renseignés pour envoyer des invitations',
    noReferrerCode:
      "Votre compte n'a pas de code de parrainage, contactez-nous via la chatbox ou essayez de vous reconnecter",
    noSub: 'Vous devez avoir un abonnement actif pour pouvoir parrainer',
  },
  inviteSuccess: '%X% praticien(s) invité(s)',
  text: {
    affiliationInstructions: 'Pour parrainer, 2 solutions :',
    caption: 'Vous nous recommandez, on vous récompense !',
    costPerAcquisitionAmount: '%X%€ offerts',
    forYou: 'pour vous',
    forYourReferral: 'pour votre filleul',
    noSub: 'Pour parrainer, vous devez être abonné',
    oneMonthFree: '5€ offerts',
    revenueShareAmount: '%X%% offerts',
    sendMail: 'Envoyer des invitations directement par mail',
    shareLink: 'Partagez votre lien de parrainage',
  },
  titles: {
    affiliations: 'Parrainage',
    myReferrals: 'Mes filleuls',
    myRewards: 'Mes récompenses',
    referrerCode: 'Mon code parrainage',
  },
};
