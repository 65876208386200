import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert/Alert';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import useAuthService from '../../services/auth/authService';
import { AuthContext } from '@kiway/shared/features/authentication-react';
import { useTranslation } from '@kiway/shared/utils/translation';
import {
  sendEmailVerificationRequest,
  userRegister,
  verifyEmail,
} from '../../services/user/userService';
import CustomButton from '../Buttons/CustomButton';
import GoogleMaps from '../GoogleMaps';
import BirthDatePicker from './BirthDatePicker';
import GenderPicker from './GenderPicker';
import GreenPageTextField from './GreenPageTextField';
import useNewPhoneInputField from './NewPhoneInputField';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ValidationCodeInput from 'react-verification-code-input';
import { makeStyles } from '@material-ui/core/styles';
import { firstUpperCase } from '../../services/utils/string';
import passwordValidator from 'password-validator';
import ReactGA from 'react-ga';
import {
  PATIENT_LABEL,
  PRACTITIONER_LABEL,
  SIGNUP_CAT,
  VALIDATE_ACCOUNT_ACTION,
} from '../../services/ga/gaEvents';
import CustomCheckbox from './CustomCheckbox';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import useQuery from '../../services/hooks/useQuery';

const schema = new passwordValidator();
schema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols()
  .has()
  .not()
  .spaces();

const initState = {
  user: {
    address: '',
    birthDate: null,
    email: '',
    firstName: '',
    GDPR: false,
    gender: 0,
    lastName: '',
    password: '',
    mobilePhone: '',
    profession: '',
    verificationCode: '',
    referrerCode: '',
  },
  errors: {
    address: '',
    birthDate: '',
    email: '',
    firstName: '',
    GDPR: '',
    gender: '',
    lastName: '',
    password: '',
    mobilePhone: '',
    profession: '',
    global: '',
    verificationCode: '',
    referrerCode: '',
  },
  activeStep: 0,
  succes: false,
  isPhoneValid: false,
  loading: false,
  codeLoading: false,
  signUpComplete: false,
  rememberMe: false,
  cgu: false,
  ofAge: false,
  certifyLegalRepresentative: false,
  affiliationId: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMITTING':
      return { ...state, loading: true };
    case 'SUBMITTING_CODE':
      return { ...state, codeLoading: true };
    case 'CODE_SUBMITTED':
      return { ...state, codeLoading: false };
    case 'SIGN_UP_COMPLETE':
      return {
        ...state,
        loading: false,
        signUpComplete: action.payload.success,
      };
    case 'CHANGE_DATA':
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.key]: action.payload.value,
        },
      };
    case 'ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.key]: action.payload.value,
        },
      };
    case 'VALID_PHONE':
      return {
        ...state,
        isPhoneValid: true,
      };
    case 'INVALID_PHONE':
      return {
        ...state,
        isPhoneValid: false,
      };
    case 'NEXT_STEP':
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    case 'TOGGLE_CGU':
      return {
        ...state,
        cgu: !state.cgu,
      };
    case 'TO_BE_OF_AGE':
      return {
        ...state,
        ofAge: action.payload,
      };
    case 'TOGGLE_LEGAL_REPRESENTATIVE':
      return {
        ...state,
        certifyLegalRepresentative: !state.certifyLegalRepresentative,
      };
    default:
      throw new Error('Unhandled action type');
  }
};

export function useSignUp(options) {
  const { login } = useAuthService();
  const { bypassEmailVerif, proOrPatient } = options;
  const { isAuthenticated } = useContext(AuthContext);
  const query = useQuery();
  const [state, dispatch] = useReducer(reducer, initState, () => ({
    ...initState,
    signUpComplete: isAuthenticated(),
    user: {
      ...initState.user,
      referrerCode: query.get('referrer') || '',
      email: query.get('email') || '',
    },
    affiliationId: query.get('invitationId') || null,
  }));
  const [initDone, setInitDone] = useState(false);
  const { t } = useTranslation();
  // eslint-disable-next-line max-len
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const handleChange = (isCheckbox) => (event) => {
    const { name, value, checked } = event.target;
    dispatch({
      type: 'CHANGE_DATA',
      payload: {
        key: name,
        value: isCheckbox
          ? checked
          : name === 'email'
          ? value.toLowerCase()
          : ['firstName', 'lastName', 'profession'].includes(name)
          ? firstUpperCase(value)
          : value,
      },
    });
  };

  const setFormError = (type, text) => {
    dispatch({
      type: 'ERROR',
      payload: {
        key: type,
        value: text,
      },
    });
  };

  function handleEventGA(userType) {
    if (userType === 'patient') {
      ReactGA.event({
        category: SIGNUP_CAT,
        action: VALIDATE_ACCOUNT_ACTION,
        label: PATIENT_LABEL,
      });
    } else if (userType === 'practitioner') {
      ReactGA.event({
        category: SIGNUP_CAT,
        action: VALIDATE_ACCOUNT_ACTION,
        label: PRACTITIONER_LABEL,
      });
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    handleEventGA(proOrPatient);
    const { errors, affiliationId, isPhoneValid, user } = state;
    if (
      user.password === '' ||
      user.email === '' ||
      user.lastName === '' ||
      user.firstName === '' ||
      !user.GDPR
    ) {
      setFormError('global', t('user:register.error.requiredFieldsEmpty'));
    } else if (!isPhoneValid) {
      setFormError(
        'global',
        t('common:form.fields.phone.errorNotValidOrNotMobile')
      );
    } else if (!emailRegex.test(user.email)) {
      setFormError('global', t('user:register.error.email'));
    } else {
      setFormError('global', '');
      let userData = {
        email: user.email,
        password: user.password,
        GDPR: user.GDPR,
        lastName: user.lastName,
        firstName: user.firstName,
        gender: user.gender !== 0 ? user.gender : undefined,
        birthDate: user.birthDate.format('YYYY-MM-DD'),
        mobilePhone: user.mobilePhone,
        profession: user.profession,
        address: user.address,
        token: user.verificationCode,
      };
      let userType;
      if (proOrPatient === 'patient') {
        userType = 'patient';
      } else if (proOrPatient === 'practitioner') {
        userType = 'practitioner';
        userData = {
          ...userData,
          affiliationId,
          referrerCode: user.referrerCode,
        };
      }
      dispatch({ type: 'SUBMITTING' });
      userRegister(userType, userData).then((result) => {
        if (
          result &&
          ((userType === 'practitioner' &&
            ['practitioner', 'pharmaco', 'partner', 'beta'].includes(
              result.role
            )) ||
            (userType === 'patient' && result.role === 'patient'))
        ) {
          const { email, password } = user;
          login({ bypassEmailVerif, email, password }).then((data) => {
            if (data._id) {
              dispatch({
                type: 'SIGN_UP_COMPLETE',
                payload: { success: true },
              });
            } else {
              dispatch({
                type: 'SIGN_UP_COMPLETE',
                payload: { success: false },
              });
            }
          });
        } else {
          dispatch({ type: 'SIGN_UP_COMPLETE', payload: { success: false } });
          if (
            result.data &&
            result.data.error &&
            result.data.error.errors &&
            result.data.error.errors.email
          ) {
            setFormError('global', t('user:register.error.emailExists'));
          } else {
            setFormError('global', t('user:register.error.global'));
          }
        }
      });
    }
  };

  const handleVerifyEmail = (verificationCode) => {
    const { user } = state;
    dispatch({ type: 'SUBMITTING_CODE' });
    if (user.email === '' || verificationCode === '') {
      setFormError('global', t('user:register.error.requiredFieldsEmpty'));
      dispatch({ type: 'CODE_SUBMITTED' });
    } else if (!emailRegex.test(user.email)) {
      setFormError('global', t('user:register.error.email'));
      dispatch({ type: 'CODE_SUBMITTED' });
    } else {
      verifyEmail(user.email, verificationCode)
        .then((data) => {
          if (data && data.verify) {
            setFormError('global', '');
            dispatch({ type: 'NEXT_STEP' });
          } else {
            setFormError('global', t('user:register.error.verifCode'));
          }
        })
        .then(() => dispatch({ type: 'CODE_SUBMITTED' }));
    }
  };
  const handleSendEmailVerificationRequest = (first = true) => (event) => {
    event.preventDefault();
    const { user } = state;
    if (user.password === '' || user.email === '') {
      setFormError('global', t('user:register.error.requiredFieldsEmpty'));
    } else if (!emailRegex.test(user.email)) {
      setFormError('global', t('user:register.error.email'));
    } else {
      sendEmailVerificationRequest(user.email).then((data) => {
        if (data && data.send) {
          if (first) {
            dispatch({ type: 'NEXT_STEP' });
            setFormError('global', '');
          }
        } else if (data && data.exists) {
          setFormError('global', t('user:register.error.emailExists'));
        } else {
          setFormError('global', t('user:register.error.emailVerif'));
        }
      });
    }
  };

  useEffect(() => {
    setInitDone(true);
  }, []);

  useEffect(() => {
    if (initDone) {
      if (!schema.validate(state.user.password)) {
        setFormError('password', t('user:form.passwordsNotGoodEnough'));
      } else {
        setFormError('password', '');
      }
    }
  }, [state.user.password]);

  useEffect(() => {
    if (initDone) {
      if (state.user.password !== state.user.passwordConfirm) {
        setFormError('passwordConfirm', t('user:form.passwordsNotMatch'));
      } else {
        setFormError('passwordConfirm', '');
      }
    }
  }, [state.user.passwordConfirm]);

  useEffect(() => {
    if (initDone) {
      if (!emailRegex.test(state.user.email)) {
        setFormError('email', t('user:register.error.email'));
      } else {
        setFormError('email', '');
      }
    }
  }, [state.user.email]);

  useEffect(() => {
    if (moment(state.user.birthDate).isValid()) {
      const ofAge = moment().diff(moment(state.user.birthDate), 'years') >= 18;
      if (ofAge || state.certifyLegalRepresentative) {
        setFormError('birthDate', '');
      } else {
        setFormError(
          'birthDate',
          t(
            'user:register.error.birthDate.ofAge' +
              (proOrPatient === 'practitioner' ? 'Pract' : 'Patient')
          )
        );
      }
      dispatch({ type: 'TO_BE_OF_AGE', payload: ofAge });
    }
    if (state.user.birthDate === null) {
      setFormError('birthDate', '');
    }
  }, [state.user.birthDate, state.certifyLegalRepresentative]);
  return [
    state,
    handleChange,
    handleSubmit,
    dispatch,
    handleSendEmailVerificationRequest,
    handleVerifyEmail,
  ];
}

const useStyles = makeStyles((theme) => {
  return {
    labelStepLabel: {
      color: (props) =>
        props.type === 'green' ? 'rgba(255,255,255,0.5) !important' : undefined,
    },
    labelIconContainer: {
      color: (props) =>
        props.type === 'green' ? 'white !important' : undefined,
    },
    activeStepLabel: {
      color: (props) =>
        props.type === 'green' ? 'white !important' : undefined,
    },
    iconText: {
      fill: (props) =>
        props.type === 'green' ? 'white !important' : undefined,
    },
    iconCompleted: {
      color: (props) =>
        props.type === 'green' ? 'white !important' : undefined,
    },
  };
});

export function useSignUpForm(options) {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const {
    bypassEmailVerif,
    customProps,
    emailAutoFocus,
    proOrPatient,
    redirectURI,
    submitColor,
    type,
  } = options;
  const classes = useStyles({
    type,
  });
  const [
    state,
    handleChange,
    handleSubmit,
    dispatch,
    handleSendEmailVerificationRequest,
    handleVerifyEmail,
  ] = useSignUp({
    bypassEmailVerif,
    proOrPatient,
  });
  const {
    activeStep,
    codeLoading,
    errors,
    loading,
    signUpComplete,
    user,
  } = state;
  const [showPassword, setShowPassword] = useState(false);
  const handleMouseUpPassword = () => setShowPassword(false);
  const handleMouseDownPassword = () => setShowPassword(true);

  const referrerCodeProps = {
    id: 'referrerCode',
    name: 'referrerCode',
    label: t('user:form.referrerCodeField'),
    autoComplete: 'referrerCode',
    value: user.referrerCode,
    error: Boolean(errors.referrerCode),
    helperText: errors.referrerCode,
    onChange: handleChange(),
    margin: 'dense',
    readOnly: query.get('referrer') && query.get('referrer') !== null,
    disabled: query.get('referrer') && query.get('referrer') !== null,
  };

  const emailFieldProps = {
    required: true,
    id: 'email',
    name: 'email',
    label: t('user:form.emailField'),
    autoComplete: 'email',
    value: user.email,
    error: Boolean(errors.email),
    helperText: errors.email,
    onChange: handleChange(),
    autoFocus: emailAutoFocus,
    margin: 'dense',
  };
  const passwordFieldProps = {
    required: true,
    id: 'password',
    name: 'password',
    type: showPassword ? 'text' : 'password',
    label: t('user:form.passwordField'),
    autoComplete: 'current-password',
    value: user.password,
    error: Boolean(errors.password),
    helperText: errors.password,
    onChange: handleChange(),
    margin: 'dense',
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onMouseDown={handleMouseDownPassword}
            onMouseUp={handleMouseUpPassword}
            onTouchStart={handleMouseDownPassword}
            onTouchEnd={handleMouseUpPassword}
          >
            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </InputAdornment>
      ),
    },
  };
  const googleMapsProps = {
    inputValue:
      user.address && user.address.description ? user.address.description : '',
    setAddressComponent: (value) =>
      handleChange(false)({ target: { name: 'address', value } }),
    setAddress: (value) =>
      handleChange(false)({
        target: {
          name: 'address',
          value: {
            description: value,
          },
        },
      }),
    margin: 'dense',
  };
  const classicFieldProps = (
    name,
    label,
    isCheckbox = false,
    required = true
  ) => ({
    required,
    id: name,
    name: name,
    label: label,
    value: user[name],
    onChange: handleChange(isCheckbox),
    margin: 'dense',
    helperText: errors[name],
    error: Boolean(errors[name]),
  });

  const [phoneState, PhoneComponent] = useNewPhoneInputField({
    forceMobile: true,
    type,
    inputProps: { required: true },
  });

  const isConfirmButtonDisabled = () => {
    const { isPhoneValid, user } = state;
    if (
      user.firstName !== '' &&
      user.lastName !== '' &&
      user.mobilePhone !== '' &&
      isPhoneValid &&
      user.birthDate &&
      (state.ofAge ||
        (proOrPatient === 'patient' ? state.certifyLegalRepresentative : false))
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isCredentialsButtonDisabled = () => {
    const { errors, user } = state;
    return !(
      state.cgu &&
      user.GDPR &&
      user.password !== '' &&
      errors['password'] === '' &&
      user.email !== '' &&
      errors['email'] === ''
    );
  };

  useEffect(() => {
    dispatch({
      type: 'CHANGE_DATA',
      payload: { key: 'mobilePhone', value: phoneState.e164 },
    });
    if (phoneState.error) {
      dispatch({ type: 'INVALID_PHONE' });
    } else {
      dispatch({ type: 'VALID_PHONE' });
    }
  }, [phoneState]);

  const credentialsButton = (
    <CustomButton
      color={submitColor || 'minoritary'}
      disabled={loading || isCredentialsButtonDisabled()}
      fullWidth
      onClick={handleSendEmailVerificationRequest()}
      style={{ marginTop: '8px' }}
    >
      {loading ? <CircularProgress size={25} /> : t('user:register.button')}
    </CustomButton>
  );
  const verificationCodeExplaination = (
    <Alert severity="info" style={{ marginBottom: '8px' }}>
      {t('user:register.verifCodeExplaination')}
    </Alert>
  );
  const verificationCodeButton = (
    <CustomButton
      color={submitColor || 'minoritary'}
      disabled={loading}
      fullWidth
      onClick={handleSendEmailVerificationRequest(false)}
      style={{ marginTop: '8px' }}
    >
      {t('user:register.buttonResend')}
    </CustomButton>
  );
  const confirmButton = (
    <CustomButton
      color={submitColor || 'minoritary'}
      disabled={loading || isConfirmButtonDisabled()}
      fullWidth
      onClick={handleSubmit}
      style={{ marginTop: '8px', marginBottom: '8px' }}
    >
      {loading ? <CircularProgress size={25} /> : t('user:form.signUpButton')}
    </CustomButton>
  );

  const SignInComponent = signUpComplete ? (
    redirectURI ? (
      <Redirect to={redirectURI} />
    ) : (
      <div>{/*DO SOMETHING WHEN USER IS LOGGED IN*/}</div>
    )
  ) : (
    <div {...customProps}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
      >
        <Step key={0}>
          <StepLabel
            classes={{
              active: classes.activeStepLabel,
              completed: classes.labelStepLabel,
              label: classes.labelStepLabel,
              iconContainer: classes.labelIconContainer,
            }}
            StepIconProps={{
              classes: {
                completed: classes.iconCompleted,
                text: classes.iconText,
              },
            }}
          >
            {t('user:register.stepper.credentials')}
          </StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel
            classes={{
              active: classes.activeStepLabel,
              completed: classes.labelStepLabel,
              label: classes.labelStepLabel,
              iconContainer: classes.labelIconContainer,
            }}
            StepIconProps={{
              classes: {
                completed: classes.iconCompleted,
                text: classes.iconText,
              },
            }}
          >
            {t('user:register.stepper.emailVerification')}
          </StepLabel>
        </Step>
        <Step key={2}>
          <StepLabel
            classes={{
              active: classes.activeStepLabel,
              completed: classes.labelStepLabel,
              label: classes.labelStepLabel,
              iconContainer: classes.labelIconContainer,
            }}
            StepIconProps={{
              classes: {
                completed: classes.iconCompleted,
                text: classes.iconText,
              },
            }}
          >
            {t('user:register.stepper.personalData')}
          </StepLabel>
        </Step>
      </Stepper>
      {type === 'green' ? (
        <>
          {activeStep === 0 && (
            <>
              {proOrPatient === 'practitioner' && (
                <GreenPageTextField {...referrerCodeProps} />
              )}
              <GreenPageTextField {...emailFieldProps} />
              <GreenPageTextField {...passwordFieldProps} />
              <CustomCheckbox
                noMargin
                error={errors.global}
                handleChange={handleChange(true)}
                label={
                  <ReactMarkdown linkTarget="_blank" className={'md-green'}>
                    {t('common:form.fields.GDPR.label')}
                  </ReactMarkdown>
                }
                name={'GDPR'}
                theme={'green'}
                value={user.GDPR}
              />
              <CustomCheckbox
                noMargin
                error={''}
                handleChange={() => dispatch({ type: 'TOGGLE_CGU' })}
                label={
                  <ReactMarkdown linkTarget="_blank" className={'md-green'}>
                    {t('common:form.fields.CGU.label')}
                  </ReactMarkdown>
                }
                theme={'green'}
                value={state.cgu}
              />
              {credentialsButton}
            </>
          )}
          {activeStep === 1 && (
            <>
              {verificationCodeExplaination}
              <ValidationCodeInput
                fieldHeight={40}
                fieldWidth={'16.66%'}
                onComplete={handleVerifyEmail}
                fields={6}
                type={'number'}
                value={user.verificationCode.split('')}
                loading={codeLoading}
                onChange={(value) =>
                  handleChange()({
                    target: { name: 'verificationCode', value },
                  })
                }
                placeholder={['·', '·', '·', '·', '·', '·']}
              />
              {verificationCodeButton}
            </>
          )}
          {activeStep === 2 && (
            <>
              <GreenPageTextField
                {...classicFieldProps(
                  'firstName',
                  t('file:addPatientModal.firstName')
                )}
              />
              <GreenPageTextField
                {...classicFieldProps(
                  'lastName',
                  t('file:addPatientModal.lastName')
                )}
              />
              <BirthDatePicker
                handleChange={(date) =>
                  handleChange()({ target: { name: 'birthDate', value: date } })
                }
                theme="green"
                value={user.birthDate}
                required
                helperText={errors['birthDate']}
                error={Boolean(errors['birthDate'])}
              />
              {PhoneComponent}
              {proOrPatient === 'patient' && (
                <>
                  <GoogleMaps {...googleMapsProps} theme="green" />
                  <GreenPageTextField
                    {...classicFieldProps(
                      'profession',
                      t('file:details.info.profession'),
                      false,
                      false
                    )}
                  />
                </>
              )}
              <GenderPicker
                handleChange={handleChange()}
                theme="green"
                value={user.gender}
              />
              {proOrPatient === 'patient' &&
                user.birthDate?.isValid() &&
                !state.ofAge && (
                  <CustomCheckbox
                    error=""
                    theme="green"
                    label={t('user:register.certifyLegalRepresentative')}
                    value={state.certifyLegalRepresentative}
                    handleChange={() =>
                      dispatch({ type: 'TOGGLE_LEGAL_REPRESENTATIVE' })
                    }
                  />
                )}
              {confirmButton}
            </>
          )}
        </>
      ) : (
        <>
          {activeStep === 0 && (
            <>
              {proOrPatient === 'practitioner' && (
                <TextField
                  {...referrerCodeProps}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              )}
              <TextField
                {...emailFieldProps}
                fullWidth
                margin="dense"
                variant="outlined"
              />
              <TextField
                {...passwordFieldProps}
                fullWidth
                margin="dense"
                variant="outlined"
              />
              <CustomCheckbox
                noMargin
                error={errors.global}
                handleChange={handleChange(true)}
                label={
                  <ReactMarkdown linkTarget="_blank" className={'md'}>
                    {t('common:form.fields.GDPR.label')}
                  </ReactMarkdown>
                }
                name={'GDPR'}
                value={user.GDPR}
              />
              <CustomCheckbox
                noMargin
                error={''}
                handleChange={() => dispatch({ type: 'TOGGLE_CGU' })}
                label={
                  <ReactMarkdown linkTarget="_blank" className={'md'}>
                    {t('common:form.fields.CGU.label')}
                  </ReactMarkdown>
                }
                value={state.cgu}
              />
              {credentialsButton}
            </>
          )}
          {activeStep === 1 && (
            <>
              {verificationCodeExplaination}
              <ValidationCodeInput
                fieldHeight={40}
                fieldWidth={'16.66%'}
                onComplete={handleVerifyEmail}
                fields={6}
                type={'number'}
                value={user.verificationCode.split('')}
                loading={codeLoading}
                onChange={(value) =>
                  handleChange()({
                    target: { name: 'verificationCode', value },
                  })
                }
                placeholder={['·', '·', '·', '·', '·', '·']}
              />
              {verificationCodeButton}
            </>
          )}
          {activeStep === 2 && (
            <>
              <TextField
                fullWidth
                margin="dense"
                variant="outlined"
                {...classicFieldProps(
                  'firstName',
                  t('file:addPatientModal.firstName')
                )}
              />
              <TextField
                fullWidth
                margin="dense"
                variant="outlined"
                {...classicFieldProps(
                  'lastName',
                  t('file:addPatientModal.lastName')
                )}
              />
              <BirthDatePicker
                handleChange={(date) =>
                  handleChange()({ target: { name: 'birthDate', value: date } })
                }
                value={user.birthDate}
                required
                helperText={errors['birthDate']}
                error={Boolean(errors['birthDate'])}
              />
              {PhoneComponent}
              {proOrPatient === 'patient' && (
                <>
                  <GoogleMaps {...googleMapsProps} />
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...classicFieldProps(
                      'profession',
                      t('file:details.info.profession'),
                      false,
                      false
                    )}
                  />
                </>
              )}
              <GenderPicker handleChange={handleChange()} value={user.gender} />
              {proOrPatient === 'patient' &&
                user.birthDate?.isValid() &&
                !state.ofAge && (
                  <CustomCheckbox
                    error=""
                    label={t('user:register.certifyLegalRepresentative')}
                    value={state.certifyLegalRepresentative}
                    handleChange={() =>
                      dispatch({ type: 'TOGGLE_LEGAL_REPRESENTATIVE' })
                    }
                  />
                )}
              {confirmButton}
            </>
          )}
        </>
      )}
      <CustomButton
        fullWidth
        onClick={() => history.push('/')}
        style={{ marginTop: '8px', marginBottom: '8px' }}
      >
        {t('user:form.backToHome')}
      </CustomButton>
      {Boolean(errors.global) && (
        <Alert severity="error" style={{ marginTop: '8px' }}>
          {errors.global}
        </Alert>
      )}
    </div>
  );
  return [SignInComponent, state];
}
