import { Plant } from '../entities/Plant';
import { PlantProvider } from './PlantProvider';

export class GetPlant {
  private plantProvider: PlantProvider;

  public constructor(plantProvider: PlantProvider) {
    this.plantProvider = plantProvider;
  }

  public execute(plantId: string): Promise<Plant> {
    return this.plantProvider.findOneById(plantId);
  }
}
