export default {
  contactInfo: {
    mobilePhone: 'Teléfono',
  },
  description: {
    title: 'Descripción',
  },
  formations: {
    title: 'Capacitación',
  },
  gallery: {
    title: 'Galería de imágenes',
  },
  noOnlineBooking:
    'Su practicante no ha activado la cita en línea, comuníquese directamente con ella.',
  places: {
    title: 'Lugares de consulta',
  },
};
