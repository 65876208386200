import { gql } from '@apollo/client';

export const GET_USER_NOTIFICATIONS = gql`
  query {
    getUserNotifications {
      id
      type
      user {
        id
      }
      isRead
      readAt
      metadata {
        appointment {
          appointmentId
        }
        release {
          releaseObj {
            id
            title
            version
            date
            notes
          }
        }
        stripeConnect {
          action
        }
        text
        link
      }
    }
  }
`;
