import * as React from 'react';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { Grid, IconButton, TextField } from '@mui/material';
import { useStyles } from '../../shared/styles';
import { useTranslation } from '@kiway/shared/utils/translation';
import { TimelineDot } from '@mui/lab';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CAPSULE_WEIGHT } from '../../../../core/entities/PharmacoOrder';

export function FormulaPlantNav(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <TableCell
        width="2%"
        className={classes.tabTitles}
        align="left"
      ></TableCell>
      <TableCell
        width="3%"
        className={classes.tabTitles}
        align="left"
      ></TableCell>
      <TableCell width="30%" className={classes.tabTitles} align="left">
        <b>{t('pharmaco:formula.pinYinName')}</b>
      </TableCell>
      <TableCell width="5%" className={classes.tabTitles} align="left">
        <b>{t('pharmaco:formula.separate')}</b>
      </TableCell>
      <TableCell width="20%" className={classes.tabTitles} align="right">
        <b>{t('pharmaco:formula.dosage')}</b>
      </TableCell>
      <TableCell width="10%" className={classes.tabTitles} align="right">
        <b>{t('pharmaco:formula.percent')}</b>
      </TableCell>
      <TableCell width="10%" className={classes.tabTitles} align="right">
        <b>
          {props.isCapsule
            ? t('pharmaco:formula.weightPerCapsule')
            : t('pharmaco:formula.weightPerPortion')}
        </b>
      </TableCell>
      <TableCell width="10%" className={classes.tabTitles} align="right">
        <b>{t('pharmaco:formula.totalWeight')}</b>
      </TableCell>
      <TableCell width="10%" className={classes.tabTitles} align="right">
        <b>{t('pharmaco:formula.priceHT')}</b>
      </TableCell>
    </>
  );
}

export function FormulaPlantBody(props) {
  const { t } = useTranslation();
  return (
    <>
      {props.rows.map((row, index) => (
        <RowFormula
          key={index}
          row={row}
          handleChangeDosage={props.handleChangeDosage}
          handleChangeIsSeparated={props.handleChangeIsSeparated}
          handleSaveDosage={props.handleSaveDosage}
          available={props.available}
          handleRemoveLineItem={props.handleRemoveLineItem}
          getRoundedItemTotalWeight={props.getRoundedItemTotalWeight}
          getRoundedItemPortionWeight={props.getRoundedItemPortionWeight}
        />
      ))}
      <TableRow>
        <TableCell align="left">
          <Grid container>
            <Grid item>
              {props.available ? (
                <TimelineDot color="success" />
              ) : (
                <TimelineDot color="error" />
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell colSpan={3} align="right">
          <b>{t('pharmaco:formula.total')}</b>
        </TableCell>
        <TableCell align="right">
          <b>
            {`${parseFloat(`${props.totalDosage}`)?.toFixed(1)}`.replace(
              /\s/g,
              '\u00A0'
            )}
          </b>
        </TableCell>
        <TableCell align="right">
          <b>{props.totalPercent}</b>
        </TableCell>
        <TableCell align="right">
          <b>
            {`${parseFloat(
              `${props.isCapsule ? CAPSULE_WEIGHT : props.portionWeight}`
            )?.toFixed(2)} g`.replace(/\s/g, '\u00A0')}
          </b>
        </TableCell>
        <TableCell align="right">
          <b>
            {`${parseFloat(`${props.totalWeight}`)?.toFixed(2)} g`.replace(
              /\s/g,
              '\u00A0'
            )}
          </b>
        </TableCell>
        <TableCell align="right">
          <b>{props.totalPrice?.toString()}</b>
        </TableCell>
      </TableRow>
    </>
  );
}

function RowFormula(props) {
  const classes = useStyles();
  const lineItem = props.row.getLineItem();

  return (
    <TableRow key={lineItem?.getId()}>
      <TableCell scope="row" align="left">
        {props.row.isAvailable() ? (
          <div style={{ width: '10%' }}>
            <TimelineDot color="success" />
          </div>
        ) : (
          <div style={{ width: '10%' }}>
            <TimelineDot color="error" />
          </div>
        )}
      </TableCell>
      <TableCell scope="row" align="left">
        <IconButton
          onClick={() => props.handleRemoveLineItem(lineItem?.getId())}
        >
          <DeleteForeverIcon />
        </IconButton>
      </TableCell>
      <TableCell scope="row">{lineItem?.getProduct()?.getName()?.fr}</TableCell>
      <TableCell scope="row" align="right">
        <Checkbox
          checked={props.row.isSeparated()}
          onChange={props.handleChangeIsSeparated(props.row)}
        />
      </TableCell>
      <TableCell scope="row" align="right">
        <TextField
          type="number"
          className={classes.noArrowInputNumber}
          value={lineItem?.getCustom()?.dosage || '0'}
          variant="outlined"
          size="small"
          onChange={props.handleChangeDosage(lineItem?.getId())}
          onBlur={() => props.handleSaveDosage()}
        ></TextField>
      </TableCell>
      <TableCell scope="row" align="right">
        {props.row.getPercent()}
      </TableCell>
      <TableCell scope="row" align="right">
        {`${parseFloat(
          `${props.getRoundedItemPortionWeight(lineItem?.getId(), true)}`
        )?.toFixed(2)} g`.replace(/\s/g, '\u00A0')}
      </TableCell>
      <TableCell scope="row" align="right">
        {`${parseFloat(
          `${props.getRoundedItemTotalWeight(lineItem?.getId())}`
        )?.toFixed(2)} g`.replace(/\s/g, '\u00A0')}
      </TableCell>
      <TableCell scope="row" align="right">
        {lineItem.getTotalPrice()?.toString()}
      </TableCell>
    </TableRow>
  );
}
