import React from 'react';
import { BaseOldApp } from '@kiway/base-old-app';
import config from './config';
import { ThemeProvider } from '@mui/material/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';

export const App = () => {
  return (
    <MuiThemeProvider theme={config.themeV4}>
      <ThemeProvider theme={config.themeV5}>
        <BaseOldApp config={config} />
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default App;
