import {
  basicDeleteRequest,
  basicGetRequest,
  getBaseURL,
  runBasicFetch,
  runBasicFetchV2,
} from '../utils/api';

const BASE_URL = getBaseURL();

// TODO implement validation
export const isChiefComplaintValid = (chiefComplaint) => true;

export const getChiefComplaint = (chiefComplaintId) => {
  return runBasicFetch(
    basicGetRequest(`${BASE_URL}/pro/chiefComplaint/${chiefComplaintId}`),
    true
  );
};

export const getPractitionerChiefComplaints = (
  practitionerId = false,
  publicPage = false
) => {
  return runBasicFetch(
    basicGetRequest(
      `${BASE_URL}/pro/chiefComplaint?publicPage=${publicPage}${
        practitionerId ? `&practitionerId=${practitionerId}` : ``
      }`
    ),
    !publicPage
  );
};

export const editChiefComplaint = (chiefComplaint) => {
  if (!isChiefComplaintValid(chiefComplaint)) {
    return false;
  }
  const { _id: chiefComplaintId } = chiefComplaint;
  let method = 'POST';
  let url = `${BASE_URL}/pro/chiefComplaint`;
  if (chiefComplaintId) {
    method = 'PUT';
    url = `${url}/${chiefComplaintId}`;
  }
  const request = {
    url,
    init: {
      method,
      body: JSON.stringify(chiefComplaint),
    },
  };
  return runBasicFetch(request, true);
};

export const deleteChiefComplaint = (chiefComplaintId) => {
  // eslint-disable-next-line max-len
  const url = `${BASE_URL}/pro/chiefComplaint/${chiefComplaintId}`;
  return runBasicFetchV2(basicDeleteRequest(url), true);
};
