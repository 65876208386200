import { LoyaltyTransactionGraphQLProvider } from '../../dataproviders/graphql-client/LoyaltyTransactionGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllLoyaltyTransactions } from '../../core/use_cases/GetAllLoyaltyTransactions';
import { LoyaltyTransaction } from '../../core/entities/LoyaltyTransaction';
import { GetLoyaltyPointsBalance } from '../../core/use_cases/GetLoyaltyPointsBalance';

class LoyaltyTransactionsGateway {
  protected static instance: LoyaltyTransactionsGateway;
  protected getAllLoyaltyTransactions: GetAllLoyaltyTransactions;
  protected getLoyaltyPointsBalanceUC: GetLoyaltyPointsBalance;

  public static getInstance(
    client: ApolloClient<any>
  ): LoyaltyTransactionsGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new LoyaltyTransactionsGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const loyaltyTransactionProvider = new LoyaltyTransactionGraphQLProvider(
      client
    );
    this.getAllLoyaltyTransactions = new GetAllLoyaltyTransactions(
      loyaltyTransactionProvider
    );
    this.getLoyaltyPointsBalanceUC = new GetLoyaltyPointsBalance(
      loyaltyTransactionProvider
    );
    this.findAll = this.findAll.bind(this);
    this.getLoyaltyPointsBalance = this.getLoyaltyPointsBalance.bind(this);
    LoyaltyTransactionsGateway.instance = this;
  }

  async findAll(): Promise<LoyaltyTransaction[]> {
    return await this.getAllLoyaltyTransactions.execute();
  }

  async getLoyaltyPointsBalance(user: string): Promise<number> {
    return await this.getLoyaltyPointsBalanceUC.execute(user);
  }
}

export function useLoyaltyTransactionsGateway() {
  const client = useApolloClient();
  const loyaltyTransactionsGateway = LoyaltyTransactionsGateway.getInstance(
    client
  );
  return {
    findAll: loyaltyTransactionsGateway.findAll,
    getLoyaltyPointsBalance: loyaltyTransactionsGateway.getLoyaltyPointsBalance,
  };
}
