export default {
  city: 'City',
  country: 'Country',
  email: 'Email',
  firstName: 'Firstname',
  helperTextNif: 'Taxpayer identification number',
  lastName: 'Lastname',
  line0: 'Floor, corridor, staircase, apartment',
  line1: 'Entrance, building, residence',
  line2: 'Street number and label',
  line3: 'Locality or other mention',
  mobilePhone: 'Mobile phone',
  nif: 'NIF',
  zipCode: 'ZIP Code',
};
