import { StoredFile } from '../entities/StoredFile';
import { StoredFileProvider } from './StoredFileProvider';

export class GetAllStoredFiles {
  private storedFileProvider: StoredFileProvider;

  public constructor(storedFileProvider: StoredFileProvider) {
    this.storedFileProvider = storedFileProvider;
  }

  public execute(): Promise<StoredFile[]> {
    return this.storedFileProvider.findAll();
  }
}
