export default {
  addPatientModal: {
    firstName: 'Nome',
    lastName: 'Apelido',
    title: 'Adicione um novo paciente',
  },
  appBar: {
    suffix: 'Anos',
  },
  dataTable: {
    columns: {
      firstName: 'Nome',
      lastName: 'Apelido',
      lastSession: 'Última sessão',
      mobilePhone: 'Telefone',
      nextSession: 'Próxima sessão',
      numberOfSessions: 'Número de sessões',
    },
    title: 'Pacientes',
  },
  deletePatientModal: {
    content:
      'Tem certeza de que deseja eliminar este paciente? A operação é irreversível.',
    title: 'Apagar paciente',
  },
  details: {
    actions: {
      alert: 'Alerta',
      appointment: 'Adicione uma consulta',
      export: 'Exportar dados do paciente',
    },
    alerts: {
      label: 'Alertas contextuais',
    },
    background: {
      label: 'História',
      redFlag: 'Importante',
    },
    dataTable: {
      columns: {
        chiefComplaint: 'Motivo de consulta',
        date: 'Data',
        evaluation: 'Avaliação',
      },
      title: 'Sessões',
    },
    info: {
      address: 'Moradas',
      birthDate: 'Data de nascimento',
      city: 'Cidade',
      country: 'País',
      email: 'Email',
      female: 'Mulher',
      firstName: 'Nome',
      gender: 'Gênero',
      label: 'Informações sobre sinalização',
      lastName: 'Apelido',
      male: 'Homem',
      mobilePhone: 'Telemóvel',
      other: 'Outro',
      profession: 'Profissão',
      street: 'Rua',
      zipCode: 'Código postal',
    },
  },
  export: {
    chiefComplaint: 'Motivo de consulta',
    comment: 'Observação',
    consultationPlace: 'Localização da consulta',
    diagnosis: {
      text: 'Diagnóstico',
    },
    endDate: 'Data final',
    followUpEval: {
      comment: 'Comentários',
      date: 'Data da avaliação do tratamento',
      treatmentEfficiency: 'Resultados do tratamento',
    },
    fourMethods: {
      text: '4 tempos do exame',
    },
    modalContent: 'Seu download está pronto, clique no botão abaixo.',
    modalTitle: 'Exportar dados do paciente',
    patientNote: 'Nota destinada ao paciente',
    startDate: 'Data de início',
    treatment: {
      text: 'Tratamento',
    },
  },
  last: {
    legacy: {
      order: 'Última encomenda gravada',
    },
  },
  link: {
    contentModal:
      'Ao clicar em "Ligar este ficheiro" irá associar o ficheiro do paciente à conta de utilizador do paciente. Isto será útil para o paciente tratar **dos seus dados pessoais*** e ter acesso a **aos seus compromissos** lista.{\\i}Obrigado à **"Notas do paciente "** poderá fornecer ao seu paciente um resumo da sessão.{\\i}Por favor, assegure-se de que introduz o endereço de correio electrónico correcto para ligar o ficheiro ao paciente correcto.',
    textButton: 'Ligar este ficheiro',
    titleModal: 'Vincular uma pasta do paciente ao seu usuário',
  },
  snackbars: {
    patient: {
      cantLinkEmailEmpty:
        'Você deve inserir um endereço de email para conectar ao arquivo do paciente',
      errorDelete:
        'Ocorreu um erro durante a eliminação. Por favor tente novamente.',
      errorLink:
        'Ocorreu um erro. O arquivo já está vinculado ao paciente ou o paciente ainda não criou uma conta com este endereço de e -mail.',
      errorNames: 'Os campos do nome e apelido são obrigatórios',
      errorPhone: 'O número de telefone não é válido',
      successCreate: 'Paciente criado com sucesso',
      successDelete: 'Paciente eliminado com sucesso',
      successLink:
        'Link efetuado com sucesso. O seu paciente pode agora aceder às consultas maracadas para si.',
      successUpdate: 'Paciente alterado com sucesso',
    },
    session: {
      successCreate: 'Sessão criada com exito',
      successUpdate: 'Sessão modificada com exito',
    },
  },
};
