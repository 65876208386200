import { LoyaltyTransactionProvider } from './LoyaltyTransactionProvider';

export class GetLoyaltyPointsBalance {
  private loyaltyTransactionProvider: LoyaltyTransactionProvider;

  public constructor(loyaltyTransactionProvider: LoyaltyTransactionProvider) {
    this.loyaltyTransactionProvider = loyaltyTransactionProvider;
  }

  public async execute(user: string): Promise<number> {
    const transactions = await this.loyaltyTransactionProvider.getLoyaltyTransactionsByUser(
      user
    );
    return transactions.reduce(
      (acc, transaction) => acc + transaction.getNbPoints(),
      0
    );
  }
}
