import { roles, role_hierarchy, mapKiwayRole } from './roles';
import { accessControlOptions } from './accessControlConfig';

export type Hierarchy = {
  role: string;
  extends: string[];
};

export type Right = {
  role: string;
  actions?: string[];
};

export type Resource = {
  name: string;
  rights: Right[];
  service?: string;
};

export type AccessControlConfig = {
  roleHierarchy: Hierarchy[];
  publicResources: string[];
  privateResources: Resource[];
};

export type AccessControlOptions = {
  resource: string;
  role?: string | string[];
  action?: string;
  servicesList?: string;
  route?: any;
  roles: string[];
};

export class AccessControl {
  config: AccessControlConfig;

  constructor() {
    this.config = accessControlOptions;
  }

  isRoleEquivalent = (
    userRole: string | string[],
    targetRole: string
  ): boolean => {
    if (roles?.map((role) => role as string).includes(targetRole)) {
      if (
        isGranted(
          targetRole,
          typeof userRole === 'string'
            ? roles?.map((role) => role as string)?.includes(userRole)
              ? userRole
              : mapKiwayRole[userRole]
            : userRole
        )
      ) {
        return true;
      }
    }
    // ELSE USE LEGACY
    const userRoles = this.config.roleHierarchy.find(
      ({ role: roleHierarchy }) => roleHierarchy === userRole
    );
    if (!userRoles) {
      return false;
    }
    return userRoles.extends.includes(targetRole);
  };

  hasAccess = (options: AccessControlOptions): boolean => {
    const { route, roles, servicesList } = options;
    if (!roles || !route?.role) {
      return this.hasAccessLegacy(options);
    }
    if (!route.private) {
      return true;
    }
    if (isGranted(route.role, roles)) {
      if (route.service) {
        return servicesList?.includes(route.service);
      }
      return true;
    }
    return false;
  };

  hasAccessLegacy = (options: AccessControlOptions): boolean => {
    const { resource, action, role, servicesList } = options;
    if (!resource) {
      // Resource is not defined
      return false;
    }
    if (this.config.publicResources.includes(resource)) {
      // Resource is public
      return true;
    }
    if (!role) {
      // No role provided, resource is not public so we return false
      return false;
    }
    const privateResource = this.config.privateResources.find(
      ({ name }) => name === resource
    );
    if (!privateResource) {
      // Resource does not exist
      return false;
    }
    const userRoles = this.config.roleHierarchy.find(
      ({ role: roleHierarchy }) =>
        roleHierarchy === role ||
        (Array.isArray(role) && role.includes(roleHierarchy))
    );
    if (!userRoles) {
      // User role does not exist
      return false;
    }
    const rightObject = privateResource.rights.find(({ role: rightRole }) =>
      userRoles.extends.includes(rightRole)
    );
    if (!rightObject) {
      // User has not sufficient rights
      return false;
    }
    if (!rightObject.actions && !privateResource.service) {
      // Only role is required and role is OK
      return true;
    }
    if (rightObject.actions && privateResource.service) {
      if (
        action &&
        servicesList &&
        rightObject.actions.includes(action) &&
        servicesList.split(',').includes(privateResource.service)
      ) {
        // Role, action and service are OK
        return true;
      }
    } else {
      if (
        rightObject.actions &&
        action &&
        rightObject.actions.includes(action)
      ) {
        // Role and action are OK
        return true;
      }
      if (
        privateResource.service &&
        servicesList &&
        servicesList.split(',').includes(privateResource.service)
      ) {
        // Role and service are OK
        return true;
      }
    }
    // No match, for security reason we return false
    return false;
  };

  hasAccessMiddleware = (
    getOptions: (req?: any) => AccessControlOptions,
    errorCallback: () => Error
  ) => (req: any, res: any, next: (error?: Error) => any) => {
    if (this.hasAccess(getOptions(req))) {
      next();
    } else {
      return next(errorCallback());
    }
  };
}

export * from './roles';

export type RoleType = typeof roles[number];

function isGrantedRecursive(
  mainRoles: Array<RoleType | string>,
  scopedRole: RoleType | string,
  firstLevelRole: RoleType | string,
  subRoles: Array<RoleType | string>,
  userRoles: Array<RoleType | string>
): boolean {
  if (subRoles?.includes(scopedRole)) {
    if (userRoles?.includes(firstLevelRole as RoleType | string)) {
      return true;
    }
  } else {
    const intersect: Array<string> = mainRoles.filter((value: any) => {
      return subRoles.includes(value);
    });
    if (!intersect.length) {
      return false;
    }
    return intersect.reduce((prev, current) => {
      return (
        prev ||
        isGrantedRecursive(
          mainRoles,
          scopedRole,
          firstLevelRole,
          role_hierarchy[current],
          userRoles
        )
      );
    }, false);
  }
}

export function isGranted(
  scopedRole: RoleType | string,
  userRoles: Array<RoleType | string>
): boolean {
  if (userRoles?.includes(scopedRole)) {
    return true;
  }
  const mainRoles: Array<string> = Object.keys(role_hierarchy);

  // Get first level array of role
  // For each role, check subRoles if intersect with userRoles
  // If one of subRoles is also one of first level role, check in deeper
  let cpt = 0;
  while (cpt < mainRoles.length) {
    const firstLevelRole = mainRoles[cpt];
    const subRoles = role_hierarchy[firstLevelRole];
    if (
      isGrantedRecursive(
        mainRoles as Array<RoleType>,
        scopedRole,
        firstLevelRole as RoleType,
        subRoles,
        userRoles
      )
    ) {
      return true;
    }
    cpt++;
  }
  return false;
}

export const getOldRoleFromFARoles = (roles: RoleType[]): string => {
  if (roles.includes('ROLE_ADMIN')) {
    return 'admin';
  }
  if (roles.includes('ROLE_ADMIN_WHOLESALER')) {
    return 'wholesalerAdmin';
  }
  if (roles.includes('ROLE_ORDER_PICKER')) {
    return 'orderPicker';
  }
  if (roles.includes('ROLE_PARTNER')) {
    return 'partner';
  }
  if (roles.includes('ROLE_PRACTITIONER_PHARMACO')) {
    return 'pharmaco';
  }
  if (roles.includes('ROLE_PRACTITIONER')) {
    return 'practitioner';
  }
  if (roles.includes('ROLE_PATIENT')) {
    return 'patient';
  }
  if (roles.includes('ROLE_USER')) {
    return 'user';
  }
  return 'noAuth';
};
