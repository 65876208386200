import * as React from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Price } from '@kiway/ecommerce-react';
import {
  FormulaPowderNav,
  FormulaPowderBody,
} from './FormulaType/FormulaPowder';
import { FormulaPlantNav, FormulaPlantBody } from './FormulaType/FormulaPlant';
import { PharmacoOrderFormula } from '../../../core/entities/PharmacoOrder';

export interface FormulaProps {
  formula: PharmacoOrderFormula;
  rows: any[];
  totalPercent: string;
  totalPrice: Price;
  totalQuantity: number;
  handleChangeDosage: (...args: any[]) => (...args: any[]) => any;
  handleChangeDosageMode: (formulaId: string, value: 'PS' | 'CP') => void;
  handleChangeIsSeparated: (...args: any[]) => (...args: any[]) => any;
  handleSaveOrder: (...args: any[]) => any;
  handleRemoveLineItem: (lineItemId: string) => any;
  getRoundedItemTotalWeight: (itemId: string) => number;
  getRoundedItemPortionWeight: (
    itemId: string,
    includeSimplifiedDecoction?: boolean
  ) => number;
}

export interface IArrayData {
  title: string;
  lines: ILines[];
}

interface ILines {
  label: string;
  value: string | number;
  type: 'weight' | 'weightPerTaken' | 'dayTakes' | 'treatmentDuration';
  availableValues?: string[];
}

export function Formula(props: FormulaProps) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer component={Paper} elevation={1}>
          <Table size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#EAE9E9' }}>
                {props.formula?.getPlantPowderType() === 'powder' ? (
                  <FormulaPowderNav
                    isCapsule={
                      props.formula.getPharmaceuticalForm() === 'capsule'
                    }
                    dosageMode={props.formula.getDosageMode()}
                    formulaId={props.formula.getGroupBy()}
                    handleChangeDosageMode={props.handleChangeDosageMode}
                  />
                ) : (
                  <FormulaPlantNav
                    isCapsule={
                      props.formula.getPharmaceuticalForm() === 'capsule'
                    }
                  />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.formula?.getPlantPowderType() === 'powder' ? (
                <FormulaPowderBody
                  rows={props.rows}
                  handleRemoveLineItem={props.handleRemoveLineItem}
                  handleChangeDosage={props.handleChangeDosage(props.formula)}
                  handleSaveDosage={props.handleSaveOrder}
                  isCapsule={
                    props.formula.getPharmaceuticalForm() === 'capsule'
                  }
                  totalPercent={props.totalPercent}
                  totalWeight={props.formula.getTotalWeight()}
                  portionWeight={props.formula.getPortionWeight()}
                  totalPrice={props.formula.getTotalPrice()}
                  available={props.formula?.isFormulaAvailable()}
                  equivalentCP={props.formula?.getTotalEquivalentCP()}
                  equivalentPS={props.formula?.getTotalEquivalentPS()}
                  dosageMode={props.formula?.getDosageMode()}
                  totalDosage={props.formula?.getTotalDosage()}
                  getRoundedItemTotalWeight={props.getRoundedItemTotalWeight}
                  getRoundedItemPortionWeight={
                    props.getRoundedItemPortionWeight
                  }
                />
              ) : (
                <FormulaPlantBody
                  rows={props.rows}
                  handleRemoveLineItem={props.handleRemoveLineItem}
                  handleChangeDosage={props.handleChangeDosage(props.formula)}
                  handleChangeIsSeparated={props.handleChangeIsSeparated}
                  handleSaveDosage={props.handleSaveOrder}
                  isCapsule={
                    props.formula.getPharmaceuticalForm() === 'capsule'
                  }
                  totalPercent={props.totalPercent}
                  totalWeight={props.formula.getTotalWeight()}
                  portionWeight={Number(
                    props.formula.getPortionWeight(true)?.toFixed(1)
                  )}
                  totalPrice={props.formula.getTotalPrice()}
                  available={props.formula?.isFormulaAvailable()}
                  totalDosage={props.formula?.getTotalDosage()}
                  getRoundedItemTotalWeight={props.getRoundedItemTotalWeight}
                  getRoundedItemPortionWeight={
                    props.getRoundedItemPortionWeight
                  }
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
