import * as React from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link as MuiLink,
  Tooltip,
  Typography,
} from '@mui/material';
import { OrderPreparationFormula } from './OrderPreparationFormula';
import PrintIcon from '@mui/icons-material/Print';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Link, Redirect } from 'react-router-dom';
import { links } from '../../../config';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Order } from '@kiway/ecommerce-react-compatible';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MobileSafeArea } from '@kiway/shared/ui';
import { PharmacoOrder } from '../../../core/entities/PharmacoOrder';

interface OrderPreparationProps {
  handleClickFinish: () => void;
  handlePrepareClick: (itemId: string) => void;
  loading: boolean;
  ready: boolean;
  order: Order;
  pharmacoOrder: PharmacoOrder;
  handlePodPreparedChange: (formulaId: string) => void;
}

export function OrderPreparation({
  handleClickFinish,
  handlePrepareClick,
  loading,
  ready,
  order,
  pharmacoOrder,
  handlePodPreparedChange,
}: OrderPreparationProps) {
  if (!order) {
    return null;
  }

  const { t } = useTranslation();

  const getFormulaItems = (formula) => {
    const items = [];
    order.getLineItemsByGroup(formula).map((lineItem) => {
      items.push({
        item: lineItem,
        prepared: order.getCustom()?.preparedItems?.[lineItem?.getId()],
      });
    });
    return items;
  };

  return !order.getStartPreparationAt() ? (
    <>
      <Redirect to={links.getOrderDetailsScreen(order.getId())} />
    </>
  ) : ready ? (
    <MobileSafeArea>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Button
            size={'small'}
            color={'inherit'}
            component={Link}
            to={`${links.getOrderDetailsScreen(order.getId())}`}
          >
            {t('pharmaco:backToOrderDetails')}
          </Button>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Typography variant="h4" align="center">
            {t(`pharmaco:prescription.order.label`)} n°
            {order?.getOrderNumber()}
          </Typography>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h5" align="center">
            {t(`pharmaco:orderDetails.packing.label`)}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'lightblue',
            borderRadius: '5px',
            mt: 2,
            mb: 2,
            p: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography fontWeight="bold">
            {t('pharmaco:orderDetails.comment.label')} :
          </Typography>
          <Typography variant="subtitle1" fontStyle="italic" sx={{ ml: 1 }}>
            {order?.getCustom()?.['preparerComment']
              ? order?.getCustom()?.['preparerComment']
              : t('pharmaco:orderDetails.comment.none')}
          </Typography>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Grid container spacing={2}>
            {pharmacoOrder &&
              pharmacoOrder.getFormulas().map((formula) => (
                <Grid item xs={12} key={formula.getGroupBy()}>
                  <Grid container alignItems="center">
                    <Grid item xs={11}>
                      <OrderPreparationFormula
                        loading={loading}
                        formula={formula}
                        getRoundedItemWeight={(id) =>
                          formula.getRoundedItemQuantity(id)
                        }
                        getRoundedItemPortionWeight={(id) => {
                          return formula.getRoundedItemPortionWeight(id, true);
                        }}
                        handlePrepareClick={handlePrepareClick}
                        items={getFormulaItems(formula.getGroupBy())}
                        totalWeight={Number(
                          formula.getTotalWeight().toFixed(1)
                        )}
                        portionWeight={Number(
                          formula.getPortionWeight(true).toFixed(1)
                        )}
                        variationType={formula
                          .getIngredients()[0]
                          .getLineItem()
                          .getProductVariation()
                          .getProductVariationType()}
                        nbOfBags={formula.getNbOfBags(true)}
                        handlePodPreparedChange={handlePodPreparedChange}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sx={{
                        textAlign: 'center',
                        marginLeft: -2,
                      }}
                    >
                      <Tooltip title={t('pharmaco:formula.print')}>
                        <IconButton
                          component={MuiLink}
                          href={`https://${
                            process.env['NX_API_URL']
                          }/order/${order.getId()}/label/${formula.getGroupBy()}/pdf`}
                          target="_blank"
                          disabled={loading}
                        >
                          <PrintIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Box>
        <Box sx={{ flex: 1 }}></Box>
        <Box sx={{ width: '100%', pt: 4, pb: 4 }}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                disabled={loading || !pharmacoOrder.canFinishPreparation()}
                onClick={handleClickFinish}
                variant="contained"
                color="minoritary"
              >
                {t('pharmaco:orderDetails.order.finishPreparation')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </MobileSafeArea>
  ) : null;
}
