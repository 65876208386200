import * as React from 'react';
import { MenuBuilder } from '@kiway/menu-builder';
import { useTranslation } from '@kiway/shared/utils/translation';
import { RoutingBuilder } from '@kiway/routing-builder';
import { KiwayLanguagesType } from '@kiway/shared/react-types';
import config from '../config';

function registerEcommerce() {
  // REGISTER ECOMMERCE MENU
  const builder = MenuBuilder.getBuilder('main');
  Object.values(config.menus?.['main'] || {}).map((menu) => {
    builder.addItem({
      ...menu,
      icon: <menu.icon fontSize="medium" />,
    });
  });

  // REGISTER ECOMMERCE TRANSLATIONS
  const { addTranslationDynamically } = useTranslation();
  Object.entries(config.translations).map(([lng, translation]) => {
    addTranslationDynamically(
      lng as KiwayLanguagesType,
      config.namespace,
      translation,
      true
    );
  });

  // REGISTER ROUTING
  const routing = RoutingBuilder.getBuilder();
  Object.values(config.routes).map((route) => {
    routing.addRoute(route);
  });
}

export function FeatureEcommerce() {
  registerEcommerce();
  return <></>;
}

export * from './components';
