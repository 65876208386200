export default {
  and: 'y',
  browserDefaults: 'Por defecto',
  chips: {
    app: 'Aplicación',
    pro: 'Pro',
    proFeature: 'Servicio pagado',
    proTrial: 'Pro - Prueba en progreso',
    student: 'Alumno',
  },
  comingSoon: 'Próximamente ! 😉',
  connectWith: 'Conexión con',
  controlButtons: {
    finish: 'Terminar',
    later: 'Más tarde',
    next: 'Próximo',
    previous: 'Anterior',
    skip: 'Pasar',
  },
  copied: 'Copiado en el portapapeles',
  default: 'defecto',
  fileSize: {
    byte: 'bytes',
    giga: 'GB',
    kilo: 'KB',
    mega: 'MB',
  },
  form: {
    buttons: {
      cancel: 'anular',
      charge: 'Facturar',
      confirm: 'Confirmar',
      delete: 'Borrar',
      download: 'Descargar',
      edit: 'Editar',
      filter: 'Filtrar',
      loadMore: 'Ver más',
      logout: 'Desconexión',
      save: 'Registro',
      send: 'enviar a',
      setDefault: 'Definir por defecto',
    },
    errors: {
      tooLongString: 'Máximo %X% caracteres',
      tooShortString: 'Mínimo %X% caracteres',
    },
    fields: {
      address: {
        label: 'Dirección',
      },
      addressComplement: {
        label: 'Complemento de dirección',
      },
      CGU: {
        label: `Al tachar este cuadro, certifico haber leído y aceptado sin reservar el [CGU](${process.env['NX_CGU_URL']}), la [Carta de uso de cookies](${process.env['NX_COOKIES_POLICY_URL']}), así como la [Política de privacidad de datos de CIAM](${process.env['NX_DATA_POLICY_URL']}) *`,
      },
      chiefComplaint: {
        label: 'Razón de consulta',
      },
      comment: {
        label: 'Observación',
      },
      consultationPlace: {
        label: 'Ubicación de consulta',
      },
      email: {
        label: 'Correo electrónico',
      },
      endDate: {
        label: 'Fecha final',
      },
      endHour: {
        label: 'Hora de finalización',
      },
      GDPR: {
        label: `Al marcar esta casilla, consiento el procesamiento de mis datos personales según lo planeado en la [Política de privacidad](${process.env['NX_PRIVACY_POLICY_URL']}) y de acuerdo con el RGDP *`,
      },
      gender: {
        default: 'No',
        female: 'Femenino',
        male: 'Masculino',
        other: 'Otro',
      },
      label: {
        label: 'apellido',
      },
      lastname: {
        label: 'apellido',
      },
      patient: {
        global: {
          label: 'Paciente',
        },
      },
      phone: {
        areaCodeInfo: 'Debe especificar el indicativo',
        country: 'Código de país',
        error: {
          possibility: {
            default: 'Debe ingresar un número de teléfono válido',
            'invalid-country-code':
              'Debe ingresar un indicativo válido (+33 para Francia, +351 para Portugal)',
            'is-possible': 'El número de teléfono parece ser posible',
            'too-long': 'El número de teléfono parece ser demasiado largo',
            'too-short': 'El número de teléfono parece ser demasiado corto',
            unknown: 'Debe ingresar un número de teléfono válido',
            wrongRegionCode: 'Debe ingresar un número local',
          },
          specificType: {
            'fixed-line': 'Debe ingresar un número de teléfono fijo',
            'fixed-line-or-mobile':
              'Debe ingresar un número de teléfono fijo o portátil',
            mobile: 'Debe ingresar un número de teléfono móvil',
          },
        },
        errorNotValid: 'Ingrese un número de teléfono válido',
        errorNotValidOrNotMobile: 'Informar un número móvil válido',
        label: 'Teléfono',
        mobileLabel: 'Portátil',
      },
      startDate: {
        label: 'Fecha de inicio',
      },
      startHour: {
        label: 'Hora de inicio',
      },
      title: {
        label: 'Título',
      },
      yourMessage: {
        label: 'vuestro mensaje',
      },
    },
    uploads: {
      dragNDrop: {
        accept: 'Pon tus archivos aquí',
        label: 'Deslice sus archivos aquí o haga clic para seleccionarlos',
        reject: 'Los archivos no cumplen con las condiciones',
        snackbars: {
          error: {
            invalidType: 'No está permitido el tipo de archivo',
            maxFilesNumber: 'No puede descargar más archivos %maxFilesNumber%.',
            multipleFalse:
              'Solo se puede descargar un archivo. Elimine el que ya está presente para descargar un nuevo',
            tooLarge: 'El tamaño del archivo no debe exceder',
            unique: 'Ya existe un archivo con el mismo nombre',
          },
        },
      },
    },
  },
  from: 'de',
  fromDate: 'De',
  ht: 'Sin impuestos',
  httpCodes: {
    buttons: {
      goBack: 'Volver',
      goHome: 'Pagina Incial',
    },
    error404: {
      content: 'No pudimos encontrar la página que está buscando.',
      title: 'Ups !',
    },
  },
  legalDocuments: 'Documentos legales',
  loading: 'Cargando...',
  menuToolbar: {
    chatbox: 'Chatbox',
    faq: 'FAQ',
    serviceDesk: 'Danos tus comentarios',
    tutorials: 'Tutoriales',
  },
  no: 'No',
  noLoggedIn: 'Parece que está desconectado, identifica nuevamente.',
  noOptionsText: 'Sin propuesta',
  noResult: 'ningún resultado',
  of: 'en',
  or: 'O',
  payment: {
    buttons: {
      validateCreditCard: 'Validar la tarjeta',
    },
    labels: {
      billingCountry: 'País de facturación',
      creditCard: 'Tarjeta de crédito',
      name: 'apellido',
    },
    messages: {
      cardAccepted: 'Tarjeta bancaria aceptada',
    },
  },
  practices: {
    acupressure: 'Acupresión',
    acupuncture: 'Acupuntura',
    error: 'Elija al menos uno',
    other: 'Otro',
    otherStr: 'Especificar',
    pharmacopoeia: 'Farmacopea',
    title: '¿Qué practicas?',
    tuinaMassage: 'Masaje de tuina',
  },
  readOnly: 'Solo lectura',
  redirectPending: 'Serás redirigido',
  to: 'para ',
  toDate: 'A',
  tourGuide: {
    demoButton: 'Ver la demostración',
    description: {
      agenda: 'Descripción de la agenda',
      dashboard: 'Descripción Panel',
      files: 'Archivos de descripción',
      invoicing: 'Descripción facturas',
      pharmaco: 'Descripción farmacopea',
      services: 'Servicios de descripción',
      settings: 'Descripción Configuración',
    },
    name: {
      agenda: 'Diario',
      dashboard: 'Salpicadero',
      files: 'Archivos',
      invoicing: 'Facturas',
      pharmaco: 'Farmacopea',
      services: 'Servicios',
      settings: 'configuraciones',
    },
    subSection: {
      description: {
        addPatientDescription: 'Descubra cómo añadir un paciente.',
        agendaGeneralDescription:
          'La página de la Agenda le permitirá planificar las citas y gestionarlas. De un vistazo, sabrás de qué está hecho tu día.',
        agendaSettingsDescription:
          'La página de la Agenda se puede personalizar para adaptarla a sus necesidades.',
        dashboardGeneralDescription:
          'El panel de control es la página de inicio del sitio. Encontrará de un vistazo las citas del día, la relación entre el número de sesiones completadas y el número de sesiones programadas, los créditos SMS, etc.',
        filesGeneralDescription:
          'En Archivos, tendrá una visión general de sus pacientes y alguna información sobre ellos.',
        invoicingGeneralDescription:
          'Aquí encontrará una lista de todas las facturas emitidas, su importe, la fecha de emisión, etc.',
        patientInfoDescription:
          'Para más detalles, consulte esta página, encontrará información sobre sus pacientes: direcciones, pedidos, historial, etc.',
        pharmacoGeneralDescription:
          'Desde esta página, puede crear y seguir sus pedidos.',
        removePatientDescription: 'Descubra como eliminar um paciente.',
        servicesGeneralDescription:
          'Aquí es donde encontrarás las opciones de pago/servicios del software.',
        settingsActivityDescription:
          'Realiza la configuración de tus actividades.',
        settingsAgendaDescription:
          'Podrá establecer semanas típicas para las citas en línea.',
        settingsGeneralDescription:
          'La página de configuración le permitirá completar la personalización de su interfaz. Es tu herramienta: puedes configurarla como quieras.',
        settingsInvoicingDescription:
          'Toda la información de facturación de sus servicios debe introducirse desde esta página.',
        settingsPublicPageDescription:
          '¿Qué aspecto tiene tu página pública? En esta página, tú decides.',
        settingsWorkplacesDescription:
          'Realice sus ajustes para sus lugares de Cita.',
      },
      title: {
        addPatient: 'Agrega un paciente',
        agendaGeneral: 'Agenda',
        agendaSettings: 'Configuración de la agenda',
        dashboardGeneral: 'Salpicadero',
        filesGeneral: 'Archivos',
        invoicingGeneral: 'Facturación',
        patientInfo: 'Hoja de paciente',
        pharmacoGeneral: 'Farmacopea',
        removePatient: 'Eliminar a un paciente',
        servicesGeneral: 'Servicios',
        settingsActivity: 'Configuración relacionada con la actividad',
        settingsAgenda: 'Configuración de la agenda',
        settingsGeneral: 'configuraciones',
        settingsInvoicing: 'Parámetros de facturación',
        settingsPublicPage: 'Parámetros de la página pública',
        settingsWorkplaces: 'Parámetros de los lugares de consulta',
      },
    },
    title: 'Tutoriales',
  },
  ttc: 'impuestos incluidos',
  VAT: 'IVA',
  welcome: 'Bienvenido',
  yes: 'sí',
};
