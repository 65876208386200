import { useTranslation } from '@kiway/shared/utils/translation';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  InputAdornment,
  IconButton,
} from '@mui/material';
import * as React from 'react';
import { ProductMetadata } from '../../../core/entities/ProductMetadata';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';
import CloseIcon from '@mui/icons-material/Close';

interface SelectMetadataProps {
  handleChange: (
    type: 'formula' | 'ingredient',
    event: SelectChangeEvent,
    subtype?: string
  ) => void;
  selectedMetadataId: string;
  filteredMetadatas: Array<ProductMetadata>;
  type: 'formula' | 'ingredient';
  subtype?: string;
}

export function SelectMetadata(props: SelectMetadataProps) {
  const { currentLanguage, t } = useTranslation();
  const placeholder = props.subtype
    ? t(`pharmaco:filters.metadatas.${props.subtype}`)
    : props.type === 'formula'
    ? t('pharmaco:filters.metadatas.formulasCategory')
    : t('pharmaco:filters.metadatas.ingredientsCategory');

  return (
    <FormControl size="small" fullWidth sx={{ maxWidth: '1000px' }}>
      <InputLabel>{placeholder}</InputLabel>
      <Select
        value={props.selectedMetadataId ?? ''}
        onChange={(e) => props.handleChange(props.type, e, props.subtype)}
        label={placeholder}
        endAdornment={
          props.selectedMetadataId !== '' ? (
            <InputAdornment position="end">
              <IconButton
                onClick={() =>
                  props.handleChange(
                    props.type,
                    {
                      target: { value: '' },
                    } as any,
                    props.subtype
                  )
                }
                sx={{ mr: 1 }}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : null
        }
      >
        <MenuItem value="" sx={{ maxWidth: '1000px' }}>
          <em>{placeholder}</em>
        </MenuItem>
        {props.filteredMetadatas.map((metadata) => {
          return (
            <MenuItem
              key={metadata.getId()}
              value={metadata?.getId()}
              sx={{ maxWidth: '1000px', whiteSpace: 'break-spaces' }}
            >
              {
                metadata.getName()?.[
                  currentLanguage?.code ?? getFallbackLanguage()
                ]
              }
            </MenuItem>
          );
        })}
        ;
      </Select>
    </FormControl>
  );
}
