import {
  TranslatableAttribute,
  kiwayLanguages,
} from '@kiway/shared/react-types';
import { Country } from './Country';
import { IPriceAttributes, Price, TaxedItemPrice, TaxRate } from './Order';

export interface IProductVariationAttributeAttributes {
  displayName: TranslatableAttribute;
  name: string;
}

export class ProductVariationAttribute {
  protected id: string;
  protected displayName: TranslatableAttribute;
  protected name: string;

  public getId(): string {
    return this.id;
  }

  public setId(id: string): ProductVariationAttribute {
    this.id = id;
    return this;
  }

  public getDisplayName(): TranslatableAttribute {
    return this.displayName;
  }

  public setDisplayName(
    displayName: TranslatableAttribute
  ): ProductVariationAttribute {
    this.displayName = displayName;
    return this;
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string): void {
    this.name = name;
  }

  constructor(
    obj: Partial<IProductVariationAttributeAttributes> & {
      _id?: string;
      id?: string;
    }
  ) {
    this.id = obj?.id || obj?._id;
    this.displayName = obj?.displayName;
    this.name = obj?.name;
  }

  public toJSON(): any {
    return {
      _id: this.getId(),
      id: this.getId(),
      displayName: this.getDisplayName(),
      name: this.getName(),
    };
  }
}

export interface IProductVariationAttributeValueAttributes {
  name: TranslatableAttribute;
  value: string | number;
  variationAttribute: IProductVariationAttributeAttributes;
}

export class ProductVariationAttributeValue {
  protected id: string;
  protected name: TranslatableAttribute;
  protected value: string | number;
  protected variationAttribute: ProductVariationAttribute;

  public getId(): string {
    return this.id;
  }

  public setId(id: string): ProductVariationAttributeValue {
    this.id = id;
    return this;
  }

  public getName(): TranslatableAttribute {
    return this.name;
  }

  public setName(name: TranslatableAttribute): ProductVariationAttributeValue {
    this.name = name;
    return this;
  }

  public getValue(): string | number {
    return this.value;
  }

  public setValue(value: string | number): ProductVariationAttributeValue {
    this.value = value;
    return this;
  }

  public getVariationAttribute(): ProductVariationAttribute {
    return this.variationAttribute;
  }

  public setVariationAttribute(
    variationAttribute: ProductVariationAttribute
  ): ProductVariationAttributeValue {
    this.variationAttribute = variationAttribute;
    return this;
  }

  constructor(
    obj: Partial<IProductVariationAttributeValueAttributes> & {
      _id?: string;
      id?: string;
    }
  ) {
    this.id = obj?.id || obj?._id;
    this.name = obj?.name;
    this.value = obj?.value;
    this.variationAttribute = new ProductVariationAttribute(
      obj?.variationAttribute
    );
  }

  public isConcentratedPowder(): boolean {
    return (
      this.variationAttribute.getName() === 'plantPowderType' &&
      this.value === 'powder'
    );
  }

  public isPlant(): boolean {
    return (
      this.variationAttribute.getName() === 'plantPowderType' &&
      this.value === 'plant'
    );
  }

  public toJSON(): any {
    return {
      _id: this.getId(),
      id: this.getId(),
      name: this.getName(),
      value: this.getValue(),
      variationAttribute: this.getVariationAttribute()?.toJSON(),
    };
  }
}

export interface IProductVariationTypeAttributes {
  name: TranslatableAttribute;
  shortcode: string;
  price: Price | IPriceAttributes | string;
  variationAttributesValue: IProductVariationAttributeValueAttributes[];
}

export class ProductVariationType {
  protected id: string;
  protected name: TranslatableAttribute;
  protected shortcode: string;
  protected price: Price;
  protected variationAttributesValue: ProductVariationAttributeValue[];

  public getId(): string {
    return this.id;
  }

  public setId(id: string): ProductVariationType {
    this.id = id;
    return this;
  }

  public getName(): TranslatableAttribute {
    return this.name;
  }

  public setName(name: TranslatableAttribute): ProductVariationType {
    this.name = name;
    return this;
  }

  public getShortcode(): string {
    return this.shortcode;
  }

  public setShortcode(shortcode: string): ProductVariationType {
    this.shortcode = shortcode;
    return this;
  }

  public getPrice(): Price {
    return this.price;
  }

  public setPrice(price: Price): ProductVariationType {
    this.price = price;
    return this;
  }

  public getVariationAttributesValue(): ProductVariationAttributeValue[] {
    return this.variationAttributesValue;
  }

  public setVariationAttributesValue(
    variationAttributesValue: ProductVariationAttributeValue[]
  ): ProductVariationType {
    this.variationAttributesValue = variationAttributesValue;
    return this;
  }

  constructor(
    obj: Partial<IProductVariationTypeAttributes> & {
      _id?: string;
      id?: string;
    }
  ) {
    this.id = obj?.id || obj?._id;
    this.name = obj?.name;
    this.shortcode = obj?.shortcode;
    if (obj?.price instanceof Price) {
      this.price = obj?.price;
    } else if (typeof obj?.price === 'string') {
      this.price = new Price({ id: obj?.price });
    } else {
      this.price = new Price(obj?.price);
    }
    this.variationAttributesValue = obj?.variationAttributesValue?.map(
      (item) => new ProductVariationAttributeValue(item)
    );
  }

  public isConcentratedPowder(): boolean {
    return this.variationAttributesValue?.reduce(
      (prev: boolean, current: ProductVariationAttributeValue) =>
        current.isConcentratedPowder() || prev,
      false
    );
  }

  public isPlant(): boolean {
    return this.variationAttributesValue?.reduce(
      (prev: boolean, current: ProductVariationAttributeValue) =>
        current.isPlant() || prev,
      false
    );
  }

  public toJSON(): any {
    return {
      _id: this.getId(),
      id: this.getId(),
      name: this.getName(),
      shortcode: this.getShortcode(),
      price: this.getPrice()?.toJSON(),
      variationAttributesValue: this.getVariationAttributesValue()?.map(
        (item) => item.toJSON()
      ),
    };
  }
}

export interface IProductVariationAttributes {
  price?: Price | IPriceAttributes | string;
  product: Product | IProductAttributes | string;
  productVariationType:
    | ProductVariationType
    | IProductVariationTypeAttributes
    | string;
  productRef: string;
  model?: string;
  weight?: number;
  available: boolean;
}

export class ProductVariation {
  protected id: string;
  protected price: Price;
  protected product: Product;
  protected productVariationType: ProductVariationType;
  protected productRef: string;
  protected model: string; // use for mongoose refPath (allow us to use several Product models (e.g plant, formula, etc.))
  protected weight: number;
  protected available: boolean;

  public isAvailable(): boolean {
    return this.available;
  }

  public setAvailable(available: boolean): ProductVariation {
    this.available = available;
    return this;
  }

  public getWeight(): number {
    return this.weight;
  }

  public setWeight(weight: number): ProductVariation {
    this.weight = weight;
    return this;
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): ProductVariation {
    this.id = id;
    return this;
  }

  // unit price
  public getPrice(): Price {
    return this.price;
  }

  public setPrice(price: Price): ProductVariation {
    this.price = price;
    return this;
  }

  public getProduct(): Product {
    return this.product;
  }

  public setProduct(product: Product): ProductVariation {
    this.product = product;
    return this;
  }

  public getProductVariationType(): ProductVariationType {
    return this.productVariationType;
  }

  public setProductVariationType(
    productVariationType: ProductVariationType
  ): ProductVariation {
    this.productVariationType = productVariationType;
    return this;
  }

  public getProductRef(): string {
    return this.productRef;
  }

  public setProductRef(productRef: string): ProductVariation {
    this.productRef = productRef;
    return this;
  }

  public getModel(): string {
    return this.model;
  }

  public setModel(model: string): ProductVariation {
    this.model = model;
    return this;
  }

  constructor(
    obj: Partial<IProductVariationAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    if (obj?.price instanceof Price) {
      this.price = obj?.price;
    } else if (typeof obj?.price === 'string') {
      this.price = new Price({ id: obj?.price }, 1);
    } else {
      this.price = obj?.price ? new Price(obj?.price, 1) : null;
    }
    if (obj?.productVariationType instanceof ProductVariationType) {
      this.productVariationType = obj?.productVariationType;
    } else if (typeof obj?.productVariationType === 'string') {
      this.productVariationType = new ProductVariationType({
        id: obj?.productVariationType,
      });
    } else {
      this.productVariationType = new ProductVariationType(
        obj?.productVariationType
      );
    }
    if (obj?.product instanceof Product) {
      this.product = obj?.product;
    } else if (typeof obj?.product === 'string') {
      this.product = new Product({
        id: obj?.product,
      });
    } else {
      this.product = new Product(obj?.product);
    }
    this.productRef = obj?.productRef;
    this.model = obj?.model;
    this.weight = obj?.weight;
    this.available = obj?.available;
  }

  public getBasePrice(): Price {
    return this.getProductVariationType()?.getPrice();
  }

  public isConcentratedPowder(): boolean {
    return this.productVariationType?.isConcentratedPowder();
  }

  public isPlant(): boolean {
    return this.productVariationType?.isPlant();
  }

  public toJSON(): any {
    return {
      _id: this.getId(),
      id: this.getId(),
      price: this.getPrice?.()?.toJSON(),
      product: this.getProduct()?.toJSON(),
      productRef: this.getProductRef(),
      productVariationType: this.getProductVariationType()?.toJSON(),
      model: this.getModel(),
      weight: this.getWeight(),
    };
  }

  getName(): TranslatableAttribute {
    const variationName = this.productVariationType.getName();
    return Object.entries(this.product?.getName() || {})
      ?.map(([lng, translation]) => [
        lng,
        `${translation} ${variationName ? `(${variationName[lng]})` : ''}`,
      ])
      ?.reduce(
        (prev, [lng, translation]) => ({ ...prev, [lng]: translation }),
        {} as TranslatableAttribute
      );
  }

  private getMaxPrice(a: Price, b: Price): Price {
    const aPrice = a instanceof Price ? a : new Price(a);
    const bPrice = b instanceof Price ? b : new Price(b);
    if (aPrice && bPrice) {
      if (aPrice.getCentAmount() > bPrice.getCentAmount()) {
        return aPrice;
      } else {
        return bPrice;
      }
    } else {
      if (aPrice && !bPrice) {
        return aPrice;
      } else if (!aPrice && bPrice) {
        return bPrice;
      } else {
        return null;
      }
    }
  }

  // Retourne le prix max entre les prix produit, variation et variationType
  getPublicPrice(): Price {
    const productPrice = this.product?.getPrice();
    const variationTypePrice = this.productVariationType?.getPrice();
    const variationPrice = this.price;
    if (productPrice) {
      return this.getMaxPrice(
        productPrice,
        this.getMaxPrice(variationPrice, variationTypePrice)
      );
    } else if (variationPrice) {
      return this.getMaxPrice(variationPrice, variationTypePrice);
    }
    return variationTypePrice;
  }

  // price x quantity with or without tax
  getTaxedPrice(quantity: number, country: Country): TaxedItemPrice {
    return {
      totalNet: this.getTotalNetPrice(quantity, country),
      totalGross: this.getTotalGrossPrice(quantity),
    };
  }

  getTotalNetPrice(quantity: number, country: Country): Price {
    let taxAmount = 0;
    if (this.isPlant()) {
      taxAmount = this.getPlantTaxAmount(quantity, country)?.getCentAmount();
    } else if (this.isConcentratedPowder()) {
      taxAmount = this.getPowderTaxAmount(quantity, country)?.getCentAmount();
    }
    return new Price({
      currency: this.getPublicPrice().getCurrency(),
      centAmount: this.getPublicPrice().getCentAmount() * quantity + taxAmount,
    });
  }

  getTaxes(
    quantity: number,
    country: Country
  ): Array<{
    taxPercent: number;
    taxAmount: number;
    taxType: 'reduce' | 'normal';
    taxCountryCode: string;
  }> {
    if (country && this.isPlant() && country.isVatPlantDefined()) {
      return [
        {
          taxPercent: country.getVatPlant(),
          taxAmount: Math.round(
            this.getPublicPrice().getCentAmount() *
              quantity *
              (country.getVatPlant() / 100)
          ),
          taxType: 'reduce',
          taxCountryCode: country.getCode(),
        },
      ];
    }
    if (
      country &&
      this.isConcentratedPowder() &&
      country.isVatPowderDefined()
    ) {
      return [
        {
          taxPercent: country.getVatPowder(),
          taxAmount: Math.round(
            this.getPublicPrice().getCentAmount() *
              quantity *
              (country.getVatPowder() / 100)
          ),
          taxType: 'normal',
          taxCountryCode: country.getCode(),
        },
      ];
    }
    return [];
  }

  getPlantTaxAmount(quantity: number, country: Country): Price {
    if (country && this.isPlant() && country.isVatPlantDefined()) {
      return new Price({
        currency: this.getPublicPrice().getCurrency(),
        centAmount:
          this.getPublicPrice().getCentAmount() *
          quantity *
          (country.getVatPlant() / 100),
      });
    }
    return new Price({
      currency: this.getPublicPrice().getCurrency(),
      centAmount: 0,
    });
  }

  getPowderTaxAmount(quantity: number, country: Country): Price {
    if (
      country &&
      this.isConcentratedPowder() &&
      country.isVatPowderDefined()
    ) {
      return new Price({
        currency: this.getPublicPrice().getCurrency(),
        centAmount:
          this.getPublicPrice().getCentAmount() *
          quantity *
          (country.getVatPowder() / 100),
      });
    }
    return new Price({
      currency: this.getPublicPrice().getCurrency(),
      centAmount: 0,
    });
  }

  getTotalGrossPrice(quantity: number): Price {
    return new Price({
      currency: this.getPublicPrice().getCurrency(),
      centAmount: this.getPublicPrice().getCentAmount() * quantity,
    });
  }
}

export const productSearchableAttributes = kiwayLanguages.map(
  (lng) => `name.${lng}`
);

export interface IProductAttributes {
  name: TranslatableAttribute;
  price?: Price | IPriceAttributes | string;
  taxRates: TaxRate[];
  productId?: any;
  variations?: IProductVariationAttributes[];
  model?: string;
  custom?: any;
}

export class Product {
  protected id: string;
  protected name: TranslatableAttribute;
  protected price: Price; // unit price
  protected taxRates: TaxRate[];
  protected variations: ProductVariation[];
  protected model: string; // use for mongoose refPath (allow us to use several Product models (e.g plant, formula, etc.))
  protected custom: any;

  public getCustom(): any {
    return this.custom;
  }

  public setCustom(custom: any): Product {
    this.custom = custom;
    return this;
  }

  public getModel(): string {
    return this.model;
  }

  public setModel(model: string): Product {
    this.model = model;
    return this;
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): Product {
    this.id = id;
    return this;
  }

  public getName(): TranslatableAttribute {
    return this.name;
  }

  public setName(name: TranslatableAttribute): Product {
    this.name = name;
    return this;
  }

  public getPrice(): Price {
    return this.price;
  }

  public setPrice(price: Price): Product {
    this.price = price;
    return this;
  }

  public getTaxRates(): TaxRate[] {
    return this.taxRates;
  }

  public setTaxRates(taxRates: TaxRate[]): Product {
    this.taxRates = taxRates;
    return this;
  }

  public getVariations(): ProductVariation[] {
    return this.variations;
  }

  public setVariations(variations: ProductVariation[]): Product {
    this.variations = variations;
    return this;
  }

  public addVariation(variation: ProductVariation): Product {
    this.variations.push(variation);
    return this;
  }

  public removeVariation(variationId: string): Product {
    this.variations = this.variations.filter(
      (variation) => variation.getId() !== variationId
    );
    return this;
  }

  constructor(
    obj: Partial<IProductAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    this.name = obj?.name;
    if (obj?.price instanceof Price) {
      this.price = obj?.price;
    } else if (typeof obj?.price === 'string') {
      this.price = new Price({ id: obj?.price });
    } else {
      this.price = new Price(obj?.price);
    }
    this.taxRates = obj?.taxRates;
    this.variations = obj?.variations ? [] : undefined;
    for (const v of obj?.variations || []) {
      if (typeof v === 'string') {
        this.addVariation(new ProductVariation({ _id: v }));
      } else {
        this.addVariation(
          new ProductVariation(
            v as Partial<IProductVariationAttributes> & {
              _id?: string;
              id?: string;
            }
          )
        );
      }
    }
    this.model = obj?.model;
    this.custom = obj?.custom;
  }

  public toJSON(): any {
    return {
      _id: this.getId(),
      id: this.getId(),
      name: this.getName(),
      price: this.getPrice()?.toJSON(),
      taxRates: this.getTaxRates(),
      variations: this.getVariations(),
      custom: this.getCustom(),
    };
  }
}
