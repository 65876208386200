import React, { useState, useEffect } from 'react';
import { usePaymentsGateway } from '../../../entrypoints/react/usePaymentsGateway';
import { ListPayments } from './ListPayments';
import { PaymentModalController } from './PaymentModalController';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Payment } from 'libs/ecommerce/src/core/entities/Payment';
import { useHistory } from 'react-router';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { links } from '@kiway/shared/features/pharmaco-react';

export function ListPaymentsController() {
  const [payments, setPayments] = React.useState([]);
  const { findAll } = usePaymentsGateway();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPayment, setSelectedPayment] = useState<Payment>(undefined);

  useEffect(() => {
    findAll().then((payments) => {
      setPayments(payments);
    });
    return () => {
      setPayments([]);
    };
  }, []);

  const handleOpen = (value: Payment) => {
    setSelectedPayment(value);
    setOpen(true);
  };

  const handleOrderNumberClick = (orderId: string) => {
    history.push(links.getOrderDetailsScreen(orderId));
  };

  return (
    <ListPayments
      payments={payments}
      handleOpen={handleOpen}
      handleOrderNumberClick={handleOrderNumberClick}
    >
      <PaymentModalController
        open={open}
        setOpen={setOpen}
        selectedPayment={selectedPayment}
      />
    </ListPayments>
  );
}
