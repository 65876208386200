/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import { ListOrders } from './ListOrders';
import { useHistory } from 'react-router-dom';
import { links } from '../../../config';
import {
  Carrier,
  useCarriersGateway,
  useOrdersGateway,
} from '@kiway/ecommerce-react';
import { AuthContext } from '@kiway/shared/features/authentication-react';
import { isGranted } from '@kiway/shared/utils/access-control-react';
import { ListOrdersPrep } from './ListOrdersPrep';
import { ListOrdersAdminWholesaler } from './ListOrdersAdminWholesaler';
import { GlobalStatus, Order } from '@kiway/ecommerce-react-compatible';
import { useProductVariationTypesGateway } from '../../../entrypoints/react/useProductVariationTypesGateway';
import { ProductVariationType } from '../../../core/entities/ProductVariationType';
import { ListOrdersPrescriber } from './ListOrdersPrescriber';
import { ListOrdersCustomer } from './ListOrdersCustomer';
import { useLoyaltyTransactionsGateway } from '@kiway/shared/loyalty-program-react';

const filterGlobalStatusValues: Array<GlobalStatus | 'ALL'> = [
  'ALL',
  'DRAFT',
  'WAITING_PAYMENT',
  'WAITING_PACKING',
  'PENDING_PACKING',
  'WAITING_SHIPMENT',
  'PENDING_SHIPMENT',
  'COMPLETE',
  'CANCELLED',
];

export function ListOrdersController(props) {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [orders, setOrders] = React.useState<Order[]>([]);
  const {
    createOrder,
    findAll,
    onOrderCreate,
    editOrders,
  } = useOrdersGateway();
  const { findAll: findAllVariationTypes } = useProductVariationTypesGateway();
  const { findAll: findAllCarrier } = useCarriersGateway();
  const {
    authState: { userInfo },
  } = React.useContext(AuthContext);

  const [filterGlobalStatus, setFilterGlobalStatus] = React.useState<
    GlobalStatus | 'ALL'
  >(
    isGranted('ROLE_ADMIN_WHOLESALER', userInfo.roles) ||
      (isGranted('ROLE_PRACTITIONER_PHARMACO', userInfo.roles) &&
        !isGranted('ROLE_ORDER_PICKER', userInfo.roles)) ||
      (isGranted('ROLE_PATIENT', userInfo.roles) &&
        !isGranted('ROLE_ORDER_PICKER', userInfo.roles))
      ? 'ALL'
      : 'WAITING_PACKING'
  );
  const [variationTypes, setVariationTypes] = React.useState<
    ProductVariationType[]
  >([]);
  const [filterVariations, setFilterVariations] = React.useState<
    string | 'ALL'
  >('ALL');
  const [carriers, setCarriers] = React.useState<Carrier[]>([]);
  const [filterCarriers, setFilterCarriers] = React.useState<string | 'ALL'>(
    'ALL'
  );

  const { getLoyaltyPointsBalance } = useLoyaltyTransactionsGateway();
  const [loyaltyPointsBalance, setLoyaltyPointsBalance] = React.useState<
    number
  >(0);
  React.useEffect(() => {
    getLoyaltyPointsBalance(userInfo?.id).then(
      (loyaltyPointsBalance: number) => {
        setLoyaltyPointsBalance(loyaltyPointsBalance);
      }
    );
    return () => {
      setLoyaltyPointsBalance(0);
    };
  }, []);

  React.useEffect(() => {
    setLoading(true);
    findAll()
      .then((orders) => {
        setOrders(orders);
      })
      .finally(() => setLoading(false));
    if (isGranted('ROLE_ORDER_PICKER', userInfo.roles)) {
      onOrderCreate((order) => setOrders((prev) => [...prev, order]));
    }
    return () => {
      setOrders([]);
    };
  }, []);

  React.useEffect(() => {
    if (isGranted('ROLE_PRODUCT_VARIATION_TYPES_LIST', userInfo.roles)) {
      findAllVariationTypes().then((variations) => {
        setVariationTypes(variations);
      });
      return () => {
        setVariationTypes([]);
      };
    }
  }, []);

  React.useEffect(() => {
    if (isGranted('ROLE_CARRIERS_LIST', userInfo.roles)) {
      findAllCarrier().then((carriers) => {
        setCarriers(carriers);
      });
      return () => {
        setCarriers([]);
      };
    }
  }, []);

  const filterOrdersByGlobalStatus = (globalStatus: GlobalStatus | 'ALL') => (
    order: Order
  ) => {
    return globalStatus === 'ALL' || order.getStatus() === globalStatus;
  };

  const filterOrdersByVariations = (variationFilter: string | 'ALL') => (
    order: Order
  ) => {
    return (
      variationFilter === 'ALL' ||
      order
        .getVariationTypes()
        ?.map((item) => item.getShortcode())
        ?.includes(variationFilter)
    );
  };

  const filterOrdersByCarriers = (carrierFilter: string | 'ALL') => (
    order: Order
  ) => {
    return (
      carrierFilter === 'ALL' ||
      order.getShippingMethod()?.getCarrier()?.getId() === carrierFilter
    );
  };

  const handleNewClick = () => {
    createOrder({
      id: userInfo.practitionerId,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      birthDate: userInfo.birthDate,
      gender: userInfo.gender,
      mobilePhone: userInfo.mobilePhone,
      profession: userInfo.profession,
      email: userInfo.email,
      timezone: userInfo.timezone,
      language: userInfo.language,
      custom: {
        userId: userInfo.practitionerId,
      },
    }).then((order) => {
      history.push(links.getOrderPrescriptionScreen(order.getId()));
    });
  };

  const filteredOrders = orders
    ?.filter(filterOrdersByGlobalStatus(filterGlobalStatus))
    ?.filter(filterOrdersByVariations(filterVariations))
    ?.filter(filterOrdersByCarriers(filterCarriers));

  const handleRemoveOrder = async (id) => {
    const order = orders.find((order) => order.getId() === id);
    await editOrders([order?.deleteOrder()?.toInput(true)]);
    setOrders((prev) => prev.filter((order) => order.getId() !== id));
  };

  const globalChildProps = (view?: string) => ({
    handleRowClick: (rowIndex) => {
      if (rowIndex !== undefined) {
        if (view === 'customer') {
          history.push(
            links.getOrderDetailsScreen(
              filteredOrders
                ?.filter((order) => order.getOrderStatus() !== 'DRAFT')
                ?.[rowIndex]?.getId()
            )
          );
        } else {
          history.push(
            view === 'prescriber' &&
              filteredOrders?.[rowIndex]?.getOrderStatus() === 'DRAFT'
              ? links.getOrderPrescriptionScreen(
                  filteredOrders?.[rowIndex]?.getId()
                )
              : links.getOrderDetailsScreen(filteredOrders?.[rowIndex]?.getId())
          );
        }
      }
    },
    handleNewClick: handleNewClick,
    handleRemoveOrder: handleRemoveOrder,
    loading: loading,
    orders: orders,
    filteredOrders,
    filterGlobalStatus,
    setFilterGlobalStatus,
    filterGlobalStatusValues,
    filterOrdersByGlobalStatus,
    filterVariationsValues: variationTypes,
    filterVariations,
    setFilterVariations,
    filterOrdersByVariations,
    filterCarriersValues: carriers,
    filterCarriers,
    setFilterCarriers,
    filterOrdersByCarriers,
  });

  return isGranted('ROLE_ADMIN_WHOLESALER', userInfo.roles) ? (
    <ListOrdersAdminWholesaler {...globalChildProps()} />
  ) : isGranted('ROLE_ORDER_PICKER', userInfo.roles) ? (
    <ListOrdersPrep {...globalChildProps()} />
  ) : isGranted('ROLE_PRACTITIONER_PHARMACO', userInfo.roles) ? (
    <ListOrdersPrescriber
      {...globalChildProps('prescriber')}
      loyaltyPointsBalance={loyaltyPointsBalance}
    />
  ) : (
    <ListOrdersCustomer {...globalChildProps('customer')} />
  );
}
