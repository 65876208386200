import {
  ProductVariationType,
  IProductVariationTypeAttributes,
} from '../../core/entities/ProductVariationType';
import { ProductVariationTypeProvider } from '../../core/use_cases/ProductVariationTypeProvider';
import { gql, ApolloClient } from '@apollo/client';

export const LIST_PRODUCT_VARIATION_TYPES = gql`
  query {
    listProductVariationTypes {
      id
      name
      shortcode
      metadataType
      price {
        currency {
          id
          symbol
          code
          format
        }
        centAmount
      }
      # Add here your attributes
    }
  }
`;

export const EDIT_MULTIPLE_PRODUCT_METADATAS = gql`
  mutation editManyProductVariationTypes($input: [ProductVariationTypeInput]) {
    editManyProductVariationTypes(input: $input) {
      id
      name
      shortcode
      metadataType
      price {
        currency {
          id
          symbol
          code
          format
        }
        centAmount
      }
    }
  }
`;

export type IProductVariationTypeAttributesData = IProductVariationTypeAttributes & {
  id: string;
};

export interface EditProductVariationTypesData {
  editManyProductVariationTypes: Array<IProductVariationTypeAttributesData>;
}

export interface ListProductVariationTypesData {
  listProductVariationTypes: Array<IProductVariationTypeAttributesData>;
}

export class ProductVariationTypeGraphQLProvider
  implements ProductVariationTypeProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async findAll(): Promise<ProductVariationType[]> {
    try {
      const result = await this.client?.query<ListProductVariationTypesData>({
        query: LIST_PRODUCT_VARIATION_TYPES,
      });
      return result.data.listProductVariationTypes.map(
        (item) => new ProductVariationType(item)
      );
    } catch (e) {
      console.log(e);
    }
  }

  save(
    productVariationType: ProductVariationType,
    userId?: string
  ): Promise<ProductVariationType> {
    throw new Error('Method not implemented.');
  }

  async editMany(
    productVariationTypes: IProductVariationTypeAttributes[],
    userId?: string
  ): Promise<ProductVariationType[]> {
    try {
      const result = await this.client?.mutate<
        EditProductVariationTypesData,
        { input: IProductVariationTypeAttributes[] }
      >({
        mutation: EDIT_MULTIPLE_PRODUCT_METADATAS,
        variables: {
          input: productVariationTypes,
        },
      });
      return result.data?.editManyProductVariationTypes?.map(
        (item) => new ProductVariationType(item)
      );
    } catch (e) {
      // console.log(e);
    }
  }

  search(search: string, full?: boolean): Promise<ProductVariationType[]> {
    throw new Error('Method not implemented.');
  }
}
