import {
  Formula,
  FormulaArrays,
  FormulaMetadataSearch,
  FormulaMetadataSearchReturn,
  IFormulaAttributes,
} from '../../core/entities/Formula';
import { FormulaProvider } from '../../core/use_cases/FormulaProvider';
import { gql, ApolloClient } from '@apollo/client';
import { ProductMetadata } from '../../core/entities/ProductMetadata';
import { Plant } from '../../core/entities/Plant';
import { PaginatedResults, PaginationOptions } from '@kiway/shared/react-types';

export const formulaGqlAttributes = `
  id
  name
  published
  metadatas {
    id
    name
    metadataType
  }
  custom {
    legacyId
    pinYinName
    description
    pictureUrl
    normal
    xiaoFang
    jingFang
    composition {
      id
      plant {
        id
        name
        isConcentratedPowder
        isPlant
        published
        custom {
          legacyId
          latinName
          pinYinName
          chineseName
          otherName
          commonName
          concentrationRate
          toxic
          posologyMin
          posologyMax
          sellAvailable
        }
        variations {
          id
          productVariationType {
            id
            name
            price {
              currency {
                id
                symbol
                code
                format
              }
              centAmount
            }
            variationAttributesValue {
              value
              variationAttribute {
                name
              }
            }
          }
          price {
            currency {
              id
              symbol
              code
              format
            }
            centAmount
          }
          productRef
          available
        }
        metadatas {
          id
          metadataType
          name
        }
      }
      dosage
    }
  }
`;

export const LIST_FORMULAS = gql`
  query listFormulas($find: JSON, $page: Int, $perPage: Int) {
    listFormulas(find: $find, page: $page, perPage: $perPage) {
      items {
        ${formulaGqlAttributes}
      }
      currentPage
      totalItems
      totalPages
      prevPage
      nextPage
      hasPrevPage
      hasNextPage
    }
  }
`;

export const GET_FORMULA = gql`
  query getFormula($formulaId: String) {
    getFormula(formulaId: $formulaId) {
      ${formulaGqlAttributes}
    }
  }
`;

export const EDIT_MULTIPLE_FORMULAS = gql`
  mutation editManyFormulas($input: [FormulaInput]) {
    editManyFormulas(input: $input) {
      ${formulaGqlAttributes}
    }
  }
`;

export const ADD_ITEM_TO_FORMULA_ARRAYS = gql`
  mutation addItemToFormulaArrays($formulaId: String, $itemId: String, $itemType: FormulaArraysEnum) {
    addItemToFormulaArrays(formulaId: $formulaId, itemId: $itemId, itemType: $itemType) {
      ${formulaGqlAttributes}
    }
  }
`;

export const REMOVE_ITEM_FROM_FORMULA_ARRAYS = gql`
  mutation removeItemFromFormulaArrays($formulaId: String, $itemId: String, $itemType: FormulaArraysEnum) {
    removeItemFromFormulaArrays(formulaId: $formulaId, itemId: $itemId, itemType: $itemType) {
      ${formulaGqlAttributes}
    }
  }
`;

export const SEARCH_FORMULA_METADATA = gql`
  query searchFormulaMetadata($search: String!) {
    searchFormulaMetadata(search: $search) {
      metadatas {
        id
        name
        metadataType
      }
      plants {
        id
        custom {
          pinYinName
          latinName
          chineseName
        }
        metadataType
      }
    }
  }
`;

export type IFormulaAttributesData = IFormulaAttributes & {
  id: string;
};

export interface ListFormulasData {
  listFormulas: PaginatedResults<IFormulaAttributesData>;
}

export interface GetFormulaData {
  getFormula: IFormulaAttributesData;
}

export interface EditManyFormulasData {
  editManyFormulas: Array<IFormulaAttributesData>;
}

export interface AddItemToFormulaArraysData {
  addItemToFormulaArrays: IFormulaAttributesData;
}

export interface RemoveItemFromFormulaArraysData {
  removeItemFromFormulaArrays: IFormulaAttributesData;
}

export interface SearchFormulaMetadataData {
  searchFormulaMetadata: FormulaMetadataSearch;
}

export class FormulaGraphQLProvider implements FormulaProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async findAll(
    find?: any,
    pagination?: PaginationOptions
  ): Promise<PaginatedResults<Formula>> {
    try {
      const result = await this.client?.query<
        ListFormulasData,
        { find?: any; page?: number; perPage?: number }
      >({
        query: LIST_FORMULAS,
        variables: {
          find,
          page: pagination?.page,
          perPage: pagination?.perPage,
        },
      });
      return {
        ...result.data.listFormulas,
        items: result.data.listFormulas.items.map((item) => new Formula(item)),
      };
    } catch (e) {
      console.log(e);
    }
  }

  async addItemToFormulaArrays(
    formulaId: string,
    itemId: string,
    itemType: FormulaArrays
  ): Promise<Formula> {
    try {
      const result = await this.client?.mutate<
        AddItemToFormulaArraysData,
        { formulaId: string; itemId: string; itemType: FormulaArrays }
      >({
        mutation: ADD_ITEM_TO_FORMULA_ARRAYS,
        variables: {
          formulaId,
          itemId,
          itemType,
        },
      });
      return new Formula(result.data.addItemToFormulaArrays);
    } catch (e) {
      console.log(e);
    }
  }

  async removeItemFromFormulaArrays(
    formulaId: string,
    itemId: string,
    itemType: FormulaArrays
  ): Promise<Formula> {
    try {
      const result = await this.client?.mutate<
        RemoveItemFromFormulaArraysData,
        { formulaId: string; itemId: string; itemType: FormulaArrays }
      >({
        mutation: REMOVE_ITEM_FROM_FORMULA_ARRAYS,
        variables: {
          formulaId,
          itemId,
          itemType,
        },
      });
      return new Formula(result.data.removeItemFromFormulaArrays);
    } catch (e) {
      console.log(e);
    }
  }

  save(formula: Formula, userId?: string): Promise<Formula> {
    throw new Error('Method not implemented.');
  }

  async editMany(
    formulas: IFormulaAttributes[],
    userId?: string
  ): Promise<Formula[]> {
    try {
      const result = await this.client?.mutate<
        EditManyFormulasData,
        { input: IFormulaAttributes[] }
      >({
        mutation: EDIT_MULTIPLE_FORMULAS,
        variables: {
          input: formulas,
        },
      });
      return result.data.editManyFormulas.map((item) => new Formula(item));
    } catch (e) {
      console.log(e);
    }
  }
  async findOneById(formulaId: string): Promise<Formula> {
    try {
      const result = await this.client?.query<
        GetFormulaData,
        { formulaId: string }
      >({
        query: GET_FORMULA,
        variables: {
          formulaId,
        },
      });
      return new Formula(result.data.getFormula);
    } catch (e) {
      console.log(e);
    }
  }

  search(search: string, full?: boolean): Promise<Formula[]> {
    throw new Error('Method not implemented.');
  }

  async searchFormulaMetadata(
    search: string
  ): Promise<FormulaMetadataSearchReturn> {
    try {
      const result = await this.client?.query<
        SearchFormulaMetadataData,
        { search: string }
      >({
        query: SEARCH_FORMULA_METADATA,
        variables: {
          search,
        },
      });
      const data = result.data.searchFormulaMetadata;
      return {
        metadatas: data?.metadatas?.map((item) => new ProductMetadata(item)),
        plants: data?.plants?.map((item) => new Plant(item)),
      };
    } catch (e) {
      console.log(e);
    }
  }
}
