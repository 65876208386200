import React from 'react';
// import ReactDOM from 'react-dom'
import './index.css';
// import * as serviceWorker from './serviceWorker'
import { ThemeContextProvider } from './services/contexts/ThemeContext';
import App from './App';

export const OldApp = (props) => {
  return (
    <ThemeContextProvider>
      <App config={props.config} />
    </ThemeContextProvider>
  );
};

// ReactDOM.render(
//   <ThemeContextProvider>
//     <App />
//   </ThemeContextProvider>,
//   document.getElementById('root'),
// )

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
