import * as React from 'react';
import { Typography } from '@mui/material';
import { Plant } from '../../../../core/entities/Plant';
import { useStyles } from '../../shared/styles';
import { Formula } from '../../../../core/entities/Formula';

export interface SelectedItemProps {
  selectedItem: Plant | Formula;
}

export function SelectedItem({ selectedItem }: SelectedItemProps) {
  const classes = useStyles();

  return (
    <Typography sx={{ fontSize: 15 }} color="text.secondary">
      {selectedItem.getPinYinName()}
    </Typography>
  );
}
