export interface ICustomerAttributes {
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  language: string;
  timezone: string;
  gender: string;
  birthDate: string;
  profession: string;
  custom: any;
}

export const customerSearchableAttributes = [
  'firstName',
  'lastName',
  'email',
  'mobilePhone',
  'gender',
  'profession',
];

export class Customer {
  protected id: string;
  protected firstName: string;
  protected lastName: string;
  protected email: string;
  protected mobilePhone: string;
  protected language: string;
  protected timezone: string;
  protected gender: string;
  protected birthDate: string;
  protected profession: string;
  protected custom: any;

  public constructor(
    obj: Partial<ICustomerAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    this.firstName = obj?.firstName;
    this.lastName = obj?.lastName;
    this.email = obj?.email;
    this.mobilePhone = obj?.mobilePhone;
    this.language = obj?.language;
    this.timezone = obj?.timezone;
    this.gender = obj?.gender;
    this.birthDate = obj?.birthDate;
    this.profession = obj?.profession;
    this.custom = obj?.custom ?? {};
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): Customer {
    this.id = id;
    return this;
  }

  public getFirstName(): string {
    return this.firstName;
  }

  public getLastName(): string {
    return this.lastName;
  }

  public getEmail(): string {
    return this.email;
  }

  public getMobilePhone(): string {
    return this.mobilePhone;
  }

  public getLanguage(): string {
    return this.language;
  }

  public getTimezone(): string {
    return this.timezone;
  }

  public getGender(): string {
    return this.gender;
  }

  public getBirthDate(): string {
    return this.birthDate;
  }

  public getProfession(): string {
    return this.profession;
  }

  public getCustom(): any {
    return this.custom;
  }

  public setCustom(custom: any): Customer {
    this.custom = custom;
    return this;
  }

  public getFullName(): string {
    return `${this.getFirstName()} ${this.getLastName()}`;
  }

  public equals(other: any): boolean {
    if (!(other instanceof Customer)) {
      return false;
    }
    if (other.getId() !== this.id) {
      return false;
    }
    if (other.getEmail() !== this.email) {
      return false;
    }
    if (other.getFirstName() !== this.firstName) {
      return false;
    }
    if (other.getLastName() !== this.lastName) {
      return false;
    }
    if (other.getGender() !== this.gender) {
      return false;
    }
    if (other.getBirthDate() !== this.birthDate) {
      return false;
    }
    if (other.getProfession() !== this.profession) {
      return false;
    }
    if (other.getLanguage() !== this.language) {
      return false;
    }
    if (other.getTimezone() !== this.timezone) {
      return false;
    }
    if (other.getMobilePhone() !== this.mobilePhone) {
      return false;
    }
    return true;
  }

  public toJSON(): any {
    return {
      id: this.getId(),
      _id: this.getId(),
      firstName: this.getFirstName(),
      lastName: this.getLastName(),
      email: this.getEmail(),
      mobilePhone: this.getMobilePhone(),
      language: this.getLanguage(),
      timezone: this.getTimezone(),
      gender: this.getGender(),
      birthDate: this.getBirthDate(),
      profession: this.getProfession(),
      custom: this.getCustom(),
    };
  }
}
