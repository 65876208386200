import { gql } from '@apollo/client'

export const PUBLIC_PAGE_GET_SLOTS = gql`
  query slots($filter: SlotFilter) {
    slots(filter: $filter) {
      day
      slots
    }
  }
`
