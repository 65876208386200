import * as React from 'react';
import { useCarriersGateway } from '../../../entrypoints/react/useCarriersGateway';
import { ListCarriers } from './ListCarriers';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Carrier } from '@kiway/ecommerce-react';

export function ListCarriersController() {
  const [loading, setLoading] = React.useState(false);
  const [carriers, setCarriers] = React.useState([]);
  const { editCarriers, findAll } = useCarriersGateway();

  React.useEffect(() => {
    setLoading(true);
    findAll()
      .then((carriers) => {
        setCarriers(carriers);
      })
      .finally(() => setLoading(false));
    return () => {
      setCarriers([]);
    };
  }, []);

  const handleAddData = () => {
    const data = new Carrier({});
    setCarriers((prev) => [data, ...prev]);
  };

  const handleClearAdd = () => {
    setCarriers((prev) => prev.filter((carrier) => carrier.getId()));
  };

  return (
    <ListCarriers
      handleAddData={handleAddData}
      handleClearAdd={handleClearAdd}
      handleSave={async (updates) => {
        if (editCarriers) {
          const editedCarriers = await editCarriers(updates);
          setCarriers((prev) => [
            ...prev.filter(
              (carrier) =>
                carrier.getId() &&
                !editedCarriers
                  .map((carrier) => carrier.getId())
                  .includes(carrier.getId())
            ),
            ...editedCarriers,
          ]);
        }
      }}
      carriers={carriers}
    />
  );
}
