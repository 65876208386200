import { Price, IPriceAttributes } from './Order';
import { ShippingMethod, IShippingMethodAttributes } from './ShippingMethod';

export interface IShippingMethodPriceAttributes {
  minWeight: number;
  maxWeight: number;
  price: Price | IPriceAttributes | string;
  shippingMethod: ShippingMethod | IShippingMethodAttributes | string;
}

export class ShippingMethodPrice {
  protected id: string;
  protected minWeight: number;
  protected maxWeight: number;
  protected price: Price;
  protected shippingMethod: ShippingMethod;

  public constructor(
    obj: Partial<IShippingMethodPriceAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    this.minWeight = obj?.minWeight;
    this.maxWeight = obj?.maxWeight;
    if (obj?.price) {
      if (obj?.price instanceof Price) {
        this.price = obj?.price;
      } else if (typeof obj?.price === 'string') {
        this.price = new Price({ id: obj?.price });
      } else {
        this.price = new Price(obj?.price);
      }
    }
    if (obj?.shippingMethod) {
      if (obj?.shippingMethod instanceof ShippingMethod) {
        this.shippingMethod = obj?.shippingMethod;
      } else if (typeof obj?.shippingMethod === 'string') {
        this.shippingMethod = new ShippingMethod({ id: obj?.shippingMethod });
      } else {
        this.shippingMethod = new ShippingMethod(obj?.shippingMethod);
      }
    }
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): ShippingMethodPrice {
    this.id = id;
    return this;
  }

  public getMinWeight(): number {
    return this.minWeight;
  }

  public setMinWeight(minWeight: number): ShippingMethodPrice {
    this.minWeight = minWeight;
    return this;
  }

  public getMaxWeight(): number {
    return this.maxWeight;
  }

  public setMaxWeight(maxWeight: number): ShippingMethodPrice {
    this.maxWeight = maxWeight;
    return this;
  }

  public getPrice(): Price {
    return this.price;
  }

  public setPrice(price: Price): ShippingMethodPrice {
    this.price = price;
    return this;
  }

  public getShippingMethod(): ShippingMethod {
    return this.shippingMethod;
  }

  public setShippingMethod(
    shippingMethod: ShippingMethod
  ): ShippingMethodPrice {
    this.shippingMethod = shippingMethod;
    return this;
  }

  toJSON(): any {
    return {
      id: this.getId(),
      minWeight: this.getMinWeight(),
      maxWeight: this.getMaxWeight(),
      price: this.getPrice().toJSON(),
    };
  }

  public toDatatableRow(): any {
    return {
      _id: this.getId(),
      minWeight: this.getMinWeight(),
      maxWeight: this.getMaxWeight(),
      price: this.getPrice()?.toJSON(),
      shippingMethod: this.getShippingMethod()?.getId(),
    };
  }
}
