export default {
  addUserTitle: 'Add user',
  dataTable: {
    columns: {
      createdAt: 'Sign up',
      docStatus: 'Doc status',
      docType: 'Doc type',
      email: 'Email',
      GDPR: 'GDPR',
      lastLogin: 'Last login',
      partnerRevenueAmount: 'Revenue amount',
      partnerRevenuePlan: 'Revenue plan',
      role: 'Role',
      subStatus: 'Sub status',
      verified: 'Verified email',
    },
    values: {
      empty: 'Null',
    },
  },
  editUserTitle: 'Edit user',
  enum: {
    docStatus: {
      contact: 'Contacted',
      pending: 'Pending',
      received: 'Received',
      verified: 'Verified',
    },
    docType: {
      diploma: 'Diploma',
      trainingCertificate: 'Training certificate',
    },
    partnerRevenuePlan: {
      costPerAcquisition: 'Cost per acquisition',
      revenueShare: 'Revenue share',
    },
  },
  errors: {
    requiredField: 'Required field',
  },
  notificationsManagement: {
    form: {
      metadata: {
        link: 'Link',
        text: 'Notification content',
      },
      sendTo: 'Send to',
      sendToOptions: {
        admin: 'Admin role',
        all: 'All users',
        beta: 'Beta role',
        partner: 'Partner role',
        patient: 'Patient role',
        pharmaco: 'Pharmaco role',
        practitioner: 'Practitioner role',
      },
      type: 'Notification type',
    },
    title: 'Send notifications',
  },
  snackbars: {
    errorEdit: 'Something went wrong during saving updates',
    successAdd: 'Successful update',
    successEdit: 'Successful update',
  },
  usersManagement: {
    title: 'Users management',
  },
};
