import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import React, { useContext } from 'react'
import { useTranslation } from '@kiway/shared/utils/translation'
import { CustomButton } from './Buttons'
import Skeleton from '@material-ui/lab/Skeleton'
import { withRouter } from 'react-router-dom'
// import { BookingContext } from '../AppRoutes'
import { BookingContext } from '../../BookingContext'
import { useSnackbar } from '@kiway/shared/utils/snackbar'
import {
  PUBLIC_PAGE_GET_SLOTS,
  PUBLIC_PAGE_ON_APPOINTMENT_EVENT_TO_SLOTS,
} from '../graphql/slot'
import {
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client'
import { updateCacheForSlots } from '../graphql/cache.utils'
import { PUBLIC_PAGE_ADD_APPOINTMENT } from '../graphql/appointment'

function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function SlotPickerColumn(props) {
  const {
    chiefComplaintId,
    consultationPlaceId,
    history,
    maxSlotsDisplayed,
    practitionerId,
    slotDay,
  } = props
  const { t } = useTranslation()
  const hourFormat = t('dates:format.hour')
  const { state, dispatch } = useContext(BookingContext)

  const { addErrorSnackbar } = useSnackbar()

  const client = useApolloClient()

  const variables = {
    filter: {
      owner: practitionerId,
      from: moment(slotDay).format('YYYY-MM-DD'),
      to: moment(slotDay).format('YYYY-MM-DD'),
      consultationPlace: consultationPlaceId,
      chiefComplaint: chiefComplaintId,
    },
  }

  const { loading, data } = useQuery(PUBLIC_PAGE_GET_SLOTS, {
    variables,
    onCompleted: (data) => {
      client.writeQuery({
        query: PUBLIC_PAGE_GET_SLOTS,
        data: {
          slots: data.slots,
        },
        variables,
      })
    },
    partialRefetch: true,
  })

  useSubscription(PUBLIC_PAGE_ON_APPOINTMENT_EVENT_TO_SLOTS, {
    variables,
    onSubscriptionData: ({ subscriptionData: { data }, client }) => {
      updateCacheForSlots(
        client,
        PUBLIC_PAGE_GET_SLOTS,
        variables,
        data.onAppointmentEventToSlots,
      )
    },
  })

  const [addAppointment] = useMutation(PUBLIC_PAGE_ADD_APPOINTMENT, {
    onError: (error) => {
      if (error.graphQLErrors[0].message === 'overlap') {
        addErrorSnackbar(t('agenda:snackbars.appointments.overlap'))
      } else if (error.graphQLErrors[0].message === 'missingFields') {
        addErrorSnackbar(t('snackbars.appointments.missingFields'))
      }
    },
    update: (store, response) => {
      dispatch({
        type: 'BOOK_APPOINTMENT_PENDING',
        payload: { appointmentId: response.data.addAppointmentFromPatient.id },
      })
      history.push({
        pathname: '/appointment/new',
      })
    },
  })

  function handleClickSlot(slot) {
    dispatch({ type: 'SELECT_SLOT', payload: { slot } })
    const duration = moment.duration({
      minutes: state.chiefComplaint.duration,
    })

    const appointmentToSend = {
      owner: state.practitioner.id,
      startDate: state.appointment.startDate || moment(slot).toDate(),
      endDate: state.appointment.endDate || moment(slot).add(duration).toDate(),
      chiefComplaint: state.chiefComplaint.id,
      consultationPlace: state.consultationPlace.id,
      status: 'PENDING',
      eventType: 'SESSION',
    }

    addAppointment({
      variables: { data: appointmentToSend },
    })
  }

  let nbSkeleton
  const skeletons = []
  if (loading) {
    nbSkeleton = getRandomInt(1, 4)
    for (let i = 0; i < nbSkeleton; i++) {
      skeletons.push({ index: i })
    }
  }

  return (
    <Grid container spacing={1} direction={'column'} alignItems={'center'}>
      <Grid container spacing={1} direction={'column'} alignItems={'center'}>
        <Grid item xs={12}>
          {slotDay.format(t('dates:format.shortDay'))}
        </Grid>
        <Grid item xs={12}>
          {slotDay.format(t('dates:format.shortDayMonth'))}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction={'column'}
        alignItems={'center'}
        style={{ paddingTop: '8px' }}
      >
        {loading
          ? skeletons &&
            skeletons.map((skel) => (
              <div key={skel.index} style={{ marginTop: '8px' }}>
                <Skeleton
                  animation={'wave'}
                  variant="rect"
                  width={70}
                  height={36}
                />
              </div>
            ))
          : data &&
            data.slots[0].slots
              .filter((slot, index) =>
                maxSlotsDisplayed ? index < maxSlotsDisplayed : true,
              )
              .filter((slot) => {
                if (moment().format('YYYY-MM-DD') === slotDay.format('YYYY-MM-DD')) {
                  return moment(slot).isAfter(moment())
                }
                return true
              })
              .map((slot) => (
                <CustomButton
                  key={slot}
                  color={'secondary'}
                  style={{ marginTop: '8px' }}
                  onClick={() => handleClickSlot(slot)}
                >
                  {moment(slot).format(hourFormat)}
                </CustomButton>
              ))}
      </Grid>
    </Grid>
  )
}

export default withRouter(SlotPickerColumn)
