import { Formula, FormulaArrays } from '../entities/Formula';
import { FormulaProvider } from './FormulaProvider';

export class RemoveItemFromFormulaArrays {
  private formulaProvider: FormulaProvider;

  public constructor(formulaProvider: FormulaProvider) {
    this.formulaProvider = formulaProvider;
  }

  public execute(
    formulaId: string,
    itemId: string,
    itemType: FormulaArrays
  ): Promise<Formula> {
    return this.formulaProvider.removeItemFromFormulaArrays(
      formulaId,
      itemId,
      itemType
    );
  }
}
