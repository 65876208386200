export default {
  affiliation: 'Affiliation',
  agenda: 'Agenda',
  appointments: 'My appointments',
  beta: 'Beta',
  dashboard: 'Dashboard',
  files: 'Files',
  forgotPassword: 'Forgot password',
  invoicing: 'Invoicing',
  notifications: 'Notifications',
  pageNotFound: 'Page not found',
  profile: 'Profile edit',
  register: 'Register',
  services: 'My services',
  settings: {
    activity: 'Generals',
    main: 'Settings',
    notifier: 'Notifications',
  },
  signIn: 'Sign in',
  subscription: 'Subscription',
  tuto: {
    intro: 'Intro',
    subtitle: 'Tutorial',
  },
  users: 'Users',
};
