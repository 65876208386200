import * as React from 'react';
import * as QRCode from 'qrcode.react';
import {
  Box,
  Button,
  Chip,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import InfoIcon from '@mui/icons-material/Info';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';

export interface TwoFAProps {
  activeStep: any;
  code: string;
  handleBack: () => void;
  handleChangeCode: (...args: any[]) => any;
  handleEnable: (...args: any[]) => any;
  handleDisable: (...args: any[]) => any;
  handleNext: (currentIndex) => () => void;
  loading?: boolean;
  qrCode: string;
  secret: string;
  steps: Array<{ label: string; description: string }>;
  twoFAMethod?: any;
  twoFAVisible: string;
}

export function TwoFA(props: TwoFAProps) {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ paddingTop: 1, paddingBottom: 1 }}
        >
          <Grid item xs={12} md={5} sx={{ paddingTop: '0 !important' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant="subtitle1" style={{ marginTop: '2px' }}>
                {t('authentication:editProfile.twoFA.title')}
              </Typography>
              <Tooltip
                title={t('authentication:editProfile.twoFA.info')}
                placement="right"
              >
                <InfoIcon color="secondary" style={{ marginLeft: '8px' }} />
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={12} md={7} sx={{ paddingTop: '0 !important' }}>
            <Grid
              container
              alignItems="center"
              justifyContent={'space-between'}
            >
              <Grid item>
                {props.twoFAMethod ? (
                  <Chip
                    label={t('authentication:editProfile.twoFA.active')}
                    color="success"
                  />
                ) : (
                  <Chip
                    label={t('authentication:editProfile.twoFA.inactive')}
                    color="error"
                  />
                )}
              </Grid>
              <Grid item>
                <Button
                  disabled={props.loading}
                  onClick={
                    props.twoFAMethod ? props.handleDisable : props.handleEnable
                  }
                  color={props.twoFAVisible !== '' ? 'inherit' : 'minoritary'}
                  variant="contained"
                >
                  {t(
                    props.twoFAVisible !== ''
                      ? 'authentication:editProfile.twoFA.cancel'
                      : props.twoFAMethod
                      ? 'authentication:editProfile.twoFA.disable'
                      : 'authentication:editProfile.twoFA.enable'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {props.twoFAVisible !== '' && (
        <Grid item xs={12}>
          <Grid container direction="row-reverse" alignItems={'center'}>
            {props.twoFAVisible === 'enable' && (
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  direction="column"
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="body2" align="center">
                      {t('authentication:editProfile.twoFA.secretCode', {
                        secret: props.secret,
                      })}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <QRCode value={props.qrCode} />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} md={props.twoFAVisible === 'enable' ? 6 : 12}>
              <Stepper activeStep={props.activeStep} orientation="vertical">
                {props.steps?.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{t(step.label)}</StepLabel>
                    <StepContent>
                      <Typography variant="body2">
                        {t(step.description)}
                      </Typography>
                      <Box sx={{ mb: 2 }}>
                        {index === props.steps?.length - 1 && (
                          <div>
                            <TextField
                              autoFocus
                              onChange={props.handleChangeCode}
                              value={props.code}
                              margin="dense"
                              size="small"
                            />
                          </div>
                        )}
                        <div>
                          <LoadingButton
                            loading={props.loading}
                            loadingPosition={
                              index === props.steps?.length - 1
                                ? 'start'
                                : undefined
                            }
                            startIcon={
                              index === props.steps?.length - 1 && <CheckIcon />
                            }
                            variant="contained"
                            color="minoritary"
                            onClick={props.handleNext(index)}
                            sx={{ mt: 1, mr: 1 }}
                            type="submit"
                          >
                            {index === props.steps?.length - 1
                              ? t('authentication:editProfile.twoFA.finish')
                              : t('authentication:editProfile.twoFA.continue')}
                          </LoadingButton>
                          <Button
                            color="inherit"
                            disabled={index === 0 || props.loading}
                            onClick={props.handleBack}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            {t('authentication:editProfile.twoFA.back')}
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
