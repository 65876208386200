import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './AppRoutes';
import client from './graphql/client';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import ReactGA from 'react-ga';
import { AuthProvider } from '@kiway/shared/features/authentication-react';
import { GlobalContextProvider } from './services/contexts/GlobalContext';
import { CrispChatbox } from '@kiway/shared/chatbox';

const trackingId = process.env['NX_GA_TRACKING_CODE'];
ReactGA.initialize(trackingId);

function App(props) {
  useEffect(() => {
    if (
      process.env.NX_CRISP_WEBSITE_ID &&
      process.env.NX_CRISP_WEBSITE_ID !== ''
    ) {
      const crisp = CrispChatbox.getInstance();
      crisp.init(process.env.NX_CRISP_WEBSITE_ID);
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {props.config.features.map((Feature, index) => (
          <Feature key={index} />
        ))}
        <BrowserRouter>
          <GlobalContextProvider>
            <AppRoutes config={props.config} />
          </GlobalContextProvider>
        </BrowserRouter>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
