import * as React from 'react';
import { Typography } from '@material-ui/core';
import { Carrier } from '../../../core/entities/Carrier';
import { Skeleton } from '@material-ui/lab';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DatatablesController } from '@kiway/datatables';
import { useTranslation } from '@kiway/shared/utils/translation';
import { deepen } from '@kiway/shared/utils/string';

export interface ListCarriersProps {
  carriers: Carrier[];
  handleAddData: () => void;
  handleClearAdd: () => void;
  handleSave?: any;
  loading?: boolean;
  type?: any;
}

const prepareToSave = (item) => {
  return {
    ...deepen(item),
    id: item._id,
    _id: undefined,
  };
};

export function ListCarriers(
  props: React.PropsWithChildren<ListCarriersProps>
) {
  const { t } = useTranslation();
  return (
    <DatatablesController
      bulkEdit={true}
      handleAddData={props.handleAddData}
      handleClearAdd={props.handleClearAdd}
      handleSaveData={
        props.handleSave
          ? (updates) => {
              if (props.handleSave) {
                return props.handleSave(updates.updatedData.map(prepareToSave));
              }
            }
          : undefined
      }
      columns={[
        {
          name: '_id',
          label: 'ID',
          type: 'text',
          options: {
            display: false,
            edit: false,
            filter: false,
          },
        },
        {
          name: 'name.fr',
          label: t(`pharmaco:carriers.label.fr`),
          type: 'text',
        },
        {
          name: 'name.en',
          label: t(`pharmaco:carriers.label.en`),
          type: 'text',
        },
        {
          name: 'name.es',
          label: t(`pharmaco:carriers.label.es`),
          type: 'text',
        },
        {
          name: 'name.pt',
          label: t(`pharmaco:carriers.label.pt`),
          type: 'text',
        },
        {
          name: 'code',
          label: t(`pharmaco:carriers.code.label`),
          type: 'text',
          options: {
            editHelperText: t(`pharmaco:carriers.code.helperText`),
          },
        },
        {
          name: 'trackingLink',
          label: t(`pharmaco:carriers.trackingLink.label`),
          type: 'text',
          options: {
            editHelperTextError: t(
              `pharmaco:carriers.trackingLink.helperTextError`,
              {
                trackingNumber: '{{trackingNumber}}',
              }
            ),
            editValidCallback: (value) => {
              return Carrier.isTrackingLinkValid(value);
            },
          },
        },
      ]}
      data={props.carriers?.map((carrier) => carrier.toDatatableRow())}
      defaultSortOrder={{ name: 'name.fr', direction: 'asc' }}
      id="listCarriers"
      loading={props.loading}
      options={{
        download: false,
        filter: false,
        print: false,
      }}
      title={t(`pharmaco:carriers.linkText`)}
    />
  );
}
