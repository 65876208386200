export default {
  availableTypes: {
    email: 'Por email',
    push: 'Na aplicação',
  },
  docSubtitle: 'Definições',
  greetings: 'Olá',
  linkText: 'Notificações',
  notifierLabel: 'Ser notificado quando ...',
  snackbars: {
    okEmailDisabled: 'Notificação por email desactivada com sucesso',
    okEmailEnabled: 'Notificação de email ativada com sucesso',
    okPushDisabled: 'Notificação no aplicativo desativada com sucesso ',
    okPushEnabled: 'Notificação no aplicativo ativada com sucesso',
  },
};
