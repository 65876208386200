import { Order } from '../entities/Order';
import { OrderProvider } from './OrderProvider';

export class GetOrder {
  private orderProvider: OrderProvider;

  public constructor(orderProvider: OrderProvider) {
    this.orderProvider = orderProvider;
  }

  public execute(orderId: string): Promise<Order> {
    return this.orderProvider.findOneById(orderId);
  }
}
