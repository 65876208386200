export interface IInvoiceAttributes {
  invoiceNumber: string;
  invoiceDate: Date | string;
  index: number;
  invoiceMonth: string;
  invoiceYear: string;
  file: any;
  totalGrossAmount: number;
  totalNetAmount: number;
  status: string;
}

export class Invoice {
  protected id: string;
  protected invoiceNumber: string;
  protected invoiceDate: Date;
  protected index: number;
  protected invoiceMonth: string;
  protected invoiceYear: string;
  protected file: any;
  protected totalGrossAmount: number;
  protected totalNetAmount: number;
  protected status: string;

  public constructor(
    obj: Partial<IInvoiceAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    this.invoiceNumber = obj?.invoiceNumber;
    this.invoiceDate =
      typeof obj?.invoiceDate === 'string'
        ? new Date(obj?.invoiceDate)
        : obj?.invoiceDate;
    this.index = obj?.index;
    this.invoiceMonth = obj?.invoiceMonth;
    this.invoiceYear = obj?.invoiceYear;
    this.file = obj?.file;
    this.totalGrossAmount = obj?.totalGrossAmount;
    this.totalNetAmount = obj?.totalNetAmount;
    this.status = obj?.status;
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): Invoice {
    this.id = id;
    return this;
  }

  public getInvoiceNumber(): string {
    return this.invoiceNumber;
  }

  public setInvoiceNumber(invoiceNumber: string): Invoice {
    this.invoiceNumber = invoiceNumber;
    return this;
  }

  public getInvoiceDate(): Date {
    return this.invoiceDate;
  }

  public setInvoiceDate(invoiceDate: Date): Invoice {
    this.invoiceDate = invoiceDate;
    return this;
  }

  public getIndex(): number {
    return this.index;
  }

  public setIndex(index: number): Invoice {
    this.index = index;
    return this;
  }

  public getInvoiceMonth(): string {
    return this.invoiceMonth;
  }

  public setInvoiceMonth(invoiceMonth: string): Invoice {
    this.invoiceMonth = invoiceMonth;
    return this;
  }

  public getInvoiceYear(): string {
    return this.invoiceYear;
  }

  public setInvoiceYear(invoiceYear: string): Invoice {
    this.invoiceYear = invoiceYear;
    return this;
  }

  public getFile(): any {
    return this.file;
  }

  public setFile(file: any): Invoice {
    this.file = file;
    return this;
  }

  public getTotalGrossAmount(): number {
    return this.totalGrossAmount;
  }

  public setTotalGrossAmount(totalGrossAmount: number): Invoice {
    this.totalGrossAmount = totalGrossAmount;
    return this;
  }

  public getTotalNetAmount(): number {
    return this.totalNetAmount;
  }

  public setTotalNetAmount(totalNetAmount: number): Invoice {
    this.totalNetAmount = totalNetAmount;
    return this;
  }

  public getStatus(): string {
    return this.status;
  }

  public setStatus(status: string): Invoice {
    this.status = status;
    return this;
  }

  public toJSON(): any {
    return {
      _id: this.getId(),
      id: this.getId(),
      invoiceNumber: this.getInvoiceNumber(),
      invoiceDate: this.getInvoiceDate(),
      index: this.getIndex(),
      invoiceMonth: this.getInvoiceMonth(),
      invoiceYear: this.getInvoiceYear(),
      file: this.getFile(),
      totalGrossAmount: this.getTotalGrossAmount(),
      totalNetAmount: this.getTotalNetAmount(),
      status: this.getStatus(),
    };
  }
}
