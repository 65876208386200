import './google-map-address-search.module.css';
import * as React from 'react';
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

declare const window: any;

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

const GOOGLE_API_KEY = process.env['NX_GOOGLE_API_KEY'];

/* eslint-disable-next-line */
export interface GoogleMapAddressSearchProps {
  country?: string;
  label: string;
  inputValue?: string;
  setAddress: (...args: any[]) => any;
  setAddressComponent: (...args: any[]) => any;
  variant?: 'filled' | 'standard' | 'outlined';
  margin?: 'dense' | 'normal' | 'none';
  readOnly?: boolean;
  required?: boolean;
  size?: 'small' | 'medium';
}

export function GoogleMapAddressSearch(props: GoogleMapAddressSearchProps) {
  const { inputValue, setAddress, setAddressComponent, ...other } = props;
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        // eslint-disable-next-line max-len
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&language=en`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const handleChange = (event: any) => {
    if (event?.target?.value) {
      setAddress(event.target.value);
    }
  };

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string; componentRestrictions?: any },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          if (autocompleteService.current) {
            (autocompleteService.current as any).getPlacePredictions(
              request,
              callback
            );
          }
        },
        200
      ),
    []
  );

  React.useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google && window.google.maps) {
      // eslint-disable-next-line max-len
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (!inputValue || inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch(
      {
        input: inputValue ?? '',
        componentRestrictions: {
          country: props.country ? [props.country] : [],
        },
      },
      (results: any) => {
        if (active) {
          setOptions(results || []);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [inputValue, fetch, props.country]);

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option: any) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      inputValue={inputValue}
      includeInputInList
      disabled={props.readOnly}
      onChange={(event, value: any) => {
        if (value) {
          setAddress(value.description);
          geocodeByAddress(value.description)
            .then(async (results) => {
              const coordinates = await getLatLng(results[0]);
              // console.log(coordinates, results[0].formatted_address);
              const addressComponent = results[0].address_components;
              let street_number = '';
              let route = '';
              let address1 = '';
              let postcode = '';
              let locality = '';
              let administrative_area_level_1 = '';
              let administrative_area_level_2 = '';
              let country = '';
              addressComponent.map((component) => {
                const componentType = component.types[0];

                switch (componentType) {
                  case 'street_number': {
                    address1 = `${component.long_name} ${address1}`;
                    street_number = component.long_name;
                    break;
                  }

                  case 'route': {
                    address1 += component.long_name;
                    route = component.long_name;
                    break;
                  }

                  case 'postal_code': {
                    postcode = `${component.long_name}${postcode}`;
                    break;
                  }

                  case 'postal_code_suffix': {
                    postcode = `${postcode}-${component.long_name}`;
                    break;
                  }

                  case 'locality':
                    locality = component.long_name;
                    break;

                  case 'administrative_area_level_1': {
                    administrative_area_level_1 = component.short_name;
                    break;
                  }

                  case 'administrative_area_level_2': {
                    administrative_area_level_2 = component.short_name;
                    break;
                  }

                  case 'country':
                    country = component.long_name;
                    break;
                }
              });
              const formattedAddressComponent = {
                address1,
                administrative_area_level_1,
                administrative_area_level_2,
                coordinates,
                country,
                description: value.description,
                formatted_address: results[0].formatted_address ?? '',
                locality,
                postcode,
                route,
                street_number,
              };
              setAddressComponent(formattedAddressComponent);
              return coordinates;
            })
            .catch(() => {
              // NOTHING
            });
        } else {
          setAddressComponent({});
          setAddress('');
        }
      }}
      onInputChange={handleChange}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, autoComplete: 'nope' }}
            required={props.required}
            label={props.label}
            variant={props.variant ?? 'outlined'}
            margin={props.margin ?? 'dense'}
            size={props.size ?? 'small'}
            fullWidth
          />
        );
      }}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      {...other}
    />
  );
}

export default GoogleMapAddressSearch;
