export default {
  actions: {
    delete: {
      title: 'Apagar um modelo de semana',
      warning: 'Você quer apagar este modelo semanal?',
    },
    deleteSlot: {
      title: 'Apague um espaço',
      warning: 'Você quer apagar este espaço?',
    },
    preview: {
      title: 'Visualização de seus modelos semanais',
    },
  },
  caption: {
    colors:
      'As cores estão lá como uma indicação para agrupar os espaços. Uma cor = um espaço.',
    onlineDisplay: 'Visivel online. Respectivamente online e offline.',
    slotMode:
      'Modo de preenchimento dos espaços. Respectivamente online e offline.',
    title: 'Legenda',
  },
  disable: 'Desativar',
  eachTwoWeeks: 'Uma vez a cada duas semanas',
  eachWeek: 'Todas as semanas',
  enable: 'Ativar',
  endDate: 'Data de fim',
  endHour: 'Fim do espaço para consultas',
  form: {
    title: {
      add: 'Adicione um modelo',
      addSlot: 'Adicione um espaço',
      edit: 'Modifique o modelo',
      editSlot: 'Modifique o espaço',
    },
  },
  from: 'De',
  info:
    'As semanas típicas possibilitam fornecer os espaços para fazer agendamentos on -line. Para cada semana de tipo, você pode criar espaços com diferentes lugares e motivos de consulta.',
  name: 'Nome da semana do tipo',
  online: {
    available: 'Disponível online',
    unavailable: 'Não disponível online',
  },
  pause: 'Pausa entre dois agendamentos',
  recurrence: 'Recorrência',
  slot: {
    days: 'Dias',
    endHour: 'Hora do término',
    noPreview:
      'Adicione um espaço para obter uma pré-visualização da sua semana',
    noWorkplaceSelected:
      'Selecione um local de consulta para exibir os padrões associados.',
    pause: 'Pausa',
    preview: 'Visão geral da minha semana',
    startHour: 'Hora de início',
    title: 'Espaço',
    workplace: 'Localização da consulta',
  },
  slotChiefComplaints: 'Motivos de consulta',
  slotConsultationPlace: 'Local da consulta',
  slotDays: 'Dias de ativos ',
  slotMode: 'Modo de preenchimento',
  slotModes: {
    down: 'Inicio do espaço para consultas',
    up: 'Nicho Start',
    'up&down': 'Nos dois sentidos',
  },
  slotModesInfo: {
    down:
      'Gera reuniões começando no final do nicho. Por exemplo, das 10h às 12h, se levarmos motivos para consultas com duração de 50 minutos, os slots oferecidos serão 10:20 e 11:10.',
    up:
      'Gera marcações de consulta começando no início do espaço para as mesmas. Por exemplo, das 10h às 12h, se você fizer 50 minutos de consulta, os espaços oferecidos serão das 10h e 10h50.',
    'up&down':
      'Gera o maior número possível de reuniões em um espaço de tempo definido. Por exemplo, das 10h às 12h, se levarmos motivos para consultas com duração de 50 minutos, os espaços oferecidos serão do tipo 10h, 10h20, 10h50 e 11h10. Estes agendamentos serão ajustados de acordo com o primeiro escolhido por um paciente. Se o primeiro a escolher for o das 10:20, permanecerá apenas o agendamento das 11:10 disponível.',
  },
  snackbars: {
    error: {
      delete: 'Ocorreu um erro. Por favor, tente novamente',
      save: 'Ocorreu um erro. Por favor, tente novamente',
      slotOverlap: 'Você não pode sobrepor dois espaços para o mesmo modelo',
    },
    success: {
      delete: 'Eliminado com sucesso',
      save: 'Gravado com sucesso',
    },
  },
  startDate: 'Data de início',
  startHour: 'Inicio do espaço para consultas',
  title: 'As minhas semanas típicas',
  to: 'para',
  warningTemplate:
    'Várias semanas típicas não podem ser ativadas na mesma semana. Para uma determinada semana, é a semana típica do topo da lista que vence. Você pode alterar o tipo de semanalmente com um "arrastar e soltar".',
};
