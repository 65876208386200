export default {
  fields: {
    appointmentDate: {
      error: 'O início e o fim de uma consulta deve ter o mesmo dia',
    },
    email: {
      error: 'Obrigado para indicar um endereço de e -mail válido',
    },
    lastname: {
      error: 'Por favor, insira seu nome',
    },
    yourMessage: {
      error: 'Por favor, escreva uma mensagem',
    },
  },
  login: {
    accountNotVerified:
      'Sua conta não foi ativada, entre em contato com um administrador via gat ou contact@kiway.co',
    error: 'Identificadores inválidos.',
    noRights:
      'Você não tem direitos suficientes para se conectar a este espaço, tem certeza de que está no caminho certo?',
    resetError:
      'Ocorreu um erro durante o envio de sua solicitação. Por favor tente novamente.',
  },
  requiredField: {
    empty: 'Campo obrigatório',
  },
};
