import { PaginatedResults, PaginationOptions } from '@kiway/shared/react-types';
import { Plant } from '../entities/Plant';
import { PlantProvider } from './PlantProvider';

export class GetAllPlants {
  private plantProvider: PlantProvider;

  public constructor(plantProvider: PlantProvider) {
    this.plantProvider = plantProvider;
  }

  public execute(
    find?: any,
    pagination?: PaginationOptions
  ): Promise<PaginatedResults<Plant>> {
    return this.plantProvider.findAll(find, pagination);
  }
}
