export function transformDeepToGraphIds(object) {
  for (const key in object) {
    if (key === '_id') {
      object.id = object[key]
      delete object._id
    }
    if (object[key] instanceof Object) {
      transformDeepToGraphIds(object[key])
    }
  }
}

export function cleanTypenameFrom(object) {
  const cleaned = {}
  for (const key in object) {
    if (key !== '__typename') {
      cleaned[key] = object[key]
    }
  }
  return cleaned
}
