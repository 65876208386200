import { CarrierGraphQLProvider } from '../../dataproviders/graphql-client/CarrierGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllCarriers } from '../../core/use_cases/GetAllCarriers';
import { Carrier, ICarrierAttributes } from '../../core/entities/Carrier';
import { EditManyCarriers } from '../../core/use_cases/EditManyCarriers';

class CarriersGateway {
  protected static instance: CarriersGateway;
  protected getAllCarriers: GetAllCarriers;
  protected editManyCarriers: EditManyCarriers;

  public static getInstance(client: ApolloClient<any>): CarriersGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new CarriersGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const carrierProvider = new CarrierGraphQLProvider(client);
    this.getAllCarriers = new GetAllCarriers(carrierProvider);
    this.editManyCarriers = new EditManyCarriers(carrierProvider);
    this.findAll = this.findAll.bind(this);
    this.editCarriers = this.editCarriers.bind(this);
    CarriersGateway.instance = this;
  }

  async findAll(): Promise<Carrier[]> {
    return await this.getAllCarriers.execute();
  }

  async editCarriers(carriers: ICarrierAttributes[]): Promise<Carrier[]> {
    return await this.editManyCarriers.execute(carriers);
  }
}

export function useCarriersGateway() {
  const client = useApolloClient();
  const carriersGateway = CarriersGateway.getInstance(client);
  return {
    findAll: carriersGateway.findAll,
    editCarriers: carriersGateway.editCarriers,
  };
}
