import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteRenderOptionState,
} from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  bar: {
    width: '70%',
    marginBottom: '2vh',
    display: 'table',
    margin: '0 auto',
  },
  barDiv: {
    width: '100%',
  },
});

export interface SearchProductMetadataAutocompleteProps<Entity> {
  data: Entity[];
  getOptionDisabled?: (option: Entity) => boolean;
  getOptionLabel: (option: Entity) => string;
  groupBy?: (option: Entity) => string;
  handleSearch: (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => any;
  handleSelect: any;
  id?: string;
  label: string;
  loading?: boolean;
  noOptionsText?: string;
  renderOption?: (
    option: Entity,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  value: string;
}

export function SearchProductMetadataAutocomplete<Entity>({
  data,
  getOptionDisabled,
  getOptionLabel,
  groupBy,
  handleSearch,
  handleSelect,
  id,
  label,
  loading,
  noOptionsText,
  renderOption,
  value,
}: SearchProductMetadataAutocompleteProps<Entity>) {
  const classes = useStyles();

  return (
    <div className={classes.barDiv}>
      <Autocomplete
        className={classes.bar}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={getOptionLabel}
        groupBy={groupBy}
        id={id || 'search-plant-metadata'}
        inputValue={value}
        noOptionsText={noOptionsText}
        onChange={handleSelect}
        onInputChange={handleSearch}
        options={data}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderOption={renderOption}
        value={null}
      />
    </div>
  );
}
