import {
  Wholesaler,
  IWholesalerAttributes,
} from '../../core/entities/Wholesaler';
import { WholesalerProvider } from '../../core/use_cases/WholesalerProvider';
import { gql, ApolloClient } from '@apollo/client';

export const LIST_WHOLESALERS = gql`
  query {
    listWholesalers {
      id
      name
      # Add here your attributes
    }
  }
`;

export type IWholesalerAttributesData = IWholesalerAttributes & {
  id: string;
};

export interface ListWholesalersData {
  listWholesalers: Array<IWholesalerAttributesData>;
}

export class WholesalerGraphQLProvider implements WholesalerProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async findAll(): Promise<Wholesaler[]> {
    try {
      const result = await this.client?.query<ListWholesalersData>({
        query: LIST_WHOLESALERS,
      });
      return result.data.listWholesalers.map((item) => new Wholesaler(item));
    } catch (e) {
      console.log(e);
    }
  }
  save(wholesaler: Wholesaler, userId?: string): Promise<Wholesaler> {
    throw new Error('Method not implemented.');
  }
  editMany(
    entities: IWholesalerAttributes[],
    userId?: string
  ): Promise<Wholesaler[]> {
    throw new Error('Method not implemented.');
  }
}
