import './safe-area.module.css';
import * as React from 'react';
import { useTheme } from '@mui/material';

/* eslint-disable-next-line */
export interface SafeAreaProps {}

export function SafeArea(props: React.PropsWithChildren<SafeAreaProps>) {
  const theme = useTheme();
  return (
    <div
      style={{
        padding: theme.spacing(3),
        minHeight: '100%',
      }}
    >
      {props.children}
    </div>
  );
}

export default SafeArea;
