export default {
  appointments: {
    chiefComplaint: 'Motivo de consulta',
    datatableTitle: 'As minhas consultas',
    date: 'Data',
    hour: 'Hora',
    patientNote: 'Notas da sessão',
    practitioner: 'Profissional',
    practitionerContact: 'Entre em contato com o seu profissional',
  },
  newPatient: 'Novo paciente',
};
