import * as React from 'react';
import {
  EmailInput,
  PrimaryPageWithAppVersion,
  DatePicker,
  CheckboxInput,
  TextInput,
} from '@kiway/shared/ui';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Moment } from 'moment';
import ReactMarkdown from 'react-markdown';
import { Alert, Button, CircularProgress, Grid } from '@mui/material';

export interface RegisterProps {
  backToHome?: () => void;
  birthDate: Moment;
  birthDateError?: string;
  certifyLegalRepresentative: boolean;
  CGU: boolean;
  displayCertifyLegalRepresentative?: boolean;
  email: string;
  firstName?: string;
  GDPR: boolean;
  handleChange: (name: string) => (event: any) => void;
  handleEmailError?: (error: string) => void;
  handleSubmit: () => void;
  lastName?: string;
  loading?: boolean;
  message?: string;
  messageType?: 'none' | 'error' | 'success';
  referrerCode: string;
  submitDisabled?: boolean;
  type: 'patient' | 'practitioner';
}

export function Register(props: RegisterProps) {
  const { t } = useTranslation();
  return (
    <PrimaryPageWithAppVersion
      maxWidth={'xs'}
      title={t('authentication:register.title', {
        app: process.env.NX_APPBAR_NAME,
      })}
    >
      <Grid container rowSpacing={1} sx={{ marginTop: 1 }}>
        {props.messageType !== 'success' && (
          <>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    autoFocus
                    displayOnPrimaryPage
                    fullWidth
                    label={t('authentication:register.fields.firstName')}
                    onChange={props.handleChange('firstName')}
                    value={props.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    displayOnPrimaryPage
                    fullWidth
                    label={t('authentication:register.fields.lastName')}
                    onChange={props.handleChange('lastName')}
                    value={props.lastName}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <EmailInput
                displayOnPrimaryPage
                fullWidth
                label={t('authentication:register.fields.email')}
                onChange={props.handleChange('email')}
                required
                setError={props.handleEmailError}
                value={props.email}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                displayOnPrimaryPage
                error={props.birthDateError ?? ''}
                handleChange={props.handleChange('birthDate')}
                label={t('authentication:register.fields.birthDate')}
                required
                value={props.birthDate}
              />
            </Grid>
            {props.type === 'practitioner' &&
            process.env.NX_AFFILIATION_ENABLED === 'true' ? (
              <Grid item xs={12}>
                <TextInput
                  displayOnPrimaryPage
                  fullWidth
                  label={t('authentication:register.fields.referrerCode')}
                  onChange={props.handleChange('referrerCode')}
                  value={props.referrerCode}
                />
              </Grid>
            ) : (
              true
            )}
            <Grid item xs={12}>
              <CheckboxInput
                displayOnPrimaryPage
                label={
                  <ReactMarkdown linkTarget="_blank" className={'md'}>
                    {t('common:form.fields.GDPR.label')}
                  </ReactMarkdown>
                }
                labelAlign="justify"
                noMargin
                onChange={props.handleChange('GDPR')}
                value={props.GDPR}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxInput
                displayOnPrimaryPage
                label={
                  <ReactMarkdown linkTarget="_blank" className={'md'}>
                    {t('common:form.fields.CGU.label')}
                  </ReactMarkdown>
                }
                labelAlign="justify"
                noMargin
                onChange={props.handleChange('CGU')}
                value={props.CGU}
              />
            </Grid>
            {props.displayCertifyLegalRepresentative && (
              <Grid item xs={12}>
                <CheckboxInput
                  displayOnPrimaryPage
                  label={t(
                    'authentication:register.fields.certifyLegalRepresentative'
                  )}
                  labelAlign="justify"
                  noMargin
                  onChange={props.handleChange('certifyLegalRepresentative')}
                  value={props.certifyLegalRepresentative}
                />
              </Grid>
            )}
          </>
        )}
        {props.messageType !== 'none' && props.message !== '' && (
          <Grid item xs={12}>
            <Alert severity={props.messageType}>{props.message}</Alert>
          </Grid>
        )}
        {props.messageType !== 'success' && (
          <Grid item xs={12}>
            <Button
              color="minoritary"
              disabled={props.loading || props.submitDisabled}
              fullWidth
              onClick={props.handleSubmit}
              variant="contained"
            >
              {props.loading ? (
                <CircularProgress size={25} />
              ) : (
                t('authentication:register.buttons.submit')
              )}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            color="inherit"
            fullWidth
            onClick={props.backToHome}
            variant="contained"
          >
            {t('authentication:register.buttons.backToHome')}
          </Button>
        </Grid>
      </Grid>
    </PrimaryPageWithAppVersion>
  );
}
