export default {
  contactInfo: {
    mobilePhone: 'Telefone',
  },
  description: {
    title: 'Descrição',
  },
  formations: {
    title: 'Formação',
  },
  gallery: {
    title: 'Galeria de imagens',
  },
  noOnlineBooking:
    'O seu profissional não ativou a marcação de consultas online, por favor contacte-o diretamente.',
  places: {
    title: 'Locais de consulta',
  },
};
