import { Customer } from '../entities/Customer';
import { CustomerProvider } from './CustomerProvider';

export class GetAllCustomers {
  private customerProvider: CustomerProvider;

  public constructor(customerProvider: CustomerProvider) {
    this.customerProvider = customerProvider;
  }

  public execute(): Promise<Customer[]> {
    return this.customerProvider.findAll();
  }
}
