import './text-input.module.css';
import * as React from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/system';

export const StyledPrimaryPageTextField = styled(TextField)(
  ({ theme }: any) => ({
    color: theme.palette.primary.contrastText,
    '& label': {
      color: theme.palette.primary.contrastText,
    },
    '& label.Mui-focused': {
      color: theme.palette.primary.contrastText,
    },
    '& input + fieldset': {
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.contrastText,
    },
    '& .MuiInputAdornment-root': {
      '& .MuiSvgIcon-root': { color: 'white' },
    },
    '& .MuiOutlinedInput-root': {
      borderColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.contrastText,
      '& fieldset': {
        borderColor: `${theme.palette.primary.contrastText} !important`,
      },
      '&:hover:not(.Mui-error) fieldset': {
        borderColor: theme.palette.primary.contrastText,
      },
      '&.Mui-focused:not(.Mui-error) fieldset': {
        borderColor: theme.palette.primary.contrastText,
      },
      '&.Mui-error:hover': {
        color: theme.palette.error.main,
        '& fieldset': {
          borderColor: `${theme.palette.error.main} !important`,
        },
      },
      '&.Mui-error': {
        color: theme.palette.error.main,
        '& fieldset': {
          borderColor: `${theme.palette.error.main} !important`,
        },
      },
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: theme.palette.error.main,
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.primary.contrastText,
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    },
  })
);

export interface TextInputProps {
  autoFocus?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: React.ReactNode;
  margin?: 'dense' | 'none' | 'normal';
  name?: string;
  onBlur?: (...args: any[]) => any;
  onChange?: (...args: any[]) => any;
  readonly?: boolean;
  required?: boolean;
  size?: 'small' | 'medium';
  value?: string | unknown;
  displayOnPrimaryPage?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  helperText?: string;
  align?: 'left' | 'center' | 'right';
  [name: string]: any;
}

export function TextInput(props: TextInputProps) {
  const [value, setValue] = React.useState('');
  const {
    align,
    displayOnPrimaryPage,
    startAdornment,
    endAdornment,
    ...rest
  } = props;
  const componentProps = {
    disabled: props.disabled,
    fullWidth: props.fullWidth,
    InputProps: {
      readOnly: props.readonly,
      startAdornment: props.startAdornment,
      endAdornment: props.endAdornment,
      inputProps: {
        sx: { textAlign: `${align ?? 'left'}` },
      },
    },
    name: props.name,
    onBlur: props.onBlur,
    required: props.required,
    ...rest,
    onChange: (event: any) => {
      props.onChange
        ? props.onChange(event)
        : setValue(event?.target?.value ?? '');
    },
    margin: props.margin ?? 'dense',
    size: props.size ?? 'small',
    value: props.value ?? value ?? '',
  };
  return displayOnPrimaryPage ? (
    <StyledPrimaryPageTextField {...componentProps} />
  ) : (
    <TextField {...componentProps} />
  );
}

export default TextInput;
