import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Typography } from '@material-ui/core';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import { blue } from '@material-ui/core/colors';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ReactMarkdown from 'react-markdown';
import CancelIcon from '@material-ui/icons/Cancel';
import { useTranslation } from '@kiway/shared/utils/translation';
import ReleaseModal from './ReleaseModal';
import InfoIcon from '@material-ui/icons/Info';
import ExtensionIcon from '@material-ui/icons/Extension';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useMutation } from '@apollo/client';
import { SET_NOTIFICATION_AS_READ } from '.';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 430,
    maxWidth: '100%',
    whiteSpace: 'normal',
  },
  avatar: {
    marginRight: theme.spacing(1),
    backgroundColor: '#FFFFFF',
  },
  release: {
    color: blue[500],
  },
  info: {
    color: theme.palette.primary.main,
  },
  appointment: {
    color: theme.palette.primary.main,
  },
  stripeConnect: {
    color: theme.palette.secondary.main,
  },
  empty: {
    color: 'grey',
  },
}));

export function generateText(notification: any, t: any) {
  let text = '';
  if (notification.type === 'appointment') {
    text = t('notification:appointment');
  }
  if (notification.type === 'release') {
    text = t('notification:release', {
      version: notification.metadata?.release?.releaseObj?.version,
    });
  }
  if (notification.type === 'empty') {
    text = t('notification:none');
  }
  if (notification.type === 'info') {
    text =
      Object.keys(notification.metadata?.text)?.includes(t('dates:moment')) &&
      notification.metadata?.text[t('dates:moment')] !== ''
        ? notification.metadata?.text[t('dates:moment')]
        : notification.metadata?.text?.default || '';
  }
  if (notification.type === 'stripeConnect') {
    if (notification.metadata?.stripeConnect?.action === 'CREATED') {
      text = t('notification:stripeConnect.created');
    }
    if (notification.metadata?.stripeConnect?.action === 'VALIDATED') {
      text = t('notification:stripeConnect.validated');
    }
  }
  return text;
}

const NotificationCard = React.forwardRef((props: any, ref) => {
  const classes = useStyles();
  const { handleClick: handleClickProps, notification } = props;
  const [openRelease, setOpenRelease] = useState(false);
  const [release, setRelease] = useState(null);
  const { t } = useTranslation();

  const [setNotificationAsRead] = useMutation(SET_NOTIFICATION_AS_READ);

  function handleClick() {
    try {
      if (notification.type === 'appointment') {
        setNotificationAsRead({ variables: { id: notification.id } });
        handleClickProps();
        if (notification.metadata?.appointment?.appointmentId) {
          window.location.href = `/agenda/${notification.metadata.appointment.appointmentId}/edit`;
        }
      } else if (notification.type === 'release') {
        setRelease(notification.metadata.release.releaseObj.id);
        setOpenRelease(true);
      } else if (notification.type === 'info') {
        setNotificationAsRead({ variables: { id: notification.id } });
        handleClickProps();
        if (notification.metadata?.link) {
          window.location.href = notification.metadata.link;
        }
      } else if (notification.type === 'stripeConnect') {
        setNotificationAsRead({ variables: { id: notification.id } });
        handleClickProps();
      } else {
        handleClickProps();
      }
    } catch (error) {
      // DO NOTHING
    }
  }

  return (
    <Box ref={ref}>
      <MenuItem className={classes.root} onClick={handleClick}>
        <Avatar className={classes.avatar} aria-label="notification-icon">
          {notification.type === 'appointment' ? (
            <DateRangeIcon className={classes.appointment} />
          ) : notification.type === 'release' ? (
            <FiberNewIcon className={classes.release} />
          ) : notification.type === 'info' ? (
            <InfoIcon className={classes.info} />
          ) : notification.type === 'stripeConnect' ? (
            <ExtensionIcon className={classes.stripeConnect} />
          ) : (
            <CancelIcon className={classes.empty} />
          )}
        </Avatar>
        <Typography variant="body2" component="div">
          <ReactMarkdown children={generateText(notification, t)} />
        </Typography>
      </MenuItem>
      <Divider />
      {release && (
        <ReleaseModal
          releaseId={release}
          open={openRelease}
          handleClose={() => {
            setOpenRelease(false);
            handleClickProps();
            setNotificationAsRead({ variables: { id: notification.id } });
          }}
        />
      )}
    </Box>
  );
});

export default NotificationCard;
