import {
  CustomerFile,
  ICustomerFileAttributes,
} from '../../core/entities/CustomerFile';
import { CustomerFileProvider } from '../../core/use_cases/CustomerFileProvider';
import { gql, ApolloClient } from '@apollo/client';

export const LIST_MY_PATIENT_FILES = gql`
  query listMyPatientFiles($practitionerId: String) {
    listMyPatientFiles(practitionerId: $practitionerId) {
      id
      patient {
        id
        firstName
        lastName
        mobilePhone
        email
      }
      # Add here your attributes
    }
  }
`;

export const LIST_CUSTOMER_FILES = gql`
  query {
    ListCustomerFiles {
      id
      practitioner {
        id
        firstName
        lastName
      }
      patient {
        id
        firstName
        lastName
      }
      background
      redFlag
      # Add here your attributes
    }
  }
`;

export type ICustomerFileAttributesData = ICustomerFileAttributes & {
  id: string;
};

export interface ListCustomerFilesData {
  listCustomerFiles: Array<ICustomerFileAttributesData>;
}

export interface ListMyPatientFilesData {
  listMyPatientFiles: Array<any>;
}

export class CustomerFileGraphQLProvider implements CustomerFileProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async findAll(): Promise<CustomerFile[]> {
    try {
      const result = await this.client?.query<ListCustomerFilesData>({
        query: LIST_CUSTOMER_FILES,
      });
      return result.data.listCustomerFiles.map(
        (item) => new CustomerFile(item)
      );
    } catch (e) {
      console.log(e);
    }
  }

  async findByPractitioner(practitionerId: string): Promise<any[]> {
    try {
      const result = await this.client?.query<ListMyPatientFilesData>({
        query: LIST_MY_PATIENT_FILES,
        variables: {
          practitionerId: practitionerId,
        },
      });
      return result.data.listMyPatientFiles;
    } catch (e) {
      console.log(e);
    }
  }

  save(customerFile: CustomerFile, userId?: string): Promise<CustomerFile> {
    throw new Error('Method not implemented.');
  }
  editMany(
    entities: ICustomerFileAttributes[],
    userId?: string
  ): Promise<CustomerFile[]> {
    throw new Error('Method not implemented.');
  }

  search(search: string, full?: boolean): Promise<CustomerFile[]> {
    throw new Error('Method not implemented.');
  }
}
