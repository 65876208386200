import './date-picker.module.css';
import * as React from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import moment, { Moment } from 'moment';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/lab';
import { StyledPrimaryPageTextField } from '../..';

/* eslint-disable-next-line */
export interface DatePickerProps {
  displayOnPrimaryPage?: boolean;
  error?: string;
  handleChange: any;
  handleSave?: any;
  label?: string;
  required?: boolean;
  value: Moment;
}

export function DatePicker(props: DatePickerProps) {
  const { t } = useTranslation();
  const localeMoment = t('dates:moment');
  const inputDateFormat = t('dates:format.date');
  React.useEffect(() => {
    moment.locale(localeMoment);
  }, [localeMoment]);
  return (
    <LocalizationProvider dateAdapter={DateAdapter} locale={localeMoment}>
      <MuiDatePicker
        clearable
        disableFuture
        label={props.label}
        inputFormat={inputDateFormat}
        value={props.value}
        onChange={props.handleChange}
        onClose={props.handleSave}
        cancelText={t('dates:dateTimePicker.buttons.cancel.text')}
        clearText={t('dates:dateTimePicker.buttons.clear.text')}
        okText={t('dates:dateTimePicker.buttons.ok.text')}
        renderInput={(params) => {
          const textInputProps: any = {
            fullWidth: true,
            size: 'small',
            margin: 'dense',
            required: props.required,
            ...params,
            error: !!props.error && props.error !== '',
            helperText: props.error,
            inputProps: {
              ...(params?.inputProps ?? {}),
              placeholder: t('dates:format.datePlaceholder'),
            },
          };
          return props.displayOnPrimaryPage ? (
            <StyledPrimaryPageTextField {...textInputProps} />
          ) : (
            <TextField {...textInputProps} />
          );
        }}
        // views={['year', 'month', 'day']}
      />
    </LocalizationProvider>
  );
}

export default DatePicker;
