export interface IWholesalerAttributes {
  name: string;
}

export class Wholesaler {
  protected id: string;
  protected name: string;

  public constructor(
    obj: Partial<IWholesalerAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj.id || obj._id;
    this.name = obj.name;
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): Wholesaler {
    this.id = id;
    return this;
  }

  public getName(): string {
    return this.name;
  }
}
