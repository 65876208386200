import './display-address.module.css';
import { Grid, Typography, TypographyProps, Chip } from '@mui/material';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Address } from '@kiway/shared/features/authentication-react-compatible';
import { useTranslation } from '@kiway/shared/utils/translation';
import { KiwayLanguagesType } from '@kiway/shared/react-types';
import { allCountries } from '@kiway/shared/utils/enums';
import translations from '../address-form/translations';
import PhoneNumber from 'awesome-phonenumber';

export interface DisplayAddressProps {
  address: Address;
  nameVariant?: TypographyProps['variant'];
  detailsVariant?: TypographyProps['variant'];
  displayDefault?: boolean;
}

export function DisplayAddress(props: DisplayAddressProps) {
  const { addTranslationDynamically, currentLanguage, t } = useTranslation();

  if (!props.address) {
    return null;
  }

  Object.entries(translations).map(([lng, translation]) => {
    addTranslationDynamically(
      lng as KiwayLanguagesType,
      'address-form',
      translation,
      true
    );
  });

  const currentCountry = allCountries.find(
    ({ code }) =>
      code.toLowerCase() === props.address.getCountryCode()?.toLowerCase()
  );
  return (
    <Grid container>
      {props.displayDefault ? (
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Grid item>
              <Typography
                variant={props.nameVariant}
              >{`${props.address.getFirstName()} ${props.address.getLastName()}`}</Typography>
            </Grid>
            {props.displayDefault && props.address.isDefault() && (
              <Grid item>
                <Chip
                  size="small"
                  color="success"
                  label={t('authentication:editProfile.address.default.chip')}
                />
              </Grid>
            )}
          </Grid>{' '}
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography
            variant={props.nameVariant}
          >{`${props.address.getFirstName()} ${props.address.getLastName()}`}</Typography>
        </Grid>
      )}
      {props.address.getLine0() && (
        <Grid item xs={12}>
          <Typography variant={props.detailsVariant ?? 'subtitle2'}>
            {props.address.getLine0()}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant={props.detailsVariant ?? 'subtitle2'}>
          {props.address.getLine1()}
        </Typography>
      </Grid>
      {props.address.getLine2() && (
        <Grid item xs={12}>
          <Typography variant={props.detailsVariant ?? 'subtitle2'}>
            {props.address.getLine2()}
          </Typography>
        </Grid>
      )}
      {props.address.getLine3() && (
        <Grid item xs={12}>
          <Typography variant={props.detailsVariant ?? 'subtitle2'}>
            {props.address.getLine3()}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant={props.detailsVariant ?? 'subtitle2'}>
          {props.address.getZipCode()} {props.address.getCity()}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={props.detailsVariant ?? 'subtitle2'}>
          {props.address.getCountry()
            ? (props.address.getCountry().getName()?.[
                currentLanguage?.code as KiwayLanguagesType
              ] as string)?.toUpperCase()
            : currentCountry?.name?.toUpperCase() ??
              props.address.getCountryCode()?.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        &nbsp;
      </Grid>
      <Grid item xs={12}>
        <Typography variant={props.detailsVariant ?? 'subtitle2'}>
          {`${t('address-form:email')} : ${props.address.getEmail() ?? 'Ø'}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={props.detailsVariant ?? 'subtitle2'}>
          {`${t('address-form:mobilePhone')} : ${
            props.address.getMobilePhone()
              ? PhoneNumber(props.address.getMobilePhone())?.getNumber(
                  'international'
                )
              : 'Ø'
          }`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={props.detailsVariant ?? 'subtitle2'}>
          {`${t('address-form:nif')} : ${props.address.getNif() ?? 'Ø'}`}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default DisplayAddress;
