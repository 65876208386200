import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  root: {
    // width: 300,
  },
  media: {
    height: 200,
    // width: 300,
  },
})

function MediaWithoutActionsCard(props) {
  const classes = useStyles()
  const { imageSrc, imageAlt, title, description, complement, ...other } = props

  return (
    <Card className={classes.root} {...other}>
      <CardMedia className={classes.media} image={imageSrc} title={imageAlt} />
      <CardContent>
        <Typography
          style={{ wordWrap: 'break-word' }}
          gutterBottom
          variant="h5"
          component="h2"
        >
          {title}
        </Typography>
        <Typography
          style={{ wordWrap: 'break-word' }}
          gutterBottom={Boolean(complement)}
          variant="body2"
          color="textSecondary"
          component="p"
        >
          {description}
        </Typography>
        {complement && (
          <Typography
            style={{ wordWrap: 'break-word' }}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {complement}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}

export default MediaWithoutActionsCard
