export default {
  buttons: {
    addPatient: 'Adicionar paciente',
    addRDV: 'Novo agendamento',
    newOrder: 'Nova encomenda',
  },
  dataTable: {
    columns: {
      hour: 'Hora',
    },
    title: 'Agenda diária',
  },
  stats: {
    cards: {
      affiliations: {
        nbCompleted: 'Finalizado',
        nbPending: 'Em andamento',
        nbSent: 'Enviados',
        title: 'Patrocínio',
      },
      consumption: {
        smsCredits: {
          subtitle: 'Ou cerca de %X% € TTC',
          title: 'Créditos do SMS',
        },
        stripeBalance: {
          negativeText:
            'O saldo reduzirá a quantidade devida na próxima fatura',
          positiveText: 'O saldo aumentará o valor devido na próxima fatura',
          title: 'Saldo remanescente',
        },
        title: 'Consumo de seus recursos',
      },
      kpis: {
        nbManualRDVs: 'Consultas com marcação manual',
        nbOnlineRDVs: 'Consultas marcadas via online',
        nbSubPro: 'Subs Pro',
        nbSubSmsTotal: 'Subs Sms ',
        nbSubStudent: 'Subs Estudante ',
        nbSubTotal: 'Total Subs',
        nbTotalFiles: 'Pasta do paciente',
        nbTotalRDVs: 'Total de consultas',
        title: 'KPIs',
      },
      monthActivity: {
        appointmentsDonePercent: 'Sessões realizadas',
        caForecast: 'Previsão',
        nbAppointments: 'Número de sessões',
        nbNewPatients: 'Novos pacientes',
        title: 'A sua atividade mensal',
      },
      users: {
        nbAdmin: 'Administradores',
        nbBeta: 'Co-criadores',
        nbGeneratedPatients: 'Pacientes criados',
        nbPartner: 'Parceiros',
        nbPatients: 'Pacientes',
        nbPharmaco: 'Fito',
        nbPractitioners: 'Profissionais',
        nbTotalNoGeneratedPatient: 'Total Inscritos',
        title: 'Tipologia do utilizador',
      },
    },
    filters: 'Filtros',
    graph: {
      bestOfUsers: {
        title: 'Usuários mais ativos ao longo do período',
      },
      loading: 'Carregando o gráfico',
      nbOfUsers: {
        signins: 'Conexões',
        signinsUnique: 'Conexões exclusivas',
        signups: 'Inscrição',
        title: 'Número de utilizadores',
      },
    },
  },
};
