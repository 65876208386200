export default {
  body: {
    columnHeaderTooltip: 'Sort by {{columnLabel}}',
    noMatch: 'No matching records found',
    toolTip: 'Sort',
  },
  filter: {
    all: 'All',
    reset: 'RESET',
    title: 'FILTERS',
  },
  pagination: {
    displayRows: 'of',
    next: 'Next Page',
    previous: 'Previous Page',
    rowsPerPage: 'Rows per page:',
  },
  selectedRows: {
    delete: 'Delete',
    deleteAria: 'Delete Selected Rows',
    text: 'row(s) selected',
  },
  toolbar: {
    downloadCsv: 'Download CSV',
    filterTable: 'Filter Table',
    print: 'Print',
    search: 'Search',
    viewColumns: 'View Columns',
  },
  viewColumns: {
    title: 'Show Columns',
    titleAria: 'Show/Hide Table Columns',
  },
};
