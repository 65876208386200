export default {
  addPatientModal: {
    firstName: 'Prénom',
    lastName: 'Nom',
    title: 'Ajouter un nouveau patient',
  },
  appBar: {
    suffix: 'ans',
  },
  dataTable: {
    columns: {
      firstName: 'Prénom',
      lastName: 'Nom',
      lastSession: 'Dernière séance',
      mobilePhone: 'Téléphone',
      nextSession: 'Prochaine séance',
      numberOfSessions: 'Nombre de séances',
    },
    title: 'Patients',
  },
  deletePatientModal: {
    content:
      "Êtes-vous sûr de vouloir supprimer ce patient ? L'opération est irréversible.",
    title: 'Supprimer un patient',
  },
  details: {
    actions: {
      alert: 'Alerte',
      appointment: 'Ajouter un rendez-vous',
      export: 'Exporter les données du patient',
    },
    alerts: {
      label: 'Alertes contextuelles',
    },
    background: {
      label: 'Antécédents',
      redFlag: 'Important',
    },
    dataTable: {
      columns: {
        chiefComplaint: 'Motif de consultation',
        date: 'Date',
        evaluation: 'Evaluation',
      },
      title: 'Séances',
    },
    info: {
      address: 'Adresses',
      birthDate: 'Date de naissance',
      city: 'Ville',
      country: 'Pays',
      email: 'Email',
      female: 'Femme',
      firstName: 'Prénom',
      gender: 'Genre',
      label: 'Informations signalétiques',
      lastName: 'Nom',
      male: 'Homme',
      mobilePhone: 'Téléphone portable',
      other: 'Autre',
      profession: 'Profession',
      street: 'Rue',
      zipCode: 'Code postal',
    },
  },
  export: {
    chiefComplaint: 'Motif de consultation',
    comment: 'Commentaire',
    consultationPlace: 'Lieu de consultation',
    diagnosis: {
      text: 'Bilan énergétique',
    },
    endDate: 'Date de fin',
    followUpEval: {
      comment: 'Commentaire sur le traitement',
      date: "Date de l'évaluation du traitement",
      treatmentEfficiency: 'Résultats du traitement',
    },
    fourMethods: {
      text: '4 temps du diagnostic',
    },
    modalContent:
      'Votre téléchargement est prêt, veuillez cliquer sur le bouton ci-dessous.',
    modalTitle: 'Export des données du patient',
    patientNote: 'Note destinée au patient',
    startDate: 'Date de début',
    treatment: {
      text: 'Traitement',
    },
  },
  last: {
    legacy: {
      order: 'Dernière commande enregistrée',
    },
  },
  link: {
    contentModal:
      'En cliquant sur "Lier le dossier" vous allez associer le dossier patient à un compte utilisateur dont le propriétaire est votre patient.\n\nVous donnerez ainsi le contrôle à votre patient sur **ses données personnelles**.\nIl pourra également consulter la liste des **ses rendez-vous** ainsi que les **"notes patient"** qui lui sont destinées sur la séance.\n\nLa laison se fera par son email, assurez-vous la véracité des informations.',
    textButton: 'Lier le dossier',
    titleModal: 'Lier un dossier patient à son utilisateur',
  },
  snackbars: {
    patient: {
      cantLinkEmailEmpty:
        'Vous devez renseigner une adresse email pour tenter de lier le dossier patient',
      errorDelete:
        "Une erreur est survenue durant la suppression. Merci d'essayer à nouveau.",
      errorLink:
        "Une erreur est survenue. Soit le dossier est déjà lié au patient, soit le patient n'a pas encore créé de compte avec cette adresse email.",
      errorNames: 'Les champs prénom et nom sont obligatoires',
      errorPhone: "Le numéro de téléphone n'est pas valide",
      successCreate: 'Patient créé avec succès',
      successDelete: 'Patient supprimé avec succès',
      successLink:
        'Lien effectué avec succès. Votre patient pourra désormais accéder à ses rendez-vous avec vous.',
      successUpdate: 'Patient modifié avec succès',
    },
    session: {
      successCreate: 'Séance créée avec succès',
      successUpdate: 'Séance modifiée avec succès',
    },
  },
};
