import { Payment, IPaymentAttributes } from '../../core/entities/Payment';
import { PaymentProvider } from '../../core/use_cases/PaymentProvider';
import { gql, ApolloClient } from '@apollo/client';

const paymentGqlAttributes = `
  id
  amount {
    centAmount
    currency {
      id
      code
      symbol
      format
    }
  }
  status
  paymentMethod
  order {
    id
    orderNumber
    customer {
      firstName
      lastName
    }
    invoicingAddress {
      mobilePhone
    }
    shippingAddress {
      mobilePhone
    }
  }
  createdAt
  validatedAt
  completedAt
  custom
`;

export const LIST_PAYMENTS = gql`
  query {
    listPayments {
      ${paymentGqlAttributes}
    }
  }
`;

export const GET_PAYMENT_BY_ORDER_ID = gql`
  query getPaymentByOrderId($orderId: ID!) {
    getPaymentByOrderId(orderId: $orderId) {
      ${paymentGqlAttributes}
    }
  }
`;

export const SAVE_PAYMENT = gql`
  mutation savePayment($payment: PaymentInput!) {
    savePayment(payment: $payment) {
      ${paymentGqlAttributes}
    }
  }
`;

export const PAY_WITH_DEFERRED_METHOD = gql`
  mutation payWithDeferredMethod($orderId: String!, $customPayment: JSON) {
    payWithDeferredMethod(orderId: $orderId, customPayment: $customPayment) {
      ${paymentGqlAttributes}
    }
  }
`;

export type IPaymentAttributesData = IPaymentAttributes & {
  id: string;
};

export interface ListPaymentsData {
  listPayments: Array<IPaymentAttributesData>;
}

export interface GetPaymentByOrderIdData {
  getPaymentByOrderId: IPaymentAttributesData;
}

export interface SavePaymentData {
  savePayment: IPaymentAttributesData;
}

export interface PayWithDeferrerMethodData {
  payWithDeferredMethod: IPaymentAttributesData;
}

export class PaymentGraphQLProvider implements PaymentProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async findOneByOrderId(orderId: string): Promise<Payment> {
    try {
      const result = await this.client?.query<GetPaymentByOrderIdData>({
        query: GET_PAYMENT_BY_ORDER_ID,
        variables: { orderId },
      });
      return new Payment(result.data.getPaymentByOrderId);
    } catch (e) {
      console.log(e);
    }
  }

  async findAll(): Promise<Payment[]> {
    try {
      const result = await this.client?.query<ListPaymentsData>({
        query: LIST_PAYMENTS,
      });
      return result.data.listPayments.map((item) => new Payment(item));
    } catch (e) {
      console.log(e);
    }
  }

  async save(payment: Payment, userId?: string): Promise<Payment> {
    try {
      const result = await this.client?.mutate<SavePaymentData>({
        mutation: SAVE_PAYMENT,
        variables: { payment: payment.toInput() },
      });
      return new Payment(result.data.savePayment);
    } catch (e) {
      console.log(e);
    }
  }

  async payWithDeferredMethod(
    orderId: string,
    customPayment: any
  ): Promise<Payment> {
    try {
      const result = await this.client?.mutate<PayWithDeferrerMethodData>({
        mutation: PAY_WITH_DEFERRED_METHOD,
        variables: { orderId, customPayment },
      });
      return new Payment(result.data.payWithDeferredMethod);
    } catch (e) {
      console.log(e);
    }
  }

  editMany(
    entities: IPaymentAttributes[],
    userId?: string
  ): Promise<Payment[]> {
    throw new Error('Method not implemented.');
  }
}
