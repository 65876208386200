export default {
  betaSub: {
    joinUs: '',
    checkbox: {
      beta: '',
    },
    button: {
      confirmSignUpText: '',
    },
    snackbar: {
      success: '',
      errorDuplicate: '',
      errorCaptcha: '',
      error: '',
      submitDisabled:
        'Please enter a valid email and tick the box to subscribe',
    },
  },
  headline: '',
  slogan: '',
  espacePro: '',
  espacePatient: '',
  rows: {
    simplifyActivity: {
      title: '',
      cards: {
        onlineAgenda: {
          title: '',
          text: '',
        },
        personalWebsite: {
          title: '',
          text: '',
        },
        smsReminder: {
          title: '',
          text: '',
        },
      },
    },
    improveFollowUp: {
      title: '',
      cards: {
        userRecords: {
          title: '',
          text: '',
        },
        popupAlert: {
          title: '',
          text: '',
        },
        sessionNotes: {
          title: '',
          text: '',
        },
      },
    },
    teamAtYourService: {
      title: '',
      teamMembers: {
        guillaume: {
          name: '',
          position: '',
          description: '',
        },
        julien: {
          name: '',
          position: '',
          description: '',
        },
        clement: {
          name: '',
          position: '',
          description: '',
        },
        leo: {
          name: '',
          position: '',
          description: '',
        },
      },
    },
    readUs: {
      title: '',
      phrase: '',
      buttonText: '',
    },
    contactUs: {
      title: '',
      snackbar: {
        success: '',
        error: '',
      },
    },
  },
  footer: {
    followUs: '',
  },
};
