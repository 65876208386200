import {
  Order,
  IOrderAttributes,
  OrderStat,
  OrderStatPeriod,
  OrderStatName,
} from '../../core/entities/Order';
import {
  OrderProvider,
  UserLastOrdersType,
} from '../../core/use_cases/OrderProvider';
import { gql, ApolloClient } from '@apollo/client';
import { CustomerInput } from '../../core/use_cases/CreateOrder';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { IAddressAttributes } from '@kiway/shared/features/authentication-react-compatible';

const addressGqlAttributes = `
  id
  city
  default
  line0
  line1
  line2
  line3
  firstName
  lastName
  zipCode
  countryCode
  email
  mobilePhone
  country {
    id
    code
    name
    vatPlant
    vatPowder
  }
  nif
`;

const orderGqlAttributes = `  
  id
  openedAt
  paidAt
  cancelledAt
  startPreparationAt
  endPreparationAt
  startShipmentAt
  endShipmentAt
  orderNumber
  orderStatus
  paymentStatus
  packingStatus
  shipmentStatus
  shippingLabelStoredFile
  shippingTrackingNumber
  shippingAddress {
    ${addressGqlAttributes}
  }
  invoicingAddress {
    ${addressGqlAttributes}
  }
  lineItems {
    id
    groupBy
    quantity
    custom
    modelProduct
    product {
      id
      name
      custom
      taxRates {
        name
        country
        percent
        includes
      }
      price {
        currency {
          id
          symbol
          code
          format
          position
        }
        centAmount
      }
      variations {
        id
        name
        productRef
        available
        price {
          currency {
            id
            symbol
            code
            format
            position
          }
          centAmount
        }
        productVariationType {
          id
          shortcode
          price {
            currency {
              id
              symbol
              code
              format
              position
            }
            centAmount
          }
          name
          variationAttributesValue {
            value
            variationAttribute {
              name
            }
          }
        }
        weight
      }
    }
    productVariation {
      id
      available
      name
      productRef
      product {
        id
        name
        taxRates {
          name
          country
          percent
          includes
        }
        price {
          currency {
            id
            symbol
            code
            format
            position
          }
          centAmount
        }
      }
      price {
        currency {
          id
          symbol
          code
          format
          position
        }
        centAmount
      }
      productVariationType {
        id
        shortcode
        price {
          currency {
            id
            symbol
            code
            format
            position
          }
          centAmount
        }
        variationAttributesValue {
          value
          variationAttribute {
            name
          }
        }
        name
      }
      weight
    }
  }
  totalPrice {
    currency {
      id
      code
      symbol
      format
      position
    }
    centAmount
  }
  taxedTotalPrice {
    totalNet {
      currency {
        id
        code
        symbol
        format
        position
      }
      centAmount
    }
    totalGross {
      currency {
        id
        code
        symbol
        format
        position
      }
      centAmount
    }
  }
  customer {
    id
    firstName
    lastName
    email
    mobilePhone
    custom
  }
  prescriber {
    id
    firstName
    lastName
    email
    mobilePhone
    custom
  }
  custom
  packingWeight
  shippingMethod {
    id
    name
    carrier {
      id
      name
      code
      trackingLink
    }
    active
    displayOrder
    productCode
    countryZone {
      id
      name
      countries {
        id
        name
        code
      }
    }
    prices {
      id
      minWeight
      maxWeight
      price {
        currency {
          id
          code
          symbol
          format
          position
        }
        centAmount
      }
    }
  }
  paymentMethod
  payment {
    id
    amount {
      centAmount
      currency {
        id
        code
        symbol
        format
        position
      }
    }
    status
    paymentMethod
    order {
      id
      orderNumber
    }
    createdAt
    validatedAt
    completedAt
    custom
  }
  invoice {
    id
    invoiceNumber
    invoiceDate
    index
    invoiceMonth
    invoiceYear
    file
    totalGrossAmount
    totalNetAmount
    status
  }
  preparedBy {
    id
    firstName
    lastName
  }
  sentBy {
    id
    firstName
    lastName
  }
  deleted
`;

export const LIST_ORDERS = gql`
  query {
    listOrders {
      ${orderGqlAttributes}
      # Add here your attributes
    }
  }
`;

export const GET_ORDER = gql`
  query getOrder($orderId: String) {
    getOrder(orderId: $orderId) {
      ${orderGqlAttributes}
    }
  }
`;
export const GET_LAST_ORDERS = gql`
  query getLastOrders($userType: String!, $lastNb: Int, $userId: String) {
    getLastOrders(userType: $userType, lastNb: $lastNb, userId: $userId) {
      ${orderGqlAttributes}
    }
  }
`;
export const GET_ORDER_PUBLIC_CHECKOUT = gql`
  query getOrderPublicCheckout($orderId: String!, $checkEmail: String) {
    getOrderPublicCheckout(orderId: $orderId, checkEmail: $checkEmail)
  }
`;
export const GET_ORDER_STRIPE_CHECKOUT_SESSION = gql`
  query getOrderStripeCheckoutSession(
    $orderId: String
    $successUrl: String
    $cancelUrl: String
  ) {
    getOrderStripeCheckoutSession(
      orderId: $orderId
      successUrl: $successUrl
      cancelUrl: $cancelUrl
    )
  }
`;
export const GET_ORDER_SYSTEMPAY_CHECKOUT_SESSION = gql`
  query getOrderSystempayCheckoutSession(
    $orderId: String
    $successUrl: String
    $cancelUrl: String
    $refusedUrl: String
    $errorUrl: String
  ) {
    getOrderSystempayCheckoutSession(
      orderId: $orderId
      successUrl: $successUrl
      cancelUrl: $cancelUrl
      refusedUrl: $refusedUrl
      errorUrl: $errorUrl
    )
  }
`;
export const GET_ORDER_STATS = gql`
  query getOrderStats($period: OrderStatPeriod, $statsNames: [OrderStatName]) {
    getOrderStats(period: $period, statsNames: $statsNames) {
      type
      name
      fromDate
      toDate
      data
      prevData
      delta
      deltaValue
      period
    }
  }
`;
export const SEND_PAYMENT_REMINDER_BY_MAIL = gql`
  mutation sendPaymentReminderByMail($orderId: String!) {
    sendPaymentReminderByMail(orderId: $orderId)
  }
`;
export const SEND_TRACKING_BY_MAIL = gql`
  mutation sendTrackingByMail($orderId: String!) {
    sendTrackingByMail(orderId: $orderId)
  }
`;
export const GENERATE_LABEL = gql`
  mutation generateLabel($orderId: String!) {
    generateLabel(orderId: $orderId) {
      ${orderGqlAttributes}
    }
  }
`;
export const EDIT_MULTIPLE_ORDERS = gql`
  mutation editManyOrders($orders: [OrderInput]) {
    editManyOrders(orders: $orders) {
      ${orderGqlAttributes}
    }
  }
`;
export const CREATE_ORDER = gql`
  mutation createOrder($prescriber: CustomerOrderInput!,$customer: CustomerOrderInput) {
    createOrder(prescriber: $prescriber, customer: $customer) {
      ${orderGqlAttributes}
    }
  }
`;
export const CHANGE_ORDER_CUSTOMER = gql`
  mutation changeOrderCustomer($orderId: String!,$customer: CustomerOrderInput!) {
    changeOrderCustomer(orderId: $orderId, customer: $customer) {
      ${orderGqlAttributes}
    }
  }
`;
export const CHANGE_SHIPPING_METHOD = gql`
  mutation changeShippingMethod($orderId: String!, $shippingMethodId: String!) {
    changeShippingMethod(orderId: $orderId, shippingMethodId: $shippingMethodId) {
      ${orderGqlAttributes}
    }
  }
`;
export const CHANGE_SHIPPING_ADDRESS = gql`
  mutation changeShippingAddress(
    $orderId: String!
    $shippingAddress: AddressInput!
  ) {
    changeShippingAddress(orderId: $orderId, shippingAddress: $shippingAddress) {
      ${orderGqlAttributes}
    }
  }
`;

export const ON_ORDER_CREATE = gql`
  subscription {
    onOrderCreate {
      order {
        ${orderGqlAttributes}
      }
    }
  }
`;

export type IOrderAttributesData = IOrderAttributes & {
  id: string;
};

export interface ListOrdersData {
  listOrders: Array<IOrderAttributesData>;
}

export interface GetOrderData {
  getOrder: IOrderAttributesData;
}

export interface GetLastOrdersData {
  getLastOrders: IOrderAttributesData[];
}

export interface GetOrderPublicCheckoutData {
  getOrderPublicCheckout: IOrderAttributesData;
}

export interface GetOrderStripeCheckoutSessionData {
  getOrderStripeCheckoutSession: string;
}

export interface GetOrderSystempayCheckoutSessionData {
  getOrderSystempayCheckoutSession: any;
}

export interface GetOrderStatsData {
  getOrderStats: OrderStat[];
}

export interface SendPaymentReminderByMailData {
  sendPaymentReminderByMail: boolean;
}

export interface SendTrackingByMailData {
  sendTrackingByMail: boolean;
}

export interface GenerateLabelData {
  generateLabel: IOrderAttributesData;
}

export interface EditManyOrdersData {
  editManyOrders: Array<IOrderAttributesData>;
}

export interface CreateOrderData {
  createOrder: IOrderAttributesData;
}

export interface ChangeOrderCustomerData {
  changeOrderCustomer: IOrderAttributesData;
}

export interface ChangeShippingMethodData {
  changeShippingMethod: IOrderAttributesData;
}

export interface ChangeShippingAddressData {
  changeShippingAddress: IOrderAttributesData;
}

export interface OnOrderCreateData {
  onOrderCreate: { order: IOrderAttributesData };
}

export class OrderGraphQLProvider implements OrderProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async generateLabel(orderId: string): Promise<Order> {
    try {
      const result = await this.client?.mutate<
        GenerateLabelData,
        { orderId: string }
      >({
        mutation: GENERATE_LABEL,
        variables: {
          orderId: orderId,
        },
      });
      return new Order(result.data.generateLabel);
    } catch (e) {
      console.log(e);
    }
  }

  findByCustomerId(customerId: string): Promise<Order[]> {
    throw new Error('Method not implemented.');
  }

  async findAll(): Promise<Order[]> {
    try {
      const result = await this.client?.query<ListOrdersData>({
        query: LIST_ORDERS,
        fetchPolicy: 'network-only',
      });
      return result.data.listOrders.map((item) => new Order(item));
    } catch (e) {
      console.log(e);
    }
  }
  async findOneById(orderId: string): Promise<Order> {
    try {
      const result = await this.client?.query<
        GetOrderData,
        { orderId: string }
      >({
        query: GET_ORDER,
        fetchPolicy: 'network-only',
        variables: {
          orderId,
        },
      });
      return new Order(result.data.getOrder);
    } catch (e) {
      console.log(e);
    }
  }
  async editMany(
    orders: IOrderAttributes[],
    userId?: string
  ): Promise<Order[]> {
    try {
      const result = await this.client?.mutate<
        EditManyOrdersData,
        { orders: IOrderAttributes[] }
      >({
        mutation: EDIT_MULTIPLE_ORDERS,
        variables: {
          orders: orders,
        },
      });
      return result.data.editManyOrders.map((item) => new Order(item));
    } catch (e) {
      console.log(e);
    }
  }
  async save(order: Order, userId?: string): Promise<Order> {
    try {
      const result = await this.client?.mutate<
        EditManyOrdersData,
        { orders: IOrderAttributes[] }
      >({
        mutation: EDIT_MULTIPLE_ORDERS,
        variables: {
          orders: [order.toInput()],
        },
      });
      return result.data.editManyOrders.map((item) => new Order(item))?.[0];
    } catch (e) {
      console.log(e);
    }
  }
  async createOrder(
    prescriber: CustomerInput,
    customer?: CustomerInput
  ): Promise<Order> {
    try {
      const result = await this.client?.mutate<
        CreateOrderData,
        { customer?: CustomerInput; prescriber: CustomerInput }
      >({
        mutation: CREATE_ORDER,
        variables: {
          customer,
          prescriber,
        },
      });
      return result.data.createOrder
        ? new Order(result.data.createOrder)
        : null;
    } catch (e) {
      console.log(e);
    }
  }
  async changeOrderCustomer(
    orderId: string,
    customer: CustomerInput
  ): Promise<Order> {
    try {
      const result = await this.client?.mutate<
        ChangeOrderCustomerData,
        { orderId: string; customer: CustomerInput }
      >({
        mutation: CHANGE_ORDER_CUSTOMER,
        variables: {
          orderId,
          customer,
        },
      });
      return result.data.changeOrderCustomer
        ? new Order(result.data.changeOrderCustomer)
        : null;
    } catch (e) {
      console.log(e);
    }
  }
  async changeShippingMethod(
    orderId: string,
    shippingMethodId: string
  ): Promise<Order> {
    try {
      const result = await this.client?.mutate<
        ChangeShippingMethodData,
        { orderId: string; shippingMethodId: string }
      >({
        mutation: CHANGE_SHIPPING_METHOD,
        variables: {
          orderId,
          shippingMethodId,
        },
      });
      return new Order(result.data.changeShippingMethod);
    } catch (e) {
      console.log(e);
    }
  }
  async changeShippingAddress(
    orderId: string,
    shippingAddress: IAddressAttributes
  ): Promise<Order> {
    try {
      const result = await this.client?.mutate<
        ChangeShippingAddressData,
        { orderId: string; shippingAddress: IAddressAttributes }
      >({
        mutation: CHANGE_SHIPPING_ADDRESS,
        variables: {
          orderId,
          shippingAddress,
        },
      });
      return new Order(result.data.changeShippingAddress);
    } catch (e) {
      console.log(e);
    }
  }
  async findLastOrders(
    userType: UserLastOrdersType,
    lastNb?: number,
    userId?: string
  ): Promise<Order[]> {
    try {
      const result = await this.client?.query<
        GetLastOrdersData,
        { userType: UserLastOrdersType; userId?: string; lastNb?: number }
      >({
        query: GET_LAST_ORDERS,
        fetchPolicy: 'network-only',
        variables: {
          userType,
          userId,
          lastNb,
        },
      });
      return result.data.getLastOrders.map((item) => new Order(item));
    } catch (e) {
      console.log(e);
    }
  }
  async getOrderPublicCheckout(
    orderId: string,
    checkEmail?: string
  ): Promise<Order> {
    try {
      const result = await this.client?.query<
        GetOrderPublicCheckoutData,
        { orderId: string; checkEmail?: string }
      >({
        query: GET_ORDER_PUBLIC_CHECKOUT,
        variables: {
          orderId,
          checkEmail,
        },
      });
      return new Order(result.data.getOrderPublicCheckout);
    } catch (e) {
      console.log(e);
    }
  }
  async getOrderStripeCheckoutSession(
    orderId: string,
    successUrl: string,
    cancelUrl: string
  ): Promise<string> {
    try {
      const result = await this.client?.query<
        GetOrderStripeCheckoutSessionData,
        { orderId: string; successUrl: string; cancelUrl: string }
      >({
        query: GET_ORDER_STRIPE_CHECKOUT_SESSION,
        fetchPolicy: 'network-only',
        variables: {
          orderId,
          successUrl,
          cancelUrl,
        },
      });
      return result.data.getOrderStripeCheckoutSession;
    } catch (e) {
      console.log(e);
    }
  }
  async getOrderSystempayCheckoutSession(
    orderId: string,
    successUrl: string,
    cancelUrl: string,
    refusedUrl: string,
    errorUrl: string
  ): Promise<any> {
    try {
      const result = await this.client?.query<
        GetOrderSystempayCheckoutSessionData,
        {
          orderId: string;
          successUrl: string;
          cancelUrl: string;
          refusedUrl: string;
          errorUrl: string;
        }
      >({
        query: GET_ORDER_SYSTEMPAY_CHECKOUT_SESSION,
        fetchPolicy: 'network-only',
        variables: {
          orderId,
          successUrl,
          cancelUrl,
          refusedUrl,
          errorUrl,
        },
      });
      return result.data.getOrderSystempayCheckoutSession;
    } catch (e) {
      console.log(e);
    }
  }
  async sendPaymentReminderByMail(orderId: string): Promise<boolean> {
    try {
      const result = await this.client?.mutate<
        SendPaymentReminderByMailData,
        { orderId: string }
      >({
        mutation: SEND_PAYMENT_REMINDER_BY_MAIL,
        variables: {
          orderId,
        },
      });
      return result.data.sendPaymentReminderByMail;
    } catch (e) {
      console.log(e);
    }
  }
  async sendTrackingByMail(orderId: string): Promise<boolean> {
    try {
      const result = await this.client?.mutate<
        SendTrackingByMailData,
        { orderId: string }
      >({
        mutation: SEND_TRACKING_BY_MAIL,
        variables: {
          orderId,
        },
      });
      return result.data.sendTrackingByMail;
    } catch (e) {
      console.log(e);
    }
  }
  open(orderId: string): Promise<Order> {
    throw new Error('Method not implemented.');
  }

  onOrderCreate(callback: (order: Order) => any): void {
    try {
      this.client
        ?.subscribe<OnOrderCreateData>({
          query: ON_ORDER_CREATE,
        })
        .subscribe({
          next(data) {
            callback(new Order(data.data.onOrderCreate.order));
          },
          error(err) {
            console.log(err);
          },
        });
    } catch (e) {
      console.log(e);
    }
  }

  async getOrderStats(
    period: OrderStatPeriod,
    statsNames: OrderStatName[]
  ): Promise<OrderStat[]> {
    try {
      const result = await this.client?.query<
        GetOrderStatsData,
        { period: OrderStatPeriod; statsNames: OrderStatName[] }
      >({
        query: GET_ORDER_STATS,
        fetchPolicy: 'network-only',
        variables: {
          period,
          statsNames,
        },
      });
      return result.data.getOrderStats;
    } catch (e) {
      console.log(e);
    }
  }
}
