import * as React from 'react';
import {
  Formula,
  FormulaArrays,
  FormulaMetadataSearchReturn,
} from '../../../core/entities/Formula';
import { FormulaDetailsHeader } from './FormulaDetailsHeader';
import { SearchProductMetadataAutocompleteController } from '../shared';
import { FormulaDetailsComposition } from './FormulaDetailsComposition';
import { Grid, Button } from '@material-ui/core';
import { ProductDetailsMetadata } from '../shared';
import { useTranslation } from '@kiway/shared/utils/translation';
import { links } from '../../../config';
import { Link } from 'react-router-dom';
import {
  ProductMetadata,
  formulaMetadataTypes,
} from '../../../core/entities/ProductMetadata';

export interface FormulaDetailsProps {
  handleAdd: (type: FormulaArrays, value: ProductMetadata) => any;
  handleChange: (type: string) => (value: string | number | boolean) => any;
  handleChangeComposition?: (id: string, event: React.ChangeEvent) => any;
  handleDelete?: (type: string) => (id: string) => any;
  handleSave: (type: string) => (id?: string) => any;
  loading?: boolean;
  loadingType?: string;
  formula: Formula;
  searchCallback: (value: string) => Promise<FormulaMetadataSearchReturn>;
}

const getMetadataName = (element) => element.getName();

export function FormulaDetails({
  handleAdd,
  handleChange,
  handleChangeComposition,
  handleDelete,
  handleSave,
  loading,
  loadingType,
  formula,
  searchCallback,
}: React.PropsWithChildren<FormulaDetailsProps>) {
  const { t } = useTranslation();
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            size={'small'}
            component={Link}
            to={`/${links.main}/${links.formulas}`}
          >
            {t('pharmaco:backToList')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <FormulaDetailsHeader
            handleChange={handleChange}
            handleSave={handleSave}
            loading={
              loading && (loadingType === 'details' || loadingType === null)
            }
            formula={formula}
          />
        </Grid>
        <Grid item xs={12}>
          <SearchProductMetadataAutocompleteController
            handleSelect={handleAdd}
            product={formula}
            searchCallback={searchCallback}
          />
        </Grid>
        <Grid item xs={12}>
          {/* Composition */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormulaDetailsComposition
                handleChange={handleChangeComposition}
                handleDelete={handleDelete('plant')}
                handleSave={handleSave('composition')}
                loading={
                  loading &&
                  (loadingType === 'composition' || loadingType === null)
                }
                composition={formula?.getComposition()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {/* Metadatas */}
          <Grid container spacing={3}>
            {formulaMetadataTypes.map((productMetadataType) => (
              <Grid item xs={12} sm={6} md={4} key={productMetadataType}>
                <ProductDetailsMetadata
                  data={formula
                    ?.getMetadatas()
                    ?.filter(
                      ProductMetadata.filterByMetadataType(productMetadataType)
                    )}
                  getLabel={getMetadataName}
                  handleDelete={
                    handleDelete ? handleDelete(productMetadataType) : undefined
                  }
                  loading={
                    loading &&
                    (loadingType === productMetadataType ||
                      loadingType === null)
                  }
                  title={t(
                    `pharmaco:productMetadatas.${productMetadataType}.cardTitle`
                  )}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
