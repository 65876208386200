import * as React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Link, Redirect } from 'react-router-dom';
import config, { links } from '../../../config';
import { Order } from '@kiway/ecommerce-react-compatible';
import { MobileSafeArea, TextInput, DisplayAddress } from '@kiway/shared/ui';

interface OrderShipmentProps {
  order: Order;
  loading: boolean;
  loadingGenerateLabel?: boolean;
  handleClickFinish: () => void;
  handleGenerateLabel: () => void;
  handleSave: () => void;
}

export function OrderShipment({
  order,
  loading,
  loadingGenerateLabel,
  handleClickFinish,
  handleGenerateLabel,
  handleSave,
}: OrderShipmentProps) {
  if (!order) {
    return null;
  }

  const [totalWeight, setTotalWeight] = React.useState<string>(
    `${Number(order.getTotalWeight()).toFixed(1)}`
  );
  const [trackingNumber, setTrackingNumber] = React.useState<string>(
    order.getShippingTrackingNumber() ?? ''
  );

  React.useEffect(() => {
    setTrackingNumber(order.getShippingTrackingNumber());
  }, [order.getShippingTrackingNumber()]);

  const { currentLanguage, t } = useTranslation();

  return !order.getStartShipmentAt() ? (
    <>
      <Redirect to={links.getOrderDetailsScreen(order.getId())} />
    </>
  ) : !loading ? (
    <MobileSafeArea>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Button
            size={'small'}
            color={'inherit'}
            component={Link}
            to={`${links.getOrderDetailsScreen(order.getId())}`}
          >
            {t('pharmaco:backToOrderDetails')}
          </Button>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Typography variant="h4" align="center">
            {t(`pharmaco:prescription.order.label`)} n°
            {order?.getOrderNumber()}
          </Typography>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h5" align="center">
            {t(`pharmaco:orderDetails.shipment.label`)}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'lightblue',
            borderRadius: '5px',
            mt: 2,
            mb: 2,
            p: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography fontWeight="bold">
            {t('pharmaco:orderDetails.comment.label')} :
          </Typography>
          <Typography variant="subtitle1" fontStyle="italic" sx={{ ml: 1 }}>
            {order?.getCustom()?.['preparerComment']
              ? order?.getCustom()?.['preparerComment']
              : t('pharmaco:orderDetails.comment.none')}
          </Typography>
        </Box>
        <Box sx={{ width: '100%', flex: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%' }}>
                <CardHeader
                  title={t(
                    `${config.namespace}:orderDetails.order.deliveryAddress`
                  )}
                />
                <CardContent>
                  <DisplayAddress
                    address={order.getShippingAddress()}
                    nameVariant={'h6'}
                    detailsVariant={'subtitle1'}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%' }}>
                <CardHeader
                  title={t(
                    `${config.namespace}:orderDetails.shipment.weighing`
                  )}
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          {t(
                            `${config.namespace}:orderDetails.packing.weight.content`
                          )}
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          {`${(order.getItemsWeight() ?? 0).toFixed(2)}g`}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          {t(
                            `${config.namespace}:orderDetails.packing.weight.packing`
                          )}
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          {`${(order.getPackingWeight() ?? 0).toFixed(2)}g`}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems="center">
                        <Grid item xs={6}>
                          {t(
                            `${config.namespace}:orderDetails.packing.weight.total`
                          )}
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <TextInput
                            endAdornment={
                              <span style={{ marginLeft: '5px' }}>g</span>
                            }
                            fullWidth
                            onChange={(e) => {
                              order.setPackingWeight(
                                Number(e.target.value ?? '0') -
                                  order.getItemsWeight()
                              );
                              setTotalWeight(e.target.value ?? '0');
                            }}
                            helperText={
                              order.getTotalWeight() < order.getItemsWeight()
                                ? t(
                                    `${config.namespace}:orderDetails.packing.weight.error`
                                  )
                                : ''
                            }
                            error={
                              order.getTotalWeight() < order.getItemsWeight()
                            }
                            value={totalWeight}
                            align="right"
                            onBlur={handleSave}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%' }}>
                <CardHeader
                  title={t(
                    `${config.namespace}:orderDetails.shipment.transporter.label`
                  )}
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          {t(
                            `${config.namespace}:orderDetails.shipment.transporter.name`
                          )}
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          {
                            order
                              .getShippingMethod()
                              ?.getCarrier()
                              ?.getName()?.[currentLanguage?.code]
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          {t(
                            `${config.namespace}:orderDetails.shipment.transporter.method`
                          )}
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          {
                            order.getShippingMethod()?.getName()?.[
                              currentLanguage?.code
                            ]
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          {t(
                            `${config.namespace}:orderDetails.shipment.transporter.price`
                          )}
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          {order
                            .getShippingMethod()
                            ?.getPrice(
                              order.getShippingAddress()?.getCountryCode(),
                              order.getTotalWeight()
                            )
                            ?.toString()}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          {t(
                            `${config.namespace}:orderDetails.shipment.trackingNumber`
                          )}
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <TextInput
                            fullWidth
                            onChange={(e) => {
                              setTrackingNumber(e.target.value ?? '0');
                              order.setShippingTrackingNumber(
                                e.target.value ?? '0'
                              );
                            }}
                            value={trackingNumber}
                            align="right"
                            onBlur={handleSave}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%' }}>
                <CardHeader
                  title={t(
                    `${config.namespace}:orderDetails.shipment.carrierLabel.label`
                  )}
                />
                <CardContent>
                  <Grid container spacing={2}>
                    {!['nacex', 'dhl/colissimo'].includes(
                      order?.getShippingMethod()?.getCarrier()?.getCode()
                    ) && (
                      <Grid item xs={12}>
                        <Typography>
                          {t(
                            `${config.namespace}:orderDetails.shipment.carrierLabel.canNotGenerateLabel`
                          )}
                        </Typography>
                      </Grid>
                    )}
                    {['nacex', 'dhl/colissimo'].includes(
                      order?.getShippingMethod()?.getCarrier()?.getCode()
                    ) &&
                      !order?.getShippingLabelStoredFile()?.url && (
                        <Grid item xs={12}>
                          <Button
                            color="minoritary"
                            variant="contained"
                            onClick={handleGenerateLabel}
                            disabled={loadingGenerateLabel}
                          >
                            {t(
                              `${config.namespace}:orderDetails.shipment.carrierLabel.generateLabel`
                            )}
                          </Button>
                        </Grid>
                      )}
                    {order?.getShippingLabelStoredFile()?.url && (
                      <>
                        <Grid item xs={12}>
                          <Button
                            color="minoritary"
                            variant="contained"
                            href={order?.getShippingLabelStoredFile()?.url}
                            target="_blank"
                          >
                            {t(
                              `${config.namespace}:orderDetails.shipment.carrierLabel.displayLabelNewTab`
                            )}
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          {order?.getShippingLabelStoredFile()?.contentType ===
                          'application/pdf' ? (
                            <iframe
                              src={`${
                                order?.getShippingLabelStoredFile()?.url
                              }#navpanes=0`}
                              style={{
                                width: '11cm',
                                maxWidth: '100%',
                                height: '17cm',
                              }}
                            />
                          ) : order?.getShippingLabelStoredFile()
                              ?.contentType === 'image/png' ? (
                            <img
                              alt="parcel label"
                              src={order?.getShippingLabelStoredFile()?.url}
                              style={{
                                width: '14.85cm',
                                maxWidth: '100%',
                                height: '10.5cm',
                              }}
                            />
                          ) : null}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flex: 1 }}></Box>
        <Box sx={{ width: '100%', pt: 4, pb: 4 }}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                disabled={!order.canFinishShipment()}
                onClick={handleClickFinish}
                variant="contained"
                color="minoritary"
              >
                {t('pharmaco:orderDetails.order.finishPreparation')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </MobileSafeArea>
  ) : null;
}
