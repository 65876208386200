export default {
  affiliation: 'Parrainage',
  agenda: 'Agenda',
  appointments: 'Mes rendez-vous',
  beta: 'Bêta',
  dashboard: 'Tableau de bord',
  files: 'Dossiers',
  forgotPassword: 'Mot de passe oublié',
  invoicing: 'Facturation',
  notifications: 'Notifications',
  pageNotFound: 'Page introuvable',
  profile: 'Edition du profil',
  register: 'Inscription',
  services: 'Mes services',
  settings: {
    activity: 'Généraux',
    main: 'Paramètres',
    notifier: 'Notifications',
  },
  signIn: 'Connexion',
  subscription: 'Abonnement',
  tuto: {
    intro: 'Intro',
    subtitle: 'Tutoriel',
  },
  users: 'Utilisateurs',
};
