import * as React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { links } from '../../../config';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useOrdersGateway } from '@kiway/ecommerce-react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Order } from '@kiway/ecommerce-react-compatible';
import { PharmacoOrder } from '../../../core/entities/PharmacoOrder';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AuthContext } from '@kiway/shared/features/authentication-react';
import { isGranted } from '@kiway/shared/utils/access-control-react';
import { OrderDetailsPrep } from './OrderDetailsPrep';
import { OrderDetailsAdminWholesaler } from './OrderDetailsAdminWholesaler';
import { OrderDetailsCustomer } from './OrderDetailsCustomer';
import { OrderDetailsPrescriber } from './OrderDetailsPrescriber';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Address } from '@kiway/shared/features/authentication-react-compatible';
import { useSnackbar } from '@kiway/shared/utils/snackbar';
import { useTranslation } from '@kiway/shared/utils/translation';

export function OrderDetailsController({ id, ...props }) {
  const history = useHistory();
  const {
    findOne,
    editOrders,
    sendPaymentReminderByMail,
    sendTrackingByMail,
  } = useOrdersGateway();
  const [loading, setLoading] = React.useState(false);
  const [order, setOrder] = React.useState<Order[]>([null]);
  const [pharmacoOrder, setPharmacoOrder] = React.useState<PharmacoOrder[]>([
    null,
  ]);
  const {
    authState: { userInfo },
  } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const { addErrorSnackbar, addSuccessSnackbar } = useSnackbar();

  const updateOrder = () => {
    setLoading(true);
    if (id) {
      findOne(id)
        .then(async (order) => {
          if (order && order.getId()) {
            setOrder([order]);
            setPharmacoOrder([
              new PharmacoOrder({ order, saveCallback: handleSaveOrder }),
            ]);
          }
        })
        .finally(() => setLoading(false));
    }
    return () => {
      setOrder(null);
    };
  };

  React.useEffect(() => {
    return updateOrder();
  }, [id]);

  const handleSaveOrder = (forcedOrder?: Order): Promise<Order[]> => {
    return new Promise((res) =>
      editOrders([(forcedOrder ?? order[0])?.toInput(true)]).then((order) => {
        setOrder(order);
        setPharmacoOrder([
          new PharmacoOrder({
            order: order?.[0],
            saveCallback: handleSaveOrder,
          }),
        ]);
        res(order);
      })
    );
  };

  const handleChangeStateOrder = (order: Order) => {
    setOrder([order]);
    setPharmacoOrder([
      new PharmacoOrder({ order, saveCallback: handleSaveOrder }),
    ]);
    return order;
  };

  const handleOrderPreparationClick = async () => {
    if (!order || !order[0] || !order[0].canDoPreparation()) {
      return;
    }
    if (!order[0]?.getStartPreparationAt()) {
      if (!order[0]?.canStartPreparation()) {
        return;
      } else {
        const updatedOrder = handleChangeStateOrder(
          order[0]?.startPreparation()
        );
        await handleSaveOrder(updatedOrder);
      }
    }
    history.push(links.getOrderPreparationScreen(order[0].getId()));
  };

  const handleOrderShipmentClick = async () => {
    if (!order || !order[0] || !order[0].canDoShipment()) {
      return;
    }
    if (!order[0]?.getStartShipmentAt()) {
      if (!order[0]?.canStartShipment()) {
        return;
      } else {
        const updatedOrder = handleChangeStateOrder(order[0]?.startShipment());
        await handleSaveOrder(updatedOrder);
      }
    }
    history.push(links.getOrderShipmentScreen(order[0].getId()));
  };

  const handleClickRenewOrder = async () => {
    const newOrder = await handleSaveOrder(order?.[0]?.renewOrder());
    history.push(
      isGranted('ROLE_ADMIN_WHOLESALER', userInfo.roles)
        ? links.getOrderDetailsScreen(newOrder?.[0]?.getId())
        : links.getOrderPrescriptionScreen(newOrder?.[0]?.getId())
    );
  };

  const handleClickCancelOrder = async () => {
    await handleSaveOrder(order?.[0]?.cancelOrder());
    history.push(links.getOrderDetailsScreen(order?.[0]?.getId()));
  };

  const handleClickDeleteOrder = async () => {
    await handleSaveOrder(order?.[0]?.deleteOrder());
    history.push(`/${links.main}/${links.listOrders}`);
  };

  const handleSaveAddress = async (
    shippingAddress: Address,
    invoicingAddress: Address
  ): Promise<any> => {
    const newOrder = order?.[0]
      ?.setShippingAddress(shippingAddress)
      ?.setInvoicingAddress(invoicingAddress);
    return await handleSaveOrder(newOrder);
  };

  const handleMailReminderClick = async () => {
    const result = await sendPaymentReminderByMail(order?.[0]?.getId());
    if (result) {
      addSuccessSnackbar(
        t('pharmaco:orderDetails.mailReminder.confirmSuccess')
      );
    } else {
      addErrorSnackbar(t('pharmaco:orderDetails.mailReminder.confirmError'));
    }
  };

  const handleMailTrackingClick = async () => {
    const result = await sendTrackingByMail(order?.[0]?.getId());
    if (result) {
      addSuccessSnackbar(
        t('pharmaco:orderDetails.mailTracking.confirmSuccess')
      );
    } else {
      addErrorSnackbar(t('pharmaco:orderDetails.mailTracking.confirmError'));
    }
  };

  return order?.[0]?.isDeleted() ? (
    <Redirect to={`/${links.main}/${links.listOrders}`} />
  ) : isGranted('ROLE_ADMIN_WHOLESALER', userInfo.roles) ? (
    <OrderDetailsAdminWholesaler
      savePaymentCallback={updateOrder}
      order={order?.[0]}
      pharmacoOrder={pharmacoOrder?.[0]}
      handleOrderPreparationClick={handleOrderPreparationClick}
      handleOrderShipmentClick={handleOrderShipmentClick}
      handleSaveAddress={handleSaveAddress}
      renewOrder={handleClickRenewOrder}
      cancelOrder={handleClickCancelOrder}
      handleMailReminderClick={handleMailReminderClick}
      handleMailTrackingClick={handleMailTrackingClick}
    />
  ) : isGranted('ROLE_ORDER_PICKER', userInfo.roles) ? (
    <OrderDetailsPrep
      order={order?.[0]}
      pharmacoOrder={pharmacoOrder?.[0]}
      handleOrderPreparationClick={handleOrderPreparationClick}
      handleOrderShipmentClick={handleOrderShipmentClick}
    />
  ) : isGranted('ROLE_PRACTITIONER_PHARMACO', userInfo.roles) ? (
    <OrderDetailsPrescriber
      handlePayClick={() => {
        props.history.push(links.getOrderCheckoutScreen(order?.[0]?.getId()));
      }}
      order={order?.[0]}
      pharmacoOrder={pharmacoOrder?.[0]}
      renewOrder={handleClickRenewOrder}
      cancelOrder={handleClickCancelOrder}
      deleteOrder={handleClickDeleteOrder}
    />
  ) : isGranted('ROLE_PATIENT', userInfo.roles) ? (
    <OrderDetailsCustomer
      handlePayClick={() => {
        props.history.push(links.getOrderCheckoutScreen(order?.[0]?.getId()));
      }}
      order={order?.[0]}
      pharmacoOrder={pharmacoOrder?.[0]}
      cancelOrder={handleClickCancelOrder}
    />
  ) : null;
}
