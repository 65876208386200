import * as React from 'react';
import { useTranslation } from '@kiway/shared/utils/translation';
import { KiwayLanguagesType } from '@kiway/shared/react-types';
import config from '../config';

function registerStorage() {
  // REGISTER STORAGE TRANSLATIONS
  const { addTranslationDynamically } = useTranslation();
  Object.entries(config.translations).map(([lng, translation]) => {
    addTranslationDynamically(
      lng as KiwayLanguagesType,
      'storage',
      translation,
      true
    );
  });
}

export function FeatureStorage() {
  registerStorage();
  return <></>;
}

export * from './components';
