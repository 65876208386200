import React from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Modal } from '@kiway/shared/ui';
import { Button } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Payment } from 'libs/ecommerce/src/core/entities/Payment';
import { ModalContentCard } from './ModalContentCard';
import { ModalContentCheck } from './ModalContentCheck';
import { Moment } from 'moment';
import moment from 'moment';

interface PaymentModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  saveData?: () => void;
  selectedPayment: Payment;
  setPaymentDate: any;
  setTempDate: React.Dispatch<React.SetStateAction<Moment>>;
  tempDate: Moment;
  setPaymentRef: React.Dispatch<React.SetStateAction<string>>;
  paymentRef: string;
}

export const PaymentModal = (props: PaymentModalProps) => {
  const { t } = useTranslation();

  const returnModalContent = () => {
    switch (props.selectedPayment.getPaymentMethod()) {
      case 'card':
        return (
          props.selectedPayment && (
            <ModalContentCard selectedPayment={props.selectedPayment} />
          )
        );
      default:
        return (
          props.selectedPayment && (
            <ModalContentCheck
              selectedPayment={props.selectedPayment}
              setTempDate={props.setTempDate}
              tempDate={props.tempDate}
              setPaymentRef={props.setPaymentRef}
              paymentRef={props.paymentRef}
            />
          )
        );
    }
  };

  const checkIfConfirmEnable = () => {
    if (props.selectedPayment.getPaymentMethod() === 'bankTransfer') {
      return moment(props.tempDate, 'MM/DD/YYYY', true).isValid()
        ? props.paymentRef && props.tempDate
          ? false
          : true
        : true;
    } else {
      props.tempDate ? false : true;
    }
  };

  return props.selectedPayment ? (
    <Modal
      title={t('pharmaco:orderDetails.payment.commandPaymentTitle', {
        orderNumber: props.selectedPayment.getOrder()?.getOrderNumber(),
      })}
      open={props.open}
      actions={
        props.selectedPayment.getPaymentMethod() === 'card' ||
        props.selectedPayment.getStatus() !== 'PENDING' ? null : (
          <>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => props.setOpen(false)}
            >
              {t('common:form.buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              color="minoritary"
              disabled={checkIfConfirmEnable()}
              onClick={async () => {
                props.setPaymentDate(props.tempDate);
                await props.saveData();
                props.setOpen(false);
              }}
            >
              {t('common:form.buttons.confirm')}
            </Button>
          </>
        )
      }
      handleClose={() => props.setOpen(false)}
    >
      {returnModalContent()}
    </Modal>
  ) : null;
};
