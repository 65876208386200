import PhoneNumber from 'awesome-phonenumber';
import moment from 'moment';

export const pad = (n, width, z) => {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const firstUpperCase = (value) => {
  return value ? value[0].toUpperCase() + value.slice(1) : '';
};

export const displayPhoneNumber = (value) => {
  if (!value) {
    return '';
  }
  const phone = new PhoneNumber(value[0] === '+' ? value : '+' + value);
  return phone.isValid() ? phone.getNumber('international') : value;
};

export const treatSmsObject = (sms, t) => {
  const dateFormat = t('dates:format.smsSentDate');
  // if (!sms || !sms.msg_status || (!sms.ts_event && !sms.scheduleAt)) {
  if (!sms || !sms.msg_status) {
    return '';
  }
  let message = '';
  switch (sms?.msg_status) {
    case 'none':
      message = t('agenda:smsNone');
      break;
    case 'sent':
      message = `${t('agenda:smsSentOn')} ${moment(sms.ts_event * 1000).format(
        dateFormat
      )}`;
      break;
    case 'delivered':
      message = `${t('agenda:smsSentOn')} ${moment(sms.ts_event * 1000).format(
        dateFormat
      )}`;
      break;
    case 'pending':
      // message = `${t('agenda:smsScheduledAt')} ${moment(sms.ts_event ? (sms.ts_event*1000) : sms.scheduleAt).format(dateFormat)}`
      message = `${t('agenda:smsScheduledAt')} ${moment(sms.scheduleAt).format(
        dateFormat
      )}`;
      break;
    default:
      return t('agenda:smsIssue');
  }
  return message;
};

export const getQueryString = (obj) => {
  let str = [];
  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
    return str.join('&');
  }
};

export const copyToClipboard = (text, callback = () => {}) => {
  navigator.clipboard.writeText(text);
  callback();
};

export const getAppUrl = () => {
  return process.env.NX_APP_URL;
};

export const validEmail = (email) => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !email.length || emailRegex.test(email);
};
