export default {
  availableTypes: {
    email: 'Par email',
    push: "Dans l'application",
  },
  docSubtitle: 'Paramètres',
  greetings: 'Bonjour',
  linkText: 'Notifications',
  notifierLabel: 'Soyez averti quand...',
  snackbars: {
    okEmailDisabled: 'Notification par email désactivée avec succès',
    okEmailEnabled: 'Notification par email activée avec succès',
    okPushDisabled: "Notification dans l'application désactivée avec succès",
    okPushEnabled: "Notification dans l'application activée avec succès",
  },
};
