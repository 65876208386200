type Item = any & { value: number };

/**
 * Take int part of each item, take the decimal part too, sort by decimal part DESC,
 * calculate the diff between the int parts sum and the total we want, then add 1 to each item
 * depending of the sorting with a loop.
 * Example: diff = 5, 4 items, the first one we add 2, the others 1
 *   [17.75, 53.25] with 1 decimal and a total of 71 will return [17.8, 53.2]
 *   As the decimal parts of this example are the same for the two items, if we invert the order the result will be inverted too:
 *   [53.25, 17.75] with 1 decimal and a total of 71 will return [53.3, 17.7]
 */
export const roundProperlyNumbersByTotal = (
  numbers: Array<Item>,
  sum: number,
  nbDecimals = 0
): Array<Item> => {
  const factor = nbDecimals ? 10 * nbDecimals : 1;

  const preparedNumbers = numbers
    ?.map((item) => ({
      ...item,
      intValue: Math.floor(item.value * factor),
      decimalValue: item.value * factor - Math.floor(item.value * factor),
    }))
    ?.sort((a, b) =>
      a.decimalValue < b.decimalValue
        ? 1
        : b.decimalValue < a.decimalValue
        ? -1
        : 0
    );

  const diff =
    sum * factor -
    preparedNumbers.reduce((prev, current) => prev + current.intValue, 0);

  if (diff >= 1) {
    let index = 0;
    let counter = 0;
    while (counter < diff) {
      preparedNumbers[index] = {
        ...preparedNumbers[index],
        newIntValue:
          (preparedNumbers[index]?.newIntValue ??
            preparedNumbers[index]?.intValue ??
            0) + 1,
      };
      counter++;
      if (index === preparedNumbers.length - 1) {
        index = 0;
      } else {
        index++;
      }
    }
  }

  const result = preparedNumbers?.map((item) => {
    const newItem = {
      ...item,
      value: (item.newIntValue ?? item.intValue) / factor,
    };
    delete newItem['newIntValue'];
    delete newItem['intValue'];
    delete newItem['decimalValue'];
    return newItem;
  });

  return result;
};

export const isNumber = (value: any) => {
  return typeof value === 'number' && isFinite(value);
};

export const isNumberObject = (n: any) => {
  return Object.prototype.toString.apply(n) === '[object Number]';
};

export const isCustomNumber = (n: any) => {
  return isNumber(n) || isNumberObject(n);
};
