export const kiwayLanguages = ['fr', 'en', 'es', 'pt'] as const;
import { Schema } from 'mongoose';
import { CustomOptions, PaginationOptions } from './clean-archi';

export type KiwayLanguagesType = 'fr' | 'en' | 'es' | 'pt';
export type KiwayLanguages = typeof kiwayLanguages[number];

export type TranslatableAttribute = {
  [key in KiwayLanguages]: string;
};

export const getMongoTranslatableAttributeDefinition = (
  required?: boolean,
  unique?: boolean,
  sparse?: boolean
): any => {
  let result = {};
  kiwayLanguages.map((lng) => {
    result = {
      ...result,
      [lng]: {
        type: String,
        unique,
        required,
        sparse,
      },
    };
  });
  return result;
};

export const flattenTranslatableAttributeToJson = (
  attribute: TranslatableAttribute,
  name: string
): any => {
  return kiwayLanguages.reduce(
    (prev, current) => ({
      ...prev,
      [`${name}.${current}`]: attribute?.[current],
    }),
    {}
  );
};

export interface Searchable<T> {
  search:
    | ((search: string) => Promise<T[]>)
    | ((search: string, options?: CustomOptions) => Promise<T[]>);
}

export interface EntityProvider<Entity, IEntityAttributes> {
  findAll(find?: any, pagination?: PaginationOptions): Promise<Entity[]>;
  save(entity: Entity, userId?: string): Promise<Entity>;
  editMany(entities: IEntityAttributes[], userId?: string): Promise<Entity[]>;
}

export const getSearchMatch = (
  searchableAttributes: string[],
  search: string,
  full?: boolean
): any => {
  let match: any = {
    $text: {
      $search: search,
    },
  };
  if (!full) {
    const or = [];
    searchableAttributes.map((item) => {
      or.push({
        [item]: new RegExp(search, 'i'),
      });
    });
    match = {
      $or: or,
    };
  }
  return match;
};

export function setSearchIndex<T>(
  schema: Schema<T>,
  searchableAttributes: string[]
): void {
  let searchIndex = {};
  searchableAttributes.map((item) => {
    searchIndex = {
      ...searchIndex,
      [item]: 'text',
    };
    return item;
  });
  schema.index(searchIndex);
}

export type Matches = {
  [name: string]: string[];
};
