import { ACTION_ADD, ACTION_UPDATE, ACTION_DELETE } from './appointment'

const includedIn = (set, object) =>
  set.map((item) => item.id).includes(object.id)

export const updateCacheForSlots = (client, query, variables, payload) => {
  const store = client.readQuery({ query, variables })
  client.writeQuery({
    query,
    data: {
      slots: [...payload],
    },
    variables,
  })
}

export function updateCacheAfterSub(client, query, variables, payload) {
  const store = client.readQuery({ query, variables })
  switch (payload.action) {
    case ACTION_ADD:
      if (store) {
        if (!includedIn(store.appointments, payload.appointment)) {
          client.writeQuery({
            query,
            data: {
              appointments: [...store.appointments, payload.appointment],
            },
            variables,
          })
        }
      } else {
        client.writeQuery({
          query,
          data: {
            appointments: [payload.appointment],
          },
          variables,
        })
      }
      return
    case ACTION_UPDATE:
      if (store) {
        if (includedIn(store.appointments, payload.appointment)) {
          const { id } = payload.appointment
          client.writeQuery({
            query,
            data: {
              appointments: [
                ...store.appointments.map((appointment) =>
                  appointment.id !== id ? appointment : payload.appointment,
                ),
              ],
            },
            variables,
          })
        }
      } else {
        client.writeQuery({
          query,
          data: {
            appointments: [payload.appointment],
          },
          variables,
        })
      }
      return
    case ACTION_DELETE:
      if (store) {
        if (includedIn(store.appointments, payload.appointment)) {
          client.writeQuery({
            query,
            data: {
              appointments: [
                ...store.appointments.filter(
                  (a) => a.id !== payload.appointment.id,
                ),
              ],
            },
            variables,
          })
        }
      }
      return
    default:
      throw new Error('This action is not handled')
  }
}
