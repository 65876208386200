import { IAddressAttributes } from '@kiway/shared/features/authentication-react-compatible';
import { EntityProvider } from '@kiway/shared/react-types';
import { IOrderAttributes, Order } from '../entities/Order';
import { CustomerInput } from './CreateOrder';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */

export type UserLastOrdersType = 'prescriber' | 'customer' | 'admin';
export const DEFAULT_LAST_ORDERS_NB = 5;

export interface OrderProvider extends EntityProvider<Order, IOrderAttributes> {
  open(orderId: string): Promise<Order>;
  findOneById(orderId: string): Promise<Order>;
  findByCustomerId(customerId: string): Promise<Order[]>;
  onOrderCreate(callback: (order: Order) => any): void;
  createOrder(
    prescriber: CustomerInput,
    customer?: CustomerInput
  ): Promise<Order>;
  changeOrderCustomer(orderId: string, customer: CustomerInput): Promise<Order>;
  findLastOrders(
    userType: UserLastOrdersType,
    lastNb?: number,
    userId?: string
  ): Promise<Order[]>;
  getOrderPublicCheckout(orderId: string, checkEmail?: string): Promise<Order>;
  getOrderStripeCheckoutSession(
    orderId: string,
    successUrl: string,
    cancelUrl: string
  ): Promise<string>;
  changeShippingMethod(orderId: string, shippingMethod: string): Promise<Order>;
  changeShippingAddress(
    orderId: string,
    shippingAddress: IAddressAttributes
  ): Promise<Order>;
  generateLabel(orderId: string): Promise<Order>;
}
