import Skeleton from '@material-ui/lab/Skeleton'
import React, { useEffect, useReducer } from 'react'
import { getPractitionerChiefComplaints } from '../../services/chiefComplaint/chiefComplaintService'
import { useTranslation } from '@kiway/shared/utils/translation'
import SearchBox from '../SearchBox'

const initState = {
  loading: false,
  chiefComplaints: [],
  chiefComplaintSelected: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        loading: true,
      }
    case 'FETCH_COMPLETE':
      return {
        ...state,
        loading: false,
        chiefComplaints: action.payload,
      }
    case 'SELECT_CHANGE':
      return {
        ...state,
        chiefComplaintSelected: action.payload,
      }
    case 'WORKPLACE_CHANGE':
      return {
        ...state,
        chiefComplaintSelected: null,
      }
    default:
      throw new Error('Unhandled action type')
  }
}

export function useChiefComplaintPicker(
  consultationPlaceId,
  practitionerId,
  publicPage = false,
  preSelected,
  isRequired = false,
) {
  const [state, dispatch] = useReducer(reducer, initState, () => initState)

  useEffect(() => {
    if (!preSelected) {
      dispatch({ type: 'WORKPLACE_CHANGE' })
    }
  }, [consultationPlaceId])

  useEffect(() => {
    dispatch({
      type: 'SELECT_CHANGE',
      payload: preSelected,
    })
  }, [preSelected])

  const component = (
    <ChiefComplaintPicker
      consultationPlaceId={consultationPlaceId}
      dispatch={dispatch}
      loading={state.loading}
      practitionerId={practitionerId}
      publicPage={publicPage}
      state={state}
      isRequired={isRequired}
    />
  )

  return [state.chiefComplaintSelected, component, state.loading]
}

function ChiefComplaintPicker(props) {
  const {
    consultationPlaceId,
    dispatch,
    loading,
    practitionerId,
    publicPage,
    state,
    isRequired,
  } = props
  const { t } = useTranslation()

  useEffect(() => {
    dispatch({ type: 'FETCH_INIT' })
    getPractitionerChiefComplaints(practitionerId, publicPage).then((data) => {
      if (data) {
        dispatch({ type: 'FETCH_COMPLETE', payload: data })
      } else {
        dispatch({ type: 'FETCH_COMPLETE' })
      }
    })
  }, [practitionerId])

  return loading ? (
    <Skeleton animation="wave" variant="rect" height={56} width="100%" />
  ) : (
    <SearchBox
      width={'100%'}
      groupBy={(option) => option.specialty.name}
      inputProps={{
        required: isRequired,
      }}
      data={
        state.chiefComplaints &&
        state.chiefComplaints
          .filter((item) =>
            item.consultationPlaces
              .map((subItem) => (subItem._id ? subItem._id : subItem))
              .includes(consultationPlaceId),
          )
          .sort((a, b) =>
            a.specialty.name === b.specialty.name
              ? a.name > b.name
              : a.specialty.name > b.specialty.name,
          )
      }
      label={t('common:form.fields.chiefComplaint.label')}
      value={state.chiefComplaintSelected}
      setValue={(value) => dispatch({ type: 'SELECT_CHANGE', payload: value })}
      getString={(value) => (value ? `${value.name}` : '')}
      disabled={!consultationPlaceId}
    />
  )
}

export default ChiefComplaintPicker
