import * as React from 'react';

/** 
Checkbox props
*/
type CheckboxInputProps = {
  /**
   * Default value of the checkbox (checked or not checked)
   */
  defaultChecked: boolean;
  /**
   * Callback to switch value of the checkbox
   */
  callback: (e: boolean) => void;
};

/**
 * Return a checkbox
 */
const CheckboxInput = (props: CheckboxInputProps): React.ReactElement => {
  return (
    <input
      type="checkbox"
      defaultChecked={props.defaultChecked}
      onChange={(e) => props.callback(e.target.checked)}
    />
  );
};

export default CheckboxInput;
