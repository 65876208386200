import { Document, Model, Schema } from 'mongoose';
import {
  IProductAttributes,
  Product,
  productSearchableAttributes,
} from '../../core/entities/Product';
import { ProductProvider } from '../../core/use_cases/ProductProvider';
import {
  CustomOptions,
  EntityProvider,
  getMongoTranslatableAttributeDefinition,
  PaginatedResults,
  PaginationOptions,
  Searchable,
  SearchableDatabaseProviderFactory,
} from '@kiway/shared/react-types';
import { PriceSchema } from './OrderDatabaseProvider';

/* Declare methods */
export interface IProduct extends IProductAttributes, Document {}

const productGlobalPopulate = [
  {
    path: 'variations.productVariationType',
    model: 'ProductVariationType',
    populate: [
      {
        path: 'price',
        populate: 'currency',
      },
      {
        path: 'variationAttributesValue',
        model: 'VariationAttributeValue',
        populate: {
          path: 'variationAttribute',
          model: 'VariationAttribute',
        },
      },
    ],
  },
  {
    path: 'variations.price',
    populate: 'currency',
  },
  {
    path: 'metadatas',
  },
];

/* Declare statics */
export interface IProductModel
  extends Model<IProduct>,
    Searchable<IProduct>,
    EntityProvider<IProduct, IProductAttributes> {}

export class ProductDatabaseProvider extends ProductDatabaseProviderFactory<
  IProduct
>([], []) {}

export function ProductDatabaseProviderFactory<IEntity>(
  customSearchableAttributes?: string[],
  customGlobalPopulate?: any
): any {
  class ProductDatabaseProvider
    extends SearchableDatabaseProviderFactory<
      Product,
      IProduct,
      IProductModel,
      IProductAttributes
    >(
      [...productSearchableAttributes, ...(customSearchableAttributes ?? [])],
      [...productGlobalPopulate, ...(customGlobalPopulate ?? [])]
    )
    implements ProductProvider {
    protected static readonly instanceName = 'Product';
    protected static instances: Array<{
      name: string;
      instance: ProductDatabaseProvider;
    }>;
    public static getInstance(
      modelName = ProductDatabaseProvider.instanceName
    ): ProductDatabaseProvider {
      if (
        !ProductDatabaseProvider.instances?.find(
          ({ name }) => name === modelName
        )
      ) {
        new ProductDatabaseProvider(modelName);
      }
      return ProductDatabaseProvider.instances?.find(
        ({ name }) => name === modelName
      )?.instance;
    }

    protected modelName: string;

    protected constructor(
      modelName = ProductDatabaseProvider.instanceName,
      customAttributes = { custom: {} }
    ) {
      const VariationSchema = new Schema({
        productVariationType: {
          type: Schema.Types.ObjectId,
          ref: 'ProductVariationType',
          required: true,
        },
        price: PriceSchema,
        productRef: { type: String },
        weight: { type: Number },
        available: { type: Boolean },
      });
      const ProductSchema: Schema<IProduct> = new Schema(
        {
          /* Product schema definition here : see mongoose lib */
          name: getMongoTranslatableAttributeDefinition(),
          variations: [VariationSchema],
          ...customAttributes,
          custom: {
            ...customAttributes?.custom,
          },
          model: { type: String },
        },
        { timestamps: true }
      );
      super(ProductSchema, modelName, 'products');
      ProductDatabaseProvider.instances = [
        ...(ProductDatabaseProvider.instances?.filter(
          ({ name }) => name !== modelName
        ) || []),
        {
          name: modelName,
          instance: this,
        },
      ];
    }
    async findOneById(productId: string): Promise<Product> {
      return super.findOne({
        find: { _id: productId, model: this.modelName },
        populate: productGlobalPopulate,
      });
    }

    async save(product: Product, userId?: string): Promise<Product> {
      return super.save(product, userId);
    }

    async findAll(
      options?: CustomOptions,
      pagination?: PaginationOptions
    ): Promise<PaginatedResults<Product>> {
      return super.findAll(
        {
          populate: productGlobalPopulate,
          ...options,
          find: {
            model: this.modelName,
            ...(options?.find || {}),
          },
        },
        pagination
      );
    }

    async search(search: string, options?: CustomOptions): Promise<Product[]> {
      return super.search(search, options);
    }

    async editMany(
      products: IProductAttributes[],
      userId?: string
    ): Promise<Product[]> {
      return super.editMany(products, userId);
    }

    public toEntity(productMongo: IProduct & { _doc?: any }): Product {
      return new Product(productMongo);
    }

    public toEntityMongo(
      product: Product,
      createdBy?: string,
      updatedBy?: string
    ): IEntity {
      return new this.model({
        _id: product.getId(),
        name: product.getName(),
        variations: product.getVariations()?.map((v) => ({
          _id: v.getId(),
          productVariationType: v.getProductVariationType()?.getId(),
          price: v.getPrice()?.toJSON(),
          productRef: v.getProductRef(),
          weight: v.getWeight(),
          available: v.isAvailable(),
        })),
        custom: product.getCustom(),
        createdBy: createdBy ? createdBy : undefined,
        updatedBy: updatedBy ? updatedBy : undefined,
        model: this.modelName,
      });
    }
  }
  return ProductDatabaseProvider;
}
