import * as React from 'react';
import { Typography } from '@material-ui/core';
import { LoyaltyTransaction } from '../../../core/entities/LoyaltyTransaction';
import { Skeleton } from '@material-ui/lab';

export interface ListLoyaltyTransactionsProps {
  loyaltyTransactions: LoyaltyTransaction[];
  loading?: boolean;
}

const renderLoyaltyTransaction = (item: LoyaltyTransaction) => (
  <li key={item.getId()}>{item.getId()}</li>
);

const loadingLoyaltyTransactionScreen = (
  <div>
    <ul>
      <li>
        <Skeleton variant="text" />
      </li>
      <li>
        <Skeleton variant="text" />
      </li>
      <li>
        <Skeleton variant="text" />
      </li>
    </ul>
  </div>
);

export function ListLoyaltyTransactions({
  loading,
  loyaltyTransactions,
}: React.PropsWithChildren<ListLoyaltyTransactionsProps>) {
  return (
    <div>
      <Typography variant="h5">LoyaltyTransaction :</Typography>
      {loading ? (
        loadingLoyaltyTransactionScreen
      ) : (
        <div>
          <ul>
            {loyaltyTransactions?.length
              ? loyaltyTransactions?.map(renderLoyaltyTransaction)
              : 'Aucune donnée'}
          </ul>
        </div>
      )}
    </div>
  );
}
