import React from 'react';
import { Address, DisplayAddress } from '@kiway/shared/ui';
import { Grid, Typography, Chip, Card, CardContent } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Address as UserAddress } from '@kiway/shared/features/authentication-react';

interface UserAddressCardProps {
  address: Address;
  displayDefault?: boolean;
  selectedAddressId?: string;
  handleSelectAddress: (address: Address) => void;
}

export const UserAddressCard = (props: UserAddressCardProps) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={3}>
      <Card
        onClick={() => {
          props.handleSelectAddress(props.address);
        }}
        elevation={props.selectedAddressId === props.address.id ? 4 : 0}
        sx={{
          border: `${
            props.selectedAddressId === props.address.id ? '3px' : '1px'
          } solid ${
            props.selectedAddressId === props.address.id
              ? process.env.NX_COLOR_SECONDARY_MAIN
              : 'grey'
          }`,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardContent sx={{ flex: 1 }}>
          <DisplayAddress
            address={new UserAddress(props.address)}
            displayDefault={true}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};
