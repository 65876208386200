import React from 'react'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  white: {
    color: 'white',
    borderColor: 'white',
  },
  red: {
    color: 'red',
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
}))

function CustomCheckbox({
  align,
  error,
  handleChange,
  label,
  name,
  theme,
  value,
  variant,
  noMargin,
  ...rest
}) {
  const classes = useStyles()
  return (
    <FormControlLabel
      className={`${!noMargin && classes.formControl} ${
        error !== '' && !value
          ? classes.red
          : theme === 'green' && classes.white
      }`}
      control={
        <Checkbox
          value={value}
          checked={value}
          name={name}
          style={theme === 'green' ? { color: 'white' } : {}}
          onChange={handleChange}
          required
        />
      }
      label={<Typography component='div' variant={variant} align={align}>{label}</Typography>}
      {...rest}
    />
  )
}

export default CustomCheckbox