import { gql } from '@apollo/client'

export const PUBLIC_PAGE_ON_APPOINTMENT_EVENT_TO_SLOTS = gql`
  subscription onAppointmentEventToSlots($filter: SlotFilter) {
    onAppointmentEventToSlots(filter: $filter) {
      day
      slots
    }
  }
`
