/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { AccordionItem } from '@kiway/ecommerce-react';
import { Order } from '@kiway/ecommerce-react-compatible';
import { MobileSafeArea, Modal } from '@kiway/shared/ui';
import { useTranslation } from '@kiway/shared/utils/translation';
import {
  Button,
  Chip,
  Grid,
  Paper,
  Table,
  TableContainer,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { links } from '../../../config';
import { PharmacoOrder } from '../../../core/entities/PharmacoOrder';
import { CustomerInfo } from './CustomerInfo';
import { OrderCardInfo } from './OrderCardInfo';
import { OrderDetailsFormula } from './OrderDetailsFormula';

interface OrderDetailsPrescriberProps {
  handlePayClick: () => void;
  order?: Order;
  pharmacoOrder?: PharmacoOrder;
  renewOrder?: () => void;
  cancelOrder?: () => Promise<void>;
  deleteOrder?: () => Promise<void>;
}

export const OrderDetailsPrescriber = (props: OrderDetailsPrescriberProps) => {
  const { t, currentLanguage } = useTranslation();
  const [expanded, setExpanded] = React.useState<string | false>('patient');
  const [openModalCancel, setOpenModalCancel] = React.useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = React.useState<boolean>(false);

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const nbComment =
    props.order?.getCustom() &&
    Object.keys(props.order?.getCustom()).filter((key) =>
      ['patientComment', 'practitionerComment', 'preparerComment'].includes(key)
    )?.length;

  return (
    <MobileSafeArea>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Button
              size={'small'}
              color={'inherit'}
              component={Link}
              to={`/${links.main}/${links.listOrders}`}
            >
              {t('pharmaco:backToList')}
            </Button>
            <div>
              {props.order?.canRenewOrder() && (
                <Button
                  size="small"
                  color="inherit"
                  variant="contained"
                  onClick={props.renewOrder}
                  sx={{ marginRight: 1 }}
                >
                  {t('pharmaco:renewOrder')}
                </Button>
              )}
              {props.order?.canCancelOrder() && (
                <Button
                  size="small"
                  color="inherit"
                  variant="contained"
                  onClick={() => setOpenModalCancel(true)}
                >
                  {t('pharmaco:cancelOrder.title')}
                </Button>
              )}
              {props.order?.canBeDeleted() && (
                <Button
                  size="small"
                  color="inherit"
                  variant="contained"
                  onClick={() => setOpenModalDelete(true)}
                >
                  {t('pharmaco:deleteOrder.title')}
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography variant="h4" align="center">
                {`${t(
                  `pharmaco:orderDetails.order.label`
                )} n°${props.order?.getOrderNumber()}`}
              </Typography>
            </Grid>
            <Grid item>
              <Chip
                label={t(
                  `pharmaco:orderDetails.order.status.${
                    props.order?.getOrderStatusJSON()?.label
                  }`
                ).toUpperCase()}
                color={props.order?.getOrderStatusJSON()?.color}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <AccordionItem
            labelVariant={'h6'}
            expanded={expanded === 'patient'}
            handleChange={handleChange('patient')}
            hideSecondaryLabelWhenExpanded={true}
            label={t(`pharmaco:orderDetails.client.label`)}
            secondaryLabel={
              props.order?.getShippingAddress().getLine2() &&
              props.order?.getShippingAddress().getCity() &&
              props.order?.getShippingAddress().getCountryCode()
                ? `${props.order
                    ?.getShippingAddress()
                    .getLine2()}, ${props.order
                    ?.getShippingAddress()
                    .getCity()}, ${props.order
                    ?.getShippingAddress()
                    .getCountryCode()
                    .toUpperCase()}`
                : ''
            }
          >
            <CustomerInfo
              customer={props.order?.getCustomer()}
              order={props.order}
            />
          </AccordionItem>
          <AccordionItem
            labelVariant={'h6'}
            expanded={expanded === 'formula'}
            handleChange={
              props.pharmacoOrder?.getFormulas()?.length > 0
                ? handleChange('formula')
                : undefined
            }
            hideExpandIcon={
              props.pharmacoOrder?.getFormulas()?.length > 0 ? false : true
            }
            label={t(`pharmaco:orderDetails.formula.label`)}
            secondaryLabel={
              props.pharmacoOrder?.getFormulas()?.length > 0
                ? `${t(`pharmaco:orderDetails.formula.formulaCount`, {
                    count: props.order?.getNbOfGroups(),
                  })} ${props.pharmacoOrder
                    ?.getFormulas()
                    ?.map((formula) => formula.getName())
                    .join(', ')}`
                : t(`pharmaco:orderDetails.comment.default`)
            }
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={2} style={{ marginBottom: 5 }}>
                  <Grid item xs={12}>
                    <TableContainer component={Paper} elevation={1}>
                      <Table size="small">
                        {props.pharmacoOrder?.getFormulas()?.map((formula) => (
                          <OrderDetailsFormula
                            key={formula.getGroupBy()}
                            formulaName={formula.getName()}
                            rows={props.order.getLineItemsByGroup(
                              formula.getGroupBy()
                            )}
                            totalQuantity={props.order.getTotalQuantityByGroup(
                              formula.getGroupBy()
                            )}
                            totalPrice={props.order.getGrossTotalPrice(
                              formula.getGroupBy()
                            )}
                            getRoundedItemWeight={(id) =>
                              formula.getRoundedItemQuantity(id)
                            }
                            prescriberOrCustomer={true}
                            description={formula.getPosologyDescription(t)}
                            formula={formula}
                          />
                        ))}
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionItem>
          <AccordionItem
            labelVariant={'h6'}
            expanded={expanded === 'preparerComment'}
            handleChange={
              props.order?.getCustom()?.['preparerComment']
                ? handleChange('preparerComment')
                : undefined
            }
            hideExpandIcon={
              props.order?.getCustom()?.['preparerComment'] ? false : true
            }
            hideSecondaryLabelWhenExpanded={true}
            label={t(`pharmaco:orderDetails.comment.label`)}
            secondaryLabel={
              nbComment > 0
                ? `${nbComment} commentaire${nbComment > 1 ? 's' : ''}`
                : t(`pharmaco:orderDetails.comment.default`)
            }
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography sx={{ textDecoration: 'underline' }}>
                  {t(`pharmaco:orderDetails.comment.ownComment`)}
                </Typography>
                <Typography>
                  {props.order?.getCustom()?.['practitionerComment']
                    ? props.order?.getCustom()?.['practitionerComment']
                    : t(`pharmaco:orderDetails.comment.default`)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ textDecoration: 'underline' }}>
                  {t(`pharmaco:orderDetails.comment.assistantComment`)}
                </Typography>
                <Typography>
                  {props.order?.getCustom()?.['preparerComment']
                    ? props.order?.getCustom()?.['preparerComment']
                    : t(`pharmaco:orderDetails.comment.default`)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ textDecoration: 'underline' }}>
                  {t(`pharmaco:orderDetails.comment.patientComment`)}
                </Typography>

                <Typography>
                  {props.order?.getCustom()?.['patientComment']
                    ? props.order?.getCustom()?.['patientComment']
                    : t(`pharmaco:orderDetails.comment.default`)}
                </Typography>
              </Grid>
            </Grid>
          </AccordionItem>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid container spacing={2} alignItems={'stretch'}>
            <Grid item xs={4}>
              <OrderCardInfo
                actions={
                  <>
                    {props.order?.getPaymentStatus() === 'BALANCE_DUE' &&
                      props.order?.getOrderStatus() === 'OPEN' && (
                        <Button
                          color="minoritary"
                          variant="contained"
                          onClick={props.handlePayClick}
                        >
                          {t('pharmaco:orderDetails.payment.pay')}
                        </Button>
                      )}
                  </>
                }
                lines={[
                  {
                    label: t(`pharmaco:orderDetails.payment.amount`),
                    value: props.order?.getTotalPrice()?.toString(),
                  },
                  {
                    label: t(`pharmaco:orderDetails.payment.paymentMethod`),
                    value: (
                      <Typography>
                        {t(
                          `pharmaco:orderDetails.payment.forms.${props.order
                            ?.getPayment()
                            ?.getPaymentMethod()}`
                        )}
                      </Typography>
                    ),
                    hide:
                      (props.order?.getPaymentStatus() !== 'PAID' &&
                        props.order?.getPaymentMethod() === 'card') ||
                      (!['PENDING', 'PAID'].includes(
                        props.order?.getPaymentStatus()
                      ) &&
                        props.order?.getPaymentMethod() !== 'card'),
                  },
                  {
                    label: t(`pharmaco:orderDetails.payment.paidAt`),
                    value: props.order?.getPaidAt()
                      ? moment(props.order?.getPaidAt())?.format(
                          t('dates:format.smsSentDate')
                        )
                      : null,
                    hide: !props.order?.getPaidAt(),
                  },
                ]}
                title={t(`pharmaco:orderDetails.payment.label`)}
                status={t(
                  `pharmaco:orderDetails.payment.status.${
                    props.order?.getPaymentStatusJSON()?.label
                  }`
                )}
                statusColor={props.order?.getPaymentStatusJSON()?.color}
              />
            </Grid>
            <Grid item xs={4}>
              <OrderCardInfo
                actions={<></>}
                lines={[
                  {
                    label: t(`pharmaco:orderDetails.packing.progress`),
                    value: `${(
                      props.order?.getPreparationProgression() * 100
                    ).toFixed(2)} %`,
                    hide: !props.order?.canDoPreparation(),
                  },
                ]}
                title={t(`pharmaco:orderDetails.packing.label`)}
                status={t(
                  `pharmaco:orderDetails.packing.status.${
                    props.order?.getPackingStatusJSON()?.label
                  }`
                )}
                statusColor={props.order?.getPackingStatusJSON()?.color}
              />
            </Grid>
            <Grid item xs={4}>
              <OrderCardInfo
                actions={<></>}
                lines={[
                  {
                    label: t(`pharmaco:orderDetails.shipment.shipmentMethod`),
                    value: props.order?.getShippingMethod()?.getName()?.[
                      currentLanguage?.code
                    ],
                    hide: !props.order?.getShippingMethod(),
                  },
                  {
                    label: t(
                      `pharmaco:orderDetails.shipment.shipmentMethodCost`
                    ),
                    value: props.order
                      ?.getShippingMethod()
                      ?.getPrice(
                        props.order.getShippingAddress()?.getCountryCode(),
                        props.order.getTotalWeight()
                      )
                      ?.toString(),
                    hide: !props.order?.getShippingMethod(),
                  },
                  {
                    label: t(`pharmaco:orderDetails.shipment.trackingNumber`),
                    value: (
                      <a
                        href={`${props.order
                          ?.getShippingMethod()
                          ?.getCarrier()
                          ?.getTrackingLink(
                            props.order?.getShippingTrackingNumber()
                          )}`}
                        target="_blank"
                      >
                        {props.order?.getShippingTrackingNumber()}
                      </a>
                    ),
                    hide: !props.order?.getShippingTrackingNumber(),
                  },
                ]}
                title={t(`pharmaco:orderDetails.shipment.label`)}
                status={t(
                  `pharmaco:orderDetails.shipment.status.${
                    props.order?.getShipmentStatusJSON()?.label
                  }`
                )}
                statusColor={props.order?.getShipmentStatusJSON()?.color}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        actions={
          <>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => setOpenModalCancel(false)}
            >
              {t('common:form.buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              color="minoritary"
              onClick={async () => {
                await props.cancelOrder();
                setOpenModalCancel(false);
              }}
            >
              {t('common:form.buttons.confirm')}
            </Button>
          </>
        }
        handleClose={() => setOpenModalCancel(false)}
        open={openModalCancel}
        title={t('pharmaco:cancelOrder.title')}
        disableEnforceFocus
      >
        {t('pharmaco:cancelOrder.message')}
      </Modal>
      <Modal
        actions={
          <>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => setOpenModalDelete(false)}
            >
              {t('common:form.buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              color="minoritary"
              onClick={async () => {
                await props.deleteOrder();
                setOpenModalDelete(false);
              }}
            >
              {t('common:form.buttons.confirm')}
            </Button>
          </>
        }
        handleClose={() => setOpenModalDelete(false)}
        open={openModalDelete}
        title={t('pharmaco:deleteOrder.title')}
        disableEnforceFocus
      >
        {t('pharmaco:deleteOrder.message')}
      </Modal>
    </MobileSafeArea>
  );
};
