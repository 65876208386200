import * as React from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Order } from '@kiway/ecommerce-react-compatible';
import { PrescriptionDetails } from '../PrescriptionDetails/PrescriptionDetails';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useOrdersGateway } from '@kiway/ecommerce-react';
import {
  PharmacoOrder,
  PharmacoOrderFormula,
  PharmacoOrderIngredient,
} from '../../../core/entities/PharmacoOrder';
import { links } from '../../../config';
import { Redirect } from 'react-router-dom';

export interface PrescriptionDetailsControllerProps {
  order?: Order;
  history?: any;
  id: string;
}

export function PrescriptionDetailsController(
  props: PrescriptionDetailsControllerProps
) {
  const { findOne, changeOrderCustomer, editOrders } = useOrdersGateway();
  const [loading, setLoading] = React.useState(false);
  const [order, setOrder] = React.useState<PharmacoOrder[]>([]);
  const [realOrder, setRealOrder] = React.useState<Order[]>([]);
  const [shopOpen, setShopOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    if (props.id) {
      findOne(props.id)
        .then(async (order) => {
          if (order && order.getId()) {
            setRealOrder([new Order(order.toJSON())]);
            setOrder([
              new PharmacoOrder({
                order,
                saveCallback: handleSaveOrder,
              }).createNewTempFormula([]),
            ]);
          }
        })
        .finally(() => setLoading(false));
    }
    return () => {
      setOrder([]);
    };
  }, [props.id]);

  const handleChangeDosage = (formula) => (lineItemId) => (e) => {
    const ingredient = formula
      ?.getIngredients()
      ?.find((item) => `${item.getLineItem()?.getId()}` === `${lineItemId}`);
    ingredient.setDosage(e.target.value);
    formula.updateTotalDosage();
    handleChangeStateOrder(order[0]);
  };

  const handleChangeIsSeparated = (ingredient: PharmacoOrderIngredient) => (
    e
  ) => {
    ingredient.setIsSeparated(e.target.checked);
    handleChangeStateOrder(order[0]);
    handleSaveOrder();
  };

  const handleSaveOrder = (forcedOrder?: PharmacoOrder) => {
    return editOrders([
      (forcedOrder ?? order[0])?.getOrder()?.toInput(true),
    ]).then((order) => {
      setRealOrder([new Order(order[0].toJSON())]);
      setOrder([
        new PharmacoOrder({
          order: order[0],
          saveCallback: handleSaveOrder,
        }).createNewTempFormula([]),
      ]);
    });
  };

  const handleChangeStateOrder = (order: PharmacoOrder) => {
    setOrder([order]);
    return order;
  };

  const handleRemoveLineItem = (lineItemId: string) => {
    order[0]?.removeLineItem(lineItemId);
    handleChangeStateOrder(order[0]);
    handleSaveOrder();
  };

  const handleRemoveFormula = (formulaGroupBy: string) => {
    order[0]?.removeFormula(formulaGroupBy);
    handleChangeStateOrder(order[0]);
    handleSaveOrder();
  };

  const handleClickNext = () => {
    if (props.history) {
      props.history.push(links.getOrderCommentsScreen(props.id));
    }
  };

  const handleChangePatient = async (newPatient: any) => {
    const newOrder = await changeOrderCustomer(
      order[0]?.getOrder()?.getId(),
      newPatient
    );
    setRealOrder([new Order(newOrder.toJSON())]);
    setOrder([
      new PharmacoOrder({
        order: newOrder,
        saveCallback: handleSaveOrder,
      }).createNewTempFormula([]),
    ]);
  };

  const handleChangeFormulaName = (formulaId: string, formulaName: string) => {
    order[0]?.changeFormulaName(formulaId, formulaName);
    handleChangeStateOrder(order[0]);
    handleSaveOrder();
  };

  const handleClickCancelOrder = async () => {
    await handleSaveOrder(order[0].cancelOrder());
    window.location.href = links.getOrderDetailsScreen(props.id);
  };

  const handleClickDeleteOrder = async () => {
    await handleSaveOrder(order?.[0]?.deleteOrder());
    props.history.push(`/${links.main}/${links.listOrders}`);
  };

  const handleToggleIsPod = (formula: PharmacoOrderFormula) => {
    const newOrder = realOrder[0]?.setCustom({
      ...realOrder[0]?.getCustom(),
      [formula.getGroupBy()]: {
        ...realOrder[0]?.getCustom()[formula.getGroupBy()],
        pod: !formula.needToAddPod(),
      },
    });
    setRealOrder([newOrder]);
    setOrder([order[0]?.setOrder(newOrder)]);
    handleSaveOrder(order[0]?.setOrder(newOrder));
  };

  const handleChangeDosageMode = (formulaId: string, value: 'CP' | 'PS') => {
    const thisOrder = order?.[0]?.getOrder();

    thisOrder.setCustom({
      ...(thisOrder.getCustom() ?? {}),
      [formulaId]: {
        ...(thisOrder.getCustom()?.[formulaId] ?? {}),
        dosageMode: value,
      },
    });

    setOrder([order?.[0]]);
    handleSaveOrder();
  };

  return !order?.[0]?.getOrder()?.isDeleted() ? (
    <PrescriptionDetails
      order={order[0]}
      realOrder={realOrder[0]}
      handleChangeDosage={handleChangeDosage}
      handleChangeDosageMode={handleChangeDosageMode}
      handleChangeFormulaName={handleChangeFormulaName}
      handleChangeIsSeparated={handleChangeIsSeparated}
      handleChangePatient={handleChangePatient}
      handleChangeStateOrder={handleChangeStateOrder}
      handleSaveOrder={handleSaveOrder}
      handleRemoveLineItem={handleRemoveLineItem}
      handleRemoveFormula={handleRemoveFormula}
      handleToggleIsPod={handleToggleIsPod}
      handleClickNext={handleClickNext}
      shopOpen={shopOpen}
      setShopOpen={setShopOpen}
      cancelOrder={handleClickCancelOrder}
      deleteOrder={handleClickDeleteOrder}
    />
  ) : (
    <Redirect to={`/${links.main}/${links.listOrders}`} />
  );
}
