/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import {
  Divider,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useStyles } from '../shared/styles';
import { useTranslation } from '@kiway/shared/utils/translation';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';
import config from '../../../config';
import { Price } from '@kiway/ecommerce-react-compatible';
import { PharmacoOrderFormula } from '../../../core/entities/PharmacoOrder';

export interface FormulaProps {
  formulaName: string;
  rows: any[];
  totalQuantity: number;
  totalPrice: Price;
  getRoundedItemWeight: (itemId: string) => number;
  prescriberOrCustomer?: boolean;
  description?: string;
  formula?: PharmacoOrderFormula;
}

function RowFormula(props) {
  const { t } = useTranslation();

  return props.prescriberOrCustomer ? (
    <TableRow>
      <TableCell>
        {props.row.getProduct()?.getName()?.fr}{' '}
        <i>{`${
          props.row.getCustom().isSeparated
            ? `(${t(`${config.namespace}:orderDetails.formula.separate`)})`
            : ''
        }`}</i>
      </TableCell>
      <TableCell>
        {`${parseFloat(
          `${props.getRoundedItemWeight(props.row.getId())}`
        )?.toFixed(2)} g`}
      </TableCell>
      <TableCell>
        {props.row.getTotalPrice()?.getCurrency()?.isDefined()
          ? props.row.getTotalPrice()?.toString()
          : '-'}
      </TableCell>
      {/* <TableCell >
        {props.row.getCustom().isSeparated
          ? firstUpperCase(t('common:yes'))
          : null}
      </TableCell> */}
    </TableRow>
  ) : (
    <TableRow>
      <TableCell>{props.row.getProductVariation()?.getProductRef()}</TableCell>
      <TableCell>
        {props.row.getProduct()?.getName()?.fr}{' '}
        <i>{`${
          props.row.getCustom().isSeparated
            ? `(${t(`${config.namespace}:orderDetails.formula.separate`)})`
            : ''
        }`}</i>
      </TableCell>
      <TableCell>
        {parseFloat(
          `${props.getRoundedItemWeight(props.row.getId())}`
        )?.toFixed(2)}{' '}
        g
      </TableCell>
      <TableCell>
        {props.row.getTotalPrice()?.getCurrency()?.isDefined()
          ? props.row.getTotalPrice()?.toString()
          : '-'}
      </TableCell>
    </TableRow>
  );
}

export function OrderDetailsFormula(props: FormulaProps) {
  const classes = useStyles();
  const { t, currentLanguage } = useTranslation();

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell sx={{ backgroundColor: '#EAE9E9' }} colSpan={5}>
            <Grid container columnSpacing={1} justifyContent="center">
              <Grid item>
                <Typography>{props.formulaName}</Typography>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <Typography>
                  {
                    props.rows[0]
                      ?.getProductVariation()
                      ?.getProductVariationType()
                      ?.getName()?.[
                      currentLanguage?.code ?? getFallbackLanguage()
                    ]
                  }
                </Typography>
              </Grid>
              {props.formula &&
                (props.prescriberOrCustomer ? (
                  <>
                    <Grid item>
                      <Divider orientation="vertical" />
                    </Grid>

                    <Grid item>
                      <Typography>{`${props.description}`}</Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item>
                      <Divider orientation="vertical" />
                    </Grid>
                    <Grid item>
                      <Typography>
                        {props.formula?.getShortenPosologyDescription(t)}
                      </Typography>
                    </Grid>
                  </>
                ))}
              <>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>

                <Grid item>
                  <Typography>
                    {props.formula?.needToAddPod()
                      ? `${t('pharmaco:prescription.withPod')}`
                      : `${t('pharmaco:prescription.withoutPod')}`}
                  </Typography>
                </Grid>
              </>
            </Grid>
          </TableCell>
        </TableRow>
        {props.prescriberOrCustomer ? (
          <TableRow>
            <TableCell className={classes.tabTitles}>
              <b>{t(`${config.namespace}:orderDetails.formula.pinYin`)}</b>
            </TableCell>
            <TableCell className={classes.tabTitles}>
              <b>{t(`${config.namespace}:orderDetails.formula.quantity`)}</b>
            </TableCell>
            <TableCell className={classes.tabTitles}>
              <b>{t(`${config.namespace}:orderDetails.formula.priceHT`)}</b>
            </TableCell>
            {/* <TableCell className={classes.tabTitles} >
              <b>{t(`${config.namespace}:orderDetails.formula.separate`)}</b>
            </TableCell> */}
          </TableRow>
        ) : (
          <TableRow>
            <TableCell className={classes.tabTitles}>
              <b>{t(`${config.namespace}:orderDetails.formula.ref`)}</b>
            </TableCell>
            <TableCell className={classes.tabTitles}>
              <b>{t(`${config.namespace}:orderDetails.formula.pinYin`)}</b>
            </TableCell>
            <TableCell className={classes.tabTitles}>
              <b>{t(`${config.namespace}:orderDetails.formula.quantity`)}</b>
            </TableCell>
            <TableCell className={classes.tabTitles}>
              <b>{t(`${config.namespace}:orderDetails.formula.priceHT`)}</b>
            </TableCell>
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        {props.rows.map((row, index) => (
          <RowFormula
            key={index}
            row={row}
            getRoundedItemWeight={props.getRoundedItemWeight}
            prescriberOrCustomer={props.prescriberOrCustomer}
          />
        ))}
        <TableRow>
          <TableCell colSpan={props.prescriberOrCustomer ? 1 : 2} align="right">
            <b>{t(`${config.namespace}:orderDetails.formula.total`)}</b>
          </TableCell>
          <TableCell>
            <b>{parseFloat(`${props.totalQuantity}`)?.toFixed(2)} g</b>
          </TableCell>
          <TableCell>
            <b>{props.totalPrice?.toString()}</b>
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
}
