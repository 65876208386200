import {
  IUserAttributes,
  User,
} from '@kiway/shared/features/authentication-react-compatible';

export interface ICustomerFileAttributes {
  owner: string | IUserAttributes | User;
  customer: string | IUserAttributes | User;
  background: string;
  redFlag: string;
}

export const customerFileSearchableAttributes = [];

export class CustomerFile {
  protected id: string;
  protected owner: User;
  protected customer: User;
  protected background: string;
  protected redFlag: string;

  public constructor(
    obj: Partial<ICustomerFileAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    if (typeof obj?.owner === 'string') {
      this.owner = new User({ id: obj?.owner });
    } else if (obj?.owner instanceof User) {
      this.owner = obj.owner;
    } else {
      this.owner = new User(obj?.owner);
    }
    if (typeof obj?.customer === 'string') {
      this.customer = new User({ id: obj?.customer });
    } else if (obj?.customer instanceof User) {
      this.customer = obj.customer;
    } else {
      this.customer = new User(obj?.customer);
    }
    this.background = obj?.background;
    this.redFlag = obj?.redFlag;
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): CustomerFile {
    this.id = id;
    return this;
  }

  public getOwner(): User {
    return this.owner;
  }

  public setOwner(owner: User): CustomerFile {
    this.owner = owner;
    return this;
  }

  public getCustomer(): User {
    return this.customer;
  }

  public setCustomer(customer: User): CustomerFile {
    this.customer = customer;
    return this;
  }

  public getBackground(): string {
    return this.background;
  }

  public setBackground(background: string): CustomerFile {
    this.background = background;
    return this;
  }

  public getRedFlag(): string {
    return this.redFlag;
  }

  public setRedFlag(redFlag: string): CustomerFile {
    this.redFlag = redFlag;
    return this;
  }
}
