export default {
  chiefComplaint: {
    buttons: {
      add: 'Adicione um motivo de consulta',
      update: 'Modificar este motivo de consulta',
    },
    deleteModal: {
      content:
        'Tem certeza de que deseja excluir este motivo de consulta? A operação é irreversível.',
      title: 'Exclua um motivo de consulta',
    },
    fields: {
      consultationPlaces: 'Locais de consulta',
      duration: 'Duração',
      name: 'Motivo de consulta',
      price: 'Preçario',
      specialty: 'Especialidade',
    },
    label: {
      plural: 'Motivo de consulta',
    },
    list: 'Lista de seus motivos de consulta',
    none: 'Você não tem motivos para consulta de momento',
    requirements:
      'Você deve inserir pelo menos uma especialidade e um local de consulta para adicionar motivos de consulta',
    slotInfo:
      'Ao alterar ou excluir um padrão, lembre-se de atualizar suas semanas típicas de acordo.',
    snackbars: {
      error: {
        create:
          'Ocorreu um problema durante a criação do motivo da consulta. Por favor tente novamente.',
        delete:
          'Um problema ocorreu durante a exclusão do motivo da consulta. Obrigado por tentar novamente.',
        deleteExistingChilds:
          'Esse motivo de consulta é usado por reuniões ou modelos semanais, não pode ser excluido.',
        update:
          'Um problema ocorreu durante a atualização do motivo da consulta. Obrigado por tentar novamente.',
        validation: 'Você deve completar todos os campos obrigatórios (*)',
      },
      success: {
        create: 'O motivo da consulta foi adicionado',
        delete: 'O motivo da consulta foi excluído',
        update: 'O motivo da consulta foi atualizado',
      },
    },
    subtitle:
      'Você pode associar motivos de consulta a uma especialidade e locais de prática. Os motivos serão exibidos ao marcar compromissos online, uma vez que os locais e a especialidade sejam escolhidos. e valor 60 € \n* "ACUPUNCURTE SOLGA -UP CONSULAÇÃO" DURA 60min e valor 50 €',
    title: 'Gestão dos motivos de consulta',
  },
  invoicing: {
    addressInfo: 'Morada ',
    goToProfile: 'Modificar estas informações',
    goToSettings: 'Configure oas faturas',
    invoiceAddressInfo: 'Endereço de cobrança',
    invoiceAddressInfoExplain:
      'O endereço de cobrança depende do local da consulta',
    invoiceNumbering: 'Fatura',
    labels: {
      businessName: 'Nome Comercial',
      invoiceDigits: 'Número de digitos',
      invoiceNextDigit: 'Próxima sequência de fatura',
      invoicePrefix: 'Prefixo da fatura',
      invoiceSuffix: 'Sufixo da fatura',
      naf: 'Código NAF',
      siret: 'Número FisContribuinte',
      specialMentions: 'Menções especiais',
      tva: ' IVA',
    },
    legalInfo: 'informação legal',
    legalInfoSubtitle: 'Essas informações serão exibidas no final da fatura.',
    needInvoicingDataToBillSession:
      'Você deve primeiro configurar a faturaçãoantes de poder usá -lo',
    nextInvoiceNumberExample: 'A próxima fatura usará o seguinte número',
    placeholders: {
      specialMentions:
        'Exemplo, se regime de IVA: IVA não aplicável, art. 9 do código tributário geral',
    },
    resultBottomInvoice: 'Resultado na parte inferior da fatura:',
    saveChangesBeforeEditUser:
      'Salve ou cancele as suas alterações antes de poder modificar essas informações',
    snackbars: {
      errorUpdate: 'Ocorreu um problema. Por favor tente novamente.',
      successUpdate: 'Configurações gravadas com sucesso',
    },
  },
  minisite: {
    contactInfo: {
      label:
        'Ative/Desative a exibição de suas informações de contato (telefone)',
      phoneChoice: 'Escolha do número a ser exibido em sua página',
      snackbars: {
        error: {
          update:
            'Um problema ocorreu durante o registro do ajuste de suas informações de contato. Obrigado por tentar novamente.',
        },
        success: {
          update:
            'O ajuste de suas informações de contato foi atualizado com sucesso',
        },
      },
    },
    content: {
      description: {
        label: 'Descrição',
        previewLabel: 'Visualização da sua descrição',
      },
      displaySanitary: 'Mostre instruções sanitárias',
      formation: {
        label: 'Treinamento',
        previewLabel: 'Visualização do seu treinamento',
      },
      placeholderSanitary: 'Adicione suas instruções aqui ...*',
      snackbars: {
        error: {
          sanitary:
            'Por favor, preencha ou desative instruções de saúde antes de salvar',
          update:
            'Um problema ocorreu durante a gravação do conteúdo da sua página pública. Obrigado por tentar novamente.',
        },
        success: {
          update: 'O conteúdo da sua página público foi atualizado com sucesso',
        },
      },
      styling:
        'Layout: \n* **gordura**: cercar a corda do personagem por dois asteriscos de cada lado: &ast;$ast;bold text&ast;&ast;\n* Lista não ordenada: inicie a linha com um asterisco seguido de pelo menos um espaço: $ast; Item \n',
      subtitle:
        'É aqui que você pode detalhar sua prática e dizer mais sobre você.',
      title: 'Escreva o conteúdo da sua página pública',
      titleInformation: 'Informação profissional',
      titleSanitary: 'Instruções de saúde (Covid 19)',
    },
    displayMinisite: {
      label: 'Ative/desative a sua página pública',
      snackbars: {
        error: {
          update:
            'Um problema ocorreu durante o registro do ajuste da sua página pública. Obrigado por tentar novamente.',
        },
        success: {
          update:
            'As definições de exibição da sua página pública foi atualizado com sucesso',
        },
      },
    },
    subtitle:
      'Esta página aumentará a sua visibilidade e permitirá a marcação online pelos seus pacientes.',
    title: 'Configuração da sua página pública',
    uploads: {
      gallery: {
        snackbars: {
          error: {
            delete:
              'Um problema ocorreu durante a eliminação dos arquivos (s), por favor tente novamente.',
            upload:
              'Um problema ocorreu durante o download dos arquivos (s), por favor tente novamente.',
          },
          success: {
            delete: 'O ficheiro foi aoagado com sucesso',
            upload: 'O ficheiro foi guardado com sucesso',
          },
        },
        subtitle:
          'Essas fotos serão exibidas na forma de uma galeria em sua página pública',
        title: 'Galeria de imagens',
      },
      profile: {
        snackbars: {
          error: {
            delete:
              'Um problema ocorreu durante a exclusão do arquivo, por favor, tente novamente',
            upload:
              'Um problema ocorreu durante o download do arquivo, por favor, tente novamente',
          },
          success: {
            delete: 'O ficheiro foi aoagado com sucesso',
            upload: 'O ficheiro foi guardado com sucesso',
          },
        },
        subtitle:
          'Esta foto servirá como uma foto de perfil em sua página pública',
        title: 'Foto do perfil',
      },
      subtitle:
        'As fotos que você baixam são públicas, desde que sua página pública esteja ativada. Você pode excluí -los a qualquer momento ou adicioná -los.',
      title: 'Baixe suas fotos',
    },
    urlSettings: {
      confirmModalContent:
        'Qualquer alteração na Slug resultará na revogação do URL anterior. Você tem certeza que quer continuar ?',
      confirmModalTitle: 'Confirme as suas alterações',
      doubleSlug: 'Já existe',
      errorSave:
        'Um problema ocorreu durante a gravação. Por favor tente novamente.',
      lock:
        'Ativar/desativar as alterações (se você alterar essas configurações, pense que o URL da sua página pode mudar)',
      mainSpecialty: {
        label: 'Especialidade principal',
      },
      mainWorkplace: {
        label: 'Local principal de consulta',
      },
      preview: {
        label: 'Visualização do URL da sua página pública',
      },
      refresh:
        'Se nenhum "local" ou "especialidade" for exibido, atualize a página.',
      slug: {
        label: 'O seu nome personalizado para URL',
      },
      subtitle: 'O nome personalizado permite que você obtenha um URL único',
      successSave: 'Gravado com sucesso',
      title: 'Configuração do endereço da sua página pública (URL)',
    },
  },
  onBoardingInfo:
    'Olá e bem vindo à {{app}}. Por favor preencha os dados com a sua informação profissional de forma a obter as melhores vantagens da nossa plataforma. \n\n Siga os passos abaixo e estará tudo pronto. \n\n Cordialmente, a equipa {{app}}!',
  places: {
    buttons: {
      add: 'Adicione um local de consulta',
      update: 'Edite este local de consulta',
    },
    deleteModal: {
      content:
        'Tem certeza de que deseja excluir este local de consulta? A operação é irreversível.',
      title: 'Elimine um local de consulta',
    },
    label: {
      plural: 'Locais de consulta',
    },
    list: 'Lista dos seus locais de consulta',
    none: 'Você não tem nenhum local de consulta no momento',
    previewInfo: 'Visualização do resultado na sua página pública',
    slotInfo:
      'Ao alterar ou excluir um local, lembre-se de atualizar as definições das suas semanas típicas de acordo.',
    snackbars: {
      error: {
        create:
          'Um problema ocorreu durante a criação do local de consulta. Por favor tente novamente.',
        delete:
          'Um problema ocorreu durante a remoção do local de consulta. Por favor tente novamente.',
        deleteExistingChilds:
          'Este local de consulta é usado por motivos de consulta, compromissos ou modelos semanais, não o pode excluir.',
        update:
          'Um problema ocorreu durante a atualização do local de consulta. Por favor tente novamente.',
      },
      success: {
        create: 'O local de consulta foi adicionado',
        delete: 'O local de consulta foi excluído',
        update: 'O local de consulta foi atualizado',
      },
    },
    subtitle:
      'Digite seus diferentes lugares de prática. Eles serão oferecidos na nomeação online.',
    title: 'Gestão dos seus locais de consulta',
  },
  snackbars: {
    error: {
      form: {
        errorsNotResolved: 'Você deve resolver os erros e validar novamente.',
        noAddress:
          'Você deve inserir um endereço válido. Certifique -se de clicar na sugestão correspondente ao seu endereço durante a pesquisa.',
        noName: 'Você deve inserir um nome para o seu local de consulta.',
      },
      rightsIssue:
        'Um erro impede o envio do pedido, parece ser um problema de direitos',
    },
  },
  specialties: {
    info:
      'O backup é automático para este campo. Ao excluir uma especialidade, lembre -se de atualizar suas semanas típicas de acordo.',
    label: {
      plural: 'Especialidades',
      singular: 'Especialidade',
    },
    pickOne: 'Escolha uma especialidade',
    snackbars: {
      error: {
        deleteExistingChilds:
          'Esta especialidade é usada para motivos de consulta, você não pode excluí-la.',
        update:
          'Um problema ocorreu durante a gravação das suas especialidades. Por favor tente novamente.',
      },
      success: {
        update: 'Especialidades guardadas com sucesso',
      },
    },
    subtitle:
      'Selecione as especialidades que você pratica e deseja oferecer aos seus pacientes.',
    title: 'Gestão das suas especialidades',
  },
  subtitle:
    'Estas informações são necessárias para o funcionamento adequado da plataforma. Elas serão exibidas na sua página pública e servem para apresentar a sua atividade. Também permitirão que configurar as consulta online.',
  tabs: {
    activity: 'Atividade',
    agenda: 'Marcações Online',
    invoicing: 'Faturação',
    publicPage: 'Página pública',
    workplaces: 'Locais de consulta',
  },
  title: 'Configuração da informação profissional',
};
