import { Carrier, ICarrierAttributes } from './Carrier';
import { Country, ICountryAttributes } from './Country';

export interface ICountryZoneAttributes {
  name: string;
  countries: Array<string | Country | ICountryAttributes>;
}

export class CountryZone {
  protected id: string;
  protected name: string;
  protected countries: Country[];

  public constructor(
    obj: Partial<ICountryZoneAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    this.name = obj?.name;
    if (obj?.countries) {
      this.countries = obj?.countries?.map((country) => {
        if (typeof country === 'string') {
          return new Country({ id: country });
        } else if (country instanceof Country) {
          return country;
        } else {
          return new Country(country);
        }
      });
    }
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): CountryZone {
    this.id = id;
    return this;
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string): CountryZone {
    this.name = name;
    return this;
  }

  public getCountries(): Country[] {
    return this.countries;
  }

  public setCountries(countries: Country[]): CountryZone {
    this.countries = countries;
    return this;
  }

  public addCountry(country: Country): CountryZone {
    this.countries = [...this.countries, country];
    return this;
  }

  public removeCountry(countryId: string): CountryZone {
    this.countries = this.countries.filter(
      (country) => country.getId() !== countryId
    );
    return this;
  }

  toJSON(): any {
    return {
      id: this.getId(),
      name: this.getName(),
      countries: this.getCountries().map((country) => country.toJSON()),
    };
  }

  public toDatatableRow(): any {
    return {
      _id: this.getId(),
      name: this.getName(),
      countries: this.getCountries()?.map((country) => country.getId()),
    };
  }

  public toDropdownValue(): any {
    return {
      label: this.getName(),
      value: this.getId(),
    };
  }
}
