/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react';
import {
  OrderStat,
  OrderStatName,
  OrderStatPeriod,
} from '@kiway/ecommerce-react-compatible';
import { useOrdersGateway } from '@kiway/ecommerce-react';
import { AnalyticEcommerce } from './AnalyticsEcommerce';

interface AnalyticEcommerceControllerProps {
  title: string;
  statType: OrderStatName;
  period: OrderStatPeriod;
  subtitle?: string;
}

export const AnalyticEcommerceController = (
  props: AnalyticEcommerceControllerProps
) => {
  const { getOrderStats } = useOrdersGateway();

  const [stats, setStats] = React.useState<OrderStat>();
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    getOrderStats(props.period, [props.statType])
      .then((result) => setStats(result[0]))
      .finally(() => setLoading(false));
    return () => {
      setStats(null);
      setLoading(false);
    };
  }, [props.period]);

  return (
    <AnalyticEcommerce
      title={props.title}
      count={stats?.data?.value}
      percentage={stats ? stats?.delta * 100 : 0}
      extra={stats?.deltaValue}
      type={stats?.type}
      loading={loading}
      period={props.period}
      statType={props.statType}
      subtitle={props.subtitle}
    />
  );
};
