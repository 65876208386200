export default {
  checkboxCGA: `Al verificar este cuadro, certifique haber leído y aceptado sin reserva el [CGA](${process.env['NX_CGA_URL']}) *`,
  checkboxDiploma:
    'Al verificar esta casilla, certifico estar en posesión de un diploma emitido por una escuela reconocida por una federación profesional *',
  checkboxSchool:
    'Al verificar esta casilla, certifico estar en posesión de un certificado de educación en una escuela reconocida por una federación profesional *',
  errorRetrievingCheckoutSessionId:
    'Se produjo un error durante la redirección a la plataforma de pago. Si el error persiste, contáctenos en support@kiway.co',
  invoicing: {
    labels: {
      amount_due: 'Valor',
      date: 'Fecha',
      description: 'Descripción',
      number: 'Número de factura',
      paid: 'Pagado',
    },
    title: 'Mis facturas',
  },
  language: 'es',
  lastPaymentFailed: 'Último problema de pago',
  paymentSources: {
    modalConfirmText:
      '¿Estás seguro de que quieres eliminar este método de pago? Esta acción es irreversible.',
    modalDeleteTitle: 'Eliminación de un método de pago',
    noData: 'No hay metodos de pago',
    snackbars: {
      error: 'Ocurrió un problema, gracias por intentarlo de nuevo',
      successDefaultCard:
        'El pago por defecto significa actualizado correctamente',
      successRemoveCard: 'Métodos de pago exitosos',
    },
    title: 'Métodos de pago',
  },
  servicesOnDemand: {
    balance: 'Balance aplicado',
    firstPayment: 'Primer pago',
    ifThreeDSecure:
      'Si sus medios de pago requieren autenticación (por ejemplo, 3D Secure), se le enviará un enlace por correo electrónico para cada pago para autenticarlo (ahora y antes de cada renovación).',
    licensedServicesTitle: 'Servicios con suscripción',
    licensedServicesTooltip:
      'Los servicios de suscripción se facturan al comienzo del período.',
    logoutScreen: {
      error:
        'Sus medios de pago pueden requerir autenticación (3D seguro, por ejemplo). Para resolver este problema y regularizar el pago, siga el enlace a continuación: \n\n[%textLink%](%link%)\n\nUne Times La factura pagada, lo invitamos a desconectar. Esto tendrá en cuenta todos los cambios.',
      errorTitle: 'Se produjo un problema durante el pago',
      success:
        'Para beneficiarse de todos sus servicios, desconecte. Esto tendrá en cuenta todos los cambios.',
      successTitle: '¡Su suscripción fué registrada con sucesso!',
    },
    meteredServicesTitle: 'Servicio al consumidor',
    meteredServicesTooltip:
      'Los servicios de consumo se facturan al final del período.',
    modalConfirmText:
      'Los cambios se facturan durante la renovación. Por lo tanto, su próxima factura se recalculará teniendo en cuenta estas modificaciones.',
    modalTitle: 'Actualización de sus servicios',
    needLogout:
      'La desconexión es necesaria para tener en cuenta las últimas modificaciones',
    nextPayments: 'Siguientes pagos',
    recapServices: 'Resumen de sus servicios',
    snackbars: {
      error:
        'Se produjo un error, intente nuevamente más tarde o comuníquese con el soporte.',
      errorPayment:
        'Se produjo un problema durante el pago, siga las instrucciones que se muestran en la página',
      success: 'La suscripción fue bien registrada',
    },
    stepper: {
      chooseServices: 'Elija sus servicios',
      confirm: 'Confirmación',
      payment: 'Pago',
    },
    subTotal: 'Total parcial',
    title: 'Lista de servicios',
    total: 'Total',
    usage: 'consumo',
    vat20Included: 'IVA 20% incl.',
    vat20NotIncluded: 'IVA 20%',
  },
  startUsingApp: 'Comience a usar la aplicación',
  subSuccessMessage:
    'Su suscripción ahora está activa. Puede cambiar su método de pago, encontrar sus facturas y modificar su suscripción en cualquier momento en su menú de usuario.',
  thanks: 'Gracias !',
  trialPending: 'Período de prueba actual',
};
