export default {
  actions: {
    delete: {
      title: 'Borrar un modelo de semana',
      warning: '¿Quieres borrar este modelo semanal?',
    },
    deleteSlot: {
      title: 'Borrar un nicho',
      warning: '¿Quieres borrar este nicho?',
    },
    preview: {
      title: 'Vista previa de sus modelos semanales',
    },
  },
  caption: {
    colors:
      'Los colores están allí como una indicación para agrupar las ranuras. Un color = un nicho.',
    onlineDisplay:
      'Visibilidad en línea. Respectivamente en línea y fuera de línea.',
    slotMode:
      'Modo de llenado de ranura. Respectivamente comenzando, fin y ambos.',
    title: 'Leyenda',
  },
  disable: 'Desactivar',
  eachTwoWeeks: 'Una vez cada dos semanas',
  eachWeek: 'Todas las semanas',
  enable: 'Permitir',
  endDate: 'Fecha de final',
  endHour: 'Final del nicho',
  form: {
    title: {
      add: 'Agregar un modelo',
      addSlot: 'Agregar un nicho',
      edit: 'Modificar el modelo',
      editSlot: 'Modificar el nicho',
    },
  },
  from: 'De',
  info:
    'Las semanas típicas permiten proporcionar espacios para hacer citas en línea. Para cada semana de la semana, puede crear espacios con diferentes lugares y razones para la consulta.',
  name: 'Tipo de nombre de la semana',
  online: {
    available: 'Disponible en linea',
    unavailable: 'No disponible en línea',
  },
  pause: 'Romper entre dos reuniones',
  recurrence: 'Reaparición',
  slot: {
    days: 'Dias',
    endHour: 'Hora de finalización',
    noPreview: 'Agregue un nicho para obtener una vista previa de su semana',
    noWorkplaceSelected:
      'Seleccione una ubicación de consulta para mostrar los patrones asociados.',
    pause: 'Pausa',
    preview: 'Descripción general de mi semana',
    startHour: 'Hora de inicio',
    title: 'Nicho',
    workplace: 'Ubicación de consulta',
  },
  slotChiefComplaints: 'Razones de consulta',
  slotConsultationPlace: 'Ubicación de consulta',
  slotDays: 'Días de nicho activos',
  slotMode: 'Modo de llenado',
  slotModes: {
    down: 'Final del nicho',
    up: 'Inicio de nicho',
    'up&down': 'En ambos sentidos',
  },
  slotModesInfo: {
    down:
      'Genera reuniones a partir del final del nicho. Por ejemplo, de 10 a.m. a 12 p.m., si tomamos razones para una consulta que dura 50 minutos, los espacios ofrecidos serán de 10:20 y 11:10.',
    up:
      'Genera reuniones a partir del comienzo del nicho. Por ejemplo, de 10 a.m. a 12 p.m., si toma un terreno de consulta de 50 minutos, los espacios ofrecidos serán de 10 a.m. y 10:50 a.m.',
    'up&down':
      'Genera tantas reuniones como sea posible en un espacio de tiempo definido. Por ejemplo, de 10 a.m. a 12 p.m., si tomamos razones para una consulta que dura 50 minutos, los espacios ofrecidos serán de 10 a.m., 10:20 a.m., 10:50 a.m. y 11:10 a.m. Estas reuniones serán ajustadas de acuerdo con la primera elegida por un paciente. Si el primero en elegir toma las 10:20, solo permanecerá disponible a las 11:10 am.',
  },
  snackbars: {
    error: {
      delete: 'Ha ocurrido un error. Por favor intente de nuevo',
      save: 'Ha ocurrido un error. Por favor intente de nuevo',
      slotOverlap: 'No puedes superponer dos ranuras para el mismo modelo',
    },
    success: {
      delete: 'Exitoso',
      save: 'Exitoso',
    },
  },
  startDate: 'Fecha de inicio',
  startHour: 'Inicio de nicho',
  title: 'Mis semanas típicas',
  to: 'para',
  warningTemplate:
    'Varias semanas típicas no pueden estar activas la misma semana. Durante una semana determinada, es la semana típica más alta de la lista que gana. Puede cambiar el tipo de semanal semanal con un "arrastre y suelte".',
};
