export default {
  checkboxCGA: `En cochant cette case, je certifie avoir lu et accepté sans réserve les [CGA](${process.env['NX_CGA_URL']}) *`,
  checkboxDiploma:
    "En cochant cette case, je certifie être en possession d'un diplôme émis par une école reconnue par une organisation professionnelle *",
  checkboxSchool:
    "En cochant cette case, je certifie être en possession d'un certificat de scolarité dans une école reconnue par une organisation professionnelle *",
  errorRetrievingCheckoutSessionId:
    "Une erreur est survenue durant la redirection vers la plateforme de paiement. Si l'erreur persiste, contactez-nous sur support@kiway.co",
  invoicing: {
    labels: {
      amount_due: 'Montant',
      date: 'Date',
      description: 'Description',
      number: 'Numéro de facture',
      paid: 'Payée',
    },
    title: 'Mes factures',
  },
  language: 'fr',
  lastPaymentFailed: 'Problème dernier paiement',
  paymentSources: {
    modalConfirmText:
      'Êtes-vous sûr de vouloir supprimer ce moyen de paiement ? Cette action est irréversible.',
    modalDeleteTitle: "Suppression d'un moyen de paiement",
    noData: 'Aucun moyen de paiement',
    snackbars: {
      error: "Un problème est survenu, merci d'essayer à nouveau",
      successDefaultCard: 'Moyen de paiement par défaut mis à jour avec succès',
      successRemoveCard: 'Moyen de paiement supprimé avec succès',
    },
    title: 'Méthodes de paiement',
  },
  servicesOnDemand: {
    balance: 'Solde appliqué',
    firstPayment: 'Premier paiement',
    ifThreeDSecure:
      "Si votre moyen de paiement nécessite une authentification (par exemple 3D secure), un lien vous sera envoyé par mail pour chaque paiement afin de l'authentifier (maintenant et avant chaque renouvellement).",
    licensedServicesTitle: "Services à l'abonnement",
    licensedServicesTooltip:
      "Les services à l'abonnement sont facturés au prorata et en début de période.",
    logoutScreen: {
      error:
        'Il se peut que votre moyen de paiement nécessite une authentification (3D secure par exemple). Afin de régler ce problème et régulariser le paiement, veuillez suivre le lien ci-aprés :\n\n[%textLink%](%link%)\n\nUne fois la facture réglée, nous vous invitons à vous déconnecter. Cela permettra de prendre en compte toutes les modifications.',
      errorTitle: 'Un problème est survenu durant le paiement',
      success:
        'Pour bénéficier de tous vos services, merci de bien vouloir vous déconnecter. Cela permettra de prendre en compte toutes les modifications.',
      successTitle: 'Votre abonnement a bien été pris en compte !',
    },
    meteredServicesTitle: 'Services à la consommation',
    meteredServicesTooltip:
      'Les services à la consommation sont facturés en fin de période.',
    modalConfirmText:
      'Les modifications sont facturées lors du renouvellement. Votre prochaine facture sera donc recalculée en prenant en compte ces modifications.',
    modalTitle: 'Mise à jour de vos services',
    needLogout:
      'Une déconnexion est nécessaire pour prendre en compte les dernières modifications',
    nextPayments: 'Paiements suivants',
    recapServices: 'Récapitulatif de vos services',
    snackbars: {
      error:
        'Une erreur est survenue, merci de réessayer plus tard ou de contacter le support.',
      errorPayment:
        'Un problème est survenu durant le paiement, merci de suivre les instructions affichées sur la page',
      success: "L'abonnement a bien été pris en compte",
    },
    stepper: {
      chooseServices: 'Choisissez vos services',
      confirm: 'Confirmation',
      payment: 'Paiement',
    },
    subTotal: 'Sous-total',
    title: 'Liste des services',
    total: 'Total',
    usage: 'consommation',
    vat20Included: 'TVA 20% incl.',
    vat20NotIncluded: 'TVA 20%',
  },
  startUsingApp: "Commencer à utiliser l'application",
  subSuccessMessage:
    'Votre abonnement est désormais actif. Vous pouvez modifier votre moyen de paiement, retrouver vos factures et modifier votre abonnement à tout moment dans votre menu utilisateur.',
  thanks: 'Merci !',
  trialPending: "Période d'essai en cours",
};
