import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useReducer } from 'react';
import PhoneNumber from 'awesome-phonenumber';
import { useTranslation } from '@kiway/shared/utils/translation';
import useCountrySelector from './CountrySelector/CountrySelector';
import GreenPageTextField from './GreenPageTextField';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';

const initState = {
  phoneValue: '',
  phoneDisplay: '',
  e164: '',
  countryCode: '',
  error: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_COUNTRY':
      return { ...state, countryCode: action.payload };
    case 'CHANGE_PHONE':
      return {
        ...state,
        phoneValue: action.payload.value,
        phoneDisplay: action.payload.display,
        e164: action.payload.e164,
        error: action.payload.error,
      };
    default:
      throw new Error('Unhandled action type');
  }
};

const getDisplayFromValue = (countryCode, value) => {
  const phoneNumber = new PhoneNumber(`+${countryCode}${value}`);
  if (phoneNumber.isValid()) {
    return phoneNumber
      ?.getNumber('international')
      ?.replace('+' + countryCode + ' ', '');
  } else {
    return value?.replace(/ /g, '');
  }
};

const isPhoneNumberValid = (countryCode, value, options) => {
  const { forceMobile } = options;
  const phoneNumber = new PhoneNumber(`+${countryCode}${value}`);
  return (
    value?.length === 0 ||
    (phoneNumber.isValid() &&
      ((forceMobile && phoneNumber.getType() === 'mobile') || !forceMobile))
  );
};

function useNewPhoneInputField(props = {}) {
  const { forceMobile, inputProps, type, ...other } = props;
  const [countryState, CountrySelector, updateCountry] = useCountrySelector({
    type: type,
  });
  const updateValue = (value) => {
    if (value) {
      const initPhone = new PhoneNumber(value[0] === '+' ? value : '+' + value);
      const countryCode = initPhone.getCountryCode();
      if (countryCode && countryState.value !== countryCode) {
        updateCountry(countryCode);
      }
      dispatch({
        type: 'CHANGE_PHONE',
        payload: {
          value: initPhone.getNumber('significant'),
          display: initPhone
            .getNumber('input')
            .replace('+' + countryCode + ' ', ''),
          e164: initPhone.getNumber(),
          error: !initPhone.isValid(),
        },
      });
    }
  };
  const resetValue = () => {
    dispatch({
      type: 'CHANGE_PHONE',
      payload: {
        ...initState,
        value: initState.phoneValue,
        display: initState.phoneDisplay,
      },
    });
  };
  const [state, dispatch] = useReducer(reducer, initState, () => ({
    ...initState,
  }));

  useEffect(() => {
    dispatch({ type: 'CHANGE_COUNTRY', payload: countryState.value.dialCode });
  }, [countryState.value]);

  const { t } = useTranslation();

  const componentProps = {
    fullWidth: true,
    variant: 'outlined',
    margin: 'dense',
    label: forceMobile
      ? t('common:form.fields.phone.mobileLabel')
      : t('common:form.fields.phone.label'),
    error: !isPhoneNumberValid(state.countryCode, state.phoneValue, {
      forceMobile,
    }),
    helperText:
      !isPhoneNumberValid(state.countryCode, state.phoneValue, {
        forceMobile,
      }) &&
      (forceMobile
        ? t('common:form.fields.phone.errorNotValidOrNotMobile')
        : t('common:form.fields.phone.errorNotValid')),
    value: getDisplayFromValue(state.countryCode, state.phoneValue),
    onChange: (event) => {
      const { value } = event.target;
      const phoneNumber = new PhoneNumber(`+${state.countryCode}${value}`);
      dispatch({
        type: 'CHANGE_PHONE',
        payload: {
          value: value,
          e164: phoneNumber.isValid() ? phoneNumber.getNumber('e164') : '',
          error: !phoneNumber.isValid(),
        },
      });
    },
    InputProps: {
      startAdornment: (
        <InputAdornment position="start">+{state.countryCode}</InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            onClick={() =>
              dispatch({
                type: 'CHANGE_PHONE',
                payload: {
                  ...initState,
                  value: initState.phoneValue,
                  display: initState.phoneDisplay,
                },
              })
            }
          >
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      ),
    },
  };

  const Component = (
    <Grid
      container
      spacing={1}
      style={{ boxSizing: 'border-box' }}
      direction="row"
      alignItems="baseline"
      {...other}
    >
      <Grid item xs={12} sm={5}>
        {CountrySelector}
      </Grid>
      <Grid item xs={12} sm={7}>
        {type === 'green' ? (
          <GreenPageTextField {...componentProps} {...inputProps} />
        ) : (
          <TextField {...componentProps} {...inputProps} />
        )}
      </Grid>
    </Grid>
  );
  return [state, Component, updateValue, resetValue];
}

export default useNewPhoneInputField;
