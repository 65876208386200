import {
  ShippingMethodPrice,
  IShippingMethodPriceAttributes,
} from '../../core/entities/ShippingMethodPrice';
import { ShippingMethodPriceProvider } from '../../core/use_cases/ShippingMethodPriceProvider';
import { gql, ApolloClient } from '@apollo/client';

export const LIST_SHIPPINGMETHODPRICES = gql`
  query {
    listShippingMethodPrices {
      id
      minWeight
      maxWeight
      price {
        currency {
          id
          code
          symbol
          format
        }
        centAmount
      }
      shippingMethod {
        id
        name
        carrier {
          id
          name
          code
          trackingLink
        }
      }
      # Add here your attributes
    }
  }
`;

export const EDIT_MULTIPLE_SHIPPING_METHOD_PRICES = gql`
  mutation editManyShippingMethodPrices($input: [ShippingMethodPriceInput]) {
    editManyShippingMethodPrices(input: $input) {
      id
      minWeight
      maxWeight
      price {
        currency {
          id
          code
          symbol
          format
        }
        centAmount
      }
      shippingMethod {
        id
        name
      }
    }
  }
`;

export type IShippingMethodPriceAttributesData = IShippingMethodPriceAttributes & {
  id: string;
};

export interface ListShippingMethodPricesData {
  listShippingMethodPrices: Array<IShippingMethodPriceAttributesData>;
}

export interface EditManyShippingMethodPricesData {
  editManyShippingMethodPrices: Array<IShippingMethodPriceAttributesData>;
}

export class ShippingMethodPriceGraphQLProvider
  implements ShippingMethodPriceProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async findAll(): Promise<ShippingMethodPrice[]> {
    try {
      const result = await this.client?.query<ListShippingMethodPricesData>({
        query: LIST_SHIPPINGMETHODPRICES,
      });
      return result.data.listShippingMethodPrices.map(
        (item) => new ShippingMethodPrice(item)
      );
    } catch (e) {
      console.log(e);
    }
  }
  save(
    shippingMethodPrice: ShippingMethodPrice,
    userId?: string
  ): Promise<ShippingMethodPrice> {
    throw new Error('Method not implemented.');
  }
  async editMany(
    entities: IShippingMethodPriceAttributes[],
    userId?: string
  ): Promise<ShippingMethodPrice[]> {
    try {
      const result = await this.client?.mutate<
        EditManyShippingMethodPricesData,
        { input: IShippingMethodPriceAttributes[] }
      >({
        mutation: EDIT_MULTIPLE_SHIPPING_METHOD_PRICES,
        variables: {
          input: entities,
        },
      });
      return result.data?.editManyShippingMethodPrices?.map(
        (item) => new ShippingMethodPrice(item)
      );
    } catch (e) {
      console.log(e);
    }
  }
}
