import React, { useContext } from 'react';
import Modal from './Modal';
import { CustomButton } from './Buttons';
import { useTranslation } from '@kiway/shared/utils/translation';
import { AuthContext } from '@kiway/shared/features/authentication-react';
import client from '../graphql/client';
import { GET_STRIPE_CONNECT_URL } from '../graphql/payment';
import { Typography } from '@material-ui/core';

function StripeConnect() {
  const { t } = useTranslation();

  const { updateUserInfo } = useContext(AuthContext);

  const handleCloseStripeConnect = () => {
    updateUserInfo({ showStripeConnect: false });
  };

  const handleConnectWithStripeClick = () => {
    client.query({ query: GET_STRIPE_CONNECT_URL }).then(({ data }) => {
      if (data?.getStripeConnectURL) {
        window.open(data.getStripeConnectURL, '_blank');
      }
    });
  };

  return (
    <Modal
      title={`${t('common:connectWith')} Stripe`}
      open={true}
      handleClose={handleCloseStripeConnect}
      actions={
        <CustomButton onClick={handleCloseStripeConnect}>
          {t('common:controlButtons.later')}
        </CustomButton>
      }
    >
      <Typography variant="body1" style={{ marginBottom: '16px' }}>
        {t('invoicing:payment.connectWithStripeText')}
      </Typography>
      <CustomButton color="minoritary" onClick={handleConnectWithStripeClick}>
        {t('common:connectWith')} <b style={{ marginLeft: '5px' }}>Stripe</b>
      </CustomButton>
    </Modal>
  );
}

export default StripeConnect;
