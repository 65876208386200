import * as React from 'react';
import { Grid, FormControl, Select, MenuItem, Stack } from '@mui/material';
import { Order } from '@kiway/ecommerce-react-compatible';
import { useTranslation } from '@kiway/shared/utils/translation';
import { ShippingMethod } from '@kiway/ecommerce-react';

interface CartInfosProps {
  availableShippingMethods: ShippingMethod[];
  handleChangeShippingMethod: (id: string) => any;
  order: Order;
}

export function CartInfos(props: CartInfosProps) {
  const { currentLanguage, t } = useTranslation();

  const returnCombo = () => {
    return (
      <FormControl fullWidth size="small">
        <Select
          onChange={(e) => {
            props.handleChangeShippingMethod(e.target?.value);
          }}
          value={props.order?.getShippingMethod()?.getId() ?? '0'}
        >
          <MenuItem value="0">
            <i>{t('pharmaco:orderDetails.shipment.selectMethod')}</i>
          </MenuItem>
          {props.availableShippingMethods
            ?.sort(ShippingMethod.sortByDisplayOrder())
            ?.map((shippingMethod) => (
              <MenuItem
                key={shippingMethod.getId()}
                value={shippingMethod.getId()}
              >
                {shippingMethod.getName()?.[currentLanguage?.code] ?? ''} (
                {shippingMethod
                  .getPrice(
                    props.order.getShippingAddress()?.getCountryCode(),
                    props.order.getTotalWeight()
                  )
                  ?.toString()}
                )
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8}>
          <Stack spacing={2}>
            {/* <CartInfosItem
              label={'Total HT'}
              value={props.order?.getTaxedTotalPrice()?.totalGross?.toString()}
            />
            <Divider variant="middle" />
            <CartInfosItem
              label={'Total TTC'}
              value={props.order?.getTaxedTotalPrice()?.totalNet?.toString()}
            />
            <Divider variant="middle" /> */}
            {/* <CartInfosItem
              align="left"
              label={'Mode de livraison'}
              value={returnCombo()}
            /> */}
            {returnCombo()}
            {/* <Divider variant="middle" />
            <CartInfosItem
              label={'Total à payer'}
              value={props.order?.getTotalPrice()?.toString()}
            /> */}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
