export default {
  agenda: {
    bookedOnline: 'Reservado en línea',
  },
  booking: {
    chiefComplaint: 'Razón de consulta',
    more: 'Ver más',
    next: 'Próxima cita',
    slot: 'Elija un horário',
    title: 'Cita en línea',
    workplace: 'Ubicación de consulta',
  },
  info: {
    reserved:
      'Su cita está reservada temporariamente, confirme en 10 minutos o no quedará disponible.',
  },
  snackbars: {
    error: 'Se produjo un error, intente nuevamente más tarde.',
    success: 'Su cita ha sido registrada.',
  },
  stepper: {
    confirmation: {
      button: 'Confirmar',
      comment: 'Agregue un comentario (opcional)',
      dateFormat: 'DD-MM-YYYY à HH:mm',
      text: 'Cita con',
      title: 'Confirmación',
    },
    identification: {
      login: 'Ya tengo una cuenta',
      signup: '¿Nuevo en {{app}}?',
      title: 'Identificación',
    },
  },
};
