import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import logoKiwayBlanc from '../assets/images/logo/logo_blanc_light.png';
import { ThemeContext } from '../services/contexts/ThemeContext';
import { useTranslation } from '@kiway/shared/utils/translation';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.minoritary.main,
    color: theme.palette.minoritary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.minoritary.dark,
      color: theme.palette.minoritary.contrastText,
    },
  },
}));

function GreenPageWithAppVersion(props) {
  const classes = useStyles();
  const { themeContent } = useContext(ThemeContext);
  const { renderLanguageChoice } = useTranslation();
  const gitVersion = process.env['NX_GIT_VERSION'] || '';

  const { children, title } = props;

  return (
    <div
      id="signin"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
        background: `linear-gradient(${themeContent.palette.primary.main}, ${themeContent.palette.secondary.main})`,
      }}
    >
      <div
        style={{
          flex: 1,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Container maxWidth="xs">
          <form className={classes.form} noValidate autoComplete="off">
            {title && (
              <Typography
                component="h1"
                variant="h5"
                style={{ color: 'white', textAlign: 'center' }}
              >
                {title}
              </Typography>
            )}
            {children}
          </form>
        </Container>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        {renderLanguageChoice()}
      </div>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <a href="https://kiway.co" target="_blank" rel="noopener noreferrer">
          <img
            src={logoKiwayBlanc}
            alt="logo kiway blanc"
            style={{ width: '100px' }}
          />
        </a>
        <p style={{ width: '100%', color: 'white' }}>{gitVersion}</p>
      </div>
    </div>
  );
}

GreenPageWithAppVersion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default GreenPageWithAppVersion;
