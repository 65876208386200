import * as React from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Autocomplete,
} from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import {
  TempFormula,
  TempFormulaMode,
  TempFormulaType,
} from '../../../core/entities/PharmacoOrder';

interface FormulaChoiceChipProps {
  changeFormulaName: (formulaName: string) => void;
  toggleFormulaMode: (mode: TempFormulaMode) => void;
  changeFormulaSelection: (
    groupBy: string,
    formulaName: string,
    type: TempFormulaType
  ) => void;
  formula: TempFormula;
  formulas: Array<{ name: string; key: string; type: TempFormulaType }>;
}

export function FormulaChoiceChip(props: FormulaChoiceChipProps) {
  const { t } = useTranslation();

  return (
    <RadioGroup row defaultValue="newFormula">
      <FormControlLabel
        value="newFormula"
        control={<Radio />}
        label={t('pharmaco:radioButton.newFormula') as string}
        onClick={() => props.toggleFormulaMode('add')}
      />

      {props.formula?.getMode() === 'add' ? (
        <TextField
          size="small"
          variant="standard"
          placeholder={t('pharmaco:radioButton.placeholderNF')}
          value={props.formula.getName() ?? ''}
          onChange={(e) => props.changeFormulaName(e?.target?.value ?? '')}
        />
      ) : null}

      {props.formula?.getMode() === 'edit' ? (
        <FormControlLabel
          value="addFormula"
          control={<Radio disabled={props.formulas?.length <= 0} />}
          disabled={props.formulas?.length <= 0}
          label={
            <Autocomplete
              disabled={props.formulas?.length <= 0}
              options={
                props.formulas?.filter(props.formula.getFilterFromType()) ?? []
              }
              value={
                props.formula.getSelectedFormula()?.key
                  ? props.formula.getSelectedFormula()
                  : null
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.key}>
                    {option.name}
                  </li>
                );
              }}
              isOptionEqualToValue={(option, value) => {
                return option.key === value.key;
              }}
              onChange={(e, value, reason) => {
                if (
                  ['selectOption', 'removeOption', 'clear'].includes(reason)
                ) {
                  if (!value) {
                    props.changeFormulaSelection(null, null, null);
                  } else if (typeof value === 'string') {
                    props.changeFormulaSelection(value, value, null);
                  } else {
                    props.changeFormulaSelection(
                      value.key,
                      value.name,
                      value.type
                    );
                  }
                }
              }}
              size="small"
              getOptionLabel={(option) => option.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('pharmaco:radioButton.addFormula') as string}
                />
              )}
            />
          }
          onClick={() => props.toggleFormulaMode('edit')}
        />
      ) : (
        <FormControlLabel
          disabled={props.formulas?.length <= 0}
          value="addFormula"
          control={<Radio />}
          label={t('pharmaco:radioButton.addFormula') as string}
          onClick={() => props.toggleFormulaMode('edit')}
        />
      )}
    </RadioGroup>
  );
}
