import { Checkbox, FormControlLabel } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert/Alert';
import React, { useContext, useReducer } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '@kiway/shared/features/authentication-react';
import { useTranslation } from '@kiway/shared/utils/translation';
import CustomButton from '../Buttons/CustomButton';
import GreenPageTextField from './GreenPageTextField';
import useAuthService from '../../services/auth/authService';
import ReactGA from 'react-ga';
import { AUTH_CAT, LOGIN_ACTION } from '../../services/ga/gaEvents';

const initState = {
  email: '',
  errors: {
    email: '',
    password: '',
    global: '',
  },
  loading: false,
  loginComplete: false,
  password: '',
  rememberMe: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMITTING':
      return { ...state, loading: true };
    case 'LOGIN_COMPLETE':
      return {
        ...state,
        loading: false,
        loginComplete: action.payload.success,
      };
    case 'CHANGE_DATA':
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case 'ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      throw new Error('Unhandled action type');
  }
};

export function useSignIn() {
  const { isAuthenticated } = useContext(AuthContext);
  const { login } = useAuthService();
  const [state, dispatch] = useReducer(reducer, initState, () => ({
    ...initState,
    loginComplete: isAuthenticated(),
  }));
  const { t } = useTranslation();
  const handleChange = (isCheckbox) => (event) => {
    const { name, value, checked } = event.target;
    dispatch({
      type: 'CHANGE_DATA',
      payload: {
        key: name,
        value: isCheckbox
          ? checked
          : name === 'email'
          ? value.toLowerCase()
          : value,
      },
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: AUTH_CAT,
      action: LOGIN_ACTION,
    });
    const { email, password, rememberMe } = state;
    if (email === '') {
      dispatch({
        type: 'ERROR',
        payload: {
          key: 'email',
          value: t('validation:form.requiredField.empty'),
        },
      });
    } else {
      dispatch({ type: 'ERROR', payload: { key: 'email', value: '' } });
    }
    if (password === '') {
      dispatch({
        type: 'ERROR',
        payload: {
          key: 'password',
          value: t('validation:form.requiredField.empty'),
        },
      });
    } else {
      dispatch({ type: 'ERROR', payload: { key: 'password', value: '' } });
    }
    if (email === '' || password === '') {
      return;
    }
    dispatch({ type: 'SUBMITTING' });
    login({ email, password, rememberMe })
      .then((result) => {
        if (!result._id) {
          if (result.verified) {
            dispatch({
              type: 'ERROR',
              payload: {
                key: 'global',
                value: t('validation:form.login.accountNotVerified'),
              },
            });
          } else if (result.rights) {
            dispatch({
              type: 'ERROR',
              payload: {
                key: 'global',
                value: t('validation:form.login.noRights'),
              },
            });
          } else {
            dispatch({
              type: 'ERROR',
              payload: {
                key: 'global',
                value: t('validation:form.login.error'),
              },
            });
          }
        } else {
          dispatch({ type: 'ERROR', payload: { key: 'global', value: '' } });
          dispatch({ type: 'LOGIN_COMPLETE', payload: { success: true } });
          return;
        }
        dispatch({ type: 'LOGIN_COMPLETE', payload: { success: false } });
      })
      .catch(() => {
        dispatch({
          type: 'ERROR',
          payload: { key: 'global', value: t('validation:form.login.error') },
        });
        dispatch({ type: 'LOGIN_COMPLETE', payload: { success: false } });
      });
  };
  return [state, handleChange, handleSubmit, dispatch];
}

export function useSignInForm(options) {
  const { t } = useTranslation();
  const {
    customProps,
    emailAutoFocus,
    from,
    redirectURI,
    submitColor,
    type,
  } = options;
  const [state, handleChange, handleSubmit] = useSignIn();
  const { email, errors, loading, loginComplete, password, rememberMe } = state;

  const emailFieldProps = {
    required: true,
    id: 'email',
    name: 'email',
    label: t('user:form.emailField'),
    autoComplete: 'email',
    value: email,
    error: Boolean(errors.email),
    helperText: errors.email,
    onChange: handleChange(),
    autoFocus: emailAutoFocus,
  };
  const passwordFieldProps = {
    required: true,
    id: 'password',
    name: 'password',
    type: 'password',
    label: t('user:form.passwordField'),
    autoComplete: 'current-password',
    value: password,
    error: Boolean(errors.password),
    helperText: errors.password,
    onChange: handleChange(),
  };

  const SignInComponent = loginComplete ? (
    from ? (
      <Redirect to={from} />
    ) : redirectURI ? (
      <Redirect to={redirectURI} />
    ) : (
      <div>{/*DO SOMETHING WHEN USER IS LOGGED IN*/}</div>
    )
  ) : (
    <div {...customProps}>
      {type === 'green' ? (
        <>
          <GreenPageTextField {...emailFieldProps} />
          <GreenPageTextField {...passwordFieldProps} />
        </>
      ) : (
        <>
          <TextField
            {...emailFieldProps}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            {...passwordFieldProps}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </>
      )}
      <FormControlLabel
        style={type === 'green' ? { color: 'white' } : {}}
        control={
          <Checkbox
            value={rememberMe}
            onChange={handleChange(true)}
            style={type === 'green' ? { color: 'white' } : {}}
          />
        }
        label={t('user:form.rememberMe')}
      />
      {Boolean(errors.global) && (
        <Alert severity="error" style={{ margin: '8px auto' }}>
          {errors.global}
        </Alert>
      )}
      <CustomButton
        color={submitColor || 'minoritary'}
        disabled={loading}
        fullWidth
        onClick={handleSubmit}
        type="submit"
      >
        {loading ? <CircularProgress size={25} /> : t('user:form.signInButton')}
      </CustomButton>
    </div>
  );
  return [SignInComponent, state];
}
