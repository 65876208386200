import React from 'react';
import {
  Typography,
  Grid,
  Chip,
  Box,
  Card,
  FormHelperText,
} from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useTranslation } from '@kiway/shared/utils/translation';
import Loader from './Loader';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  OrderStatName,
  OrderStatPeriod,
  OrderStatType,
} from '@kiway/ecommerce-react-compatible';

interface AnalyticEcommerceProps {
  title: string;
  count: string;
  percentage: number;
  extra: number;
  loading: boolean;
  period: OrderStatPeriod;
  type: OrderStatType;
  statType: OrderStatName;
  subtitle?: string;
}

export const AnalyticEcommerce = (props: AnalyticEcommerceProps) => {
  const { t } = useTranslation();

  const returnPercentIcon = () => {
    if (props.percentage > 0) {
      return <ShowChartIcon />;
    } else if (props.percentage < 0) {
      return <ShowChartIcon style={{ transform: 'scale(-1, 1)' }} />;
    } else {
      return null;
    }
  };

  const returnTranslationPeriodString = (type) => {
    let result;
    switch (type) {
      case 'today':
        result = 'pharmaco:dashboard.analytics.extra.period.today';
        break;
      case 'yesterday':
        result = 'pharmaco:dashboard.analytics.extra.period.yesterday';
        break;
      case 'week':
        result = 'pharmaco:dashboard.analytics.extra.period.week';
        break;
      case 'prevWeek':
        result = 'pharmaco:dashboard.analytics.extra.period.prevWeek';
        break;
      case 'month':
        result = 'pharmaco:dashboard.analytics.extra.period.month';
        break;
      case 'prevMonth':
        result = 'pharmaco:dashboard.analytics.extra.period.prevMonth';
        break;
      case 'quarter':
        result = 'pharmaco:dashboard.analytics.extra.period.quarter';
        break;
      case 'prevQuarter':
        result = 'pharmaco:dashboard.analytics.extra.period.prevQuarter';
        break;
      case 'year':
        result = 'pharmaco:dashboard.analytics.extra.period.year';
        break;
      case 'prevYear':
        result = 'pharmaco:dashboard.analytics.extra.period.prevYear';
        break;
      default:
        break;
    }
    return t(result);
  };

  const returnTranslationProfitString = () => {
    let result;
    if (props.percentage >= 0) {
      result = 'pharmaco:dashboard.analytics.extra.profit.more';
    } else {
      result = 'pharmaco:dashboard.analytics.extra.profit.less';
    }
    return t(result);
  };

  const returnTranslationTypeString = (type) => {
    let result;
    switch (type) {
      case 'nbOrders':
        result = 'pharmaco:dashboard.analytics.extra.type.orders';
        break;
      case 'nbDisctinctsClients':
        result = 'pharmaco:dashboard.analytics.extra.type.clients';
        break;
      default:
        break;
    }
    return t(result);
  };

  return (
    <Card
      sx={{
        p: 2.25,
        borderRadius: 2,
        border: '1px solid lightgrey',
        height: '100%',
      }}
      elevation={0}
    >
      {props.loading ? (
        <Loader />
      ) : (
        <>
          <Typography variant="body1" color="textSecondary">
            {t(`pharmaco:dashboard.analytics.${props.title}`)}
          </Typography>

          <FormHelperText>
            {props.subtitle
              ? t(`pharmaco:dashboard.analytics.${props.subtitle}`)
              : ' '}
          </FormHelperText>

          <Grid container sx={{ pt: 0.5 }} alignItems="center">
            <Grid item>
              <Typography variant="h4">
                {props.type === 'currency'
                  ? `${parseInt(props.count) / 100} €`
                  : props.count}
              </Typography>
            </Grid>
            {props.percentage !== 0 && (
              <Grid item>
                <Chip
                  variant="filled"
                  color={props.percentage > 0 ? 'success' : 'warning'}
                  icon={returnPercentIcon()}
                  label={`${props.percentage?.toFixed(2)}%`}
                  sx={{ ml: 1.25, pl: 1, borderRadius: 1 }}
                  size="small"
                />
              </Grid>
            )}
          </Grid>
          <Box sx={{ pt: 1.5 }}>
            {props.extra !== 0 && (
              <Typography variant="caption" color="textSecondary">
                <Typography
                  component="span"
                  variant="caption"
                  color={props.percentage >= 0 ? 'success.main' : '#ed6c02'}
                >
                  {props.type === 'currency'
                    ? `${(props.extra / 100).toFixed(2)} €`
                    : `${props.extra} ${returnTranslationTypeString(
                        props.statType
                      )}`}
                </Typography>
                {`
                ${returnTranslationProfitString()}
                ${returnTranslationPeriodString(props.period)}
                `}
              </Typography>
            )}
          </Box>
        </>
      )}
    </Card>
  );
};
