import { CustomerFileGraphQLProvider } from '../../dataproviders/graphql-client/CustomerFileGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllCustomerFiles } from '../../core/use_cases/GetAllCustomerFiles';
import { CustomerFile } from '../../core/entities/CustomerFile';
import { CustomerFileProvider } from '../../core/use_cases/CustomerFileProvider';

class CustomerFilesGateway {
  protected static instance: CustomerFilesGateway;
  protected getAllCustomerFiles: GetAllCustomerFiles;
  protected customerFileProvider: CustomerFileProvider;

  public static getInstance(client: ApolloClient<any>): CustomerFilesGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new CustomerFilesGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    this.customerFileProvider = new CustomerFileGraphQLProvider(client);
    this.getAllCustomerFiles = new GetAllCustomerFiles(
      this.customerFileProvider
    );
    this.findAll = this.findAll.bind(this);
    this.findByPractitioner = this.findByPractitioner.bind(this);
    CustomerFilesGateway.instance = this;
  }

  async findAll(): Promise<CustomerFile[]> {
    return await this.getAllCustomerFiles.execute();
  }

  async findByPractitioner(practitionerId?: string): Promise<any[]> {
    return this.customerFileProvider.findByPractitioner(practitionerId);
  }
}

export function useCustomerFilesGateway() {
  const client = useApolloClient();
  const customerFilesGateway = CustomerFilesGateway.getInstance(client);
  return {
    findAll: customerFilesGateway.findAll,
    findByPractitioner: customerFilesGateway.findByPractitioner,
  };
}
