import { IWholesalerAttributes, Wholesaler } from './Wholesaler';

export interface IRetailerAttributes {
  name: string;
  delegatePreparation?: boolean;
  shopUrl?: string;
  wholesalers: IWholesalerAttributes[];
  marginPercent: number;
  systemMarginPercent: number;
}

export class Retailer {
  protected id: string;
  protected name: string;
  protected delegatePreparation: boolean;
  protected shopUrl: string;
  protected marginPercent: number;
  protected systemMarginPercent: number;
  protected wholesalers: Wholesaler[];

  public constructor(
    obj: Partial<IRetailerAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj.id || obj._id;
    this.name = obj.name;
    this.delegatePreparation = obj.delegatePreparation || false;
    this.shopUrl = obj.shopUrl;
    this.marginPercent = obj.marginPercent || 0;
    this.systemMarginPercent = obj.systemMarginPercent || 0;
    this.wholesalers = obj?.wholesalers ? [] : undefined;
    for (const w of obj?.wholesalers || []) {
      if (w instanceof Wholesaler) {
        this.addWholesaler(w);
      } else if (typeof w === 'string') {
        this.addWholesaler(new Wholesaler({ _id: w }));
      } else {
        this.addWholesaler(new Wholesaler(w));
      }
    }
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): Retailer {
    this.id = id;
    return this;
  }

  public getName(): string {
    return this.name;
  }

  public isDelegatePreparation(): boolean {
    return this.delegatePreparation;
  }

  public setDelegatePreparation(delegatePreparation: boolean): Retailer {
    this.delegatePreparation = delegatePreparation;
    return this;
  }

  public getShopUrl(): string {
    return this.shopUrl;
  }

  public setShopUrl(shopUrl: string): Retailer {
    this.shopUrl = shopUrl;
    return this;
  }

  public getMarginPercent(): number {
    return this.marginPercent;
  }

  public setMarginPercent(marginPercent: number): Retailer {
    this.marginPercent = marginPercent;
    return this;
  }

  public getSystemMarginPercent(): number {
    return this.systemMarginPercent;
  }

  public setSystemMarginPercent(systemMarginPercent: number): Retailer {
    this.systemMarginPercent = systemMarginPercent;
    return this;
  }

  public getWholesalers(): Wholesaler[] {
    return this.wholesalers;
  }

  public setWholesalers(wholesalers: Wholesaler[]): Retailer {
    this.wholesalers = wholesalers;
    return this;
  }

  public addWholesaler(Wholesaler: Wholesaler): Retailer {
    this.wholesalers.push(Wholesaler);
    return this;
  }

  public removeWholesaler(wholesalerId: string): Retailer {
    this.wholesalers = this.wholesalers.filter(
      (wholesaler) => wholesaler.getId() !== wholesalerId
    );
    return this;
  }
}
