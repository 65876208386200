import * as React from 'react';
import { MenuBuilder } from '@kiway/menu-builder';
import { useTranslation } from '@kiway/shared/utils/translation';
import { RoutingBuilder } from '@kiway/routing-builder';
import { KiwayLanguagesType } from '@kiway/shared/react-types';
import ReactGA from 'react-ga';
import config from '../config';

function registerAuthentication() {
  // INITIALIZE GOOGLE ANALYTICS
  const trackingId = process.env['NX_GA_TRACKING_CODE'];
  ReactGA.initialize(trackingId);

  // REGISTER AUTHENTICATION MAIN MENU
  const builder = MenuBuilder.getBuilder('main');
  Object.values(config.menus?.['main'] || {}).map((menu) => {
    builder.addItem({
      ...menu,
      icon: <menu.icon fontSize="medium" />,
    });
  });

  // REGISTER AUTHENTICATION USER MENU
  const builderUser = MenuBuilder.getBuilder('user');
  Object.values(config.menus?.['user'] || {}).map((menu) => {
    const anyMenu = menu as any;
    builderUser.addItem({
      ...menu,
      icon: anyMenu.icon ? <anyMenu.icon fontSize="medium" /> : undefined,
    });
  });

  // REGISTER AUTHENTICATION TRANSLATIONS
  const { addTranslationDynamically } = useTranslation();
  Object.entries(config.translations).map(([lng, translation]) => {
    addTranslationDynamically(
      lng as KiwayLanguagesType,
      'authentication',
      translation,
      true
    );
  });

  // REGISTER ROUTING
  const routing = RoutingBuilder.getBuilder();
  Object.values(config.routes).map((route) => {
    routing.addRoute(route);
  });
}

export function FeatureAuthentication() {
  registerAuthentication();
  return <></>;
}

export * from './components';
export * from './contexts';
