import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '2vh',
  },
  textField: {
    textAlign: 'center',
  },
  title: {
    fontSize: 35,
    fontWeight: 'bold',
  },
  tabTitles: {
    // padding: '6px !important' as any,
    fontWeight: 'bold !important' as any,
  },
  important: {
    color: 'green',
    textDecoration: 'underline',
    textAlign: 'right',
    cursor: 'pointer',
  },
  image: {
    maxWidth: '300px',
    minWidth: '100px',
    height: 'auto',
    margin: '0 10px',
  },
  imageContainer: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flex: 1,
  },
  titrePartie: {
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '2vh',
  },
  dataSideLineWrapper: {
    display: 'flex',
  },
  dataSideLine: {
    fontSize: 15,
    fontWeight: 'bold',
    margin: 'auto 0',
  },
  card: {
    height: '100%',
  },
  metadataChip: {
    margin: theme.spacing(0.5),
  },
  deleteIcon: {
    cursor: 'pointer',
    color: 'black',
    '&:hover': {
      color: '#ED5252',
    },
  },
  noArrowInputNumber: {
    '& .MuiInputBase-input': {
      padding: '3px 5px',
      textAlign: 'right',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
    },
  },
  browserItemCard: {
    // boxShadow: '0px 10px 10px 5px rgba(0,0,0,0.2)',
    // borderRadius: '10px',
    // minHeight: '18vh',
    height: '100%',
    // display: 'flex',
  },
  browserItemCardContent: {
    '&.MuiCardContent-root': { padding: theme.spacing(1), width: '100%' },
    '&.MuiCardContent-root:last-child': {
      paddingBottom: 0,
    },
  },
  browserItemDiv: {
    padding: '1vh 1vw',
    textAlign: 'center',
    overflow: 'auto',
    maxHeight: '58vh',

    '&::-webkit-scrollbar': {
      width: 10,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '15px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: process.env.NX_COLOR_SECONDARY_MAIN,
      borderRadius: 15,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: process.env.NX_COLOR_PRIMARY_MAIN,
    },
  },

  browserItemTypeButtons: {
    '&.MuiCardActions-root': {
      justifyContent: 'center',
    },
  },
  centerElementsInBrowser: {
    width: '100%',
    textAlign: 'center',
  },
  validationModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    padding: 10,
    boxShadow: '24',
  },
  addressesModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    padding: 10,
    boxShadow: '24',
    minHeight: 500,
    width: 500,
  },
  addressCard: {
    margin: '1vh 0',
    cursor: 'pointer',
  },
  addNewAdressLabel: {
    textAlign: 'right',
    cursor: 'pointer',

    '&:hover': {
      color: '#01610C',
    },
  },
}));
