/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useStyles } from '../checkout.style';
import { PaymentMethod } from '@kiway/ecommerce-react-compatible';

interface PaymentMethodsAccordionProps {
  disabled?: boolean;
  handleChange: (value: string) => void;
  targetExpand: string | null;
  name: PaymentMethod;
  summaryTitle: string;
  summaryDescription: string;
}

export function PaymentMethodsAccordion({
  disabled,
  handleChange,
  targetExpand,
  name,
  children,
  summaryTitle,
  summaryDescription,
}: React.PropsWithChildren<PaymentMethodsAccordionProps>) {
  const isExpanded = targetExpand === name;
  const [expandedAccordion, setExpandedAccordion] = React.useState<boolean>(
    false
  );

  const toggleExpanded = () => {
    setExpandedAccordion(!expandedAccordion);
  };
  return (
    <Accordion
      onClick={toggleExpanded}
      onChange={() => handleChange(name)}
      disabled={disabled}
      expanded={Boolean(children) && isExpanded}
    >
      <AccordionSummary
        expandIcon={
          !isExpanded ? (
            <RadioButtonUncheckedIcon />
          ) : (
            <CheckCircleRoundedIcon
              color="primary"
              style={{
                transform:
                  Boolean(children) && isExpanded
                    ? 'rotate(180deg)'
                    : undefined,
              }}
            />
          )
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              <b>{summaryTitle}</b>
            </Typography>
          </Grid>
          {!(Boolean(children) && isExpanded) && (
            <Grid item xs={12}>
              <Typography>{summaryDescription}</Typography>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
