export default {
  form: {
    backToHome: "Retour à l'accueil",
    createPatient: 'Je suis un patient',
    createPractitioner: 'Je suis un praticien',
    emailField: 'Email',
    forgotPassword: 'Mot de passe oublié',
    newPasswordField: 'Nouveau mot de passe',
    noAccount: 'Pas de compte ? Inscrivez-vous',
    oldPasswordField: 'Ancien mot de passe',
    passwordConfirmField: 'Confirmation',
    passwordField: 'Mot de passe',
    passwordsNotGoodEnough:
      'Le mot de passe doit faire au moins 8 caractères, contenir 1 majuscule, 1 minuscule, 1 chiffre, 1 caractère spécial et ne contenir aucun espace',
    passwordsNotMatch: 'Les mots de passes ne sont pas identiques',
    referrerCodeField: 'Code parrainage',
    rememberMe: 'Se souvenir de moi',
    resetCredentialsNotOK: 'Requête expirée ou déjà complétée',
    resetPasswordButton: 'Changer le mot de passe',
    resetPasswordEmailSent:
      "Si votre adresse email est valide et présente dans la base de données, un email vous sera envoyé prochainement. Cliquez sur le lien à l'intérieur du mail pour réinitialiser votre mot de passe",
    resetPasswordFailTitle: 'Un problème est survenu, mot de passe inchangé',
    resetPasswordRequestButton: 'Envoyer le mail',
    resetPasswordRequestTitle: 'Réinitialisation du mot de passe',
    resetPasswordSuccessTitle: 'Mot de passe changé avec succès',
    signInButton: 'Connexion',
    signInTitle: 'Connexion',
    signUpButton: "S'inscrire",
    yesAccount: "J'ai un compte",
  },
  menu: {
    logout: 'Déconnexion',
    profile: 'Mon profil',
    tutorial: 'Tutoriel',
  },
  onBoarding: {
    explain: {
      address:
        "Merci de saisir l'adresse de votre lieu de consultation, elle sera utilisée dans les mails de confirmation destinés au patient.",
      password:
        "Si vous ne l'avez pas encore fait, merci de bien vouloir changer votre mot de passe pour des raisons de sécurité.",
      practices:
        'Vos pratiques nous sont utiles à des fins statistiques. Pour le moment elles ne sont pas liées aux rendez-vous.',
    },
    save: {
      error:
        "Un problème est survenu durant l'enregitrement, merci d'essayer à nouveau",
      errorGDPR:
        "Vous devez cocher la case RGPD pour qu'on puisse enregistrer vos informations",
      success: 'Modifications enregistrées',
    },
    steps: {
      address: {
        city: 'Ville',
        country: 'Pays',
        countrySearch: 'Rechercher un pays',
        search: 'Rechercher une adresse',
        street: 'Rue',
        title: 'Lieu de consultation',
        zipCode: 'Code postal',
      },
      changePassword: {
        title: 'Changez votre mot de passe',
      },
      personalData: {
        title: 'Informations personnelles',
      },
      practices: {
        title: 'Pratiques',
      },
    },
    subTitleInfo:
      "Ces informations sont très importantes pour nous et pour le bon fonctionnement de l'application. Merci de prendre 5 minutes pour les renseigner.",
    title: 'Dites-nous en plus sur vous !',
  },
  profile: {
    updateError: "Une erreur est survenue, merci d'essayer à nouveau",
    updateSuccess: 'Modifications enregistrées',
  },
  register: {
    button: 'Créer mon espace',
    buttonResend: 'Renvoyer le code',
    certifyLegalRepresentative:
      'Je certifie être accompagné par mon représentant légal *',
    disablePro:
      "L'inscription n'est possible qu'en tant que patient ou depuis https://kiway.co pour les praticiens voulant faire partie du programme de co-création",
    error: {
      birthDate: {
        ofAgePatient:
          'Vous devez être majeur ou accompagné par votre représentant légal',
        ofAgePract: "Vous devez être majeur pour utiliser l'application",
      },
      email: 'Merci de renseigner une adresse email valide',
      emailExists: 'Un compte existe déjà pour cet email',
      emailVerif:
        "Un problème est survenu durant l'envoi du code pour vérifier votre email. Merci d'essayer à nouveau.",
      global: "Une erreur est survenue, merci d'essayer à nouveau.",
      password: "Le mot de passe n'est pas valide",
      requiredFieldsEmpty:
        'Merci de bien vouloir remplir les champs requis signalés par une astérisque (*)',
      verifCode: 'Code incorrect',
    },
    stepper: {
      credentials: 'Identifiants',
      emailVerification: 'Vérification email',
      personalData: 'Données personnelles',
    },
    success:
      "Votre compte a bien été créé. Vous allez recevoir un mail sur l'adresse que vous avez renseignée afin de vérifier qu'elle vous appartient. Il vous suffira de cliquer sur le lien afin de valider votre compte et vous pourrez par la suite vous connecter.",
    title: 'Création de votre espace {{app}}',
    validation: {
      error:
        "Une erreur est survenue durant la vérification, merci d'essayer à nouveau. Il se peut que vous ayez déjà vérifié votre email.",
      missingParams:
        "Votre email n'a pu être vérifié, il manque des paramètres à votre requête. Merci de vérifier que vous avez bien recopié le lien reçu dans le mail.",
      ok:
        'Votre email a bien été vérifié. Vous pouvez désormais vous connecter en cliquant sur le bouton ci-dessous.',
      title: 'Vérification de votre email',
    },
    verifCodeExplaination: 'Veuillez entrer le code reçu par email',
  },
};
