/** List of events details for google analytics */

/**
 * CATEGORIES
 */
export const SIGNUP_CAT = 'Signup';
export const AUTH_CAT = 'Authentication';
export const FILE_CAT = 'File';
export const SESSION_CAT = 'Session';
export const SUBSCRIPTION_CAT = 'Subscription';
export const SERVICES_ON_DEMAND_CAT = 'ServicesOnDemand';
export const FAQ_CAT = 'Faq';

/**
 * ACTIONS
 */
export const LOGIN_ACTION = 'login';
export const RESET_PASSWORD_ACTION = 'reset-password';
export const CREATE_ACCOUNT_ACTION = 'create-account';
export const CHOOSE_ACCOUNT_ACTION = 'choose-account';
export const VALIDATE_ACCOUNT_ACTION = 'validate-account';
export const OPEN_ACTION = 'open';
export const EDIT_ACTION = 'edit';
export const CANCEL_ACTION = 'cancel';
export const SAVE_ACTION = 'save';
export const SUBSCRIBE_ACTION = 'subscribe';
export const SELECT_SERVICES_ACTION = 'select-services';
export const PAY_SERVICES_CARD_ACTION = 'pay-services-card';
export const PAY_SERVICES_NEXT_ACTION = 'pay-services-next';
export const CONFIRM_SERVICES_CREATE_ACTION = 'confirm-services-create';
export const CONFIRM_SERVICES_UPDATE_ACTION = 'confirm-services-update';
export const CANCEL_SERVICES_UPDATE_ACTION = 'cancel-services-update';
export const OPEN_FAQ_ACTION = 'open-faq';

/**
 * LABELS
 */
export const PATIENT_LABEL = 'patient';
export const PRACTITIONER_LABEL = 'practitioner';
export const SUB_STUDENT_LABEL = 'student';
export const SUB_PRO_LABEL = 'pro';
export const SERVICES_FIRST_TIME_LABEL = 'first-time';
