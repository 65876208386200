import { FormulaMetadataSearchReturn } from '../entities/Formula';
import { FormulaProvider } from './FormulaProvider';

export class SearchFormulaMetadata {
  private formulaProvider: FormulaProvider;

  public constructor(formulaProvider: FormulaProvider) {
    this.formulaProvider = formulaProvider;
  }

  public execute(search: string): Promise<FormulaMetadataSearchReturn> {
    return this.formulaProvider.searchFormulaMetadata(search);
  }
}
