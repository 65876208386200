export default {
  bill: 'Issue Invoice',
  billingInProgress: 'Invoicing in progress...',
  dataTable: {
    columns: {
      actions: 'Actions',
      amount_due: 'Amount Due',
      createdAt: 'Issuing Date',
      description: 'Description',
      number: 'Number',
      paid: 'Paid',
      patient: 'Patient',
      viewPDF: 'PDF Invoice',
    },
    title: 'List Of Invoices',
  },
  doYouHaveTaxes: 'Do you apply any tax?',
  invoicingAlert:
    'Once invoice is issued, you cannot go back. Do you want to proceed?',
  noReceiptData: 'Sorry, there is no receipt available for this payment',
  paidAt: 'Paid at',
  patientEmailRequired: 'Patient email is mandatory to send a payment link',
  pay: 'Pay',
  payInvoice: 'Pay invoice',
  payment: {
    checkout: {
      cancel: {
        text:
          'Oops, you left a bit to soon... We may ask you to try again in order to proceed to the payment.',
        title: 'Your invoice payment has been canceled',
      },
      redirectPage: {
        badStatus: 'It seems that this invoice has already been paid.',
        noConnectedAccount:
          'We are experiencing an issue with payment settings, please contact your practitioner directly.',
        redirecting: 'You are being redirected...',
        title: 'Invoice Payment',
        unknownError:
          'Something went wrong, please contact your practitioner directly.',
      },
      success: {
        text: 'We thank you for your payment, you can now go back to the app.',
        title: 'Your invoice has been successfully paid',
      },
    },
    connectWithStripeText:
      'In order to complete "Online Payment" service activation, you need to create or connect a Stripe account. This account will allow you to receive and withdraw your funds. Please click on the button below and you will be redirected to the Stripe website.',
  },
  paymentDate: 'Date of payment',
  paymentMethod: {
    card: 'Credit card',
    cash: 'Cash',
    check: 'Bank check',
    kiway: 'Online payment',
    label: 'Payment method',
    title: 'Other payment methods',
  },
  paymentRef: 'Payment reference',
  paymentSaved: 'Payment added successfully',
  payOnline: 'Pay online',
  preview: 'Preview:',
  receiptModalTitle: 'Payment receipt',
  receiptToString:
    'Invoice paid on %paymentDate% with "%paymentMethod%", payment reference : %paymentRef%',
  savePayment: 'Save payment',
  scanMeToPay: 'Scan me to pay with your smartphone',
  seeInvoice: 'View invoice',
  seeReceipt: 'View Receipt',
  sendPaymentLink:
    'Would you like to send your patient an online payment link by email?',
  sessionInvoicing: 'Session Invoicing',
  sessionInvoicingConfirm: 'Confirm to issue invoice',
  sessionToBill: 'Session to charge:',
  subTotal: 'Sub-total:',
  successInvoiceFinalized: 'Invoice has been issued',
  taxes: {
    country: 'Tax Country',
    modes: {
      excl: 'Tax is excluded from the amount above',
      incl: 'Tax is included to the amount above',
      no: 'No',
    },
    name: 'Tax Name',
    percent: 'Tax rate (percent)',
    percentLimits: 'Between 0 and 100',
    render: 'Applied Tax',
  },
  toBill: 'To charge',
  total: 'Total:',
  youHaveToActivateStripe:
    'You must activate your Stripe account to be able to use online payments',
};
