import { Document, Model, Schema } from 'mongoose';
import { enumUserRoles, IUserAttributes, User } from '../../core/entities/User';
import {
  LoginResult,
  RefreshToken,
  Setup2FA,
  UserProvider,
  UserRegister,
  UserRegisterType,
} from '../../core/use_cases/UserProvider';
import UserProps from './UserProps';
import {
  EntityProvider,
  DatabaseProviderFactory,
} from '@kiway/shared/react-types';

/* Declare methods */
export interface IUser extends IUserAttributes, Document {}

/* Declare statics */
export interface IUserModel
  extends Model<IUser>,
    EntityProvider<IUser, IUserAttributes> {}

export const AddressSchema = new Schema({
  city: { type: String },
  default: { type: Boolean },
  line0: { type: String },
  line1: { type: String },
  line2: { type: String },
  line3: { type: String },
  firstName: { type: String },
  lastName: { type: String },
  countryCode: { type: String },
  zipCode: { type: String },
  email: { type: String },
  mobilePhone: { type: String },
  nif: { type: String },
  country: { type: Schema.Types.Mixed },
});

export class UserDatabaseProvider
  extends DatabaseProviderFactory<User, IUser, IUserModel, IUserAttributes>()
  implements UserProvider {
  public static getInstance(): UserDatabaseProvider {
    if (!UserDatabaseProvider.instance) {
      new UserDatabaseProvider();
    }
    return UserDatabaseProvider.instance;
  }

  public getModel(): IUserModel {
    return this.model;
  }

  private constructor() {
    const UserSchema: Schema<IUser> = new Schema(
      {
        /* User schema definition here : see mongoose lib */
        email: { type: String },
        mainRole: {
          type: String,
          enum: {
            values: enumUserRoles,
            message: 'error.validator.role',
          },
        },
        firstName: { type: String },
        lastName: { type: String },
        gender: {
          type: String,
          enum: {
            values: ['F', 'M', 'O', null],
            message: 'error.validator.gender',
          },
        },
        verified: { type: Boolean },
        salt: { type: String },
        hash: { type: String },
        birthDate: { type: String },
        imageUrl: { type: String },
        profession: {
          type: String,
        },
        timezone: {
          type: String,
        },
        language: {
          type: String,
        },
        mobilePhone: {
          type: String,
        },
        secondPhone: {
          type: String,
        },
        addresses: [AddressSchema],
        referrerCode: { type: String },
        custom: { type: Schema.Types.Mixed },
      },
      { timestamps: true }
    );

    UserSchema.methods.customUpdateOne = function (updates) {
      for (const key in updates) {
        if (updates[key] instanceof Object && !Array.isArray(updates[key])) {
          this[key] = {
            ...this[key],
            ...updates[key],
          };
        } else {
          this[key] = updates[key] !== undefined ? updates[key] : this[key];
        }
      }
    };

    // free: 1, send 1 SMS            => free - SMS = 0
    // free: 30, send 1 SMS           => free - SMS = 29
    // free: 1, paid: 50, send 3 SMS  => free - SMS = -2
    // (free + paid - nbSMS) >= 0 => OK
    UserSchema.methods.canSendSMS = async function () {
      if (['admin'].includes(this.mainRole)) {
        return true;
      }
      const stripeSubscription = await UserProps.find({
        user: this._id,
        type: 'stripeSubscription',
      });
      if (
        !stripeSubscription ||
        !stripeSubscription.length ||
        (stripeSubscription.length &&
          stripeSubscription[0].data &&
          stripeSubscription[0].data.status !== 'active')
      ) {
        return false;
      }

      return (
        stripeSubscription[0].data?.items?.data?.find(
          (service) => service?.price?.metadata?.shortname === 'sms'
        ) !== undefined
      );
    };

    UserSchema.methods.consumeSmsCredits = async function (nbOfCredits) {
      // Get subscription document
      // const stripeSubscription = await UserProps.find({
      //   user: this._id,
      //   type: 'stripeSubscription',
      // });
      // let smsSubItem = null;
      // if (stripeSubscription.length) {
      //   smsSubItem = stripeSubscription[0].data?.items?.data?.find(
      //     (service) => service?.price?.metadata?.shortname === 'sms'
      //   );
      // }
      // if (!smsSubItem) {
      //   throw new ForbiddenError('sms', 'send');
      // }
      // this.customUpdateOne({
      //   currentSmsCredits: this.currentSmsCredits + nbOfCredits * 10,
      // });
      // await this.save();
      // return await StripeController.consumeSmsCredits(
      //   smsSubItem.id,
      //   parseInt(nbOfCredits * 10)
      // );
    };

    UserSchema.methods.setupUserProps = function (userProps) {
      for (const { data, type } of userProps) {
        if ((this as any)._doc) {
          (this as any)._doc.custom[type] = data;
        } else {
          this.custom[type] = data;
        }
      }
    };

    UserSchema.post('find', async function (results) {
      await Promise.all(
        results?.map(async (userResult) => {
          if (userResult.setupUserProps) {
            const userProps = await UserProps.find({ user: userResult._id });
            if (userProps.length) {
              userResult.setupUserProps(userProps);
            }
          }
        })
      );
    });

    UserSchema.post('findOne', async function (result) {
      if (result && result.setupUserProps) {
        const userProps = await UserProps.find({ user: result._id });
        result.setupUserProps(userProps);
      }
    });

    UserSchema.methods.removeSensitiveData = function () {
      const {
        _ac,
        _ct,
        hash,
        salt,
        role,
        mainRole,
        roles,
        loginHistory,
        onBoarding,
        currentSmsCredits,
        ...restUser
      } = (this as any)._doc;
      return restUser;
    };

    UserSchema.methods.getAdminData = function () {
      const {
        _ac,
        _ct,
        firstName,
        lastName,
        birthDate,
        mobilePhone,
        secondPhone,
        profession,
        address,
        gender,
        hash,
        salt,
        roles,
        loginHistory,
        onBoarding,
        currentSmsCredits,
        ...restUser
      } = (this as any)._doc;
      return {
        ...restUser,
        subStatus: restUser?.custom?.stripeSubscription?.status,
      };
    };

    UserSchema.statics.findOneOrCreate = function (condition, callback) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      return self.findOne(condition, (err, result) => {
        return result
          ? callback(err, result)
          : self.create(condition, (err, result) => {
              return callback(err, result);
            });
      });
    };

    UserSchema.statics.getUnreadNotifications = function () {
      const pipeline = [
        {
          $lookup: {
            from: 'notifications',
            localField: '_id',
            foreignField: 'user',
            as: 'userNotifs',
          },
        },
        {
          $unwind: '$userNotifs',
        },
        {
          $match: {
            'userNotifs.isRead': false,
          },
        },
        {
          $group: {
            _id: '$email',
            unread: {
              $push: '$userNotifs.isRead',
            },
          },
        },
        {
          $project: {
            _id: '$_id',
            nbNotifications: {
              $size: '$unread',
            },
          },
        },
      ];
      return this.aggregate(pipeline);
    };

    super(UserSchema, 'NewUser', process.env.DB_USER_TABLE_NAME ?? 'users');
  }
  registerUser(user: UserRegister, type: UserRegisterType): Promise<string> {
    throw new Error('Method not implemented.');
  }
  deleteRefreshToken(tokenId: string): Promise<Array<RefreshToken>> {
    throw new Error('Method not implemented.');
  }
  deleteAllRefreshTokens(): Promise<Array<RefreshToken>> {
    throw new Error('Method not implemented.');
  }
  listRefreshTokens(): Promise<RefreshToken[]> {
    throw new Error('Method not implemented.');
  }
  sendForgotPassword(email?: string): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  changeEmail(email: string): Promise<User> {
    throw new Error('Method not implemented.');
  }
  enable2FA(code: string, secret: string): Promise<User> {
    throw new Error('Method not implemented.');
  }
  disable2FA(code: string, methodId: string): Promise<User> {
    throw new Error('Method not implemented.');
  }
  get2FAUrl(email?: string): Promise<Setup2FA> {
    throw new Error('Method not implemented.');
  }

  async findOneBy(find: Partial<IUserAttributes>): Promise<User> {
    return super.findOne({
      find,
    });
  }

  async findOneById(userId: string): Promise<User> {
    return super.findOne({
      find: { _id: userId },
    });
  }

  async findOneByEmail(email: string): Promise<User> {
    return super.findOne({
      find: { email },
    });
  }

  logout(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  async save(user: User, userId?: string): Promise<User> {
    return super.save(user, userId);
  }

  async findAll(): Promise<User[]> {
    return super.findAll();
  }

  async editMany(users: IUserAttributes[], userId?: string): Promise<User[]> {
    return super.editMany(users, userId);
  }

  async login(email: string, password: string): Promise<LoginResult> {
    const user: User = await super.findOne({ find: { email } });
    if (!user || !user.validatePassword(password)) {
      throw new Error('Invalid credentials');
    }
    return { user };
  }

  private toEntity(userMongo: IUser): User {
    return new User(userMongo);
  }

  private toEntityMongo(
    user: User,
    createdBy?: string,
    updatedBy?: string
  ): IUser {
    const entityMongo = new this.model({
      _id: user.getId(),
      email: user.getEmail(),
      imageUrl: user.getImageUrl(),
      mainRole: user.getMainRole(),
      firstName: user.getFirstName(),
      lastName: user.getLastName(),
      gender: user.getGender(),
      verified: user.isVerified(),
      birthDate: user.getBirthDate(),
      profession: user.getProfession(),
      timezone: user.getTimezone(),
      language: user.getLanguage(),
      mobilePhone: user.getMobilePhone(),
      secondPhone: user.getSecondPhone(),
      addresses: user.getAddresses(),
      referrerCode: user.getReferrerCode(),
      custom: user.getCustom(),
      createdBy: createdBy ? createdBy : undefined,
      updatedBy: updatedBy ? updatedBy : undefined,
    });
    if (!user.getAddresses()) {
      entityMongo.addresses = undefined;
    }
    return entityMongo;
  }
}
