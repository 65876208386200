import * as React from 'react';
import { ButtonGroup, Button } from '@mui/material';

export interface SwitchPSPCProps {
  disabled?: boolean;
  values: Array<any>;
  selectedValue: string;
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
}

export function SwitchPSPC({
  disabled,
  values,
  selectedValue,
  setSelectedValue,
}: SwitchPSPCProps) {
  return (
    <ButtonGroup color="primary" size="small" disabled={disabled}>
      {values?.map((type) => {
        return (
          <Button
            size="small"
            color="primary"
            key={type.value}
            onClick={() => setSelectedValue(type.value)}
            variant={type.value === selectedValue ? 'contained' : 'outlined'}
          >
            {type.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}
