export default {
  addEvent: {
    modal: {
      confirmEmail: 'Envoyer un email de confirmation',
      notifyWarning:
        "Pensez à prévenir votre patient du changement d'horaire ou d'adresse",
      smsReminder: 'Programmer un SMS de rappel 24h avant',
      title: 'Ajouter un rendez-vous',
    },
  },
  caption: 'Légende :',
  deleteAppointmentModal: {
    content:
      "Êtes-vous sûr de vouloir supprimer ce rendez-vous ? L'opération est irréversible.",
    title: 'Supprimer un rendez-vous',
  },
  eventType: {
    other: 'Autre',
    session: 'RDV avec patient',
  },
  nextWeek: 'Suivante',
  prevWeek: 'Précédente',
  settings: {
    daysDisplayed: {
      labels: {
        friday: 'Vendredi',
        monday: 'Lundi',
        saturday: 'Samedi',
        sunday: 'Dimanche',
        thursday: 'Jeudi',
        tuesday: 'Mardi',
        wednesday: 'Mercredi',
      },
    },
    modal: {
      apply: 'Appliquer',
      button: 'Préférences',
      close: 'Fermer',
      dayEnds: 'La journée finit à',
      dayStarts: 'La journée commence à',
      displayWeekends: 'Afficher les Weekends',
      hideWeekends: 'Cacher les Weekends',
      slotDuration: "Durée d'un intervalle",
      title: "Préférences de l'agenda",
    },
    rowsPerHour: {
      title: 'Nombre de lignes par heure',
    },
    timeSlots: {
      title: 'Limites de la plage horaire affichée',
    },
  },
  smsIssue: 'SMS non reçu',
  smsNone: 'Pas de SMS de rappel',
  smsScheduledAt: 'Un SMS sera envoyé le',
  smsSentOn: 'SMS envoyé le',
  snackbars: {
    appointments: {
      error:
        "Un problème est survenu durant l'enregistrement du rendez-vous. Merci d'essayer à nouveau.",
      errorDelete:
        "Un problème est survenu durant la suppression du rendez-vous. Merci d'essayer à nouveau.",
      missingFields: 'Merci de remplir tous les champs requis',
      overlap: 'Vous ne pouvez pas superposer plusieurs RDV',
      pendingClick:
        "Un patient est en train de prendre rendez-vous en ligne, vous ne pouvez l'éditer pour le moment.",
      successCreate: 'Rendez-vous créé avec succès',
      successDelete: 'Rendez-vous supprimé avec succès',
      successUpdate: 'Rendez-vous modifié avec succès',
    },
    load: {
      error:
        "Un problème est survenu durant le chargement. Merci de rafraîchir l'application pour essayer à nouveau.",
    },
    settings: {
      error:
        "Un problème est survenu durant l'enregistrement des paramètres. Merci d'essayer à nouveau.",
      successUpdate: 'Paramètres enregistrés avec succès',
    },
  },
  updateEvent: {
    modal: {
      title: 'Modifier un rendez-vous',
    },
  },
  views: {
    '3days': '3 jours',
    day: 'Jour',
    list: 'Liste',
    week: 'Semaine',
  },
  weekShort: 'Sem.',
};
