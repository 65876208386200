import { Order } from '../entities/Order';
import { OrderProvider } from './OrderProvider';

export class GetAllOrders {
  private orderProvider: OrderProvider;

  public constructor(orderProvider: OrderProvider) {
    this.orderProvider = orderProvider;
  }

  public execute(find?: any): Promise<Order[]> {
    return this.orderProvider.findAll({ ...find, deleted: { $ne: true } });
  }
}
