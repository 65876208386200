import * as React from 'react';
import { Typography } from '@material-ui/core';
import { Wholesaler } from '../../../core/entities/Wholesaler';
import { Skeleton } from '@material-ui/lab';

export interface ListWholesalersProps {
  wholesalers: Wholesaler[];
  loading?: boolean;
}

const renderWholesaler = (item: Wholesaler) => (
  <li key={item.getId()}>{item.getId()}</li>
);

const loadingWholesalerScreen = (
  <div>
    <ul>
      <li>
        <Skeleton variant="text" />
      </li>
      <li>
        <Skeleton variant="text" />
      </li>
      <li>
        <Skeleton variant="text" />
      </li>
    </ul>
  </div>
);

export function ListWholesalers({
  loading,
  wholesalers,
}: React.PropsWithChildren<ListWholesalersProps>) {
  return (
    <div>
      <Typography variant="h5">Wholesaler :</Typography>
      {loading ? (
        loadingWholesalerScreen
      ) : (
        <div>
          <ul>{wholesalers?.length ? wholesalers?.map(renderWholesaler) : 'Aucune donnée'}</ul>
        </div>
      )}
    </div>
  );
}
