import { ProductVariationTypeProvider } from './ProductVariationTypeProvider';
import {
  IProductVariationTypeAttributes,
  ProductVariationType,
} from '../entities/ProductVariationType';

export class EditManyProductVariationTypes {
  private productVariationTypeProvider: ProductVariationTypeProvider;

  public constructor(
    productVariationTypeProvider: ProductVariationTypeProvider
  ) {
    this.productVariationTypeProvider = productVariationTypeProvider;
  }

  public execute(
    productVariationTypes: Array<IProductVariationTypeAttributes>,
    userId?: string
  ): Promise<Array<ProductVariationType>> {
    return this.productVariationTypeProvider.editMany(
      productVariationTypes,
      userId
    );
  }
}
