import { useMediaQuery, withWidth } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from '@kiway/shared/utils/translation';
import {
  getGalleryPictures,
  getMinisite,
  getProfilePicture,
} from '../../services/publicPage/publicPageService';
import { getBlobUrl, toDataUrl } from '../../services/utils/api';
import MediaWithoutActionsCard from '../../shared/Cards/MediaWithoutActionsCard';
import GalleryCarousel from './GalleryCarousel';
import './PublicPage.css';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Booking from './Booking';
import PhoneIcon from '@material-ui/icons/Phone';
// import { BookingContext } from '../../AppRoutes'
import { BookingContext } from '../../../BookingContext';
import { displayPhoneNumber } from '../../services/utils/string';
import { Alert } from '@material-ui/lab';
import { doubleLineBreak } from '../../shared/Forms/MarkdownTextArea';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(2),
    backgroundColor: process.env.NX_COLOR_SECONDARY_MAIN,
    minHeight: '200px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    justifyContent: 'center',
  },
  info: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  bookingSticky: {
    padding: theme.spacing(2),
    // position: '-webkit-sticky',
    position: 'sticky',
    top: '12px',
    color: theme.palette.text.secondary,
  },
  bookingColumn: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  progress: {
    width: '150px',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    width: '150px',
    height: '150px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 || 0;
    // eslint-disable-next-line eqeqeq
    const v = c == 'x' ? r : (r && 0x3) || 0x8;
    return v.toString(16);
  });
}

function PublicPage(props) {
  const { t } = useTranslation();

  const [practitionerId, setPractitionerId] = useState(null);

  const [minisiteDisplay, setMinisiteDisplay] = useState({});
  const [consultationPlaces, setConsultationPlaces] = useState([]);
  const [files, setFiles] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isProfilePictureLoading, setIsProfilePictureLoading] = useState(true);
  const [isGalleryLoading, setIsGalleryLoading] = useState(true);
  const [showRedirect, setShowRedirect] = useState(false);

  const { dispatch } = useContext(BookingContext);

  const initFiles = (practitionerId) => {
    getProfilePicture(practitionerId)
      .then((profileData) => {
        if (profileData && profileData?.name) {
          setProfile({
            preview:
              `data:${profileData.contentType};` +
              `base64,${profileData.buffer}`,
            type: profileData.contentType,
            path: profileData.name,
            name: profileData.name,
          });
          dispatch({
            type: 'SET_PROFILE_PIC',
            payload: {
              pic:
                `data:${profileData.contentType};` +
                `base64,${profileData.buffer}`,
            },
          });
        }
      })
      .finally(() => setIsProfilePictureLoading(false));

    getGalleryPictures(practitionerId)
      .then((galleryData) => {
        if (galleryData) {
          const formattedFiles = [];
          // eslint-disable-next-line no-restricted-syntax
          for (const i in galleryData) {
            if (Object.prototype.hasOwnProperty.call(galleryData, i)) {
              const currentFile = galleryData[i];
              formattedFiles.push({
                preview:
                  `data:${currentFile.contentType};` +
                  `base64,${currentFile.buffer}`,
                type: currentFile.contentType,
                path: currentFile.name,
                name: currentFile.name,
                _id: uuidv4(),
              });
            }
          }
          setFiles(formattedFiles);
        }
      })
      .finally(() => setIsGalleryLoading(false));
  };

  const { slug } = props;

  useEffect(() => {
    getMinisite(slug).then((result) => {
      if (!result) {
        setShowRedirect(true);
      } else {
        // setSocketAttributes({
        //   publicPagePractitionerId: result.practitionerId,
        // })
        dispatch({
          type: 'SET_PRACTITIONER',
          payload: {
            id: result.practitionerId,
            fullname: result.fullName,
            mobilePhone: result.mobilePhone,
          },
        });
        dispatch({
          type: 'SET_SANITARY_INFO',
          payload: {
            display: result.displaySanitaryInfo,
            content: result.sanitaryInfo,
          },
        });
        Promise.all(
          result.consultationPlaces.map(async (item) => {
            const image = await toDataUrl(
              getBlobUrl(item.address.coordinates),
              undefined
            );
            return {
              image,
              ...item,
            };
          })
        ).then((places) => {
          setPractitionerId(result.practitionerId);
          setConsultationPlaces(places);
          setMinisiteDisplay(result);
          setIsProfilePictureLoading(true);
          setIsGalleryLoading(true);
          initFiles(result.practitionerId);
        });
      }
    });
  }, []);

  const classes = useStyles();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <div className={classes.root}>
      {showRedirect && <Redirect to="/404" />}
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.header}>
            <div style={{ height: '150px' }}>
              {isProfilePictureLoading ? (
                <CircularProgress />
              ) : (
                <Avatar
                  className={classes.avatar}
                  imgProps={{
                    style: {
                      maxWidth: '150px',
                      maxHeight: '150px',
                      height: 'auto',
                      width: 'auto',
                    },
                  }}
                  alt={minisiteDisplay.fullName}
                  src={profile && profile.preview}
                />
              )}
            </div>
            <div style={{ color: 'white' }}>
              <Typography
                variant="h5"
                style={{
                  color: 'white',
                  marginTop: '12px',
                }}
              >
                <b>{minisiteDisplay.fullName}</b>
              </Typography>
              <div>
                {minisiteDisplay &&
                  minisiteDisplay.specialties &&
                  minisiteDisplay.specialties.map((item) => (
                    <Chip
                      variant="outlined"
                      key={item.name}
                      label={item.name}
                      style={{
                        marginRight: '8px',
                        color: 'white',
                        borderColor: 'white',
                        marginTop: '8px',
                      }}
                    />
                  ))}
              </div>
              <div
                style={{
                  marginTop: '16px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {/* eslint-disable-next-line max-len */}
                {minisiteDisplay.displayContactInfo &&
                  minisiteDisplay.mobilePhone && (
                    <>
                      <PhoneIcon size={'small'} />{' '}
                      {displayPhoneNumber(minisiteDisplay.mobilePhone)}
                    </>
                  )}
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid
          container
          spacing={3}
          style={
            matches
              ? {
                  maxWidth: '1300px',
                  marginTop: '12px',
                }
              : {
                  maxWidth: '1300px',
                  margin: '12px auto',
                }
          }
          direction={'row-reverse'}
        >
          <Grid item xs={12} sm={12} md={5}>
            <ClickAwayListener onClickAway={() => setBackdropOpen(false)}>
              <Paper
                className={classes.bookingSticky}
                elevation={backdropOpen ? 24 : 1}
                onClick={() => {
                  setBackdropOpen(true);
                  return false;
                }}
                style={{ zIndex: 11 }}
              >
                {practitionerId && <Booking practitionerId={practitionerId} />}
              </Paper>
            </ClickAwayListener>
            <Backdrop
              transitionDuration={500}
              style={{ zIndex: 10 }}
              open={backdropOpen}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <Paper className={classes.info}>
              {minisiteDisplay.displaySanitaryInfo &&
                minisiteDisplay.sanitaryInfo &&
                minisiteDisplay.sanitaryInfo.length && (
                  <Alert style={{ marginTop: '16px' }} severity="warning">
                    {minisiteDisplay.sanitaryInfo}
                  </Alert>
                )}
              {minisiteDisplay &&
                minisiteDisplay.description &&
                minisiteDisplay.description !== '' && (
                  <div style={{ marginTop: '16px' }}>
                    <Typography variant="h6">
                      {t('publicPage:description.title')}
                    </Typography>
                    <Divider
                      style={{
                        backgroundColor: process.env.NX_COLOR_PRIMARY_MAIN,
                      }}
                    />
                    <ReactMarkdown
                      className={'markdownPreview'}
                      allowedElements={['p', 'ul', 'li', 'strong']}
                      children={doubleLineBreak(minisiteDisplay.description)}
                    />
                  </div>
                )}
              {minisiteDisplay &&
                minisiteDisplay.formations &&
                minisiteDisplay.formations !== '' && (
                  <div style={{ marginTop: '16px' }}>
                    <Typography variant="h6">
                      {t('publicPage:formations.title')}
                    </Typography>
                    <Divider
                      style={{
                        backgroundColor: process.env.NX_COLOR_PRIMARY_MAIN,
                      }}
                    />
                    <ReactMarkdown
                      className={'markdownPreview'}
                      allowedElements={['p', 'ul', 'li', 'strong']}
                      children={doubleLineBreak(minisiteDisplay.formations)}
                    />
                  </div>
                )}
              {consultationPlaces && consultationPlaces.length > 0 && (
                <div style={{ marginTop: '16px' }}>
                  <Typography variant="h6">
                    {t('publicPage:places.title')}
                  </Typography>
                  <Divider
                    style={{
                      backgroundColor: process.env.NX_COLOR_PRIMARY_MAIN,
                    }}
                  />
                  <Grid container spacing={2} style={{ marginTop: '8px' }}>
                    {consultationPlaces &&
                      consultationPlaces.length > 0 &&
                      consultationPlaces.map((item) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={4}
                          style={
                            {
                              // minWidth: '310px',
                            }
                          }
                          key={item.name}
                        >
                          <MediaWithoutActionsCard
                            style={{
                              marginTop: '16px',
                            }}
                            imageAlt="maps-consultation-place-preview"
                            imageSrc={item.image}
                            title={item.name}
                            description={item.address.description}
                            complement={item.complement}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </div>
              )}
              {(isGalleryLoading || (files && files.length > 0)) && (
                <div style={{ marginTop: '16px' }}>
                  <Typography variant="h6">
                    {t('publicPage:gallery.title')}
                  </Typography>
                  <Divider
                    style={{
                      backgroundColor: process.env.NX_COLOR_PRIMARY_MAIN,
                    }}
                  />
                  {isGalleryLoading ? (
                    <div
                      style={{
                        width: '100%',
                        height: '150px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    files && <GalleryCarousel galleryItems={files} />
                  )}
                </div>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>

    // <main style={{ width: '100%', backgroundColor: '#FAFAFA' }}>
    //   {showRedirect && <Redirect to="/404" />}
    //   {/**************************
    //    *** PRACTITIONER HEADER ***
    //    ***************************/}
    //   <div
    //     style={{
    //       backgroundColor: process.env.NX_COLOR_SECONDARY_MAIN,
    //       minHeight: '200px',
    //       display: 'flex',
    //       flexDirection: 'row',
    //       alignItems: 'center',
    //       padding: ['md', 'lg', 'xl'].includes(width) ? '16px 150px' : '16px',
    //     }}
    //   >
    //     {isProfilePictureLoading ? (
    //       <div
    //         style={{
    //           width: '150px',
    //           height: '150px',
    //           display: 'flex',
    //           alignItems: 'center',
    //           justifyContent: 'center',
    //         }}
    //       >
    //         <CircularProgress />
    //       </div>
    //     ) : (
    //       <Avatar
    //         style={{ width: '150px', height: '150px' }}
    //         imgProps={{
    //           style: {
    //             maxWidth: '150px',
    //             maxHeight: '150px',
    //             height: 'auto',
    //             width: 'auto',
    //           },
    //         }}
    //         alt={minisiteDisplay.fullName}
    //         src={profile && profile.preview}
    //       />
    //     )}
    //     <div style={{ marginLeft: '15px', color: 'white' }}>
    //       <Typography variant="h5">
    //         <b>{minisiteDisplay.fullName}</b>
    //       </Typography>
    //       <div>
    //         {minisiteDisplay &&
    //           minisiteDisplay.specialties &&
    //           minisiteDisplay.specialties.map((item) => (
    //             <Chip
    //               variant="outlined"
    //               key={item.name}
    //               label={item.name}
    //               style={{
    //                 marginRight: '8px',
    //                 color: 'white',
    //                 borderColor: 'white',
    //                 marginTop: '8px',
    //               }}
    //             />
    //           ))}
    //       </div>
    //       <div style={{ marginTop: '16px' }}>
    //         {minisiteDisplay.displayContactInfo &&
    //           minisiteDisplay.mobilePhone &&
    //           `${t('publicPage:contactInfo.mobilePhone')} : ${
    //             minisiteDisplay.mobilePhone[0] === '+'
    //               ? minisiteDisplay.mobilePhone
    //               : `+${minisiteDisplay.mobilePhone}`
    //           }`}
    //       </div>
    //     </div>
    //   </div>
    //   {/************************
    //    *** PRACTITIONER INFO ***
    //    *************************/}
    //   <div
    //     style={{
    //       padding: ['lg', 'xl'].includes(width)
    //         ? '16px 250px'
    //         : width === 'md'
    //         ? '16px 150px'
    //         : '16px',
    //     }}
    //   >
    //     {minisiteDisplay &&
    //       minisiteDisplay.description &&
    //       minisiteDisplay.description !== '' && (
    //         <div style={{ marginTop: '16px' }}>
    //           <Typography variant="h6">
    //             {t('publicPage:description.title')}
    //           </Typography>
    //           <Divider style={{ backgroundColor: process.env.NX_COLOR_PRIMARY_MAIN }} />
    //           <ReactMarkdown className={'markdownPreview'} children={minisiteDisplay.description} />
    //         </div>
    //       )}
    //     {minisiteDisplay &&
    //       minisiteDisplay.formations &&
    //       minisiteDisplay.formations !== '' && (
    //         <div style={{ marginTop: '16px' }}>
    //           <Typography variant="h6">
    //             {t('publicPage:formations.title')}
    //           </Typography>
    //           <Divider style={{ backgroundColor: process.env.NX_COLOR_PRIMARY_MAIN }} />
    //           <ReactMarkdown className={'markdownPreview'} children={minisiteDisplay.formations} />
    //         </div>
    //       )}
    //     {consultationPlaces && consultationPlaces.length > 0 && (
    //       <div style={{ marginTop: '16px' }}>
    //         <Typography variant="h6">{t('publicPage:places.title')}</Typography>
    //         <Divider style={{ backgroundColor: process.env.NX_COLOR_PRIMARY_MAIN }} />
    //         <Grid container spacing={2} style={{ marginTop: '8px' }}>
    //           {consultationPlaces &&
    //             consultationPlaces.length > 0 &&
    //             consultationPlaces.map((item) => (
    //               <Grid
    //                 item
    //                 xs={12}
    //                 sm={6}
    //                 md={4}
    //                 lg={3}
    //                 style={{ minWidth: '310px' }}
    //                 key={item.name}
    //               >
    //                 <MediaWithoutActionsCard
    //                   style={{ marginTop: '16px' }}
    //                   imageAlt="maps-consultation-place-preview"
    //                   imageSrc={item.image}
    //                   title={item.name}
    //                   description={item.address.description}
    //                   complement={item.complement}
    //                 />
    //               </Grid>
    //             ))}
    //         </Grid>
    //       </div>
    //     )}
    //     {(isGalleryLoading || (files && files.length > 0)) && (
    //       <div style={{ marginTop: '16px' }}>
    //         <Typography variant="h6">
    //           {t('publicPage:gallery.title')}
    //         </Typography>
    //         <Divider style={{ backgroundColor: process.env.NX_COLOR_PRIMARY_MAIN }} />
    //         {isGalleryLoading ? (
    //           <div
    //             style={{
    //               width: '100%',
    //               height: '150px',
    //               display: 'flex',
    //               alignItems: 'center',
    //               justifyContent: 'center',
    //             }}
    //           >
    //             <CircularProgress />
    //           </div>
    //         ) : (
    //           files && <GalleryCarousel galleryItems={files} />
    //         )}
    //       </div>
    //     )}
    //   </div>
    // </main>
  );
}

PublicPage.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default withWidth()(PublicPage);
