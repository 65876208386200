import * as React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Checkbox,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useTranslation } from '@kiway/shared/utils/translation';
import { PlantVariation } from '../../../core/entities/PlantVariation';
import { ProductDetailsCard } from '@kiway/ecommerce-react';
import { Skeleton } from '@material-ui/lab';
import { KiwayLanguagesType } from '@kiway/shared/react-types';
import { InputAdornment } from '@mui/material';

interface RowLoaderProps {
  count?: number;
}
const defaultRowLoader: RowLoaderProps = { count: 1 };
function RowLoader({ count } = defaultRowLoader) {
  const lines = new Array(count).fill('');
  return (
    <>
      {lines?.map((_, index) => (
        <TableRow key={index}>
          <TableCell component="th" scope="row">
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}
export interface PlantDetailsVariationsProps {
  handleChange: (
    id: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => any;
  handleDelete: (id: string) => any;
  handleSave: (id?: string) => any;
  loading?: boolean;
  variations: PlantVariation[];
}

interface PlantDetailsVariationRowProps {
  handleChange: PlantDetailsVariationsProps['handleChange'];
  handleDelete: PlantDetailsVariationsProps['handleDelete'];
  handleSave: PlantDetailsVariationsProps['handleSave'];
  plantVariation?: PlantVariation;
}

function PlantDetailsVariationRow({
  handleChange,
  handleDelete,
  handleSave,
  plantVariation,
}: PlantDetailsVariationRowProps) {
  const { addTranslationDynamically, t } = useTranslation();
  const displayName = plantVariation.getProductVariationType().getName();

  if (displayName && typeof displayName !== 'string') {
    Object.entries(displayName).map(([lng, translation]) => {
      addTranslationDynamically(
        lng as KiwayLanguagesType,
        'pharmaco',
        {
          productVariationTypeDisplayNames: {
            [`${plantVariation.getId()}`]: translation,
          },
        },
        true
      );
    });
  }
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {t(
          `pharmaco:productVariationTypeDisplayNames.${plantVariation.getId()}`
        )}
      </TableCell>
      <TableCell component="th" scope="row">
        {plantVariation
          .getBasePrice()
          ?.getCurrency()
          ?.toString(plantVariation.getBasePrice())}
      </TableCell>
      <TableCell>
        <TextField
          type="number"
          name="price"
          value={plantVariation.getPrice()?.getCentAmount() / 100 || ''}
          onChange={(event) => {
            handleChange(plantVariation.getId(), event);
          }}
          onBlur={(event) => {
            handleSave(plantVariation.getId());
          }}
          InputProps={{
            [`${
              plantVariation.getPrice()?.getCurrency()?.getPosition() ?? 'start'
            }Adornment`]: (
              <InputAdornment
                position={plantVariation
                  .getPrice()
                  ?.getCurrency()
                  ?.getPosition()}
              >
                {plantVariation.getPrice()?.getCurrency()?.getSymbol()}
              </InputAdornment>
            ),
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          type="text"
          name="productRef"
          value={plantVariation.getProductRef() || ''}
          onChange={(event) => {
            handleChange(plantVariation.getId(), event);
          }}
          onBlur={(event) => {
            handleSave(plantVariation.getId());
          }}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          name="available"
          checked={plantVariation.isAvailable() || false}
          onChange={(event) => {
            handleChange(plantVariation.getId(), event);
            handleSave(plantVariation.getId());
          }}
        />
      </TableCell>
      <TableCell>
        <IconButton
          onClick={() =>
            handleDelete(plantVariation.getProductVariationType().getId())
          }
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export function PlantDetailsVariations({
  handleChange,
  handleDelete,
  handleSave,
  loading,
  variations,
}: React.PropsWithChildren<PlantDetailsVariationsProps>) {
  const { t } = useTranslation();
  return (
    <ProductDetailsCard title={t('pharmaco:variations.title')}>
      {variations ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('pharmaco:variations.name')}</TableCell>
              <TableCell>{t('pharmaco:variations.basePrice')}</TableCell>
              <TableCell>{t('pharmaco:variations.price')}</TableCell>
              <TableCell>{t('pharmaco:variations.productRef')}</TableCell>
              <TableCell>{t('pharmaco:variations.available')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <RowLoader count={2} />
            ) : variations?.length ? (
              variations.map((row) => (
                <PlantDetailsVariationRow
                  handleChange={handleChange}
                  handleDelete={handleDelete}
                  handleSave={handleSave}
                  key={row.getId()}
                  plantVariation={row}
                />
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={6}
                  align="center"
                  style={{ fontStyle: 'italic' }}
                >
                  {t('pharmaco:noData')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      ) : null}
    </ProductDetailsCard>
  );
}
