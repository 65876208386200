import * as React from 'react';
import {
  Collapse,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  MenuItem as MUIMenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Box } from '@mui/material';

export function menuBuilder(): string {
  return 'menu-builder';
}

export type SubMenuItemAttributes = {
  id: string;
  text: string;
  link: string;
  external?: boolean;
  openNewTab?: boolean;
  listItemProps?: ListItemProps | any;
  display: boolean;
  role: string;
  order?: number;
  render?(isDrawerOpen?: boolean): React.ReactNode;
};

export type MenuItemAttributes = SubMenuItemAttributes & {
  icon?: JSX.Element;
  subItems?: Array<SubMenuItemAttributes>;
};

const useStyles = makeStyles((theme) => {
  const baseSubItemMarginLeft = theme.spacing(1);
  return {
    rootSubItem: {
      marginLeft: baseSubItemMarginLeft,
      '& .active': {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: 'rgba(0,0,0,0) !important',
        marginLeft: baseSubItemMarginLeft * 2,
        borderLeft: `2px solid ${theme.palette.primary.main}`,
      },
    },
    rootSubItemTooltip: {
      zIndex: 100000,
      marginLeft: '45px',
      marginTop: '-50px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '-63px',
      },
    },
    rootSubItemTooltipDrawerOpen: {
      zIndex: 100000,
      marginLeft: '10px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '-4px',
        marginLeft: '-2px',
      },
    },
    rootSubItemTooltipNoSubItemsDrawerOpen: {
      zIndex: 100000,
      marginLeft: '10px',
      marginTop: '-25px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '-4px',
        marginLeft: '-2px',
      },
    },
    rootSubItemTooltipNoSubItems: {
      zIndex: 100000,
      marginLeft: '45px',
      marginTop: '-45px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '-60px',
      },
    },
    rootSubItemTooltipList: {
      '& > *:hover': {
        backgroundColor: `${theme.palette.secondary.main} !important`,
      },
    },
    rootSubItemListIcon: {
      color: 'white',
    },
  };
});

export class SubMenuItem extends React.Component {
  id: string;
  text: string;
  link: string;
  external: boolean;
  openNewTab: boolean;
  listItemProps?: ListItemProps | any;
  display: boolean;
  role: string;
  order?: number;

  constructor(options: SubMenuItemAttributes) {
    super(options);
    this.id = options.id;
    this.text = options.text;
    this.link = options.link;
    this.external = options.external;
    this.openNewTab = options.openNewTab;
    this.display = options.display;
    this.role = options.role;
    this.order = options.order;
    this.listItemProps = {
      button: true,
      component: this.external ? Link : NavLink,
      ...(this.external
        ? {
            href: `${this.link}`,
            target: this.openNewTab ? '_blank' : undefined,
            style: { color: 'inherit' },
          }
        : {
            to: `/${this.link}`,
            exact: this.link === '',
            activeClassName: 'active',
          }),
      ...options.listItemProps,
    } as ListItemProps | any;
    if (options.render) {
      this.render = options.render;
    }
  }

  renderListItem(
    isDrawerOpen?: boolean,
    t?: (str: string) => string
  ): React.ReactNode {
    return (
      <React.Fragment key={this.id}>
        <ListItem {...this.listItemProps}>
          <ListItemText primary={t ? t(this.text) : this.text} />
        </ListItem>
      </React.Fragment>
    );
  }

  renderMenuItem(
    isDrawerOpen?: boolean,
    t?: (str: string) => string
  ): React.ReactNode {
    return null;
  }
}

function isParentRouteActive(
  pathname: string,
  subItems: SubMenuItem[]
): boolean {
  const findIt = subItems?.find((item: SubMenuItem) =>
    pathname.includes(`/${item.link}`)
  );
  return findIt !== undefined;
}

export class MenuItem extends SubMenuItem {
  icon?: JSX.Element;
  subItems?: Array<SubMenuItem>;

  constructor(options: MenuItemAttributes) {
    super(options);
    this.icon = options.icon;
    if (options.subItems) {
      this.subItems = options.subItems.map((item) => new SubMenuItem(item));
    }
    if (options.render) {
      this.render = options.render;
    }
  }

  addSubItem(subItem: MenuItemAttributes): MenuItem {
    this.subItems = [...this.subItems, new SubMenuItem(subItem)];
    return this;
  }

  getSubItems(): Array<SubMenuItem> {
    return this.subItems?.filter(({ display }) => display);
  }

  renderListItem(
    isDrawerOpen?: boolean,
    t?: (str: string) => string
  ): React.ReactNode {
    const [open, setOpen] = React.useState<boolean>(false);
    const handleClickOpen = (event: any) => {
      setOpen((prev: boolean) => !prev);
      event.stopPropagation();
      event.preventDefault();
    };
    const classes = useStyles();
    const location = useLocation();
    React.useEffect(() => {
      setOpen(
        location.pathname === `/${this.link}` ||
          isParentRouteActive(location.pathname, this.subItems)
      );
      return () => {
        setOpen(false);
      };
    }, [location]);
    return (
      <React.Fragment key={this.id}>
        <ListItem
          className={
            isParentRouteActive(location.pathname, this.subItems)
              ? 'active'
              : ''
          }
          {...this.listItemProps}
        >
          <Tooltip
            interactive={!isDrawerOpen && this.subItems?.length > 0}
            placement={isDrawerOpen ? 'right-end' : 'bottom-start'}
            PopperProps={{
              className: !this.subItems?.length
                ? isDrawerOpen
                  ? classes.rootSubItemTooltipNoSubItemsDrawerOpen
                  : classes.rootSubItemTooltipNoSubItems
                : isDrawerOpen
                ? classes.rootSubItemTooltipDrawerOpen
                : classes.rootSubItemTooltip,
            }}
            title={
              <div>
                {!this.getSubItems()?.length || isDrawerOpen ? (
                  <Typography>{t ? t(this.text) : this.text}</Typography>
                ) : (
                  <List
                    component="div"
                    disablePadding
                    className={classes.rootSubItemTooltipList}
                  >
                    {this.getSubItems()
                      ?.sort(sortMenuItemsByOrder)
                      .map((subItem) =>
                        subItem.renderListItem(isDrawerOpen, t)
                      )}
                  </List>
                )}
              </div>
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                flex: 1,
              }}
              data-tour={isDrawerOpen ? `menu-main-${this.id}` : undefined}
            >
              <ListItemIcon
                className={
                  location.pathname === `/${this.link}` ||
                  isParentRouteActive(location.pathname, this.subItems)
                    ? classes.rootSubItemListIcon
                    : undefined
                }
              >
                <Box
                  data-tour={isDrawerOpen ? undefined : `menu-main-${this.id}`}
                >
                  {this.icon}
                </Box>
              </ListItemIcon>
              <ListItemText primary={t ? t(this.text) : this.text} />
              {this.getSubItems()?.length ? (
                <React.Fragment>
                  {open ? (
                    <ExpandLess onClick={handleClickOpen} />
                  ) : (
                    <ExpandMore onClick={handleClickOpen} />
                  )}
                </React.Fragment>
              ) : null}
            </div>
          </Tooltip>
        </ListItem>
        {this.getSubItems()?.length ? (
          <Collapse in={open && isDrawerOpen} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              className={classes.rootSubItem}
            >
              {this.getSubItems()
                ?.sort(sortMenuItemsByOrder)
                .map((subItem) => subItem.renderListItem(isDrawerOpen, t))}
            </List>
          </Collapse>
        ) : null}
      </React.Fragment>
    );
  }

  renderMenuItem(
    isDrawerOpen?: boolean,
    t?: (str: string) => string,
    handleClose?: any
  ): React.ReactNode {
    return (
      <MUIMenuItem
        key={this.id}
        onClick={handleClose ? () => handleClose() : undefined}
        {...this.listItemProps}
        data-tour={isDrawerOpen ? `menu-user-${this.id}` : undefined}
      >
        {t ? t(this.text) : this.text}
      </MUIMenuItem>
    );
  }
}

const sortMenuItemsByOrder = (
  a: MenuItem | SubMenuItem,
  b: MenuItem | SubMenuItem
): number => {
  if (!a.order && !b.order) {
    return 0;
  } else if (!a.order) {
    return 1;
  } else if (!b.order) {
    return -1;
  } else if (a.order < b.order) {
    return -1;
  } else if (a.order > b.order) {
    return 1;
  }
  return 0;
};

const filterUniqueById = (item, index, self) =>
  self.map(({ id }) => id).indexOf(item.id) === index;

type MenuType = 'drawer' | 'dropdown';
export class MenuBuilder {
  private static menus: Array<{ menuBuilder: MenuBuilder; menuName: string }>;

  private items: Array<MenuItem>;
  private name: string;
  private type: MenuType = 'drawer';
  private customItemsFilter: (item: Partial<MenuItem | SubMenuItem>) => boolean;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor(name?: string) {
    this.name = name;
  }

  public static getBuilder(name: string): MenuBuilder {
    const menuInstance = MenuBuilder.menus?.find(
      ({ menuName }) => menuName === name
    )?.menuBuilder;
    if (!menuInstance) {
      const newMenuInstance = new MenuBuilder(name);
      if (MenuBuilder.menus && Array.isArray(MenuBuilder.menus)) {
        MenuBuilder.menus.push({
          menuBuilder: newMenuInstance,
          menuName: name,
        });
      } else {
        MenuBuilder.menus = [{ menuBuilder: newMenuInstance, menuName: name }];
      }
      return newMenuInstance;
    }

    return menuInstance;
  }

  getItemsLength(): number {
    return this.items?.length;
  }

  setCustomItemsFilter(
    customItemsFilter: (item: Partial<MenuItem | SubMenuItem>) => boolean
  ): void {
    this.customItemsFilter = customItemsFilter;
  }

  private getItemsFilter(
    customItemsFilter?
  ): (item: MenuItem | SubMenuItem) => boolean {
    if (customItemsFilter) {
      return customItemsFilter;
    }
    return ({ display }) => display;
  }

  private prepareItems(): Array<MenuItem> {
    return this.items
      ?.filter(this.getItemsFilter(this.customItemsFilter))
      ?.map(
        (menuItem: MenuItem) =>
          new MenuItem({
            ...menuItem,
            subItems: menuItem.subItems?.filter(
              this.getItemsFilter(this.customItemsFilter)
            ),
          })
      )
      ?.sort(sortMenuItemsByOrder);
  }

  setItems(items: Array<MenuItemAttributes>) {
    this.items = [
      ...(items?.map((item) => {
        let mergedItem = item;
        const oldItem = this.items?.find(({ id }) => id === item.id);
        if (oldItem) {
          mergedItem = {
            ...oldItem,
            ...item,
            subItems: [
              ...(oldItem.subItems || []),
              ...(item.subItems || []),
            ].filter(filterUniqueById),
          };
        }
        return new MenuItem(mergedItem);
      }) || new Array<MenuItem>()),
      ...(this.items || new Array<MenuItem>()),
    ].filter(filterUniqueById);
  }

  addItem(item: MenuItemAttributes, atTheBeginning?: boolean): void {
    if (atTheBeginning) {
      this.items = [new MenuItem(item), ...(this.items || [])];
    } else {
      this.items = [...(this.items || []), new MenuItem(item)];
    }
  }

  addSubItem(
    parentItem: MenuItemAttributes,
    subItem: MenuItemAttributes
  ): void {
    const exists = this.items?.find(({ id }) => id === parentItem.id);
    if (!exists) {
      this.addItem({ ...parentItem, subItems: [subItem] });
      return;
    }
    this.items = this.items?.map((item: MenuItem) => {
      if (item.id === parentItem.id) {
        return item.addSubItem(subItem);
      }
      return item;
    });
  }

  removeItem(itemId: MenuItem['id']): void {
    let i = 0;
    while (i < this.items.length) {
      if (this.items[i]?.id === itemId) {
        this.items.splice(i, 1);
      } else {
        ++i;
      }
    }
  }

  setType(type: MenuType): void {
    this.type = type;
  }

  render(
    isDrawerOpen?: boolean,
    handleClose?: any
  ): JSX.Element | React.ReactNode[] {
    const { t } = useTranslation();
    const preparedItems = this.prepareItems();
    return this.type === 'drawer' ? (
      <List>
        {preparedItems?.map((item) => item.renderListItem(isDrawerOpen, t))}
      </List>
    ) : (
      preparedItems?.map((item) =>
        item.renderMenuItem(isDrawerOpen, t, handleClose)
      )
    );
  }

  static renderStatic(
    name: string,
    isDrawerOpen?: boolean
  ): JSX.Element | React.ReactNode[] {
    const menu = MenuBuilder.getBuilder(name);
    if (menu) {
      return menu.render(isDrawerOpen);
    }
    return null;
  }
}
