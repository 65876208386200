import { ProductMetadataGraphQLProvider } from '../../dataproviders/graphql-client/ProductMetadataGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllProductMetadatas } from '../../core/use_cases/GetAllProductMetadatas';
import { EditManyProductMetadatas } from '../../core/use_cases/EditManyProductMetadatas';
import {
  ProductMetadata,
  IProductMetadataAttributes,
} from '../../core/entities/ProductMetadata';

class ProductMetadatasGateway {
  protected static instance: ProductMetadatasGateway;
  protected getAllProductMetadatas: GetAllProductMetadatas;
  protected editManyProductMetadatas: EditManyProductMetadatas;

  public static getInstance(
    client: ApolloClient<any>
  ): ProductMetadatasGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new ProductMetadatasGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const productMetadataProvider = new ProductMetadataGraphQLProvider(client);
    this.getAllProductMetadatas = new GetAllProductMetadatas(
      productMetadataProvider
    );
    this.editManyProductMetadatas = new EditManyProductMetadatas(
      productMetadataProvider
    );
    this.findAll = this.findAll.bind(this);
    this.editMany = this.editMany.bind(this);
    ProductMetadatasGateway.instance = this;
  }

  async findAll(): Promise<ProductMetadata[]> {
    return await this.getAllProductMetadatas.execute();
  }

  async editMany(
    productMetadatas: IProductMetadataAttributes[]
  ): Promise<ProductMetadata[]> {
    return await this.editManyProductMetadatas.execute(productMetadatas);
  }
}

export function useProductMetadatasGateway() {
  const client = useApolloClient();
  const productMetadatasGateway = ProductMetadatasGateway.getInstance(client);
  return {
    findAll: productMetadatasGateway.findAll,
    editMany: productMetadatasGateway?.editMany,
  };
}
