import './country-select.module.css';
import * as React from 'react';
import { Select } from '../..';
import { allCountries } from '@kiway/shared/utils/enums';
import 'flag-icons/css/flag-icons.min.css';

const countries = [
  ...new Map(allCountries.map((item) => [item['name'], item])).values(),
];

export interface CountryOption {
  name: string;
  code: string;
}

export interface CountrySelectProps {
  countries?: CountryOption[];
  disabled?: boolean;
  label?: string;
  margin?: 'dense' | 'none';
  onChange?: (newValue: CountryOption) => void;
  required?: boolean;
  size?: 'small' | 'medium';
  value?: string;
}

export function CountrySelect(props: CountrySelectProps) {
  const [value, setValue] = React.useState<string | undefined>(props.value);

  React.useEffect(() => {
    setValue(props.value?.toUpperCase());
  }, [props.value]);

  const handleChange = (event: any) => {
    const code = (event.target.value as string)?.toUpperCase();
    const newValue = {
      code,
      name: countries?.find(({ code: iso2 }) => iso2 === code?.toLowerCase())
        ?.name,
    };
    setValue(code);
    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  return (
    <Select
      disabled={props.disabled}
      handleClear={() => handleChange({ target: { value: '' } })}
      label={props.label ?? 'Country'}
      margin={props.margin}
      onChange={handleChange}
      options={(props.countries ?? countries).map(({ name, code }) => ({
        label: (
          <div>
            <span
              className={`fi fi-${code.toLowerCase()}`}
              style={{ marginRight: 5 }}
            ></span>
            {name} ({code.toUpperCase()})
          </div>
        ),
        value: code?.toUpperCase(),
      }))}
      required={props.required}
      size={props.size}
      value={value?.toUpperCase() ?? ''}
    />
  );
}

export default CountrySelect;
