import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  list: {
    width: 250,
    maxHeight: '65vh',
    overflowY: 'auto',
  },
  fullList: {
    width: 'auto',
  },

  //BROWSER COLLAPSED
  positioning: {
    overflow: 'hidden',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  openButtonWrap: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent !important',
  },
  openButton: {
    padding: 10,
    paddingTop: 3,
    paddingBottom: 0,
    borderRadius: '10px 10px 0px 0px',
    borderBottom: 'none',
    marginBottom: -2,
    zIndex: 100,
    backgroundColor: 'white',
    boxShadow: '0px -5px 20px -8px rgba(0,0,0,0.2)',
    cursor: 'pointer',
  },
  openButtonPop: {
    zIndex: 10,
    borderRadius: '5px 5px 0px 0px',
    height: 20,
    width: '100%',
    boxShadow: '0px -1px 20px 5px rgba(0,0,0,0.2)',
    backgroundColor: 'white !important',
  },

  //BROWSER OPEN
  closeButtonWrap: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: -2,
  },
  closeButtonPop: {
    borderRadius: '5px 5px 0px 0px',
    height: 20,
    width: '100%',
    backgroundColor: 'white',
  },
});
