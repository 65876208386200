export default {
  contactInfo: {
    mobilePhone: 'Téléphone',
  },
  description: {
    title: 'Description',
  },
  formations: {
    title: 'Formations',
  },
  gallery: {
    title: "Galerie d'images",
  },
  noOnlineBooking:
    "Votre praticien n'a pas activé la prise de rendez-vous en ligne, contactez le directement.",
  places: {
    title: 'Lieux de consultation',
  },
};
