import './modal.module.css';
import React, { useEffect } from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles as any)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          style={{ color: 'white' }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

declare const window: any;

export interface ModalProps {
  actions?: React.ReactNode;
  maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl';
  handleClose?: any;
  id?: string;
  open: boolean;
  scroll?: 'body' | 'paper';
  title: string;
  [name: string]: any;
}

export function Modal(props: React.PropsWithChildren<ModalProps>) {
  const {
    actions,
    children,
    maxWidth,
    handleClose,
    id,
    open,
    scroll,
    title,
    ...rest
  } = props;

  const theme = useTheme();

  return (
    <div>
      <Dialog
        onClose={handleClose}
        fullWidth
        aria-labelledby="customized-dialog-title"
        open={open}
        scroll={scroll}
        maxWidth={maxWidth ?? 'sm'}
        {...rest}
      >
        <DialogTitle
          id={id}
          onClose={handleClose}
          style={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    </div>
  );
}

export default Modal;
