export default {
  actions: {
    delete: {
      title: 'Effacer un modèle de semaine',
      warning: 'Voulez-vous effacer ce modèle de semaine ?',
    },
    deleteSlot: {
      title: 'Effacer un créneau',
      warning: 'Voulez-vous effacer ce créneau ?',
    },
    preview: {
      title: 'Prévisualisation de vos modèles de semaine',
    },
  },
  caption: {
    colors:
      'Les couleurs sont là à titre indicatif afin de regrouper les créneaux ensemble. Une couleur = un créneau.',
    onlineDisplay:
      'Visibilité en ligne. Respectivement en ligne et hors ligne.',
    slotMode:
      'Mode de remplissage des créneaux. Respectivement début, fin et les deux.',
    title: 'Légende',
  },
  disable: 'Désactiver',
  eachTwoWeeks: 'Une fois toutes les deux semaines',
  eachWeek: 'Toutes les semaines',
  enable: 'Activer',
  endDate: 'Date de fin',
  endHour: 'Fin du créneau',
  form: {
    title: {
      add: 'Ajouter un modèle',
      addSlot: 'Ajouter un créneau',
      edit: 'Modifier le modèle',
      editSlot: 'Modifier le créneau',
    },
  },
  from: 'Du',
  info:
    'Les semaines types permettent de mettre à disposition des créneaux pour la prise de rendez-vous en ligne. Pour chaque semaine type, vous pouvez créer des créneaux avec des lieux et des motifs de consultation différents.',
  name: 'Nom de la semaine type',
  online: {
    available: 'Disponible en ligne',
    unavailable: 'Non disponible en ligne',
  },
  pause: 'Pause entre deux rendez-vous',
  recurrence: 'Récurrence',
  slot: {
    days: 'Jours',
    endHour: 'Heure de fin',
    noPreview: 'Ajoutez un créneau pour avoir un aperçu de votre semaine',
    noWorkplaceSelected:
      'Sélectionnez un lieu de consultation pour afficher les motifs associés.',
    pause: 'Pause',
    preview: 'Aperçu de ma semaine',
    startHour: 'Heure de début',
    title: 'Créneau',
    workplace: 'Lieu de consultation',
  },
  slotChiefComplaints: 'Motifs de consultation',
  slotConsultationPlace: 'Lieu de consultation',
  slotDays: 'Jours actifs du créneau',
  slotMode: 'Mode de remplissage',
  slotModes: {
    down: 'Fin du créneau',
    up: 'Début du créneau',
    'up&down': 'Dans les deux sens',
  },
  slotModesInfo: {
    down:
      "Génère des rendez-vous en commençant par la fin du créneau. Par exemple, de 10h à 12h, si on prend des motifs de consultation d'une durée de 50min, les créneaux proposés seront 10h20 et 11h10.",
    up:
      "Génère des rendez-vous en commençant par le début du créneau. Par exemple, de 10h à 12h, si on prend des motifs de consultation d'une durée de 50min, les créneaux proposés seront 10h et 10h50.",
    'up&down':
      "Génère le plus de rendez-vous possible dans un espace de temps défini. Par exemple, de 10h à 12h, si on prend des motifs de consultation d'une durée de 50min, les créneaux proposés seront 10h, 10h20, 10h50 et 11h10. Ces rendez-vous seront ajustés selon le premier choisi par un patient. Si le premier à choisir prend 10h20, il ne restera plus que 11h10 de disponible.",
  },
  snackbars: {
    error: {
      delete: 'Une erreur est survenue, merci de réessayer',
      save: 'Une erreur est survenue, merci de réessayer',
      slotOverlap:
        'Vous ne pouvez superposer deux créneaux pour un même modèle',
    },
    success: {
      delete: 'Supprimé avec succès',
      save: 'Enregistré avec succès',
    },
  },
  startDate: 'Date de début',
  startHour: 'Début du créneau',
  title: 'Mes semaines types',
  to: 'au',
  warningTemplate:
    'Plusieurs semaines types ne peuvent pas être actives la même semaine. Pour une semaine donnée, c’est la semaine type la plus haute dans la liste qui l’emporte. Vous pouvez changer l’ordre des semaine types avec un "glisser-déposer".',
};
