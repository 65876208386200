import * as React from 'react';
import { OrderStepper } from './OrderStepper';
import { useHistory } from 'react-router-dom';
import { links } from '../../../config';

export type AvailableStep = 'prescription' | 'comments' | 'shipping' | 'resume';

export interface OrderStepperControllerProps {
  currentStep: AvailableStep;
  disableClickOnSteps?: boolean;
  orderId?: string;
}

const steps = ['prescription', 'comments', 'shipping', 'resume'];

export function OrderStepperController(props: OrderStepperControllerProps) {
  const history = useHistory();
  const handleClickOnStep = (step: AvailableStep) => {
    if (!props.orderId) {
      return;
    }
    if (props.disableClickOnSteps) {
      return;
    }
    if (props.currentStep === 'resume') {
      return;
    }
    switch (step) {
      case 'prescription':
        history.push(links.getOrderPrescriptionScreen(props.orderId));
        break;
      case 'comments':
        history.push(links.getOrderCommentsScreen(props.orderId));
        break;
      case 'shipping':
        history.push(links.getOrderShippingScreen(props.orderId));
        break;
    }
  };
  return (
    <OrderStepper
      currentIndex={steps.indexOf(props.currentStep)}
      disableClickOnSteps={
        props.disableClickOnSteps ||
        props.currentStep === 'resume' ||
        !props.orderId
      }
      steps={steps}
      handleClickOnStep={handleClickOnStep}
    />
  );
}
