export default {
  and: 'and',
  browserDefaults: 'Default value',
  chips: {
    app: 'App',
    pro: 'Pro',
    proFeature: 'Paid service',
    proTrial: 'Pro - Trialing',
    student: 'Student',
  },
  comingSoon: 'Coming soon! 😉',
  connectWith: 'Connect with',
  controlButtons: {
    finish: 'Finish',
    later: 'Later',
    next: 'Next',
    previous: 'Previous',
    skip: 'Skip',
  },
  copied: 'Copied to clipboard',
  default: 'Default',
  fileSize: {
    byte: 'bytes',
    giga: 'GB',
    kilo: 'KB',
    mega: 'MB',
  },
  form: {
    buttons: {
      cancel: 'Cancel',
      charge: 'Charge',
      confirm: 'Confirm',
      delete: 'Delete',
      download: 'Download',
      edit: 'Edit',
      filter: 'Filter',
      loadMore: 'See more',
      logout: 'Sign out',
      save: 'Save',
      send: 'Send',
      setDefault: 'Set default',
    },
    errors: {
      tooLongString: 'Up to %X% chars',
      tooShortString: '%X% chars at least',
    },
    fields: {
      address: {
        label: 'Address',
      },
      addressComplement: {
        label: 'Additional information',
      },
      CGU: {
        label: `By checking this box, I certify having read and accepted without any reservations the [TCU](${process.env['NX_CGU_URL']}), the [cookies policy](${process.env['NX_COOKIES_POLICY_URL']}) and the [CIAM data confidentiality charter](${process.env['NX_DATA_POLICY_URL']}) *`,
      },
      chiefComplaint: {
        label: 'Chief complaint',
      },
      comment: {
        label: 'Comment',
      },
      consultationPlace: {
        label: 'Consultation place',
      },
      email: {
        label: 'Email',
      },
      endDate: {
        label: 'End date',
      },
      endHour: {
        label: 'End hour',
      },
      GDPR: {
        label: `By checking this box, I give my consent to process my personal data as per the [privacy policy](${process.env['NX_PRIVACY_POLICY_URL']}) in compliance with GDPR *`,
      },
      gender: {
        default: 'None',
        female: 'Female',
        male: 'Male',
        other: 'Other',
      },
      label: {
        label: 'Label',
      },
      lastname: {
        label: 'Lastname',
      },
      patient: {
        global: {
          label: 'Patient',
        },
      },
      phone: {
        areaCodeInfo: 'You must specify the area code',
        country: 'Country code',
        error: {
          possibility: {
            default: 'You must enter a valid phone number',
            'invalid-country-code':
              'You must enter a valid area code (+33 for France, +351 for Portugal)',
            'is-possible': 'The phone number seems to be possible',
            'too-long': 'The phone number seems to be too long',
            'too-short': 'The phone number seems to be too short',
            unknown: 'You must enter a valid phone number',
            wrongRegionCode: 'You must enter a local phone number',
          },
          specificType: {
            'fixed-line': 'You must enter a fixed line phone number',
            'fixed-line-or-mobile':
              'You must enter a fixed line or mobile phone number',
            mobile: 'You must enter a mobile phone number',
          },
        },
        errorNotValid: 'Enter a valid phone number',
        errorNotValidOrNotMobile: 'Enter a valid mobile phone number',
        label: 'Phone',
        mobileLabel: 'Mobile phone',
      },
      startDate: {
        label: 'Start date',
      },
      startHour: {
        label: 'Start hour',
      },
      title: {
        label: 'Title',
      },
      yourMessage: {
        label: 'Your message',
      },
    },
    uploads: {
      dragNDrop: {
        accept: 'Drop your files here',
        label: "Drag 'n' drop your files here, or click to select files",
        reject: "Your files don't comply with conditions of use",
        snackbars: {
          error: {
            invalidType: 'This file format is not authorized',
            maxFilesNumber:
              'You cannot upload more than %maxFilesNumber% files.',
            multipleFalse:
              'Only one file can be uploaded. Please remove existing one before uplading the new one',
            tooLarge: 'File size should not exceed',
            unique: 'A file with the same name already exists',
          },
        },
      },
    },
  },
  from: 'from',
  fromDate: 'From',
  ht: 'excl. tax',
  httpCodes: {
    buttons: {
      goBack: 'Go back',
      goHome: 'Go home',
    },
    error404: {
      content: "We can't seem to find the page you're looking for.",
      title: 'Oops!',
    },
  },
  legalDocuments: 'Legal documents',
  loading: 'Loading...',
  menuToolbar: {
    chatbox: 'Chatbox',
    faq: 'FAQ',
    serviceDesk: 'Give us your feedback',
    tutorials: 'Tutoriels',
  },
  no: 'no',
  noLoggedIn: 'Please login again',
  noOptionsText: 'No options',
  noResult: 'No matching records found',
  of: 'of',
  or: 'or',
  payment: {
    buttons: {
      validateCreditCard: 'Validate credit card',
    },
    labels: {
      billingCountry: 'Billing country',
      creditCard: 'Credit card',
      name: 'Name',
    },
    messages: {
      cardAccepted: 'Credit card accepted',
    },
  },
  practices: {
    acupressure: 'Acupressure',
    acupuncture: 'Acupuncture',
    error: 'Please choose at least one',
    other: 'Other',
    otherStr: 'Specify',
    pharmacopoeia: 'Pharmacopoeia',
    title: 'What do you practice ?',
    tuinaMassage: 'Tuina Massage',
  },
  readOnly: 'Read only',
  redirectPending: 'You are being redirected',
  to: 'to',
  toDate: 'To',
  tourGuide: {
    demoButton: 'See tutorial',
    description: {
      agenda: 'Agenda description',
      dashboard: 'Dashboard description',
      files: 'Files description',
      invoicing: 'Invoicing description',
      pharmaco: 'Pharmacopoeia description',
      services: 'Services description',
      settings: 'Settings description',
    },
    name: {
      agenda: 'Agenda',
      dashboard: 'Dashboard',
      files: 'Files',
      invoicing: 'Invoicing',
      pharmaco: 'Pharmacopoeia',
      services: 'Services',
      settings: 'Settings',
    },
    subSection: {
      description: {
        addPatientDescription: 'Discover how to add a patient.',
        agendaGeneralDescription:
          'The Agenda page will allow you to plan appointments and manage them. At a glance, you will know what your day is made of.',
        agendaSettingsDescription:
          'The Agenda page is customizable to best suit your needs.',
        dashboardGeneralDescription:
          'The dashboard is the home page of the site. You will find at a glance the appointments of the day, the ratio of the number of completed sessions to the number of scheduled sessions, the SMS credits, etc.',
        filesGeneralDescription:
          'Under Files, you will have an overview of your patients and some information about them.',
        invoicingGeneralDescription:
          'Here you will find a list of all invoices issued, their amount, date of issue, etc.',
        patientInfoDescription:
          'For more details, consult this page, you will find information about your patients: addresses, orders, history, etc.',
        pharmacoGeneralDescription:
          'From this page, you can create and track your orders.',
        removePatientDescription: 'Discover how to remove a patient.',
        servicesGeneralDescription:
          'This is where you will find the paid options/premium services of the software.',
        settingsActivityDescription: 'Make your settings for your activities.',
        settingsAgendaDescription:
          'You will be able to set up typical weeks for online appointments.',
        settingsGeneralDescription:
          'The Settings page will allow you to complete the customization of your interface. It is your tool: you can set it up as you wish.',
        settingsInvoicingDescription:
          'All invoicing information for your services must be entered from this page.',
        settingsPublicPageDescription:
          'What does your public page look like? On this page, you decide.',
        settingsWorkplacesDescription:
          'Make your settings for your workplaces.',
      },
      title: {
        addPatient: 'Add patient',
        agendaGeneral: 'Agenda',
        agendaSettings: 'Agenda settings',
        dashboardGeneral: 'Dashboard',
        filesGeneral: 'Files',
        invoicingGeneral: 'Invoicing',
        patientInfo: 'Patient informations',
        pharmacoGeneral: 'Pharmacopoeia',
        removePatient: 'Remove patient',
        servicesGeneral: 'Services',
        settingsActivity: 'Settings activity',
        settingsAgenda: 'Settings agenda',
        settingsGeneral: 'Settings',
        settingsInvoicing: 'Settings invoicing',
        settingsPublicPage: 'Settings public page',
        settingsWorkplaces: 'Settings workplaces',
      },
    },
    title: 'Tutorials',
  },
  ttc: 'incl. tax',
  VAT: 'VAT',
  welcome: 'Welcome',
  yes: 'yes',
};
