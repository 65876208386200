/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Order } from '@kiway/ecommerce-react';
import { AuthContext } from '@kiway/shared/features/authentication-react';

interface ConfirmationProps {
  order: Order;
  isRedirection?: boolean;
}

export const Confirmation = (props: ConfirmationProps) => {
  const { t } = useTranslation();
  const { isRoleAllowed } = React.useContext(AuthContext);

  return (
    <Grid
      container
      justifyContent="center"
      spacing={3}
      sx={{ padding: 2, borderRadius: '15px' }}
    >
      <Grid item xs={10} textAlign="center">
        <Typography variant="h4" sx={{ textTransform: 'uppercase' }}>
          {t(`pharmaco:order.aknowledgement`)}
        </Typography>
      </Grid>
      <Grid item xs={10} textAlign="center">
        <Typography>
          {t(`pharmaco:checkout.cardPaymentSuccess`)}
          {props.order?.getPayment()?.getPaymentMethod() !== 'card' &&
            !props.isRedirection &&
            ` ${t(`pharmaco:checkout.deferredPaymentSuccess`)}`}
        </Typography>
      </Grid>

      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="minoritary"
          onClick={() => {
            window.location.href = '/';
          }}
        >
          {t(
            `pharmaco:checkout.${
              isRoleAllowed('ROLE_PRACTITIONER')
                ? 'proSpaceReturn'
                : 'homeReturn'
            }`
          )}
        </Button>
      </Grid>
    </Grid>
  );
};
