/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  IProductAttributes,
  Product,
  ProductVariation,
} from '@kiway/ecommerce-react-compatible';
import { KiwayLanguagesType } from '@kiway/shared/react-types';
import { PlantVariation } from './PlantVariation';
import {
  ProductVariationType,
  IProductVariationTypeAttributes,
} from './ProductVariationType';
import {
  ProductMetadata,
  IProductMetadataAttributes,
  IProductMetadataSearchable,
  ProductMetadataType,
} from './ProductMetadata';
import { flatten } from '@kiway/shared/utils/string';

export type IPlantCustomAttributes = IProductAttributes['custom'] & {
  legacyId?: string;
  latinName: string;
  pinYinName: string;
  chineseName: string;
  otherName: string;
  commonName: string;
  concentrationRate: number;
  toxic: boolean;
  posologyMin?: number;
  posologyMax?: number;
  pictureUrl?: string;
  sellAvailable?: boolean;
};

export interface IPlantAttributes extends IProductAttributes {
  isConcentratedPowder: boolean;
  isPlant: boolean;
  published: boolean;
  metadatas?: IProductMetadataAttributes[];
  custom?: IPlantCustomAttributes;
}

export interface PlantMetadataSearch {
  productVariationTypes?: IProductVariationTypeAttributes[];
  metadatas?: IProductMetadataAttributes[];
}

export interface PlantMetadataSearchReturn {
  productVariationTypes?: ProductVariationType[];
  metadatas?: ProductMetadata[];
}

export type PlantArrays = 'metadatas' | 'productVariationType';

export const plantSearchableAttributes = [
  'custom.latinName',
  'custom.pinYinName',
  'custom.chineseName',
  'custom.otherName',
  'custom.commonName',
];

function escapeRegExp(str) {
  // eslint-disable-next-line no-useless-escape
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

export class Plant extends Product implements IProductMetadataSearchable {
  protected legacyId: string;
  protected latinName: string;
  protected pinYinName: string;
  protected chineseName: string;
  protected otherName: string;
  protected commonName: string;
  protected hasConcentratedPowder: boolean;
  protected hasPlant: boolean;
  protected concentrationRate: number;
  protected toxic: boolean;
  protected sellAvailable: boolean;
  protected published: boolean;
  protected posologyMin: number;
  protected posologyMax: number;
  protected metadatas: ProductMetadata[];
  protected custom: IPlantCustomAttributes;

  static getSimpleSearchFilter(search: string) {
    return (plant: Plant): boolean => {
      if (!search) {
        return true;
      }
      return (
        plant
          .getChineseName()
          ?.toLowerCase()
          ?.search(escapeRegExp(search).toLowerCase()) > -1 ||
        plant
          .getLatinName()
          ?.toLowerCase()
          ?.search(escapeRegExp(search).toLowerCase()) > -1 ||
        plant
          .getPinYinName()
          ?.toLowerCase()
          ?.search(escapeRegExp(search).toLowerCase()) > -1 ||
        plant
          .getOtherName()
          ?.toLowerCase()
          ?.search(escapeRegExp(search).toLowerCase()) > -1 ||
        plant
          .getCommonName()
          ?.toLowerCase()
          ?.search(escapeRegExp(search).toLowerCase()) > -1
      );
    };
  }

  public getCustomInput(): any {
    return {
      ...this.getCustom(),
      posologyMin: `${this.getPosologyMin()}`,
      posologyMax: `${this.getPosologyMax()}`,
      concentrationRate: `${this.getConcentrationRate()}`,
    };
  }

  public constructor(
    obj: Partial<IPlantAttributes> & { _id?: string; id?: string }
  ) {
    super(obj);
    this.custom = {
      ...this.custom,
      legacyId: obj?.custom?.legacyId,
      latinName: obj?.custom?.latinName,
      pinYinName: obj?.custom?.pinYinName,
      chineseName: obj?.custom?.chineseName,
      otherName: obj?.custom?.otherName,
      commonName: obj?.custom?.commonName,
      concentrationRate: obj?.custom?.concentrationRate,
      toxic: obj?.custom?.toxic,
      sellAvailable: obj?.custom?.sellAvailable,
      posologyMin: obj?.custom?.posologyMin,
      posologyMax: obj?.custom?.posologyMax,
      referenceStockPlant: obj?.custom?.referenceStockPlant,
      referenceStockPowder: obj?.custom?.referenceStockPowder,
      pictureUrl: obj?.custom?.pictureUrl,
    };
    this.hasConcentratedPowder = obj?.isConcentratedPowder;
    this.hasPlant = obj?.isPlant;
    this.published = obj?.published;
    this.metadatas = [];
    for (const m of obj?.metadatas || []) {
      if (!m.name) {
        this.addMetadata(new ProductMetadata({ _id: m.toString() }));
      } else {
        this.addMetadata(new ProductMetadata(m));
      }
    }
  }

  public static checkPosologyRequirements(obj: {
    min?: number;
    max?: number;
  }): boolean {
    return !(obj?.min && obj?.max && obj?.min > obj?.max);
  }

  public getLegacyId(): string {
    return this.custom.legacyId;
  }

  public getLatinName(): string {
    return this.custom.latinName;
  }

  public setLatinName(latinName: string): Plant {
    this.custom.latinName = latinName;
    return this;
  }

  public getPinYinName(): string {
    return this.custom.pinYinName;
  }

  public setPinYinName(pinYinName: string): Plant {
    this.custom.pinYinName = pinYinName;
    return this;
  }

  public getChineseName(): string {
    return this.custom.chineseName;
  }

  public setChineseName(chineseName: string): Plant {
    this.custom.chineseName = chineseName;
    return this;
  }

  public getOtherName(): string {
    return this.custom.otherName;
  }

  public setOtherName(otherName: string): Plant {
    this.custom.otherName = otherName;
    return this;
  }

  public getCommonName(): string {
    return this.custom.commonName;
  }

  public setCommonName(commonName: string): Plant {
    this.custom.commonName = commonName;
    return this;
  }

  public getPictureUrl(): string {
    return this.custom.pictureUrl;
  }

  public setPictureUrl(pictureUrl: string): Plant {
    this.custom.pictureUrl = pictureUrl;
    return this;
  }

  public isConcentratedPowder(force?: boolean): boolean {
    if (!force && this.hasConcentratedPowder !== undefined) {
      return this.hasConcentratedPowder;
    }
    return this.variations?.reduce(
      (prev: boolean, current: PlantVariation) =>
        current.isConcentratedPowder() || prev,
      false
    );
  }

  public isPlant(force?: boolean): boolean {
    if (!force && this.hasPlant !== undefined) {
      return this.hasPlant;
    }
    return this.variations?.reduce(
      (prev: boolean, current: PlantVariation) => current.isPlant() || prev,
      false
    );
  }

  private refreshIsPlantIsPowder(): void {
    this.hasConcentratedPowder = this.isConcentratedPowder(true);
    this.hasPlant = this.isPlant(true);
  }

  public getConcentrationRate(): number {
    return this.custom.concentrationRate;
  }

  public setConcentrationRate(concentrationRate: number): Plant {
    this.custom.concentrationRate = concentrationRate;
    return this;
  }

  public isToxic(): boolean {
    return this.custom.toxic;
  }

  public setToxic(toxic: boolean): Plant {
    this.custom.toxic = toxic;
    return this;
  }

  public isPublished(): boolean {
    return this.published;
  }

  public setPublished(published: boolean): Plant {
    this.published = published;
    return this;
  }

  public isSellAvailable(): boolean {
    return this.custom.sellAvailable;
  }

  public setSellAvailable(sellAvailable: boolean): Plant {
    this.custom.sellAvailable = sellAvailable;
    return this;
  }


  public getPosologyMin(): number {
    return this.custom.posologyMin;
  }

  public setPosologyMin(posologyMin: number): Plant {
    this.custom.posologyMin = posologyMin;
    return this;
  }

  public getPosologyMax(): number {
    return this.custom.posologyMax;
  }

  public setPosologyMax(posologyMax: number): Plant {
    this.custom.posologyMax = posologyMax;
    return this;
  }

  public getReferenceStockPlant(): string {
    return this.custom.referenceStockPlant;
  }

  public setReferenceStockPlant(referenceStockPlant: string): Plant {
    this.custom.referenceStockPlant = referenceStockPlant;
    return this;
  }

  public getReferenceStockPowder(): string {
    return this.custom.referenceStockPowder;
  }

  public setReferenceStockPowder(referenceStockPowder: string): Plant {
    this.custom.referenceStockPowder = referenceStockPowder;
    return this;
  }

  public getMetadatas(type?: ProductMetadataType): ProductMetadata[] {
    return type
      ? this.metadatas?.filter((m) => m.getMetadataType() === type)
      : this.metadatas;
  }

  public setMetadatas(metadatas: ProductMetadata[]): Plant {
    this.metadatas = metadatas;
    return this;
  }

  public addMetadata(metadata: ProductMetadata): Plant {
    this.metadatas.push(metadata);
    return this;
  }

  public removeMetadata(metadataId: string): Plant {
    this.metadatas = this.metadatas.filter(
      (metadata) => metadata.getId() !== metadataId
    );
    return this;
  }

  public getVariations(): PlantVariation[] {
    return super.getVariations();
  }

  public setVariations(variations: ProductVariation[]): Plant {
    super.setVariations(variations);
    return this;
  }

  public addVariation(variation: ProductVariation): Plant {
    super.addVariation(variation);
    this.refreshIsPlantIsPowder();
    return this;
  }

  public removeVariation(variationId: string): Plant {
    super.removeVariation(variationId);
    this.refreshIsPlantIsPowder();
    return this;
  }

  public getMetadataIds(): string[] {
    return [
      ...this.metadatas.map((e) => e.getId()),
      ...this.variations.map((e) => e.getProductVariationType()?.getId()),
    ];
  }

  public toDatatableRow(): any {
    return {
      _id: this.getId(),
      ...flatten({ custom: this.getCustom() }),
      published: this.isPublished(),
    };
  }

  public toJSON(): any {
    return {
      ...super.toJSON(),
      pinYinName: this.getPinYinName(),
      latinName: this.getLatinName(),
      chineseName: this.getChineseName(),
      commonName: this.getCommonName(),
      otherName: this.getOtherName(),
      published: this.isPublished(),
      pictureUrl: this.getPictureUrl(),
    };
  }

  public getMetadataType(): ProductMetadataType {
    return 'plant';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public parseEntity(lng: KiwayLanguagesType): AutocompleteResultMatches {
    return {
      mainDisplayName: `${this.getPinYinName()} - ${this.getLatinName()} - ${this.getChineseName()}`,
      otherDisplayNames: [],
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public toString(lng: KiwayLanguagesType): string {
    return `${this.getPinYinName()} ${this.getLatinName()} ${this.getChineseName()}`;
  }
}

type AutocompleteResultMatches = {
  mainDisplayName: string;
  otherDisplayNames: string[];
};
