import * as React from 'react';
import { CustomerSelector } from './CustomerSelector';
import { useCustomerFilesGateway } from '@kiway/shared/features/customer-files-react';

export interface CustomerSelectorControllerProps {
  handleChangePatient: any;
  selectedFileId?: string;
}

export function CustomerSelectorController(
  props: CustomerSelectorControllerProps
) {
  const [customers, setCustomers] = React.useState<any[]>([]);
  const { findByPractitioner } = useCustomerFilesGateway();
  React.useEffect(() => {
    findByPractitioner().then((results) => setCustomers(results));
  }, []);
  return (
    <CustomerSelector
      customers={customers}
      selected={customers?.find(
        (patientFile) => patientFile.id === props.selectedFileId
      )}
      handleChange={props.handleChangePatient}
    />
  );
}
