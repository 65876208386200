import { Country, ICountryAttributes } from '../entities/Country';
import { CountryProvider } from './CountryProvider';

export class EditManyCountries {
  private countryProvider: CountryProvider;

  public constructor(countryProvider: CountryProvider) {
    this.countryProvider = countryProvider;
  }

  public execute(
    countries: Array<ICountryAttributes>,
    userId?: string
  ): Promise<Array<Country>> {
    return this.countryProvider.editMany(countries, userId);
  }
}
