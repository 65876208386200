export default {
  dateTimePicker: {
    buttons: {
      cancel: {
        text: 'Cancelar',
      },
      clear: {
        text: 'Borrar',
      },
      ok: {
        text: 'Ok',
      },
      today: {
        text: 'Hoy',
      },
    },
  },
  format: {
    agendaSettingsHour: 'HH:mm:ss',
    date: 'DD/MM/YYYY',
    datePlaceholder: 'JJ/MM/AAAA',
    dayMonthYearHourMinute: 'DD/MM/YYYY HH:mm',
    hour: 'HH:mm',
    hourOnly: 'HH[h]',
    hourTxt: 'HH[h]mm',
    invalid: 'dados inválidos',
    isoDate: 'YYYY-MM-DD',
    isoHour: 'HH:mm:ssSSSZ',
    literalComplete: 'Do MMMM YYYY [à] HH[h]mm',
    literalDate: 'Do MMMM YYYY',
    shortDate: 'D MMM YYYY',
    shortDay: 'ddd',
    shortDayMonth: 'D MMM',
    shortYearDate: 'DD/MM/YY',
    smsSentDate: 'DD/MM/YYYY [à] HH:mm',
  },
  hourFormat: '24',
  moment: 'es',
  month: 'mes',
  strings: {
    currentMonth: 'Este mes',
    currentWeek: 'Esta semana',
    previousMonth: 'Mes anterior',
    previousWeek: 'Semana anterior',
  },
};
