import * as React from 'react';
import { RegisterChoice } from './RegisterChoice';
import ReactGA from 'react-ga';
import { gaEvents } from '@kiway/shared/utils-react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { RegisterDisabled } from '../..';

export function RegisterChoiceController() {
  const match = useRouteMatch();
  const history = useHistory();

  const handleEventGA = (name: 'patient' | 'practitioner') => () => {
    if (name === 'patient') {
      ReactGA.event({
        category: gaEvents.SIGNUP_CAT,
        action: gaEvents.CHOOSE_ACCOUNT_ACTION,
        label: gaEvents.PATIENT_LABEL,
      });
    } else if (name === 'practitioner') {
      ReactGA.event({
        category: gaEvents.SIGNUP_CAT,
        action: gaEvents.CHOOSE_ACCOUNT_ACTION,
        label: gaEvents.PRACTITIONER_LABEL,
      });
    }
  };
  return process.env.NX_REGISTER_ENABLED === 'true' ? (
    <RegisterChoice
      handleEventGA={handleEventGA}
      handleReturnHome={() => history.push('/')}
      matchUrl={match.url}
    />
  ) : (
    <RegisterDisabled />
  );
}
