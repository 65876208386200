import * as React from 'react';
import { TwoFA } from './TwoFA';
import { Setup2FA } from '../../../core/use_cases/UserProvider';
import { useUsersGateway } from '../../../entrypoints/react/useUsersGateway';
import { User } from '../../../core/entities/User';
import { AuthContext } from '../..';
import { useSnackbar } from '@kiway/shared/utils/snackbar';
import { useTranslation } from '@kiway/shared/utils/translation';

const steps = [
  {
    label: 'authentication:editProfile.twoFA.dlApp.label',
    description: 'authentication:editProfile.twoFA.dlApp.description',
  },
  {
    label: 'authentication:editProfile.twoFA.scanQRCode.label',
    description: 'authentication:editProfile.twoFA.scanQRCode.description',
  },
  {
    label: 'authentication:editProfile.twoFA.enterCode.label',
    description: 'authentication:editProfile.twoFA.enterCode.description',
  },
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TwoFAControllerProps {}

export function TwoFAController(props: TwoFAControllerProps) {
  const [code, setCode] = React.useState('');
  const [qrCode, setQrCode] = React.useState('');
  const [secret, setSecret] = React.useState('');
  const [twoFAVisible, setTwoFAVisible] = React.useState('');
  const [twoFAMethod, setTwoFAMethod] = React.useState([]);
  const [goodSteps, setGoodSteps] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const { addErrorSnackbar, addSuccessSnackbar } = useSnackbar();
  const { get2FAUrl, enable2FA, disable2FA } = useUsersGateway();
  const { authState, updateUserInfo } = React.useContext(AuthContext);
  React.useEffect(() => {
    get2FAUrl().then((result: Setup2FA) => {
      setQrCode(result.qrCode);
      setSecret(result.secret);
    });
    return () => {
      setQrCode('');
      setSecret('');
      setTwoFAMethod(null);
      setGoodSteps([]);
    };
  }, []);

  const resetTwoFAMethod = (user) => {
    const method =
      user
        ?.getTwoFactor()
        ?.methods?.filter(({ method }) => method === 'authenticator')?.[0] ??
      null;
    setTwoFAMethod(method ? [method] : []);
  };

  React.useEffect(() => {
    resetTwoFAMethod(new User(authState.userInfo));
  }, []);

  React.useEffect(() => {
    setGoodSteps(
      twoFAVisible === 'enable'
        ? steps
        : twoFAVisible === 'disable'
        ? [steps[steps.length - 1]]
        : []
    );
  }, [twoFAVisible, steps]);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleActionResult = (type: 'enable' | 'disable', result: User) => {
    if (result) {
      updateUserInfo(result, false);
      resetTwoFAMethod(result);
      addSuccessSnackbar(t(`authentication:editProfile.twoFA.${type}Success`));
      return true;
    } else {
      addErrorSnackbar(t(`authentication:editProfile.twoFA.${type}Error`));
      return false;
    }
  };

  const handleNext = (currentIndex) => () => {
    if (currentIndex === goodSteps?.length - 1) {
      if (twoFAVisible === 'enable') {
        setLoading(true);
        enable2FA(code, secret)
          .then((result) => {
            return handleActionResult(twoFAVisible, result);
          })
          .then((result) => {
            if (result) {
              setTwoFAVisible('');
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (twoFAVisible === 'disable') {
        setLoading(true);
        disable2FA(code, twoFAMethod?.[0]?.id)
          .then((result) => {
            return handleActionResult(twoFAVisible, result);
          })
          .then((result) => {
            if (result) {
              setTwoFAVisible('');
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <TwoFA
      activeStep={activeStep}
      code={code}
      handleBack={handleBack}
      handleChangeCode={(event) => setCode(event.target.value)}
      handleNext={handleNext}
      loading={loading}
      qrCode={qrCode}
      secret={secret}
      steps={goodSteps}
      twoFAMethod={twoFAMethod?.[0]}
      twoFAVisible={twoFAVisible}
      handleEnable={() =>
        setTwoFAVisible((prev) => (prev === '' ? 'enable' : ''))
      }
      handleDisable={() =>
        setTwoFAVisible((prev) => (prev === '' ? 'disable' : ''))
      }
    />
  );
}
