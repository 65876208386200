import React from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import { Payment } from '../../../core/entities/Payment';
import { useTranslation } from '@kiway/shared/utils/translation';
import moment from 'moment';
import { getFontAwesome4IconFromPaymentMethod } from '@kiway/shared/utils/string';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Price } from '@kiway/ecommerce-react';

interface ModalContentCardProps {
  selectedPayment: Payment;
}

export const ModalContentCard = (props: ModalContentCardProps) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <b>{t(`pharmaco:orderDetails.status`)}</b>
          </Grid>
          <Grid item>
            <Chip
              label={t(
                `pharmaco:orderDetails.payment.status.${
                  props.selectedPayment.getPaymentStatusJSON().label
                }`
              ).toUpperCase()}
              color={props.selectedPayment.getPaymentStatusJSON()?.color}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <b>{t(`pharmaco:orderDetails.payment.amount`)}</b>
          </Grid>
          <Grid item>{props.selectedPayment.getAmount().toString()}</Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <b>{t(`pharmaco:orderDetails.payment.paidAt`)}</b>
          </Grid>
          <Grid item>
            {props.selectedPayment.getCompletedAt()
              ? moment(props.selectedPayment.getCompletedAt())?.format(
                  t('dates:format.smsSentDate')
                )
              : null}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <b>{t(`pharmaco:orderDetails.payment.paymentMethod`)}</b>
          </Grid>
          <Grid item>
            <Typography>
              <i
                className={
                  `fa-lg ${getFontAwesome4IconFromPaymentMethod(
                    props.selectedPayment.getPaymentMethod(),
                    props.selectedPayment.getCustom()?.card?.brand
                  )}` as any
                }
              ></i>
              {' ···· '}
              {props.selectedPayment.getCustom()?.card?.last4}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <b>{t(`pharmaco:orderDetails.payment.paymentFees`)}</b>
          </Grid>
          <Grid item>
            {new Price({
              centAmount: props.selectedPayment.getCustom()?.paymentFees,
            })?.toString()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
