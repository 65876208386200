/* eslint-disable react/no-array-index-key */
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Autocomplete from '@material-ui/lab/Autocomplete'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import React from 'react'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import PropTypes from 'prop-types'
import { useTranslation } from '@kiway/shared/utils/translation'
import { getTimeZoneFromCoordinates } from '../services/utils/api'
import GreenPageTextField from './Forms/GreenPageTextField'

function loadScript(src, position, id) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = { current: null }

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

const GOOGLE_API_KEY = process.env['NX_GOOGLE_API_KEY']

function GoogleMaps(props) {
  const classes = useStyles()

  const {
    inputValue,
    setAddress,
    setAddressComponent,
    setTimezone,
    variant,
    margin,
    readOnly,
    theme,
    ...other
  } = props
  const [options, setOptions] = React.useState([])
  const loaded = React.useRef(false)
  const { t } = useTranslation()

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        // eslint-disable-next-line max-len
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      )
    }

    loaded.current = true
  }

  const handleChange = (event) => {
    setAddress(event.target.value)
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback)
      }, 200),
    [],
  )

  React.useEffect(() => {
    let active = true
    if (!autocompleteService.current && window.google && window.google.maps) {
      // eslint-disable-next-line max-len
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions([])
      return undefined
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results || [])
      }
    })

    return () => {
      active = false
    }
  }, [inputValue, fetch])

  const getTimeZoneFromLocation = (lat, lng) =>
    getTimeZoneFromCoordinates(lat, lng)

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      inputValue={inputValue}
      includeInputInList
      disabled={readOnly}
      onChange={(event, value) => {
        if (value) {
          setAddress(value.description)
          geocodeByAddress(value.description)
            .then(async (results) => {
              const coordinates = await getLatLng(results[0])
              const addressComponent = results[0].address_components
              const streetNumber = addressComponent.filter((item) =>
                item.types.includes('street_number'),
              )
              const route = addressComponent.filter((item) =>
                item.types.includes('route'),
              )
              const locality = addressComponent.filter((item) =>
                item.types.includes('locality'),
              )
              const administrativeAreaLevel2 = addressComponent.filter((item) =>
                item.types.includes('administrative_area_level_2'),
              )
              const administrativeAreaLevel1 = addressComponent.filter((item) =>
                item.types.includes('administrative_area_level_1'),
              )
              const country = addressComponent.filter((item) =>
                item.types.includes('country'),
              )
              const postalCode = addressComponent.filter((item) =>
                item.types.includes('postal_code'),
              )
              const formatedAddressComponent = {
                street_number: streetNumber[0]
                  ? streetNumber[0].long_name
                  : null, // numéro
                route: route[0] ? route[0].long_name : null, // rue
                locality: locality[0] ? locality[0].long_name : null, // ville
                administrative_area_level_2: administrativeAreaLevel2[0]
                  ? administrativeAreaLevel2[0].long_name
                  : null, // département
                administrative_area_level_1: administrativeAreaLevel1[0]
                  ? administrativeAreaLevel1[0].long_name
                  : null, // région
                country: country[0] ? country[0].long_name : null, // pays
                postal_code: postalCode[0] ? postalCode[0].long_name : null, // code postal
                coordinates,
                description: value.description,
              }
              setAddressComponent(formatedAddressComponent)
              return coordinates
            })
            .then(async ({ lat, lng }) => {
              if (setTimezone) {
                getTimeZoneFromLocation(lat, lng).then((tz) => setTimezone(tz))
              }
            })
            .catch(() => {})
        } else {
          setAddressComponent({})
          setAddress('')
        }
      }}
      renderInput={(params) => {
        if (theme !== 'green') {
          return (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, autoComplete: 'nope' }}
              required={props.required}
              label={t('common:form.fields.address.label')}
              variant={variant || 'outlined'}
              margin={margin}
              fullWidth
              onChange={handleChange}
            />
          )
        }
        return (
          <GreenPageTextField
            {...params}
            inputProps={{ ...params.inputProps, autoComplete: 'nope' }}
            required={props.required}
            label={t('common:form.fields.address.label')}
            variant={variant || 'outlined'}
            margin={margin}
            fullWidth
            onChange={handleChange}
          />
        )
      }}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        )

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
      {...other}
    />
  )
}

GoogleMaps.propTypes = {
  inputValue: PropTypes.string,
  setAddress: PropTypes.func.isRequired,
  setAddressComponent: PropTypes.func.isRequired,
  setTimezone: PropTypes.func,
  variant: PropTypes.string,
  margin: PropTypes.string,
  readOnly: PropTypes.bool,
}

GoogleMaps.defaultProps = {
  readOnly: false,
}

export default GoogleMaps
