import * as React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';

export interface ButtonGroupSelectorProps {
  handleChange?: (value: string) => any;
  value: string;
  values: Array<{ value: string; label: string }>;
}

export function ButtonGroupSelector(props: ButtonGroupSelectorProps) {
  return (
    <ButtonGroup color="primary" size="small">
      {props.values?.map((item) => {
        return (
          <Button
            size="small"
            key={item.value}
            onClick={
              props.handleChange
                ? () => props.handleChange(item.value)
                : undefined
            }
            variant={props.value === item.value ? 'contained' : 'outlined'}
          >
            {item.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}
