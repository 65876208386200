import { CountryZone } from '../entities/CountryZone';
import { CountryZoneProvider } from './CountryZoneProvider';

export class GetAllCountryZones {
  private countryZoneProvider: CountryZoneProvider;

  public constructor(countryZoneProvider: CountryZoneProvider) {
    this.countryZoneProvider = countryZoneProvider;
  }

  public execute(): Promise<CountryZone[]> {
    return this.countryZoneProvider.findAll();
  }
}
