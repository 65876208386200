import { IOrderAttributes } from '../entities/Order';
import { Order } from '../entities/Order';
import { OrderProvider } from './OrderProvider';
import { EventEmitter } from '@kiway/event-emitter';

export class EditManyOrders {
  private orderProvider: OrderProvider;

  public constructor(orderProvider: OrderProvider) {
    this.orderProvider = orderProvider;
  }

  public async execute(
    orders: Array<IOrderAttributes & { _id?: string; id?: string }>,
    userId?: string
  ): Promise<Array<Order>> {
    let orderOpen = false,
      orderCreate = false,
      orderShipped = false,
      orderPaid = false,
      orderCancelled = false;
    if (orders?.length === 1 && (orders?.[0]?.id || orders?.[0]?._id)) {
      const oldOrder = await this.orderProvider.findOneById(
        orders?.[0]?.id ?? orders?.[0]?._id
      );
      if (
        oldOrder &&
        oldOrder.getOrderStatus() === 'DRAFT' &&
        orders?.[0]?.orderStatus === 'OPEN'
      ) {
        orderOpen = true;
      }
      if (
        oldOrder &&
        oldOrder.getShipmentStatus() === 'PENDING' &&
        orders?.[0]?.shipmentStatus === 'SHIPPED'
      ) {
        orderShipped = true;
      }
      if (
        oldOrder &&
        ['BALANCE_DUE', 'PENDING'].includes(oldOrder.getPaymentStatus()) &&
        orders?.[0]?.paymentStatus === 'PAID'
      ) {
        orderPaid = true;
      }
      if (
        oldOrder &&
        oldOrder.getOrderStatus() === 'OPEN' &&
        (oldOrder.getPaymentStatus() === 'BALANCE_DUE' ||
          oldOrder.getPaymentStatus() === 'PENDING') &&
        orders?.[0]?.orderStatus === 'CANCELLED'
      ) {
        orderCancelled = true;
      }
    }
    const ordersDB = await this.orderProvider.editMany(orders, userId);
    if (orders?.length === 1 && !orders?.[0]?._id && !orders?.[0]?.id) {
      if (ordersDB?.[0]) {
        orderCreate = true;
      }
    }
    const eventEmitter = EventEmitter.getInstance();
    if (orderCreate) {
      eventEmitter.emit('ORDER_CREATE', {
        order: ordersDB?.[0],
      });
    }
    if (orderOpen) {
      eventEmitter.emit('ORDER_OPEN', {
        order: ordersDB?.[0],
      });
    }
    if (orderShipped) {
      eventEmitter.emit('ORDER_SHIPPED', { order: ordersDB?.[0] });
    }
    if (orderPaid) {
      eventEmitter.emit('ORDER_PAID', { order: ordersDB?.[0] });
    }
    if (orderCancelled) {
      eventEmitter.emit('ORDER_CANCELLED', { order: ordersDB?.[0] });
    }
    return ordersDB;
  }
}
