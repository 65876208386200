import MomentUtils from '@date-io/moment'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import moment from 'moment'
import React from 'react'
import { useTranslation } from '@kiway/shared/utils/translation'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  whiteForm: {
    color: 'white',
    '& label': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& input + fieldset': {
      borderWidth: 2,
      color: 'white',
      borderColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      borderColor: 'white',
      color: 'white',
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover:not(.Mui-error) fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused:not(.Mui-error) fieldset': {
        borderColor: 'white',
      },
    },
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
}))

function BirthDatePicker(props) {
  const classes = useStyles()
  const { handleChange, theme, value, required, ...rest } = props
  const { t } = useTranslation()
  const localeMoment = t('dates:moment')
  const hourFormat = t('dates:hourFormat')
  const inputDateFormat = t('dates:format.date')
  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      className={
        (theme === 'green' ? classes.formControl : '') + ' ' + classes.root
      }
      utils={MomentUtils}
      locale={localeMoment}
    >
      <Grid container justifyContent="flex-start" className={classes.root}>
        <KeyboardDatePicker
          autoOk
          ampm={hourFormat === 12}
          clearable
          inputVariant="outlined"
          margin="dense"
          id="date-picker-dialog-start-date"
          name="birthDate"
          disableFuture
          label={t('file:details.info.birthDate')}
          format={inputDateFormat}
          className={
            (theme === 'green' ? classes.whiteForm : '') + ' ' + classes.root
          }
          value={value}
          cancelLabel={t('dates:dateTimePicker.buttons.cancel.text')}
          okLabel={t('dates:dateTimePicker.buttons.ok.text')}
          onChange={handleChange}
          onAccept={handleChange}
          required={required}
          placeholder={t('dates:format.datePlaceholder')}
          {...rest}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default BirthDatePicker
