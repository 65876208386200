export default {
  appBar: {
    prefix: 'Sessão de',
  },
  diagnosis: {
    title: 'Diagnóstico',
  },
  followUp: {
    comment: 'Observações',
    date: 'Data',
    rating: 'Avaliação',
    title: 'Seguimento do tratamento',
  },
  fourMethods: {
    title: 'Quatro fases do diagnóstico',
  },
  info: {
    chiefComplaint: 'Motivo de consulta',
    comment: 'Observações',
    date: 'Data',
    title: 'Informações gerais',
  },
  patientNote: {
    title: 'Notas do paciente',
  },
  treatment: {
    title: 'Tratamento',
  },
};
