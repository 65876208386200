/* eslint-disable no-case-declarations */
import React, { useContext, useReducer, createContext } from 'react';
import moment from 'moment';

export function initBookingState() {
  return {
    practitioner: { id: '', fullname: '', mobilePhone: '', pic: '' },
    consultationPlace: {
      id: '',
      name: '',
      address: '',
      complement: '',
      zipCode: '',
      city: '',
    },
    chiefComplaint: {
      id: '',
      label: '',
      duration: '',
      price: '',
      specialy: '',
    },
    appointment: { id: '', startDate: '', endDate: '', comment: '' },
    patient: { id: '', fullname: '' },
    sanitaryInfo: { display: false, content: '' },
  };
}

export function bookingReducer(state, action) {
  switch (action.type) {
    case 'SET_PRACTITIONER':
      return {
        ...state,
        practitioner: action.payload,
      };
    case 'SET_PROFILE_PIC':
      return {
        ...state,
        practitioner: {
          ...state.practitioner,
          pic: action.payload.pic,
        },
      };
    case 'SET_COMMENT':
      return {
        ...state,
        appointment: {
          ...state.appointment,
          comment: action.payload.comment,
        },
      };
    case 'SELECT_CHIEF_COMPLAINT':
      return {
        ...state,
        chiefComplaint: action.payload,
      };
    case 'SELECT_CONSULTATION_PLACE':
      return {
        ...state,
        consultationPlace: action.payload,
      };
    case 'SELECT_SLOT':
      /** @todo: check if dates are well formatted */
      const duration = moment.duration({
        minutes: state.chiefComplaint.duration,
      });
      const startDate = moment(action.payload.slot).toDate();
      const startTmp = moment(action.payload.slot);
      const endDate = startTmp.add(duration).toDate();
      return {
        ...state,
        appointment: { startDate, endDate },
      };
    case 'BOOK_APPOINTMENT_PENDING':
      return {
        ...state,
        appointment: {
          ...state.appointment,
          id: action.payload.appointmentId,
        },
      };
    case 'PATIENT_LOGIN':
      return {
        ...state,
        patient: action.payload,
      };
    case 'SET_SANITARY_INFO':
      return {
        ...state,
        sanitaryInfo: action.payload,
      };
    default:
      throw new Error('Unhandled action type');
  }
}

/**
 * Create and initialize a Context object to maintain
 *  booking states accross components tree
 */
const BookingContext = createContext(initBookingState());

function BookingContextProvider(props) {
  const { children } = props;

  /**
   * Create and initialize our Booking states management system
   */
  const initialBookingState = useContext(BookingContext);
  const [state, dispatch] = useReducer(bookingReducer, initialBookingState);

  return (
    <BookingContext.Provider value={{ state, dispatch }}>
      {children}
    </BookingContext.Provider>
  );
}

export { BookingContextProvider, BookingContext };
