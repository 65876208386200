import * as React from 'react';
import { UserAddress } from './UserAddress';
import { IAddressAttributes, User } from '../../../core/entities/User';
import { useUsersGateway } from '../../../entrypoints/react/useUsersGateway';
import { AuthContext } from '../../contexts';
import { useSnackbar } from '@kiway/shared/utils/snackbar';
import { useTranslation } from '@kiway/shared/utils/translation';
import { generateObjectId } from '@kiway/shared/utils/string';
import { Address } from '../../../core/entities/User';
import { getFallbackCountryCode } from '@kiway/shared/utils-react-compatible';

const emptyAddress = new Address({
  city: '',
  line0: '',
  line1: '',
  line2: '',
  line3: '',
  firstName: '',
  lastName: '',
  zipCode: '',
  countryCode: getFallbackCountryCode(),
});

export interface UserAddressControllerProps {
  userId?: string;
  user?: User;
  enableUpdates?: boolean;
}

export function UserAddressController(
  props: UserAddressControllerProps = { enableUpdates: true }
) {
  const { updateUserInfo } = React.useContext(AuthContext);
  const [user, setUser] = React.useState<User[]>([new User({})]);
  const [initialUser, setInitialUser] = React.useState<User>();
  const { checkToken, editProfile, findOneById } = useUsersGateway();
  const { addErrorSnackbar, addSuccessSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState('');
  const [modalAddress, setModalAddress] = React.useState<Address>(emptyAddress);

  React.useEffect(() => {
    if (props.user?.getId()) {
      setUser([props.user]);
      setInitialUser(props.user);
    } else if (props.userId) {
      findOneById(props.userId).then((foundUser) => {
        setUser([foundUser]);
        setInitialUser(foundUser);
      });
    } else {
      checkToken().then(({ user }) => {
        setUser([user]);
        setInitialUser(new User(user.toJSON()));
      });
    }
    return () => {
      setUser([new User({})]);
      setInitialUser(new User({}));
    };
  }, [props.user, props.userId]);

  const handleSave = () => {
    if (!user[0]?.equals(initialUser)) {
      editProfile(user[0]).then((newUser) => {
        if (newUser?.getId()) {
          if (!props.user.getId() && !props.userId) {
            updateUserInfo(newUser.toJSON(), false);
          }
          addSuccessSnackbar(t('authentication:editProfile.saveSuccess'));
          setInitialUser(newUser);
          resetModal();
        } else {
          addErrorSnackbar(t('authentication:editProfile.saveError'));
        }
      });
    }
  };

  const handleSetDefaultAddress = (addressId: string) => {
    setUser([user[0].setDefaultAddress(addressId)]);
    handleSave();
  };

  const handleRemoveAddress = (addressId: string) => {
    setDeleteModalOpen(true);
    setDeleteId(addressId);
  };

  const confirmRemoveAddress = () => {
    setUser([user[0].removeAddress(deleteId)]);
    handleSave();
  };

  const initNewAddress = () => {
    setModalAddress(
      new Address({
        ...emptyAddress,
        id: generateObjectId(),
        firstName: user[0].getFirstName() ?? '',
        lastName: user[0].getLastName() ?? '',
        email: user[0]?.getEmail() ?? '',
        mobilePhone: user[0]?.getMobilePhone() ?? '',
        nif: user[0]?.getCustom()?.nif ?? '',
      })
    );
    setModalOpen('create');
  };

  const confirmNewAddress = () => {
    setUser([user[0].addAddress(modalAddress)]);
    handleSave();
  };

  const initUpdateAddress = (addressId: string) => {
    setModalAddress(
      user[0]
        ?.getAddresses()
        ?.find((address) => address.getId() === addressId) ?? emptyAddress
    );
    setModalOpen('update');
  };

  const confirmUpdateAddress = () => {
    setUser([user[0].updateAddress(modalAddress.getId(), modalAddress)]);
    handleSave();
  };

  const resetModal = () => {
    setModalAddress(emptyAddress);
    setModalOpen('');
    setDeleteId('');
    setDeleteModalOpen(false);
  };

  return (
    <UserAddress
      addresses={user[0]?.getAddresses() ?? []}
      confirmNewAddress={confirmNewAddress}
      confirmRemoveAddress={confirmRemoveAddress}
      confirmUpdateAddress={confirmUpdateAddress}
      deleteModalOpen={deleteModalOpen}
      editAddress={initUpdateAddress}
      enableUpdates={props.enableUpdates}
      handleChangeAddress={(address: IAddressAttributes) =>
        setModalAddress((prev) => new Address({ ...prev, ...address }))
      }
      modalAddress={modalAddress}
      modalState={modalOpen}
      newAddress={initNewAddress}
      removeAddress={handleRemoveAddress}
      resetModal={resetModal}
      setDefaultAddress={handleSetDefaultAddress}
    />
  );
}
