import { LoyaltyTransaction } from '../entities/LoyaltyTransaction';
import { LoyaltyTransactionProvider } from './LoyaltyTransactionProvider';

export class GetAllLoyaltyTransactions {
  private loyaltyTransactionProvider: LoyaltyTransactionProvider;

  public constructor(loyaltyTransactionProvider: LoyaltyTransactionProvider) {
    this.loyaltyTransactionProvider = loyaltyTransactionProvider;
  }

  public execute(user?: string): Promise<LoyaltyTransaction[]> {
    return user
      ? this.loyaltyTransactionProvider.getLoyaltyTransactionsByUser(user)
      : this.loyaltyTransactionProvider.findAll();
  }
}
