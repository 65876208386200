export default {
  body: {
    columnHeaderTooltip: 'Classificar por {{columnLabel}}',
    noMatch: 'Nenhum resultado',
    toolTip: 'Ordenar',
  },
  filter: {
    all: 'Tudo',
    reset: 'Reiniciar',
    title: 'Filtros',
  },
  pagination: {
    displayRows: 'em',
    next: 'Próxima página',
    previous: 'Página anterior',
    rowsPerPage: 'Linhas por página:',
  },
  selectedRows: {
    delete: 'Apagar',
    deleteAria: 'Eliminar linhas selecionadas',
    text: 'Linha (s) selecionada (s)',
  },
  toolbar: {
    downloadCsv: 'Exportar em CSV',
    filterTable: 'Filtros',
    print: 'Imprimir',
    search: 'Pesquisar',
    viewColumns: 'Selecionar colunas',
  },
  viewColumns: {
    title: 'Colunas exibidas',
    titleAria: 'Mostrar/ocultar colunas',
  },
};
