import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardActions,
  IconButton,
  CardHeader,
} from '@mui/material';
import { Plant } from '../../../../core/entities/Plant';
import { ItemIcon } from './ItemIcon';
import { useStyles } from '../../shared/styles';
import InfoIcon from '@mui/icons-material/Info';
import { InfoModal } from './InfoModal';
import { Formula } from '../../../../core/entities/Formula';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Product } from '@kiway/ecommerce-react-compatible';
import { useTranslation } from '@kiway/shared/utils/translation';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';

export interface ItemProps {
  item: Product;
  selected?: boolean;
  handleSelect: () => void;
}
export interface PlantItemProps {
  item: Plant;
  selected?: boolean;
  handleSelect: () => void;
}
export interface FormulaItemProps {
  item: Formula;
  selected?: boolean;
  handleSelect: () => void;
}

export function Item(props: ItemProps) {
  if (props.item instanceof Plant) {
    return <PlantItem {...(props as PlantItemProps)} />;
  } else if (props.item instanceof Formula) {
    return <FormulaItem {...(props as FormulaItemProps)} />;
  } else {
    return null;
  }
}

const truncate = (input: string, n: number) =>
  input.length > n ? `${input.substring(0, n)}...` : input;

export function FormulaItem(props: FormulaItemProps) {
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const { currentLanguage } = useTranslation();

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenInfo(true);
  };
  return (
    <Card
      sx={(theme) => ({
        borderColor: 'secondary.main',
        borderWidth: theme.spacing(0.4),
        borderStyle: props.selected ? 'solid' : 'none',
        padding: props.selected ? 0.6 : 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      })}
      onClick={props.handleSelect}
    >
      <CardHeader
        sx={{ p: 0 }}
        action={
          <IconButton size="small" onClick={handleClick}>
            <InfoIcon />
          </IconButton>
        }
      />
      <CardContent sx={{ p: 0, flex: 1 }}>
        <Grid
          container
          alignItems="center"
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" textAlign="center">
                  <b>{props.item.getPinYinName()}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" textAlign="center">
                  {truncate(
                    props.item.getDescription()?.[
                      currentLanguage?.code ?? getFallbackLanguage()
                    ] ?? '',
                    200
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

      {openInfo && (
        <InfoModal
          open={openInfo}
          handleClose={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenInfo(false);
          }}
          formula={props.item}
          type="formula"
        />
      )}
    </Card>
  );
}

export function PlantItem(props: PlantItemProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenInfo(true);
  };

  return (
    <Card
      sx={(theme) => ({
        borderColor: 'secondary.main',
        borderWidth: theme.spacing(0.4),
        borderStyle: props.selected ? 'solid' : 'none',
        padding: props.selected ? 0.6 : 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      })}
      onClick={
        props.item.getCustom()?.sellAvailable ? props.handleSelect : undefined
      }
    >
      <CardHeader
        sx={{ p: 0 }}
        action={
          <IconButton size="small" onClick={handleClick}>
            <InfoIcon />
          </IconButton>
        }
      />
      <CardContent sx={{ p: 0, flex: 1 }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100%' }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" textAlign="center">
                  <b>{props.item.getPinYinName()}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  textAlign="center"
                  color="text.secondary"
                >
                  <i>{props.item.getLatinName()}</i>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  textAlign="center"
                  color="text.secondary"
                >
                  {props.item.getCustom().commonName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions disableSpacing className={classes.browserItemTypeButtons}>
        {props.item.getCustom().sellAvailable ? (
          <ItemIcon plant={props.item} />
        ) : (
          <Typography textAlign="center" variant="body2">
            {t('pharmaco:productMetadatas.sellAvailable')}
          </Typography>
        )}
      </CardActions>

      {openInfo && (
        <InfoModal
          open={openInfo}
          handleClose={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenInfo(false);
          }}
          plant={props.item}
          type="plant"
        />
      )}
    </Card>
  );
}
