import { useContext } from 'react';
import {
  basicPostRequest,
  getBaseURL,
  runBasicFetch,
  runBasicFetchV2,
} from '../utils/api';
import { AuthContext } from '@kiway/shared/features/authentication-react';

const BASE_URL = `${getBaseURL()}/auth`;

export default function useAuthService() {
  const { setAuthState } = useContext(AuthContext);
  const login = ({ bypassEmailVerif, email, password, rememberMe }) => {
    return runBasicFetchV2(
      basicPostRequest(BASE_URL + '/login', {
        email,
        password,
        rememberMe,
        bypassEmailVerif,
      })
    ).then((data) => {
      if (data && data._id) {
        const { token, expiresAt, ...userInfo } = data;
        setAuthState({
          expiresAt: expiresAt || 'session',
          token,
          userInfo,
        });
        return data;
      }
      return data.json();
    });
  };
  return {
    login,
  };
}

export const resetPassword = (data) => {
  const url = `${BASE_URL}/reset/password`;
  return runBasicFetch(basicPostRequest(url, data));
};

export const resetPasswordCheck = (data) => {
  const url = `${BASE_URL}/reset/check`;
  return runBasicFetch(basicPostRequest(url, data));
};

export const resetPasswordRequest = (email) => {
  const url = `${BASE_URL}/reset`;
  return runBasicFetch(basicPostRequest(url, { email }));
};
