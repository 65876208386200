import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { useTranslation } from '@kiway/shared/utils/translation'

function SearchBox(props) {
  const [input, setInput] = useState('')
  const { t } = useTranslation()

  const filterOptions = createFilterOptions({
    stringify: (option) => props.getString(option),
  })

  const {
    data,
    getString,
    width,
    disabled,
    value,
    setValue,
    label,
    variant,
    margin,
    marginZero,
    noMargin,
    renderInput,
    inputProps,
    ...other
  } = props

  return (
    <Autocomplete
      options={data && Array.isArray(data) ? data : []}
      getOptionSelected={(option, value) =>
        value.id ? option._id === value.id : option._id === value._id
      }
      filterOptions={filterOptions}
      getOptionLabel={(option) => getString(option)}
      style={{
        width: width || 450,
        margin: marginZero ? '0 auto' : !noMargin ? '20px auto' : '8px auto',
      }}
      inputValue={input}
      disabled={disabled}
      onInputChange={(event, newInput) => {
        setInput(newInput !== '' ? newInput : getString(value))
      }}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue)
        setInput(getString(newValue))
      }}
      noOptionsText={t('common:noOptionsText')}
      renderInput={
        renderInput
          ? renderInput
          : (params) => (
            <TextField
              {...params}
              label={label}
              margin={margin}
              variant={variant || 'outlined'}
              fullWidth
              {...inputProps}
            />
            )
      }
      {...other}
    />
  )
}

export default SearchBox
