export default {
  appointments: {
    chiefComplaint: 'Razón de consulta',
    datatableTitle: 'Mis apuntes',
    date: 'Fecha',
    hour: 'Hora',
    patientNote: 'Notas de sesión',
    practitioner: 'Profissional',
    practitionerContact: 'Póngase en contacto con el practicante',
  },
  newPatient: 'Nuevo paciente',
};
