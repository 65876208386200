import { TaxMode, ITaxModeAttributes } from '../../core/entities/TaxMode';
import { TaxModeProvider } from '../../core/use_cases/TaxModeProvider';
import { gql, ApolloClient } from '@apollo/client';

export const LIST_TAXMODES = gql`
  query {
    listTaxModes {
      id
      name
description
      # Add here your attributes
    }
  }
`;

export type ITaxModeAttributesData = ITaxModeAttributes & {
  id: string;
};

export interface ListTaxModesData {
  listTaxModes: Array<ITaxModeAttributesData>;
}

export class TaxModeGraphQLProvider implements TaxModeProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async findAll(): Promise<TaxMode[]> {
    try {
      const result = await this.client?.query<ListTaxModesData>({
        query: LIST_TAXMODES,
      });
      return result.data.listTaxModes.map((item) => new TaxMode(item));
    } catch (e) {
      console.log(e);
    }
  }
  save(taxMode: TaxMode, userId?: string): Promise<TaxMode> {
    throw new Error('Method not implemented.');
  }
  editMany(entities: ITaxModeAttributes[], userId?: string): Promise<TaxMode[]> {
    throw new Error('Method not implemented.');
  }
}
