export default {
  bill: 'Facturer',
  billingInProgress: 'Facturation en cours...',
  dataTable: {
    columns: {
      actions: 'Actions',
      amount_due: 'Montant dû',
      createdAt: "Date d'émission",
      description: 'Description',
      number: 'Numéro',
      paid: 'Payée',
      patient: 'Patient',
      viewPDF: 'Facture PDF',
    },
    title: 'Liste des factures',
  },
  doYouHaveTaxes: 'Appliquez-vous une taxe ?',
  invoicingAlert:
    'Une fois facturé, vous ne pouvez plus revenir en arrière. Êtes-vous sûr de vouloir continuer ?',
  noReceiptData: "Désolé, il n'y a pas de reçu disponible pour ce paiement",
  paidAt: 'Payé le',
  patientEmailRequired:
    "L'email du patient est obligatoire pour pouvoir envoyer le lien de paiement",
  pay: 'Payer',
  payInvoice: 'Payer la facture',
  payment: {
    checkout: {
      cancel: {
        text:
          'Oups, vous êtes parti un peu vite... Nous vous invitons à renouveler la requête afin de procéder au paiement.',
        title: 'Paiement de votre facture annulé',
      },
      redirectPage: {
        badStatus: 'Votre facture semble déjà payée.',
        noConnectedAccount:
          'Le module de paiement rencontre un problème de paramétrage, merci de contacter votre praticien.',
        redirecting: 'Vous allez être redirigé...',
        title: 'Paiement de votre facture',
        unknownError:
          'Une erreur est survenue, merci de contacter votre praticien.',
      },
      success: {
        text:
          "Merci pour votre paiement, vous pouvez désormais retourner sur l'application.",
        title: 'Paiement de votre facture réussi',
      },
    },
    connectWithStripeText:
      "Pour compléter l'activation du service \"Paiement en ligne\", vous devez créer ou connecter un compte Stripe. Ce compte vous permettra de recevoir et de retirer vos fonds. Vous n'avez qu'à cliquer sur le bouton ci-dessous et vous serez redirigé sur la plateforme Stripe.",
  },
  paymentDate: 'Date de paiement',
  paymentMethod: {
    card: 'Carte bancaire',
    cash: 'Espèces',
    check: 'Chèque',
    kiway: 'Paiement en ligne',
    label: 'Moyen de paiement',
    title: 'Autres moyens de paiement',
  },
  paymentRef: 'Référence de paiement',
  paymentSaved: 'Paiement ajouté avec succès',
  payOnline: 'Payer en ligne',
  preview: 'Prévisualisation :',
  receiptModalTitle: 'Reçu de paiement',
  receiptToString:
    'Facture payée le %paymentDate% par "%paymentMethod%", référence du paiement : %paymentRef%',
  savePayment: 'Enregistrer le paiement',
  scanMeToPay: 'Scannez-moi pour payer avec votre smartphone',
  seeInvoice: 'Voir la facture',
  seeReceipt: 'Voir le reçu du paiement',
  sendPaymentLink:
    'Voulez-vous envoyer un lien de paiement en ligne par mail au patient ?',
  sessionInvoicing: 'Facturation de la séance',
  sessionInvoicingConfirm: 'Confirmation de la finalisation de la facture',
  sessionToBill: 'Acte à facturer :',
  subTotal: 'Sous-total :',
  successInvoiceFinalized: 'La facture a bien été émise',
  taxes: {
    country: "Pays d'application de la taxe",
    modes: {
      excl: 'Oui, elle est exclue du prix ci-dessus',
      incl: 'Oui, elle est incluse au prix ci-dessus',
      no: 'Non',
    },
    name: 'Nom de la taxe',
    percent: 'Montant de la taxe (pourcentage)',
    percentLimits: 'Entre 0 et 100',
    render: 'Taxe appliquée :',
  },
  toBill: 'A facturer',
  total: 'Total :',
  youHaveToActivateStripe:
    'Vous devez activer votre compte Stripe pour pouvoir utiliser les paiements en ligne',
};
