import * as React from 'react';
import { RefreshToken } from '../../../core/use_cases/UserProvider';
import { useUsersGateway } from '../../../entrypoints/react/useUsersGateway';
import { ListRefreshTokens } from './ListRefreshTokens';

export function ListRefreshTokensController() {
  const {
    listRefreshTokens,
    deleteAllRefreshTokens,
    deleteRefreshToken,
  } = useUsersGateway();
  const [tokens, setTokens] = React.useState([]);
  const deleteCallback = (result: Array<RefreshToken>) => {
    if (result) {
      setTokens(result);
    }
  };
  React.useEffect(() => {
    listRefreshTokens().then((refreshTokens) => {
      if (refreshTokens) {
        setTokens(refreshTokens);
      }
    });
    return () => {
      setTokens([]);
    };
  }, []);
  return (
    <ListRefreshTokens
      tokens={tokens}
      deleteToken={(tokenId: string) => {
        deleteRefreshToken(tokenId).then(deleteCallback);
      }}
      deleteAllTokens={() => {
        deleteAllRefreshTokens().then(deleteCallback);
      }}
    />
  );
}
