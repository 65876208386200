/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import { Order } from '@kiway/ecommerce-react-compatible';
import { PharmacoOrder } from '../../../core/entities/PharmacoOrder';
import { AvailableStep } from '../OrderStepper';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Select } from '@kiway/shared/ui';

export interface ResumeLineProps {
  label: string;
  value: string | any;
}

const ResumeLine = (props: ResumeLineProps) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={6}>
        {props.label}
      </Grid>
      <Grid
        item
        xs={6}
        textAlign={typeof props.value === 'string' ? 'right' : undefined}
      >
        {props.value}
      </Grid>
    </Grid>
  );
};

export interface PrescriptionResumeProps {
  canDoNext?: () => boolean;
  canDoPrev?: () => boolean;
  currentStep?: AvailableStep;
  order: PharmacoOrder;
  realOrder: Order;
  handleClickNext: any;
  handleClickPrev?: any;
  reasonCantGoToCommentSection?: string;
  reasonCantValidateOrder?: string;
  handleChangeLoyaltyProgramOption?: (value: string) => void;
  loyaltyPointsBalance?: number;
}

export function PrescriptionResume(props: PrescriptionResumeProps) {
  const { t } = useTranslation();
  const loyaltyOptions = props.order
    ?.getOrder()
    ?.getAvailableLoyaltyProgramOptions(props.loyaltyPointsBalance);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card elevation={8}>
          <CardHeader title={t('pharmaco:orders.orderResume')} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <ResumeLine
                      label={t('pharmaco:prescription.resume.cartGrossTotal')}
                      value={props.realOrder
                        ?.getTaxedTotalPrice()
                        ?.totalGross?.toString()}
                    />
                  </Grid>
                  {props.currentStep === 'shipping' && (
                    <Grid item xs={12}>
                      <ResumeLine
                        label={t('pharmaco:prescription.resume.cartNetTotal')}
                        //   props.realOrder?.getShippingMethod()
                        //     ?
                        //     : t('pharmaco:prescription.resume.totalNetPriceWT')
                        // }
                        value={props.realOrder
                          ?.getTaxedTotalPrice()
                          ?.totalNet?.toString()}
                      />
                    </Grid>
                  )}
                  {props.currentStep === 'shipping' && (
                    <Grid item xs={12}>
                      <ResumeLine
                        label={t(
                          'pharmaco:prescription.resume.loyaltyOption.label'
                        )}
                        value={
                          props.handleChangeLoyaltyProgramOption ? (
                            <Select
                              onChange={(e) => {
                                props.handleChangeLoyaltyProgramOption(
                                  e.target.value
                                );
                              }}
                              margin="dense"
                              options={
                                loyaltyOptions?.map((option) => ({
                                  label: t(
                                    `pharmaco:prescription.resume.loyaltyOption.${option}`
                                  ),
                                  value: option,
                                })) ?? []
                              }
                              size="small"
                              value={props.order
                                ?.getOrder()
                                ?.getLoyaltyProgramOption()}
                            />
                          ) : (
                            t(
                              `pharmaco:prescription.resume.loyaltyOption.${props.order
                                ?.getOrder()
                                ?.getLoyaltyProgramOption()}`
                            )
                          )
                        }
                      />
                    </Grid>
                  )}
                  {props.currentStep === 'shipping' &&
                    props.order?.getOrder()?.getLoyaltyProgramOption() !==
                      'addPoints' &&
                    loyaltyOptions?.includes(
                      props.order?.getOrder()?.getLoyaltyProgramOption()
                    ) && (
                      <Grid item xs={12}>
                        <ResumeLine
                          label={''}
                          value={`-${props.order
                            ?.getOrder()
                            ?.getDiscountAmount('price')
                            ?.toString()}`}
                        />
                      </Grid>
                    )}
                  {props.currentStep === 'shipping' &&
                    props.realOrder?.getShippingMethod() && (
                      <Grid item xs={12}>
                        <ResumeLine
                          label={t(
                            'pharmaco:orderDetails.shipment.transporter.label'
                          )}
                          value={props.realOrder
                            ?.getShippingMethod()
                            ?.getPrice(
                              props.order
                                ?.getOrder()
                                ?.getShippingAddress()
                                ?.getCountryCode(),
                              props.order?.getOrder()?.getTotalWeight()
                            )
                            ?.toString()}
                        />
                      </Grid>
                    )}
                  {props.currentStep === 'shipping' && (
                    <Grid item xs={12}>
                      <ResumeLine
                        label={t('pharmaco:prescription.resume.totalToPay')}
                        value={props.realOrder?.getTotalPrice()?.toString()}
                      />
                    </Grid>
                  )}
                  {props.currentStep !== 'shipping' && (
                    <Grid item xs={12}>
                      <Typography sx={{ mt: 1, fontStyle: 'italic' }}>
                        {t(
                          'pharmaco:prescription.resume.missingCostsExplanation'
                        )}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} textAlign="right">
                {props.handleClickPrev && (
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={props.handleClickPrev}
                    disabled={props.canDoPrev ? !props.canDoPrev() : undefined}
                    sx={{ mr: 1 }}
                  >
                    {t('pharmaco:previous')}
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="minoritary"
                  onClick={props.handleClickNext}
                  disabled={
                    props.canDoNext
                      ? props.currentStep === 'shipping'
                        ? !(
                            props.canDoNext() &&
                            loyaltyOptions?.includes(
                              props.order?.getOrder()?.getLoyaltyProgramOption()
                            )
                          )
                        : !props.canDoNext()
                      : undefined
                  }
                >
                  {props.currentStep !== 'shipping'
                    ? t('pharmaco:next')
                    : t('pharmaco:validate')}
                </Button>
                {props.currentStep !== 'shipping' ? (
                  !props.canDoNext?.() && props.reasonCantGoToCommentSection ? (
                    <Typography
                      color="error.main"
                      sx={{ mt: 1, fontStyle: 'italic' }}
                    >
                      {t(
                        `pharmaco:prescription.reasonCantGoToCommentSection.${props.reasonCantGoToCommentSection}`
                      )}
                    </Typography>
                  ) : null
                ) : !props.canDoNext?.() ||
                  !loyaltyOptions?.includes(
                    props.order?.getOrder()?.getLoyaltyProgramOption()
                  ) ? (
                  <Typography
                    color="error.main"
                    sx={{ mt: 1, fontStyle: 'italic' }}
                  >
                    {t(
                      `pharmaco:prescription.reasonCantValidateOrder.${
                        props.reasonCantValidateOrder ?? 'loyaltyProgramError'
                      }`
                    )}
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
