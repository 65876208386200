import {
  ApolloClient,
  InMemoryCache,
  split,
  HttpLink,
  from,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';

const baseApiUrl = process.env['NX_API_URL'] || 'api.kiway.co';

const wsLink = new WebSocketLink({
  uri: `wss://${baseApiUrl}/graphql`,
  options: {
    reconnect: true,
    connectionParams: {
      credentials: 'same-origin',
    },
  },
});

const httpLink = new HttpLink({
  uri: `https://${baseApiUrl}/graphql`,
  credentials: 'include',
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (message.includes('Unauthenticated or token expired')) {
        window.location.reload();
      }
    });
  }
});

/**
 * The split function allows to use independently http et ws links
 * It takes three parameters:
 *
 * - A function that's called for each operation to execute
 * - The Link to use for an operation if the function returns a "truthy" value
 * - The Link to use for an operation if the function returns a "falsy" value
 */
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  from([errorLink, httpLink])
);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    NextPolicy: 'cache',
  },
};

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Slots: {
        keyFields: ['day'],
        fields: {
          slots: {
            merge: false,
          },
        },
      },
      Query: {
        fields: {
          appointments: {
            merge: false,
          },
        },
      },
    },
  }),
  credentials: 'include',
  defaultOptions,
});

export default client;
