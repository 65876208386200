export default {
  appointment:
    'Uma nova nova marcação foi efetuada online. Clique na notificação para visualizar.',
  none: 'Nenhuma nova notificação',
  release: 'A versão **{{version}}** agora está disponível',
  stripeConnect: {
    created:
      'A sua conta Stripe foi conectada! Agora você deve validá-la para usar o pagamento online. Você deve ter recebido um email do Stripe, basta seguir as instruções nele contidas.',
    validated:
      'Parabéns, sua conta Stripe foi validada! Agora você pode usar o pagamento online.',
  },
};
