import * as React from 'react';
import { ShippingMethodPrice } from '../../../core/entities/ShippingMethodPrice';
import { Price } from '../../../core/entities/Order';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DatatablesController } from '@kiway/datatables';
import { useTranslation } from '@kiway/shared/utils/translation';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';
import { deepen } from '@kiway/shared/utils/string';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DropdownValue } from '@kiway/datatables';
import { Grid } from '@mui/material';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Select } from '@kiway/shared/ui';
import { ShippingMethod } from '../../../core/entities/ShippingMethod';

export interface ListShippingMethodPricesProps {
  availableShippingMethods: DropdownValue[];
  shippingMethodPrices: ShippingMethodPrice[];
  shippinMethodsRaw: Array<ShippingMethod>;
  filterShippingMethods: string;
  setFilterShippingMethods: React.Dispatch<React.SetStateAction<string>>;
  handleAddData: () => void;
  handleClearAdd: () => void;
  handleSave?: any;
  loading?: boolean;
}

const prepareToSave = (item) => {
  return {
    ...deepen(item),
    id: item._id ?? item.id,
    _id: undefined,
    price: new Price(item.price).toInput(true, true),
  };
};

export function ListShippingMethodPrices(
  props: React.PropsWithChildren<ListShippingMethodPricesProps>
) {
  const { t, currentLanguage } = useTranslation();
  const [isInEditingMode, setIsInEditingMode] = React.useState<boolean>(false);

  return (
    <div>
      <Grid item xs={12} sm={4} sx={{ maxWidth: '300px' }}>
        <Select
          label={t('pharmaco:filters.shippingMethods.label')}
          disabled={isInEditingMode}
          options={[
            { label: t('pharmaco:filters.shippingMethods.all'), value: 'ALL' },
            ...props.shippinMethodsRaw?.map((value) => ({
              label: value?.getName()?.[
                currentLanguage?.code ?? getFallbackLanguage()
              ],
              value: value?.getId(),
            })),
          ]}
          margin="dense"
          size="small"
          onChange={(e) => {
            props.setFilterShippingMethods(e.target.value as string | 'ALL');
          }}
          value={props.filterShippingMethods as string}
        />
      </Grid>
      <DatatablesController
        bulkEdit={true}
        handleAddData={props.handleAddData}
        handleClearAdd={props.handleClearAdd}
        handleSaveData={
          props.handleSave
            ? (updates) => {
                if (props.handleSave) {
                  return props.handleSave(
                    updates.updatedData.map(prepareToSave)
                  );
                }
              }
            : undefined
        }
        columns={[
          {
            name: '_id',
            label: 'ID',
            type: 'text',
            options: {
              display: false,
              edit: false,
              filter: false,
            },
          },
          {
            name: 'shippingMethod',
            label: t(`pharmaco:shippingMethodPrices.shippingMethod`),
            type: 'dropdown',
            options: {
              availableValues: props.availableShippingMethods,
            },
          },
          {
            name: 'minWeight',
            label: t(`pharmaco:shippingMethodPrices.minWeight`),
            type: 'number',
          },
          {
            name: 'maxWeight',
            label: t(`pharmaco:shippingMethodPrices.maxWeight`),
            type: 'number',
          },
          {
            name: 'price',
            label: t(`pharmaco:shippingMethodPrices.price`),
            type: 'price',
            options: {
              priceDisableCentDecimals: true,
            },
          },
        ]}
        data={props.shippingMethodPrices?.map((price) =>
          price.toDatatableRow()
        )}
        defaultSortOrder={{ name: '_id', direction: 'asc' }}
        id="listShippingMethodPrices"
        loading={props.loading}
        options={{
          download: false,
          filter: false,
          print: false,
        }}
        setIsInEditingMode={setIsInEditingMode}
        title={t(`pharmaco:shippingMethodPrices.linkText`)}
      />
    </div>
  );
}
