import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TypographyProps,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { truncate } from '@kiway/shared/utils/string';

const MAX_LENGTH = 70;

export interface AccordionItemProps {
  bgColor?: string;
  expanded: boolean;
  handleChange?: (...args: any[]) => any;
  hideExpandIcon?: boolean;
  hideSecondaryLabelWhenExpanded?: boolean;
  label: string;
  labelVariant?: TypographyProps['variant'];
  labelWidth?: string;
  maxSecondaryLength?: number; // Default 70
  secondaryLabel?: string;
}

export function AccordionItem(
  props: React.PropsWithChildren<AccordionItemProps>
) {
  return (
    <Accordion
      expanded={props.expanded}
      elevation={2}
      onChange={props.handleChange}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{ visibility: props.hideExpandIcon ? 'hidden' : 'visible' }}
          />
        }
        aria-controls="therapist-bh-content"
        id="therapist-bh-header"
        style={{ backgroundColor: props.bgColor }}
      >
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography
            variant={props.labelVariant}
            sx={{ width: props.labelWidth ?? '33%', flexShrink: 0 }}
          >
            {props.label}
          </Typography>
          {props.secondaryLabel &&
            (!props.hideSecondaryLabelWhenExpanded || !props.expanded) && (
              <Typography sx={{ color: 'text.secondary' }}>
                {truncate(
                  props.secondaryLabel,
                  props.maxSecondaryLength ?? MAX_LENGTH
                )}
              </Typography>
            )}
        </div>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
}
