import fr from './translations/fr';
import en from './translations/en';
import pt from './translations/pt';
import es from './translations/es';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EcoIcon from '@material-ui/icons/Eco';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  FormulaDetailsController,
  ListFormulasController,
  ListPlantsController,
  ListProductMetadatasController,
  ListProductVariationTypesController,
  PlantDetailsController,
  PrescriptionDetailsController,
  ShippingDetailsController,
  CheckoutController,
  ListOrdersController,
  OrderDetailsController,
  OrderPreparationController,
  OrderValidationController,
  OrderShipmentController,
  OrderCommentsController,
  DashboardController,
} from './ui/components';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  ListCarriersController,
  ListCountryZonesController,
  ListShippingMethodPricesController,
  ListShippingMethodsController,
  ListCountriesController,
  ListPaymentsController,
} from '@kiway/ecommerce-react';

const namespace = 'pharmaco';

export const links = {
  main: 'pharmaco',
  dashboard: 'dashboard',
  plants: 'plants',
  formulas: 'formulas',
  actions: 'actions',
  categories: 'categories',
  meridians: 'meridians',
  natures: 'natures',
  productMetadata: 'metadatas',
  productVariationType: 'variations',
  savours: 'savours',
  prescription: 'prescription',
  shipping: 'shipping',
  resume: 'resume',
  checkout: 'checkout',
  details: 'details',
  listOrders: 'orders',
  wholesalerSettings: 'settings',
  orderPreparation: 'preparation',
  orderShipment: 'shipment',
  orderComments: 'comments',
  listPayments: 'payments',
  listCarriers: 'carriers',
  listCountries: 'countries',
  listCountryZones: 'countryZones',
  listShippingMethods: 'shippingMethods',
  listShippingMethodPrices: 'shippingMethodPrices',
  getPlantDetailsById: (id: string) => `/${links.main}/${links.plants}/${id}`,
  getFormulaDetailsById: (id: string) =>
    `/${links.main}/${links.formulas}/${id}`,
  getOrderPrescriptionScreen: (id: string) =>
    `/${links.main}/${links.listOrders}/${id}/${links.prescription}`,
  getOrderShippingScreen: (id: string) =>
    `/${links.main}/${links.listOrders}/${id}/${links.shipping}`,
  getOrderResumeScreen: (id: string) =>
    `/${links.main}/${links.listOrders}/${id}/${links.resume}`,
  getOrderCheckoutScreen: (id: string) =>
    `/${links.main}/${links.listOrders}/${id}/${links.checkout}`,
  getOrderDetailsScreen: (id: string) =>
    `/${links.main}/${links.listOrders}/${id}/${links.details}`,
  getOrderPreparationScreen: (id: string): string =>
    `/${links.main}/${links.listOrders}/${id}/${links.orderPreparation}`,
  getOrderShipmentScreen: (id: string): string =>
    `/${links.main}/${links.listOrders}/${id}/${links.orderShipment}`,
  getOrderCommentsScreen: (id: string): string =>
    `/${links.main}/${links.listOrders}/${id}/${links.orderComments}`,
};

export const plantPowderTypes = {
  label: 'pharmaco:prescription.plantPowderType.label',
  data: [
    { label: 'pharmaco:prescription.plantPowderType.plant', value: 'plant' },
    { label: 'pharmaco:prescription.plantPowderType.powder', value: 'powder' },
  ],
};

export const pharmaceuticalForms = {
  label: 'pharmaco:prescription.pharmaceuticalForm.label',
  data: [
    {
      label: 'pharmaco:prescription.pharmaceuticalForm.small_bag',
      value: 'small_bag',
    },
    {
      label: 'pharmaco:prescription.pharmaceuticalForm.capsule',
      value: 'capsule',
    },
  ],
};

export const plantPowderGroundSizes = {
  label: 'pharmaco:prescription.plantPowderGroundSize.label',
  data: [
    {
      label: 'pharmaco:prescription.plantPowderGroundSize.whole',
      value: 'whole',
    },
    {
      label: 'pharmaco:prescription.plantPowderGroundSize.big_ground',
      value: 'big_ground',
    },
    {
      label: 'pharmaco:prescription.plantPowderGroundSize.fine_ground',
      value: 'fine_ground',
    },
  ],
};

export const posologyPlantSmallBagsWeightModes = {
  label: 'pharmaco:prescription.weightMode.label',
  data: [
    { label: 'pharmaco:prescription.weightMode.total', value: 'total' },
    { label: 'pharmaco:prescription.weightMode.portion', value: 'portion' },
  ],
};

export default {
  routes: {
    admin: [
      {
        id: 'dashboard',
        path: `/${links.dashboard}`,
        subtitle: 'Dashboard',
        exact: false,
        private: true,
        component: DashboardController,
        appShell: true,
        role: 'ROLE_USER',
      },
      {
        id: 'plant_details',
        path: `/${links.main}/${links.plants}/:id`,
        subtitle: 'pharmaco:plant.detailsDocSubtitle',
        exact: false,
        private: true,
        component: PlantDetailsController,
        parseMatchParams: (matchParams: any) => ({ id: matchParams?.id }),
        appShell: true,
        role: 'ROLE_ADMIN_WHOLESALER',
      },
      {
        id: 'list_plants',
        path: `/${links.main}/${links.plants}`,
        subtitle: 'pharmaco:list.docSubtitle',
        exact: false,
        private: true,
        component: ListPlantsController,
        appShell: true,
        role: 'ROLE_ADMIN_WHOLESALER',
      },
      {
        id: 'formula_details',
        path: `/${links.main}/${links.formulas}/:id`,
        subtitle: 'pharmaco:formula.detailsDocSubtitle',
        exact: false,
        private: true,
        component: FormulaDetailsController,
        parseMatchParams: (matchParams: any) => ({ id: matchParams?.id }),
        appShell: true,
        role: 'ROLE_ADMIN_WHOLESALER',
      },
      {
        id: 'list_formulas',
        path: `/${links.main}/${links.formulas}`,
        subtitle: 'pharmaco:formula.docSubtitle',
        exact: false,
        private: true,
        component: ListFormulasController,
        appShell: true,
        role: 'ROLE_ADMIN_WHOLESALER',
      },
      {
        id: 'productMetadata',
        path: `/${links.main}/${links.productMetadata}`,
        subtitle: 'pharmaco:productMetadatas.docSubtitle',
        exact: false,
        private: true,
        component: ListProductMetadatasController,
        appShell: true,
        role: 'ROLE_ADMIN_WHOLESALER',
      },
      {
        id: 'productVariationTypes',
        path: `/${links.main}/${links.productVariationType}`,
        subtitle: 'pharmaco:productMetadatas.productVariationType.docSubtitle',
        exact: false,
        private: true,
        component: ListProductVariationTypesController,
        appShell: true,
        role: 'ROLE_ADMIN_WHOLESALER',
      },
      {
        id: 'listOrders',
        path: `/${links.main}/${links.listOrders}`,
        subtitle: `pharmaco:orders.docSubtitle`,
        exact: true,
        private: true,
        component: ListOrdersController,
        appShell: true,
        role: 'ROLE_ORDER_PICKER',
      },
      {
        id: 'orderDetails',
        path: `/${links.main}/${links.listOrders}/:id/${links.details}`,
        subtitle: `pharmaco:orderDetails.docSubtitle`,
        exact: false,
        private: true,
        component: OrderDetailsController,
        parseMatchParams: (matchParams: any) => ({ id: matchParams?.id }),
        appShell: true,
        hideAppShellOnMobile: true,
        role: 'ROLE_ORDER_PICKER',
      },
      {
        id: 'orderPreparation',
        path: `/${links.main}/${links.listOrders}/:id/${links.orderPreparation}`,
        subtitle: `${namespace}:orderDetails.packing.label`,
        exact: false,
        private: true,
        component: OrderPreparationController,
        parseMatchParams: (matchParams: any) => ({ id: matchParams?.id }),
        appShell: true,
        hideAppShellOnMobile: true,
        role: 'ROLE_ORDER_PICKER',
      },
      {
        id: 'orderShipment',
        path: `/${links.main}/${links.listOrders}/:id/${links.orderShipment}`,
        subtitle: `${namespace}:orderShipment.docSubtitle`,
        exact: false,
        private: true,
        component: OrderShipmentController,
        parseMatchParams: (matchParams: any) => ({ id: matchParams?.id }),
        appShell: true,
        hideAppShellOnMobile: true,
        role: 'ROLE_ORDER_PICKER',
      },
      {
        id: 'listPayments',
        path: `/${links.main}/${links.listPayments}`,
        subtitle: `${namespace}:orderDetails.payment.docSubtitle`,
        exact: false,
        private: true,
        component: ListPaymentsController,
        appShell: true,
        role: 'ROLE_ADMIN_WHOLESALER',
      },
      {
        id: 'listCarriers',
        path: `/${links.main}/${links.wholesalerSettings}/${links.listCarriers}`,
        subtitle: `${namespace}:carriers.docSubtitle`,
        exact: false,
        private: true,
        component: ListCarriersController,
        appShell: true,
        role: 'ROLE_ADMIN_WHOLESALER',
      },
      {
        id: 'listCountries',
        path: `/${links.main}/${links.wholesalerSettings}/${links.listCountries}`,
        subtitle: `${namespace}:countries.docSubtitle`,
        exact: false,
        private: true,
        component: ListCountriesController,
        appShell: true,
        role: 'ROLE_ADMIN_WHOLESALER',
      },
      {
        id: 'listCountryZones',
        path: `/${links.main}/${links.wholesalerSettings}/${links.listCountryZones}`,
        subtitle: `${namespace}:countryZones.docSubtitle`,
        exact: false,
        private: true,
        component: ListCountryZonesController,
        appShell: true,
        role: 'ROLE_ADMIN_WHOLESALER',
      },
      {
        id: 'listShippingMethods',
        path: `/${links.main}/${links.wholesalerSettings}/${links.listShippingMethods}`,
        subtitle: `${namespace}:shippingMethods.docSubtitle`,
        exact: false,
        private: true,
        component: ListShippingMethodsController,
        appShell: true,
        role: 'ROLE_ADMIN_WHOLESALER',
      },
      {
        id: 'listShippingMethodPrices',
        path: `/${links.main}/${links.wholesalerSettings}/${links.listShippingMethodPrices}`,
        subtitle: `${namespace}:shippingMethodPrices.docSubtitle`,
        exact: false,
        private: true,
        component: ListShippingMethodPricesController,
        appShell: true,
        role: 'ROLE_ADMIN_WHOLESALER',
      },
    ],
    client: [
      {
        id: 'listOrders',
        path: `/${links.main}/${links.listOrders}`,
        subtitle: `pharmaco:orders.docSubtitle`,
        exact: true,
        private: true,
        component: ListOrdersController,
        appShell: true,
        role: 'ROLE_DISPLAY_LIST_ORDERS',
      },
      {
        id: 'prescriptionDetails',
        path: links.getOrderPrescriptionScreen(':id'),
        subtitle: 'pharmaco:prescription.docSubtitle',
        exact: false,
        private: true,
        component: PrescriptionDetailsController,
        parseMatchParams: (matchParams: any) => ({ id: matchParams?.id }),
        appShell: true,
        role: 'ROLE_PRACTITIONER_PHARMACO',
      },
      {
        id: 'comments',
        path: links.getOrderCommentsScreen(':id'),
        subtitle: 'pharmaco:prescription.docSubtitle',
        exact: false,
        private: true,
        component: OrderCommentsController,
        parseMatchParams: (matchParams: any) => ({ id: matchParams?.id }),
        appShell: true,
        role: 'ROLE_PRACTITIONER_PHARMACO',
      },
      {
        id: 'shipping',
        path: links.getOrderShippingScreen(':id'),
        subtitle: 'pharmaco:prescription.docSubtitle',
        exact: false,
        private: true,
        component: ShippingDetailsController,
        parseMatchParams: (matchParams: any) => ({ id: matchParams?.id }),
        appShell: true,
        role: 'ROLE_PRACTITIONER_PHARMACO',
      },
      {
        id: 'resume',
        path: links.getOrderResumeScreen(':id'),
        subtitle: 'pharmaco:prescription.docSubtitle',
        exact: false,
        private: true,
        component: OrderValidationController,
        parseMatchParams: (matchParams: any) => ({ id: matchParams?.id }),
        appShell: true,
        role: 'ROLE_PRACTITIONER_PHARMACO',
      },
      {
        id: 'checkout',
        path: links.getOrderCheckoutScreen(':id'),
        subtitle: 'pharmaco:prescription.docSubtitle',
        exact: false,
        private: false,
        component: CheckoutController,
        parseMatchParams: (matchParams: any) => ({ id: matchParams?.id }),
        appShell: false,
        role: 'ROLE_PRACTITIONER_PHARMACO',
      },
      {
        id: 'orderDetails',
        path: `/${links.main}/${links.listOrders}/:id/${links.details}`,
        subtitle: `pharmaco:orderDetails.docSubtitle`,
        exact: false,
        private: true,
        component: OrderDetailsController,
        parseMatchParams: (matchParams: any) => ({ id: matchParams?.id }),
        appShell: true,
        role: 'ROLE_DISPLAY_LIST_ORDERS',
      },
    ],
  },
  menus: {
    admin: {
      main: [
        {
          id: 'dashboard',
          link: `${links.dashboard}`,
          text: 'Dashboard',
          display: true,
          role: 'ROLE_ADMIN_WHOLESALER',
          icon: DashboardIcon,
        },
        {
          id: 'pharmaco',
          link: `${links.main}/${links.plants}`,
          text: 'pharmaco:linkText',
          display: true,
          role: 'ROLE_ADMIN_WHOLESALER',
          icon: EcoIcon,
          subItems: [
            {
              id: 'list-plants',
              text: 'pharmaco:list.linkText',
              link: `${links.main}/${links.plants}`,
              display: true,
              role: 'ROLE_ADMIN_WHOLESALER',
              order: 1,
            },
            {
              id: 'list-formulas',
              text: 'pharmaco:formula.linkText',
              link: `${links.main}/${links.formulas}`,
              display: true,
              role: 'ROLE_ADMIN_WHOLESALER',
              order: 1,
            },
            {
              id: 'list-product-metadata',
              text: 'pharmaco:productMetadatas.linkText',
              link: `${links.main}/${links.productMetadata}`,
              display: true,
              role: 'ROLE_ADMIN_WHOLESALER',
              order: 2,
            },
            {
              id: 'list-product-variation-types',
              text: 'pharmaco:productMetadatas.productVariationType.linkText',
              link: `${links.main}/${links.productVariationType}`,
              display: true,
              role: 'ROLE_ADMIN_WHOLESALER',
              order: 3,
            },
          ],
        },
        {
          id: 'ecommerce',
          link: `${links.main}/${links.listOrders}`,
          text: `pharmaco:orders.linkText`,
          display: true,
          role: 'ROLE_ORDER_PICKER',
          icon: ShoppingCartIcon,
          subItems: [
            {
              id: 'list-orders',
              text: `pharmaco:orders.linkText`,
              link: `${links.main}/${links.listOrders}`,
              display: true,
              role: 'ROLE_ORDER_PICKER',
              order: 1,
            },
            {
              id: 'list-payments',
              text: `pharmaco:orderDetails.payment.linkText`,
              link: `${links.main}/${links.listPayments}`,
              display: true,
              role: 'ROLE_ADMIN_WHOLESALER',
              order: 2,
            },
            {
              id: 'order-details',
              text: `pharmaco:orders.linkText`,
              link: `${links.getOrderDetailsScreen(':id')}`,
              display: false,
              role: 'ROLE_ORDER_PICKER',
              order: 1,
            },
          ],
        },
        {
          id: 'wholesaler-settings',
          link: `${links.main}/${links.wholesalerSettings}/${links.listCarriers}`,
          text: `pharmaco:wholesalerSettings.linkText`,
          display: true,
          role: 'ROLE_ADMIN_WHOLESALER',
          icon: SettingsIcon,
          subItems: [
            {
              id: 'list-carriers',
              text: `pharmaco:carriers.linkText`,
              link: `${links.main}/${links.wholesalerSettings}/${links.listCarriers}`,
              display: true,
              role: 'ROLE_ADMIN_WHOLESALER',
              order: 2,
            },
            {
              id: 'list-countries',
              text: `pharmaco:countries.linkText`,
              link: `${links.main}/${links.wholesalerSettings}/${links.listCountries}`,
              display: true,
              role: 'ROLE_ADMIN_WHOLESALER',
              order: 3,
            },
            {
              id: 'list-countryZones',
              text: `pharmaco:countryZones.linkText`,
              link: `${links.main}/${links.wholesalerSettings}/${links.listCountryZones}`,
              display: true,
              role: 'ROLE_ADMIN_WHOLESALER',
              order: 4,
            },
            {
              id: 'list-shippingMethods',
              text: `pharmaco:shippingMethods.linkText`,
              link: `${links.main}/${links.wholesalerSettings}/${links.listShippingMethods}`,
              display: true,
              role: 'ROLE_ADMIN_WHOLESALER',
              order: 5,
            },
            {
              id: 'list-shippingMethodPrices',
              text: `pharmaco:shippingMethodPrices.linkText`,
              link: `${links.main}/${links.wholesalerSettings}/${links.listShippingMethodPrices}`,
              display: true,
              role: 'ROLE_ADMIN_WHOLESALER',
              order: 6,
            },
          ],
        },
      ],
    },
    client: {
      main: [
        {
          id: 'ecommerce',
          link: `${links.main}/${links.listOrders}`,
          text: `pharmaco:orders.linkText`,
          display: true,
          role: 'ROLE_DISPLAY_LIST_ORDERS',
          icon: ShoppingCartIcon,
          order: 2,
          subItems: [
            {
              id: 'list-orders',
              text: `pharmaco:orders.linkText`,
              link: `${links.main}/${links.listOrders}`,
              display: true,
              role: 'ROLE_DISPLAY_LIST_ORDERS',
              order: 1,
            },
            {
              id: 'order-details',
              text: `pharmaco:orders.linkText`,
              link: `${links.getOrderDetailsScreen(':id')}`,
              display: false,
              role: 'ROLE_DISPLAY_LIST_ORDERS',
              order: 2,
            },
          ],
        },
      ],
    },
  },
  namespace,
  translations: {
    fr,
    en,
    pt,
    es,
  },
};
