export default {
  city: 'Ville',
  country: 'Pays',
  email: 'Mail',
  firstName: 'Prénom',
  helperTextNif: "Numéro d'identification fiscale",
  lastName: 'Nom',
  line0: 'Etage, couloir, escalier, appartement',
  line1: 'Entrée, bâtiment, immeuble, résidence',
  line2: 'Numéro et libellé de voie',
  line3: 'Lieu-dit ou autre mention',
  mobilePhone: 'Portable',
  nif: 'NIF',
  zipCode: 'Code postal',
};
