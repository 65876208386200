import * as React from 'react';
import { Switch, Tooltip, Typography } from '@material-ui/core';
import {
  NotifierSettingLineProps,
  NotifierSettingsProps,
  NotifierType,
  notifierTypes,
} from './types';
import { useQuery, useMutation } from '@apollo/client';
import {
  LIST_NOTIFIERS,
  ListNotifiersData,
  LIST_MY_NOTIFIERS_SETTINGS,
  ListMyNotifiersSettingsData,
} from './queries';
import {
  EDIT_MY_NOTIFIER_SETTING,
  EditMyNotifierSettingData,
} from './mutations';
import { useTranslation } from '@kiway/shared/utils/translation';
import { KiwayLanguagesType } from '@kiway/shared/react-types';
import { MenuBuilder } from '@kiway/menu-builder';
import { EditNotifierSettingInputArgs } from '@kiway/shared/features/notifier';
import { useSnackbar } from '@kiway/shared/utils/snackbar';
import { firstUpperCase } from '@kiway/shared/utils/string';
import { getTranslations } from './translations';
import { RoutingBuilder } from '@kiway/routing-builder';
import InfoIcon from '@material-ui/icons/Info';
import { Paper } from '@mui/material';

const defaultNotifierSettingLineProps = {
  labelVariant: 'body1',
};

const defaultNotifierSettingsProps = {
  headerVariant: 'h6',
  initialValues: {},
  lines: [],
};

const notifierSettingLineContainer: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '16px',
  boxSizing: 'border-box',
};
const notifierSettingLineLabel: React.CSSProperties = {
  flex: 2,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};
const notifierSettingLineItem: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export function NotifierSettingLine(props: NotifierSettingLineProps) {
  const {
    availableTypes,
    description,
    handleChange,
    label,
    labelVariant,
    slug,
    values,
  } = props;
  const { addTranslationDynamically, t } = useTranslation();
  Object.entries(label).map(([lng, translation]) => {
    addTranslationDynamically(
      lng as KiwayLanguagesType,
      'notifier',
      {
        label: {
          [`${slug}`]: translation,
        },
      },
      true
    );
  });
  Object.entries(description).map(([lng, translation]) => {
    addTranslationDynamically(
      lng as KiwayLanguagesType,
      'notifier',
      {
        description: {
          [`${slug}`]: translation,
        },
      },
      true
    );
  });
  return (
    <div style={notifierSettingLineContainer}>
      <div style={notifierSettingLineLabel}>
        <Typography variant={labelVariant}>
          {t(`notifier:label.${slug}`)}
        </Typography>
        {t(`notifier:description.${slug}`) !== `description.${slug}` ? (
          <Tooltip title={t(`notifier:description.${slug}`)} placement="bottom">
            <InfoIcon color="secondary" style={{ marginLeft: '8px' }} />
          </Tooltip>
        ) : null}
      </div>

      {Object.values(notifierTypes).map((item) => (
        <div key={item} style={notifierSettingLineItem}>
          {availableTypes?.includes(item) && (
            <Switch
              checked={values[item] || false}
              onChange={(e) => {
                handleChange(item)(e?.target?.checked);
              }}
              color="primary"
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          )}
        </div>
      ))}
    </div>
  );
}

NotifierSettingLine.defaultProps = defaultNotifierSettingLineProps;

export function registerNotifier() {
  // REGISTER NOTIFIER MENU
  const builder = MenuBuilder.getBuilder('main');
  builder.addSubItem(
    {
      id: 'settings',
      text: 'menu:settings.main',
      link: 'settings',
      display: true,
      role: 'practitioner',
    },
    {
      id: 'notifier_settings',
      text: 'notifier:linkText',
      link: 'notifier-settings',
      display: true,
      role: 'practitioner',
      order: 2,
    }
  );
  // REGISTER NOTIFIER TRANSLATIONS
  const { addTranslationDynamically } = useTranslation();
  const translations = getTranslations();
  Object.entries(translations).map(([lng, translation]) => {
    addTranslationDynamically(
      lng as KiwayLanguagesType,
      'notifier',
      translation,
      true
    );
  });
  // REGISTER ROUTING
  const routing = RoutingBuilder.getBuilder();
  routing.addRoute({
    id: 'notifier_settings',
    path: '/notifier-settings',
    subtitle: 'notifier:docSubtitle',
    exact: false,
    private: true,
    component: NotifierSettings,
    appShell: true,
    role: 'ROLE_PRACTITIONER',
  });
}

export function NotifierSettings(props: NotifierSettingsProps) {
  const { headerVariant } = props;
  const { addSuccessSnackbar } = useSnackbar();
  const [values, setValues] = React.useState({});
  const { data: notifiers } = useQuery<ListNotifiersData>(LIST_NOTIFIERS);
  const { data: myNotifierSettings } = useQuery<ListMyNotifiersSettingsData>(
    LIST_MY_NOTIFIERS_SETTINGS
  );
  const [editMyNotifierSetting] = useMutation<
    EditMyNotifierSettingData,
    EditNotifierSettingInputArgs
  >(EDIT_MY_NOTIFIER_SETTING);
  const { t } = useTranslation();

  React.useEffect(() => {
    const initValues = myNotifierSettings?.listMyNotifiersSettings?.reduce(
      (prev, current) => {
        return {
          ...prev,
          [current.notifier?.slug]: {
            email: current.email,
            push: current.push,
          },
        };
      },
      {}
    );
    setValues(initValues);
  }, [myNotifierSettings]);
  return (
    <div>
      <div style={notifierSettingLineContainer}>
        <div style={{ ...notifierSettingLineLabel, fontWeight: 'bold' }}>
          <Typography variant={headerVariant}>
            {t('notifier:notifierLabel')}
          </Typography>
        </div>
        {Object.values(notifierTypes).map((item) => (
          <div
            key={item}
            style={{ ...notifierSettingLineItem, fontWeight: 'bold' }}
          >
            <Typography variant={headerVariant}>
              {t(`notifier:availableTypes.${item}`)}
            </Typography>
          </div>
        ))}
      </div>
      {notifiers?.listNotifiers?.map((item) => (
        <Paper key={item.slug} elevation={4}>
          <NotifierSettingLine
            {...item}
            values={values?.[item.slug] || {}}
            handleChange={(type: NotifierType) => (checked: boolean) => {
              editMyNotifierSetting({
                variables: {
                  input: {
                    notifierId: item.id,
                    type,
                    [type]: checked,
                  },
                },
              }).then(() => {
                const translationString = `notifier:snackbars.ok${firstUpperCase(
                  type
                )}${checked ? 'Enabled' : 'Disabled'}`;
                addSuccessSnackbar(t(translationString), null, {
                  toastId: `${item.slug}.${type}.${checked ? 'true' : 'false'}`,
                });
              });
              setValues((prev) => ({
                ...prev,
                [item.slug]: { ...prev[item.slug], [type]: checked },
              }));
            }}
          />
        </Paper>
      ))}
    </div>
  );
}

export function FeatureNotifier() {
  registerNotifier();
  return <></>;
}

NotifierSettings.defaultProps = defaultNotifierSettingsProps;
