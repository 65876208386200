export default {
  addEvent: {
    modal: {
      confirmEmail: 'Enviar un correo electrónico de confirmación',
      notifyWarning:
        'Recuerde su paciente si hay cambios de horario o dirección',
      smsReminder: 'Programar un sms de recierdo para 24 horas antes',
      title: 'Agregue una cita',
    },
  },
  caption: 'Leyenda :',
  deleteAppointmentModal: {
    content:
      '¿Estás seguro de que quieres eliminar esta cita? La operación es irreversible.',
    title: 'Eliminar una cita',
  },
  eventType: {
    other: 'Otro',
    session: 'Apunte con el paciente',
  },
  nextWeek: 'siguiente',
  prevWeek: 'Anterior',
  settings: {
    daysDisplayed: {
      labels: {
        friday: 'Viernes',
        monday: 'Lunes',
        saturday: 'Sábado',
        sunday: 'Domingo',
        thursday: 'Jueves',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
      },
    },
    modal: {
      apply: 'Aplicar',
      button: 'Preferencias',
      close: 'Cerrar',
      dayEnds: 'El día termina en',
      dayStarts: 'El día comienza en',
      displayWeekends: 'Exhibir fines de semana',
      hideWeekends: 'Ocultar fines de semana',
      slotDuration: 'Duración de un intervalo',
      title: 'Preferencias de agenda',
    },
    rowsPerHour: {
      title: 'Número de líneas por hora',
    },
    timeSlots: {
      title: 'Límites de la ranura de tiempo que la muestra',
    },
  },
  smsIssue: 'SMS no recibido',
  smsNone: 'No hay recordatorio SMS',
  smsScheduledAt: 'Se enviará un SMS el',
  smsSentOn: 'SMS enviado',
  snackbars: {
    appointments: {
      error:
        'Se produjo un problema durante la grabación de la cita. Por favor intente de nuevo.',
      errorDelete:
        'Se produjo un problema durante la eliminación de la cita. Por favor intente de nuevo.',
      missingFields: 'Llene todos los campos requeridos',
      overlap: 'No puede superponer varias citas',
      pendingClick:
        'Un paciente está haciendo una cita en línea, no puede editarlo por el momento.',
      successCreate: 'Reunión exitosa',
      successDelete: 'Cita eliminada con éxito',
      successUpdate: 'Cita exitosa',
    },
    load: {
      error:
        'Se produjo un problema durante la carga. Actualice la aplicación para que vuelva a intentarlo.',
    },
    settings: {
      error:
        'Se produjo un problema durante el registro de los parámetros. Gracias por intentarlo de nuevo.',
      successUpdate: 'Configuración grabada con éxito',
    },
  },
  updateEvent: {
    modal: {
      title: 'Modificar una cita',
    },
  },
  views: {
    '3days': '3 días',
    day: 'Día',
    list: 'Listado',
    week: 'Semana',
  },
  weekShort: 'SEM.',
};
