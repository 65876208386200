import React from 'react'
import { TextField, withStyles } from '@material-ui/core'

const StyledGreenPageTextField = withStyles({
  root: {
    color: 'white',
    '& label': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& input + fieldset': {
      color: 'white',
      borderColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      borderColor: 'white',
      color: 'white',
      '& fieldset': {
        borderColor: 'white !important',
      },
      '&:hover:not(.Mui-error) fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused:not(.Mui-error) fieldset': {
        borderColor: 'white',
      },
    },
  },
})(TextField)

function GreenPageTextField(props) {
  return (
    <StyledGreenPageTextField
      variant="outlined"
      margin="normal"
      fullWidth
      InputProps={{
        style: { color: 'white' },
      }}
      InputLabelProps={{
        style: { color: 'white' },
      }}
      {...props}
    />
  )
}

export default GreenPageTextField
