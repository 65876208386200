import React from 'react';
import { Paper, Typography, Chip } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';

export interface MetadataCardProps {
  datas: Array<any>;
  type: 'chip' | 'text';
  title: string;
}

export function MetadataCard(props: MetadataCardProps) {
  const { currentLanguage } = useTranslation();

  const renderDatas = () => {
    switch (props.type) {
      case 'chip':
        return props.datas.map((data) => (
          <Chip
            sx={{ m: '2px', p: 1 }}
            key={data.getId()}
            label={`${
              data.getName()?.[
                currentLanguage?.code ?? getFallbackLanguage()
              ] ?? ''
            }`}
            size="small"
          />
        ));

      case 'text':
        return props.datas.map((data) => (
          <Typography key={data.getId()} color="text.secondary">
            {`${
              data.getName()?.[
                currentLanguage?.code ?? getFallbackLanguage()
              ] ?? ''
            }`}
          </Typography>
        ));

      default:
        break;
    }
  };

  return (
    <Paper sx={{ p: 1, height: '100%' }}>
      <Typography variant="subtitle1">{props.title}</Typography>
      {renderDatas()}
    </Paper>
  );
}
