export default {
  and: 'e',
  browserDefaults: 'Por padrão',
  chips: {
    app: 'Aplicativo',
    pro: 'Pró',
    proFeature: 'Serviço pago',
    proTrial: 'Pro - teste em andamento',
    student: 'Aluno',
  },
  comingSoon: 'Em breve ! 😉',
  connectWith: 'Conexão com',
  controlButtons: {
    finish: 'Terminar',
    later: 'Mais tarde',
    next: 'Próximo',
    previous: 'Anterior',
    skip: 'Passar',
  },
  copied: 'Copiado para a área de transferência',
  default: 'Padrão',
  fileSize: {
    byte: 'bytes',
    giga: 'GB',
    kilo: 'KB',
    mega: 'MB',
  },
  form: {
    buttons: {
      cancel: 'Cancelar',
      charge: 'Faturar',
      confirm: 'Confirmar',
      delete: 'Apagar',
      download: 'Carregar',
      edit: 'Editar',
      filter: 'Filtro',
      loadMore: 'Ver mais',
      logout: 'Desconetar',
      save: 'Registro',
      send: 'Enviar',
      setDefault: 'Definir como padrão',
    },
    errors: {
      tooLongString: 'Carateres máximos de %X%',
      tooShortString: 'Carateres mínimos %X%',
    },
    fields: {
      address: {
        label: 'Endereço',
      },
      addressComplement: {
        label: 'Complemento de morada',
      },
      CGU: {
        label: `Ao marcar esta caixa, certifica ter lido e aceito sem reservar a [CGU](${process.env['NX_CGU_URL']}), a [cookie usage charter](${process.env['NX_COOKIES_POLICY_URL']}), bem como a [Política de Privacidade de Dados do CIAM](${process.env['NX_DATA_POLICY_URL']}) *`,
      },
      chiefComplaint: {
        label: 'Razão da consulta',
      },
      comment: {
        label: 'Observação',
      },
      consultationPlace: {
        label: 'Localização da consulta',
      },
      email: {
        label: 'E-mail',
      },
      endDate: {
        label: 'Data final',
      },
      endHour: {
        label: 'Hora do fim',
      },
      GDPR: {
        label: `Ao marcar esta caixa, consinto ao processamento de meus dados pessoais conforme planejado na [Política de Privacidade](${process.env['NX_PRIVACY_POLICY_URL']}) e de acordo com o RGDP *`,
      },
      gender: {
        default: 'Não',
        female: 'Feminino',
        male: 'Masculino',
        other: 'Outro',
      },
      label: {
        label: 'Nome',
      },
      lastname: {
        label: 'Nome',
      },
      patient: {
        global: {
          label: 'Paciente',
        },
      },
      phone: {
        areaCodeInfo: 'Você deve especificar o indicativo',
        country: 'Código do país',
        error: {
          possibility: {
            default: 'Você deve inserir um número de telefone válido',
            'invalid-country-code':
              'Você deve inserir um indicativo válido (+33 para a França, +351 para Portugal)',
            'is-possible': 'O número de telefone parece correto',
            'too-long': 'O número de telefone parece ser muito longo',
            'too-short': 'O número de telefone parece ser muito curto',
            unknown: 'Você deve inserir um número de telefone válido',
            wrongRegionCode: 'Você deve inserir um número local',
          },
          specificType: {
            'fixed-line': 'Você deve inserir um número de telefone fixo',
            'fixed-line-or-mobile':
              'Você deve inserir um número de telefone fixo ou telemóvel',
            mobile: 'Você deve inserir um número de telemóvel',
          },
        },
        errorNotValid: 'Insira um número de telefone válido',
        errorNotValidOrNotMobile: 'Insira um número de telemóvel válido',
        label: 'Telefone',
        mobileLabel: 'Portátil',
      },
      startDate: {
        label: 'Data de início',
      },
      startHour: {
        label: 'Hora do início',
      },
      title: {
        label: 'Título',
      },
      yourMessage: {
        label: 'A sua mensagem',
      },
    },
    uploads: {
      dragNDrop: {
        accept: 'Coloque seus arquivos aqui',
        label: 'Deslize seus arquivos aqui ou clique para selecioná-los',
        reject: 'Os arquivos não correspondem às defenições',
        snackbars: {
          error: {
            invalidType: 'O tipo do arquivo não é permitido',
            maxFilesNumber:
              'Você não pode baixar mais arquivos %maxfilesNumber%.',
            multipleFalse:
              'Apenas um arquivo pode ser carregado. Exclua este para poder carregar outro artigo',
            tooLarge: 'O tamanho do arquivo não deve exceder',
            unique: 'Um arquivo com o mesmo nome já existe',
          },
        },
      },
    },
  },
  from: 'de',
  fromDate: 'De',
  ht: 'Sem taxas',
  httpCodes: {
    buttons: {
      goBack: 'Retornar',
      goHome: 'Pagina inicial',
    },
    error404: {
      content: 'Não conseguimos encontrar a página que você está a procurar.',
      title: 'Oops!',
    },
  },
  legalDocuments: 'Documentos legais',
  loading: 'A Carregar...',
  menuToolbar: {
    chatbox: 'Chatbox',
    faq: 'FAQ',
    serviceDesk: 'Dê-nos o seu feedback',
    tutorials: 'Tutoriais',
  },
  no: 'Não',
  noLoggedIn: 'Você parece estar desconectado, identifique -se novamente.',
  noOptionsText: 'Nenhuma opção',
  noResult: 'Nenhum resultado',
  of: 'em',
  or: 'Ou',
  payment: {
    buttons: {
      validateCreditCard: 'Validar o cartão',
    },
    labels: {
      billingCountry: 'País de Faturação',
      creditCard: 'Cartão de Crédito',
      name: 'Nome',
    },
    messages: {
      cardAccepted: 'Cartão de Crédito Aceite',
    },
  },
  practices: {
    acupressure: 'Acupressão',
    acupuncture: 'Acupuntura',
    error: 'Escolha pelo menos um',
    other: 'Outro',
    otherStr: 'Especificar',
    pharmacopoeia: 'Fitoterapia',
    title: 'O que você pratica?',
    tuinaMassage: 'Massagem de Tuina',
  },
  readOnly: 'Somente leitura',
  redirectPending: 'Você será redirecionado',
  to: 'para ',
  toDate: 'A',
  tourGuide: {
    demoButton: 'Veja a demonstração',
    description: {
      agenda: 'Descrição da agenda',
      dashboard: 'Descrição do Painel/Dashboard ',
      files: 'Descrição Arquivos',
      invoicing: 'Descrição Faturas',
      pharmaco: 'Descrição Fitoterapia',
      services: 'Descrição Serviços',
      settings: 'Descrição Configurações',
    },
    name: {
      agenda: 'Diário',
      dashboard: 'Painel',
      files: 'arquivos',
      invoicing: 'Faturas',
      pharmaco: 'Fitoterapia',
      services: 'Serviços',
      settings: 'Definições',
    },
    subSection: {
      description: {
        addPatientDescription: 'Descubra como adicionar um paciente.',
        agendaGeneralDescription:
          'A página Agenda permitir-lhe-á planear as consultas e geri-las. Num relance, saberá de que é feito o seu dia.',
        agendaSettingsDescription:
          'A página da Agenda é personalizável para melhor se adequar às suas necessidades.',
        dashboardGeneralDescription:
          'O painel de bordo é a página inicial do site. Encontrará num relance os compromissos do dia, a relação entre o número de sessões completadas e o número de sessões programadas, os créditos SMS, etc.',
        filesGeneralDescription:
          'Em Ficheiros, terá uma visão geral dos seus pacientes e alguma informação sobre eles.',
        invoicingGeneralDescription:
          'Aqui encontrará uma lista de todas as facturas emitidas, o seu montante, data de emissão, etc.',
        patientInfoDescription:
          'Para mais detalhes, consulte esta página, encontrará informações sobre os seus pacientes: endereços, encomendas, história, etc.',
        pharmacoGeneralDescription:
          'A partir desta página, pode criar e acompanhar as suas encomendas.',
        removePatientDescription: 'Descubra como eliminar um paciente.',
        servicesGeneralDescription:
          'É aqui que encontrará as opções pagas/serviços do software.',
        settingsActivityDescription:
          'Faça os seus ajustes para as suas actividades.',
        settingsAgendaDescription:
          'Poderá estabelecer semanas típicas para compromissos on-line.',
        settingsGeneralDescription:
          'A página Configurações permitir-lhe-á completar a personalização da sua interface. É a sua ferramenta: pode configurá-la como desejar.',
        settingsInvoicingDescription:
          'Todas as informações de faturação dos seus serviços devem ser introduzidas a partir desta página.',
        settingsPublicPageDescription:
          'Como é a sua página pública? Nesta página, você decide.',
        settingsWorkplacesDescription:
          'Faça os seus ajustes para os seus locais de Consulta.',
      },
      title: {
        addPatient: 'Adicione um paciente',
        agendaGeneral: 'Agenda',
        agendaSettings: 'Definições da agenda',
        dashboardGeneral: 'Painel',
        filesGeneral: 'Arquivos',
        invoicingGeneral: 'Faturação',
        patientInfo: 'Folha de pacientes',
        pharmacoGeneral: 'Fitoterapia',
        removePatient: 'Elimine um paciente',
        servicesGeneral: 'Serviços',
        settingsActivity: 'Configurações relacionadas à atividade',
        settingsAgenda: 'Configurações da agenda',
        settingsGeneral: 'Definições',
        settingsInvoicing: 'Parâmetros de cobrança',
        settingsPublicPage: 'Parâmetros de página pública',
        settingsWorkplaces: 'Parâmetros de locais de consulta',
      },
    },
    title: 'Tutoriais',
  },
  ttc: 'Taxas Incluídas',
  VAT: 'IVA',
  welcome: 'Bem vindo',
  yes: 'sim',
};
