import {
  ShippingMethod,
  IShippingMethodAttributes,
} from '../../core/entities/ShippingMethod';
import { ShippingMethodProvider } from '../../core/use_cases/ShippingMethodProvider';
import { gql, ApolloClient } from '@apollo/client';

export const LIST_SHIPPING_METHODS = gql`
  query listShippingMethods($find: JSON) {
    listShippingMethods(find: $find) {
      id
      name
      carrier {
        id
        name
        code
        trackingLink
      }
      active
      displayOrder
      productCode
      countryZone {
        id
        name
        countries {
          id
          name
          code
        }
      }
      prices {
        id
        minWeight
        maxWeight
        price {
          currency {
            id
            code
            symbol
            format
          }
          centAmount
        }
      }
      # Add here your attributes
    }
  }
`;

export const EDIT_MULTIPLE_SHIPPING_METHODS = gql`
  mutation editManyShippingMethods($input: [ShippingMethodInput]) {
    editManyShippingMethods(input: $input) {
      id
      name
      carrier {
        id
        name
        code
        trackingLink
      }
      active
      displayOrder
      productCode
      countryZone {
        id
        name
        countries {
          id
          name
          code
        }
      }
      prices {
        id
        minWeight
        maxWeight
        price {
          currency {
            id
            code
            symbol
            format
          }
          centAmount
        }
      }
    }
  }
`;

export type IShippingMethodAttributesData = IShippingMethodAttributes & {
  id: string;
};

export interface ListShippingMethodsData {
  listShippingMethods: Array<IShippingMethodAttributesData>;
}

export interface EditManyShippingMethodsData {
  editManyShippingMethods: Array<IShippingMethodAttributesData>;
}

export class ShippingMethodGraphQLProvider implements ShippingMethodProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async findAll(find?: any): Promise<ShippingMethod[]> {
    try {
      const result = await this.client?.query<
        ListShippingMethodsData,
        { find?: any }
      >({
        query: LIST_SHIPPING_METHODS,
        variables: { find },
      });
      return result.data.listShippingMethods.map(
        (item) => new ShippingMethod(item)
      );
    } catch (e) {
      console.log(e);
    }
  }
  save(
    shippingMethod: ShippingMethod,
    userId?: string
  ): Promise<ShippingMethod> {
    throw new Error('Method not implemented.');
  }
  async editMany(
    entities: IShippingMethodAttributes[],
    userId?: string
  ): Promise<ShippingMethod[]> {
    try {
      const result = await this.client?.mutate<
        EditManyShippingMethodsData,
        { input: IShippingMethodAttributes[] }
      >({
        mutation: EDIT_MULTIPLE_SHIPPING_METHODS,
        variables: {
          input: entities,
        },
      });
      return result.data?.editManyShippingMethods?.map(
        (item) => new ShippingMethod(item)
      );
    } catch (e) {
      console.log(e);
    }
  }
}
