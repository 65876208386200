import './base-old-app.module.css';
import * as React from 'react';
import { OldApp } from './oldFrontKiway';

export interface BaseOldAppProps {
  config: {
    features: any;
    menus: any;
    routes: any;
    themeV4: any;
    themeV5: any;
  };
}

export const BaseOldApp = (props: BaseOldAppProps) => {
  return <OldApp config={props.config} />;
};

export default BaseOldApp;
