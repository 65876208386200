/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DatatablesController } from '@kiway/datatables';
import { GlobalStatus, Order } from '@kiway/ecommerce-react-compatible';
import { flatten } from '@kiway/shared/utils/string';
import { Button, Grid } from '@mui/material';
import { Select } from '@kiway/shared/ui';
import { useTranslation } from '@kiway/shared/utils/translation';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';
import { ProductVariationType } from '../../../core/entities/ProductVariationType';
import { Carrier } from '@kiway/ecommerce-react';
import config from '../../../config';

export interface ListOrdersAdminWholesalerProps {
  handleNewClick?: () => void;
  handleRowClick?: (rowIndex: number) => void;
  handleSave?: any;
  loading?: boolean;
  orders: Order[];
  filteredOrders: Order[];
  filterGlobalStatusValues: Array<GlobalStatus | 'ALL'>;
  filterGlobalStatus: GlobalStatus | 'ALL';
  setFilterGlobalStatus: any;
  filterOrdersByGlobalStatus: (
    globalStatus: GlobalStatus | 'ALL'
  ) => (order: Order) => boolean;
  filterVariationsValues: Array<ProductVariationType>;
  filterVariations: string | 'ALL';
  setFilterVariations: any;
  filterOrdersByVariations: (
    variationFilter: string | 'ALL'
  ) => (order: Order) => boolean;
  filterCarriersValues: Array<Carrier>;
  filterCarriers: string | 'ALL';
  setFilterCarriers: any;
  filterOrdersByCarriers: (
    carrierFilter: string | 'ALL'
  ) => (order: Order) => boolean;
}

export function ListOrdersAdminWholesaler(
  props: ListOrdersAdminWholesalerProps
) {
  const { t, currentLanguage } = useTranslation();

  const seeInvoice = (id: string) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    window
      .open(
        `https://${process.env.NX_API_URL}/storage/display/${
          props.orders
            .find((order) => order?.getId() === id)
            ?.getInvoice()
            ?.getFile()?.id
        }`,
        '_blank'
      )
      .focus();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} sx={{ maxWidth: '300px' }}>
            <Select
              options={props.filterGlobalStatusValues?.map((value) => ({
                label: `${t(`pharmaco:orderDetails.global.status.${value}`)} (${
                  props.orders?.filter(props.filterOrdersByGlobalStatus(value))
                    ?.length
                })`,
                value,
              }))}
              margin="dense"
              size="small"
              onChange={(e) => {
                props.setFilterGlobalStatus(
                  e.target.value as GlobalStatus | 'ALL'
                );
              }}
              value={props.filterGlobalStatus as string}
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={{ maxWidth: '300px' }}>
            <Select
              label={t('pharmaco:filters.variations.label')}
              options={[
                { label: t('pharmaco:filters.variations.all'), value: 'ALL' },
                ...props.filterVariationsValues?.map((value) => ({
                  label: value?.getName()?.[
                    currentLanguage?.code ?? getFallbackLanguage()
                  ],
                  value: value?.getShortcode(),
                })),
              ]}
              margin="dense"
              size="small"
              onChange={(e) => {
                props.setFilterVariations(e.target.value as string | 'ALL');
              }}
              value={props.filterVariations as string}
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={{ maxWidth: '300px' }}>
            <Select
              label={t('pharmaco:filters.carriers.label')}
              options={[
                { label: t('pharmaco:filters.carriers.all'), value: 'ALL' },
                ...props.filterCarriersValues?.map((value) => ({
                  label: value?.getName()?.[
                    currentLanguage?.code ?? getFallbackLanguage()
                  ],
                  value: value?.getId(),
                })),
              ]}
              margin="dense"
              size="small"
              onChange={(e) => {
                props.setFilterCarriers(e.target.value as string | 'ALL');
              }}
              value={props.filterCarriers as string}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DatatablesController
          bulkEdit={false}
          columns={[
            {
              name: 'orderNumber',
              label: t('pharmaco:orders.columns.orderNumber'),
              type: 'text',
            },
            {
              name: 'openedAt',
              label: t('pharmaco:orders.columns.openedAt'),
              type: 'simple-date',
            },
            {
              name: 'paidAt',
              label: t('pharmaco:orders.columns.paidAt'),
              type: 'simple-date',
            },
            {
              name: 'prescriber',
              label: t('pharmaco:orders.columns.prescriber'),
              type: 'text',
            },
            {
              name: 'customer',
              label: t('pharmaco:orders.columns.customer'),
              type: 'text',
            },
            {
              name: 'status',
              label: t('pharmaco:orders.columns.globalStatus'),
              type: 'status-borderless',
            },
            {
              name: 'orderStatus',
              label: t('pharmaco:orders.columns.orderStatus'),
              type: 'status-borderless',
              options: {
                display: false,
              },
            },
            {
              name: 'paymentStatus',
              label: t('pharmaco:orders.columns.paymentStatus'),
              type: 'status-borderless',
              options: {
                display: false,
              },
            },
            {
              name: 'packingStatus',
              label: t('pharmaco:orders.columns.packingStatus'),
              type: 'status-borderless',
              options: {
                display: false,
              },
            },
            {
              name: 'shipmentStatus',
              label: t('pharmaco:orders.columns.shipmentStatus'),
              type: 'status-borderless',
              options: {
                display: false,
              },
            },
            {
              name: 'shippingMethod.carrier.name',
              label: t('pharmaco:orders.columns.carrier'),
              type: 'translatable',
              options: {
                display: false,
              },
            },
            {
              name: 'shippingMethod.name',
              label: t('pharmaco:orders.columns.shipping'),
              type: 'translatable',
              options: {
                display: false,
              },
            },
            {
              name: 'shippingPrice',
              label: t('pharmaco:orders.columns.shippingPrice'),
              type: 'text',
              options: {
                display: false,
              },
            },
            {
              name: 'totalGrossPrice',
              label: t('pharmaco:orders.columns.totalGrossPrice'),
              type: 'text',
              options: {
                display: false,
              },
            },
            {
              name: 'totalNetPrice',
              label: t('pharmaco:orders.columns.totalNetPrice'),
              type: 'text',
              options: {
                display: false,
              },
            },
            {
              name: 'totalPrice',
              label: t('pharmaco:orders.columns.totalPrice'),
              type: 'text',
            },
            {
              name: 'actions',
              label: ' ',
              type: 'text',
              options: {
                customBodyRender(value, tableMeta, updateValue) {
                  return value.canSeeInvoice ? (
                    <Button size="small" onClick={seeInvoice(value.orderId)}>
                      {t(`${config.namespace}:orderDetails.buttons.invoice`)}
                    </Button>
                  ) : null;
                },
                display: true,
                sort: false,
                filter: false,
                print: false,
                searchable: false,
                download: false,
                draggable: false,
                viewColumns: false,
                empty: true,
                forceView: true,
              },
            },
            // {
            //   name: 'shippingTrackingNumber',
            //   label: 'Suivi',
            //   type: 'text',
            // },
          ]}
          data={props.filteredOrders
            ?.map((item) => item.toDatatableRow())
            ?.map(
              ({
                orderStatus,
                paymentStatus,
                packingStatus,
                shipmentStatus,
                shippingMethod,
                status,
                ...order
              }) => ({
                ...order,
                // ...flatten({ shippingMethod }),
                'shippingMethod.carrier.name':
                  shippingMethod?.carrier?.name ?? '',
                'shippingMethod.name': shippingMethod?.name ?? '',
                // 'shippingMethod.price': shippingMethod?.price ?? {},
                status: {
                  ...status,
                  label: `pharmaco:orderDetails.global.status.${status.label}`,
                },
                orderStatus: {
                  ...orderStatus,
                  label: `pharmaco:orderDetails.order.status.${orderStatus.label}`,
                },
                paymentStatus: {
                  ...paymentStatus,
                  label: `pharmaco:orderDetails.payment.status.${paymentStatus.label}`,
                },
                packingStatus: {
                  ...packingStatus,
                  label: `pharmaco:orderDetails.packing.status.${packingStatus.label}`,
                },
                shipmentStatus: {
                  ...shipmentStatus,
                  label: `pharmaco:orderDetails.shipment.status.${shipmentStatus.label}`,
                },
                actions: {
                  canSeeInvoice:
                    shipmentStatus.label === 'SHIPPED' &&
                    orderStatus.label === 'COMPLETE',
                  orderId: order._id,
                },
              })
            )}
          defaultSortOrder={{ name: 'orderNumber', direction: 'desc' }}
          handleRowClick={props.handleRowClick}
          handleSaveData={props.handleSave}
          id={'listOrders-admin-wholesaler'}
          loading={false}
          title={t('pharmaco:orders.docSubtitle')}
          options={{
            filter: false,
          }}
        />
      </Grid>
    </Grid>
  );
}
