export default {
  buttons: {
    addPatient: 'Adicionar Paciente',
    addRDV: 'Nuevo cita',
    newOrder: 'Nueva orden',
  },
  dataTable: {
    columns: {
      hour: 'Hora',
    },
    title: 'Citas del dia',
  },
  stats: {
    cards: {
      affiliations: {
        nbCompleted: 'Acabado',
        nbPending: 'En curso',
        nbSent: 'Envíado',
        title: 'Patrocinio',
      },
      consumption: {
        smsCredits: {
          subtitle: 'O alrededor de %X% € TTC',
          title: 'Créditos SMS',
        },
        stripeBalance: {
          negativeText:
            'El saldo reducirá la cantidad adeudada en la próxima factura',
          positiveText:
            'El saldo aumentará la cantidad adeudada en la próxima factura',
          title: 'Balance restante',
        },
        title: 'Consumo de sus recursos',
      },
      kpis: {
        nbManualRDVs: 'Cita tomada manual',
        nbOnlineRDVs: 'Citas tomadas en línea',
        nbSubPro: 'Subs Pro',
        nbSubSmsTotal: 'Subs Sms',
        nbSubStudent: 'Subs Estudiante ',
        nbSubTotal: 'Total Subs',
        nbTotalFiles: 'Archivos de pacientes',
        nbTotalRDVs: 'Total de las Citas',
        title: 'KPI',
      },
      monthActivity: {
        appointmentsDonePercent: 'Sesiones realizadas',
        caForecast: 'Pronóstico',
        nbAppointments: 'Número de sesiones',
        nbNewPatients: 'Nuevos pacientes',
        title: 'Tu actividad mensual',
      },
      users: {
        nbAdmin: 'Administradores',
        nbBeta: 'Cocreadores',
        nbGeneratedPatients: 'Pacientes generados',
        nbPartner: 'Socios',
        nbPatients: 'Pacientes',
        nbPharmaco: 'Fito',
        nbPractitioners: 'Practicantes',
        nbTotalNoGeneratedPatient: 'Total registrado',
        title: 'Tipología de usuario',
      },
    },
    filters: 'Filtros',
    graph: {
      bestOfUsers: {
        title: 'La mayoría de los usuarios activos durante el período',
      },
      loading: 'Cargando el gráfico',
      nbOfUsers: {
        signins: 'Conexión',
        signinsUnique: 'Conexiones únicas',
        signups: 'Registro',
        title: 'Número de usuarios',
      },
    },
  },
};
