export default {
  appointments: {
    chiefComplaint: 'Chief complaint',
    datatableTitle: 'My appointments',
    date: 'Date',
    hour: 'Hour',
    patientNote: 'Session notes',
    practitioner: 'Practitioner',
    practitionerContact: 'Contact practitioner',
  },
  newPatient: 'New patient',
};
