import { Order } from '../entities/Order';
import { Payment, PaymentMethod } from '../entities/Payment';
import { OrderProvider } from './OrderProvider';
import { PaymentProvider } from './PaymentProvider';

export class ChangeOrderPaymentMethod {
  private paymentProvider: PaymentProvider;
  private orderProvider: OrderProvider;

  public constructor(
    paymentProvider: PaymentProvider,
    orderProvider: OrderProvider
  ) {
    this.paymentProvider = paymentProvider;
    this.orderProvider = orderProvider;
  }

  private async checkPayment(orderId: string): Promise<Payment> {
    const payment = await this.paymentProvider.findOneByOrderId(orderId);
    if (!payment?.getId()) {
      throw new Error('Error payment');
    }
    return payment;
  }

  public async execute(
    orderId: string,
    paymentMethod: PaymentMethod
  ): Promise<Payment> {
    const payment = await this.checkPayment(orderId);
    payment.setPaymentMethod(paymentMethod);
    return await this.paymentProvider.save(payment);
    // return await this.orderProvider.getOrderPublicCheckout(orderId);
  }
}
