import { PlantMetadataSearchReturn } from '../entities/Plant';
import { PlantProvider } from './PlantProvider';

export class SearchPlantMetadata {
  private plantProvider: PlantProvider;

  public constructor(plantProvider: PlantProvider) {
    this.plantProvider = plantProvider;
  }

  public execute(search: string): Promise<PlantMetadataSearchReturn> {
    return this.plantProvider.searchPlantMetadata(search);
  }
}
