import * as React from 'react';
import {
  Autocomplete,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Formula } from '../PrescriptionDetails/Formula';
import SideCardLine from './SideCard/SideCardLine';
import { ButtonGroupSelector } from './ButtonGroupSelector';
import {
  pharmaceuticalForms,
  plantPowderGroundSizes,
  plantPowderTypes,
  posologyPlantSmallBagsWeightModes,
} from '../../../config';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AccordionItem } from '@kiway/ecommerce-react';
import { useStyles } from '../shared';
import { useTranslation } from '@kiway/shared/utils/translation';
import {
  CAPSULES_BATCH_SIZE,
  CAPSULE_WEIGHT,
} from '../../../core/entities/PharmacoOrder';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

export function PrescriptionDetailsFormula(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<boolean>(true);
  const [isDecoction, setIsDecoction] = React.useState<boolean>(false);
  const [decoctionOptions, setDecoctionOptions] = React.useState<Array<string>>(
    []
  );
  const [decoctionValue, setDecoctionValue] = React.useState<string>('');
  const handleChangeAccordion = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? true : false);
  };
  const {
    formula,
    handleChangeDosage,
    handleChangeDosageMode,
    handleSaveOrder,
    handleToggleIsPod,
  } = props;
  const [formulaCustom, setFormulaCustom] = React.useState(formula.getCustom());
  const [quantityValue, setQuantityValue] = React.useState<string>(
    formula.getQuantity()
  );
  const [nbOfBagsValue, setNbOfBagsValue] = React.useState<string>(
    formula.getNbOfBags()
  );
  const [nbOfCapsulesBatchValue, setNbOfCapsulesBatchValue] = React.useState<
    string
  >(formula.getNbOfCapsulesBatch());

  React.useEffect(() => {
    setQuantityValue(formula.getQuantity());
    return () => {
      setQuantityValue('0');
    };
  }, [formula.getQuantity()]);

  React.useEffect(() => {
    const NX_DECOCTION_EXPIRATION_DAYS = 7;
    const min = Math.ceil(
      parseInt(nbOfBagsValue) / NX_DECOCTION_EXPIRATION_DAYS
    );
    const options = Array.from(
      { length: parseInt(nbOfBagsValue) - min + 1 },
      (v, k) => (k + min).toString()
    );
    setDecoctionOptions(options);
    setDecoctionValue('');
  }, [nbOfBagsValue]);

  React.useEffect(() => {
    setIsDecoction(formula.getCustom().simplifiedDecoction);
    setDecoctionValue(
      formula.getCustom().simplifiedDecoctionValue !== 'false'
        ? formula.getCustom().simplifiedDecoctionValue
        : ''
    );
  }, []);

  const handleChange = (type, value, save) => {
    switch (type) {
      case 'quantity':
        formula.setQuantity(value, save);
        setQuantityValue(value);
        break;
      case 'nbOfBags':
        formula.setNbOfBags(value, save);
        setNbOfBagsValue(value);
        break;
      case 'nbOfCapsulesBatch':
        formula.setNbOfCapsulesBatch(value, save);
        setNbOfCapsulesBatchValue(value);
        break;
      default:
        console.log(type);
        break;
    }
  };
  const formulaNameRaw = formula.getName();
  const group = formula.getGroupBy();
  const rows = formula.getIngredients();
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [formulaName, setFormulaName] = React.useState<string>(formulaNameRaw);

  const handleDecoctionValueChange = (value) => {
    formula.setCustomProperty(
      'simplifiedDecoctionValue',
      isDecoction ? value : false
    );
    setDecoctionValue(value);
  };

  const toggleIsDecoction = () => {
    setIsDecoction((prev) => {
      formula.setCustomProperty('simplifiedDecoction', !prev);
      formula.setCustomProperty(
        'simplifiedDecoctionValue',
        !prev ? decoctionValue : ''
      );
      return !prev;
    });
  };

  const toggleIsPod = () => {
    handleToggleIsPod(formula);
  };

  return (
    <Card elevation={8}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item xs={11} alignItems="center">
                <Grid container>
                  <Grid item md={4}>
                    {editMode ? (
                      <Grid container>
                        <Grid item>
                          <TextField
                            variant="outlined"
                            size="small"
                            value={formulaName}
                            onChange={(e) => setFormulaName(e.target.value)}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={() => {
                              setEditMode(false);
                              props.handleChangeFormulaName(
                                formula.getGroupBy(),
                                formulaName
                              );
                            }}
                          >
                            <SaveIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setEditMode(false);
                              setFormulaName(formula.getName());
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item>
                          <Typography variant="h4">
                            {formula.getName()}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton onClick={() => setEditMode(true)}>
                            <ModeEditIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  {formula.getPlantPowderType() === 'powder' &&
                    formula.getPharmaceuticalForm() === 'small_bag' && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formula.needToAddPod()}
                            onClick={() => toggleIsPod()}
                          />
                        }
                        label={`${t('pharmaco:prescription.addPod')}`}
                      />
                    )}

                  <Grid item xs={12} md={8}>
                    <Grid container columnSpacing={2}>
                      <Grid item>
                        <SideCardLine>
                          <ButtonGroupSelector
                            values={plantPowderTypes.data?.map((item) => ({
                              ...item,
                              label: t(item.label),
                            }))}
                            value={formula.getPlantPowderType()}
                            handleChange={(value) => {
                              formula.setPlantPowderType(value);
                              setFormulaCustom((prev) => ({
                                ...prev,
                                plantPowderType: value,
                              }));
                            }}
                          />
                        </SideCardLine>
                      </Grid>
                      <Grid item>
                        <SideCardLine>
                          <ButtonGroupSelector
                            values={pharmaceuticalForms.data?.map((item) => ({
                              ...item,
                              label: t(item.label),
                            }))}
                            value={formula.getPharmaceuticalForm()}
                            handleChange={(value) => {
                              formula.setPharmaceuticalForm(value);
                              setFormulaCustom((prev) => ({
                                ...prev,
                                pharmaceuticalForm: value,
                              }));
                            }}
                          />
                        </SideCardLine>
                      </Grid>
                      {formulaCustom.plantPowderType === 'plant' &&
                        formulaCustom.pharmaceuticalForm === 'small_bag' && (
                          <Grid item sm={12} lg={6}>
                            <SideCardLine>
                              <ButtonGroupSelector
                                values={plantPowderGroundSizes.data?.map(
                                  (item) => ({
                                    ...item,
                                    label: t(item.label),
                                  })
                                )}
                                value={
                                  formula.getPlantPowderGroundSize() || 'whole'
                                }
                                handleChange={(value) => {
                                  formula.setPlantPowderGroundSize(value);
                                  setFormulaCustom((prev) => ({
                                    ...prev,
                                    plantPowderGroundSize: value,
                                  }));
                                }}
                              />
                            </SideCardLine>
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={1} textAlign="end">
                <IconButton onClick={() => props.handleRemoveFormula(group)}>
                  <DeleteForeverIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Formula
              key={group}
              formula={formula}
              rows={rows}
              totalPercent={formula.getTotalPercent()}
              totalPrice={formula.getTotalPrice()}
              totalQuantity={formula.getTotalQuantity()}
              handleChangeDosage={handleChangeDosage}
              handleChangeDosageMode={handleChangeDosageMode}
              handleChangeIsSeparated={props.handleChangeIsSeparated}
              handleSaveOrder={handleSaveOrder}
              handleRemoveLineItem={props.handleRemoveLineItem}
              getRoundedItemTotalWeight={(id: string) =>
                formula.getRoundedItemTotalWeight(id)
              }
              getRoundedItemPortionWeight={(
                id: string,
                includeSimplifiedDecoction?: boolean
              ) =>
                formula.getRoundedItemPortionWeight(
                  id,
                  includeSimplifiedDecoction,
                  formula.getPharmaceuticalForm() === 'small_bag' ? 1 : 2
                )
              }
            />
          </Grid>
          {formula.getPlantPowderType() === 'plant' &&
            formula.getPharmaceuticalForm() === 'small_bag' && (
              <Grid item xs={12}>
                <AccordionItem
                  bgColor="#EAE9E9"
                  label={t('pharmaco:prescription.posology.plant.title')}
                  secondaryLabel={t(
                    'pharmaco:prescription.posology.plant.resume',
                    {
                      count: parseFloat(nbOfBagsValue),
                      nbOfBags: formula.getNbOfBags(true),
                      portionWeight: Number(
                        formula.getPortionWeight(true).toFixed(1)
                      ).toFixed(2),
                      variation: t(
                        `pharmaco:prescription.plantPowderGroundSize.${formula.getPlantPowderGroundSize()}`
                      )?.toLowerCase(),
                    }
                  )}
                  expanded={expanded}
                  handleChange={handleChangeAccordion(`posology-ps`)}
                  labelWidth={'25%'}
                  maxSecondaryLength={80}
                >
                  <Grid container>
                    {/* <Grid item xs={12} sm={6} md={4}>
                      <SideCardLine
                        label={t(posologyPlantSmallBagsWeightModes.label)}
                      >
                        <ButtonGroupSelector
                          values={posologyPlantSmallBagsWeightModes.data?.map(
                            (item) => ({
                              ...item,
                              label: t(item.label),
                            })
                          )}
                          value={formula.getWeightMode()}
                          handleChange={(value) => {
                            formula.setWeightMode(value);
                            setFormulaCustom((prev) => ({
                              ...prev,
                              weightMode: value,
                            }));
                          }}
                        />
                      </SideCardLine>
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={4}>
                      <SideCardLine
                        label={t(
                          `pharmaco:prescription.quantity.${formula.getWeightMode()}`
                        )}
                      >
                        <TextField
                          value={quantityValue}
                          onChange={(e) =>
                            handleChange('quantity', e.target.value, false)
                          }
                          onBlur={(e) =>
                            handleChange('quantity', e.target.value, true)
                          }
                          variant="outlined"
                          type="number"
                          size="small"
                          className={classes.noArrowInputNumber}
                        />
                      </SideCardLine>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <SideCardLine label={t('pharmaco:prescription.nbOfBags')}>
                        <TextField
                          value={nbOfBagsValue}
                          onChange={(e) =>
                            handleChange('nbOfBags', e.target.value, false)
                          }
                          onBlur={(e) =>
                            handleChange('nbOfBags', e.target.value, true)
                          }
                          variant="outlined"
                          type="number"
                          size="small"
                          className={classes.noArrowInputNumber}
                        />
                      </SideCardLine>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      display="flex"
                      alignItems="center"
                      marginTop={2}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!isDecoction}
                            disabled={formula.getNbOfBags() <= 1}
                            onClick={() => toggleIsDecoction()}
                          />
                        }
                        label={`${t('pharmaco:prescription.decoction')}`}
                      />
                      {isDecoction && (
                        <Autocomplete
                          disablePortal
                          disabled={formula.getNbOfBags() <= 1}
                          onInputChange={(e, value) =>
                            handleDecoctionValueChange(value)
                          }
                          options={decoctionOptions}
                          sx={{ width: 300 }}
                          value={decoctionValue ?? null}
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t('pharmaco:prescription.decoction')}`}
                            />
                          )}
                        />
                      )}
                    </Grid>
                  </Grid>
                </AccordionItem>
              </Grid>
            )}
          {formula.getPlantPowderType() === 'powder' &&
            formula.getPharmaceuticalForm() === 'small_bag' && (
              <Grid item xs={12}>
                <AccordionItem
                  bgColor="#EAE9E9"
                  label={t('pharmaco:prescription.posology.powder.title')}
                  secondaryLabel={t(
                    'pharmaco:prescription.posology.powder.resume',
                    {
                      totalWeight: Number(formula.getTotalWeight()).toFixed(2),
                    }
                  )}
                  expanded={expanded}
                  handleChange={handleChangeAccordion(`posology-cp`)}
                  labelWidth={'25%'}
                  maxSecondaryLength={80}
                >
                  <Grid container>
                    <Grid item>
                      <SideCardLine
                        label={t('pharmaco:prescription.quantity.default')}
                      >
                        <TextField
                          value={quantityValue}
                          onChange={(e) =>
                            handleChange('quantity', e.target.value, false)
                          }
                          onBlur={(e) =>
                            handleChange('quantity', e.target.value, true)
                          }
                          variant="outlined"
                          type="number"
                          size="small"
                          className={classes.noArrowInputNumber}
                        />
                      </SideCardLine>
                    </Grid>
                  </Grid>
                </AccordionItem>
              </Grid>
            )}
          {formula.getPharmaceuticalForm() === 'capsule' && (
            <Grid item xs={12}>
              <AccordionItem
                bgColor="#EAE9E9"
                label={t(
                  `pharmaco:prescription.posology.capsule.${formula.getPlantPowderType()}.title`
                )}
                secondaryLabel={t(
                  `pharmaco:prescription.posology.capsule.${formula.getPlantPowderType()}.resume`,
                  {
                    count: parseFloat(nbOfCapsulesBatchValue),
                    nbOfCapsulesBatch: nbOfCapsulesBatchValue,
                    capsulesBatchSize: CAPSULES_BATCH_SIZE,
                    capsuleWeight: CAPSULE_WEIGHT,
                  }
                )}
                expanded={expanded}
                handleChange={handleChangeAccordion(`posology-g`)}
                labelWidth={'25%'}
                maxSecondaryLength={80}
              >
                <Grid container>
                  <Grid item>
                    <SideCardLine
                      label={t(`pharmaco:prescription.nbOfCapsulesBatch`)}
                    >
                      <TextField
                        value={nbOfCapsulesBatchValue}
                        onChange={(e) =>
                          handleChange(
                            'nbOfCapsulesBatch',
                            e.target.value,
                            false
                          )
                        }
                        onBlur={(e) =>
                          handleChange(
                            'nbOfCapsulesBatch',
                            e.target.value,
                            true
                          )
                        }
                        variant="outlined"
                        type="number"
                        size="small"
                        className={classes.noArrowInputNumber}
                      />
                    </SideCardLine>
                  </Grid>
                </Grid>
              </AccordionItem>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
