/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { IProductAttributes, Product } from '@kiway/ecommerce-react-compatible';
import {
  flattenTranslatableAttributeToJson,
  kiwayLanguages,
  KiwayLanguagesType,
  TranslatableAttribute,
} from '@kiway/shared/react-types';
import { Plant, IPlantAttributes } from './Plant';
import {
  IProductMetadataAttributes,
  IProductMetadataSearchable,
  ProductMetadata,
  ProductMetadataType,
} from './ProductMetadata';

type Dosage = number;

interface IFormulaIngredientAttributes {
  plant: IPlantAttributes;
  dosage: Dosage;
  isSeparated: boolean;
}

export class FormulaIngredient {
  protected id: string;
  protected plant: Plant;
  protected dosage: Dosage;
  protected isSeparated: boolean;

  constructor(
    obj: Partial<IFormulaIngredientAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?._id || obj?.id;
    if (typeof obj?.plant === 'string') {
      this.plant = new Plant({
        _id: obj.plant,
      });
    } else {
      this.plant = new Plant(obj.plant);
    }
    this.dosage = obj?.dosage;
  }

  public getId(): string {
    return this.id;
  }

  public getPlant(): Plant {
    return this.plant;
  }

  public getDosage(): Dosage {
    return this.dosage;
  }

  public setDosage(dosage: number): FormulaIngredient {
    this.dosage = dosage;
    return this;
  }

  public getIsSeparated(): boolean {
    return this.isSeparated;
  }

  public setIsSeparated(isSeparated: boolean): FormulaIngredient {
    this.isSeparated = isSeparated;
    return this;
  }

  public toInput(): any {
    return {
      id: this.id,
      plant: this.plant.getId(),
      dosage: `${this.dosage}`,
    };
  }
}

export type IFormulaCustomAttributes = IProductAttributes['custom'] & {
  legacyId?: string;
  pinYinName: string;
  description: TranslatableAttribute;
  composition: IFormulaIngredientAttributes[];
  pictureUrl?: string;
  normal?: boolean;
  xiaoFang?: boolean;
  jingFang?: boolean;
};

export interface IFormulaAttributes extends IProductAttributes {
  published: boolean;
  metadatas?: IProductMetadataAttributes[];
  custom?: IFormulaCustomAttributes;
}

export interface FormulaMetadataSearch {
  metadatas?: IProductMetadataAttributes[];
  plants?: IPlantAttributes[];
}

export interface FormulaMetadataSearchReturn {
  metadatas?: ProductMetadata[];
  plants?: Plant[];
}

export type FormulaArrays = 'metadatas' | 'plant';

export const formulaSearchableAttributes = [
  'custom.pinYinName',
  ...kiwayLanguages.map((lng) => `custom.description.${lng}`),
];

function escapeRegExp(str) {
  // eslint-disable-next-line no-useless-escape
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

export class Formula extends Product implements IProductMetadataSearchable {
  protected id: string;
  protected legacyId: string;
  protected pinYinName: string;
  protected description: TranslatableAttribute;
  protected published: boolean;
  protected composition: FormulaIngredient[];
  protected metadatas: ProductMetadata[];
  protected pictureUrl: string;
  protected normal: boolean;
  protected xiaoFang: boolean;
  protected jingFang: boolean;

  public constructor(
    obj: Partial<IFormulaAttributes> & { _id?: string; id?: string }
  ) {
    super(obj);
    this.id = obj?.id || obj?._id;
    this.custom = {
      ...this.custom,
      legacyId: obj?.custom?.legacyId,
      pinYinName: obj?.custom?.pinYinName,
      description: obj?.custom?.description,
      pictureUrl: obj?.custom?.pictureUrl,
      normal: obj?.custom?.normal ?? false,
      xiaoFang: obj?.custom?.xiaoFang ?? false,
      jingFang: obj?.custom?.jingFang ?? false,
      composition: [],
    };
    this.published = obj?.published;
    this.metadatas = [];
    for (const m of obj?.metadatas || []) {
      if (!m.name) {
        this.addMetadata(new ProductMetadata({ _id: m.toString() }));
      } else {
        this.addMetadata(new ProductMetadata(m));
      }
    }
    this.custom.composition = obj?.custom?.composition ? [] : undefined;
    for (const ingredient of obj?.custom?.composition || []) {
      if (typeof ingredient === 'string') {
        this.addIngredient(new FormulaIngredient({ _id: ingredient }));
      } else {
        this.addIngredient(
          new FormulaIngredient(
            ingredient as Partial<IFormulaIngredientAttributes> & {
              _id?: string;
              id?: string;
            }
          )
        );
      }
    }
  }

  static getSimpleSearchFilter(search: string) {
    return (formula: Formula): boolean => {
      if (!search) {
        return true;
      }
      return (
        formula
          .getPinYinName()
          ?.toLowerCase()
          ?.search(escapeRegExp(search).toLowerCase()) > -1 ||
        Object.values(formula.getDescription())
          ?.join(' ')
          ?.toLowerCase()
          ?.search(escapeRegExp(search).toLowerCase()) > -1
      );
    };
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): Formula {
    this.id = id;
    return this;
  }

  public getLegacyId(): string {
    return this.custom.legacyId;
  }

  public getPinYinName(): string {
    return this.custom.pinYinName;
  }

  public setPinYinName(name: string): Formula {
    this.custom.pinYinName = name;
    return this;
  }

  public getDescription(): TranslatableAttribute {
    return this.custom.description;
  }

  public setDescription(description: TranslatableAttribute): Formula {
    this.custom.description = description;
    return this;
  }

  public isPublished(): boolean {
    return this.published;
  }

  public setPublished(published: boolean): Formula {
    this.published = published;
    return this;
  }

  public getComposition(): FormulaIngredient[] {
    return this.custom.composition;
  }

  public setComposition(composition: FormulaIngredient[]): Formula {
    this.custom.composition = composition;
    return this;
  }

  public addIngredient(ingredient: FormulaIngredient): Formula {
    this.custom?.composition?.push(ingredient);
    return this;
  }

  public removeIngredient(ingredient: FormulaIngredient): Formula {
    this.custom.composition = this.composition?.filter(
      (item) => item?.getPlant()?.getId() !== ingredient?.getPlant()?.getId()
    );
    return this;
  }

  public getMetadatas(type?: ProductMetadataType): ProductMetadata[] {
    return type
      ? this.metadatas?.filter((m) => m.getMetadataType() === type)
      : this.metadatas;
  }

  public setMetadatas(metadatas: ProductMetadata[]): Formula {
    this.metadatas = metadatas;
    return this;
  }

  public addMetadata(metadata: ProductMetadata): Formula {
    this.metadatas.push(metadata);
    return this;
  }

  public removeMetadata(metadataId: string): Formula {
    this.metadatas = this.metadatas.filter(
      (metadata) => metadata.getId() !== metadataId
    );
    return this;
  }

  public getMetadataIds(): string[] {
    return [...this.metadatas.map((e) => e.getId())];
  }

  public getPictureUrl(): string {
    return this.custom.pictureUrl;
  }

  public setPictureUrl(pictureUrl: string): Formula {
    this.custom.pictureUrl = pictureUrl;
    return this;
  }

  public isNormal(): boolean {
    return this.custom.normal;
  }

  public setNormal(normal: boolean): Formula {
    this.custom.normal = normal;
    return this;
  }

  public isXiaoFang(): boolean {
    return this.custom.xiaoFang;
  }

  public setXiaoFang(xiaoFang: boolean): Formula {
    this.custom.xiaoFang = xiaoFang;
    return this;
  }

  public isJingFang(): boolean {
    return this.custom.jingFang;
  }

  public setJingFang(jingFang: boolean): Formula {
    this.custom.jingFang = jingFang;
    return this;
  }

  public toDatatableRow(): any {
    return {
      _id: this.getId(),
      pinYinName: this.getPinYinName(),
      ...flattenTranslatableAttributeToJson(
        this.getDescription(),
        'description'
      ),
      published: this.isPublished(),
    };
  }

  public toInput(): any {
    return {
      id: this.getId(),
      published: this.isPublished(),
      custom: this.getCustomInput(),
    };
  }

  public getCustomInput(): any {
    return {
      ...this.getCustom(),
      composition: this.getComposition().map((e) => e.toInput()),
    };
  }

  public getMetadataType(): ProductMetadataType {
    return 'formula';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public parseEntity(lng: KiwayLanguagesType): AutocompleteResultMatches {
    return {
      mainDisplayName: `${this.getPinYinName()}`,
      otherDisplayNames: [],
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public toString(lng: KiwayLanguagesType): string {
    return `${this.getPinYinName()}`;
  }
}

type AutocompleteResultMatches = {
  mainDisplayName: string;
  otherDisplayNames: string[];
};
