import { PlantGraphQLProvider } from '../../dataproviders/graphql-client/PlantGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllPlants } from '../../core/use_cases/GetAllPlants';
import {
  Plant,
  PlantMetadataSearchReturn,
  IPlantAttributes,
  PlantArrays,
} from '../../core/entities/Plant';
import { SearchPlantMetadata } from '../../core/use_cases/SearchPlantMetadata';
import { GetPlant } from '../../core/use_cases/GetPlant';
import { EditManyPlants } from '../../core/use_cases/EditManyPlants';
import { AddItemToPlantArrays } from '../../core/use_cases/AddItemToPlantArrays';
import { RemoveItemFromPlantArrays } from '../../core/use_cases/RemoveItemFromPlantArrays';
import { PaginatedResults } from '@kiway/shared/react-types';

class PlantsGateway {
  protected static instance: PlantsGateway;
  protected addItemToPlantArrays: AddItemToPlantArrays;
  protected editManyPlants: EditManyPlants;
  protected getAllPlants: GetAllPlants;
  protected getPlant: GetPlant;
  protected removeItemFromPlantArrays: RemoveItemFromPlantArrays;
  protected useCaseSearchPlantMetadata: SearchPlantMetadata;

  public static getInstance(client: ApolloClient<any>): PlantsGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new PlantsGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const plantProvider = new PlantGraphQLProvider(client);
    this.addItemToPlantArrays = new AddItemToPlantArrays(plantProvider);
    this.editManyPlants = new EditManyPlants(plantProvider);
    this.getAllPlants = new GetAllPlants(plantProvider);
    this.getPlant = new GetPlant(plantProvider);
    this.removeItemFromPlantArrays = new RemoveItemFromPlantArrays(
      plantProvider
    );
    this.useCaseSearchPlantMetadata = new SearchPlantMetadata(plantProvider);
    this.addMetadataToPlant = this.addMetadataToPlant.bind(this);
    this.findAll = this.findAll.bind(this);
    this.listCatalogPlants = this.listCatalogPlants.bind(this);
    this.findOne = this.findOne.bind(this);
    this.editPlants = this.editPlants.bind(this);
    this.removeMetadataFromPlant = this.removeMetadataFromPlant.bind(this);
    this.searchPlantMetadata = this.searchPlantMetadata.bind(this);
    PlantsGateway.instance = this;
  }

  async addMetadataToPlant(
    plantId: string,
    itemId: string,
    itemType: PlantArrays
  ): Promise<Plant> {
    return await this.addItemToPlantArrays.execute(plantId, itemId, itemType);
  }

  async editPlants(plants: IPlantAttributes[]): Promise<Plant[]> {
    return await this.editManyPlants.execute(plants, null);
  }

  async findAll(): Promise<PaginatedResults<Plant>> {
    return await this.getAllPlants.execute({}, { page: 1, perPage: -1 });
  }

  async listCatalogPlants(
    page?: number,
    nbResultsPerPage?: number
  ): Promise<PaginatedResults<Plant>> {
    return await this.getAllPlants.execute(
      { published: true },
      { page, perPage: nbResultsPerPage }
    );
  }

  async findOne(plantId: string): Promise<Plant> {
    return await this.getPlant.execute(plantId);
  }

  async removeMetadataFromPlant(
    plantId: string,
    itemId: string,
    itemType: PlantArrays
  ): Promise<Plant> {
    return await this.removeItemFromPlantArrays.execute(
      plantId,
      itemId,
      itemType
    );
  }

  async searchPlantMetadata(
    search: string
  ): Promise<PlantMetadataSearchReturn> {
    return await this.useCaseSearchPlantMetadata.execute(search);
  }
}

export function usePlantsGateway() {
  const client = useApolloClient();
  const plantsGateway = PlantsGateway.getInstance(client);
  return {
    addMetadataToPlant: plantsGateway.addMetadataToPlant,
    editPlants: plantsGateway.editPlants,
    findAll: plantsGateway.findAll,
    listCatalogPlants: plantsGateway.listCatalogPlants,
    findOne: plantsGateway.findOne,
    removeMetadataFromPlant: plantsGateway.removeMetadataFromPlant,
    searchPlantMetadata: plantsGateway.searchPlantMetadata,
  };
}
