import * as React from 'react';
import { SearchProductMetadataAutocomplete } from './SearchProductMetadataAutocomplete';
import { useTranslation } from '@kiway/shared/utils/translation';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {
  Formula,
  FormulaArrays,
  FormulaMetadataSearchReturn,
} from '../../../core/entities/Formula';
import { useTheme } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { IProductMetadataSearchable } from '../../../core/entities/ProductMetadata';
import {
  Plant,
  PlantArrays,
  PlantMetadataSearchReturn,
} from '../../../core/entities/Plant';

interface SearchProductMetadataAutocompleteControllerProps {
  handleSelect: (
    type: FormulaArrays | PlantArrays,
    value: IProductMetadataSearchable
  ) => any;
  product: Formula | Plant;
  searchCallback: (
    value: string
  ) => Promise<FormulaMetadataSearchReturn | PlantMetadataSearchReturn>;
}

export function SearchProductMetadataAutocompleteController({
  handleSelect,
  product,
  searchCallback,
}: SearchProductMetadataAutocompleteControllerProps) {
  const theme: Theme = useTheme();
  const { t, currentLanguage } = useTranslation();
  const [results, setResults] = React.useState<
    Array<IProductMetadataSearchable>
  >([]);
  const [initResults, setInitResults] = React.useState<
    Array<IProductMetadataSearchable>
  >([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>('');

  const search = (
    value: string
  ): Promise<Array<IProductMetadataSearchable>> => {
    setLoading(true);
    return searchCallback(value)
      .then(async (data) => {
        // eslint-disable-next-line prefer-spread
        const dataArray = [].concat.apply([], Object.values(data || {}));
        setResults(dataArray);
        return dataArray;
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    search('').then((dataArray) => {
      setInitResults(dataArray);
    });
    return () => {
      setInitResults([]);
    };
  }, []);

  return (
    <SearchProductMetadataAutocomplete<IProductMetadataSearchable>
      data={results}
      getOptionDisabled={(option) =>
        product?.getMetadataIds()?.includes(option?.getId())
      }
      getOptionLabel={(option: IProductMetadataSearchable) =>
        option.toString(currentLanguage?.code)
      }
      groupBy={(option) => {
        return t(
          `pharmaco:productMetadatas.${option?.getMetadataType()}.groupByLabel`
        );
      }}
      handleSearch={(event, value, reason) => {
        if (reason === 'reset') {
          return;
        }
        if (reason === 'input' && value?.length > 1) {
          search(value);
        } else {
          setResults(initResults);
        }
        setValue(value);
      }}
      handleSelect={(event, value, reason) => {
        if (reason === 'select-option') {
          handleSelect(value?.getMetadataType(), value);
        }
        setValue('');
        setResults(initResults);
      }}
      label={t('pharmaco:search.search')}
      loading={loading}
      noOptionsText={t('pharmaco:search.none')}
      renderOption={(option: IProductMetadataSearchable, { inputValue }) => {
        const { mainDisplayName, otherDisplayNames } = option.parseEntity(
          currentLanguage?.code
        );

        const matches = match(mainDisplayName, inputValue, {
          insideWords: true,
          findAllOccurrences: true,
        });
        const parts = parse(mainDisplayName, matches);

        const otherParts = otherDisplayNames?.map((item) => {
          const matches = match(item, inputValue, {
            insideWords: true,
            findAllOccurrences: true,
          });
          return parse(item, matches);
        });

        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                  color: part.highlight
                    ? theme.palette.primary.main
                    : undefined,
                  textDecoration: part.highlight ? 'underline' : undefined,
                }}
              >
                {part.text}
              </span>
            ))}
            {otherParts?.length > 0 ? (
              <span style={{ fontStyle: 'italic' }}>
                {' ('}
                {otherParts.map((parts, index, self) => {
                  return (
                    <React.Fragment key={index}>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                            color: part.highlight
                              ? theme.palette.primary.main
                              : undefined,
                            textDecoration: part.highlight
                              ? 'underline'
                              : undefined,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                      {index < self.length - 1 ? ', ' : ''}
                    </React.Fragment>
                  );
                })}
                {')'}
              </span>
            ) : null}
          </div>
        );
      }}
      value={value}
    />
  );
}
