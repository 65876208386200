/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import { Formula } from '../../../core/entities/Formula';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useStyles } from '../shared';
import { kiwayLanguages } from '@kiway/shared/react-types';
import { useTranslation } from '@kiway/shared/utils/translation';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';
import { ProductDetailsFieldWrapper } from '@kiway/ecommerce-react';
import { Skeleton } from '@material-ui/lab';

export interface FormulaDetailsHeaderProps {
  handleChange: (type: string) => (value: string | number | boolean) => any;
  handleSave: (type: string) => (id?: string) => void;
  loading?: boolean;
  formula: Formula;
}

export function FormulaDetailsHeader({
  handleChange,
  handleSave,
  loading,
  formula,
}: React.PropsWithChildren<FormulaDetailsHeaderProps>) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          {formula?.getLegacyId() ? (
            <Grid item xs={12}>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Typography align="center" variant="caption">
                  {t('pharmaco:formula.legacyId')} {formula?.getLegacyId()}
                </Typography>
              </div>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={10}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ProductDetailsFieldWrapper
                  handleChange={handleChange('pinYinName')}
                  handleSave={handleSave('pinYinName')}
                  loading={loading}
                  inputType="text"
                  label="pharmaco:formula.pinYinName"
                  value={formula?.getPinYinName()}
                  style={{
                    fontSize: 24,
                    textAlign: 'center',
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={1}>
                    {kiwayLanguages?.map((code) => (
                      <Grid item xs={12} key={code}>
                        <ProductDetailsFieldWrapper
                          handleChange={handleChange(`description.${code}`)}
                          handleSave={handleSave(`description.${code}`)}
                          loading={loading}
                          inputType="text"
                          label={{
                            label: 'pharmaco:formula.description',
                            params: {
                              code: (
                                code || getFallbackLanguage()
                              )?.toUpperCase(),
                            },
                          }}
                          value={formula?.getDescription()?.[code]}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('published')}
                        handleSave={handleSave('published')}
                        loading={loading}
                        inputType="checkbox"
                        label={'pharmaco:formula.published'}
                        value={formula?.isPublished()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('normal')}
                        handleSave={handleSave('normal')}
                        loading={loading}
                        inputType="checkbox"
                        label={'pharmaco:formula.normal'}
                        value={formula?.isNormal()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('xiaoFang')}
                        handleSave={handleSave('xiaoFang')}
                        loading={loading}
                        inputType="checkbox"
                        label={'pharmaco:formula.xiaoFang'}
                        value={formula?.isXiaoFang()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('jingFang')}
                        handleSave={handleSave('jingFang')}
                        loading={loading}
                        inputType="checkbox"
                        label={'pharmaco:formula.jingFang'}
                        value={formula?.isJingFang()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className={classes.imageContainer}>
              {loading ? (
                <Skeleton variant="rect" width={110} height={160} />
              ) : formula?.getPictureUrl() ? (
                <img className={classes.image} src={formula?.getPictureUrl()} />
              ) : null}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
