import * as React from 'react';
import { Suspense } from 'react';
import { RouteComponentProps, Switch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import * as ReactGA from 'react-ga';
import { useTranslation } from '@kiway/shared/utils/translation';
import { firstUpperCase } from '@kiway/shared/utils/string';
import { BrowserView, MobileView } from 'react-device-detect';
import { MobileSafeArea } from '@kiway/shared/ui';

type PageName = {
  url: string;
  path: string;
};

export interface IRoute {
  // Id of the route, used to avoid duplicates
  id: string;
  // Path, like in basic prop
  path: string | string[];
  // Exact, like in basic prop
  exact: boolean;
  // Preloader for lazy loading
  fallback?: NonNullable<React.ReactNode> | null;
  // Lazy Loaded component
  component?:
    | React.LazyExoticComponent<React.ComponentType<any>>
    | React.ComponentType<any>;
  parseMatchParams?: (matchParams: any) => any;
  // If router is private, this is going to be true
  private?: boolean;
  // If router is accessible only when the user is logged out
  publicOnly?: boolean;
  // The user need to be allowed for accessing this resource: see access control, we are about to change this behavour
  // Redirect path
  redirect?: string;
  resource?: string;
  // The user need to be granted with this role for accessing this route: see access control
  role?: string;
  // Sub routes
  routes?: IRoute[];
  // The user need to be subscribed to this service for accessing this route: see access control
  service?: string;
  // The document title is composed like that : '{title} - {subtitle} - {description}'
  // The page title, to overwrite "process.env.NX_APPBAR_NAME"
  title?: string;
  // The page subtitle, to overwrite "Plateforme spécialisée MTC"
  subtitle?: string;
  // The page description, to be displayed after the page subtitle
  description?: string;
  // The page name is used for reference with Google Analytics, it could be a string or a function returning a string
  pageName?: string | ((props: RouteComponentProps) => PageName);
  // Used to add route under an AppShell or not
  appShell?: boolean;
  // Used to add route under an AppShell or not when mobile device
  hideAppShellOnMobile?: boolean;
  // Used to order routes
  order?: number;
}

export interface IRouterProps {
  AppShellComponent: React.ComponentType<any>;
  authUtils?: any;
  routes: IRoute[];
  t: (str: string) => string;
}

export interface IRouteProps extends IRoute {
  authUtils?: any;
  // Translation function
  t: (str: string) => string;
}

const SETTINGS_TAB_URI = '/settings/:tab?';

export const RouteWithSubRoutes: React.FC<IRouteProps> = ({
  authUtils,
  t,
  ...route
}) => {
  /** Authenticated flag */
  const authenticated = authUtils?.isAuthenticated();
  /** Role allowed flag */
  const authorized = authUtils?.isAllowedToAccess(route);
  const verified = authUtils?.isEmailVerified();
  const component = (
    <Route
      path={route.path}
      render={(props: RouteComponentProps) => {
        const query = new URLSearchParams(props.location.search);
        /**
         * Register the pageview to Google Analytics
         */
        const { pageName, path: routePath } = route;
        let pathGA = null;
        if (typeof pageName === 'function') {
          const { path, url } = pageName(props);
          pathGA = path === SETTINGS_TAB_URI ? url : path;
        } else {
          pathGA = pageName;
        }
        ReactGA.set({ page: pathGA || routePath });
        ReactGA.pageview(pathGA || routePath);
        /**
         * Change HTML document title
         */
        const { description, subtitle, title } = route;
        if (title || subtitle || description) {
          document.title = `${
            firstUpperCase(t ? t(title) : title) || process.env.NX_APPBAR_NAME
          }${
            subtitle ? ` - ${firstUpperCase(t ? t(subtitle) : subtitle)}` : ''
          }${
            description
              ? ` - ${firstUpperCase(t ? t(description) : description)}`
              : ''
          }`;
        }
        /**
         * Handle necessary redirections or display the component
         */
        const renderComponent = route.component && (
          <route.component
            {...props}
            authUtils={authUtils}
            routes={route.routes}
            {...(route.parseMatchParams
              ? route.parseMatchParams(props.match.params)
              : {})}
          />
        );
        return route.redirect ? (
          <Redirect to={route.redirect} />
        ) : route.private ? (
          authenticated ? (
            authorized ? (
              verified ? (
                renderComponent
              ) : (
                <MobileSafeArea>
                  You need to verified your email address in order to use the
                  app
                </MobileSafeArea>
              )
            ) : authUtils.ready ? (
              <MobileSafeArea>Insufficient rights</MobileSafeArea>
            ) : (
              <MobileSafeArea>Checking account...</MobileSafeArea>
            )
          ) : (
            <Redirect
              to={{
                pathname: '/signin',
                search: `?redirectURI=${props.location?.pathname}`,
              }}
            />
          )
        ) : route.publicOnly && authenticated ? (
          <Redirect to={query.get('redirectURI') || '/'} />
        ) : (
          renderComponent
        );
      }}
    />
  );
  return route.fallback ? (
    <Suspense fallback={route.fallback}>{component}</Suspense>
  ) : (
    component
  );
};

const sortRoutes = (a: IRoute, b: IRoute) => {
  if (a.path === '*') {
    return 1;
  }
  if (b.path === '*') {
    return -1;
  }
  if (a.appShell && b.appShell) {
    if (a.order !== undefined) {
      if (b.order !== undefined) {
        // a order & b order are defined
        return a.order < b.order ? 1 : a.order > b.order ? -1 : 0;
      } else {
        // a order only is defined
        return a.order < 0 ? 1 : a.order > 0 ? -1 : 0;
      }
    } else if (b.order !== undefined) {
      // b order only is defined
      return b.order < 0 ? -1 : b.order > 0 ? 1 : 0;
    }
    // none a order or b order are defined
    return 0;
  }
  if (a.appShell && !b.appShell) {
    return 1;
  }
  if (!a.appShell && b.appShell) {
    return -1;
  }
};

export const Router: React.FC<IRouterProps> = ({
  AppShellComponent,
  authUtils,
  routes,
  t,
}) => {
  const routesComponents = [];
  const mobileRoutesComponents = [];
  const appShelledComponents = [];
  const mobileAppShelledComponents = [];
  let notFoundRoute = null;
  routes.sort(sortRoutes).map((route: IRoute, index: number) => {
    const component = (
      <RouteWithSubRoutes
        key={
          typeof route.path === 'string'
            ? route.path
            : Array.isArray(route.path) && route.path?.length > 0
            ? route.path[0]
            : index
        }
        authUtils={authUtils}
        t={t}
        {...route}
      />
    );
    if (route.appShell) {
      appShelledComponents.push(component);
      if (!route.hideAppShellOnMobile) {
        mobileAppShelledComponents.push(component);
      } else {
        mobileRoutesComponents.push(component);
      }
    } else {
      if (route.path !== '*') {
        routesComponents.push(component);
        mobileRoutesComponents.push(component);
      } else {
        notFoundRoute = component;
      }
    }
  });
  routesComponents.push(
    <AppShellComponent key={'appShell'}>
      <Switch>{appShelledComponents}</Switch>
    </AppShellComponent>
  );
  routesComponents.push(notFoundRoute);
  mobileRoutesComponents.push(
    <AppShellComponent key={'appShellMobile'}>
      <Switch>{mobileAppShelledComponents}</Switch>
    </AppShellComponent>
  );
  mobileRoutesComponents.push(notFoundRoute);
  return (
    <>
      <BrowserView style={{ height: '100%' }}>
        <Switch>{routesComponents}</Switch>
      </BrowserView>
      <MobileView style={{ height: '100%' }}>
        <Switch>{mobileRoutesComponents}</Switch>
      </MobileView>
    </>
  );
};

const filterUniqueRoutesById = (
  route: IRoute,
  index: number,
  self: Array<IRoute>
) => self.map(({ id }) => id).indexOf(route.id) === index;

export type AuthUtils = {
  isAuthenticated: () => boolean;
  isAllowedToAccess: (route: IRoute) => boolean;
  [name: string]: any;
};

export class RoutingBuilder {
  private static instance: RoutingBuilder;

  private routes: Array<IRoute>;
  private authUtils: AuthUtils;
  private AppShellComponent: React.ComponentType<any>;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getBuilder(): RoutingBuilder {
    if (!RoutingBuilder.instance) {
      RoutingBuilder.instance = new RoutingBuilder();
    }

    return RoutingBuilder.instance;
  }

  setAuthUtils(authUtils: AuthUtils) {
    this.authUtils = authUtils;
  }

  setAppShellComponent(AppShellComponent: React.ComponentType<any>) {
    this.AppShellComponent = AppShellComponent;
  }

  setRoutes(routes: Array<IRoute>) {
    this.routes = [...routes, ...(this.routes || new Array<IRoute>())].filter(
      filterUniqueRoutesById
    );
  }

  addRoute(route: IRoute, atTheBeginning?: boolean): void {
    if (atTheBeginning) {
      this.routes = [route, ...(this.routes || [])].filter(
        filterUniqueRoutesById
      );
    } else {
      this.routes = [...(this.routes || []), route].filter(
        filterUniqueRoutesById
      );
    }
  }

  addRoutes(routes: Array<IRoute>, atTheBeginning?: boolean): void {
    if (atTheBeginning) {
      this.routes = [...routes, ...(this.routes || [])].filter(
        filterUniqueRoutesById
      );
    } else {
      this.routes = [...(this.routes || []), ...routes].filter(
        filterUniqueRoutesById
      );
    }
  }

  render(): JSX.Element {
    const { t } = useTranslation();
    return (
      <Router
        AppShellComponent={this.AppShellComponent}
        authUtils={this.authUtils}
        routes={this.routes}
        t={t}
      />
    );
  }
}
