import { DocumentNode } from 'graphql';
import { gql } from '@apollo/client';
import {
  INotifierAttributes,
  INotifierSettingAttributes,
} from '@kiway/shared/features/notifier';

export type INotifierAttributesData = INotifierAttributes & {
  id: string;
};

export interface ListNotifiersData {
  listNotifiers: Array<INotifierAttributesData>;
}

export type INotifierSettingAttributesData = INotifierSettingAttributes & {
  notifier: INotifierAttributes;
};

export interface ListMyNotifiersSettingsData {
  listMyNotifiersSettings: Array<INotifierSettingAttributesData>;
}

export const LIST_NOTIFIERS: DocumentNode = gql`
  query {
    listNotifiers {
      id
      label
      description
      slug
      availableTypes
    }
  }
`;

export const LIST_MY_NOTIFIERS_SETTINGS: DocumentNode = gql`
  query {
    listMyNotifiersSettings {
      id
      email
      push
      notifier {
        id
        label
        description
        slug
      }
    }
  }
`;
