export function sharedChatbox(): string {
  return 'shared-chatbox';
}

export interface Chatbox {
  hide: () => void;
  show: () => void;
  open: () => void;
  close: () => void;
  init: (websiteId: string) => void;
}

declare global {
  interface Window {
    $crisp: any;
    CRISP_WEBSITE_ID: string;
  }
}

export class CrispChatbox implements Chatbox {
  protected static instance: CrispChatbox;

  static getInstance(): CrispChatbox {
    if (!CrispChatbox.instance) {
      CrispChatbox.instance = new CrispChatbox();
    }
    return CrispChatbox.instance;
  }

  private constructor() {
    // DO NOTHING
  }

  init(websiteId: string): void {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = websiteId;
    (function () {
      const d = document;
      const s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = true;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
    if (window.$crisp?.push) {
      window.$crisp.push(['safe', true]);
      window.$crisp.push(['do', 'chat:hide']);
      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          window.$crisp.push(['do', 'chat:hide']);
        },
      ]);
    }
  }

  hide() {
    window.$crisp.push(['do', 'chat:hide']);
  }

  show() {
    window.$crisp.push(['do', 'chat:show']);
  }

  open() {
    window.$crisp.push(['do', 'chat:open']);
  }

  close() {
    window.$crisp.push(['do', 'chat:close']);
  }
}
