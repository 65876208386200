export default {
  bill: 'Facturar',
  billingInProgress: 'Faturação em progresso...',
  dataTable: {
    columns: {
      actions: 'Ações',
      amount_due: 'Valor pago',
      createdAt: 'Data de emissão',
      description: 'Descrição',
      number: 'Número',
      paid: 'Pago',
      patient: 'Paciente',
      viewPDF: 'Fatura em pdf',
    },
    title: 'Lista de faturas',
  },
  doYouHaveTaxes: 'Você aplica algum imposto?',
  invoicingAlert:
    'Uma vez faturado, não pode voltar atrás. Tem certeza que quer continuar ?',
  noReceiptData:
    'Desculpe, não há nenhum recibo disponível para este pagamento',
  paidAt: 'Pago em',
  patientEmailRequired:
    'O email do paciente é obrigatório para poder enviar o link de pagamento',
  pay: 'Efectuar pagamento',
  payInvoice: 'Efetuar pagamento',
  payment: {
    checkout: {
      cancel: {
        text:
          'Oops, saiu demasiado rápido ... Convidamos a renovar o pedido para efetuar o pagamento.',
        title: 'Pagamento da sua fatura cancelada',
      },
      redirectPage: {
        badStatus: 'Sua fatura já está paga.',
        noConnectedAccount:
          'O módulo de pagamento encontra um problema de configuração, entre em contato com seu profissional.',
        redirecting: 'Você será redirecionado ...',
        title: 'Pagamento da sua fatura',
        unknownError: 'Ocorreu um erro, entre em contato com seu profissional.',
      },
      success: {
        text:
          'Obrigado pelo seu pagamento, agora você pode retornar ao aplicativo.',
        title: 'Pagamento da fatira bem sucedido',
      },
    },
    connectWithStripeText:
      'Para concluir a ativação do serviço "pagamento online", você deve criar ou conectar-se a uma conta Stripe. Esta conta permitirá que você receba e remova os seus fundos. Apenas necessita clicar no botão abaixo e será redirecionado para a plataforma Stripe.',
  },
  paymentDate: 'Data de pagamento',
  paymentMethod: {
    card: 'Cartão de crédito',
    cash: 'Numerário',
    check: 'Cheque',
    kiway: 'Pagamento online',
    label: 'Meios de pagamento',
    title: 'Outros meios de pagamento',
  },
  paymentRef: 'Referência de pagamento',
  paymentSaved: 'Pagamento bem-sucedido',
  payOnline: 'Pague online',
  preview: 'Visualização:',
  receiptModalTitle: 'Recibo de pagamento',
  receiptToString:
    'Fatura pagou a%paymentDate% por "%paymentMethod%", referência de pagamento: %paymentRef%',
  savePayment: 'Gravar o pagamento',
  scanMeToPay: 'Digitalize-me para pagar com seu smartphone',
  seeInvoice: 'Veja a fatura',
  seeReceipt: 'Veja o recibo de pagamento',
  sendPaymentLink:
    'Deseja enviar por email para o seu paciente, um link de pagamento online?',
  sessionInvoicing: 'Fatura da sessão',
  sessionInvoicingConfirm: 'Confirmação da finalização da fatura',
  sessionToBill: 'Ata para a fatura:',
  subTotal: 'Subtotal:',
  successInvoiceFinalized: 'A fatura foi emitida',
  taxes: {
    country: 'País de aplicação do imposto',
    modes: {
      excl: 'Sim, está excluído do valor acima',
      incl: 'Sim, está incluído no valor acima',
      no: 'Não',
    },
    name: 'Nome do imposto',
    percent: 'Valor do imposto (percentagem)',
    percentLimits: 'Entre 0 e 100',
    render: 'Imposto aplicado:',
  },
  toBill: 'A cobrar',
  total: 'Total: ',
  youHaveToActivateStripe:
    'Você deve ativar sua conta Stripe para poder utilizar os pagamentos online',
};
