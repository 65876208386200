import * as React from 'react';
import {
  ProductMetadata,
  ProductMetadataType,
} from '../../../core/entities/ProductMetadata';
import { useProductMetadatasGateway } from '../../../entrypoints/react/useProductMetadatasGateway';
import { ListProductMetadatas } from './ListProductMetadatas';

export function ListProductMetadatasController() {
  const [loading, setLoading] = React.useState(false);
  const [productMetadatas, setProductMetadatas] = React.useState([]);
  const { editMany, findAll } = useProductMetadatasGateway();

  const handleAddData = (type: ProductMetadataType) => () => {
    const data: ProductMetadata = new ProductMetadata({ metadataType: type });
    setProductMetadatas((prev) => [data, ...prev]);
  };

  React.useEffect(() => {
    setLoading(true);
    findAll()
      .then((productMetadatas) => {
        setProductMetadatas(productMetadatas);
      })
      .finally(() => setLoading(false));
    return () => {
      setProductMetadatas([]);
    };
  }, []);

  return (
    <ListProductMetadatas
      handleAddData={handleAddData}
      handleSave={async (updates) => {
        if (editMany) {
          const editedPlantMetadatas = await editMany(updates);
          setProductMetadatas((prev) => [
            ...prev.filter(
              (productMetadata) =>
                productMetadata.getId() &&
                !editedPlantMetadatas
                  .map((productMetadata) => productMetadata.getId())
                  .includes(productMetadata.getId())
            ),
            ...editedPlantMetadatas,
          ]);
        }
      }}
      loading={loading}
      productMetadatas={productMetadatas}
    />
  );
}
