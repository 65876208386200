import * as React from 'react';
import { ProductDetailsCard } from '@kiway/ecommerce-react';
import {
  ProductDetailsMetadataChip,
  ProductDetailsMetadataChipLoader,
} from './ProductDetailsMetadataChip';
import { useTranslation } from '@kiway/shared/utils/translation';
import { ProductMetadata } from '../../../core/entities/ProductMetadata';

export interface ProductDetailsMetadataProps {
  data: Array<ProductMetadata>;
  getLabel: (element: ProductMetadata) => string;
  handleDelete?: (id: string) => any;
  loading?: boolean;
  title: string;
}

export function ProductDetailsMetadata({
  data,
  getLabel,
  handleDelete,
  loading,
  title,
}: React.PropsWithChildren<ProductDetailsMetadataProps>) {
  const { t } = useTranslation();
  return (
    <ProductDetailsCard title={title}>
      {loading ? (
        <ProductDetailsMetadataChipLoader />
      ) : data?.length ? (
        data?.map((element: ProductMetadata) => (
          <ProductDetailsMetadataChip
            key={element.getId()}
            handleDelete={handleDelete}
            label={getLabel(element)}
            id={element.getId()}
          />
        ))
      ) : (
        <div
          style={{ width: '100%', fontStyle: 'italic', textAlign: 'center' }}
        >
          {t('pharmaco:noData')}
        </div>
      )}
    </ProductDetailsCard>
  );
}
