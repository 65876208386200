import { ProductVariationTypeGraphQLProvider } from '../../dataproviders/graphql-client/ProductVariationTypeGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllProductVariationTypes } from '../../core/use_cases/GetAllProductVariationTypes';
import { EditManyProductVariationTypes } from '../../core/use_cases/EditManyProductVariationTypes';
import {
  ProductVariationType,
  IProductVariationTypeAttributes,
} from '../../core/entities/ProductVariationType';

class ProductVariationTypesGateway {
  protected static instance: ProductVariationTypesGateway;
  protected getAllProductVariationTypes: GetAllProductVariationTypes;
  protected editManyProductVariationTypes: EditManyProductVariationTypes;

  public static getInstance(
    client: ApolloClient<any>
  ): ProductVariationTypesGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new ProductVariationTypesGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const productVariationTypeProvider = new ProductVariationTypeGraphQLProvider(
      client
    );
    this.getAllProductVariationTypes = new GetAllProductVariationTypes(
      productVariationTypeProvider
    );
    this.editManyProductVariationTypes = new EditManyProductVariationTypes(
      productVariationTypeProvider
    );
    this.findAll = this.findAll.bind(this);
    this.editMany = this.editMany.bind(this);
    ProductVariationTypesGateway.instance = this;
  }

  async findAll(): Promise<ProductVariationType[]> {
    return await this.getAllProductVariationTypes.execute();
  }

  async editMany(
    productVariationTypes: IProductVariationTypeAttributes[]
  ): Promise<ProductVariationType[]> {
    return await this.editManyProductVariationTypes.execute(
      productVariationTypes
    );
  }
}

export function useProductVariationTypesGateway() {
  const client = useApolloClient();
  const productVariationTypesGateway = ProductVariationTypesGateway.getInstance(
    client
  );
  return {
    findAll: productVariationTypesGateway.findAll,
    editMany: productVariationTypesGateway?.editMany,
  };
}
