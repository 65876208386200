// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  ShippingMethod,
  ShippingMethodPrice,
  useShippingMethodsGateway,
  useCarriersGateway,
  Carrier,
} from '@kiway/ecommerce-react';
import { useTranslation } from '@kiway/shared/utils/translation';
import * as React from 'react';
import { useShippingMethodPricesGateway } from '../../../entrypoints/react/useShippingMethodPricesGateway';
import { ListShippingMethodPrices } from './ListShippingMethodPrices';

export function ListShippingMethodPricesController() {
  const [loading, setLoading] = React.useState(false);
  const [shippingMethodPrices, setShippingMethodPrices] = React.useState([]);
  const [shippingMethodsList, setShippingMethodsList] = React.useState<
    Array<ShippingMethod>
  >([]);
  const {
    editShippingMethodPrices,
    findAll: findAllShippingMethodPrices,
  } = useShippingMethodPricesGateway();
  const { findAll: findAllShippingMethods } = useShippingMethodsGateway();
  const { findAll: findAllCarriers } = useCarriersGateway();
  const { currentLanguage } = useTranslation();
  const [filterShippingMethods, setFilterShippingMethods] = React.useState<
    string | 'ALL'
  >('ALL');

  React.useEffect(() => {
    setLoading(true);
    findAllShippingMethodPrices()
      .then((shippingMethodPrices) => {
        setShippingMethodPrices(shippingMethodPrices);
      })
      .finally(() => setLoading(false));
    return () => {
      setShippingMethodPrices([]);
    };
  }, []);

  React.useEffect(() => {
    findAllShippingMethods().then((shippingMethods) => {
      setShippingMethodsList(shippingMethods);
    });
    return () => {
      setShippingMethodsList([]);
    };
  }, []);

  const handleAddData = () => {
    const data = new ShippingMethodPrice({});
    setShippingMethodPrices((prev) => [data, ...prev]);
  };

  const handleClearAdd = () => {
    setShippingMethodPrices((prev) =>
      prev.filter((shippingMethodPrice) => shippingMethodPrice.getId())
    );
  };

  const filterShippingMethodPriceByShippingMethod = (
    shippingMethod: string | 'ALL'
  ) => (shippingMethodPrice: ShippingMethodPrice) => {
    return (
      shippingMethod === 'ALL' ||
      shippingMethodPrice.getShippingMethod()?.getId() === shippingMethod
    );
  };

  return (
    <ListShippingMethodPrices
      loading={loading}
      availableShippingMethods={shippingMethodsList.map((shippingMethod) =>
        shippingMethod.toDropdownValue(currentLanguage?.code)
      )}
      shippinMethodsRaw={shippingMethodsList}
      shippingMethodPrices={shippingMethodPrices.filter(
        filterShippingMethodPriceByShippingMethod(filterShippingMethods)
      )}
      filterShippingMethods={filterShippingMethods}
      setFilterShippingMethods={setFilterShippingMethods}
      handleAddData={handleAddData}
      handleClearAdd={handleClearAdd}
      handleSave={async (updates) => {
        if (editShippingMethodPrices) {
          const editedShippingMethodPrices = await editShippingMethodPrices(
            updates
          );
          setShippingMethodPrices((prev) => [
            ...prev.filter(
              (shippingMethodPrice) =>
                shippingMethodPrice.getId() &&
                !editedShippingMethodPrices
                  .map((shippingMethodPrice) => shippingMethodPrice.getId())
                  .includes(shippingMethodPrice.getId())
            ),
            ...editedShippingMethodPrices,
          ]);
        }
      }}
    />
  );
}
