export default {
  address: {
    addAddress: 'Adicionar uma morada',
    address: 'Morada',
    city: 'Cidade',
    country: 'País',
    fullName: 'Nome completo',
    label: 'Morada',
    number: 'número da rua',
    useAddress: 'Usar esta morada',
    useAsDefault: 'Usar como morada padrão',
    validate: 'Validar mudanças',
    zipCode: 'Código postal',
  },
  backToList: 'de volta à lista',
  backToOrderDetails: 'De volta aos detalhes do pedido',
  browser: {
    addCart: '',
    all: 'Todos',
    emptyCart: '',
    formula: 'Fórmula',
    ingredients: 'Ingredientes',
    jingFang: 'Jing fang',
    noFilter: 'Sem filtro',
    normal: 'Normal',
    noSelected: 'Sem plantas selecionadas',
    plant: 'Plantas',
    powder: 'Pós / Granulados Concentrados',
    sentence: {
      all: 'todos os itens',
      contains: 'que contêm',
      display: 'Você exibe',
      formulas_jingFang: 'fórmulas de Jing Fang',
      formulas_normal: 'fórmulas normais',
      formulas_xiaoFang: 'fórmulas de  FangXiao',
      have_action: 'todo',
      have_category: 'que tem a categoria',
      have_syndrom: 'todo',
      ingredients_all: 'todos os ingredientes',
      ingredients_plant: 'os ingredientes disponíveis em plantas secas',
      ingredients_powder: 'os ingredientes disponíveis em pó concentrado',
    },
    xiaoFang: 'Xiao Fang',
  },
  button: {
    discard: 'Cancelar',
    save: 'Registro',
  },
  cancel: 'Cancelar',
  cancelOrder: {
    message: '',
    title: '',
  },
  carriers: {
    code: {
      helperText: 'Útil para o desenvolvimento, não modificar',
      label: 'Código de automatização',
    },
    docSubtitle: 'Lista de transportadoras',
    label: {
      en: 'Nome EN',
      es: 'Nome ES',
      fr: 'Nome FR',
      pt: 'Nome PT',
    },
    linkText: 'Transportadoras',
    trackingLink: {
      helperTextError:
        'Substitua o numero de seguimento no link {{trackingNumber}}',
      label: 'Link para seguimento do pedido',
    },
  },
  checkout: {
    acceptation: 'Eu aceito a política de privacidade de Yao Dao',
    address: 'Verificação de morada',
    approvment: 'Eu aprovo as condições de venda',
    cardPaymentSuccess: 'Pagamento efetuado com êxito',
    confirmAddress: 'Eu confirmo a precisão desta informação',
    confirmAddressMessage:
      'Verifique se as informações da morada estão corretass. Não nos responsabilizamos no caso de não entrega do seu pedido.',
    confirmation: 'Confirmação',
    deferredPaymentSuccess: 'Lembre-se de enviar o seu pagamento',
    deliveryPayment: 'Entrega e pagamento',
    errorDeferredPayment:
      'Ocorreu um erro durante o pagamento. Entre em contato com um administrador.',
    errorSnackbarAddress:
      'Morada invalida, preencha os campos obrigatórios indicados com *',
    homeReturn: 'Voltar ao Inicio',
    proSpaceReturn: 'Voltar ao espaço profissional',
    wish:
      'Desejo receber emails da Yao Dao que me informem sobre ofertas, promoções, novidades, estágios e formações relacionados à farmacopeia chinesa e à medicina tradicional chinesa.',
  },
  composition: {
    dosage: 'Dosagem',
    name: 'nome',
    title: 'Composição',
  },
  countries: {
    docSubtitle: 'Lista de países',
    label: {
      code: 'Código ISO',
      name: {
        en: 'Nome EN',
        es: 'Nome ES',
        fr: 'Nome FR',
        pt: 'Nome  PT',
      },
      vatPlant: 'IVA Plantas',
      vatPowder: 'IVA CP',
    },
    linkText: 'País',
  },
  countryZones: {
    countries: 'Lista de países',
    docSubtitle: 'Lista de áreas do país',
    label: {
      name: 'Nome',
    },
    linkText: 'Áreas do país',
  },
  dashboard: {
    analytics: {
      avgCart: '',
      charts: {
        orderStatus: '',
        preparerStats: '',
      },
      extra: {
        period: {
          month: '',
          prevMonth: '',
          prevQuarter: '',
          prevWeek: '',
          prevYear: '',
          quarter: '',
          today: '',
          week: '',
          year: '',
          yesterday: '',
        },
        profit: {
          less: '',
          more: '',
        },
        type: {
          clients: '',
          orders: '',
        },
      },
      HT_HT: '',
      nbOrder: '',
      orderCancelled: '',
      orderCompleted: '',
      periods: {
        lastQuarter: '',
        month: '',
        prevMonth: '',
        prevWeek: '',
        prevYear: '',
        quarter: '',
        today: '',
        week: '',
        year: '',
        yesterday: '',
      },
      revenue: '',
      running: '',
      title: '',
      totalClients: '',
      totalOrders: '',
    },
  },
  deleteOrder: {
    message: '',
    title: '',
  },
  filters: {
    carriers: {
      all: 'Tudo',
      label: 'Transportadora',
    },
    metadatas: {
      action: 'todo',
      formulasCategory: 'Selecionar uma categoria de fórmulas',
      ingredientsCategory: 'Selecionar uma categoria de ingredientes',
      syndrom: 'todo',
    },
    shippingMethods: {
      all: 'Tudo',
      label: 'Método de Entrega',
    },
    variations: {
      all: 'Tudo',
      label: 'Condicionamento',
    },
  },
  formula: {
    clickPlantToSeeMore: 'Selecione uma planta para ver a sua ficha',
    concentration: 'Taxa de concentração',
    description: 'Fang zheng {{code}}',
    detailsDocSubtitle: 'Detalhes da fórmula',
    detailsLinkText: 'Detalhes das fórmulas',
    detailsSuccessUpdate_one: 'Modificação registrada',
    detailsSuccessUpdate_other: 'Modificações registradas',
    docSubtitle: 'Lista de fórmulas',
    dosage: 'Dosagem',
    dosagePlant: 'Dosagem em plantas secas',
    dosagePowder: 'Dosagem em granulados concentrados',
    equivalentCP: 'Equiv. GC',
    equivalentPS: 'Equiv. PS',
    fangZheng: 'Fang Zheng',
    jingFang: 'Jing Fang',
    legacyId: 'ID antiga:',
    linkText: 'Fórmulas',
    listTitle: 'Fórmulas',
    normal: 'Normal',
    percent: '%',
    pinYinName: 'Nome Pinyin',
    priceHT: 'Preço sem taxas',
    print: 'Imprima a fórmula',
    published: 'Publicado',
    separate: 'Separado',
    total: 'Total',
    totalWeight: 'Peso total',
    variation: 'Variação',
    weightPerCapsule: 'Por cápsula',
    weightPerPortion: 'Por saco',
    xiaoFang: 'Xiao Fang',
  },
  lastOrders: {
    title: 'Últimas encomendas',
  },
  linkText: 'Farmacopeia',
  list: {
    docSubtitle: 'Lista de plantas',
    linkText: 'Plantas',
  },
  loading: 'Carregando...',
  modify: 'Editar',
  next: 'Seguinte',
  noData: 'Sem dados',
  order: {
    addToOrder: 'Adicione a seleção ao pedido',
    aknowledgement: 'Obrigado pelo seu pedido!',
    commandDetails: {
      command: 'Encomendado',
      commandDetails: 'Detalhes do pedido',
      date: 'Data',
      product: 'Produtos',
      quantity: 'Quantidade',
      sentTo: 'Enviado para',
    },
    emptyOrder: 'Pedido vazio',
    newOrder: 'Novo Pedido',
    payNowButton: 'Pague agora',
    resumeMessage:
      'A sua encomenda foi validada, o paciente receberá um e-mail com uma ligação de pagamento. Pode agora pagá-lo utilizando o botão abaixo.',
    steps: {
      comments: 'Comentários',
      prescription: 'Cesto ',
      resume: 'Confirmação',
      shipping: 'Entrega',
    },
  },
  orderCount: 'Número de encomendas',
  orderDetails: {
    buttons: {
      addItemsToOrder: 'Adicionar produtos ao pedido',
      dispatch: 'Expedir',
      followPackage: 'Seguir o meu pedido',
      invoice: 'Fatura',
      mailReminder: 'Lembrete',
      markAsPaid: 'Marcar como pago',
      prepare: 'Preparar',
      seePayment: 'Ver pagamento',
      sendMail: 'Consulte o email',
      sendTracking: 'Retornar segue -P -up',
      startPrepare: 'Inicie a preparação',
    },
    cartPrice: 'Preço do cesto de compras',
    changePatient: 'Mudar de paciente',
    client: {
      address1: 'Morada 1',
      address2: 'Morada 2',
      city: 'Cidade',
      country: 'País',
      email: 'E-mail',
      firstName: 'Nome',
      indicator: 'Indicador',
      label: 'Paciente',
      lastName: 'Apelido',
      mobilePhone: 'Telefone móvel',
      nif: 'Nif',
      zip: 'Código postal',
    },
    comment: {
      assistantComment: 'Comentário para o preparador',
      default: 'Ø',
      label: 'Observação',
      none: 'Sem comentários',
      ownComment: 'Comentário para si mesmo',
      patientComment: 'Comentário para o paciente',
    },
    confirmAddresses: 'Confimação de morada',
    docSubtitle: 'Detalhes do pedido',
    editAddresses: 'Editar morada',
    formula: {
      formulaCount_one: '{{ count }} fórmula:',
      formulaCount_other: '{{ count }} fórmulas:',
      label: 'Fórmulas',
      name: 'nome',
      pinYin: 'Pin Yin',
      priceHT: 'Preço sem tacas',
      quantity: 'Quantidade',
      ref: 'Referência',
      separate: 'Separado',
      total: 'Total',
      variation: 'Variação',
    },
    global: {
      label: 'Status geral',
      status: {
        ALL: 'Todos os pedidos',
        CANCELLED: 'Cancelado',
        COMPLETE: 'Finalizado',
        DRAFT: 'Rascunho ',
        ERROR_PACKING: 'Erro',
        ERROR_SYNC_STATUS: 'Erro',
        PENDING_PACKING: 'Em preparação',
        PENDING_PAYMENT: 'pagamento pendente',
        PENDING_SHIPMENT: 'Envio em progresso',
        VALIDATED: '',
        WAITING_PACKING: 'Esperando a preparação',
        WAITING_PAYMENT: 'À espera de pagamento',
        WAITING_SHIPMENT: 'Aguardando envio',
      },
    },
    information: {
      itemNumber: 'Artigo',
      label: 'Informação',
      packaging: 'Embalagem',
      paymentMode: 'Forma de pagamento',
      paymentStatus: 'Status do pagamento',
      preparationStatus: 'Status da preparação',
      shippingMethod: 'Tipo de envio',
      shippingPrice: 'Valor do envio',
      totalWeight: 'Peso total',
      weight: 'Peso',
    },
    mailReminder: {
      confirmError:
        'Ocorreu um problema no envio do email. Por favor, tente novamente.',
      confirmSuccess:
        'O email contendo a ligação de pagamento foi enviado para o cliente no endereço de e-mail associado ao endereço de entrega.',
      confirmText:
        'Tem a certeza que quer enviar por email o link de pagamento de volta para o cliente?',
      confirmTitle: 'Reenviar a ligação de pagamento por email',
    },
    mailTracking: {
      confirmError:
        'Ocorreu um problema durante o envio do e-mail. Por favor, tente novamente',
      confirmSuccess:
        'O email contendo o link de rastreio foi enviado para o cliente no endereço de e-mail associado ao endereço de entrega.',
      confirmText:
        'Tem a certeza de que quer enviar por e-mail o link de rastreio de volta para o cliente?',
      confirmTitle: 'Reenviar o link de localização por e-mail',
    },
    openOrder: 'Abra o pedido',
    order: {
      deliveryAddress: 'Morada de entrega',
      finishPreparation: 'Terminar o pedido e sair',
      finishShipping: 'terminar o envio e sair',
      label: 'Encomenda',
      newAddress: 'Nova morada',
      paymentAddress: 'Endereço de faturação',
      sameAddress: 'Endereço de faturação idêntico ao endereço de entrega',
      status: {
        CANCELLED: 'Cancelado',
        COMPLETE: 'Finalizado',
        DRAFT: 'Rascunho áspero',
        OPEN: 'Aberto',
      },
      validAddress: 'Favor preencha um endereço válido',
    },
    packing: {
      label: 'Preparação',
      preparedAt: 'Preparado em',
      preparedBy: 'Preparado por',
      progress: 'Em Progresso',
      status: {
        DELAYED: 'Atrasado',
        PENDING: 'Em andamento',
        PREPARED: 'Preparado',
        READY: 'Esperando a preparação',
        WAITING: 'Esperando',
      },
      weight: {
        content: 'Contente',
        error: 'O peso total deve ser maior ou igual ao do conteúdo',
        packing: 'Embalagem',
        total: 'Peso total da encomenda',
      },
    },
    payment: {
      amount: 'Valor',
      commandPaymentTitle: 'Encomenda n°{{ orderNumber }} pagamento',
      docSubtitle: 'Lista de pagamentos',
      forms: {
        address: 'Endereço',
        bank: 'Banco',
        bankTransfer: 'transferência bancária',
        bankTransferDescription:
          'Depois de realizar a transferência bancária, anexe a prova da transferência (prova editada pelo seu banco) como um anexo através do Escolha um arquivo',
        bankTransferInformationLabel:
          'Informação sobre transferências bancárias',
        card: 'Cartão',
        cardDescription: 'Pagamento do cartão bancário: fácil e seguro',
        cardNumber: 'Número do cartão',
        check: 'Cheque bancário',
        checkDescription:
          'Reservado apenas para cheques franceses. Preencha o formulário abaixo antes de enviar o cheque.',
        checkInformationLabel: 'Verifique a informação',
        checkNumber: 'Verifique o número',
        chooseFile: 'Escolha um arquivo',
        cvv: 'Cvv',
        expirationDate: 'Data de validade',
        firstName: 'Primeiro nome',
        lastName: 'nome',
        nameOnCard: 'Nome no cartão',
        order: 'Pedido',
        resume: 'Resumo',
        sendProof: '',
      },
      label: 'Pagamento',
      linkText: 'Pagamentos',
      paidAt: 'Pague',
      pay: 'Pagar',
      paymentFees: 'Taxa de pagamento',
      paymentMethod: 'Método de pagamento',
      simulate: 'Simular um pagamento',
      status: {
        BALANCE_DUE: 'De',
        CREDIT_OWED: 'Restituição devida',
        DRAFT: 'Rascunho',
        FAILED: 'Falhou',
        PAID: 'Pago',
        PENDING: 'Em andamento',
        WAITING: 'Em espera',
      },
    },
    reportError: 'Relatar um erro',
    selectPatient: 'Selecione um paciente',
    sendMail: 'Enviar um email',
    shipment: {
      carrierLabel: {
        canNotGenerateLabel:
          'A geração automática de etiquetas não está disponível para este transportador',
        displayLabelNewTab: 'Abrir num novo separador',
        generateLabel: 'Gerar etiqueta',
        label: 'Etiqueta da encomenda',
      },
      coordonates: 'Informações de Contato',
      deliveryInfo: 'informação de entrega',
      label: 'Expedição',
      selectMethod: 'Selecione um método de envio',
      sentAt: '',
      sentBy: 'Enviado por',
      shipmentMethod: 'Método de Entrega',
      shipmentMethodCost: 'Preço de envio',
      status: {
        BACKORDER: 'Envio atrasado',
        DELAYED: 'Atrasado',
        PENDING: 'Envio em andamento',
        READY: 'Aguardando envio',
        SHIPPED: 'Enviado',
        WAITING: 'Em espera',
      },
      trackingNumber: 'Numero de rastreio',
      transporter: {
        label: 'Transporte',
        method: 'Método de Entrega',
        name: 'O nome do transportador',
        price: 'Custo de entrega',
      },
      weighing: 'Pesagem',
    },
    status: 'Status',
    therapist: {
      dispenser: 'Preparador',
      label: 'Terapeuta',
      sender: 'Remetente',
    },
  },
  orders: {
    columns: {
      carrier: 'Operadora',
      customer: 'Cliente',
      customerPhone: 'Celular do cliente',
      globalStatus: 'Status',
      nbGroups: 'Fórmulas',
      nbItems: 'Linhas',
      nbOfBags: 'Sacos',
      openedAt: 'Salvo',
      orderNumber: 'Número',
      orderStatus: 'Status do pedido',
      packingStatus: 'Status de preparação',
      paidAt: 'Pago',
      patient: 'Paciente',
      paymentStatus: 'Status do pagamento',
      prescriber: 'Prescritor',
      shipmentStatus: 'Status do envio',
      shipping: 'Envio',
      shippingMethod: 'Método de envio',
      shippingPrice: 'Custo de envio',
      totalGrossPrice: 'Total sem taxas',
      totalNetPrice: 'Preço total',
      totalNetPriceWT: 'Preço total',
      totalPrice: 'Total a pagar',
      variationTypes: 'Modelo',
    },
    docSubtitle: 'Lista de Encomendas',
    linkText: 'Encomendas',
    orderResume: 'Resumo da encomenda',
  },
  plant: {
    availableConcentratedPowder: 'todo',
    availablePlant: 'todo',
    chineseName: 'Nome chinês',
    commonName: 'Nome comum',
    concentratedPowder: 'Pó concentrado',
    concentrationRate: 'Taxa de concentração',
    detailsDocSubtitle: 'Detalhes da planta',
    detailsLinkText: 'Detalhes da planta',
    detailsSuccessUpdate_one: 'Modificação registrada',
    detailsSuccessUpdate_other: 'Modificações registradas',
    dryPlant: 'Planta seca',
    latinName: 'Nome latino',
    legacyId: 'ID antiga:',
    listTitle: 'Plantas',
    otherName: 'Outros nomes',
    pinYinName: 'Nome Pinyin',
    plant: 'Plantar',
    posology: 'Dosagem',
    posologyMax: 'Dosagem máxima',
    posologyMin: 'Dosagem min',
    published: 'Publicado',
    refStock: 'Stock ref',
    refStockPlant: 'Planta stock ref',
    refStockPowder: 'CP stock ref',
    sellAvailable: 'todo',
    toxic: 'Tóxico',
    noToxic: 'No tóxico',
    unavailableConcentratedPowder: 'todo',
    unavailablePlant: 'todo',
  },
  posologies: {
    displayChip: {
      max: 'Até {{max}} g',
      min: 'De {{min}} g',
      minMax: 'De {{min}} g até {{max}} g',
    },
    max: 'Máximo',
    min: 'Mínimo',
    requirementsNotOK: 'Min deve ser menor ou igual ao máximo',
    title: 'Dosagem',
  },
  prescription: {
    addPod: 'Juntar uma Colher medida',
    decoction: 'Decoção simplificada',
    docSubtitle: 'Prescrição',
    nbOfBags: 'Número de sacos',
    nbOfCapsulesBatch:
      'Número de lotes de cápsulas (1 lote = 100 cápsulas = 50g)',
    order: {
      label: 'Encomenda',
    },
    pharmaceuticalForm: {
      capsule: 'Cápsula',
      label: 'Forma farmaceutica',
      small_bag: 'Saco',
    },
    plantPowderGroundSize: {
      big_ground: 'Moído grosso',
      fine_ground: 'Moído fino',
      label: 'Condicionamento',
      whole: 'Planta inteira',
    },
    plantPowderType: {
      label: 'Tipo de substância',
      plant: 'Planta',
      powder: 'Pó Concentrado',
    },
    pod: 'Colher Medida',
    posology: {
      capsule: {
        plant: {
          resume_one:
            'Eu quero {{nbOfCapsulesBatch}} lote de {{capsulesBatchSize}} cápsulas de {{capsuleWeight}} g desta fórmula de planta seca',
          resume_other:
            'Eu quero {{nbOfCapsulesBatch}} muitos {{capsulesBatchSize}} cápsulas de {{capsuleWeight}} g desta fórmula de planta seca',
          title: 'Dosagem de cápsulas ',
        },
        powder: {
          resume_one:
            'Eu quero {{nbOfCapsulesBatch}} lote de {{capsulesBatchSize}} cápsulas de {{capsuleWeight}} g dessa fórmula em pó concentrado',
          resume_other:
            'Eu quero {{nbOfCapsulesBatch}} muitos {{capsulesBatchSize}} cápsulas de {{capsuleWeight}} g dessa fórmula em pó concentrado',
          title: 'Dosagem de cápsulas para CP',
        },
      },
      plant: {
        resume_one:
          'Eu quero {{nbOfBags}} saco de {{portionWeight}} g desta fórmula de planta seca ({{variation}})',
        resume_other:
          'Eu quero {{nbOfBags}} sacos de {{portionWeight}} g desta fórmula em planta seca ({{variation}})',
        title: 'PS dosagem',
      },
      powder: {
        resume:
          'Eu quero 1 saqueta de {{totalweight}} g desta fórmula de pó/granulado concentrado',
        title: 'Dosagem de CP',
      },
      shortenDescription: {
        capsule: {
          resume_one:
            '{{nbOfCapsulesBatch}} lote {{capsulesBatchSize}} {{abbreviation}}',
          resume_other:
            '{{nbOfCapsulesBatch}} lotes {{capsulesBatchSize}} {{abbreviation}}',
        },
        plant: {
          resume_one: '{{nbOfBags}} saco {{portionWeight}}g {{abbreviation}}',
          resume_other:
            '{{nbOfBags}} sacos {{portionWeight}}g {{abbreviation}}',
        },
        powder: {
          resume: '1 saco de {{totalWeight}}g {{abbreviation}}',
        },
      },
    },
    quantity: {
      default: 'Peso total (em gramas)',
      portion: 'Peso por saco (em gramas)',
      total: 'Peso total (em gramas)',
    },
    reasonCantGoToCommentSection: {
      noCustomer: 'todo',
      noFormulas: 'todo',
      notAllItemsAvailable: 'todo',
      unknown: 'todo',
    },
    reasonCantValidateOrder: {
      invalidAddress: 'todo',
      loyaltyProgramError: 'todo',
      noShippingMethod: 'todo',
      notAllItemsAvailable: 'todo',
      unknown: 'todo',
    },
    resume: {
      cartGrossTotal: 'Custo do carrinho de compras excl. imposto',
      cartNetTotal: 'Custo do carrinho de compras incl. imposto',
      deliveryCost: 'Custo de envio',
      loyaltyOption: {
        label: 'todo',
        addPoints: 'todo',
        pay10Percent: 'todo',
        pay15Percent: 'todo',
        pay20Percent: 'todo',
        pay5Percent: 'todo',
      },
      missingCostsExplanation:
        'Os impostos e taxas de entrega serão calculados na fase de entrega',
      totalToPay: 'Total a pagar',
    },
    weightMode: {
      label: 'Peso equivalente a?',
      portion: 'Peso por parte',
      total: 'Peso total',
    },
    withoutPod: 'Sem colher medida',
    withPod: 'Com colher medida',
  },
  previous: 'Anterior',
  productMetadatas: {
    action: {
      cardTitle: 'Ações',
      groupByLabel: 'Estoque',
    },
    categoryFormula: {
      cardTitle: 'Categorias',
      groupByLabel: 'Categoria',
    },
    categoryFormulaJingFang: {
      cardTitle: 'Categorias Jing Fang',
      groupByLabel: 'Categoria Jing Fang',
    },
    categoryFormulaXiaoFang: {
      cardTitle: 'Categorias Xiao Fang',
      groupByLabel: 'Categoria Xiao Fang',
    },
    categoryPlant: {
      cardTitle: 'Categorias',
      groupByLabel: 'Categoria',
    },
    composition: 'Composição',
    docSubtitle: 'Metadados de produtos',
    linkText: 'Metadados',
    meridian: {
      cardTitle: 'Meridianos',
      groupByLabel: 'Meridiano',
    },
    name: 'Nome {{lngcode}}',
    nature: {
      cardTitle: 'Fia',
      groupByLabel: 'Natureza',
    },
    plant: {
      cardTitle: 'Plantas',
      groupByLabel: 'Planta',
    },
    productTypes: {
      formula: 'Fórmulas',
      plant: 'Plantas',
    },
    productVariationType: {
      cardTitle: 'Variações',
      docSubtitle: 'Variações',
      groupByLabel: 'Variação',
      linkText: 'Variações',
      price: 'Preço sem IVA',
      shortcode: 'Abreviação',
    },
    savour: {
      cardTitle: 'Sabores',
      groupByLabel: 'Sabor',
    },
    sellAvailable: 'Indisponível para venda',
    syndrom: {
      cardTitle: 'Síndromes',
      groupByLabel: 'Sindrome',
    },
  },
  productVariationTypes: {
    title: 'Variações',
  },
  radioButton: {
    addFormula: 'Adicione a uma fórmula existente',
    newFormula: 'Crie uma nova fórmula',
    placeholderNF: 'Nome da fórmula',
  },
  renewOrder: '',
  search: {
    none: 'Nenhum resultado',
    search: 'Pesquisar',
  },
  sendVerifToUsePharmaco:
    'Para poder fazer encomendas, deve enviar prova de formação para o seguinte endereço electrónico: contact@yao-dao.com',
  shippingMethodPrices: {
    countryZone: 'Área do país',
    docSubtitle: 'Lista de preços das opções de transporte',
    label: {
      en: 'Nome EN',
      es: 'Nome ES',
      fr: 'Nome FR',
      pt: 'Nome PT',
    },
    linkText: 'Preço das opções de transporte',
    maxWeight: 'Peso máximo',
    minWeight: 'Peso mínimo',
    price: 'Preço',
    shippingMethod: 'Método de transporte',
  },
  shippingMethods: {
    docSubtitle: 'Lista de métodos de transporte',
    label: {
      active: 'Ativo',
      carrier: 'Transportadora',
      displayOrder: 'Ordem de exibição',
      name: {
        en: 'Nome EN',
        es: 'Nome ES',
        fr: 'Nome FR',
        pt: 'Nome PT',
      },
      pricesLength: 'Número de preços associados',
      productCode: 'Código do produto',
    },
    linkText: 'Opções de transporte',
  },
  shop: {
    title: 'Comprar',
  },
  total: 'Total',
  validate: 'Validar',
  variations: {
    available: 'todo',
    basePrice: 'Preço inicial',
    name: 'Nome',
    price: 'Preço sem TVA',
    productRef: 'Referência do produto',
    title: 'Variações',
    unavailable: 'todo',
  },
  wholesalerSettings: {
    linkText: 'Definições',
  },
};
