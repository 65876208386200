/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
// import { MenuBuilder } from '@kiway/menu-builder';
import { useTranslation } from '@kiway/shared/utils/translation';
// import { RoutingBuilder } from '@kiway/routing-builder';
import { KiwayLanguagesType } from '@kiway/shared/react-types';
import config from '../config';

function registerLoyaltyProgram() {
  // REGISTER PHARMACO MENU
  // const builder = MenuBuilder.getBuilder('main');
  // Object.values(config.menus?.admin?.['main'] || {}).map((menu) => {
  //   builder.addItem({
  //     ...menu,
  //     icon: <menu.icon fontSize="medium" />,
  //   });
  // });

  // REGISTER PHARMACO TRANSLATIONS
  const { addTranslationDynamically } = useTranslation();
  Object.entries(config.translations).map(([lng, translation]) => {
    addTranslationDynamically(
      lng as KiwayLanguagesType,
      'loyaltyProgram',
      translation,
      true
    );
  });

  // REGISTER ROUTING
  // const routing = RoutingBuilder.getBuilder();
  // Object.values(config.routes?.admin).map((route) => {
  //   routing.addRoute(route);
  // });
}

export function FeatureLoyaltyProgram() {
  registerLoyaltyProgram();
  return <></>;
}

export * from './components';
