import { ShippingMethod } from '../entities/ShippingMethod';
import { ShippingMethodProvider } from './ShippingMethodProvider';

export class GetAllShippingMethods {
  private shippingMethodProvider: ShippingMethodProvider;

  public constructor(shippingMethodProvider: ShippingMethodProvider) {
    this.shippingMethodProvider = shippingMethodProvider;
  }

  public execute(find?: any): Promise<ShippingMethod[]> {
    return this.shippingMethodProvider.findAll(find);
  }
}
