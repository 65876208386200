/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Plant } from './Plant';
import { generateObjectId } from '@kiway/shared/utils/string';
import {
  CurrencyEUR,
  ERROR_MULTIPLE_CURRENCIES,
  LineItem,
  Order,
  Price,
  ProductVariation,
  ProductVariationType,
} from '@kiway/ecommerce-react-compatible';
import { Address } from '@kiway/shared/features/authentication-react-compatible';
import { ShippingMethod } from '@kiway/ecommerce-react-compatible';
import { roundProperlyNumbersByTotal } from '@kiway/shared/utils-react-compatible';
import { Formula } from './Formula';

export const CAPSULES_BATCH_SIZE = 100;
export const CAPSULE_WEIGHT = 0.5;

const filterByVariationTypeAttribute = (name: string, value: any) => (
  variation: ProductVariation
) => {
  const variationType = variation.getProductVariationType();
  const attributesValue = variationType?.getVariationAttributesValue();
  const attributes = attributesValue?.map((attributeValue) => ({
    name: attributeValue.getVariationAttribute().getName(),
    value: attributeValue.getValue(),
  }));
  return value
    ? attributes?.find(({ name: attrName }) => attrName === name)?.value ===
        value
    : true;
};

export class PharmacoOrderIngredient {
  protected lineItem: LineItem;
  protected totalDosage: number;
  protected totalEquivalentCP: number;
  protected totalEquivalentPS: number;
  protected formula: PharmacoOrderFormula;
  protected totalWeight: number;
  protected portionWeight: number;
  protected availability: boolean;

  isAvailable(): boolean {
    return this.availability;
  }

  getTotalWeight(): number {
    return this.totalWeight;
  }

  getPortionWeight(includeSimplifiedDecoction?: boolean): number {
    if (
      this.formula.getPharmaceuticalForm() === 'small_bag' &&
      this.formula.getPlantPowderType() === 'plant' &&
      this.formula?.getCustom()?.simplifiedDecoction &&
      includeSimplifiedDecoction
    ) {
      const nbOfDecoctionBags = parseInt(
        this.formula?.getCustom()?.simplifiedDecoctionValue
      );
      return Number((this.getTotalWeight() / nbOfDecoctionBags).toFixed(2));
    }
    return Number(this.portionWeight?.toFixed(2));
  }

  getLineItem(): LineItem {
    return this.lineItem;
  }

  getPercentValue(): number {
    if (this.totalDosage === 0) {
      return 0;
    }
    const custom = this.formula?.getCustom();
    const totalEquivalentCP = this.formula?.getTotalEquivalentCP();
    if (custom?.plantPowderType === 'powder') {
      return parseFloat(this.getEquivalentCP(true) ?? '0') / totalEquivalentCP;
    }
    return (
      parseFloat(this.lineItem?.getCustom()?.dosage ?? '0') / this.totalDosage
    );
  }

  getPercent(): string {
    return `${(this.getPercentValue() * 100).toFixed(2)} %`.replace(
      /\s/g,
      '\u00A0'
    );
  }

  getEquivalentCP(raw = false): string {
    const equivalent = (
      parseFloat(`${this.lineItem?.getCustom()?.dosage}`) /
      parseFloat(
        `${this.lineItem?.getProduct()?.getCustom()?.concentrationRate ?? 1}`
      )
    )?.toFixed(2);
    return raw ? equivalent : `${equivalent} g`.replace(/\s/g, '\u00A0');
  }

  getEquivalentPS(raw = false): string {
    const equivalent = (
      parseFloat(`${this.lineItem?.getCustom()?.dosage}`) *
      parseFloat(
        `${this.lineItem?.getProduct()?.getCustom()?.concentrationRate ?? 1}`
      )
    )?.toFixed(2);
    return raw ? equivalent : `${equivalent} g`.replace(/\s/g, '\u00A0');
  }

  constructor(ingredient: LineItem, formula: PharmacoOrderFormula) {
    this.lineItem = ingredient;
    this.updateAvailability();
    this.formula = formula;
    this.totalDosage = 0;
  }

  updateAvailability(): void {
    this.availability =
      this.lineItem.getProductVariation()?.isAvailable() &&
      this.getAvailableVariations()
        ?.map((v) => `${v.getId()}`)
        ?.includes(`${this.lineItem.getProductVariation()?.getId()}`); // TODO remove this check because if lineItem is available it must have a productVariation
  }

  updateQuantity(): void {
    const custom = this.formula?.getCustom();
    const quantity = this.formula.getQuantity();
    const nbOfBags = parseFloat(this.formula.getNbOfBags());
    const nbOfCapsulesBatch = parseFloat(this.formula.getNbOfCapsulesBatch());
    if (custom?.plantPowderType === 'plant') {
      if (custom.pharmaceuticalForm === 'small_bag') {
        if (this.formula?.getWeightMode() === 'portion') {
          this.totalWeight =
            nbOfBags * this.getPercentValue() * parseFloat(quantity);
          this.portionWeight = this.getPercentValue() * parseFloat(quantity);
        } else {
          // By default "Poids total"
          this.totalWeight = this.getPercentValue() * parseFloat(quantity);
          this.portionWeight =
            (this.getPercentValue() * parseFloat(quantity)) / nbOfBags;
        }
      } else {
        // Capsules
        const totalOfCapsules = nbOfCapsulesBatch * CAPSULES_BATCH_SIZE;
        this.totalWeight =
          this.getPercentValue() * totalOfCapsules * CAPSULE_WEIGHT;
        this.portionWeight = this.totalWeight / totalOfCapsules;
      }
    } else {
      if (custom.pharmaceuticalForm === 'small_bag') {
        // By default "Poids total"
        this.totalWeight = this.getPercentValue() * parseFloat(quantity);
        this.portionWeight =
          (this.getPercentValue() * parseFloat(quantity)) / nbOfBags;
      } else {
        // Capsules
        const totalOfCapsules = nbOfCapsulesBatch * CAPSULES_BATCH_SIZE;
        this.totalWeight =
          this.getPercentValue() * totalOfCapsules * CAPSULE_WEIGHT;
        this.portionWeight = this.totalWeight / totalOfCapsules;
      }
    }
    this.lineItem.setQuantity(this.totalWeight);
  }

  setTotalDosage(totalDosage: number): PharmacoOrderIngredient {
    this.totalDosage = totalDosage;
    return this;
  }

  setTotalEquivalentCP(totalEquivalentCP: number): PharmacoOrderIngredient {
    this.totalEquivalentCP = totalEquivalentCP;
    return this;
  }

  setTotalEquivalentPS(totalEquivalentPS: number): PharmacoOrderIngredient {
    this.totalEquivalentPS = totalEquivalentPS;
    return this;
  }

  setDosage(dosage: number): void {
    this.lineItem.setCustom({
      ...this.lineItem.getCustom(),
      dosage,
    });
    this.updateAll(true);
  }

  setIsSeparated(isSeparated: boolean): void {
    this.lineItem.setCustom({
      ...this.lineItem.getCustom(),
      isSeparated,
    });
    this.updateAll(true);
  }

  isSeparated(): boolean {
    return this.getLineItem()?.getCustom()?.isSeparated || false;
  }

  getAvailableVariations(): Array<ProductVariation> {
    const variations = this.lineItem.getProduct()?.getVariations();
    const filterByPlantPowderType = variations?.filter(
      filterByVariationTypeAttribute(
        'plantPowderType',
        this.formula?.getCustom()?.plantPowderType
      )
    );
    const filterByPharmaceuticalForm = variations?.filter(
      filterByVariationTypeAttribute(
        'pharmaceuticalForm',
        this.formula?.getCustom()?.pharmaceuticalForm
      )
    );
    const data = [filterByPlantPowderType, filterByPharmaceuticalForm];
    if (
      this.formula?.getCustom()?.plantPowderType === 'plant' &&
      this.formula?.getCustom()?.pharmaceuticalForm === 'small_bag'
    ) {
      const filterByPlantPowderGroundSize = variations?.filter(
        filterByVariationTypeAttribute(
          'plantPowderGroundSize',
          this.formula?.getCustom()?.plantPowderGroundSize ?? 'whole'
        )
      );
      data.push(filterByPlantPowderGroundSize);
    }
    const result = data.reduce((a, b) => {
      return a?.filter((c) =>
        b?.map((v) => `${v.getId()}`).includes(`${c.getId()}`)
      );
    });
    return result;
  }

  updateAll(checkIngredients = false): void {
    const allowedVariations = this.getAvailableVariations();
    this.lineItem.setProductVariation(
      allowedVariations?.length > 0 ? allowedVariations[0] : null
    );
    this.updateQuantity();
    this.updateAvailability();
    this.formula.updateAll(checkIngredients);
  }
}

export class PharmacoOrderFormula {
  protected order: PharmacoOrder;
  protected ingredients: Array<PharmacoOrderIngredient>;
  protected custom: any;
  protected setCustomOrder: (custom: any) => void;
  protected groupBy: string;
  protected totalWeight: number;
  protected portionWeight: number;
  protected totalPrice: Price;

  constructor(
    ingredients: Array<LineItem>,
    custom: any,
    groupBy: string,
    setCustomOrder: (custom: any) => void,
    order: PharmacoOrder
  ) {
    this.order = order;
    this.ingredients = ingredients.map(
      (ingredient) => new PharmacoOrderIngredient(ingredient, this)
    );
    this.custom = custom ?? {};
    this.setCustomOrder = setCustomOrder;
    this.updateTotalDosage();
    this.groupBy = groupBy;
    this.custom = {
      name: 'Formule',
      ...this.custom,
    };
    this.updateAll();
  }

  isFormulaAvailable(): boolean {
    return this.ingredients
      .map((ingredient) => ingredient.isAvailable())
      ?.reduce((prev, current) => prev && current, true);
  }

  getRoundedItemQuantity(lineItemId: string): number {
    const correctWeights = roundProperlyNumbersByTotal(
      this.getIngredients()?.map((ingredient) => ({
        id: ingredient.getLineItem().getId(),
        value: ingredient.getLineItem().getQuantity(),
      })),
      this.getTotalQuantity(),
      1
    );
    return correctWeights?.find(({ id }) => id === lineItemId)?.value ?? 0;
  }

  getRoundedItemTotalWeight(lineItemId: string): number {
    const baseWeights = this.getIngredients()?.map((ingredient) => ({
      id: ingredient.getLineItem().getId(),
      value: Number(ingredient.getTotalWeight()?.toFixed(2)),
    }));
    const correctWeights = roundProperlyNumbersByTotal(
      baseWeights,
      this.getTotalWeight(),
      1
    );
    return correctWeights?.find(({ id }) => id === lineItemId)?.value ?? 0;
  }

  getRoundedItemPortionWeight(
    lineItemId: string,
    includeSimplifiedDecoction?: boolean,
    nbDecimals?: number
  ): number {
    const baseWeights = this.getIngredients()?.map((ingredient) => ({
      id: ingredient.getLineItem().getId(),
      value: ingredient.getPortionWeight(includeSimplifiedDecoction),
    }));
    const correctWeights = roundProperlyNumbersByTotal(
      baseWeights,
      this.getPortionWeight(includeSimplifiedDecoction),
      nbDecimals ?? 1
    );
    return correctWeights?.find(({ id }) => id === lineItemId)?.value ?? 0;
  }

  getTotalQuantity(): number {
    return this.totalWeight;
  }

  saveOrder(): void {
    this.order.save();
  }

  updatePortionWeight(): void {
    this.portionWeight = this.ingredients
      ?.map((ingredient) => ingredient.getPortionWeight())
      .reduce((prev, current) => {
        return prev + current;
      }, 0);
  }

  getPortionWeight(includeSimplifiedDecoction?: boolean): number {
    if (
      this.getPharmaceuticalForm() === 'small_bag' &&
      this.getPlantPowderType() === 'plant' &&
      this.getCustom()?.simplifiedDecoction &&
      includeSimplifiedDecoction
    ) {
      const nbOfDecoctionBags = parseInt(
        this.getCustom()?.simplifiedDecoctionValue
      );
      const baseNbOfBags = parseInt(this.getNbOfBags());
      return (this.portionWeight * baseNbOfBags) / nbOfDecoctionBags;
    }
    return this.portionWeight;
  }

  updateTotalWeight(): void {
    this.totalWeight = this.ingredients
      ?.map((ingredient) => ingredient.getTotalWeight())
      .reduce((prev, current) => {
        return prev + current;
      }, 0);
  }

  getTotalWeight(): number {
    return this.totalWeight;
  }

  updateTotalPrice(): void {
    const nullPrice: Price = new Price({
      currency: CurrencyEUR,
      centAmount: 0,
    });
    this.totalPrice = this.ingredients
      ?.map((ingredient) => ingredient.getLineItem())
      ?.reduce((prev, current) => {
        const currentPrice = current.getTotalGrossPrice();
        if (
          prev?.getCurrency()?.isDefined() &&
          currentPrice?.getCurrency()?.isDefined() &&
          !currentPrice?.getCurrency().equals(prev?.getCurrency())
        ) {
          throw Error(ERROR_MULTIPLE_CURRENCIES);
        }
        return new Price({
          ...prev,
          currency: prev?.getCurrency()?.isDefined()
            ? prev?.getCurrency()
            : currentPrice?.getCurrency()?.isDefined()
            ? currentPrice?.getCurrency()
            : nullPrice?.getCurrency(),
          centAmount:
            prev?.getCentAmount() + (currentPrice?.getCentAmount() ?? 0),
        });
      }, nullPrice);
  }

  getTotalPrice(): Price {
    return this.totalPrice;
  }

  getName(): string {
    return this.custom?.name;
  }

  getGroupBy(): string {
    return this.groupBy;
  }

  getCustom(): any {
    return this.custom;
  }

  updateTotalDosage(): void {
    const totalDosage = this.getTotalDosage();
    this.ingredients.map((ingredient) =>
      ingredient.setTotalDosage(totalDosage)
    );
  }

  updateTotalEquivalentCP(): void {
    const totalEquivalentCP = this.getTotalEquivalentCP();
    this.ingredients.map((ingredient) =>
      ingredient.setTotalEquivalentCP(totalEquivalentCP)
    );
  }

  updateTotalEquivalentPS(): void {
    const totalEquivalentPS = this.getTotalEquivalentPS();
    this.ingredients.map((ingredient) =>
      ingredient.setTotalEquivalentPS(totalEquivalentPS)
    );
  }

  getTotalDosage(): number {
    return this.ingredients.reduce(
      (prev, current) =>
        prev + parseFloat(current.getLineItem()?.getCustom()?.dosage ?? '0'),
      0
    );
  }

  getTotalEquivalentCP(): number {
    return this.ingredients.reduce(
      (prev, current) =>
        prev + parseFloat(current.getEquivalentCP(true) ?? '0'),
      0
    );
  }

  getTotalEquivalentPS(): number {
    return this.ingredients.reduce(
      (prev, current) =>
        prev + parseFloat(current.getEquivalentPS(true) ?? '0'),
      0
    );
  }

  getTotalPercent(): string {
    return `${parseFloat('100').toFixed(2)} %`.replace(/\s/g, '\u00A0');
  }

  getIngredients(): Array<PharmacoOrderIngredient> {
    return this.ingredients;
  }

  needToAddPod(): boolean {
    return this.getPlantPowderType() === 'powder' &&
      this.getPharmaceuticalForm() === 'small_bag'
      ? this.getCustomProperty('pod') !== undefined
        ? this.getCustomProperty('pod')
        : true
      : false;
  }

  setNeedToAddPod(needToAddPod: boolean): void {
    this.setCustomProperty('pod', needToAddPod);
  }

  setCustomProperty(property: string, value: any, save = true) {
    this.custom = {
      ...this.custom,
      [property]: value,
    };
    if (property === 'plantPowderType' && value === 'powder') {
      this.custom = {
        ...this.custom,
        plantPowderGroundSize: null,
      };
    }
    this.setCustomOrder(this.custom);
    this.updateAll(true, save);
  }

  getCustomProperty(property: string): any {
    return this.custom?.[property];
  }

  getPlantPowderType(): string {
    return this.getCustomProperty('plantPowderType') ?? 'plant';
  }

  setPlantPowderType(plantPowderType: string): void {
    return this.setCustomProperty('plantPowderType', plantPowderType);
  }

  getPharmaceuticalForm(): string {
    return this.getCustomProperty('pharmaceuticalForm') ?? 'small_bag';
  }

  setPharmaceuticalForm(pharmaceuticalForm: string): void {
    return this.setCustomProperty('pharmaceuticalForm', pharmaceuticalForm);
  }

  getPlantPowderGroundSize(): string {
    return this.getCustomProperty('plantPowderGroundSize') ?? 'whole';
  }

  setPlantPowderGroundSize(plantPowderGroundSize: string): void {
    return this.setCustomProperty(
      'plantPowderGroundSize',
      plantPowderGroundSize
    );
  }

  getWeightMode(): string {
    if (this.getPharmaceuticalForm() === 'small_bag') {
      if (
        this.getPlantPowderType() === 'powder' ||
        this.getPlantPowderGroundSize() === 'fine_ground'
      ) {
        return 'total';
      } else {
        return 'portion';
      }
    } else {
      return 'total';
    }
    return this.getCustomProperty('weightMode') ?? 'total';
  }

  setWeightMode(weightMode: string): void {
    return this.setCustomProperty('weightMode', weightMode);
  }

  getQuantity(): string {
    return this.getCustomProperty('quantity') || this.getTotalDosage();
  }

  setQuantity(quantity: string, save = false): void {
    return this.setCustomProperty('quantity', quantity, save);
  }

  getNbOfBags(includeSimplifiedDecoction?: boolean): string {
    if (this.getCustom()?.simplifiedDecoction && includeSimplifiedDecoction) {
      return this.getCustom()?.simplifiedDecoctionValue;
    }
    return this.getCustomProperty('nbOfBags') || '1';
  }

  setNbOfBags(nbOfBags: string, save = false): void {
    return this.setCustomProperty('nbOfBags', nbOfBags, save);
  }

  getNbOfCapsulesBatch(): string {
    return this.getCustomProperty('nbOfCapsulesBatch') || '1';
  }

  setNbOfCapsulesBatch(nbOfCapsulesBatch: string, save = false): void {
    return this.setCustomProperty('nbOfCapsulesBatch', nbOfCapsulesBatch, save);
  }

  getDosageMode(): 'CP' | 'PS' {
    return this.getCustomProperty('dosageMode') ?? 'PS';
  }

  updateAll(withIngredients = true, save = false): void {
    if (withIngredients) {
      this.ingredients?.map((ingredient) => ingredient.updateAll());
    }
    this.updateTotalEquivalentCP();
    this.updateTotalEquivalentPS();
    this.updateTotalWeight();
    this.updatePortionWeight();
    this.updateTotalDosage();
    this.updateTotalPrice();
    if (save) {
      this.saveOrder();
    }
  }

  getPosologyDescription(t: any): string {
    let label = 'pharmaco:prescription.posology.';
    const variables: any = {};
    if (this.getPharmaceuticalForm() === 'capsule') {
      label += 'capsule.';
      variables.count = parseFloat(this.getNbOfCapsulesBatch());
      variables.nbOfCapsulesBatch = this.getNbOfCapsulesBatch();
      variables.capsulesBatchSize = CAPSULES_BATCH_SIZE;
      variables.capsuleWeight = CAPSULE_WEIGHT;
    } else {
      variables.count = parseFloat(this.getNbOfBags(true));
      variables.totalWeight = Number(this.getTotalWeight()).toFixed(2);
      variables.nbOfBags = this.getNbOfBags(true);
      variables.portionWeight = Number(
        this.getPortionWeight(true).toFixed(1)
      ).toFixed(2);
      variables.variation = t(
        `pharmaco:prescription.plantPowderGroundSize.${this.getPlantPowderGroundSize()}`
      )?.toLowerCase();
    }
    label += `${this.getPlantPowderType()}.resume`;
    return t(label, variables);
  }

  getShortenPosologyDescription(t: any): string {
    let label = 'pharmaco:prescription.posology.shortenDescription.';
    const variables: any = {};
    if (this.getPharmaceuticalForm() === 'capsule') {
      label += 'capsule.resume';
      variables.count = parseFloat(this.getNbOfCapsulesBatch());
      variables.nbOfCapsulesBatch = this.getNbOfCapsulesBatch();
      variables.capsulesBatchSize = CAPSULES_BATCH_SIZE;
      variables.abbreviation = this.getIngredients()[0]
        .getLineItem()
        .getProductVariation()
        .getProductVariationType()
        .getShortcode();
    } else {
      label += `${this.getPlantPowderType()}.resume`;
      variables.count = parseFloat(this.getNbOfBags(true));
      variables.totalWeight = Number(this.getTotalWeight()).toFixed(1);
      variables.nbOfBags = this.getNbOfBags(true);
      variables.portionWeight = Number(
        this.getPortionWeight(true).toFixed(1)
      ).toFixed(2);
      variables.abbreviation = this.getIngredients()[0]
        ?.getLineItem()
        ?.getProductVariation()
        ?.getProductVariationType()
        ?.getShortcode();
    }
    return t(label, variables);
  }
}

type Dosage = {
  plantId: string;
  dosage: number;
};

export type TempFormulaMode = 'edit' | 'add';

export type TempFormulaType = 'plant' | 'powder';

export class TempFormula {
  protected groupBy: string;
  protected mode: TempFormulaMode;
  protected name: string;
  protected selectedFormula: {
    key: string;
    name: string;
    type: TempFormulaType;
  };
  protected plants: Plant[];
  protected dosages: Dosage[];
  protected type: TempFormulaType;

  public constructor(plants: Plant[], index?: number, formulaName?: string) {
    this.plants = plants ?? [];
    this.groupBy = generateObjectId();
    this.name = formulaName ?? `Formule ${index}`;
    this.resetDosages();
    this.mode = 'add';
    this.selectedFormula = null;
    this.type = 'plant';
  }

  getFilterFromType() {
    return (formula: { key: string; name: string; type: TempFormulaType }) => {
      // return true;
      // if (this.type === 'plant') {
      //   return true;
      // }
      // FOR NOW, WE CAN ONLY ADD TO EXISTING FORMULA IF ITS TYPE IS THE SAME OF THE SELECTED ONE
      return formula.type === this.type;
    };
  }

  getType(): TempFormulaType {
    return this.type;
  }

  setType(type: TempFormulaType): void {
    this.type = type;
    if (type === 'powder' && this.selectedFormula?.type !== type) {
      this.selectedFormula = null;
    }
  }

  getSelectedFormula(): { key: string; name: string; type: TempFormulaType } {
    return this.selectedFormula;
  }

  setSelectedFormula(formula: {
    key: string;
    name: string;
    type: TempFormulaType;
  }): void {
    this.selectedFormula = formula;
    if (formula.key) {
      this.type = formula.type;
    }
  }

  getMode(): TempFormulaMode {
    return this.mode;
  }

  setMode(mode: TempFormulaMode): void {
    this.mode = mode;
  }

  getName(): string {
    return this.name;
  }

  setName(formulaName: string): void {
    this.name = formulaName;
  }

  getGroupBy(): string {
    return this.groupBy;
  }

  setGroupBy(groupBy: string): void {
    this.groupBy = groupBy;
  }

  getPlants(): Plant[] {
    return this.plants;
  }

  getDosages(): Dosage[] {
    return this.dosages;
  }

  getDosage(plantId: string): number {
    return this.dosages?.find((dosage) => dosage.plantId === plantId)?.dosage;
  }

  resetDosages(): void {
    this.dosages =
      this.plants?.map((plant) => ({
        plantId: plant.getId(),
        dosage: null,
      })) ?? [];
  }

  initDosages(): void {
    this.dosages =
      this.plants?.map(
        (plant) =>
          this.dosages?.find(({ plantId }) => plantId === plant.getId()) ?? {
            plantId: plant.getId(),
            dosage: null,
          }
      ) ?? [];
  }

  isAllDosageValid(): boolean {
    return this.dosages.findIndex(({ dosage }) => !dosage || dosage < 1) === -1;
  }

  setPlants(plants: Plant[]): void {
    this.plants = plants;
  }

  addFormula(formula: Formula): void {
    formula.getComposition().map((ingredient) => {
      const plant = ingredient.getPlant();
      const dosage = ingredient.getDosage();
      if (
        !this.plants.map((_plant) => _plant.getId()).includes(plant.getId())
      ) {
        this.plants.push(plant);
      }
      this.setDosage(plant.getId(), dosage);
    });
  }

  setDosage(
    plantId: string,
    newDosage?: number,
    addIfExist?: boolean,
    removeIfExist?: boolean
  ): void {
    if (this.dosages?.map((dosage) => dosage.plantId).includes(plantId)) {
      this.dosages = this.dosages?.map((dosage) =>
        dosage.plantId === plantId
          ? {
              ...dosage,
              dosage: addIfExist
                ? dosage.dosage + (newDosage ?? 0)
                : removeIfExist
                ? dosage.dosage - (newDosage ?? 0)
                : newDosage ?? null,
            }
          : dosage
      );
    } else {
      this.dosages?.push({
        plantId,
        dosage: newDosage,
      });
    }
  }

  getTotalDosage(): number {
    return this.dosages?.reduce(
      (prev, current) => prev + (current.dosage ?? 0),
      0
    );
  }

  removePlant(plantId: string): void {
    this.plants =
      this.plants?.filter((plant) => plant.getId() !== plantId) ?? [];
    this.dosages =
      this.dosages?.filter((dosage) => dosage.plantId !== plantId) ?? [];
  }
}

export interface IPharmacoOrderAttributes {
  order: Order;
  saveCallback: () => void;
}

export class PharmacoOrder {
  protected order: Order;
  protected formulas: Array<PharmacoOrderFormula>;
  protected tempFormula: TempFormula;
  protected saveCallback: (order?: PharmacoOrder) => void;

  constructor(obj: IPharmacoOrderAttributes) {
    this.order = obj?.order;
    this.saveCallback = obj?.saveCallback;
    this.formulas = this.order
      ?.getGroups()
      ?.map(
        (group) =>
          new PharmacoOrderFormula(
            this.order.getLineItemsByGroup(group),
            this.order?.getCustom()?.[group],
            group,
            this.setCustomFormula(group),
            this
          )
      );
    this.initOrUpdateFormulas();
    this.save.bind(this);
  }

  canFinishPreparation(): boolean {
    const preparedPod = this.formulas.reduce((prev, current) => {
      return (
        prev &&
        ((current.needToAddPod() && current.getCustomProperty('podPrepared')) ||
          !current.needToAddPod())
      );
    }, true);

    return preparedPod && this.order?.canFinishPreparation();
  }

  initOrUpdateFormulas(): void {
    this.formulas?.map((formula) => {
      formula
        .getIngredients()
        ?.map((ingredient) => ingredient.updateQuantity());
      formula.updateTotalEquivalentCP();
      formula.updateTotalWeight();
      formula.updatePortionWeight();
      formula.updateTotalPrice();
    });
  }

  updateAllFormulas(): void {
    this.formulas?.map((formula) => {
      formula.updateAll();
    });
  }

  createNewTempFormula(plants: Plant[]): PharmacoOrder {
    this.tempFormula = new TempFormula(plants, this.formulas.length + 1);
    return this;
  }

  getTempFormula(): TempFormula {
    return this.tempFormula;
  }

  setCustomFormula(formulaName: string): (custom: any) => void {
    return (custom: any) =>
      this.order.setCustom({
        ...this.order.getCustom(),
        [formulaName]: {
          ...this.order.getCustom()?.[formulaName],
          ...custom,
        },
      });
  }

  transformPlantToLineItem(
    plant: Plant,
    options?: {
      dosage: number;
      groupBy: string;
      plantPowderType: 'plant' | 'powder';
    }
  ): LineItem {
    return new LineItem({
      groupBy: options.groupBy,
      product: plant,
      custom: {
        dosage: options.dosage,
      },
      modelProduct: 'Plant',
    });
  }

  removeLineItem(lineItemId: string): void {
    this.order.removeLineItem(lineItemId);
    this.updateAllFormulas();
  }

  removeFormula(formulaGroupBy: string): void {
    this.order.removeLineItemsByGroup(formulaGroupBy);
    this.order.setCustom({
      ...this.order.getCustom(),
      [formulaGroupBy]: undefined,
    });
    this.updateAllFormulas();
  }

  changeShippingAddress(address: Address): PharmacoOrder {
    this.order.setShippingAddress(address);
    this.order.setCustom({
      ...this.order.getCustom(),
      shippingAddressId: address.getId(),
    });
    if (this.order?.getCustom()?.sameInvoicingAndShippingAddress) {
      this.changeInvoicingAddress(address);
    }
    return this;
  }

  changeInvoicingAddress(address: Address): PharmacoOrder {
    this.order.setInvoicingAddress(address);
    this.order.setCustom({
      ...this.order.getCustom(),
      invoicingAddressId: address.getId(),
    });
    return this;
  }

  handleClickSameAddress(value: boolean): PharmacoOrder {
    this.order.setCustom({
      ...(this.order.getCustom() ?? {}),
      sameInvoicingAndShippingAddress: value,
    });
    if (value) {
      this.changeInvoicingAddress(this.order.getShippingAddress());
    }
    return this;
  }

  changeShippingMethod(shippingMethod: ShippingMethod): PharmacoOrder {
    this.order.setShippingMethod(shippingMethod);
    return this;
  }

  changePatient(newPatient: any): PharmacoOrder {
    this.order.setCustom({
      ...(this.order.getCustom() ?? {}),
      patient: { ...(this.order.getCustom() ?? {})?.patient, ...newPatient },
    });
    return this;
  }

  getPreparerComment(): string {
    return this.order.getCustom()?.['preparerComment'];
  }

  setPreparerComment(comment: string): PharmacoOrder {
    this.order.setCustom({
      ...(this.order.getCustom() ?? {}),
      preparerComment: comment,
    });
    return this;
  }

  getPatientComment(): string {
    return this.order.getCustom()?.['patientComment'];
  }

  setPatientComment(comment: string): PharmacoOrder {
    this.order.setCustom({
      ...(this.order.getCustom() ?? {}),
      patientComment: comment,
    });
    return this;
  }

  getPractitionerComment(): string {
    return this.order.getCustom()?.['practitionerComment'];
  }

  setPractitionerComment(comment: string): PharmacoOrder {
    this.order.setCustom({
      ...(this.order.getCustom() ?? {}),
      practitionerComment: comment,
    });
    return this;
  }

  transformTempFormula(): void {
    const isAddMode = this.tempFormula.getMode() === 'add';
    const group = isAddMode
      ? this.tempFormula.getGroupBy()
      : this.tempFormula.getSelectedFormula()?.key;
    const formulaCustom = this.order?.getCustom()?.[group] ?? {
      name: isAddMode
        ? this.tempFormula.getName()
        : this.tempFormula.getSelectedFormula()?.name,
      plantPowderType: this.tempFormula.getType(),
      pharmaceuticalForm: 'small_bag',
    };
    const formula = new PharmacoOrderFormula(
      this.tempFormula.getPlants()?.map((plant) =>
        this.transformPlantToLineItem(plant, {
          dosage: this.tempFormula.getDosage(plant.getId()),
          groupBy: group,
          plantPowderType: this.tempFormula.getType(),
        })
      ),
      formulaCustom,
      group,
      this.setCustomFormula(group),
      this
    );
    formula.getIngredients()?.map((ingredient) => {
      this.order.addLineItem(ingredient.getLineItem(), (item: LineItem) =>
        item.setCustom({
          ...item.getCustom(),
          dosage:
            (item.getCustom()?.dosage ?? 0) +
            (ingredient.getLineItem().getCustom()?.dosage ?? 0),
        })
      );
    });
    this.order.setCustom({
      ...this.order.getCustom(),
      [group]: formulaCustom,
    });
  }

  changeFormulaName(formulaId: string, formulaName: string): PharmacoOrder {
    this.setCustomFormula(formulaId)({ name: formulaName });
    return this;
  }

  openOrder(): PharmacoOrder {
    if (this.order.canOpenOrder()) {
      this.order.openOrder();
    }
    return this;
  }

  canCancelOrder(): boolean {
    if (
      (this.getOrder()?.getOrderStatus() === 'DRAFT' ||
        (this.getOrder()?.getOrderStatus() === 'OPEN' &&
          this.getOrder()?.getPaymentStatus() === 'BALANCE_DUE')) &&
      this.getOrder()?.getPackingStatus() === 'WAITING' &&
      this.getOrder()?.getShipmentStatus() === 'WAITING' &&
      (!this.getOrder()?.getPayment()?.getId() ||
        this.getOrder()?.getPayment()?.getStatus() === 'DRAFT')
    ) {
      return true;
    }
    return false;
  }

  cancelOrder(): PharmacoOrder {
    if (this.canCancelOrder()) {
      this.order.setOrderStatus('CANCELLED');
      this.order.setCancelledAt(new Date());
    }
    return this;
  }

  deleteOrder(): PharmacoOrder {
    if (this.order.canBeDeleted()) {
      this.order.deleteOrder();
    }
    return this;
  }

  getOrder(): Order {
    return this.order;
  }

  setOrder(order: Order): PharmacoOrder {
    this.order = order;
    return this;
  }

  getVariationTypes(): ProductVariationType[] {
    return this.order.getVariationTypes();
  }

  getFormulas(): Array<PharmacoOrderFormula> {
    return this.formulas;
  }

  canGoToCommentSection(): boolean {
    return (
      this.getOrder()?.canGoToCommentSection() &&
      this.getOrder()?.getCustomer()?.getCustom()?.fileId &&
      this.getFormulas()?.length > 0
    );
  }

  reasonCantGoToCommentSection(): string | null {
    if (this.canGoToCommentSection()) {
      return null;
    }
    if (!this.getOrder()?.getCustomer()?.getCustom()?.fileId) {
      return 'noCustomer';
    }
    if (this.getFormulas()?.length <= 0) {
      return 'noFormulas';
    }
    return this.getOrder()?.reasonCantGoToCommentSection();
  }

  canValidateOrder(): boolean {
    return this.getOrder()?.canValidateOrder();
  }

  reasonCantValidateOrder(): string | null {
    if (this.canValidateOrder()) {
      return null;
    }
    return this.getOrder()?.reasonCantValidateOrder();
  }

  save(): void {
    this.saveCallback(this);
  }
}
