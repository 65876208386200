// import ArkhipTtf from '../assets/font/Arkhip_font.ttf'

const arkhip = {
  fontFamily: 'Arkhip',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
        local('Arkhip'),
        local('Arkhip-Regular'),
        url(../assets/font/Arkhip_font.ttf) format('truetype')
    `,
  unicodeRange:
    'U+0000-00FF, ' +
    'U+0131, ' +
    'U+0152-0153, ' +
    'U+02BB-02BC, ' +
    'U+02C6, ' +
    'U+02DA, ' +
    'U+02DC, ' +
    'U+2000-206F, ' +
    'U+2074, ' +
    'U+20AC, ' +
    'U+2122, ' +
    'U+2191, ' +
    'U+2193, ' +
    'U+2212, ' +
    'U+2215, ' +
    'U+FEFF',
};

const primaryColor = process.env.NX_COLOR_PRIMARY_MAIN;
const secondaryColor = process.env.NX_COLOR_SECONDARY_MAIN;
const minoritaryColor = process.env.NX_COLOR_MINORITARY_MAIN;
const minoritaryDarkColor = process.env.NX_COLOR_MINORITARY_DARK;

const primaryTextColor = process.env.NX_COLOR_PRIMARY_TEXT;
const secondaryTextColor = process.env.NX_COLOR_SECONDARY_TEXT;
const minoritaryContrastText = process.env.NX_COLOR_MINORITARY_CONTRAST;

const theme = {
  palette: {
    primary: { main: primaryColor, contrastText: '#FFFFFF' },
    secondary: { main: secondaryColor, contrastText: '#FFFFFF' },
    minoritary: {
      main: minoritaryColor,
      dark: minoritaryDarkColor,
      contrastText: minoritaryContrastText,
    },
    pastels: {
      blue: '#64b5f6',
      green: '#81c784',
      orange: '#ffb74d',
      red: '#e57373',
    },
    backgroundLight: '#FFFFFF',
    backgroundDark: '#F0F0F0',
    text: {
      primary: primaryTextColor,
      secondary: secondaryTextColor,
    },
  },
  typography: {
    overline: {
      fontFamily: 'Arkhip, Arial',
      fontSize: '1.3rem',
      textTransform: 'none',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [arkhip],
      },
    },
    MuiFab: {
      primary: {
        color: primaryTextColor,
        backgroundColor: minoritaryColor,
        '&:hover': {
          backgroundColor: minoritaryDarkColor,
        },
      },
    },
    MuiAlert: {
      message: {
        color: primaryTextColor,
      },
    },
    MuiIconButton: {
      root: {
        color: primaryTextColor,
      },
    },
    MuiButton: {
      label: {
        color: primaryTextColor,
      },
      containedPrimary: {
        '& > span': {
          color: '#ffffff !important',
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: secondaryTextColor,
      },
    },
    MuiChip: {
      deletable: {
        color: primaryTextColor,
      },
    },
    MUIDataTableToolbar: {
      actions: {
        '& path': {
          fill: secondaryTextColor,
        },
      },
    },
    MuiSnackbar: {
      root: {
        position: 'relative',
      },
    },
  },
};

export default theme;
