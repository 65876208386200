import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Plant } from '../../../../core/entities/Plant';
import {
  Dispatch,
  SetStateAction,
} from 'react-transition-group/node_modules/@types/react';
import { ItemRow } from './ItemRow';
import { PharmacoOrder } from '../../../../core/entities/PharmacoOrder';

interface ItemToValidateTableProps {
  plants?: Plant[];
  order: PharmacoOrder;
  handleChangeStateOrder: any;
  removePlant: (plantId: string) => void;
  setIsButtonDisabled: Dispatch<SetStateAction<boolean>>;
}

export type TableItem = Plant & {
  id: `${string}`;
  dosage: number;
};

export function ItemToValidateTable(props: ItemToValidateTableProps) {
  const formula = props.order.getTempFormula();
  const { t } = useTranslation();
  return (
      <Table sx={{ border: '1px solid #EAE9E9'}} size="small">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#EAE9E9' }}>
            <TableCell>{t('pharmaco:plant.pinYinName')}</TableCell>
            <TableCell align="center">{t('pharmaco:formula.dosage')}</TableCell>
            <TableCell align="center">
              {t('pharmaco:formula.percent')}
            </TableCell>
            <TableCell align="center">{t('pharmaco:plant.posology')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formula?.getPlants()?.map((plant, index) => (
            <ItemRow
              key={plant.getId()}
              index={index}
              plant={plant}
              removePlant={props.removePlant}
              dosage={formula.getDosage(plant.getId())}
              totalDosage={formula.getTotalDosage()}
              setIsButtonDisabled={props.setIsButtonDisabled}
              setDosage={(dosage) => {
                formula.setDosage(plant.getId(), dosage)
                props.setIsButtonDisabled(!formula.isAllDosageValid())
                props.handleChangeStateOrder(props.order)
              }}
            />
          ))}
        </TableBody>
      </Table>
  );
}
