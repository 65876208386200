import { gql } from '@apollo/client';

export const SET_NOTIFICATION_AS_READ = gql`
  mutation setNotificationAsRead($id: ID!) {
    setNotificationAsRead(id: $id)
  }
`;

export const SEND_BATCH_NOTIFICATION = gql`
  mutation sendBatch($input: NotificationBatchInput) {
    sendBatch(input: $input)
  }
`;
