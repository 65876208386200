import * as React from 'react';
import { useTaxModesGateway } from '../../../entrypoints/react/useTaxModesGateway';
import { ListTaxModes } from './ListTaxModes';

export function ListTaxModesController() {
  const [loading, setLoading] = React.useState(false);
  const [taxModes, setTaxModes] = React.useState([]);
  const { findAll } = useTaxModesGateway();

  React.useEffect(() => {
    setLoading(true);
    findAll()
      .then((taxModes) => {
        setTaxModes(taxModes);
      })
      .finally(() => setLoading(false));
    return () => {
      setTaxModes([]);
    };
  }, []);

  return (
    <ListTaxModes loading={loading} taxModes={taxModes} />
  );
}
