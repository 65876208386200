import * as React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';

interface InvoiceInfosProps {
  isSameAddress: boolean;
  setIsSameAddress: React.Dispatch<React.SetStateAction<boolean>>;
}

export function InvoiceInfos(
  props: React.PropsWithChildren<InvoiceInfosProps>
) {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!props.isSameAddress}
              onClick={() => props.setIsSameAddress(!props.isSameAddress)}
            />
          }
          label={t('pharmaco:orderDetails.order.sameAddress') as string}
        />
      </Grid>
      <Grid item xs={12}>
        {props.children}
      </Grid>
    </Grid>
  );
}
