import * as React from 'react';
import { useLoyaltyTransactionsGateway } from '../../../entrypoints/react/useLoyaltyTransactionsGateway';
import { ListLoyaltyTransactions } from './ListLoyaltyTransactions';

export function ListLoyaltyTransactionsController() {
  const [loading, setLoading] = React.useState(false);
  const [loyaltyTransactions, setLoyaltyTransactions] = React.useState([]);
  const { findAll } = useLoyaltyTransactionsGateway();

  React.useEffect(() => {
    setLoading(true);
    findAll()
      .then((loyaltyTransactions) => {
        setLoyaltyTransactions(loyaltyTransactions);
      })
      .finally(() => setLoading(false));
    return () => {
      setLoyaltyTransactions([]);
    };
  }, []);

  return (
    <ListLoyaltyTransactions
      loading={loading}
      loyaltyTransactions={loyaltyTransactions}
    />
  );
}
