import { Status, StatusColor } from '@kiway/ecommerce-react-compatible';
import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { OrderCardInfoLine } from './OrderCardInfoLine';
import { useTranslation } from '@kiway/shared/utils/translation';
import config from '../../../config';

interface Line {
  label: string;
  value: any;
  hide?: boolean;
}

export interface OrderCardInfoProps {
  actions: any;
  lines: Line[];
  status: Status;
  statusColor: StatusColor;
  title: string;
}

export function OrderCardInfo(props: OrderCardInfoProps) {
  const { t } = useTranslation();
  return (
    <Card
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      elevation={2}
    >
      <CardContent style={{ flex: 1 }}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Grid container spacing={1} justifyContent="center">
              <Grid item>
                <Typography variant="h6">{props.title}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center">
              <Grid item flex={1}>
                <b>{t(`${config.namespace}:orderDetails.status`)}</b>
              </Grid>
              <Grid item>
                <Chip
                  label={props?.status?.toUpperCase()}
                  color={props?.statusColor}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          {props?.lines
            ?.filter((line) => !line.hide)
            ?.map((line, index) => (
              <Grid item xs={12} key={index}>
                <OrderCardInfoLine label={line.label} value={line.value} />
              </Grid>
            ))}
        </Grid>
      </CardContent>
      <CardActions>{props?.actions}</CardActions>
    </Card>
  );
}
