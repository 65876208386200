import { Paper, Typography, Drawer } from '@mui/material';
import './browser.module.css';
import * as React from 'react';
import clsx from 'clsx';
import { useStyles } from '../browser/shared/style';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export interface BrowserProps {
  open?: boolean;
  setOpen?: any;
}

export function Browser({
  children,
  open,
  setOpen,
}: React.PropsWithChildren<BrowserProps>) {
  const classes = useStyles();

  /* ----------------------- THEMING ------------------------ */
  let browserTheme = createTheme({});
  browserTheme = responsiveFontSizes(browserTheme);
  /* ------------------------------------------------------- */

  return (
    <div className={classes.positioning}>
      <Paper onClick={() => setOpen(true)} className={classes.openButtonWrap}>
        <div className={classes.openButton}>
          <Typography>BROWSER</Typography>
        </div>
        <div className={classes.openButtonPop}></div>
      </Paper>

      <Drawer
        anchor={'bottom'}
        open={open}
        onClose={() => setOpen(false)}
        elevation={0}
        PaperProps={{
          style: { backgroundColor: 'transparent' },
        }}
      >
        <div className={classes.closeButtonWrap}>
          <div className={classes.openButton}>
            <Typography>BROWSER</Typography>
          </div>
          <div className={classes.closeButtonPop}></div>
        </div>
        <Paper
          style={{ overflow: 'hidden' }}
          className={clsx(classes.list, {
            [classes.fullList]: true,
          })}
          role="presentation"
        >
          {children}
        </Paper>
      </Drawer>
    </div>
  );
}

export default Browser;
