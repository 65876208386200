import * as React from 'react';
import { Grid } from '@mui/material';
import { SaveButton } from './Buttons/SaveButton';
import { DiscardButton } from './Buttons/DiscardButton';
import { FormulaChoiceChip } from './FormulaChoiceChip';
import {
  PharmacoOrder,
  TempFormulaMode,
  TempFormulaType,
} from '../../../core/entities/PharmacoOrder';
import { ItemToValidateTable } from './ItemsToValidateTable/ItemToValidateTable';
import { SwitchPSPC } from './Buttons/SwitchPSPC';
import { Modal } from '@kiway/shared/ui';
import { useTranslation } from '@kiway/shared/utils/translation';

interface ValidationModalProps {
  order?: PharmacoOrder;
  handleChangeStateOrder?: any;
  isOpen?: boolean;
  setIsOpen?: any;
  removePlant: (plantId: string) => void;
  changeFormulaName: (formulaName: string) => void;
  toggleFormulaMode: (mode: TempFormulaMode) => void;
  changeFormulaSelection: (
    groupBy: string,
    formulaName: string,
    type: TempFormulaType
  ) => void;
  changeFormulaType: (type: TempFormulaType) => void;
  handleFormulaSubmit: any;
  isButtonDisabled: boolean;
  setIsButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ValidationModal(props: ValidationModalProps) {
  const { t } = useTranslation();

  const types = [
    {
      value: 'plant',
      label: t('pharmaco:plant.dryPlant'),
    },
    {
      value: 'powder',
      label: t('pharmaco:plant.concentratedPowder'),
    },
  ];

  const tempFormula = props.order.getTempFormula();
  const selectedFormula = tempFormula?.getSelectedFormula();

  return (
    <Modal
      actions={
        <>
          <DiscardButton setIsOpen={props.setIsOpen} />
          <SaveButton
            isDisabled={props.isButtonDisabled}
            onClick={props.handleFormulaSubmit}
          />
        </>
      }
      open={props.isOpen}
      title={t('pharmaco:order.addToOrder')}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <SwitchPSPC
            values={types}
            disabled={selectedFormula?.key && tempFormula?.getMode() === 'edit'}
            selectedValue={
              selectedFormula?.key && tempFormula?.getMode() === 'edit'
                ? selectedFormula?.type
                : tempFormula?.getType()
            }
            setSelectedValue={(value: TempFormulaType) =>
              props.changeFormulaType(value)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <ItemToValidateTable
            order={props.order}
            handleChangeStateOrder={props.handleChangeStateOrder}
            setIsButtonDisabled={props.setIsButtonDisabled}
            removePlant={props.removePlant}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <FormulaChoiceChip
                changeFormulaName={props.changeFormulaName}
                toggleFormulaMode={props.toggleFormulaMode}
                changeFormulaSelection={props.changeFormulaSelection}
                formula={tempFormula}
                formulas={props.order?.getFormulas()?.map((formula) => ({
                  name: formula.getName(),
                  key: formula.getGroupBy(),
                  type: formula.getPlantPowderType() as TempFormulaType,
                }))}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
