export default {
  addPatientModal: {
    firstName: 'Nombre',
    lastName: 'Apellido',
    title: 'Agrega un nuevo paciente',
  },
  appBar: {
    suffix: 'años',
  },
  dataTable: {
    columns: {
      firstName: 'Nombre ',
      lastName: 'Apellido',
      lastSession: 'Última sesión',
      mobilePhone: 'Teléfono',
      nextSession: 'Siguiente sesión',
      numberOfSessions: 'Número de sesiones',
    },
    title: 'Pacientes',
  },
  deletePatientModal: {
    content:
      '¿Estás seguro de que quieres eliminar a este paciente? La operación es irreversible.',
    title: 'Eliminar paciente',
  },
  details: {
    actions: {
      alert: 'Alerta',
      appointment: 'Agregue una cita',
      export: 'Exportar datos de pacientes',
    },
    alerts: {
      label: 'Alertas contextuales',
    },
    background: {
      label: 'Historia',
      redFlag: 'Importante',
    },
    dataTable: {
      columns: {
        chiefComplaint: 'Motivo de consultación',
        date: 'Fecha',
        evaluation: 'Valoración',
      },
      title: 'Sesiones',
    },
    info: {
      address: 'Direccións',
      birthDate: 'Fecha de nacimiento',
      city: 'Ciudad',
      country: 'País',
      email: 'Correo electrónico',
      female: 'Mujer',
      firstName: 'Nombre ',
      gender: 'Género',
      label: 'Información de señalización',
      lastName: 'Apellido',
      male: 'Hombre',
      mobilePhone: 'Teléfono móvil',
      other: 'Otro',
      profession: 'Profesión',
      street: 'Calle',
      zipCode: 'Código postal',
    },
  },
  export: {
    chiefComplaint: 'Motivo de consulta',
    comment: 'Observación',
    consultationPlace: 'Ubicación de consulta',
    diagnosis: {
      text: 'Diagnóstico',
    },
    endDate: 'Fecha final',
    followUpEval: {
      comment: 'Comentar ',
      date: 'Fecha de procesamiento del tratamiento',
      treatmentEfficiency: 'Resultado del tratamiento',
    },
    fourMethods: {
      text: '4 tempos de exame',
    },
    modalContent:
      'Su descarga está lista, haga clic en el botón a continuación.',
    modalTitle: 'Exportar datos de pacientes',
    patientNote: 'Nota destinada al paciente',
    startDate: 'Fecha de inicio',
    treatment: {
      text: 'Tratamiento',
    },
  },
  last: {
    legacy: {
      order: 'Ultimo pedido grabado',
    },
  },
  link: {
    contentModal:
      'Al hacer clic en "Vincular este archivo" asociará el archivo del paciente a la cuenta de usuario del paciente.\\NEsto será útil para que el paciente maneje **sus datos personales** y tenga acceso a **su lista de citas**.\\NGracias a las **"Notas del paciente "** podrá proporcionar a su paciente un resumen de la sesión.\\NAsegúrese de introducir la dirección de correo electrónico correcta para vincular el archivo al paciente correcto.',
    textButton: 'Vincular este archivo',
    titleModal: 'Vincular una carpeta de pacientes a su usuario',
  },
  snackbars: {
    patient: {
      cantLinkEmailEmpty:
        'Debe ingresar una dirección de correo electrónico para intentar vincular el archivo del paciente',
      errorDelete:
        'Se produjo un error durante la eliminación. Le rogamos que repita de nuevo.',
      errorLink:
        'Se ha producido un error. O el archivo ya está vinculado al paciente, o el paciente aún no ha creado una cuenta con esta dirección de correo electrónico.',
      errorNames: 'Los campos del nombre y apellido son obligatorios',
      errorPhone: 'El número de teléfono no es válido',
      successCreate: 'Paciente creado con éxito',
      successDelete: 'Paciente eliminado con éxito',
      successLink:
        'Enlace hecho con éxito. Su paciente ahora puede acceder a sus citas con usted.',
      successUpdate: 'Paciente alterado con éxito',
    },
    session: {
      successCreate: 'Sesión creada con exito',
      successUpdate: 'Sesión modificada con exito',
    },
  },
};
