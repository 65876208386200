import { PaymentGraphQLProvider } from '../../dataproviders/graphql-client/PaymentGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllPayments } from '../../core/use_cases/GetAllPayments';
import { Payment, PaymentMethod } from '../../core/entities/Payment';
import { ChangeOrderPaymentMethod } from '../../core/use_cases/ChangeOrderPaymentMethod';
import { OrderGraphQLProvider } from '../../dataproviders/graphql-client/OrderGraphQLProvider';
import { Order } from '../../core/entities/Order';

class PaymentsGateway {
  protected static instance: PaymentsGateway;
  protected paymentProvider: PaymentGraphQLProvider;
  protected getAllPayments: GetAllPayments;
  protected changeOrderPaymentMethodUC: ChangeOrderPaymentMethod;

  public static getInstance(client: ApolloClient<any>): PaymentsGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new PaymentsGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    this.paymentProvider = new PaymentGraphQLProvider(client);
    const orderProvider = new OrderGraphQLProvider(client);
    this.getAllPayments = new GetAllPayments(this.paymentProvider);
    this.changeOrderPaymentMethodUC = new ChangeOrderPaymentMethod(
      this.paymentProvider,
      orderProvider
    );
    this.findAll = this.findAll.bind(this);
    this.save = this.save.bind(this);
    this.payWithDeferredMethod = this.payWithDeferredMethod.bind(this);
    this.changeOrderPaymentMethod = this.changeOrderPaymentMethod.bind(this);
    PaymentsGateway.instance = this;
  }

  async findAll(): Promise<Payment[]> {
    return await this.getAllPayments.execute();
  }

  async save(payment: Payment): Promise<Payment> {
    return await this.paymentProvider.save(payment);
  }

  async payWithDeferredMethod(
    orderId: string,
    customPayment: any
  ): Promise<Payment> {
    return await this.paymentProvider.payWithDeferredMethod(
      orderId,
      customPayment
    );
  }

  async changeOrderPaymentMethod(
    orderId: string,
    paymentMethod: PaymentMethod
  ): Promise<Payment> {
    return await this.changeOrderPaymentMethodUC.execute(
      orderId,
      paymentMethod
    );
  }
}

export function usePaymentsGateway() {
  const client = useApolloClient();
  const paymentsGateway = PaymentsGateway.getInstance(client);
  return {
    findAll: paymentsGateway.findAll,
    save: paymentsGateway.save,
    payWithDeferredMethod: paymentsGateway.payWithDeferredMethod,
    changeOrderPaymentMethod: paymentsGateway.changeOrderPaymentMethod,
  };
}
