import * as React from 'react';

import { useTranslation } from '@kiway/shared/utils/translation';

import {
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUIDataTableProps,
} from 'mui-datatables';

import {
  DatatableColumnType,
  DatatableColumnOptions,
  DatatableColumn,
  DatatablesSaveReturn,
  RenderCellOptions,
} from './types';
import { Datatables } from './Datatables';
import Cell from './Cell';
import { useSnackbar } from '../../../shared/utils/snackbar/src/lib/snackbar';
import { KiwayLanguagesType } from '@kiway/shared/react-types';

const columnDefaults = {
  options: {
    sort: true,
    fitler: true,
    display: true,
    hint: null,
    sortThirdClickReset: true,
    align: 'left',
    viewColumns: true,
    searchable: true,
    draggable: true,
    download: true,
    empty: false,
    print: true,
  },
};

const datatablesColumnDefaults = {
  options: {
    edit: true,
  },
};

const onInputChange = (setModifiedData, rowId, colName, event) => {
  const val = event.target ? event.target.value : event;
  setModifiedData((prev) => ({
    ...prev,
    [rowId]: {
      ...prev[rowId],
      [colName]: val,
    },
  }));
  return val;
};

const renderCell = (
  columnType: DatatableColumnType,
  columnOptions: DatatableColumnOptions,
  options?: RenderCellOptions,
  t?: any,
  currentLanguage?: any
) => {
  if (
    [
      'boolean',
      'date',
      'simple-date',
      'short-date',
      'dropdown',
      'text',
      'price',
      'status',
      'status-borderless',
      'translatable',
      'array-translatable',
      'standard',
      'number',
      'multi-select',
    ].includes(columnType)
  ) {
    const showInput = columnOptions?.edit && options?.isEditingMode;
    return (value: any, tableMeta: any): any => {
      return (
        <Cell
          columnType={columnType}
          showInput={showInput}
          value={value}
          tableMeta={tableMeta}
          options={options}
          columnOptions={columnOptions}
          onInputChange={onInputChange}
          t={t}
          currentLanguage={currentLanguage}
        />
      );
    };
  }
  return undefined;
};

const getColumns = (
  columns: Array<DatatableColumn>,
  id: string,
  editMode?: boolean,
  modifiedData?: any,
  setModifiedData?: any,
  t?: any,
  currentLanguage?: any
): Array<MUIDataTableColumn> => {
  const savedColumnsView = localStorage.getItem(`${id}ColumnView`)?.split(',');
  return columns.map((col: DatatableColumn, colIndex: number) => {
    const {
      display,
      sort,
      filter,
      hint,
      viewColumns,
      searchable,
      print,
      empty,
      draggable,
      download,
    } = col.options || {};
    const filterList = JSON.parse(
      localStorage.getItem(`${id}Filter-${col.name}`) || '[]'
    );
    return {
      ...columnDefaults,
      name: col.name,
      label: col.label,
      options: {
        ...columnDefaults?.options,
        customHeadLabelRender: (columnMeta) => {
          return <strong>{columnMeta.label}</strong>;
        },
        customBodyRender:
          col.options?.customBodyRender ||
          renderCell(
            col.type,
            { ...datatablesColumnDefaults?.options, ...col.options },
            {
              isEditingMode: editMode,
              modifiedData: modifiedData,
              setModifiedData: setModifiedData,
            },
            t,
            currentLanguage
          ),
        customBodyRenderLite: col.options?.customBodyRenderLite,
        setCellProps: (columnMeta) => ({
          align: col?.options?.align || columnDefaults?.options?.align,
        }),
        customFilterListOptions: col.options?.customFilterListOptions,
        display:
          editMode &&
          (col.options?.edit !== undefined
            ? col.options?.edit
            : datatablesColumnDefaults?.options?.edit)
            ? true
            : savedColumnsView?.length
            ? col.options?.forceView
              ? true
              : savedColumnsView.includes(col.name)
            : display !== undefined
            ? col.options?.forceView
              ? true
              : display
            : columnDefaults.options.display,
        sort: sort !== undefined ? sort : columnDefaults.options.sort,
        filter: filter !== undefined ? filter : columnDefaults.options.fitler,
        hint: hint !== undefined ? hint : columnDefaults.options.hint,
        viewColumns:
          viewColumns !== undefined
            ? viewColumns
            : columnDefaults.options.viewColumns,
        searchable:
          searchable !== undefined
            ? searchable
            : columnDefaults.options.searchable,
        empty: empty !== undefined ? empty : columnDefaults.options.empty,
        draggable:
          draggable !== undefined
            ? draggable
            : columnDefaults.options.draggable,
        download:
          download !== undefined ? download : columnDefaults.options.download,
        print: print !== undefined ? print : columnDefaults.options.print,
        filterList,
        sortCompare: col.options?.sortCompare,
      },
    };
  });
};

const prepareDataToSave = (data) => (
  modifiedData,
  ids
): DatatablesSaveReturn => {
  const updatedData = data.map((row) => {
    if (modifiedData[row._id]) {
      return {
        ...row,
        ...modifiedData[row._id],
      };
    }
    return row;
  });
  return {
    data: updatedData,
    updatedData: updatedData.filter((row) => ids.includes(row._id) || !row._id),
    updatedIds: ids,
  };
};

const datatablesDefault = {
  download: true,
  filter: true,
  pagination: true,
  print: true,
  search: true,
  sort: true,
  viewColumns: true,
};

/**
 * Datatable controller props
 */
export type DatatablesControllerProps = {
  /**
   * Edit several informations
   */
  bulkEdit?: boolean;
  /**
   * Datatable columns to render
   */
  columns: Array<DatatableColumn>;
  /**
   * Data taked from MUIDataTable React component
   */
  data: MUIDataTableProps['data'];
  /**
   * Default sort order set by MUIDataTableOptions
   */
  defaultSortOrder?: MUIDataTableOptions['sortOrder'];
  /**
   * Custom toolbar actions to add at the end
   */
  endCustomToolbar?: React.ReactNode;
  /**
   * Function on click to add data
   */
  handleAddData?: () => void;
  /**
   * Function to clear added datas
   */
  handleClearAdd?: () => void;
  /**
   * Function on click on row
   */
  handleRowClick?: (rowIndex: number) => void;
  /**
   * Function on click to save datas
   */
  handleSaveData?: (updates: DatatablesSaveReturn) => Promise<any>;
  /**
   * Id to store datatable datas in local storage
   */
  id: string;
  /**
   * Boolean loading data
   */
  loading?: boolean;
  /**
   * Options available on Datatable component + MUIDataTable React component
   */
  options: any;
  /**
   * To know about the edit mode on parent component
   */
  setIsInEditingMode?: (isInEditingMode: boolean) => void;
  /**
   * Custom toolbar actions to add at the end
   */
  startCustomToolbar?: React.ReactNode;
  /**
   * Title of the datatable
   */
  title: MUIDataTableProps['title'];
  /**
   * Check if data is refreashing while asking to save after and edit
   */
  refreshData?: any;
};

/**
 * Controller of Datatables, managing tooltip translations, datas saving, edit mode toggling and edit clearing
 */
export function DatatablesController(
  props: DatatablesControllerProps
): JSX.Element {
  const { columns, handleRowClick, handleSaveData } = props;
  const { addTranslationDynamically, t, currentLanguage } = useTranslation();
  const { addErrorSnackbar, addSuccessSnackbar } = useSnackbar();

  React.useEffect(() => {
    Object.entries({
      fr: {
        addTooltip: 'Ajouter',
        bulkSave: 'Modifications enregistrées',
        bulkSaveError:
          "Problème durant l'enregistrement, merci d'essayer à nouveau",
        cancelTooltip: 'Annuler',
        editTooltip: 'Editer',
        saveTooltip: 'Enregistrer',
      },
      en: {
        addTooltip: 'Add',
        bulkSave: 'Changes saved',
        bulkSaveError: 'Error while saving changes, please try again',
        cancelTooltip: 'Cancel',
        editTooltip: 'Edit',
        saveTooltip: 'Save',
      },
    }).map(([lng, translation]) => {
      addTranslationDynamically(
        lng as KiwayLanguagesType,
        'kiway-datatables',
        translation,
        true
      );
    });
  }, []);

  const [isInEditingMode, setIsInEditingMode] = React.useState(false);
  const [modifiedData, setModifiedData] = React.useState({});

  React.useEffect(() => {
    if (props.setIsInEditingMode) {
      props.setIsInEditingMode(isInEditingMode);
    }
  }, [isInEditingMode]);

  const toggleEditMode = () => {
    setIsInEditingMode(!isInEditingMode);
  };

  const handleSaveClick = (): void => {
    setIsInEditingMode(!isInEditingMode);
    if (handleSaveData) {
      handleSaveData(
        prepareDataToSave(props.data)(modifiedData, Object.keys(modifiedData))
      ).then(async (result) => {
        if (result !== undefined && !result) {
          addErrorSnackbar(t('kiway-datatables:bulkSaveError'));
        } else if (Object.keys(modifiedData)?.length) {
          addSuccessSnackbar(t('kiway-datatables:bulkSave'));
          if (props.refreshData) {
            await props.refreshData();
          }
        }
        setModifiedData({});
      });
    }
  };

  const handleAddClick = () => {
    if (props.handleAddData) {
      return () => {
        props.handleAddData();
        if (!isInEditingMode) {
          setIsInEditingMode(true);
        }
      };
    }
  };

  const clearEdit = () => {
    setIsInEditingMode(false);
    setModifiedData({});
    if (props.handleClearAdd) {
      props.handleClearAdd();
    }
  };

  return (
    <Datatables
      bulkEdit={props.bulkEdit}
      clear={clearEdit}
      columns={getColumns(
        columns,
        props.id,
        isInEditingMode,
        modifiedData,
        setModifiedData,
        t,
        currentLanguage
      )}
      data={props.data}
      defaultSortOrder={props.defaultSortOrder}
      endCustomToolbar={props.endCustomToolbar}
      handleAddData={handleAddClick()}
      handleRowClick={handleRowClick}
      handleSaveData={handleSaveData ? handleSaveClick : undefined}
      id={props.id}
      isInEditingMode={isInEditingMode}
      loading={props.loading}
      options={{
        ...props.options,
        download: isInEditingMode
          ? false
          : props.options?.download !== undefined
          ? props.options?.download
          : datatablesDefault.download,
        filter: isInEditingMode
          ? false
          : props.options?.filter !== undefined
          ? props.options?.filter
          : datatablesDefault.filter,
        pagination: isInEditingMode
          ? false
          : props.options?.pagination !== undefined
          ? props.options?.pagination
          : datatablesDefault.pagination,
        print: isInEditingMode
          ? false
          : props.options?.print !== undefined
          ? props.options?.print
          : datatablesDefault.print,
        search: isInEditingMode
          ? false
          : props.options?.search !== undefined
          ? props.options?.search
          : datatablesDefault.search,
        sort: isInEditingMode
          ? false
          : props.options?.sort !== undefined
          ? props.options?.sort
          : datatablesDefault.sort,
        viewColumns: isInEditingMode
          ? false
          : props.options?.viewColumns !== undefined
          ? props.options?.viewColumns
          : datatablesDefault.viewColumns,
      }}
      originalColumns={props.columns}
      startCustomToolbar={props.startCustomToolbar}
      title={props.title}
      toggleEditMode={toggleEditMode}
    />
  );
}
