import * as React from 'react';
import { UserGlobalSettings } from './UserGlobalSettings';
import momentTZ from 'moment-timezone';
import { User } from '../../../core/entities/User';
import { useUsersGateway } from '../../../entrypoints/react/useUsersGateway';
import { AuthContext } from '../../contexts';
import { useTranslation } from '@kiway/shared/utils/translation';
import { useSnackbar } from '@kiway/shared/utils/snackbar';

export function UserGlobalSettingsController() {
  const [user, setUser] = React.useState([new User({})]);
  const { checkToken, editProfile } = useUsersGateway();
  const { updateUserInfo } = React.useContext(AuthContext);
  const {
    availableLanguages,
    changeLanguage,
    defaultLanguage,
    t,
  } = useTranslation();
  const { addErrorSnackbar, addSuccessSnackbar } = useSnackbar();

  React.useEffect(() => {
    checkToken().then(({ user }) => {
      setUser([user]);
    });
    return () => {
      setUser([new User({})]);
    };
  }, []);
  const handleSave = () => {
    editProfile(user[0]).then((newUser) => {
      if (newUser?.getId()) {
        updateUserInfo(newUser.toJSON(), false);
        addSuccessSnackbar(t('authentication:editProfile.saveSuccess'));
      } else {
        addErrorSnackbar(t('authentication:editProfile.saveError'));
      }
    });
  };
  const handleChange = (field: string) => (newValue) => {
    let updated;
    switch (field) {
      case 'timezone':
        if (newValue !== user[0]?.getTimezone()) {
          updated = user[0]?.setTimezone(
            newValue !==
              t('authentication:editProfile.settings.browserDefaults')
              ? newValue
              : null
          );
          handleSave();
        }
        break;
      case 'language':
        updated = user[0]?.setLanguage(newValue !== '0' ? newValue : null);
        changeLanguage(newValue !== '0' ? newValue : defaultLanguage);
        handleSave();
        break;
    }
    if (updated) {
      setUser([updated]);
    }
  };
  return (
    <UserGlobalSettings
      availableLanguages={Object.entries(
        availableLanguages
      )?.map(([key, { code, label }]) => ({ key, code, label }))}
      handleChange={handleChange}
      timezones={[
        t('authentication:editProfile.settings.browserDefaults'),
        ...momentTZ.tz.names(),
      ]}
      user={user[0]}
    />
  );
}
