import { Order } from '../entities/Order';
import { OrderProvider } from './OrderProvider';

export type CustomerInput = {
  id: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: string;
  mobilePhone: string;
  profession: string;
  email: string;
  timezone: string;
  language: string;
  custom?: any;
};

export class CreateOrder {
  private orderProvider: OrderProvider;

  public constructor(orderProvider: OrderProvider) {
    this.orderProvider = orderProvider;
  }

  public async execute(
    prescriber: CustomerInput,
    customer?: CustomerInput
  ): Promise<Order> {
    return this.orderProvider.createOrder(prescriber, customer);
  }
}
