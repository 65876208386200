export default {
  chiefComplaint: {
    buttons: {
      add: 'Ajouter un motif de consultation',
      update: 'Modifier ce motif de consultation',
    },
    deleteModal: {
      content:
        "Êtes-vous sûr de vouloir supprimer ce motif de consultation ? L'opération est irréversible.",
      title: 'Supprimer un motif de consultation',
    },
    fields: {
      consultationPlaces: 'Lieux de consultation',
      duration: 'Durée',
      name: 'Motif de consultation',
      price: 'Tarif',
      specialty: 'Spécialité',
    },
    label: {
      plural: 'Motifs de consultation',
    },
    list: 'Liste de vos motifs de consultations',
    none: "Vous n'avez aucun motif de consultation pour le moment",
    requirements:
      'Vous devez renseigner au moins une spécialité et un lieu de consultation pour ajouter des motifs de consultation',
    slotInfo:
      'Lorsque vous modifiez ou supprimez un motif, pensez à mettre à jour vos semaines types en conséquence.',
    snackbars: {
      error: {
        create:
          "Un problème est survenu durant la création du motif de consultation. Merci d'essayer à nouveau.",
        delete:
          "Un problème est survenu durant la suppression du motif de consultation. Merci d'essayer à nouveau.",
        deleteExistingChilds:
          'Ce motif de consultation est utilisé par des rendez-vous ou des templates de semaine, vous ne pouvez pas le supprimer.',
        update:
          "Un problème est survenu durant la mise à jour du motif de consultation. Merci d'essayer à nouveau.",
        validation: 'Vous devez remplir tous les champs obligatoires (*)',
      },
      success: {
        create: 'Le motif de consultation a bien été ajouté',
        delete: 'Le motif de consultation a bien été supprimé',
        update: 'Le motif de consultation a bien été mis à jour',
      },
    },
    subtitle:
      'Vous pouvez associer des motifs de consultation à une spécialité et à des lieux d\'exercice. Les motifs seront affichés lors de la prise de rendez-vous en ligne, une fois que le(s) lieu(x) et la spécialité sont choisis.\n\nChaque motif peut avoir une durée de séance et un prix différents, par exemple :\n\n *  "Première consultation en acupuncture" dure 90min et coûte 60€\n*  "Consultation de suivi en acupuncture" dure 60min et coûte 50€',
    title: 'Gestion de vos motifs de consultation',
  },
  invoicing: {
    addressInfo: 'Adresse du siège social',
    goToProfile: 'Modifier ces informations',
    goToSettings: 'Paramétrer la facturation',
    invoiceAddressInfo: 'Adresse de facturation',
    invoiceAddressInfoExplain:
      "L'adresse de facturation dépend du lieu de consultation",
    invoiceNumbering: 'Numérotation de facture',
    labels: {
      businessName: 'Raison sociale',
      invoiceDigits: 'Nombre de chiffres',
      invoiceNextDigit: 'Prochaine séquence de facture',
      invoicePrefix: 'Préfixe de la facture',
      invoiceSuffix: 'Suffixe de la facture',
      naf: 'Code NAF',
      siret: 'Numéro SIREN/SIRET',
      specialMentions: 'Mentions particulières',
      tva: 'Numéro de TVA',
    },
    legalInfo: 'Informations légales',
    legalInfoSubtitle:
      'Ces informations seront affichées à la fin de la facture.',
    needInvoicingDataToBillSession:
      "Vous devez d'abord paramétrer la facturation avant de pouvoir vous en servir",
    nextInvoiceNumberExample: 'La prochaine facture portera le numéro suivant',
    placeholders: {
      specialMentions:
        'Par exemple, si régime de franchise de TVA : TVA non applicable, art. 293 B du Code général des impôts',
    },
    resultBottomInvoice: 'Résultat en bas de la facture :',
    saveChangesBeforeEditUser:
      'Sauvegardez ou annulez vos changements avant de pouvoir modifier ces informations',
    snackbars: {
      errorUpdate: "Un problème est survenu, merci d'essayer à nouveau",
      successUpdate: 'Paramètres enregistrés avec succès',
    },
  },
  minisite: {
    contactInfo: {
      label:
        "Activer/Désactiver l'affichage de vos informations de contact (téléphone)",
      phoneChoice: 'Choix du numéro à afficher sur votre page',
      snackbars: {
        error: {
          update:
            "Un problème est survenu durant l'enregistrement du réglage de vos informations de contact. Merci d'essayer à nouveau.",
        },
        success: {
          update:
            'Le réglage de vos informations de contact a été mis à jour avec succès',
        },
      },
    },
    content: {
      description: {
        label: 'Description',
        previewLabel: 'Prévisualisation de votre description',
      },
      displaySanitary: 'Afficher des consignes sanitaires',
      formation: {
        label: 'Formations',
        previewLabel: 'Prévisualisation de vos formations',
      },
      placeholderSanitary: 'Ajoutez vos consignes ici...*',
      snackbars: {
        error: {
          sanitary:
            "Veuillez remplir ou désactiver les consignes sanitaires avant d'enregistrer",
          update:
            "Un problème est survenu durant l'enregistrement du contenu de votre page publique. Merci d'essayer à nouveau.",
        },
        success: {
          update:
            'Le contenu de votre page publique a été mis à jour avec succès',
        },
      },
      styling:
        "Mise en page :\n* **Gras** : entourez la chaîne de caractères par deux astérisques de chaque côté : &ast;&ast;text en gras&ast;&ast;\n* Liste non ordonnée : commencez la ligne par une astérisque suivi d'au moins un espace : &ast; Item \n",
      subtitle:
        "C'est ici que vous pouvez détailler votre pratique et en dire plus sur vous.",
      title: 'Rédigez le contenu de votre page publique',
      titleInformation: 'Informations professionnelles',
      titleSanitary: 'Consignes sanitaires (COVID 19)',
    },
    displayMinisite: {
      label: 'Activer/Désactiver votre page publique',
      snackbars: {
        error: {
          update:
            "Un problème est survenu durant l'enregistrement du réglage de votre page publique. Merci d'essayer à nouveau.",
        },
        success: {
          update:
            "Le réglage d'affichage de votre page publique a été mis à jour avec succès",
        },
      },
    },
    subtitle:
      "Cette page permettra d'augmenter votre visibilité et de proposer la prise de rendez-vous en ligne à vos patients.\n\nSi vous ne souhaitez pas apparaître en ligne, vous pouvez désactiver cette fonctionnalité à tout moment.",
    title: 'Paramétrage de votre page publique',
    uploads: {
      gallery: {
        snackbars: {
          error: {
            delete:
              "Un problème est survenu durant la suppression du ou des fichier(s), merci d'essayer à nouveau",
            upload:
              "Un problème est survenu durant le téléchargement du ou des fichier(s), merci d'essayer à nouveau",
          },
          success: {
            delete: 'Supprimé avec succès',
            upload: 'Enregistré avec succès',
          },
        },
        subtitle:
          'Ces photos seront affichées sous forme de galerie sur votre page publique',
        title: 'Galerie photos',
      },
      profile: {
        snackbars: {
          error: {
            delete:
              "Un problème est survenu durant la suppression du fichier, merci d'essayer à nouveau",
            upload:
              "Un problème est survenu durant le téléchargement du fichier, merci d'essayer à nouveau",
          },
          success: {
            delete: 'Supprimé avec succès',
            upload: 'Enregistré avec succès',
          },
        },
        subtitle:
          'Cette photo servira comme photo de profil sur votre page publique',
        title: 'Photo de profil',
      },
      subtitle:
        'Les photos que vous téléchargez seront publiques tant que votre page publique est activée. Vous pouvez à tout moment les supprimer ou en ajouter.',
      title: 'Téléchargez vos photos',
    },
    urlSettings: {
      confirmModalContent:
        "Tout changement du slug entraînera la révocation de l'URL précédente. Êtes-vous sûr de vouloir continuer ?",
      confirmModalTitle: 'Confirmer vos modifications',
      doubleSlug: 'Existe déjà',
      errorSave:
        "Un problème est survenu durant l'enregistrement. Merci d'essayer à nouveau.",
      lock:
        "Activer/Désactiver les modifications (si vous modifiez ces paramètres, pensez que l'URL de votre page risque de changer)",
      mainSpecialty: {
        label: 'Spécialité principale',
      },
      mainWorkplace: {
        label: 'Lieu de consultation principal',
      },
      preview: {
        label: "Prévisualisation de l'URL de votre page publique",
      },
      refresh:
        'Si aucun "lieu" ou "spécialité" ne s\'affiche, merci de rafraichir la page.',
      slug: {
        label: "Votre nom personnalisé pour l'URL",
      },
      subtitle: "Le nom personnalisé permet d'obtenir une URL unique",
      successSave: 'Enregistré avec succès',
      title: "Paramétrage de l'adresse de votre page publique (URL)",
    },
  },
  onBoardingInfo:
    "Bonjour et bienvenue chez {{app}}.\n\nAfin de démarrer dans les meilleures conditions nous vous invitons à paramétrer les informations concernant votre activité.\n\nPour cela, rien de plus simple, il vous suffit de suivre les étapes ci-dessous. Laissez-vous guider par notre petit guide.\n\nAmicalement, l'équipe {{app}} !",
  places: {
    buttons: {
      add: 'Ajouter un lieu de consultation',
      update: 'Modifier ce lieu de consultation',
    },
    deleteModal: {
      content:
        "Êtes-vous sur de vouloir supprimer ce lieu de consultation ? L'opération est irréversible.",
      title: 'Supprimer un lieu de consultation',
    },
    label: {
      plural: 'Lieux de consultation',
    },
    list: 'Liste de vos lieux de consultation',
    none: "Vous n'avez aucun lieu de consultation pour le moment",
    previewInfo: 'Prévisualisation du résultat sur votre page publique',
    slotInfo:
      'Lorsque vous modifiez ou supprimez un lieu, pensez à mettre à jour vos semaines types en conséquence.',
    snackbars: {
      error: {
        create:
          "Un problème est survenu durant la création du lieu de consultation. Merci d'essayer à nouveau.",
        delete:
          "Un problème est survenu durant la suppression du lieu de consultation. Merci d'essayer à nouveau.",
        deleteExistingChilds:
          'Ce lieu de consultation est utilisé par des motifs de consultation, des rendez-vous ou des templates de semaine, vous ne pouvez pas le supprimer.',
        update:
          "Un problème est survenu durant la mise à jour du lieu de consultation. Merci d'essayer à nouveau.",
      },
      success: {
        create: 'Le lieu de consultation a bien été ajouté',
        delete: 'Le lieu de consultation a bien été supprimé',
        update: 'Le lieu de consultation a bien été mis à jour',
      },
    },
    subtitle:
      "Saisissez vos différents lieux d'exercice. Ils seront proposés dans la prise de rendez-vous en ligne.",
    title: 'Gestion de vos lieux de consultation',
  },
  snackbars: {
    error: {
      form: {
        errorsNotResolved:
          'Vous devez résoudre les erreurs et valider à nouveau.',
        noAddress:
          'Vous devez renseigner une adresse valide. Assurez vous de cliquer sur la suggestion correspondant à votre adresse lors de la recherche.',
        noName: 'Vous devez renseigner un nom pour votre lieu de consultation.',
      },
      rightsIssue:
        "Une erreur empêche l'envoi de la requête, il semblerait s'agir d'un problème de droits",
    },
  },
  specialties: {
    info:
      'La sauvegarde est automatique pour ce champ. Lorsque vous supprimez une spécialité, pensez à mettre à jour vos semaines types en conséquence.',
    label: {
      plural: 'Spécialités',
      singular: 'Spécialité',
    },
    pickOne: 'Choisir une spécialité',
    snackbars: {
      error: {
        deleteExistingChilds:
          'Cette spécialité est utilisée par des motifs de consultation, vous ne pouvez pas la supprimer.',
        update:
          "Un problème est survenu durant l'enregistrement de vos spécialités. Merci d'essayer à nouveau.",
      },
      success: {
        update: 'Spécialités enregistrées avec succès',
      },
    },
    subtitle:
      'Sélectionnez les spécialités que vous pratiquez et que vous voulez proposer à vos patients.',
    title: 'Gestion de vos spécialités',
  },
  subtitle:
    'Ces informations sont nécessaires au bon fonctionnement de la plateforme. Elles seront affichées sur votre page publique pour présenter votre activité. Elles permettront également de configurer la prise de rendez-vous en ligne.',
  tabs: {
    activity: 'Activité',
    agenda: 'RDV en ligne',
    invoicing: 'Facturation',
    publicPage: 'Page publique',
    workplaces: 'Lieux de consultation',
  },
  title: 'Paramétrage de vos informations professionnelles',
};
