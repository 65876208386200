export default {
  appBar: {
    prefix: 'Séance du',
  },
  diagnosis: {
    title: 'Bilan énergétique',
  },
  followUp: {
    comment: 'Commentaire',
    date: 'Date',
    rating: 'Evaluation',
    title: 'Suivi du traitement',
  },
  fourMethods: {
    title: 'Quatre temps du diagnostic',
  },
  info: {
    chiefComplaint: 'Motif de consultation',
    comment: 'Commentaire',
    date: 'Date',
    title: 'Informations générales',
  },
  patientNote: {
    title: 'Notes patient',
  },
  treatment: {
    title: 'Traitement',
  },
};
