import {
  AGENDA_GET_APPOINTMENTS,
  DASHBOARD_GET_APPOINTMENTS,
  FILE_GET_APPOINTMENTS,
  MODAL_GET_APPOINTMENT,
} from './queries'
import {
  AGENDA_ADD_APPOINTMENT,
  MODAL_UPDATE_APPOINTMENT,
  PUBLIC_PAGE_ADD_APPOINTMENT,
  PUBLIC_PAGE_UPDATE_APPOINTMENT,
  DELETE_APPOINTMENT,
} from './mutations'
import {
  AGENDA_ON_APPOINTMENT_EVENT,
  DASHBOARD_ON_APPOINTMENT_EVENT,
  ACTION_ADD,
  ACTION_DELETE,
  ACTION_UPDATE,
} from './subscriptions'

export {
  ACTION_ADD,
  ACTION_DELETE,
  ACTION_UPDATE,
  AGENDA_GET_APPOINTMENTS,
  AGENDA_ADD_APPOINTMENT,
  AGENDA_ON_APPOINTMENT_EVENT,
  DASHBOARD_GET_APPOINTMENTS,
  DASHBOARD_ON_APPOINTMENT_EVENT,
  DELETE_APPOINTMENT,
  FILE_GET_APPOINTMENTS,
  MODAL_GET_APPOINTMENT,
  MODAL_UPDATE_APPOINTMENT,
  PUBLIC_PAGE_ADD_APPOINTMENT,
  PUBLIC_PAGE_UPDATE_APPOINTMENT,
}
