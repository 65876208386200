import { useTranslation } from '@kiway/shared/utils/translation';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import * as React from 'react';
import { Skeleton } from '@material-ui/lab';

interface ProductDetailsCheckboxTypeProps {
  handleChange?: (value: string | number | boolean) => any;
  handleSave: (id?: string) => void;
  label: string;
  loading?: boolean;
  value: boolean;
}

export function ProductDetailsCheckboxType({
  handleChange,
  handleSave,
  label,
  loading,
  value,
}: React.PropsWithChildren<ProductDetailsCheckboxTypeProps>) {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      control={
        loading ? (
          <Skeleton variant="rect" width={17} style={{ marginRight: '5px' }} />
        ) : (
          <Checkbox
            checked={value}
            onChange={(event) => {
              handleChange(event.target.checked);
              handleSave();
            }}
          />
        )
      }
      label={t(label)}
    />
  );
}
