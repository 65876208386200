import * as React from 'react';
import { ShippingDetails } from './ShippingDetails';
import { PharmacoOrder } from '../../../core/entities/PharmacoOrder';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  Country,
  ShippingMethod,
  useCountriesGateway,
  useOrdersGateway,
  useShippingMethodsGateway,
} from '@kiway/ecommerce-react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Address } from '@kiway/shared/features/authentication-react-compatible';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Order } from '@kiway/ecommerce-react-compatible';
import { links } from '../../../config';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Address as UserAddress } from '@kiway/shared/features/authentication-react-compatible';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  AuthContext,
  useUsersGateway,
} from '@kiway/shared/features/authentication-react';
import { Redirect } from 'react-router-dom';
import { useLoyaltyTransactionsGateway } from '@kiway/shared/loyalty-program-react';

export function ShippingDetailsController(props) {
  const { findOne, editOrders } = useOrdersGateway();
  const [loading, setLoading] = React.useState(false);
  const [order, setOrder] = React.useState<PharmacoOrder[]>([]);
  const [realOrder, setRealOrder] = React.useState<Order[]>([]);
  const [panelOpen, setPanelOpen] = React.useState<string | null>('panel1');
  const [shippingMethods, setShippingMethods] = React.useState<
    ShippingMethod[]
  >([]);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const { findActive: findActiveShippingMethods } = useShippingMethodsGateway();
  const { findAll: findAllCountries } = useCountriesGateway();
  const [initShippingAddress, setInitShippingAddress] = React.useState<
    UserAddress
  >(null);
  const [initInvoicingAddress, setInitInvoicingAddress] = React.useState<
    UserAddress
  >(null);
  const [userAddresses, setUserAddresses] = React.useState<Array<any>>([]);
  const [
    selectedShippingAddressId,
    setSelectedShippingAddressId,
  ] = React.useState<string>('');
  const [
    selectedInvoicingAddressId,
    setSelectedInvoicingAddressId,
  ] = React.useState<string>('');
  const [
    displayShippingAddressForm,
    setDisplayShippingAddressForm,
  ] = React.useState(true);
  const [
    displayInvoicingAddressForm,
    setDisplayInvoicingAddressForm,
  ] = React.useState(true);
  const { findOneById } = useUsersGateway();
  const invoicingAddress = order[0]
    ?.getOrder()
    ?.getInvoicingAddress()
    ?.toJSON();
  const shippingAddress = order[0]?.getOrder()?.getShippingAddress()?.toJSON();
  const realShippingAddress = new UserAddress(shippingAddress);
  const realInvoicingAddress = new UserAddress(invoicingAddress);

  React.useEffect(() => {
    findActiveShippingMethods().then((shippingMethods) =>
      setShippingMethods(shippingMethods)
    );
    findAllCountries().then((countries) => setCountries(countries));
    return () => {
      setCountries([]);
      setShippingMethods([]);
    };
  }, []);

  React.useEffect(() => {
    async function getUserAddresses() {
      const userId = order?.[0]?.getOrder().getCustom().customerId;
      if (userId) {
        const user = await findOneById(userId);
        if (user) {
          let setDefaultShippingAddress = false;
          let setDefaultInvoicingAddress = false;
          const userAddresses = user
            .getAddresses()
            ?.filter((address) => address.validateAddress());
          const defaultAddress = userAddresses?.find((address) =>
            address.isDefault()
          );
          setUserAddresses(userAddresses);
          if (userAddresses?.length <= 0) {
            setDisplayShippingAddressForm(true);
          } else if (order[0]?.getOrder()?.getCustom()?.shippingAddressId) {
            if (
              userAddresses?.find(
                (addr) =>
                  addr.getId() ===
                  order[0]?.getOrder()?.getCustom()?.shippingAddressId
              )
            ) {
              setDisplayShippingAddressForm(false);
            } else {
              setDisplayShippingAddressForm(true);
            }
          } else {
            setDisplayShippingAddressForm(false);
            if (defaultAddress) {
              setDefaultShippingAddress = true;
            }
          }
          if (
            order?.[0]?.getOrder()?.isInvoicingAddressSameAsShippingAddress()
          ) {
            setDisplayInvoicingAddressForm(false);
          } else {
            if (userAddresses?.length > 0) {
              setDisplayInvoicingAddressForm(false);
              if (defaultAddress) {
                setDefaultInvoicingAddress = true;
              }
            } else {
              setDisplayInvoicingAddressForm(true);
            }
          }
        }
      }
    }
    getUserAddresses().then(() => {
      setSelectedShippingAddressId(
        order[0]?.getOrder()?.getCustom()?.shippingAddressId
      );
      setSelectedInvoicingAddressId(
        order[0]?.getOrder()?.getCustom()?.invoicingAddressId
      );
    });
    return () => {
      setUserAddresses([]);
      setSelectedShippingAddressId('');
      setSelectedInvoicingAddressId('');
    };
  }, [order?.[0]]);

  React.useEffect(() => {
    async function setDefaults() {
      if (order?.[0] && userAddresses) {
        if (userAddresses?.length > 0) {
          const defaultAddress = userAddresses?.find((address) =>
            address.isDefault()
          );
          if (defaultAddress) {
            const hasToDefaultShippingAddress = !order[0]
              ?.getOrder()
              ?.getShippingAddress()
              ?.validateAddress();
            const hasToDefaultInvoicingAddress = !order[0]
              ?.getOrder()
              ?.getInvoicingAddress()
              ?.validateAddress();
            const isBothTheSameAddress: boolean = order[0]
              .getOrder()
              .isInvoicingAddressSameAsShippingAddress();
            if (
              hasToDefaultShippingAddress &&
              hasToDefaultInvoicingAddress &&
              isBothTheSameAddress
            ) {
              await handleChangeShippingAddress(defaultAddress.toJSON(), true);
              handleChangePanel(null)();
            } else if (
              hasToDefaultShippingAddress &&
              hasToDefaultInvoicingAddress &&
              !isBothTheSameAddress
            ) {
              const orderToUpdate = order[0]?.changeInvoicingAddress(
                defaultAddress
              );
              handleChangeStateOrder(orderToUpdate);
              await handleSaveOrder(orderToUpdate);
              handleChangePanel(null)();
            } else if (
              hasToDefaultShippingAddress &&
              !hasToDefaultInvoicingAddress &&
              !isBothTheSameAddress
            ) {
              await handleChangeShippingAddress(defaultAddress.toJSON(), true);
              handleChangePanel(null)();
            } else if (
              !hasToDefaultShippingAddress &&
              hasToDefaultInvoicingAddress &&
              !isBothTheSameAddress
            ) {
              await handleChangeInvoicingAddress(defaultAddress.toJSON(), true);
              handleChangePanel(null)();
            }
          }
        }
      }
    }
    setDefaults();
  }, [order?.[0], userAddresses]);

  const [
    isDefaultShippingMethodSet,
    setIsDefaultShippingMethodSet,
  ] = React.useState<boolean>(false);
  React.useEffect(() => {
    async function setDefaultShippingMethod() {
      if (
        order?.[0]?.getOrder()?.getShippingMethodFilter() &&
        isDefaultShippingMethodSet
      ) {
        const filteredShippingMethods = shippingMethods?.filter(
          order?.[0]?.getOrder()?.getShippingMethodFilter()
        );
        if (filteredShippingMethods?.length > 0) {
          if (
            !order?.[0]?.getOrder()?.getShippingMethod()?.getId() ||
            !filteredShippingMethods?.find(
              (shippingMethod) =>
                shippingMethod?.getId() ===
                order?.[0]?.getOrder()?.getShippingMethod()?.getId()
            )
          ) {
            // Si on a des méthodes de livraison dispo et qu'on a pas de méthode de livraison sélectionnée ou alors qu'elle n'existe pas parmi les dispo alors on set à la première méthode de livraison dispo
            await handleChangeShippingMethod(
              filteredShippingMethods?.sort(
                ShippingMethod.sortByDisplayOrder()
              )?.[0]
            );
          }
        } else {
          if (order?.[0]?.getOrder()?.getShippingMethod()?.getId()) {
            await handleChangeShippingMethod(null);
          }
        }
        setIsDefaultShippingMethodSet(true);
      }
    }
    setDefaultShippingMethod();
  }, [order?.[0]?.getOrder()?.getShippingMethodFilter(), shippingMethods]);

  React.useEffect(() => {
    setInitShippingAddress(realOrder?.[0]?.getShippingAddress());
    setInitInvoicingAddress(realOrder?.[0]?.getInvoicingAddress());
  }, [realOrder?.[0]]);

  React.useEffect(() => {
    if (order?.[0]?.getOrder()?.isInvoicingAddressSameAsShippingAddress()) {
      handleChangeInvoicingAddress(shippingAddress);
      handleSaveOrder();
    }
  }, [order?.[0]?.getOrder()?.isInvoicingAddressSameAsShippingAddress()]);

  React.useEffect(() => {
    setLoading(true);
    if (props.id) {
      findOne(props.id)
        .then(async (order) => {
          if (order && order.getId()) {
            setRealOrder([new Order(order.toJSON())]);
            setOrder([
              new PharmacoOrder({
                order,
                saveCallback: handleSaveOrder,
              }).createNewTempFormula([]),
            ]);
            if (order.getShippingAddress()?.validateAddress()) {
              if (order.getInvoicingAddress()?.validateAddress()) {
                if (order.getShippingMethod()?.getId()) {
                  setPanelOpen(null);
                } else {
                  setPanelOpen('panel3');
                }
              } else {
                setPanelOpen('panel2');
              }
            }
          }
        })
        .finally(() => setLoading(false));
    }
    return () => {
      setOrder([]);
    };
  }, [props.id]);

  const handleSaveOrder = async (forcedOrder?: PharmacoOrder) => {
    return editOrders([
      (forcedOrder ?? order[0])?.getOrder()?.toInput(true),
    ]).then((order) => {
      if (order) {
        setRealOrder([new Order(order?.[0].toJSON())]);
        setOrder([
          new PharmacoOrder({
            order: order?.[0],
            saveCallback: handleSaveOrder,
          }).createNewTempFormula([]),
        ]);
      }
    });
  };

  const handleChangeStateOrder = (order: PharmacoOrder) => {
    setOrder([order]);
  };

  const handleChangeShippingAddress = async (address: any, save?: boolean) => {
    const shippingAddress = new Address(address);
    if (order[0]?.getOrder()?.isInvoicingAddressSameAsShippingAddress()) {
      const invoicingAddress = new Address(address);
      handleChangeInvoicingAddress(invoicingAddress);
    }
    const orderToUpdate = order[0]?.changeShippingAddress(shippingAddress);
    handleChangeStateOrder(orderToUpdate);
    if (save) {
      await handleSaveOrder(orderToUpdate);
    }
  };

  const handleChangeInvoicingAddress = async (address: any, save?: boolean) => {
    const invoicingAddress = new Address(address);
    const orderToUpdate = order[0]?.changeInvoicingAddress(invoicingAddress);
    handleChangeStateOrder(orderToUpdate);
    if (save) {
      await handleSaveOrder(orderToUpdate);
    }
  };

  const handleClickSameAddress = async (value: boolean) => {
    handleChangeStateOrder(order[0]?.handleClickSameAddress(value));
    await handleSaveOrder();
  };

  const handleChangePanel = (panel: string) => () => {
    if (panelOpen === panel) {
      setPanelOpen(null);
    } else {
      setPanelOpen(panel);
    }
  };

  const handleChangeShippingMethod = async (shippingMethod: ShippingMethod) => {
    const orderToUpdate = order[0]?.changeShippingMethod(shippingMethod);
    handleChangeStateOrder(orderToUpdate);
    await handleSaveOrder(orderToUpdate);
  };

  const handleOpenOrder = () => {
    handleChangeStateOrder(order[0]?.openOrder());
    return handleSaveOrder();
  };

  const handleSelectShippingAddress = async (address) => {
    setSelectedShippingAddressId(address.id);
    handleChangeShippingAddress(address);
    await handleSaveOrder();
    if (order[0]?.getOrder()?.isInvoicingAddressSameAsShippingAddress()) {
      handleChangePanel('panel3')();
    } else {
      handleChangePanel('panel2')();
    }
  };

  const handleSelectInvoicingAddress = (address) => {
    setSelectedShippingAddressId(address.id);
    handleChangeInvoicingAddress(address);
    handleSaveOrder();
    handleChangePanel('panel3')();
  };

  const handleChangeLoyaltyProgramOption = (value: string) => {
    const thisOrder = order?.[0]?.getOrder();

    thisOrder.setCustom({
      ...(thisOrder.getCustom() ?? {}),
      loyaltyProgramOption: value,
    });

    setOrder([order?.[0]]);
    handleSaveOrder();
  };

  const { getLoyaltyPointsBalance } = useLoyaltyTransactionsGateway();
  const [loyaltyPointsBalance, setLoyaltyPointsBalance] = React.useState<
    number
  >(0);
  const {
    authState: { userInfo },
  } = React.useContext(AuthContext);

  React.useEffect(() => {
    getLoyaltyPointsBalance(userInfo?.id).then(
      (loyaltyPointsBalance: number) => {
        setLoyaltyPointsBalance(loyaltyPointsBalance);
      }
    );
    return () => {
      setLoyaltyPointsBalance(0);
    };
  }, []);

  return order?.[0]?.getOrder()?.isDeleted() ? (
    <Redirect to={`/${links.main}/${links.listOrders}`} />
  ) : (
    <ShippingDetails
      availableCountries={countries?.filter(
        Country.filterByAvailabilityInShippingMethods(shippingMethods)
      )}
      availableShippingMethods={
        order?.[0]?.getOrder()
          ? shippingMethods?.filter(
              order?.[0]?.getOrder()?.getShippingMethodFilter()
            )
          : []
      }
      displayInvoicingAddressForm={displayInvoicingAddressForm}
      displayShippingAddressForm={displayShippingAddressForm}
      handleChangeInvoicingAddress={handleChangeInvoicingAddress}
      handleChangeLoyaltyProgramOption={handleChangeLoyaltyProgramOption}
      loyaltyPointsBalance={loyaltyPointsBalance}
      handleChangeShippingMethod={handleChangeShippingMethod}
      handleChangeShippingAddress={handleChangeShippingAddress}
      handleClickSameAddress={handleClickSameAddress}
      handleNext={() =>
        handleOpenOrder().then(() => {
          props.history.push(links.getOrderResumeScreen(props.id));
        })
      }
      handlePrev={() =>
        props.history.push(links.getOrderCommentsScreen(props.id))
      }
      handleSave={handleSaveOrder}
      handleSelectInvoicingAddress={handleSelectInvoicingAddress}
      handleSelectShippingAddress={handleSelectShippingAddress}
      initInvoicingAddress={initInvoicingAddress}
      initShippingAddress={initShippingAddress}
      invoicingAddress={invoicingAddress}
      panelOpen={panelOpen}
      order={order?.[0]}
      realInvoicingAddress={realInvoicingAddress}
      realOrder={realOrder?.[0]}
      realShippingAddress={realShippingAddress}
      selectedInvoicingAddressId={selectedInvoicingAddressId}
      selectedShippingAddressId={selectedShippingAddressId}
      setDisplayInvoicingAddressForm={setDisplayInvoicingAddressForm}
      setDisplayShippingAddressForm={setDisplayShippingAddressForm}
      setInitShippingAddress={setInitShippingAddress}
      setInitInvoicingAddress={setInitInvoicingAddress}
      setPanelOpen={handleChangePanel}
      shippingAddress={shippingAddress}
      userAddresses={userAddresses}
    />
  );
}
