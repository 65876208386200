/* eslint-disable @typescript-eslint/no-this-alias */
import { Schema } from 'mongoose';
import * as mongoose from 'mongoose';

const UserPropsSchema = new Schema({
  user: {
    type: Schema.Types.ObjectId,
    ref: 'NewUser',
  },
  type: {
    type: String,
    enum: {
      values: [
        'stripeServices',
        'stripeCustomerBalance',
        'stripeDefaultSource',
        'stripeSources',
        'stripeSubscription',
        'stripeConnect',
      ],
      message: 'error.validator.userPropsType',
    },
  },
  data: {
    type: Object,
  },
});

UserPropsSchema.statics.updateCustomerBalance = async function (
  userId,
  stripeCustomerBalance
) {
  if (!userId || !stripeCustomerBalance) {
    return false;
  }
  const self = this;
  let stripeCustomerBalanceObj = await self.findOne({
    user: userId,
    type: 'stripeCustomerBalance',
  });
  if (!stripeCustomerBalanceObj) {
    stripeCustomerBalanceObj = new self({
      user: userId,
      type: 'stripeCustomerBalance',
    });
  }
  stripeCustomerBalanceObj.data = stripeCustomerBalance;
  return await stripeCustomerBalanceObj.save();
};

UserPropsSchema.statics.updateDefaultSource = async function (
  userId,
  stripeDefaultSource
) {
  if (!userId || !stripeDefaultSource) {
    return false;
  }
  const self = this;
  let stripeDefaultSourceObj = await self.findOne({
    user: userId,
    type: 'stripeDefaultSource',
  });
  if (!stripeDefaultSourceObj) {
    stripeDefaultSourceObj = new self({
      user: userId,
      type: 'stripeDefaultSource',
    });
  }
  stripeDefaultSourceObj.data = stripeDefaultSource;
  return await stripeDefaultSourceObj.save();
};

UserPropsSchema.statics.editSources = async function (
  userId,
  card,
  deleted = false
) {
  if (!userId || !card) {
    return false;
  }
  let cardObject = card;
  if (card.object === 'source') {
    cardObject = {
      ...card.card,
      id: card.id,
    };
  }
  const {
    id,
    brand,
    country,
    exp_month,
    exp_year,
    last4,
    metadata,
    name,
  } = cardObject;
  const self = this;
  let stripeSourcesObj = await self.findOne({
    user: userId,
    type: 'stripeSources',
  });
  if (!stripeSourcesObj) {
    stripeSourcesObj = new self({
      user: userId,
      type: 'stripeSources',
      data: [],
    });
  }
  const reducedCard = {
    id,
    brand,
    country,
    exp_month,
    exp_year,
    last4,
    metadata,
    name,
  };
  let stripeSources = stripeSourcesObj?.data || [];
  if (deleted) {
    stripeSources = stripeSources.filter(({ id }) => id !== reducedCard.id);
  } else {
    let finded = false;
    stripeSources = stripeSources.map((source) => {
      if (source.id === reducedCard.id) {
        finded = true;
        return reducedCard;
      }
      return source;
    });
    if (!finded) {
      stripeSources.push(reducedCard);
    }
  }
  stripeSourcesObj.data = stripeSources;
  return await stripeSourcesObj.save();
};

export default mongoose.model('UserProps', UserPropsSchema, 'userProps');
