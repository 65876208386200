import { Price } from './Order';

type CurrencyPosition = 'start' | 'end';

export interface ICurrencyAttributes {
  symbol: string;
  code: string;
  format: string;
  position: CurrencyPosition;
}
export class Currency {
  protected id: string;
  // Symbol example: € | $
  protected symbol: string;
  // Code example: EUR | USD
  protected code: string;
  protected format: string; // = '{{symbol}} {{amount}}';
  protected position: CurrencyPosition;

  public getId(): string {
    return this.id;
  }

  public setId(id: string): Currency {
    this.id = id;
    return this;
  }

  public getSymbol(): string {
    return this.symbol;
  }

  public setSymbol(symbol: string): Currency {
    this.symbol = symbol;
    return this;
  }

  public getCode(): string {
    return this.code;
  }

  public setCode(code: string): Currency {
    this.code = code;
    return this;
  }

  public getFormat(): string {
    return this.format;
  }

  public setFormat(format: string): Currency {
    this.format = format;
    return this;
  }

  public getPosition(): CurrencyPosition {
    return this.position ?? 'start';
  }

  public setPosition(position: CurrencyPosition): Currency {
    this.position = position;
    return this;
  }

  constructor(
    obj: Partial<ICurrencyAttributes> & {
      _id?: string;
      id?: string;
    }
  ) {
    this.id = obj?.id || obj?._id;
    this.symbol = obj?.symbol;
    this.code = obj?.code;
    this.format = obj?.format;
    this.position = obj?.position;
  }

  public toJSON(): any {
    return {
      _id: this.getId(),
      id: this.getId(),
      symbol: this.getSymbol(),
      code: this.getCode(),
      format: this.getFormat(),
      position: this.getPosition(),
    };
  }

  isDefined(): boolean {
    return (
      this.code !== undefined &&
      this.symbol !== undefined &&
      this.code !== null &&
      this.symbol !== null
    );
  }

  equals(other: any): boolean {
    if (!(other instanceof Currency)) {
      return false;
    }
    if (other.getCode() !== this.code) {
      return false;
    }
    if (other.getSymbol() !== this.symbol) {
      return false;
    }
    return true;
  }

  toString(price: Price): string {
    return (
      this.getFormat()
        ?.replace(
          '{{amount}}',
          `${parseFloat(`${price.getCentAmount() / 100}`).toFixed(
            2 + price.getAllowCentDecimals()
          )}`
        )
        ?.replace('{{symbol}}', this.getSymbol()) ||
      `${this.getSymbol()} ${price.getCentAmount() / 100}`
    );
  }
}
