import { CustomerGraphQLProvider } from '../../dataproviders/graphql-client/CustomerGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllCustomers } from '../../core/use_cases/GetAllCustomers';
import { Customer } from '../../core/entities/Customer';

class CustomersGateway {
  protected static instance: CustomersGateway;
  protected getAllCustomers: GetAllCustomers;

  public static getInstance(client: ApolloClient<any>): CustomersGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new CustomersGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const customerProvider = new CustomerGraphQLProvider(client);
    this.getAllCustomers = new GetAllCustomers(customerProvider);
    this.findAll = this.findAll.bind(this);
    CustomersGateway.instance = this;
  }

  async findAll(): Promise<Customer[]> {
    return await this.getAllCustomers.execute();
  }
}

export function useCustomersGateway() {
  const client = useApolloClient();
  const customersGateway = CustomersGateway.getInstance(client);
  return {
    findAll: customersGateway.findAll,
  };
}
