/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import { Checkout } from './NewCheckout';
// import { Checkout } from './Checkout';
import {
  useOrdersGateway,
  usePaymentsGateway,
  ShippingMethod,
  useShippingMethodsGateway,
  useCountriesGateway,
  Country,
} from '@kiway/ecommerce-react';
import {
  Order,
  Payment,
  PaymentMethod,
} from '@kiway/ecommerce-react-compatible';
import { Address } from '@kiway/shared/features/authentication-react-compatible';
import { useSnackbar } from '@kiway/shared/utils/snackbar';
import { useTranslation } from '@kiway/shared/utils/translation';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@kiway/routing-builder';
import { Redirect } from 'react-router-dom';

export interface CheckoutControllerProps {
  id?: string;
  history: RouteComponentProps['history'];
}

const steps = ['address', 'deliveryPayment', 'confirmation'];

export function CheckoutController(props: CheckoutControllerProps) {
  const {
    getOrderPublicCheckout,
    getOrderStripeCheckoutSession,
    getOrderSystempayCheckoutSession,
    changeShippingMethod,
    changeShippingAddress,
  } = useOrdersGateway();
  const { findActive: findActiveShippingMethods } = useShippingMethodsGateway();
  const {
    changeOrderPaymentMethod,
    payWithDeferredMethod,
    save: savePayment,
  } = usePaymentsGateway();
  const { addErrorSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryParams = useQuery();
  const queryStep = queryParams.get('step') ?? steps[0];
  const stepIndex = steps.findIndex((item) => item === queryStep);
  const [activeStep, setActiveStep] = React.useState<number>(
    stepIndex >= 0 ? stepIndex : 0
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  const [order, setOrder] = React.useState<Order[]>(null);
  React.useEffect(() => {
    getOrderPublicCheckout(props.id).then((result) => {
      setOrder([result]);
      if (
        !result?.getId() &&
        !['cancel', 'success'].includes(queryParams?.get('redirection'))
      ) {
        window.location.href = '/';
      }
    });
  }, []);

  const [shippingMethods, setShippingMethods] = React.useState<
    ShippingMethod[]
  >(null);
  React.useEffect(() => {
    findActiveShippingMethods().then((shippingMethods) =>
      setShippingMethods(shippingMethods)
    );
    return () => {
      setShippingMethods([]);
    };
  }, []);

  const { findAll: findAllCountries } = useCountriesGateway();
  const [countries, setCountries] = React.useState<Country[]>([]);
  React.useEffect(() => {
    findAllCountries().then((countries) => setCountries(countries));
    return () => {
      setCountries([]);
    };
  }, []);

  const handleChangeStateOrder = (order: Order) => {
    setOrder([order]);
  };
  const handleChangePaymentMethod = (paymentMethod: PaymentMethod) => {
    setLoading(true);
    changeOrderPaymentMethod(order?.[0]?.getId(), paymentMethod)
      .then((payment) => {
        handleChangeStateOrder(order?.[0]?.setPayment(payment));
      })
      .finally(() => setLoading(false));
  };
  const handleChangeShippingAddress = (address: any) => {
    const shippingAddress = new Address(address);
    handleChangeStateOrder(order[0]?.setShippingAddress(shippingAddress));
  };
  const handleSaveShippingAddress = () => {
    changeShippingAddress(
      order?.[0]?.getId(),
      order?.[0]?.getShippingAddress()?.toJSON(true)
    )?.then((result) => {
      if (result && result.getId()) {
        setOrder([new Order(result.toJSON())]);
        handleNext();
      } else {
        addErrorSnackbar(t('pharmaco:checkout.errorSnackbarAddress'));
      }
    });
  };

  const [payLoading, setPayLoading] = React.useState<boolean>(false);
  const handlePayClick = () => {
    setPayLoading(true);
    if (order?.[0]?.getPaymentMethod() === 'card') {
      if (process.env.NX_PAYMENT_MODE === 'stripe') {
        getOrderStripeCheckoutSession(
          props.id,
          `${window.location.origin}${window.location.pathname}?step=confirmation&redirection=success`,
          `${window.location.origin}${window.location.pathname}?step=deliveryPayment&redirection=cancel`
        ).then((result) => {
          if (result) {
            setPayLoading(false);
            window.location.href = result;
          }
        });
      } else if (process.env.NX_PAYMENT_MODE === 'systempay') {
        getOrderSystempayCheckoutSession(
          props.id,
          `${window.location.origin}${window.location.pathname}?step=confirmation&redirection=success`,
          `${window.location.origin}${window.location.pathname}?step=deliveryPayment&redirection=cancel`,
          `${window.location.origin}${window.location.pathname}?step=deliveryPayment&redirection=refused`,
          `${window.location.origin}${window.location.pathname}?step=deliveryPayment&redirection=error`
        ).then((result) => {
          if (result) {
            const form = document.createElement('form');
            form.setAttribute('method', 'post');
            form.setAttribute(
              'action',
              process.env.NX_SYSTEMPAY_PAYMENT_URL ??
                'https://paiement.systempay.fr/vads-payment/'
            );
            Object.entries(result).forEach(([key, value]: [string, string]) => {
              const input = document.createElement('input');
              input.setAttribute('type', 'hidden');
              input.setAttribute('name', key);
              input.setAttribute('value', value);
              form.appendChild(input);
            });
            document.body.appendChild(form);
            form.submit();
          }
        });
      } else {
        setPayLoading(false);
      }
    } else if (
      ['check', 'bankTransfer'].includes(order?.[0]?.getPaymentMethod())
    ) {
      payWithDeferredMethod(
        order?.[0]?.getId(),
        order?.[0]?.getPayment()?.getCustom()
      ).then((result) => {
        if (result) {
          handleNext();
        } else {
          addErrorSnackbar(t('pharmaco:checkout.errorDeferredPayment'));
        }
      });
    }
  };

  const handleChangeCheckInfo = (name: string) => (e: any) => {
    const payment = order?.[0]?.getPayment();
    handleChangeStateOrder(
      order?.[0]?.setPayment(payment?.setCheckInfo(name, e.target.value))
    );
  };

  const handleChangeBankInfo = (name: string) => (e: any) => {
    const payment = order?.[0]
      ?.getPayment()
      ?.setBankTransferInfo(name, e.target.value);
    handleChangeStateOrder(order?.[0]?.setPayment(payment));
    handleSavePayment(payment);
  };

  const handleBankTransferProofUploaded = (files: any[]) => {
    if (files?.length > 0) {
      const proof = files?.[0];
      handleChangeBankInfo('proof')({ target: { value: proof } });
    }
  };

  const handleNext = () => {
    const next = activeStep === steps.length - 1 ? activeStep : activeStep + 1;
    setActiveStep(next);
    props.history.push(`?step=${steps[next]}`);
  };

  const isPayButtonDisabled = (): boolean => {
    if (order?.[0]?.getPaymentMethod() === 'card') {
      return false;
    } else if (order?.[0]?.getPaymentMethod() === 'check') {
      return (
        !order?.[0]?.getPayment()?.getCustom()?.check?.firstName ||
        !order?.[0]?.getPayment()?.getCustom()?.check?.lastName ||
        !order?.[0]?.getPayment()?.getCustom()?.check?.address ||
        !order?.[0]?.getPayment()?.getCustom()?.check?.checkNumber ||
        !order?.[0]?.getPayment()?.getCustom()?.check?.bank
      );
    } else if (order?.[0]?.getPaymentMethod() === 'bankTransfer') {
      return !order?.[0]?.getPayment()?.getCustom()?.bankTransfer?.proof;
    }
    return true;
  };

  const handleSavePayment = (payment?: Payment) => {
    setLoading(true);
    if (payment && payment instanceof Payment) {
      savePayment(payment)
        ?.then((payment) => {
          handleChangeStateOrder(order?.[0]?.setPayment(payment));
        })
        .finally(() => setLoading(false));
    } else {
      savePayment(order?.[0]?.getPayment())
        ?.then((payment) => {
          handleChangeStateOrder(order?.[0]?.setPayment(payment));
        })
        .finally(() => setLoading(false));
    }
  };

  const handleBankTransferProofDeleted = (isDeleted?: boolean) => {
    if (isDeleted) {
      handleChangeBankInfo('proof')({ target: { value: null } });
    }
  };

  return (
    <Checkout
      activeStep={activeStep}
      availableCountries={countries?.filter(
        Country.filterByAvailabilityInShippingMethods(shippingMethods)
      )}
      availableShippingMethods={
        order?.[0]
          ? shippingMethods?.filter(order?.[0]?.getShippingMethodFilter())
          : []
      }
      handleBankTransferProofDeleted={handleBankTransferProofDeleted}
      handleBankTransferProofUploaded={handleBankTransferProofUploaded}
      handleChangeBankInfo={handleChangeBankInfo}
      handleChangeCheckInfo={handleChangeCheckInfo}
      handleChangePaymentMethod={handleChangePaymentMethod}
      handleChangeShippingAddress={handleChangeShippingAddress}
      handleChangeShippingMethod={(id) => {
        if (order?.[0]) {
          setLoading(true);
          return changeShippingMethod(order?.[0]?.getId(), id)
            .then((newOrder) => {
              if (newOrder) {
                setOrder([new Order(newOrder?.toJSON())]);
              }
            })
            .finally(() => setLoading(false));
        }
        return null;
      }}
      handlePayClick={handlePayClick}
      handleSavePayment={handleSavePayment}
      handleSaveShippingAddress={handleSaveShippingAddress}
      isPayButtonDisabled={isPayButtonDisabled}
      order={order?.[0]}
      payLoading={payLoading}
      steps={steps}
      loading={loading}
      isRedirection={['cancel', 'success'].includes(
        queryParams?.get('redirection')
      )}
    />
  );
}
