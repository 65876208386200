import * as React from 'react';
import { useCustomersGateway } from '../../../entrypoints/react/useCustomersGateway';
import { ListCustomers } from './ListCustomers';

export function ListCustomersController() {
  const [loading, setLoading] = React.useState(false);
  const [customers, setCustomers] = React.useState([]);
  const { findAll } = useCustomersGateway();

  React.useEffect(() => {
    setLoading(true);
    findAll()
      .then((customers) => {
        setCustomers(customers);
      })
      .finally(() => setLoading(false));
    return () => {
      setCustomers([]);
    };
  }, []);

  return (
    <ListCustomers loading={loading} customers={customers} />
  );
}
