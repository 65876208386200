export default {
  betaSub: {
    joinUs: 'Rejoignez la communauté et accédez à Kiway',
    checkbox: {
      beta:
        "En cochant cette case, je m'inscris au programme de co-création de Kiway",
    },
    button: {
      confirmSignUpText: "Je m'inscris",
    },
    snackbar: {
      success:
        "Bienvenue parmi nous ! Un mail vous sera envoyé sous peu pour vous donner plus d'informations",
      errorDuplicate: 'Vous êtes déjà parmi nos contacts',
      errorCaptcha:
        "La vérification de sécurité a échoué. Merci de recharger la page et d'essayer à nouveau.",
      error: "Une erreur s'est produite, merci de recommencer",
      submitDisabled:
        'Vous devez saisir un mail et cocher la case pour pouvoir vous inscrire',
    },
  },
  headline: 'La plateforme spécialisée en Médecine Traditionnelle Chinoise',
  slogan: 'La plateforme pour et par les praticiens',
  espacePro: 'Espace Pro',
  espacePatient: 'Espace Patient',
  rows: {
    simplifyActivity: {
      title: 'Simplifiez votre activité',
      cards: {
        onlineAgenda: {
          title: 'Agenda en ligne',
          text:
            'Une gestion personnalisée de l’agenda pour un gain de temps. Les rendez-vous sont pris en ligne 24h/24h et 7j/7j sans aucune intervention de votre part. Vous personnalisez vos semaines et choisissez vos créneaux horaires disponibles.',
        },
        personalWebsite: {
          title: 'Site praticien',
          text:
            'Un mini-site personnalisé pour améliorer sa présence en ligne. Votre espace permet d’afficher les informations importantes pour votre pratique comme vos lieux de consultation, vos formations, vos tarifs, vos spécialités ainsi que votre agenda.',
        },
        smsReminder: {
          title: 'Rappel SMS',
          text:
            'Un rappel des rendez-vous pour réduire le taux de non-présentation de 75%. Le rappel est automatisé 24h avant le rendez-vous pris depuis l’agenda en ligne ou par vos soins dans l’espace praticien. Il rappelle votre nom, l’horaire et invite à vous contacter en cas d’annulation.',
        },
      },
    },
    improveFollowUp: {
      title: 'Améliorez le suivi patient',
      cards: {
        userRecords: {
          title: 'Dossiers usagers',
          text:
            'Un système sécurisé de gestion pour archiver les données de séances et accéder facilement aux dossiers des usagers. Vous allez enfin pouvoir dire adieu aux prises de notes sur feuilles volantes ! En quelques clics vous accédez à l’historique des séances de chaque usager.',
        },
        popupAlert: {
          title: 'Alerte contextuelle',
          text:
            'Des alertes contextuelles pour fidéliser vos usagers et personnaliser votre relation. Vous automatisez la proposition de rendez-vous aux moments opportuns comme à chaque intersaison, avant une saison ou une date précise. Vous créez simplement une alerte depuis le dossier usager.',
        },
        sessionNotes: {
          title: 'Notes de séance',
          text:
            'Une interface de prise de notes pour organiser et éditer vos informations. Vous créez un suivi sécurisé de vos usagers avec un haut niveau de personnalisation pour améliorer l’efficacité de vos traitements.',
        },
      },
    },
    teamAtYourService: {
      title: 'Une équipe à votre service',
      teamMembers: {
        guillaume: {
          name: 'Guillaume Ricciuti',
          position: 'CEO',
          description:
            "Praticien en énergétique traditionnelle chinoise et développeur d'applications, je supervise la conception de la plateforme afin qu'elle réponde à vos besoins.",
        },
        julien: {
          name: 'Julien Granata',
          position: 'Managing Director',
          description:
            'Docteur en sciences de gestion et instructeur en méditation, je dirige la publication des contenus et conduit les partenariats stratégiques.',
        },
        clement: {
          name: 'Clément Correia',
          position: 'CTO',
          description:
            "Ingénieur en informatique, je m'occupe de coder et de sécuriser les outils auxquels vous rêvez pour optimiser votre pratique.",
        },
        leo: {
          name: 'Léo Temsamani',
          position: 'Head of Communication',
          description:
            'Spécialiste des réseaux sociaux, je conçois les campagnes de communication et participe à la création de la communauté de praticiens.',
        },
      },
    },
    readUs: {
      title: 'Lisez-nous',
      phrase:
        'Venez découvrir nos articles sur les thèmes du bien-être et des médecines traditionnelles',
      buttonText: 'Lire les articles',
    },
    contactUs: {
      title: 'Contactez-nous',
      snackbar: {
        success:
          'Merci de votre message, nous vous contacterons dans les plus brefs délais',
        error: "Une erreur s'est produite, merci de recommencer",
      },
    },
  },
  footer: {
    followUs: 'Nous Suivre',
  },
};
