import * as React from 'react';
import { useWholesalersGateway } from '../../../entrypoints/react/useWholesalersGateway';
import { ListWholesalers } from './ListWholesalers';

export function ListWholesalersController() {
  const [loading, setLoading] = React.useState(false);
  const [wholesalers, setWholesalers] = React.useState([]);
  const { findAll } = useWholesalersGateway();

  React.useEffect(() => {
    setLoading(true);
    findAll()
      .then((wholesalers) => {
        setWholesalers(wholesalers);
      })
      .finally(() => setLoading(false));
    return () => {
      setWholesalers([]);
    };
  }, []);

  return (
    <ListWholesalers loading={loading} wholesalers={wholesalers} />
  );
}
