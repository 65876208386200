import React, { useContext, useReducer, createContext } from 'react'
import { cleanTypenameFrom } from '../../graphql/utils'

const initServicesState = () => {
  return {
    // Card data to send while generating card token id
    cardData: {
      name: '',
      address_country: '',
    },
    stripeTokenId: null,
    // List of services to get from backend
    servicesList: [],
    // Services selected by the user
    servicesSelected: [],
    // Total services selected amount
    totalAmount: 0,
    activeStep: 0,
    // Terms of service checkbox state
    tosChecked: false,
    isFirstTime: true,
  }
  // return {
  //   // Card data to send while generating card token id
  //   cardData: {
  //     name: '',
  //     address_country: '',
  //   },
  //   stripeTokenId: null,
  //   // List of services to get from backend
  //   servicesList: [
  //     {
  //       id: '1',
  //       shortname: 'sms',
  //       metered: true,
  //       serviceName: 'Rappel SMS',
  //       fixedFees: 1,
  //       unitPrice: 2.4,
  //       description: 'Système de rappel par SMS, envoyé 24h avant le RDV',
  //       priceTooltip:
  //         '(4.5 crédits / sms en France soit 0.11€ / sms + 1€ / mois)',
  //       priceLabel: '0.024€/crédit + 1€/mois',
  //     },
  //   ],
  //   // Services selected by the user
  //   servicesSelected: [],
  //   // Total services selected amount
  //   totalAmount: 0,
  //   activeStep: 0,
  //   // Terms of service checkbox state
  //   tosChecked: false,
  //   isFirstTime: true,
  // }
}

// Service data for frontend:

// service = {
//   id: '',
//   shortname: '',
//   metered: false,
//   serviceName: '',
//   fixedFees: 0,
//   unitPrice: '',
//   description: '',
//   priceTooltip: '',
//   priceLabel: '',
// }

// Service db model:

// shortname: String
// stripePriceId: String
// tax_rates: [String]
// subscriptionItemId: String
// metered: Boolean
// quantity: Number
// fixedFees: Number
// serviceName: String
// unitPrice: Number
// currency: String
// description: String
// priceTooltip: String
// priceLabel: String

/**
 * Create and initialize a Context object to maintain
 *  services states accross components tree
 */
const ServicesOnDemandContext = createContext(initServicesState())

const isServiceIn = (services, service) => {
  for (const s of services) {
    if (s.shortname === service.shortname) {
      return true
    }
  }
  return false
}

const isServiceIncludesTVA = (service) => {
  if (service.tax_rates?.includes(process.env['NX_STRIPE_TVA_INCLUSE'])) {
    return true
  }
  return false
}

const servicesReducer = (state, action) => {
  switch (action.type) {
    case 'GET_SERVICES':
      return {
        ...state,
        servicesList: action.payload.map((service) =>
          cleanTypenameFrom(service),
        ),
      }
    case 'CANCEL_CHANGES':
      return {
        ...state,
        servicesSelected: state.servicesSelected.filter(({ subscriptionItemId }) => subscriptionItemId !== undefined)
      }
    case 'TOGGLE_SERVICE':
      const isAlreadySelected = isServiceIn(
        state.servicesSelected,
        action.payload,
      )

      let selected, amount
      if (isAlreadySelected) {
        selected = state.servicesSelected.filter(({ shortname }) => shortname !== action.payload?.shortname)
        if (!action.payload.metered) {
          amount = state.totalAmount - action.payload.unitPrice / 100
        }
      } else {
        selected = [...state.servicesSelected, action.payload]
        if (!action.payload.metered) {
          amount = state.totalAmount + action.payload.unitPrice / 100
        }
      }

      return {
        ...state,
        servicesSelected: [...selected],
        totalAmount: !action.payload.metered ? amount : state.totalAmount,
      }
    case 'SET_STRIPE_TOKEN_ID':
      return {
        ...state,
        stripeTokenId: action.payload,
      }
    case 'TOGGLE_TOS':
      return {
        ...state,
        tosChecked: !state.tosChecked,
      }
    case 'SET_ACTIVE_STEP':
      return {
        ...state,
        activeStep: action.payload,
      }
    case 'SET_NAME':
      return {
        ...state,
        cardData: {
          ...state.cardData,
          name: action.payload,
        },
      }
    case 'SET_COUNTRY':
      return {
        ...state,
        cardData: {
          ...state.cardData,
          address_country: action.payload,
        },
      }
    default:
      throw new Error('Unhandled action type')
  }
}

function ServicesOnDemandContextProvider(props) {
  const { children } = props

  /**
   * Create and initialize our services on demand states management system
   */
  const initialServicesState = useContext(ServicesOnDemandContext)
  const [state, dispatch] = useReducer(servicesReducer, initialServicesState)

  const computeAmount = () => {
    const result = {
      totalMetered: 0,
      meteredUsagePrices: [],
      totalLicensed: 0,
      metered: false,
      tva20Included: {
        licensed: 0,
        metered: {
          fixed: 0,
          usage: 0,
        },
      },
    }
    state.servicesSelected.map(
      ({ serviceName, unitPrice, fixedFees, metered, ...restService }) => {
        if (!unitPrice && !fixedFees) {
          return
        }
        if (metered) {
          result.metered = true
          result.totalMetered = result.totalMetered + parseFloat(fixedFees)
          result.meteredUsagePrices = [
            ...result.meteredUsagePrices,
            {
              serviceName,
              unitPrice,
            },
          ]
          result.tva20Included.metered.fixed =
            result.tva20Included.metered.fixed + 0.2 * fixedFees
          result.tva20Included.metered.usage =
            result.tva20Included.metered.usage + 0.2 * unitPrice
        } else {
          result.totalLicensed = result.totalLicensed + parseFloat(unitPrice)
          result.tva20Included.licensed =
            result.tva20Included.licensed + 0.2 * unitPrice
        }
      },
    )
    return result
  }

  return (
    <ServicesOnDemandContext.Provider value={{ state, dispatch, computeAmount, isServiceIncludesTVA, isServiceIn }}>
      {children}
    </ServicesOnDemandContext.Provider>
  )
}

export { ServicesOnDemandContextProvider, ServicesOnDemandContext }
