import * as React from 'react';
import { Button, Grid, Typography, Tooltip, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { InvoiceInfos } from './InvoiceInfos';
import { CartInfos } from './CartInfos/CartInfos';
import { OrderStepperController } from '../OrderStepper';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Address, AddressFormController } from '@kiway/shared/ui';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AccordionItem, Country, ShippingMethod } from '@kiway/ecommerce-react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Order } from '@kiway/ecommerce-react-compatible';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Address as UserAddress } from '@kiway/shared/features/authentication-react-compatible';
import { useTranslation } from '@kiway/shared/utils/translation';
import { PrescriptionResume } from '../PrescriptionDetails/PrescriptionResume';
import { PharmacoOrder } from '../../../core/entities/PharmacoOrder';
import { UserAddressCard } from './UserAddressCard';

interface ShippingDetailsProps {
  availableCountries?: Country[];
  availableShippingMethods: ShippingMethod[];
  displayInvoicingAddressForm: boolean;
  displayShippingAddressForm: boolean;
  handleChangeInvoicingAddress: (address: Address) => any;
  handleChangeLoyaltyProgramOption: (value: string) => void;
  loyaltyPointsBalance: number;
  handleChangeShippingAddress: (address: Address) => any;
  handleChangeShippingMethod: (shippingMethod: ShippingMethod) => any;
  handleClickSameAddress: (value: boolean) => any;
  handleNext: () => void;
  handlePrev: () => void;
  handleSave: () => void;
  handleSelectInvoicingAddress: (address: Address) => any;
  handleSelectShippingAddress: (address: Address) => any;
  initInvoicingAddress: UserAddress;
  initShippingAddress: UserAddress;
  invoicingAddress: Address;
  panelOpen: string;
  order: PharmacoOrder;
  realInvoicingAddress: UserAddress;
  realOrder: Order;
  realShippingAddress: UserAddress;
  selectedInvoicingAddressId: string;
  selectedShippingAddressId: string;
  setDisplayInvoicingAddressForm: (value: boolean) => any;
  setDisplayShippingAddressForm: (value: boolean) => any;
  setInitInvoicingAddress: (address: UserAddress) => void;
  setInitShippingAddress: (address: UserAddress) => void;
  setPanelOpen: (panel: string) => () => void;
  shippingAddress: Address;
  userAddresses: Address[];
}

export function ShippingDetails(props: ShippingDetailsProps) {
  const { currentLanguage, t } = useTranslation();

  return (
    <Grid container spacing={2} justifyContent="center" sx={{ pb: 12 }}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          {t(`pharmaco:prescription.order.label`)} n°
          {props.order?.getOrder()?.getOrderNumber()}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <OrderStepperController
          currentStep="shipping"
          orderId={props.order?.getOrder()?.getId()}
        />
      </Grid>

      <Grid item xs={12}>
        <AccordionItem
          handleChange={props.setPanelOpen('panel1')}
          expanded={props.panelOpen === 'panel1'}
          label={t('pharmaco:orderDetails.order.deliveryAddress')}
          secondaryLabel={
            props.realShippingAddress?.validateAddress()
              ? props.realShippingAddress.getFormattedAddress(
                  currentLanguage?.code
                )
              : t('pharmaco:orderDetails.order.validAddress')
          }
          hideSecondaryLabelWhenExpanded={true}
        >
          {!props.displayShippingAddressForm ? (
            <Grid container spacing={2}>
              {props.userAddresses?.map((address) => {
                return (
                  <UserAddressCard
                    key={address.id}
                    address={address}
                    selectedAddressId={props.selectedShippingAddressId}
                    handleSelectAddress={props.handleSelectShippingAddress}
                  />
                );
              })}
              <Grid item>
                <Box
                  onClick={() => {
                    props.setDisplayShippingAddressForm(true);
                    props.handleChangeShippingAddress({
                      ...props.shippingAddress,
                      id: undefined,
                    });
                  }}
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip title={t('pharmaco:orderDetails.order.newAddress')}>
                    <Button
                      variant="text"
                      style={{
                        height: '100%',
                        color: 'grey',
                      }}
                    >
                      <AddIcon />
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <AddressFormController
                  address={props.shippingAddress}
                  availableCountries={
                    props.availableCountries?.map((country) =>
                      country.toJSON(currentLanguage?.code)
                    ) ?? []
                  }
                  setAddress={(address) => {
                    props.handleChangeShippingAddress(address);
                    if (
                      address &&
                      props.order
                        ?.getOrder()
                        ?.isInvoicingAddressSameAsShippingAddress()
                    ) {
                      props.handleChangeInvoicingAddress(address);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} textAlign="right">
                {props.userAddresses?.length > 0 && (
                  <Button
                    onClick={() => {
                      props.setDisplayShippingAddressForm(false);
                    }}
                  >
                    {t('pharmaco:cancel')}
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={() => {
                    props.handleSave();
                    if (
                      props.order
                        ?.getOrder()
                        ?.isInvoicingAddressSameAsShippingAddress()
                    ) {
                      props.setPanelOpen('panel3')();
                    } else {
                      props.setPanelOpen('panel2')();
                    }
                  }}
                  disabled={
                    props.order
                      ?.getOrder()
                      ?.getShippingAddress()
                      .equals(props.initShippingAddress) ||
                    !new UserAddress(props.shippingAddress)?.validateAddress()
                  }
                >
                  {t('pharmaco:validate')}
                </Button>
              </Grid>
            </Grid>
          )}
        </AccordionItem>

        <AccordionItem
          handleChange={props.setPanelOpen('panel2')}
          expanded={props.panelOpen === 'panel2'}
          label={t('pharmaco:orderDetails.order.paymentAddress')}
          secondaryLabel={
            props.realInvoicingAddress?.validateAddress()
              ? props.realInvoicingAddress.getFormattedAddress(
                  currentLanguage?.code
                )
              : t('pharmaco:orderDetails.order.validAddress')
          }
          hideSecondaryLabelWhenExpanded={true}
        >
          <InvoiceInfos
            isSameAddress={props.order
              ?.getOrder()
              ?.isInvoicingAddressSameAsShippingAddress()}
            setIsSameAddress={props.handleClickSameAddress}
          >
            {!props.order
              ?.getOrder()
              ?.isInvoicingAddressSameAsShippingAddress() &&
              (!props.displayInvoicingAddressForm ? (
                <Grid container spacing={2}>
                  {props.userAddresses?.map((address) => {
                    return (
                      <UserAddressCard
                        key={address.id}
                        address={address}
                        selectedAddressId={props.selectedInvoicingAddressId}
                        handleSelectAddress={props.handleSelectInvoicingAddress}
                      />
                    );
                  })}
                  <Grid item>
                    <Box
                      onClick={() => props.setDisplayInvoicingAddressForm(true)}
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Tooltip
                        title={t('pharmaco:orderDetails.order.newAddress')}
                      >
                        <Button
                          variant="text"
                          style={{
                            height: '100%',
                            color: 'grey',
                          }}
                        >
                          <AddIcon />
                        </Button>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <AddressFormController
                      address={props.order
                        ?.getOrder()
                        ?.getInvoicingAddress()
                        ?.toJSON()}
                      readonly={props.order
                        ?.getOrder()
                        ?.isInvoicingAddressSameAsShippingAddress()}
                      setAddress={(address) => {
                        props.handleChangeInvoicingAddress(address);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} textAlign="right">
                    {props.userAddresses?.length > 0 && (
                      <Button
                        onClick={() => {
                          props.setDisplayInvoicingAddressForm(false);
                        }}
                      >
                        {t('pharmaco:cancel')}
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      onClick={() => {
                        props.handleSave();
                        props.setPanelOpen('panel3')();
                      }}
                      disabled={props.order
                        ?.getOrder()
                        ?.getInvoicingAddress()
                        .equals(props.initInvoicingAddress)}
                    >
                      {t('pharmaco:validate')}
                    </Button>
                  </Grid>
                </Grid>
              ))}
          </InvoiceInfos>
        </AccordionItem>
        <AccordionItem
          handleChange={props.setPanelOpen('panel3')}
          expanded={props.panelOpen === 'panel3'}
          label={t('pharmaco:orderDetails.shipment.shipmentMethod')}
          secondaryLabel={`${
            props.realOrder?.getShippingMethod()?.getName()?.[
              currentLanguage?.code
            ] ?? t('pharmaco:orderDetails.shipment.selectMethod')
          }${
            props.realOrder?.getShippingMethod()
              ? ` (${
                  props.realOrder
                    ?.getShippingMethod()
                    ?.getPrice(
                      props.realOrder?.getShippingAddress()?.getCountryCode(),
                      props.realOrder?.getTotalWeight()
                    )
                    ?.toString() ?? '0.00 €'
                })`
              : ''
          }`}
          hideSecondaryLabelWhenExpanded={true}
        >
          <CartInfos
            availableShippingMethods={props.availableShippingMethods}
            handleChangeShippingMethod={(id: string) => {
              const method = props.availableShippingMethods?.find(
                (shippingMethod) => shippingMethod.getId() === id
              );
              if (method) {
                props.handleChangeShippingMethod(method);
              }
            }}
            order={props.realOrder}
          />
        </AccordionItem>
      </Grid>
      <Grid item xs={12}>
        <PrescriptionResume
          currentStep="shipping"
          order={props.order}
          realOrder={props.realOrder}
          handleClickNext={props.handleNext}
          handleClickPrev={props.handlePrev}
          canDoNext={() => props.order?.getOrder()?.canOpenOrder()}
          reasonCantValidateOrder={props.order?.reasonCantValidateOrder()}
          handleChangeLoyaltyProgramOption={
            props.handleChangeLoyaltyProgramOption
          }
          loyaltyPointsBalance={props.loyaltyPointsBalance}
        />
      </Grid>
    </Grid>
  );
}
