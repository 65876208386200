import { Carrier, ICarrierAttributes } from './Carrier';
import { Price } from './Order';
import {
  ShippingMethodPrice,
  IShippingMethodPriceAttributes,
} from './ShippingMethodPrice';
import {
  flattenTranslatableAttributeToJson,
  TranslatableAttribute,
  KiwayLanguagesType,
} from '@kiway/shared/react-types';
import { CountryZone, ICountryZoneAttributes } from './CountryZone';

export interface IShippingMethodAttributes {
  name: TranslatableAttribute;
  carrier: Carrier | ICarrierAttributes | string;
  active: boolean;
  displayOrder: number;
  productCode: string;
  prices: Array<ShippingMethodPrice | IShippingMethodPriceAttributes | string>;
  countryZone: CountryZone | ICountryZoneAttributes | string;
}

export class ShippingMethod {
  protected id: string;
  protected name: TranslatableAttribute;
  protected carrier: Carrier;
  protected active: boolean;
  protected displayOrder: number;
  protected productCode: string;
  protected prices: ShippingMethodPrice[];
  protected countryZone: CountryZone;

  static sortByDisplayOrder(
    order: 'ASC' | 'DESC' = 'ASC'
  ): (a: ShippingMethod, b: ShippingMethod) => number {
    const defaultDisplayOrder = 10000;
    return (a: ShippingMethod, b: ShippingMethod) => {
      if (
        (a.getDisplayOrder() ?? defaultDisplayOrder) <
        (b.getDisplayOrder() ?? defaultDisplayOrder)
      ) {
        return order === 'ASC' ? -1 : 1;
      }
      if (
        (a.getDisplayOrder() ?? defaultDisplayOrder) >
        (b.getDisplayOrder() ?? defaultDisplayOrder)
      ) {
        return order === 'ASC' ? 1 : -1;
      }
      return 0;
    };
  }

  public constructor(
    obj: Partial<IShippingMethodAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    this.name = obj?.name;
    if (obj?.carrier instanceof Carrier) {
      this.carrier = obj?.carrier;
    } else if (typeof obj?.carrier === 'string') {
      this.carrier = new Carrier({ id: obj?.carrier });
    } else {
      this.carrier = new Carrier(obj?.carrier);
    }
    this.active = obj?.active;
    this.displayOrder = obj?.displayOrder;
    this.productCode = obj?.productCode;
    if (obj?.prices) {
      this.prices = obj?.prices?.map((price) => {
        if (price instanceof ShippingMethodPrice) {
          return price;
        } else if (typeof price === 'string') {
          return new ShippingMethodPrice({ id: price });
        } else {
          return new ShippingMethodPrice(price);
        }
      });
    }
    if (obj?.countryZone) {
      if (obj?.countryZone instanceof CountryZone) {
        this.countryZone = obj?.countryZone;
      } else if (typeof obj?.countryZone === 'string') {
        this.countryZone = new CountryZone({ id: obj?.countryZone });
      } else {
        this.countryZone = new CountryZone(obj?.countryZone);
      }
    }
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): ShippingMethod {
    this.id = id;
    return this;
  }

  public getName(): TranslatableAttribute {
    return this.name;
  }

  public getCarrier(): Carrier {
    return this.carrier;
  }

  public setCarrier(carrier: Carrier): ShippingMethod {
    this.carrier = carrier;
    return this;
  }

  public getPrices(): ShippingMethodPrice[] {
    return this.prices;
  }

  public setPrices(prices: ShippingMethodPrice[]): ShippingMethod {
    this.prices = prices;
    return this;
  }

  public getPrice(countryCode: string, weight: number): Price {
    if (
      !countryCode ||
      countryCode === '' ||
      !weight ||
      weight <= 0 ||
      !this.prices
    ) {
      return null;
    }
    const price = this.prices?.find((price) => {
      const isGoodCountryCode = this.getCountryZone()
        ?.getCountries()
        ?.map((country) => country.getCode().toLowerCase())
        ?.includes(countryCode.toLowerCase());
      const isRightRangeWeight =
        price.getMinWeight() < weight && price.getMaxWeight() >= weight;
      return isGoodCountryCode && isRightRangeWeight;
    });
    return price?.getPrice();
  }

  public isActive(): boolean {
    return this.active;
  }

  public setActive(active: boolean): ShippingMethod {
    this.active = active;
    return this;
  }

  public getDisplayOrder(): number {
    return this.displayOrder;
  }

  public setDisplayOrder(displayOrder: number): ShippingMethod {
    this.displayOrder = displayOrder;
    return this;
  }

  public getProductCode(): string {
    return this.productCode;
  }

  public setProductCode(productCode: string): ShippingMethod {
    this.productCode = productCode;
    return this;
  }

  public getCountryZone(): CountryZone {
    return this.countryZone;
  }

  public setCountryZone(countryZone: CountryZone): ShippingMethod {
    this.countryZone = countryZone;
    return this;
  }

  public toJSON(): any {
    return {
      id: this.getId(),
      name: this.getName(),
      carrier: this.getCarrier().toJSON(),
      active: this.isActive(),
      displayOrder: this.getDisplayOrder(),
      productCode: this.getProductCode(),
      prices: this.getPrices()?.map((price) => price.toJSON()),
      countryZone: this.getCountryZone()?.toJSON(),
    };
  }

  public toDatatableRow(): any {
    return {
      _id: this.getId(),
      active: this.isActive(),
      carrier: this.getCarrier().getId(),
      displayOrder: this.getDisplayOrder(),
      productCode: this.getProductCode(),
      ...flattenTranslatableAttributeToJson(this.getName(), 'name'),
      pricesLength: this.getPrices()?.length,
      prices: this.getPrices(),
      countryZone: this.getCountryZone()?.getId(),
    };
  }

  public toDropdownValue(lng: KiwayLanguagesType): any {
    return {
      label: this.getName()?.[lng],
      value: this.getId(),
    };
  }
}
