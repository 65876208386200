import * as React from 'react';
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Table,
  TableContainer,
  Typography,
} from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import config, { links } from '../../../config';
import moment from 'moment';
import { CustomerInfo } from './CustomerInfo';
import { OrderCardInfo } from './OrderCardInfo';
import { OrderDetailsFormula } from './OrderDetailsFormula';
import { Link } from 'react-router-dom';
import { Order } from '@kiway/ecommerce-react-compatible';
import { AccordionItem } from '@kiway/ecommerce-react';
import { MobileSafeArea } from '@kiway/shared/ui';
import { PharmacoOrder } from '../../../core/entities/PharmacoOrder';

export interface OrderDetailsPrepProps {
  order?: Order;
  pharmacoOrder?: PharmacoOrder;
  handleOrderPreparationClick?: () => void;
  handleOrderShipmentClick?: () => void;
}

export function OrderDetailsPrep({
  order,
  pharmacoOrder,
  handleOrderPreparationClick,
  handleOrderShipmentClick,
}: OrderDetailsPrepProps) {
  const { currentLanguage, t } = useTranslation();
  const [expanded, setExpanded] = React.useState<string | false>('patient');

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <MobileSafeArea>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            size={'small'}
            color={'inherit'}
            component={Link}
            to={`/${links.main}/${links.listOrders}`}
          >
            {t('ecommerce:backToList')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography variant="h4" align="center">
                {t(`${config.namespace}:orderDetails.order.label`)} n°
                {order?.getOrderNumber()}
              </Typography>
            </Grid>
            <Grid item>
              <Chip
                label={t(
                  `${config.namespace}:orderDetails.order.status.${
                    order?.getOrderStatusJSON()?.label
                  }`
                ).toUpperCase()}
                color={order?.getOrderStatusJSON()?.color}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              width: '100%',
              backgroundColor: 'lightblue',
              borderRadius: '5px',
              mt: 2,
              mb: 2,
              p: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography fontWeight="bold">
              {t('pharmaco:orderDetails.comment.label')} :
            </Typography>
            <Typography variant="subtitle1" fontStyle="italic" sx={{ ml: 1 }}>
              {order?.getCustom()?.['preparerComment']
                ? order?.getCustom()?.['preparerComment']
                : t('pharmaco:orderDetails.comment.none')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2} alignItems={'stretch'}>
            <Grid item xs={6}>
              <OrderCardInfo
                actions={
                  <>
                    {order?.getPackingStatus() !== 'PREPARED' && (
                      <Button
                        size="small"
                        variant="contained"
                        color="minoritary"
                        onClick={handleOrderPreparationClick}
                        disabled={!order?.canDoPreparation()}
                      >
                        {t(
                          `${config.namespace}:orderDetails.buttons.${
                            order?.getStartPreparationAt()
                              ? 'prepare'
                              : 'startPrepare'
                          }`
                        )}
                      </Button>
                    )}
                  </>
                }
                lines={[
                  {
                    label: t(
                      `${config.namespace}:orderDetails.packing.preparedBy`
                    ),
                    value: order?.getPreparedBy()?.getFullName(),
                    hide: !order?.getPreparedBy(),
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.packing.progress`
                    ),
                    value: `${(
                      order?.getPreparationProgression() * 100
                    ).toFixed(2)} %`,
                    hide: !order?.canDoPreparation(),
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.packing.weight.content`
                    ),
                    value: `${order?.getItemsWeight()?.toFixed(2) ?? '0.00'} g`,
                  },
                ]}
                title={t(`${config.namespace}:orderDetails.packing.label`)}
                status={t(
                  `${config.namespace}:orderDetails.packing.status.${
                    order?.getPackingStatusJSON()?.label
                  }`
                )}
                statusColor={order?.getPackingStatusJSON()?.color}
              />
            </Grid>
            <Grid item xs={6}>
              <OrderCardInfo
                actions={
                  <>
                    {order?.getShipmentStatus() !== 'SHIPPED' && (
                      <Button
                        disabled={!order?.canDoShipment()}
                        onClick={handleOrderShipmentClick}
                        size="small"
                        variant="contained"
                        color="minoritary"
                      >
                        {t(`${config.namespace}:orderDetails.buttons.dispatch`)}
                      </Button>
                    )}
                  </>
                }
                lines={[
                  {
                    label: t(
                      `${config.namespace}:orderDetails.shipment.sentBy`
                    ),
                    value: order?.getSentBy()?.getFullName(),
                    hide: !order?.getSentBy(),
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.shipment.shipmentMethod`
                    ),
                    value: order?.getShippingMethod()?.getName()?.[
                      currentLanguage?.code
                    ],
                    hide: !order?.getShippingMethod(),
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.shipment.shipmentMethodCost`
                    ),
                    value: order
                      ?.getShippingMethod()
                      ?.getPrice(
                        order.getShippingAddress()?.getCountryCode(),
                        order.getTotalWeight()
                      )
                      ?.toString(),
                    hide: !order?.getShippingMethod(),
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.packing.weight.packing`
                    ),
                    value: `${
                      order?.getPackingWeight()?.toFixed(2) ?? '0.00'
                    } g`,
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.packing.weight.total`
                    ),
                    value: `${order?.getTotalWeight()?.toFixed(2) ?? '0.00'} g`,
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.shipment.trackingNumber`
                    ),
                    value: (
                      <a
                        href={`${order
                          ?.getShippingMethod()
                          ?.getCarrier()
                          ?.getTrackingLink(
                            order?.getShippingTrackingNumber()
                          )}`}
                        target="_blank"
                      >
                        {order?.getShippingTrackingNumber()}
                      </a>
                    ),
                    hide: !order?.getShippingTrackingNumber(),
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.shipment.carrierLabel.label`
                    ),
                    value: (
                      <a
                        href={order?.getShippingLabelStoredFile()?.url}
                        target="_blank"
                      >
                        {t(
                          `${config.namespace}:orderDetails.shipment.carrierLabel.displayLabelNewTab`
                        )}
                      </a>
                    ),
                    hide: !order?.getShippingLabelStoredFile()?.url,
                  },
                ]}
                title={t(`${config.namespace}:orderDetails.shipment.label`)}
                status={t(
                  `${config.namespace}:orderDetails.shipment.status.${
                    order?.getShipmentStatusJSON()?.label
                  }`
                )}
                statusColor={order?.getShipmentStatusJSON()?.color}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <AccordionItem
            labelVariant={'h6'}
            expanded={expanded === 'formula'}
            handleChange={
              pharmacoOrder?.getFormulas()?.length > 0
                ? handleChange('formula')
                : undefined
            }
            hideExpandIcon={
              pharmacoOrder?.getFormulas()?.length > 0 ? false : true
            }
            label={t(`${config.namespace}:orderDetails.formula.label`)}
            secondaryLabel={
              pharmacoOrder?.getFormulas()?.length > 0
                ? `${t(
                    `${config.namespace}:orderDetails.formula.formulaCount`,
                    {
                      count: order?.getNbOfGroups(),
                    }
                  )} ${pharmacoOrder
                    ?.getFormulas()
                    ?.map((formula) => formula.getName())
                    .join(', ')}`
                : t(`${config.namespace}:orderDetails.comment.default`)
            }
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={2} style={{ marginBottom: 5 }}>
                  <Grid item xs={12}>
                    <TableContainer component={Paper} elevation={1}>
                      <Table size="small">
                        {pharmacoOrder?.getFormulas()?.map((formula) => (
                          <OrderDetailsFormula
                            key={formula.getGroupBy()}
                            formulaName={formula.getName()}
                            formula={formula}
                            rows={order.getLineItemsByGroup(
                              formula.getGroupBy()
                            )}
                            totalQuantity={order.getTotalQuantityByGroup(
                              formula.getGroupBy()
                            )}
                            totalPrice={order.getGrossTotalPrice(
                              formula.getGroupBy()
                            )}
                            getRoundedItemWeight={(id) =>
                              formula.getRoundedItemQuantity(id)
                            }
                          />
                        ))}
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionItem>
        </Grid>
      </Grid>
    </MobileSafeArea>
  );
}
