import {
  Plant,
  IPlantAttributes,
  PlantMetadataSearch,
  PlantMetadataSearchReturn,
  PlantArrays,
} from '../../core/entities/Plant';
import { PlantProvider } from '../../core/use_cases/PlantProvider';
import { gql, ApolloClient } from '@apollo/client';
import { ProductMetadata } from '../../core/entities/ProductMetadata';
import { PaginationOptions, PaginatedResults } from '@kiway/shared/react-types';

export const plantGqlAttributes = `
  id
  name
  isConcentratedPowder
  isPlant
  published
  custom {
    legacyId
    latinName
    pinYinName
    chineseName
    otherName
    commonName
    concentrationRate
    toxic
    posologyMin
    posologyMax
    referenceStockPlant
    referenceStockPowder
    pictureUrl
    sellAvailable
  }
  variations {
    id
    productVariationType {
      id
      name
      price {
        currency {
          id
          symbol
          code
          format
          position
        }
        centAmount
      }
      variationAttributesValue {
        value
        variationAttribute {
          name
        }
      }
    }
    price {
      currency {
        id
        symbol
        code
        format
        position
      }
      centAmount
    }
    productRef
    available
  }
  metadatas {
    id
    metadataType
    name
  }
`;

export const LIST_PLANTS = gql`
  query listPlants($find: JSON, $page: Int, $perPage: Int) {
    listPlants(find: $find, page: $page, perPage: $perPage) {
      items { 
        ${plantGqlAttributes}
      }
      currentPage
      totalItems
      totalPages
      prevPage
      nextPage
      hasPrevPage
      hasNextPage
    }
  }
`;

export const GET_PLANT = gql`
  query getPlant($plantId: String) {
    getPlant(plantId: $plantId) {
      ${plantGqlAttributes}
    }
  }
`;

export const EDIT_MULTIPLE_PLANTS = gql`
  mutation editManyPlants($input: [PlantInput]) {
    editManyPlants(input: $input) {
      ${plantGqlAttributes}
    }
  }
`;

export const ADD_ITEM_TO_PLANT_ARRAYS = gql`
  mutation addItemToPlantArrays($plantId: String, $itemId: String, $itemType: PlantArraysEnum) {
    addItemToPlantArrays(plantId: $plantId, itemId: $itemId, itemType: $itemType) {
      ${plantGqlAttributes}
    }
  }
`;

export const REMOVE_ITEM_FROM_PLANT_ARRAYS = gql`
  mutation removeItemFromPlantArrays($plantId: String, $itemId: String, $itemType: PlantArraysEnum) {
    removeItemFromPlantArrays(plantId: $plantId, itemId: $itemId, itemType: $itemType) {
      ${plantGqlAttributes}
    }
  }
`;

export const SEARCH_PLANT_METADATA = gql`
  query searchPlantMetadata($search: String!) {
    searchPlantMetadata(search: $search) {
      metadatas {
        id
        name
        metadataType
      }
    }
  }
`;

export type IPlantAttributesData = IPlantAttributes & {
  id: string;
};

export interface ListPlantsData {
  listPlants: PaginatedResults<IPlantAttributesData>;
}

export interface GetPlantData {
  getPlant: IPlantAttributesData;
}

export interface EditManyPlantsData {
  editManyPlants: Array<IPlantAttributesData>;
}

export interface AddItemToPlantArraysData {
  addItemToPlantArrays: IPlantAttributesData;
}

export interface RemoveItemFromPlantArraysData {
  removeItemFromPlantArrays: IPlantAttributesData;
}

export interface SearchPlantMetadataData {
  searchPlantMetadata: PlantMetadataSearch;
}

export class PlantGraphQLProvider implements PlantProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async findAll(
    find?: any,
    pagination?: PaginationOptions
  ): Promise<PaginatedResults<Plant>> {
    try {
      const result = await this.client?.query<
        ListPlantsData,
        { find?: any; page?: number; perPage?: number }
      >({
        query: LIST_PLANTS,
        variables: {
          find,
          page: pagination?.page,
          perPage: pagination?.perPage,
        },
      });
      return {
        ...result.data.listPlants,
        items: result.data.listPlants.items.map((item) => new Plant(item)),
      };
    } catch (e) {
      console.log(e);
    }
  }

  async addItemToPlantArrays(
    plantId: string,
    itemId: string,
    itemType: PlantArrays
  ): Promise<Plant> {
    try {
      const result = await this.client?.mutate<
        AddItemToPlantArraysData,
        { plantId: string; itemId: string; itemType: PlantArrays }
      >({
        mutation: ADD_ITEM_TO_PLANT_ARRAYS,
        variables: {
          plantId,
          itemId,
          itemType,
        },
      });
      return new Plant(result.data.addItemToPlantArrays);
    } catch (e) {
      console.log(e);
    }
  }

  async removeItemFromPlantArrays(
    plantId: string,
    itemId: string,
    itemType: PlantArrays
  ): Promise<Plant> {
    try {
      const result = await this.client?.mutate<
        RemoveItemFromPlantArraysData,
        { plantId: string; itemId: string; itemType: PlantArrays }
      >({
        mutation: REMOVE_ITEM_FROM_PLANT_ARRAYS,
        variables: {
          plantId,
          itemId,
          itemType,
        },
      });
      return new Plant(result.data.removeItemFromPlantArrays);
    } catch (e) {
      console.log(e);
    }
  }

  async editMany(
    plants: IPlantAttributes[],
    userId?: string
  ): Promise<Plant[]> {
    try {
      const result = await this.client?.mutate<
        EditManyPlantsData,
        { input: IPlantAttributes[] }
      >({
        mutation: EDIT_MULTIPLE_PLANTS,
        variables: {
          input: plants,
        },
      });
      return result.data.editManyPlants.map((item) => new Plant(item));
    } catch (e) {
      console.log(e);
    }
  }
  async findOneById(plantId: string): Promise<Plant> {
    try {
      const result = await this.client?.query<
        GetPlantData,
        { plantId: string }
      >({
        query: GET_PLANT,
        variables: {
          plantId,
        },
      });
      return new Plant(result.data.getPlant);
    } catch (e) {
      console.log(e);
    }
  }
  save(plant: Plant, userId?: string): Promise<Plant> {
    throw new Error('Method not implemented.');
  }
  search(search: string, full?: boolean): Promise<Plant[]> {
    throw new Error('Method not implemented.');
  }
  async searchPlantMetadata(
    search: string
  ): Promise<PlantMetadataSearchReturn> {
    try {
      const result = await this.client?.query<
        SearchPlantMetadataData,
        { search: string }
      >({
        query: SEARCH_PLANT_METADATA,
        variables: {
          search,
        },
      });
      const data = result.data.searchPlantMetadata;
      return {
        metadatas: data?.metadatas?.map((item) => new ProductMetadata(item)),
      };
      // return data;
    } catch (e) {
      console.log(e);
    }
  }
}
