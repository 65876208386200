import { MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import React from 'react'
import { useTranslation } from '@kiway/shared/utils/translation'

const useStyles = makeStyles((theme) => ({
  whiteForm: {
    color: 'white',
    '& label': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& input + fieldset': {
      borderWidth: 2,
      color: 'white',
      borderColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      borderColor: 'white',
      color: 'white',
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover:not(.Mui-error) fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused:not(.Mui-error) fieldset': {
        borderColor: 'white',
      },
    },
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
}))

export const genders = (t) => [
  {
    value: null,
    label: <i>{t('common:form.fields.gender.default')}</i>,
  },
  {
    value: 'F',
    label: t('common:form.fields.gender.female'),
  },
  {
    value: 'M',
    label: t('common:form.fields.gender.male'),
  },
]

function GenderPicker(props) {
  const classes = useStyles()
  const { disabled, handleChange, theme, value } = props
  const { t } = useTranslation()
  return (
    <FormControl
      variant="outlined"
      fullWidth
      margin="dense"
      className={
        theme === 'green' ? `${classes.formControl} ${classes.whiteForm}` : ''
      }
    >
      <InputLabel id="gender-label">{t('file:details.info.gender')}</InputLabel>
      <Select
        labelId="gender-label"
        margin="dense"
        id="gender"
        name="gender"
        variant="outlined"
        value={value || ''}
        labelWidth={50}
        fullWidth
        onChange={handleChange}
        disabled={disabled}
      >
        {genders(t).map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.value === 0 ? <i>{option.label}</i> : option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default GenderPicker
