import * as React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import { OrderStepperController } from '../OrderStepper';
import { Order } from '@kiway/ecommerce-react-compatible';
import { useTranslation } from '@kiway/shared/utils/translation';
import { PrescriptionResume } from '../PrescriptionDetails/PrescriptionResume';
import { PharmacoOrder } from '../../../core/entities/PharmacoOrder';
import { TextInput } from '@kiway/shared/ui';

export interface OrderCommentsProps {
  order: PharmacoOrder;
  realOrder: Order;
  handleChange: (commentType: any) => (e: any) => void;
  handleNext: () => void;
  handlePrev: () => void;
}

export function OrderComments(props: OrderCommentsProps) {
  const { t } = useTranslation();

  if (!props.order) {
    return null;
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          {t(`pharmaco:prescription.order.label`)} n°
          {props.realOrder?.getOrderNumber()}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <OrderStepperController
          currentStep="comments"
          orderId={props.realOrder?.getId()}
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={t('pharmaco:orderDetails.comment.patientComment')}
          />
          <CardContent>
            <TextInput
              onChange={props.handleChange('patientComment')}
              value={props.order?.getPatientComment()}
              fullWidth
              multiline
              minRows={3}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={t('pharmaco:orderDetails.comment.assistantComment')}
          />
          <CardContent>
            <TextInput
              onChange={props.handleChange('preparerComment')}
              value={props.order?.getPreparerComment()}
              fullWidth
              multiline
              minRows={3}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={t('pharmaco:orderDetails.comment.ownComment')} />
          <CardContent>
            <TextInput
              onChange={props.handleChange('practitionerComment')}
              value={props.order?.getPractitionerComment()}
              fullWidth
              multiline
              minRows={3}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <PrescriptionResume
          currentStep="comments"
          order={props.order}
          realOrder={props.realOrder}
          handleClickNext={props.handleNext}
          handleClickPrev={props.handlePrev}
        />
      </Grid>
    </Grid>
  );
}
