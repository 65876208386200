import {
  basicDeleteRequest,
  basicGetRequest,
  getBaseURL,
  runBasicFetch,
  runBasicFetchV2,
} from '../utils/api';

const BASE_URL = getBaseURL();

// TODO implement validation
export const isConsultationPlaceValid = (consultationPlace) => true;

export const getConsultationPlace = (consultationPlaceId) => {
  return runBasicFetch(
    basicGetRequest(`${BASE_URL}/pro/consultationPlace/${consultationPlaceId}`),
    true
  );
};

export const getPractitionerConsultationPlaces = (
  practitionerId = false,
  publicPage = false
) => {
  return runBasicFetch(
    basicGetRequest(
      // eslint-disable-next-line max-len
      `${BASE_URL}/pro/consultationPlace?publicPage=${publicPage}${
        practitionerId ? `&practitionerId=${practitionerId}` : ``
      }`
    ),
    !publicPage
  );
};

export const editConsultationPlace = (consultationPlace) => {
  if (!isConsultationPlaceValid(consultationPlace)) {
    return false;
  }
  const { _id: consultationPlaceId } = consultationPlace;
  let method = 'POST';
  let url = `${BASE_URL}/pro/consultationPlace`;
  if (consultationPlaceId) {
    method = 'PUT';
    url = `${url}/${consultationPlaceId}`;
  }
  const request = {
    url,
    init: {
      method,
      body: JSON.stringify(consultationPlace),
    },
  };
  return runBasicFetch(request, true);
};

export const deleteConsultationPlace = (consultationPlaceId) => {
  const url = `${BASE_URL}/pro/consultationPlace/${consultationPlaceId}`;
  return runBasicFetchV2(basicDeleteRequest(url), true);
};
