import './select.module.css';
import * as React from 'react';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
} from '@mui/material';
import MUISelect, { SelectChangeEvent } from '@mui/material/Select';
import ClearIcon from '@mui/icons-material/Clear';

export interface SelectOption {
  label: any;
  value: string;
}

export interface SelectProps {
  disabled?: boolean;
  handleClear?: (...args: any[]) => any;
  label?: string;
  margin?: 'dense' | 'none';
  onChange?: (event: SelectChangeEvent) => void;
  options: Array<SelectOption | string>;
  required?: boolean;
  size?: 'small' | 'medium';
  value?: string;
}

export function Select(props: SelectProps) {
  const [value, setValue] = React.useState(props.value ?? '');

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  React.useEffect(() => {
    setValue(props.value ?? '');
    return () => {
      setValue('');
    };
  }, [props.value]);

  return (
    <Box>
      <FormControl
        disabled={props.disabled}
        fullWidth
        margin={props.margin}
        required={props.required}
        size={props.size}
      >
        {props.label && (
          <InputLabel id="select-label">{props.label}</InputLabel>
        )}
        <MUISelect
          id="select"
          label={props.label}
          labelId="select-label"
          margin={props.margin}
          onChange={handleChange}
          size={props.size}
          value={value}
          startAdornment={
            props.value !== '' &&
            props.handleClear && (
              <IconButton
                sx={{ marginRight: 1 }}
                size="small"
                disabled={props.disabled}
                onClick={props.handleClear}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }
          sx={{
            paddingLeft:
              props.value !== '' && props.handleClear ? 1 : undefined,
          }}
        >
          {props.options.map((option, index) => {
            if (typeof option === 'string') {
              return (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              );
            } else {
              return (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            }
          })}
        </MUISelect>
      </FormControl>
    </Box>
  );
}

export default Select;
