import { LoginResult, UserProvider } from './UserProvider';

export class Login {
  private userProvider: UserProvider;

  public constructor(userProvider: UserProvider) {
    this.userProvider = userProvider;
  }

  public execute(
    email: string,
    password: string,
    rememberMe?: boolean
  ): Promise<LoginResult> {
    if (!email) {
      throw new Error('email is required');
    }
    if (!password) {
      throw new Error('password is required');
    }
    return this.userProvider.login(email, password, rememberMe);
  }
}
