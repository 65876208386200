import * as React from 'react';
import { Plant } from '../../../core/entities/Plant';
import { Skeleton } from '@material-ui/lab';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useStyles } from '../shared';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ProductDetailsFieldWrapper } from '@kiway/ecommerce-react';
import { useTranslation } from '@kiway/shared/utils/translation';

export interface PlantDetailsHeaderProps {
  handleChange: (type: string) => (value: string | number | boolean) => any;
  handleSave: (type: string) => (id?: string) => void;
  loading?: boolean;
  plant: Plant;
}

export function PlantDetailsHeader({
  handleChange,
  handleSave,
  loading,
  plant,
}: React.PropsWithChildren<PlantDetailsHeaderProps>) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          {plant?.getLegacyId() ? (
            <Grid item xs={12}>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Typography align="center" variant="caption">
                  {t('pharmaco:plant.legacyId')} {plant?.getLegacyId()}
                </Typography>
              </div>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={10}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ProductDetailsFieldWrapper
                  handleChange={handleChange('pinYinName')}
                  handleSave={handleSave('pinYinName')}
                  loading={loading}
                  inputType="text"
                  label="pharmaco:plant.pinYinName"
                  value={plant?.getPinYinName()}
                  style={{
                    fontSize: 24,
                    textAlign: 'center',
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('latinName')}
                        handleSave={handleSave('latinName')}
                        loading={loading}
                        inputType="text"
                        label="pharmaco:plant.latinName"
                        value={plant?.getLatinName()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('commonName')}
                        handleSave={handleSave('commonName')}
                        loading={loading}
                        inputType="text"
                        label="pharmaco:plant.commonName"
                        value={plant?.getCommonName()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('chineseName')}
                        handleSave={handleSave('chineseName')}
                        loading={loading}
                        inputType="text"
                        label="pharmaco:plant.chineseName"
                        value={plant?.getChineseName()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('otherName')}
                        handleSave={handleSave('otherName')}
                        loading={loading}
                        inputType="text"
                        label="pharmaco:plant.otherName"
                        value={plant?.getOtherName()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('concentrationRate')}
                        handleSave={handleSave('concentrationRate')}
                        loading={loading}
                        inputType="number"
                        label="pharmaco:plant.concentrationRate"
                        value={plant?.getConcentrationRate()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('posologyMin')}
                        handleSave={handleSave('posologyMin')}
                        loading={loading}
                        inputType="number"
                        label="pharmaco:plant.posologyMin"
                        value={plant?.getPosologyMin()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('posologyMax')}
                        handleSave={handleSave('posologyMax')}
                        loading={loading}
                        inputType="number"
                        label="pharmaco:plant.posologyMax"
                        value={plant?.getPosologyMax()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('referenceStockPlant')}
                        handleSave={handleSave('referenceStockPlant')}
                        loading={loading}
                        inputType="text"
                        label="pharmaco:plant.refStockPlant"
                        value={plant?.getReferenceStockPlant()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('referenceStockPowder')}
                        handleSave={handleSave('referenceStockPowder')}
                        loading={loading}
                        inputType="text"
                        label="pharmaco:plant.refStockPowder"
                        value={plant?.getReferenceStockPowder()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('plant')}
                        handleSave={handleSave('plant')}
                        loading={loading}
                        inputType="boolean"
                        label="pharmaco:plant.availablePlant"
                        value={plant?.isPlant()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('concentratedPowder')}
                        handleSave={handleSave('concentratedPowder')}
                        loading={loading}
                        inputType="boolean"
                        label="pharmaco:plant.availableConcentratedPowder"
                        value={plant?.isConcentratedPowder()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('toxic')}
                        handleSave={handleSave('toxic')}
                        loading={loading}
                        inputType="checkbox"
                        label="pharmaco:plant.toxic"
                        value={plant?.isToxic()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('published')}
                        handleSave={handleSave('published')}
                        loading={loading}
                        inputType="checkbox"
                        label="pharmaco:plant.published"
                        value={plant?.isPublished()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProductDetailsFieldWrapper
                        handleChange={handleChange('sellAvailable')}
                        handleSave={handleSave('sellAvailable')}
                        loading={loading}
                        inputType="checkbox"
                        label="pharmaco:plant.sellAvailable"
                        value={plant?.isSellAvailable()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={2}>
            <div className={classes.imageContainer}>
              {loading ? (
                <Skeleton variant="rect" width={110} height={160} />
              ) : plant?.getPictureUrl() ? (
                <img className={classes.image} src={plant?.getPictureUrl()} />
              ) : null}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
