import { User } from '../entities/User';
import { UserProvider } from './UserProvider';

export class GetAllUsers {
  private userProvider: UserProvider;

  public constructor(userProvider: UserProvider) {
    this.userProvider = userProvider;
  }

  public execute(): Promise<User[]> {
    return this.userProvider.findAll();
  }
}
