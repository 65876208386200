import { Payment } from '../entities/Payment';
import { PaymentProvider } from './PaymentProvider';

export class GetAllPayments {
  private paymentProvider: PaymentProvider;

  public constructor(paymentProvider: PaymentProvider) {
    this.paymentProvider = paymentProvider;
  }

  public execute(): Promise<Payment[]> {
    return this.paymentProvider.findAll();
  }
}
