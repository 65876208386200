import { Customer, ICustomerAttributes } from '../../core/entities/Customer';
import { CustomerProvider } from '../../core/use_cases/CustomerProvider';
import { gql, ApolloClient } from '@apollo/client';

export const LIST_CUSTOMERS = gql`
  query {
    listCustomers {
      id
      firstName
      lastName
      email
      mobilePhone
      language
      timezone
      gender
      birthDate
      profession
      custom
      # Add here your attributes
    }
  }
`;

export type ICustomerAttributesData = ICustomerAttributes & {
  id: string;
};

export interface ListCustomersData {
  listCustomers: Array<ICustomerAttributesData>;
}

export class CustomerGraphQLProvider implements CustomerProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }
  findOne(find?: any): Promise<Customer> {
    throw new Error('Method not implemented.');
  }

  async findAll(): Promise<Customer[]> {
    try {
      const result = await this.client?.query<ListCustomersData>({
        query: LIST_CUSTOMERS,
      });
      return result.data.listCustomers.map((item) => new Customer(item));
    } catch (e) {
      console.log(e);
    }
  }

  save(customer: Customer, userId?: string): Promise<Customer> {
    throw new Error('Method not implemented.');
  }
  editMany(
    entities: ICustomerAttributes[],
    userId?: string
  ): Promise<Customer[]> {
    throw new Error('Method not implemented.');
  }

  search(search: string, full?: boolean): Promise<Customer[]> {
    throw new Error('Method not implemented.');
  }
}
