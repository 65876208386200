import React from 'react';
import { Payment } from '../../../core/entities/Payment';

interface BankTransferInformationProps {
  selectedPayment: Payment;
}

export const BankTransferInformation = (
  props: BankTransferInformationProps
) => {
  const [url, setUrl] = React.useState<string>('');
  const [isImage, setIsImage] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (props.selectedPayment?.getCustom()?.bankTransfer?.proofUrl !== url) {
      setUrl(props.selectedPayment?.getCustom()?.bankTransfer?.proofUrl ?? '');
    }
    if (
      props.selectedPayment
        ?.getCustom()
        ?.bankTransfer?.proof?.contentType?.includes('image')
    ) {
      if (!isImage) {
        setIsImage(true);
      }
    } else {
      if (isImage) {
        setIsImage(false);
      }
    }
    return () => {
      setUrl('');
      setIsImage(false);
    };
  }, [props.selectedPayment]);
  return isImage ? (
    <img
      src={url}
      alt="bank transfer proof"
      style={{ width: '100%', height: 'auto' }}
    />
  ) : (
    <iframe src={url} style={{ width: '100%', minHeight: '500px' }} />
  );
};
