import * as React from 'react';
import { Typography } from '@material-ui/core';
import { Retailer } from '../../../core/entities/Retailer';
import { Skeleton } from '@material-ui/lab';

export interface ListRetailersProps {
  retailers: Retailer[];
  loading?: boolean;
}

const renderRetailer = (item: Retailer) => (
  <li key={item.getId()}>{item.getId()}</li>
);

const loadingRetailerScreen = (
  <div>
    <ul>
      <li>
        <Skeleton variant="text" />
      </li>
      <li>
        <Skeleton variant="text" />
      </li>
      <li>
        <Skeleton variant="text" />
      </li>
    </ul>
  </div>
);

export function ListRetailers({
  loading,
  retailers,
}: React.PropsWithChildren<ListRetailersProps>) {
  return (
    <div>
      <Typography variant="h5">Retailer :</Typography>
      {loading ? (
        loadingRetailerScreen
      ) : (
        <div>
          <ul>{retailers?.length ? retailers?.map(renderRetailer) : 'Aucune donnée'}</ul>
        </div>
      )}
    </div>
  );
}
