import * as React from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Dialog } from '@kiway/shared/ui';
import {
  Button,
  Grid,
  Divider,
  Select,
  SelectChangeEvent,
  MenuItem,
} from '@mui/material';
import { Item } from './Item/Item';
import { Plant } from '../../../core/entities/Plant';
import { SearchBar } from './SearchBar/SearchBar';
import { Filter } from './Filter/Filter';
import { useStyles } from '../shared/styles';
import { ValidationModal } from '../ValidationModal/ValidationModal';
import { SelectedItem } from './Item/SelectedItem';
import { useTranslation } from '@kiway/shared/utils/translation';
import {
  PharmacoOrder,
  TempFormulaMode,
  TempFormulaType,
} from '../../../core/entities/PharmacoOrder';
import { Formula } from '../../../core/entities/Formula';
import { Pagination, Typography } from '@mui/material';
import { PaginatedResults } from '@kiway/shared/react-types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Product } from '@kiway/ecommerce-react-compatible';
import { ChipFilter } from './PharmacoBrowserController';
import { ProductMetadata } from '../../../core/entities/ProductMetadata';
import { SelectMetadata } from './SelectMetadata';
export interface PharmacoBrowserProps {
  itemPerPage: number;
  paginatedResults: PaginatedResults<Plant | Formula>;
  handleChangePage: (
    event: React.ChangeEvent<unknown>,
    pageNumber: number
  ) => void;
  isBrowserOpen?: boolean;
  isDosageModalOpen?: boolean;
  order?: PharmacoOrder;
  handleChangeStateOrder: any;
  removePlant: (plantId: string) => void;
  changeFormulaName: (formulaName: string) => void;
  selectedItems: Array<Plant | Formula>;
  setIsBrowserOpen: any;
  setIsDosageModalOpen: any;
  handleClickItem: (item: Plant | Formula) => () => void;
  toggleFormulaMode: (mode: TempFormulaMode) => void;
  changeFormulaSelection: (
    groupBy: string,
    formulaName: string,
    type: TempFormulaType
  ) => void;
  changeFormulaType: (type: TempFormulaType) => void;
  handleFormulaSubmit: any;
  search: string;
  setSearch: any;
  handleChangeFilter: (value: string) => any;
  selectedFilter: string;
  isButtonDisabled: boolean;
  setIsButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  filteredIngredientsMetadatas: Array<ProductMetadata>;
  filteredFormulasMetadatas: Array<ProductMetadata>;
  filteredActionsMetadatas: Array<ProductMetadata>;
  filteredSyndromsMetadatas: Array<ProductMetadata>;
  selectedIngredientsCategoriesMetadataId: string;
  setSelectedIngredientsCategoriesMetadataId: React.Dispatch<
    React.SetStateAction<string>
  >;
  selectedFormulasCategoriesMetadataId: string;
  setSelectedFormulasCategoriesMetadataId: React.Dispatch<
    React.SetStateAction<string>
  >;
  selectedFormulasSyndromsMetadataId: string;
  setSelectedFormulasSyndromsMetadataId: React.Dispatch<
    React.SetStateAction<string>
  >;
  selectedIngredientsActionsMetadataId: string;
  setSelectedIngredientsActionsMetadataId: React.Dispatch<
    React.SetStateAction<string>
  >;
  filterSentence: string;
}

export function PharmacoBrowser(props: PharmacoBrowserProps) {
  const { t } = useTranslation();

  const handleChangeSelectMetadata = (
    type: 'formula' | 'ingredient',
    event?: SelectChangeEvent,
    subtype?: string
  ) => {
    if (type === 'formula') {
      if (subtype === 'syndrom') {
        props.setSelectedFormulasSyndromsMetadataId(event.target.value);
      } else {
        props.setSelectedFormulasCategoriesMetadataId(event.target.value);
      }
    } else if (type === 'ingredient') {
      if (subtype === 'action') {
        props.setSelectedIngredientsActionsMetadataId(event.target.value);
      } else {
        props.setSelectedIngredientsCategoriesMetadataId(event.target.value);
      }
    }
  };

  const defaultFilters: ChipFilter[] = [
    {
      label: t('pharmaco:browser.ingredients'),
      key: 'ingredients',
      subFilters: [
        {
          label: t('pharmaco:browser.all'),
          key: 'ingredients_all',
        },
        {
          label: t('pharmaco:browser.plant'),
          key: 'ingredients_plant',
        },
        {
          label: t('pharmaco:browser.powder'),
          key: 'ingredients_powder',
        },
      ],
    },
    {
      label: t('pharmaco:browser.formula'),
      key: 'formulas',
      subFilters: [
        {
          label: t('pharmaco:browser.normal'),
          key: 'formulas_normal',
        },
        {
          label: t('pharmaco:browser.xiaoFang'),
          key: 'formulas_xiaoFang',
        },
        {
          label: t('pharmaco:browser.jingFang'),
          key: 'formulas_jingFang',
        },
      ],
    },
    {
      label: t('pharmaco:browser.noFilter'),
      key: 'all',
    },
  ];

  const returnSelectCategory = () => {
    if (props.selectedFilter.includes('ingredients')) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SelectMetadata
              type="ingredient"
              handleChange={handleChangeSelectMetadata}
              selectedMetadataId={props.selectedIngredientsCategoriesMetadataId}
              filteredMetadatas={props.filteredIngredientsMetadatas}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectMetadata
              type="ingredient"
              subtype="action"
              handleChange={handleChangeSelectMetadata}
              selectedMetadataId={props.selectedIngredientsActionsMetadataId}
              filteredMetadatas={props.filteredActionsMetadatas}
            />
          </Grid>
        </Grid>
      );
    } else if (props.selectedFilter.includes('formulas')) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={props.selectedFilter.includes('jingFang') ? 6 : 12}>
            <SelectMetadata
              type="formula"
              handleChange={handleChangeSelectMetadata}
              selectedMetadataId={props.selectedFormulasCategoriesMetadataId}
              filteredMetadatas={props.filteredFormulasMetadatas}
            />
          </Grid>

          {props.selectedFilter.includes('jingFang') && (
            <Grid item xs={6}>
              <SelectMetadata
                type="formula"
                subtype="syndrom"
                handleChange={handleChangeSelectMetadata}
                selectedMetadataId={props.selectedFormulasSyndromsMetadataId}
                filteredMetadatas={props.filteredSyndromsMetadatas}
              />
            </Grid>
          )}
        </Grid>
      );
    } else {
      return null;
    }
  };

  const returnBrowserItems = () => {
    return (
      <Grid container>
        <Grid item xs={2}>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            direction="row"
            textAlign="center"
          >
            {props.selectedItems.length > 0 ? (
              props.selectedItems.map((selectedItem: Plant | Formula) => {
                return (
                  <Grid item xs={10} key={selectedItem.getId()}>
                    <SelectedItem selectedItem={selectedItem} />
                    <Divider variant="middle" sx={{ marginTop: 1 }} />
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={10}>
                <SelectedItem
                  selectedItem={
                    new Plant({
                      custom: { pinYinName: t('pharmaco:browser.noSelected') },
                    })
                  }
                />
                <Divider variant="middle" sx={{ marginTop: 1 }} />
              </Grid>
            )}
            <Grid item xs={10}>
              <Button
                color="minoritary"
                variant="contained"
                disabled={props.selectedItems.length <= 0}
                onClick={() =>
                  props.setIsDosageModalOpen(!props.isDosageModalOpen)
                }
              >
                {t('pharmaco:validate')}
              </Button>
              <ValidationModal
                isOpen={props.isDosageModalOpen}
                setIsOpen={props.setIsDosageModalOpen}
                removePlant={props.removePlant}
                changeFormulaName={props.changeFormulaName}
                changeFormulaSelection={props.changeFormulaSelection}
                changeFormulaType={props.changeFormulaType}
                toggleFormulaMode={props.toggleFormulaMode}
                order={props.order}
                handleChangeStateOrder={props.handleChangeStateOrder}
                handleFormulaSubmit={props.handleFormulaSubmit}
                isButtonDisabled={props.isButtonDisabled}
                setIsButtonDisabled={props.setIsButtonDisabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontStyle={'italic'}>
                {props.filterSentence}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {props.paginatedResults?.items?.map((item) => {
                  return (
                    <Grid item xs={4} md={3} lg={2} key={item.getId()}>
                      <Item
                        item={item}
                        selected={
                          props.selectedItems
                            ?.find((plant) => plant.getId() === item.getId())
                            ?.getId() !== undefined
                        }
                        handleSelect={
                          item instanceof Plant
                            ? item.getCustom()?.sellAvailable
                              ? props.handleClickItem(item)
                              : null
                            : props.handleClickItem(item)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Pagination
                  count={props.paginatedResults?.totalPages}
                  page={props.paginatedResults?.currentPage}
                  onChange={props.handleChangePage}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      handleClose={() => props.setIsBrowserOpen(false)}
      open={props.isBrowserOpen}
      title={t('pharmaco:orderDetails.buttons.addItemsToOrder')}
    >
      <Grid
        container
        rowSpacing={2}
        sx={{ m: 0, mt: 1, p: 1 }}
        justifyContent="center"
      >
        <Grid item xs={12}>
          <SearchBar search={props.search} setSearch={props.setSearch} />
        </Grid>

        <Grid item xs={12}>
          <Grid container columnSpacing={1} justifyContent="center">
            {defaultFilters.map((filter) => (
              <Grid item key={filter.key}>
                <Filter
                  key={filter.key}
                  filter={filter}
                  selectedKey={props.selectedFilter}
                  handleClick={props.handleChangeFilter}
                  complexFilter={filter?.subFilters?.length > 0}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {returnSelectCategory()}
        </Grid>
        <Grid item xs={12}>
          {returnBrowserItems()}
        </Grid>
      </Grid>
    </Dialog>
  );
}
