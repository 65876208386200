import React from 'react';
import { Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import { firstUpperCase } from '../services/utils/string';

const SETTINGS_TAB_URI = '/settings/:tab?';
function CustomRoute({
  children,
  description,
  pageName,
  render,
  subtitle,
  title,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        let pathGA = null;
        if (typeof pageName === 'function') {
          const { path, url } = pageName(props);
          pathGA = path === SETTINGS_TAB_URI ? url : path;
        } else {
          pathGA = pageName;
        }
        ReactGA.set({ page: pathGA || rest.path });
        ReactGA.pageview(pathGA || rest.path);
        if (title || subtitle || description) {
          document.title = `${
            firstUpperCase(title) || process.env.NX_APPBAR_NAME
          }${subtitle ? ` - ${firstUpperCase(subtitle)}` : ''}${
            description ? ` - ${firstUpperCase(description)}` : ''
          }`;
        }
        return render(props);
      }}
    >
      {children}
    </Route>
  );
}

export default CustomRoute;
