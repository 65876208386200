import { Grid } from '@mui/material';
import * as React from 'react';

export interface OrderCardInfoLineProps {
  label: string;
  value: any;
}

export function OrderCardInfoLine(props: OrderCardInfoLineProps) {
  return (
    <Grid container spacing={1}>
      <Grid item flex={1}>
        <b>{props.label}</b>
      </Grid>
      <Grid item>{props.value}</Grid>
    </Grid>
  );
}
