import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from '../../shared/styles';
import { firstUpperCase } from '@kiway/shared/utils/string';

interface SideCardLineProps {
  label?: string;
}

export default function SideCardLine({
  label,
  children,
}: React.PropsWithChildren<SideCardLineProps>) {
  const classes = useStyles();

  return (
    <Grid container style={{ marginTop: '0.8vh' }}>
      {label && (
        <Grid item xs={12} className={classes.dataSideLineWrapper}>
          <Typography variant="body1">{firstUpperCase(label)}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
}
