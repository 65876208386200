/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import { Grid, TextField, Typography, Box } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Order } from '@kiway/ecommerce-react';

export interface CheckProps {
  disabled?: boolean;
  handleChange: (name: string) => (e: any) => void;
  handleSave: () => void;
  order: Order;
}

export function Check(props: CheckProps) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              disabled={props.disabled}
              fullWidth
              label={t('pharmaco:orderDetails.payment.forms.lastName')}
              variant="outlined"
              value={
                props.order?.getPayment()?.getCustom()?.check?.lastName ?? ''
              }
              onChange={props.handleChange('lastName')}
              onBlur={props.handleSave}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={props.disabled}
              fullWidth
              label={t('pharmaco:orderDetails.payment.forms.firstName')}
              variant="outlined"
              value={
                props.order?.getPayment()?.getCustom()?.check?.firstName ?? ''
              }
              onChange={props.handleChange('firstName')}
              onBlur={props.handleSave}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={props.disabled}
          fullWidth
          label={t('pharmaco:orderDetails.payment.forms.address')}
          variant="outlined"
          value={props.order?.getPayment()?.getCustom()?.check?.address ?? ''}
          onChange={props.handleChange('address')}
          onBlur={props.handleSave}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={props.disabled}
          fullWidth
          label={t('pharmaco:orderDetails.payment.forms.checkNumber')}
          variant="outlined"
          value={
            props.order?.getPayment()?.getCustom()?.check?.checkNumber ?? ''
          }
          onChange={props.handleChange('checkNumber')}
          onBlur={props.handleSave}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={props.disabled}
          fullWidth
          label={t('pharmaco:orderDetails.payment.forms.bank')}
          variant="outlined"
          value={props.order?.getPayment()?.getCustom()?.check?.bank ?? ''}
          onChange={props.handleChange('bank')}
          onBlur={props.handleSave}
        />
      </Grid>

      <Box mt={2} ml={4}>
        <Grid container>
          <Grid item xs={4}>
            <Typography>
              {t('pharmaco:orderDetails.payment.forms.order')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              <b>Yao Dao</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <Typography>
              {t('pharmaco:orderDetails.payment.forms.address')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid container>
              <Grid item xs={12}>
                <Typography>
                  <b>Yao Dao Chinese Herbs</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Rua Rui de Moura Belo n°2</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>2925-226 Azeitao</Typography>
              </Grid>
              <Grid item>
                <Typography>Portugal</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
