export default {
  appointments: {
    chiefComplaint: 'Motif de consultation',
    datatableTitle: 'Mes rendez-vous',
    date: 'Date',
    hour: 'Heure',
    patientNote: 'Notes de séance',
    practitioner: 'Praticien',
    practitionerContact: 'Contacter le praticien',
  },
  newPatient: 'Nouveau patient',
};
