export default {
  address: {
    addAddress: 'Añadir una dirección',
    address: 'Dirección',
    city: 'Ciudad',
    country: 'País',
    fullName: 'nombre completo',
    label: 'Dirección',
    number: 'número de calle',
    useAddress: 'Usa esta dirección',
    useAsDefault: 'Usar como dirección predeterminada',
    validate: 'Validar cambios',
    zipCode: 'Código postal',
  },
  backToList: 'vuelve a la lista',
  backToOrderDetails: 'Volver a los detalles del pedido',
  browser: {
    addCart: '',
    all: 'Todos',
    emptyCart: '',
    formula: 'Fórmula',
    ingredients: 'Ingredientes',
    jingFang: 'Jing Fang',
    noFilter: 'Sin filtro',
    normal: 'Normal',
    noSelected: 'No hay plantas seleccionadas',
    plant: 'Hierbas',
    powder: 'Granulados Concentrados',
    sentence: {
      all: 'todos los artículos',
      contains: 'que contienen',
      display: 'Uusted muestra',
      formulas_jingFang: 'fórmulas de Jing Fang',
      formulas_normal: 'fórmulas normales',
      formulas_xiaoFang: 'fórmulas de Xiao Fang',
      have_action: 'todo',
      have_category: 'que tienen la categoría',
      have_syndrom: 'todo',
      ingredients_all: 'todos los ingredientes',
      ingredients_plant: 'los ingredientes disponibles en las plantas secas',
      ingredients_powder: 'los ingredientes disponibles en polvo concentrado',
    },
    xiaoFang: 'Xiao Fang',
  },
  button: {
    discard: 'Cancelar',
    save: 'Registro',
  },
  cancel: 'anular',
  cancelOrder: {
    message: '',
    title: '',
  },
  carriers: {
    code: {
      helperText: 'Útil para el desnvolvimento, no cambiar',
      label: 'codigo de automatización',
    },
    docSubtitle: 'Lista de transportes',
    label: {
      en: 'Nombre EN',
      es: 'Nombre ES',
      fr: 'Nombre FR',
      pt: 'Nombre de PT',
    },
    linkText: 'Transportes',
    trackingLink: {
      helperTextError:
        'Substitua el numero de seguimiento en el link {{trackingNumber}}',
      label: 'Link para seguimiento del pedido',
    },
  },
  checkout: {
    acceptation: 'Acepto la Política de privacidad de Yao Dao',
    address: 'Verificación de dirección',
    approvment: 'Apruebo las condiciones de venta',
    cardPaymentSuccess: 'pago exitoso',
    confirmAddress: 'Confirmo la precisión de esta información',
    confirmAddressMessage:
      'Verifique que la información de la dirección sea precisa. No seremos responsables en caso de no entrega de su pedido.',
    confirmation: 'Confirmación',
    deferredPaymentSuccess: 'No olvide enviar su pago',
    deliveryPayment: 'Entrega y pago',
    errorDeferredPayment:
      'Se produjo un error durante el pago. Póngase en contacto con un administrador.',
    errorSnackbarAddress:
      'Dirección invalidal, complete los campos obligatorios indicados por *',
    homeReturn: 'Vuelver al inicio',
    proSpaceReturn: 'Vuel al espacio professional',
    wish:
      'Deseo recibir correos electrónicos de Yao Dao que me informen sobre ofertas, promociones, novedades, pasantías y capacitación relacionadas con la farmacopea china y la medicina tradicional china.',
  },
  composition: {
    dosage: 'Dosis',
    name: 'apellido',
    title: 'Composición',
  },
  countries: {
    docSubtitle: 'Lista de países',
    label: {
      code: 'Código ISO',
      name: {
        en: 'Nombre',
        es: 'Nombrar',
        fr: 'Nombre FR',
        pt: 'Nombre de PT',
      },
      vatPlant: 'Taxa Plantas',
      vatPowder: 'Taxa CP',
    },
    linkText: 'País',
  },
  countryZones: {
    countries: 'Lista de países',
    docSubtitle: 'Lista de áreas de campo',
    label: {
      name: 'apellido',
    },
    linkText: 'Áreas de campo',
  },
  dashboard: {
    analytics: {
      avgCart: '',
      charts: {
        orderStatus: '',
        preparerStats: '',
      },
      extra: {
        period: {
          month: '',
          prevMonth: '',
          prevQuarter: '',
          prevWeek: '',
          prevYear: '',
          quarter: '',
          today: '',
          week: '',
          year: '',
          yesterday: '',
        },
        profit: {
          less: '',
          more: '',
        },
        type: {
          clients: '',
          orders: '',
        },
      },
      HT_HT: '',
      nbOrder: '',
      orderCancelled: '',
      orderCompleted: '',
      periods: {
        lastQuarter: '',
        month: '',
        prevMonth: '',
        prevWeek: '',
        prevYear: '',
        quarter: '',
        today: '',
        week: '',
        year: '',
        yesterday: '',
      },
      revenue: '',
      running: '',
      title: '',
      totalClients: '',
      totalOrders: '',
    },
  },
  deleteOrder: {
    message: '',
    title: '',
  },
  filters: {
    carriers: {
      all: 'Todos',
      label: 'Transportador',
    },
    metadatas: {
      action: 'todo',
      formulasCategory: 'Seleccionar una categoria de formulas',
      ingredientsCategory: 'Seleccionar una categoria de ingredientes',
      syndrom: 'todo',
    },
    shippingMethods: {
      all: 'Todos',
      label: 'Método de entrega',
    },
    variations: {
      all: 'Todos',
      label: 'Acondicionamiento',
    },
  },
  formula: {
    clickPlantToSeeMore: 'Seleccionar un hierba e ver su ficha',
    concentration: 'Tasa de concentración',
    description: 'Fang Zheng {{código}}',
    detailsDocSubtitle: 'Detalles de la fórmula',
    detailsLinkText: 'Detalles de las fórmulas',
    detailsSuccessUpdate_one: 'Modificación registrada',
    detailsSuccessUpdate_other: 'Modificaciones registradas',
    docSubtitle: 'Lista de fórmulas',
    dosage: 'Dosis',
    dosagePlant: 'Dosificación en plantas secas',
    dosagePowder: 'Dosificación en polvo concentrado',
    equivalentCP: 'Equiv. PC',
    equivalentPS: 'Equiv. PS',
    fangZheng: 'Fang Zheng',
    jingFang: 'Jing Fang',
    legacyId: 'Antigua identificación:',
    linkText: 'Fórmulas',
    listTitle: 'Fórmulas',
    normal: 'Normal',
    percent: '%',
    pinYinName: 'Nombre de pinyin',
    priceHT: 'Precio sin taxas',
    print: 'Imprima la fórmula',
    published: 'Publicado',
    separate: 'Separado',
    total: 'Total',
    totalWeight: 'Peso total',
    variation: 'Variación',
    weightPerCapsule: 'Por capsula',
    weightPerPortion: 'por bolsa',
    xiaoFang: 'Xiao Fang',
  },
  lastOrders: {
    title: 'Últimos pedidos',
  },
  linkText: 'Farmacopea',
  list: {
    docSubtitle: 'Lista de plantas',
    linkText: 'Plantas',
  },
  loading: 'Cargando...',
  modify: 'Editar',
  next: 'Próximo',
  noData: 'Sin datos',
  order: {
    addToOrder: 'Agregue la selección al pedido',
    aknowledgement: 'Gracias por su pedido!',
    commandDetails: {
      command: 'Ordenado',
      commandDetails: 'Detalles del pedido',
      date: 'Fecha',
      product: 'Producto',
      quantity: 'Cantidad',
      sentTo: 'Enviado a',
    },
    emptyOrder: 'Comando vacío',
    newOrder: 'Nuevo pedido',
    payNowButton: 'Pagar ahora',
    resumeMessage:
      'Su pedido ha sido validado, el paciente recibirá un correo electrónico con un enlace de pago. Ahora puede pagarlo utilizando el botón de abajo.',
    steps: {
      comments: 'Comentario',
      prescription: 'Cesta',
      resume: 'Validación',
      shipping: 'Entrega',
    },
  },
  orderCount: 'Numero de ordenes',
  orderDetails: {
    buttons: {
      addItemsToOrder: 'adicionar productos al pedido',
      dispatch: 'Despacho',
      followPackage: 'seguir mi pedido',
      invoice: 'Factura',
      mailReminder: 'Recordatorio',
      markAsPaid: 'marcar como pagado',
      prepare: 'Preparar',
      seePayment: 'ver el pago',
      sendMail: 'Consulte el correo electrónico',
      sendTracking: 'Regreso de seguimiento -Up',
      startPrepare: 'Comience la preparación',
    },
    cartPrice: 'Precio de canasta',
    changePatient: 'Cambiar al paciente',
    client: {
      address1: 'Dirección 1',
      address2: 'Dirección 2',
      city: 'Ciudad',
      country: 'País',
      email: 'Correo electrónico',
      firstName: 'Nombre',
      indicator: 'Indicador',
      label: 'Paciente',
      lastName: 'apellido',
      mobilePhone: 'Teléfono móvil',
      nif: 'NIF',
      zip: 'Código postal',
    },
    comment: {
      assistantComment: 'Comentario para el preparador',
      default: 'Ø',
      label: 'Observación',
      none: 'Ningún comentario',
      ownComment: 'Comenta pora ti mismo',
      patientComment: 'Comentario para el paciente',
    },
    confirmAddresses: 'confirmación de direcion',
    docSubtitle: 'Detalles del pedido',
    editAddresses: 'Editar direccion',
    formula: {
      formulaCount_one: '{{ count }} fórmula:',
      formulaCount_other: '{{ count }} fórmulas:',
      label: 'Fórmulas',
      name: 'apellido',
      pinYin: 'Pin Yin',
      priceHT: 'precio sin taxas',
      quantity: 'Cantidad',
      ref: 'Referencia',
      separate: 'Separado',
      total: 'Total',
      variation: 'Variación',
    },
    global: {
      label: 'Estatus general',
      status: {
        ALL: 'Todas las órdenes',
        CANCELLED: 'Cancelado',
        COMPLETE: 'Acabado',
        DRAFT: 'proyecto',
        ERROR_PACKING: 'Error',
        ERROR_SYNC_STATUS: 'Error',
        PENDING_PACKING: 'En la preparación de',
        PENDING_PAYMENT: 'pago pendiente',
        PENDING_SHIPMENT: 'En envío',
        VALIDATED: '',
        WAITING_PACKING: 'Esperando la preparación',
        WAITING_PAYMENT: 'A la espera del pago',
        WAITING_SHIPMENT: 'Esperando envío',
      },
    },
    information: {
      itemNumber: 'Articulo',
      label: 'Información',
      packaging: 'embalaje',
      paymentMode: 'Forma de pago',
      paymentStatus: 'Estado de pago',
      preparationStatus: 'Estado de preparación',
      shippingMethod: 'Tipo de envío',
      shippingPrice: 'Precio de envío',
      totalWeight: 'Peso total',
      weight: 'Peso',
    },
    mailReminder: {
      confirmError:
        'Se ha producido un problema al enviar el correo electrónico. Por favor, inténtelo de nuevo.',
      confirmSuccess:
        'El correo electrónico que contiene el enlace de pago ha sido enviado al cliente en la dirección de correo electrónico asociada a la dirección de entrega.',
      confirmText:
        '¿Está seguro de que quiere enviar por correo electrónico el enlace de pago al cliente?',
      confirmTitle: 'Reenviar el enlace de pago por correo electrónico',
    },
    mailTracking: {
      confirmError:
        'Se ha producido un problema al enviar el correo electrónico. Por favor, inténtelo de nuevo.',
      confirmSuccess:
        'El correo electrónico que contiene el enlace de seguimiento se ha enviado al cliente en la dirección de correo electrónico asociada a la dirección de entrega.',
      confirmText:
        '¿Está seguro de que quiere enviar por correo electrónico el enlace de seguimiento al cliente?',
      confirmTitle: 'Reenviar el enlace de seguimiento por correo electrónico',
    },
    openOrder: 'Abrir el pedido',
    order: {
      deliveryAddress: 'Dirección de entrega',
      finishPreparation: 'Termina la preparación y deja',
      finishShipping: 'Termine de enviar y dejar el envío',
      label: 'Pedido',
      newAddress: 'Nueva direccion',
      paymentAddress: 'Dirección de facturación',
      sameAddress: 'Dirección de facturación idéntica a la de entrega',
      status: {
        CANCELLED: 'Cancelado',
        COMPLETE: 'Acabado',
        DRAFT: 'Borrador',
        OPEN: 'Abrió',
      },
      validAddress: 'Por favor, introduzca una dirección válida',
    },
    packing: {
      label: 'Preparación',
      preparedAt: 'Preparado en',
      preparedBy: 'Preparado por',
      progress: 'En Progreso',
      status: {
        DELAYED: 'Demorado',
        PENDING: 'En curso',
        PREPARED: 'Preparado',
        READY: 'Esperando la preparación',
        WAITING: 'A la espera',
      },
      weight: {
        content: 'Contenido',
        error: 'El peso total debe ser mayor o igual al del contenido',
        packing: 'embalaje',
        total: 'Peso total del pedido',
      },
    },
    payment: {
      amount: 'Valor',
      commandPaymentTitle: 'Pedido n°{{ orderNumber }} pago',
      docSubtitle: 'lista de pagos',
      forms: {
        address: 'Dirección',
        bank: 'Banco',
        bankTransfer: 'Transferencia bancaria',
        bankTransferDescription:
          'Después de llevar a cabo la transferencia bancaria, adjunte la prueba de la transferencia (prueba editada por su banco) como archivo adjunto a través del archivo de elige un archivo',
        bankTransferInformationLabel:
          'Información sobre transferencias bancarias',
        card: 'Tarjeta de crédito',
        cardDescription: 'Pago de tarjeta bancaria: fácil y seguro',
        cardNumber: 'número de tarjeta',
        check: 'Cheque bancario',
        checkDescription:
          'Reservado solo para cheques franceses. Complete el siguiente formulario antes de enviar el cheque.',
        checkInformationLabel: 'verifique informaciones',
        checkNumber: 'Número de cheque',
        chooseFile: 'Escoge un archivo',
        cvv: 'CVV',
        expirationDate: 'Fecha de caducidad',
        firstName: 'Nombre de pila',
        lastName: 'apellido',
        nameOnCard: 'Nombre en la Tarjeta',
        order: 'Pedido',
        resume: 'Resumen',
        sendProof: '',
      },
      label: 'Pago',
      linkText: 'pagos',
      paidAt: 'Pagalo',
      pay: 'Paga',
      paymentFees: 'Taxa de pago',
      paymentMethod: 'Método de pago',
      simulate: 'Simular un pago',
      status: {
        BALANCE_DUE: 'De',
        CREDIT_OWED: 'Reembolso debido',
        DRAFT: 'proyecto',
        FAILED: 'Ha fallado',
        PAID: 'Pagado',
        PENDING: 'En curso',
        WAITING: 'En espera',
      },
    },
    reportError: 'Informar un error',
    selectPatient: 'Seleccione un paciente',
    sendMail: 'Enviar un email',
    shipment: {
      carrierLabel: {
        canNotGenerateLabel:
          'La generación automática de etiquetas no está disponible para este transporte',
        displayLabelNewTab: 'Abrir en una nueva ventana',
        generateLabel: 'Etiqueta del paquete',
        label: 'Etiqueta del paquete',
      },
      coordonates: 'Información del contacto',
      deliveryInfo: 'información de entrega',
      label: 'Expedicion',
      selectMethod: 'Seleccione un método de envio',
      sentAt: '',
      sentBy: 'Enviado por',
      shipmentMethod: 'Modo de entrega',
      shipmentMethodCost: 'Coste de envío',
      status: {
        BACKORDER: 'Envío retrasado',
        DELAYED: 'Demorado',
        PENDING: 'Envío en progreso',
        READY: 'Esperando envío',
        SHIPPED: 'Enviado',
        WAITING: 'En espera',
      },
      trackingNumber: 'Número de seguimiento',
      transporter: {
        label: 'Transporte',
        method: 'Modo de entrega',
        name: 'El nombre del transportador',
        price: 'Coste de envío',
      },
      weighing: 'Peso',
    },
    status: 'Estado',
    therapist: {
      dispenser: 'Preparador',
      label: 'Terapeuta',
      sender: 'Remitente',
    },
  },
  orders: {
    columns: {
      carrier: 'Transportador',
      customer: 'Cliente',
      customerPhone: 'Teléfono móvil del cliente',
      globalStatus: 'Estado',
      nbGroups: 'Fórmulas',
      nbItems: 'Líneas',
      nbOfBags: 'Saquetas',
      openedAt: 'Grabado',
      orderNumber: 'Número',
      orderStatus: 'Estado de pedido',
      packingStatus: 'Estado de preparación',
      paidAt: 'Pagado',
      patient: 'Paciente',
      paymentStatus: 'Estado de pago',
      prescriber: 'Prescribir',
      shipmentStatus: 'Estado del envío',
      shipping: 'Envio',
      shippingMethod: 'Método de envio',
      shippingPrice: 'Gastos de envío',
      totalGrossPrice: 'Total sin taxas',
      totalNetPrice: 'Precio total',
      totalNetPriceWT: 'Precio total',
      totalPrice: 'Total a pagar',
      variationTypes: 'Modelo',
    },
    docSubtitle: 'Lista de pedidos',
    linkText: 'Pedidos',
    orderResume: 'Resumen del pedido',
  },
  plant: {
    availableConcentratedPowder: 'todo',
    availablePlant: 'todo',
    chineseName: 'Nombre chino',
    commonName: 'Nombre común',
    concentratedPowder: 'Polvo concentrado',
    concentrationRate: 'Tasa de concentración',
    detailsDocSubtitle: 'Detalles de la planta',
    detailsLinkText: 'Detalles de la planta',
    detailsSuccessUpdate_one: 'Modificación registrada',
    detailsSuccessUpdate_other: 'Modificaciones registradas',
    dryPlant: 'Planta seca',
    latinName: 'Nombre latino',
    legacyId: 'Antigua identificación:',
    listTitle: 'Plantas',
    otherName: 'Otros nombres',
    pinYinName: 'Nombre de pinyin',
    plant: 'Planta',
    posology: 'Dosis',
    posologyMax: 'Dosis máxima',
    posologyMin: 'Mínimo',
    published: 'Publicado',
    refStock: 'Stock ref',
    refStockPlant: 'Planta stock ref',
    refStockPowder: 'CP stock ref',
    sellAvailable: 'todo',
    toxic: 'Tóxico',
    noToxic: 'No tóxico',
    unavailableConcentratedPowder: 'todo',
    unavailablePlant: 'todo',
  },
  posologies: {
    displayChip: {
      max: 'Hasta {{max}} g',
      min: 'De {{min}} g',
      minMax: 'Desde {{min}} g a {{max}} g',
    },
    max: 'Máximo',
    min: 'Mínimo',
    requirementsNotOK: 'Min debe ser menor o igual al máximo',
    title: 'Dosis',
  },
  prescription: {
    addPod: 'Añadir cuchara medidora',
    decoction: 'Decoción simplificada',
    docSubtitle: 'Prescripción',
    nbOfBags: 'Número de saquetas',
    nbOfCapsulesBatch:
      'Número de lotes de cápsulas (1 lote = 100 cápsulas = 50 g)',
    order: {
      label: 'Pedido',
    },
    pharmaceuticalForm: {
      capsule: 'Cápsula',
      label: 'Forma farmaceutica',
      small_bag: 'Saqueta',
    },
    plantPowderGroundSize: {
      big_ground: 'Molido gruesso',
      fine_ground: 'Molido fino',
      label: 'Acondicionamiento',
      whole: 'Toda la planta',
    },
    plantPowderType: {
      label: 'Tipo de sustancia',
      plant: 'Planta',
      powder: 'Polvo Concentrado',
    },
    pod: 'Cuchara Medidora',
    posology: {
      capsule: {
        plant: {
          resume_one:
            'Quiero {{nbOfCapsulesBatch}} lote de {{capsulesBatchSize}} cápsulas de {{capsuleWeight}} G de esta fórmula de planta seca',
          resume_other:
            'Quiero {{nbOfCapsulesBatch}} lotes de {{capsulesBatchSize}} cápsulas de {{capsuleWeight}} G de esta fórmula de planta seca',
          title: 'Dosis de Capsula',
        },
        powder: {
          resume_one:
            'Quiero {{nbOfCapsulesBatch}} lote de {{capsulesBatchSize}} Cápsulas de {{capsuleWeight}} G de esta fórmula en polvo concentrado',
          resume_other:
            'Quiero {{nbOfCapsulesBatch}} lotes de {{capsulesBatchSize}} Cápsulas de {{capsuleWeight}} G de esta fórmula en polvo concentrado',
          title: 'Dosis de cápsulas de CP',
        },
      },
      plant: {
        resume_one:
          'Quiero {{nbOfBags}} Sachet de {{portionWeight}} g de esta fórmula de planta seca ({{variation}})',
        resume_other:
          'Quiero {{nbOfBags}} bolsitas de {{portionWeight}} g de esta fórmula de planta seca ({{variation}})',
        title: 'Dosis de PS',
      },
      powder: {
        resume:
          'Quiero 1 Sachete de {{totalweight}} g de esta fórmula de polvo concentrada',
        title: 'Dosis de CP',
      },
      shortenDescription: {
        capsule: {
          resume_one:
            '{{nbOfCapsulesBatch}} Lot {{capsulesBatchSize}} {{abbreviation}}',
          resume_other:
            '{{nbOfCapsulesBatch}} Lotes {{capsulesBatchSize}} {{abbreviation}}',
        },
        plant: {
          resume_one:
            '{{nbOfBags}} saqueta {{portionWeight}}g {{abbreviation}}',
          resume_other:
            '{{nbOfBags}} saquetas {{portionWeight}}g {{abbreviation}}',
        },
        powder: {
          resume: '1 saqueta de {{totalWeight}}g {{abbreviation}}',
        },
      },
    },
    quantity: {
      default: 'Peso total (en gramos)',
      portion: 'Peso por saqueta (em gramas)',
      total: 'Peso total (en gramos)',
    },
    reasonCantGoToCommentSection: {
      noCustomer: 'todo',
      noFormulas: 'todo',
      notAllItemsAvailable: 'todo',
      unknown: 'todo',
    },
    reasonCantValidateOrder: {
      invalidAddress: 'todo',
      loyaltyProgramError: 'todo',
      noShippingMethod: 'todo',
      notAllItemsAvailable: 'todo',
      unknown: 'todo',
    },
    resume: {
      cartGrossTotal: 'Coste de la cesta de la compra sin impuestos ',
      cartNetTotal: 'Coste de la cesta de la compra con impuestos incluidos',
      deliveryCost: 'Gastos de envío',
      loyaltyOption: {
        label: 'todo',
        addPoints: 'todo',
        pay10Percent: 'todo',
        pay15Percent: 'todo',
        pay20Percent: 'todo',
        pay5Percent: 'todo',
      },
      missingCostsExplanation:
        'Los impuestos y los gastos de envío se calcularán en la fase de entrega',
      totalToPay: 'total a pagar',
    },
    weightMode: {
      label: 'Peso equivalente a?',
      portion: 'Peso por porción',
      total: 'Peso total',
    },
    withoutPod: 'Sin cuchara medidora',
    withPod: 'Con cuchara medidora',
  },
  previous: 'Anterior',
  productMetadatas: {
    action: {
      cardTitle: 'Comportamiento',
      groupByLabel: 'Valores',
    },
    categoryFormula: {
      cardTitle: 'Categorías',
      groupByLabel: 'Categoría',
    },
    categoryFormulaJingFang: {
      cardTitle: 'Categorías Jing Fang',
      groupByLabel: 'Categoría Jing Fang',
    },
    categoryFormulaXiaoFang: {
      cardTitle: 'Categorías Xiao Fang',
      groupByLabel: 'Categoría Xiao Fang',
    },
    categoryPlant: {
      cardTitle: 'Categorías',
      groupByLabel: 'Categoría',
    },
    composition: 'Composición',
    docSubtitle: 'Metadatos de productos',
    linkText: 'Metadatos',
    meridian: {
      cardTitle: 'Meridianos',
      groupByLabel: 'Meridiano',
    },
    name: 'Nombre {{lngcode}}',
    nature: {
      cardTitle: 'Hilas',
      groupByLabel: 'Naturaleza',
    },
    plant: {
      cardTitle: 'Plantas',
      groupByLabel: 'Planta',
    },
    productTypes: {
      formula: 'Fórmulas',
      plant: 'Plantas',
    },
    productVariationType: {
      cardTitle: 'Variaciones',
      docSubtitle: 'Variaciones',
      groupByLabel: 'Variación',
      linkText: 'Variaciones',
      price: 'Precio sin taxas',
      shortcode: 'Abreviatura',
    },
    savour: {
      cardTitle: 'Sabores',
      groupByLabel: 'Sabor',
    },
    sellAvailable: 'No disponible para venta',
    syndrom: {
      cardTitle: 'Síndromes',
      groupByLabel: 'Síndrome',
    },
  },
  productVariationTypes: {
    title: 'Variaciones',
  },
  radioButton: {
    addFormula: 'Agregar a una fórmula existente',
    newFormula: 'Crear una nueva fórmula',
    placeholderNF: 'Nombre de fórmula',
  },
  renewOrder: '',
  search: {
    none: 'ningún resultado',
    search: 'Investigar',
  },
  sendVerifToUsePharmaco:
    'Para poder realizar pedidos, debe enviar la prueba de formación a la siguiente dirección de correo electrónico: contact@yao-dao.com',
  shippingMethodPrices: {
    countryZone: 'Área del país',
    docSubtitle: 'Lista de precios de los métodos de entrega',
    label: {
      en: 'Nombre EN',
      es: 'Nombre ES',
      fr: 'Nombre FR',
      pt: 'Nombre PT',
    },
    linkText: 'Precio de los métodos de entrega',
    maxWeight: 'Peso máximo',
    minWeight: 'Peso mínimo',
    price: 'Precio',
    shippingMethod: 'Método de entrega',
  },
  shippingMethods: {
    docSubtitle: 'Lista de métodos de entrega',
    label: {
      active: 'Activo',
      carrier: 'Transportador',
      displayOrder: 'Orden de presentación',
      name: {
        en: 'Nombre EN',
        es: 'Nombre ES',
        fr: 'Nombre FR',
        pt: 'Nombre PT',
      },
      pricesLength: 'Número de precios asociados',
      productCode: 'Código del producto',
    },
    linkText: 'métodos de entrega',
  },
  shop: {
    title: 'Tienda',
  },
  total: 'Total',
  validate: 'Validar',
  variations: {
    available: 'todo',
    basePrice: 'Precio inicial',
    name: 'apellido',
    price: 'Precio sin taxas',
    productRef: 'Referencia del producto',
    title: 'Variaciones',
    unavailable: 'todo',
  },
  wholesalerSettings: {
    linkText: 'Configuraciones',
  },
};
