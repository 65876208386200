import * as React from 'react';
import { Typography } from '@material-ui/core';
import { Payment } from '../../../core/entities/Payment';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DatatablesController } from '@kiway/datatables';
import { useTranslation } from '@kiway/shared/utils/translation';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

export interface ListPaymentsProps {
  payments: Payment[];
  handleOpen: (value: Payment) => void;
  handleOrderNumberClick: (orderId: string) => void;
}

export function ListPayments(
  props: React.PropsWithChildren<ListPaymentsProps>
) {
  const { t } = useTranslation();

  const returnIcon = (index) => {
    switch (props.payments[index].getStatus()) {
      case 'PAID':
        return <RemoveRedEyeIcon />;
      case 'DRAFT':
        return null;
      default:
        return <EditIcon />;
    }
  };

  return (
    <div>
      <DatatablesController
        columns={[
          {
            name: '_id',
            label: 'ID',
            type: 'text',
            options: {
              display: false,
              filter: false,
            },
          },
          {
            name: 'order',
            label: t(`pharmaco:order.commandDetails.command`),
            type: 'text',
            options: {
              customBodyRender: (value) => {
                return value?.orderNumber;
              },
              onCellClick: (_, other) => {
                props.handleOrderNumberClick(
                  props.payments?.[other.dataIndex]?.getOrder()?.getId()
                );
              },
              sortCompare: (order) => ({ data: a }, { data: b }) => {
                if (order === 'none') {
                  return 0;
                }
                if (!a) {
                  return order === 'asc' ? -1 : 1;
                }
                if (!b) {
                  return order === 'asc' ? 1 : -1;
                }
                const aNumber = parseInt(a?.orderNumber ?? '-1', 10);
                const bNumber = parseInt(b?.orderNumber ?? '-1', 10);
                const result =
                  aNumber > bNumber ? 1 : aNumber < bNumber ? -1 : 0;
                return order === 'asc' ? result : result * -1;
              },
            },
          },
          {
            name: 'amount',
            label: t(`pharmaco:orderDetails.payment.amount`),
            type: 'price',
            options: {
              priceDisableCentDecimals: true,
            },
          },
          {
            name: 'paymentMethod',
            label: t(`pharmaco:orderDetails.payment.paymentMethod`),
            type: 'text',
          },
          {
            name: 'customer',
            label: t('pharmaco:orders.columns.customer'),
            type: 'text',
          },
          {
            name: 'customerPhone',
            label: t('pharmaco:orders.columns.customerPhone'),
            type: 'text',
          },
          {
            name: 'status',
            label: t(`pharmaco:orders.columns.paymentStatus`),
            type: 'status-borderless',
          },
          {
            name: 'completedAt',
            label: t(`pharmaco:orderDetails.payment.paidAt`),
            type: 'simple-date',
          },
          {
            name: '',
            label: '',
            type: 'standard',
            options: {
              customBodyRenderLite: (dataIndex) => (
                <IconButton
                  size="small"
                  sx={{ p: 0, ml: 1 }}
                  onClick={() => {
                    props.handleOpen(props.payments?.[dataIndex]);
                  }}
                >
                  {returnIcon(dataIndex)}
                </IconButton>
              ),
              display: true,
              sort: false,
              filter: false,
              print: false,
              searchable: false,
              download: false,
              draggable: false,
              viewColumns: false,
              empty: true,
              forceView: true,
            },
          },
        ]}
        data={props.payments
          ?.map((payment) => payment.toDatatableRow())
          ?.map(({ paymentMethod, status, ...rest }) => ({
            ...rest,
            status: {
              ...status,
              label: `pharmaco:orderDetails.payment.status.${status.label}`,
            },
            paymentMethod: t(
              `pharmaco:orderDetails.payment.forms.${paymentMethod}`
            ),
          }))}
        defaultSortOrder={{ name: 'order', direction: 'desc' }}
        id="listPayments"
        options={{
          download: false,
          filter: false,
          print: false,
        }}
        title={t(`pharmaco:orderDetails.payment.linkText`)}
      />
      {props.children}
    </div>
  );
}
