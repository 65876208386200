import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface LastLegacyOrderProps {
  lastOrder: any;
}

export const LastLegacyOrder = (props: LastLegacyOrderProps) => {
  const { t } = useTranslation();

  return props.lastOrder ? (
    <Paper style={{ padding: '10px 20px' }}>
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h6">{t('file:last.legacy.order')}</Typography>
        </Grid>
        <Grid item xs={2}>
          {moment(props.lastOrder?.date).format('DD/MM/YYYY')}
        </Grid>
      </Grid>
      <Divider style={{ margin: '8px 0' }} />

      <Grid container>
        {props.lastOrder?.formulas.map((formula) => (
          <Grid
            container
            key={formula.name}
            alignItems="center"
            style={{ margin: '5px' }}
          >
            <Grid item xs={4} textAlign="start" sx={{ p: '5px 0' }}>
              <Typography>{formula.name}</Typography>
              <Typography color="text.secondary" fontSize={14}>
                {formula.variationType}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{formula.composition}</Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid container textAlign="start">
        <Grid item xs={12}>
          <Typography>
            <b>Commentaire praticien</b> :{' '}
            {props.lastOrder?.practitionerComment}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <b>Commentaire patient</b> : {props.lastOrder?.patientComment}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <b>Commentaire préparateur</b> : {props.lastOrder?.preparerComment}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  ) : null;
};
