export default {
  asterisks: {
    onReferralFirstPurchase: 'Quando o seu afilhado compra pela primeira vez',
    onReferralPurchases: 'Em todas as compras do seu afilhado',
    shareLinkExplanation:
      'Copie diretamente o link clicando nele. Os seus afilhados devem inscrever-se através deste link para que o apadrinhamento funcione',
  },
  buttons: {
    invite: 'Convidar',
  },
  dataTable: {
    columns: {
      lastUpdate: 'Última atualização',
      referral: 'Afilhado',
      rewardAmount: 'Valor oferecido',
      rewardedBy: 'Recompensado por',
      rewardType: 'Tipo de recompensa',
      status: 'Status',
      subType: 'Tipo de inscrição',
    },
    enums: {
      rewardType: {
        balance: 'Créditos',
        cashback: 'Devolução do Dinheiro ',
        monthFree: '1 mês grátis',
      },
      status: {
        completed: 'Concluído',
        paid: 'Pago',
        pending: 'Em andamento',
        sent: 'Enviado',
      },
    },
    yourReferrer: 'O seu padrinho',
  },
  errors: {
    global:
      'Ocorreu um erro, obrigado por tentar novamente ou entre em contato conosco',
    noFullName: 'O seu nome e apelido devem ser inseridos para enviar convites',
    noReferrerCode:
      'A sua conta não possui um código de patrocínio, entre em contato connosco através do chatbox ou tente reconectar',
    noSub: 'Você deve ter uma assinatura ativa para poder apadrinhar',
  },
  inviteSuccess: '%X% profissional convidado (s)',
  text: {
    affiliationInstructions: 'Para apadrinhar, 2 soluções:',
    caption: 'Você recomenda-nos, nós recompensamos!',
    costPerAcquisitionAmount: '%X%€ oferta',
    forYou: 'para si',
    forYourReferral: 'Para o seu afilhado',
    noSub: 'Para apadrinhar, primeiro deve estar inscrito',
    oneMonthFree: '5 € oferta',
    revenueShareAmount: '%X%% oferta',
    sendMail: 'Envie convites diretamente por e -mail',
    shareLink: 'Compartilhe seu link de Apadrinhamento',
  },
  titles: {
    affiliations: 'Apadrinhamento',
    myReferrals: 'Os meus afilhados',
    myRewards: 'Os Meus prémios',
    referrerCode: 'O meu código de apadrinhamento',
  },
};
