import * as React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
  label: {
    fontWeight: 'bold',
  },
});

interface ProductDetailsFieldProps {
  handleChange: (value: string | number | boolean) => void;
  handleSave: (id?: string) => void;
  inputType: 'text' | 'number' | 'checkbox';
  label: string;
  loading?: boolean;
  style?: any;
  value: string | number | boolean;
  fullWidth?: boolean;
}

export function ProductDetailsField({
  fullWidth,
  handleChange,
  handleSave,
  inputType,
  label,
  loading,
  style,
  value,
}: React.PropsWithChildren<ProductDetailsFieldProps>) {
  const classes = useStyles();

  return (
    <Grid container alignItems={'center'}>
      <Grid item xs={12} md={fullWidth ? 12 : 4}>
        <Typography className={classes.label}>{label}</Typography>
      </Grid>
      <Grid item xs={12} md={fullWidth ? 12 : 8}>
        {loading ? (
          <Typography>
            <Skeleton />
          </Typography>
        ) : (
          <TextField
            type={inputType === 'number' ? 'number' : 'text'}
            fullWidth
            value={value || ''}
            onChange={(event) => {
              handleChange(event.target.value);
            }}
            inputProps={{ style }}
            onBlur={(event) => {
              handleSave();
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
