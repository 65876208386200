import React, { useState } from 'react'
import LightTheme from '../../themes/LightTheme'
import DarkTheme from '../../themes/DarkTheme'

const ThemeContext = React.createContext()

function ThemeContextProvider(props) {
  const [themeName, setThemeName] = useState('light')
  const [themeContent, setThemeContent] = useState(LightTheme)

  const { children } = props

  const toggleTheme = () => {
    setThemeName((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'))
    setThemeContent((prevTheme) =>
      prevTheme === LightTheme ? DarkTheme : LightTheme,
    )
  }

  return (
    <ThemeContext.Provider value={{ themeName, themeContent, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeContextProvider, ThemeContext }
