import * as React from 'react';
import { FileUploader } from './FileUploader';
import axios from 'axios';
import { useStoredFilesGateway } from '../../../entrypoints/react/useStoredFilesGateway';
import { useSnackbar } from '@kiway/shared/utils/snackbar';
import { IStoredFileAttributes } from '../../../core/entities/StoredFile';

export interface FileUploaderControllerProps {
  accept?: string;
  bucketName?: string;
  buttonText?: string;
  directory?: string;
  disabled?: boolean;
  files?: IStoredFileAttributes[];
  multiple?: boolean;
  nbMaxFiles?: number;
  handleFileUploaded?: (filesUploaded: any[]) => any;
  handleFileDeleted?: (isDeleted?: boolean) => void;
}

export function FileUploaderController(props: FileUploaderControllerProps) {
  const [files, setFiles] = React.useState<IStoredFileAttributes[]>(
    props.files ?? []
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  const { deleteOneStoredFile } = useStoredFilesGateway();

  const handleChangeFile = (e) => {
    const formData = new FormData();
    Array.from(e.target.files).forEach((file: any) => {
      formData.append('upload', file);
    });
    setLoading(true);
    axios
      .post(
        `https://${
          process.env.NX_API_MARKETPLACE_URL
        }/storage/upload?directory=${props.directory.replace(/\/+$/, '')}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
        }
      )
      .then((result) => {
        setFiles(result.data?.filesUploaded);
        if (props.handleFileUploaded) {
          props.handleFileUploaded(result.data?.filesUploaded);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleRemoveFile = (id) => {
    deleteOneStoredFile(id).then((result) => {
      if (result) {
        setFiles((prev) => prev.filter((file: any) => file.id !== id));
      }
      if (props.handleFileDeleted) {
        props.handleFileDeleted(result);
      }
    });
  };

  return (
    <FileUploader
      accept={props.accept}
      buttonText={props.buttonText}
      disabled={props.disabled}
      handleChangeFile={handleChangeFile}
      handleRemoveFile={handleRemoveFile}
      loading={loading}
      multiple={props.multiple}
      nbMaxFiles={props.multiple ? props.nbMaxFiles : 1}
      selectedFiles={files}
    />
  );
}
