import * as React from 'react';
import { User } from '../../../core/entities/User';
import { useUsersGateway } from '../../../entrypoints/react/useUsersGateway';
import { ListUsers } from './ListUsers';

export function ListUsersController() {
  const [loading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState<User[]>([]);
  const { findAll } = useUsersGateway();

  React.useEffect(() => {
    setLoading(true);
    findAll()
      .then(async (users) => {
        setUsers(users);
      })
      .finally(() => setLoading(false));
    return () => {
      setUsers([]);
    };
  }, []);

  return <ListUsers loading={loading} users={users} />;
}
