import React from 'react';
import { TextField, Grid, InputAdornment, IconButton } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import CloseIcon from '@mui/icons-material/Close';

export interface SearchBarProps {
  search: string;
  setSearch: any;
}

export function SearchBar(props: SearchBarProps) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={11} sm={8} md={6}>
        <TextField
          fullWidth
          value={props.search}
          onChange={(e) => props.setSearch(e.target?.value ?? '')}
          placeholder={t('pharmaco:search.search')}
          InputProps={{
            endAdornment:
              props.search !== '' ? (
                <InputAdornment position="end">
                  <IconButton onClick={() => props.setSearch('')}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
        />
      </Grid>
    </Grid>
  );
}
