import { ProductMetadata } from '../entities/ProductMetadata';
import { ProductMetadataProvider } from './ProductMetadataProvider';

export class GetAllProductMetadatas {
  private productMetadataProvider: ProductMetadataProvider;

  public constructor(productMetadataProvider: ProductMetadataProvider) {
    this.productMetadataProvider = productMetadataProvider;
  }

  public execute(): Promise<ProductMetadata[]> {
    return this.productMetadataProvider.findAll();
  }
}
