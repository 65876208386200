import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import Skeleton from '@material-ui/lab/Skeleton'
import React, { useEffect, useReducer } from 'react'
import { getPractitionerConsultationPlaces } from '../../services/consultationPlace/consultationPlaceService'
import { useTranslation } from '@kiway/shared/utils/translation'
import SearchBox from '../SearchBox'

const initState = {
  loading: false,
  consultationPlaces: [],
  consultationPlaceSelected: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        loading: true,
      }
    case 'FETCH_COMPLETE':
      return {
        ...state,
        loading: false,
        consultationPlaces: action.payload,
      }
    case 'SELECT_CHANGE':
      return {
        ...state,
        consultationPlaceSelected: action.payload,
      }
    default:
      throw new Error('Unhandled action type')
  }
}

export function useConsultationPlacePicker(
  practitionerId,
  publicPage = false,
  preSelected,
  isRequired = false,
) {
  const [state, dispatch] = useReducer(reducer, initState, () => initState)
  const component = (
    <ConsultationPlacePicker
      dispatch={dispatch}
      loading={state.loading}
      practitionerId={practitionerId}
      state={state}
      publicPage={publicPage}
      isRequired={isRequired}
    />
  )

  useEffect(() => {
    dispatch({
      type: 'SELECT_CHANGE',
      payload: preSelected,
    })
  }, [preSelected])

  return [state.consultationPlaceSelected, component, state.loading]
}

function ConsultationPlacePicker(props) {
  const {
    dispatch,
    loading,
    practitionerId,
    publicPage,
    state,
    isRequired,
  } = props
  const { t } = useTranslation()

  useEffect(() => {
    dispatch({ type: 'FETCH_INIT' })
    getPractitionerConsultationPlaces(practitionerId, publicPage).then(
      (data) => {
        if (data) {
          dispatch({ type: 'FETCH_COMPLETE', payload: data })
        } else {
          dispatch({ type: 'FETCH_COMPLETE' })
        }
      },
    )
  }, [practitionerId])

  return loading ? (
    <Skeleton animation="wave" variant="rect" height={56} width="100%" />
  ) : (state.consultationPlaces.length > 0 || !publicPage) ? (
    <SearchBox
      width={'100%'}
      data={
        state.consultationPlaces &&
        state.consultationPlaces.sort((a, b) => a.name > b.name)
      }
      label={t('common:form.fields.consultationPlace.label')}
      value={state.consultationPlaceSelected}
      setValue={(value) => dispatch({ type: 'SELECT_CHANGE', payload: value })}
      getString={(value) =>
        value ? `${value.name} - ${value.address.description}` : ''
      }
      inputProps={{
        required: isRequired,
      }}
      renderOption={(option) => (
        <Grid container alignItems="center" style={{ width: '100%' }}>
          <Grid item xs>
            <span>{option.name}</span>
            <Typography variant="body2" color="textSecondary">
              {option.address.description}
            </Typography>
          </Grid>
        </Grid>
      )}
    />
  ) : (
    <Alert severity="info">{t('publicPage:noOnlineBooking')}</Alert>
  )
}

export default ConsultationPlacePicker
