import * as React from 'react';
import {
  Plant,
  PlantArrays,
  PlantMetadataSearchReturn,
} from '../../../core/entities/Plant';
import { PlantDetailsHeader } from './PlantDetailsHeader';
import { SearchProductMetadataAutocompleteController } from '../shared';
import { PlantDetailsVariations } from './PlantDetailsVariations';
import { Grid, Button } from '@material-ui/core';
import { ProductDetailsMetadata } from '../shared';
import { useTranslation } from '@kiway/shared/utils/translation';
import { links } from '../../../config';
import { Link } from 'react-router-dom';
import {
  ProductMetadata,
  plantMetadataTypes,
} from '../../../core/entities/ProductMetadata';

export interface PlantVariationsPrices {
  [id: string]: string | number;
}

export interface PlantDetailsProps {
  handleAdd: (type: PlantArrays, value: ProductMetadata) => any;
  handleChange: (type: string) => (value: string | number | boolean) => any;
  handleChangeVariations: (id: string, event: React.ChangeEvent) => any;
  handleDelete: (type: string) => (id: string) => any;
  handleSave: (type: string) => (id?: string) => any;
  loading?: boolean;
  loadingType?: string;
  plant: Plant;
  searchCallback: (value: string) => Promise<PlantMetadataSearchReturn>;
}

const getMetadataName = (element) => element.getName();

export function PlantDetails({
  handleAdd,
  handleChange,
  handleChangeVariations,
  handleDelete,
  handleSave,
  loading,
  loadingType,
  plant,
  searchCallback,
}: React.PropsWithChildren<PlantDetailsProps>) {
  const { t } = useTranslation();
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            size={'small'}
            component={Link}
            to={`/${links.main}/${links.plants}`}
          >
            {t('pharmaco:backToList')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <PlantDetailsHeader
            handleChange={handleChange}
            handleSave={handleSave}
            loading={
              loading && (loadingType === 'details' || loadingType === null)
            }
            plant={plant}
          />
        </Grid>
        <Grid item xs={12}>
          <SearchProductMetadataAutocompleteController
            handleSelect={handleAdd}
            product={plant}
            searchCallback={searchCallback}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PlantDetailsVariations
                handleChange={handleChangeVariations}
                handleDelete={handleDelete('productVariationType')}
                handleSave={handleSave('variations')}
                loading={
                  loading &&
                  (loadingType === 'variations' || loadingType === null)
                }
                variations={plant?.getVariations()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {plantMetadataTypes.map((productMetadataType) => (
              <Grid item xs={12} sm={6} md={4} key={productMetadataType}>
                <ProductDetailsMetadata
                  data={plant
                    ?.getMetadatas()
                    ?.filter(
                      ProductMetadata.filterByMetadataType(productMetadataType)
                    )}
                  getLabel={getMetadataName}
                  handleDelete={handleDelete(productMetadataType)}
                  loading={
                    loading &&
                    (loadingType === productMetadataType ||
                      loadingType === null)
                  }
                  title={t(
                    `pharmaco:productMetadatas.${productMetadataType}.cardTitle`
                  )}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
