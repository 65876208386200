import { TranslatableAttribute } from '@kiway/shared/types';
import { TypographyVariant } from '@material-ui/core';

/**
 * Describe allowed/implemented notifier types
 */
// export type NotifierType =
//   | 'email'
//   | 'push'
//   | 'sms';
// export enum NotifierTypeEnum {
//   'email' = 'email',
//   'push' = 'push',
//   'sms' = 'sms',
// }
// export type NotifierTypeTest = Array<NotifierTypeEnum>
export const notifierTypes = ['email', 'push'] as const;
export type NotifierType = typeof notifierTypes[number];

/**
 * Common notifier setting line params between the props and the {@link NotifierSettingsProps} `lines` prop
 */
export type NotifierSettingLineParams = {
  /**
   * An array of {@link NotifierType| NotifierType} to setup what is beeing displayed
   */
  availableTypes: NotifierType[];
  /**
   * The label of the notifier setting line
   */
  label: TranslatableAttribute;
  /**
   * The description of the notifier setting line
   */
  description?: TranslatableAttribute;
  slug: string;
};

export type NotifierValuesProp = {
  [name: string]: boolean;
};

export type ObjNotifierValuesProp = {
  [index: number]: NotifierValuesProp;
};

/**
 * Function to handle change of one checkbox in one line
 *
 * @param type - {@link NotifierType} - The type of the notifier's checkbox
 * @param checked - {@link Boolean boolean} - The event target is checked or not
 *
 * @returns void
 */
export type NotifierHandleChangeFn = (
  type: NotifierType
) => (checked: boolean) => void;

export type NotifierSettingLineProps = NotifierSettingLineParams & {
  /**
   * Function to handle change of one checkbox in one line
   *
   * @param type - {@link NotifierType} - The type of the notifier's checkbox
   * @param checked - {@link Boolean boolean} - The event target is checked or not
   *
   * @returns void
   */
  handleChange: NotifierHandleChangeFn;
  /**
   * Material-UI {@see Typography Typovariant} for the label of the notifier setting line
   */
  labelVariant?: TypographyVariant;
  /**
   * Values to initialize the notifier setting line's checkboxes
   */
  values: NotifierValuesProp;
};

/**
 * @type
 */
export type NotifierSettingsProps = {
  headerVariant?: TypographyVariant;
};
