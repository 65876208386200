import * as React from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DatatablesController } from '@kiway/datatables';
import { Order } from '@kiway/ecommerce-react-compatible';
import { flatten } from '@kiway/shared/utils/string';
import { Button, Grid } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';

export interface ListOrdersProps {
  clientSide?: boolean;
  handleNewClick?: () => void;
  handleRowClick?: (rowIndex: number) => void;
  handleSave?: any;
  loading?: boolean;
  orders: Order[];
}

export function ListOrders(props: ListOrdersProps) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DatatablesController
          bulkEdit={false}
          columns={[
            {
              name: 'orderNumber',
              label: 'Numéro',
              type: 'text',
            },
            {
              name: 'openedAt',
              label: 'Date validation',
              type: 'simple-date',
            },
            {
              name: 'paidAt',
              label: 'Date paiement',
              type: 'simple-date',
            },
            {
              name: props.clientSide ? 'custom' : 'customer',
              label: props.clientSide ? 'Patient' : 'Client',
              type: 'text',
              options: {
                customBodyRender: (value) => {
                  return props.clientSide
                    ? `${value?.patient?.firstName} ${value?.patient?.lastName}`
                    : value;
                },
              },
            },
            {
              name: 'nbOfBags',
              label: 'Nb of bags',
              type: 'standard',
            },
            {
              name: 'nbItems',
              label: 'Nb of lines',
              type: 'standard',
            },
            {
              name: 'nbGroups',
              label: 'Nb of formulas',
              type: 'standard',
            },
            {
              name: 'status',
              label: 'Statut',
              type: 'status',
            },
            {
              name: 'orderStatus',
              label: 'Statut commande',
              type: 'status',
              options: {
                display: false,
              },
            },
            {
              name: 'paymentStatus',
              label: 'Statut paiement',
              type: 'status',
              options: {
                display: false,
              },
            },
            {
              name: 'packingStatus',
              label: 'Statut préparation',
              type: 'status',
              options: {
                display: false,
              },
            },
            {
              name: 'shipmentStatus',
              label: 'Statut expedition',
              type: 'status',
              options: {
                display: false,
              },
            },
            {
              name: 'shippingMethod.carrier.name',
              label: 'Transporteur',
              type: 'text',
              options: {
                display: false,
              },
            },
            {
              name: 'shippingMethod.name.fr',
              label: 'Méthode de livraison',
              type: 'text',
              options: {
                display: false,
              },
            },
            {
              name: 'shippingMethod.price',
              label: 'Coût livraison',
              type: 'text',
              options: {
                display: false,
              },
            },
            {
              name: 'totalGrossPrice',
              label: 'Total HT',
              type: 'text',
              options: {
                display: false,
              },
            },
            {
              name: 'totalNetPrice',
              label: 'Total TTC',
              type: 'text',
              options: {
                display: false,
              },
            },
            {
              name: 'totalPrice',
              label: 'Total',
              type: 'text',
            },
            // {
            //   name: 'shippingTrackingNumber',
            //   label: 'Suivi',
            //   type: 'text',
            // },
          ]}
          data={props.orders
            ?.map((item) => item.toDatatableRow())
            ?.map(
              ({
                orderStatus,
                paymentStatus,
                packingStatus,
                shipmentStatus,
                shippingMethod,
                status,
                ...order
              }) => ({
                ...order,
                ...flatten({ shippingMethod }),
                status: {
                  ...status,
                  label: `pharmaco:orderDetails.global.status.${status.label}`,
                },
                orderStatus: {
                  ...orderStatus,
                  label: `pharmaco:orderDetails.order.status.${orderStatus.label}`,
                },
                paymentStatus: {
                  ...paymentStatus,
                  label: `pharmaco:orderDetails.payment.status.${paymentStatus.label}`,
                },
                packingStatus: {
                  ...packingStatus,
                  label: `pharmaco:orderDetails.packing.status.${packingStatus.label}`,
                },
                shipmentStatus: {
                  ...shipmentStatus,
                  label: `pharmaco:orderDetails.shipment.status.${shipmentStatus.label}`,
                },
              })
            )}
          defaultSortOrder={{ name: 'orderNumber', direction: 'desc' }}
          handleRowClick={props.handleRowClick}
          handleSaveData={props.handleSave}
          id={'listOrders'}
          loading={false}
          refreshData={() => console.log}
          title={t('pharmaco:orders.docSubtitle')}
          options={{
            filter: false,
          }}
        />
      </Grid>
      {props.clientSide && (
        <Grid item xs={12} textAlign="right">
          <Button
            variant="contained"
            color="minoritary"
            onClick={props.handleNewClick}
          >
            {t('pharmaco:order.newCommand')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
