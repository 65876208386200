import * as React from 'react';
import { useState } from 'react';
import i18n from 'i18next';
import { FormControl, MenuItem, Select, Theme } from '@mui/material';
import {
  initReactI18next,
  useTranslation as useReactTranslation,
} from 'react-i18next';
import AffiliationFr from './translations/affiliation/affiliation.fr';
import AffiliationEn from './translations/affiliation/affiliation.en';
import AffiliationPt from './translations/affiliation/affiliation.pt';
import AffiliationEs from './translations/affiliation/affiliation.es';
import AgendaEn from './translations/agenda/agenda.en';
import AgendaFr from './translations/agenda/agenda.fr';
import AgendaPt from './translations/agenda/agenda.pt';
import AgendaEs from './translations/agenda/agenda.es';
import AdminEn from './translations/admin/admin.en';
import AdminFr from './translations/admin/admin.fr';
import AdminPt from './translations/admin/admin.pt';
import AdminEs from './translations/admin/admin.es';
import CommonEn from './translations/common/common.en';
import CommonFr from './translations/common/common.fr';
import CommonPt from './translations/common/common.pt';
import CommonEs from './translations/common/common.es';
import DatesEn from './translations/dates/dates.en';
import DatesFr from './translations/dates/dates.fr';
import DatesPt from './translations/dates/dates.pt';
import DatesEs from './translations/dates/dates.es';
import LandingEn from './translations/landing/landing.en';
import LandingFr from './translations/landing/landing.fr';
import MenuEn from './translations/menu/menu.en';
import MenuFr from './translations/menu/menu.fr';
import MenuPt from './translations/menu/menu.pt';
import MenuEs from './translations/menu/menu.es';
import UserEn from './translations/user/user.en';
import UserFr from './translations/user/user.fr';
import UserPt from './translations/user/user.pt';
import UserEs from './translations/user/user.es';
import ValidationEn from './translations/validation/validation.en';
import ValidationFr from './translations/validation/validation.fr';
import ValidationPt from './translations/validation/validation.pt';
import ValidationEs from './translations/validation/validation.es';
import FileEn from './translations/file/file.en';
import FileFr from './translations/file/file.fr';
import FilePt from './translations/file/file.pt';
import FileEs from './translations/file/file.es';
import SessionEn from './translations/session/session.en';
import SessionFr from './translations/session/session.fr';
import SessionPt from './translations/session/session.pt';
import SessionEs from './translations/session/session.es';
import DashboardEn from './translations/dashboard/dashboard.en';
import DashboardFr from './translations/dashboard/dashboard.fr';
import DashboardPt from './translations/dashboard/dashboard.pt';
import DashboardEs from './translations/dashboard/dashboard.es';
import DatatableEn from './translations/datatable/datatable.en';
import DatatableFr from './translations/datatable/datatable.fr';
import DatatablePt from './translations/datatable/datatable.pt';
import DatatableEs from './translations/datatable/datatable.es';
import NotificationEn from './translations/notification/notification.en';
import NotificationFr from './translations/notification/notification.fr';
import NotificationPt from './translations/notification/notification.pt';
import NotificationEs from './translations/notification/notification.es';
import PatientEn from './translations/patient/patient.en';
import PatientFr from './translations/patient/patient.fr';
import PatientPt from './translations/patient/patient.pt';
import PatientEs from './translations/patient/patient.es';
import ProSettingsEn from './translations/proSettings/proSettings.en';
import ProSettingsFr from './translations/proSettings/proSettings.fr';
import ProSettingsPt from './translations/proSettings/proSettings.pt';
import ProSettingsEs from './translations/proSettings/proSettings.es';
import PublicPageEn from './translations/publicPage/publicPage.en';
import PublicPageFr from './translations/publicPage/publicPage.fr';
import PublicPagePt from './translations/publicPage/publicPage.pt';
import PublicPageEs from './translations/publicPage/publicPage.es';
import WeekTemplateEn from './translations/weekTemplate/weekTemplate.en';
import WeekTemplateFr from './translations/weekTemplate/weekTemplate.fr';
import WeekTemplatePt from './translations/weekTemplate/weekTemplate.pt';
import WeekTemplateEs from './translations/weekTemplate/weekTemplate.es';
import OnlineBookingEn from './translations/onlineBooking/onlineBooking.en';
import OnlineBookingFr from './translations/onlineBooking/onlineBooking.fr';
import OnlineBookingPt from './translations/onlineBooking/onlineBooking.pt';
import OnlineBookingEs from './translations/onlineBooking/onlineBooking.es';
import SubscriptionFr from './translations/subscription/subscription.fr';
import SubscriptionEn from './translations/subscription/subscription.en';
import SubscriptionPt from './translations/subscription/subscription.pt';
import SubscriptionEs from './translations/subscription/subscription.es';
import InvoicingFr from './translations/invoicing/invoicing.fr';
import InvoicingEn from './translations/invoicing/invoicing.en';
import InvoicingPt from './translations/invoicing/invoicing.pt';
import InvoicingEs from './translations/invoicing/invoicing.es';
import { KiwayLanguagesType } from '@kiway/shared/types';
// import { i18nextPlugin } from 'translation-check';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';

/**
 * "common" // Things that are reused everywhere, like "Confirm" and "Cancel" on buttons
 * "validation" // All validation text, like "email address not valid" in a form
 * "glossary" // Words we want to be reused consistently, like key words in your app
 */

const availableLanguages = {
  fr: {
    label: 'Français',
    code: 'fr',
    date: 'fr',
  },
  en: {
    label: 'English',
    code: 'en',
    date: 'en-gb',
  },
  pt: {
    label: 'Português',
    code: 'pt',
    date: 'pt',
  },
  es: {
    label: 'Español',
    code: 'es',
    date: 'es',
  },
};

const getLanguageCode = (): string => {
  const initLng: string =
    localStorage.getItem('lng') || navigator.language || 'fr';
  return Object.keys(availableLanguages).includes(initLng)
    ? initLng
    : initLng.includes('-')
    ? initLng.split('-')[0]
    : 'fr';
};

const setLanguageCode = (lngCode?: string): void => {
  if (!lngCode) {
    localStorage.removeItem('lng');
  } else {
    localStorage.setItem('lng', lngCode);
    document.documentElement.setAttribute('lang', lngCode);
  }
};

i18n
  .use(initReactI18next)
  // .use(i18nextPlugin)
  .init({
    resources: {
      en: {
        admin: AdminEn,
        affiliation: AffiliationEn,
        agenda: AgendaEn,
        common: CommonEn,
        dashboard: DashboardEn,
        datatable: DatatableEn,
        dates: DatesEn,
        file: FileEn,
        invoicing: InvoicingEn,
        landing: LandingEn,
        menu: MenuEn,
        notification: NotificationEn,
        onlineBooking: OnlineBookingEn,
        patient: PatientEn,
        proSettings: ProSettingsEn,
        publicPage: PublicPageEn,
        session: SessionEn,
        subscription: SubscriptionEn,
        translation: {
          searchBoxLabel: 'Search',
          theme: {
            name: {
              dark: 'Dark',
              light: 'Light',
            },
          },
        },
        user: UserEn,
        validation: ValidationEn,
        weekTemplate: WeekTemplateEn,
      },
      fr: {
        admin: AdminFr,
        affiliation: AffiliationFr,
        agenda: AgendaFr,
        common: CommonFr,
        dashboard: DashboardFr,
        datatable: DatatableFr,
        dates: DatesFr,
        file: FileFr,
        invoicing: InvoicingFr,
        landing: LandingFr,
        menu: MenuFr,
        notification: NotificationFr,
        onlineBooking: OnlineBookingFr,
        patient: PatientFr,
        proSettings: ProSettingsFr,
        publicPage: PublicPageFr,
        session: SessionFr,
        subscription: SubscriptionFr,
        translation: {
          searchBoxLabel: 'Recherche',
          theme: {
            name: {
              dark: 'Foncé',
              light: 'Clair',
            },
          },
        },
        user: UserFr,
        validation: ValidationFr,
        weekTemplate: WeekTemplateFr,
      },
      pt: {
        admin: AdminPt,
        affiliation: AffiliationPt,
        agenda: AgendaPt,
        common: CommonPt,
        dashboard: DashboardPt,
        datatable: DatatablePt,
        dates: DatesPt,
        file: FilePt,
        invoicing: InvoicingPt,
        menu: MenuPt,
        notification: NotificationPt,
        onlineBooking: OnlineBookingPt,
        patient: PatientPt,
        proSettings: ProSettingsPt,
        publicPage: PublicPagePt,
        session: SessionPt,
        subscription: SubscriptionPt,
        translation: {
          searchBoxLabel: 'Recherche',
          theme: {
            name: {
              dark: 'Foncé',
              light: 'Clair',
            },
          },
        },
        user: UserPt,
        validation: ValidationPt,
        weekTemplate: WeekTemplatePt,
      },
      es: {
        admin: AdminEs,
        affiliation: AffiliationEs,
        agenda: AgendaEs,
        common: CommonEs,
        dashboard: DashboardEs,
        datatable: DatatableEs,
        dates: DatesEs,
        file: FileEs,
        invoicing: InvoicingEs,
        menu: MenuEs,
        notification: NotificationEs,
        onlineBooking: OnlineBookingEs,
        patient: PatientEs,
        proSettings: ProSettingsEs,
        publicPage: PublicPageEs,
        session: SessionEs,
        subscription: SubscriptionEs,
        translation: {
          searchBoxLabel: 'Recherche',
          theme: {
            name: {
              dark: 'Foncé',
              light: 'Clair',
            },
          },
        },
        user: UserEs,
        validation: ValidationEs,
        weekTemplate: WeekTemplateEs,
      },
    },
    lng: getLanguageCode() || getFallbackLanguage(),
    fallbackLng: getFallbackLanguage(),
    nsSeparator: ':',
    keySeparator: '.',
    interpolation: {
      skipOnVariables: false,
    },
  });

function useTranslation(namespaces = [], options = {}) {
  const defaultLanguage = availableLanguages[getLanguageCode()];

  const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage);

  const { t, i18n: i18nInit, ready } = useReactTranslation(
    namespaces,
    options !== {} ? options : { i18n }
  );

  const changeLanguage = (value) => {
    if (availableLanguages[value] !== undefined) {
      i18nInit.changeLanguage(availableLanguages[value].code);
      setCurrentLanguage(availableLanguages[value]);
      setLanguageCode(availableLanguages[value].code);
    } else {
      setLanguageCode();
      const defaultLng = availableLanguages[getLanguageCode()];
      i18nInit.changeLanguage(defaultLng.code);
      setCurrentLanguage(defaultLng);
    }
  };

  const renderLanguageChoice = (mobile = false, visibility = true) => (
    <FormControl style={{ visibility: visibility ? 'visible' : 'hidden' }}>
      <Select
        value={localStorage.getItem('lng') ? currentLanguage?.code : '0'}
        sx={() => ({
          color: mobile ? 'primary.main' : 'primary.contrastText',
        })}
        size="small"
        variant="standard"
        onChange={(event) => changeLanguage(event.target.value)}
      >
        <MenuItem value={'0'}>{t('common:browserDefaults')}</MenuItem>
        {Object.values(availableLanguages).map((item) => (
          <MenuItem key={item.code} value={item.code}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const addTranslationDynamically = (
    lng: KiwayLanguagesType,
    namespace: string,
    translations: any,
    deep?: boolean
  ) => {
    i18nInit.addResourceBundle(lng, namespace, translations, deep);
  };

  return {
    t,
    i18n: i18nInit,
    ready,
    changeLanguage,
    availableLanguages,
    defaultLanguage,
    renderLanguageChoice,
    currentLanguage,
    addTranslationDynamically,
  };
}

export { useTranslation };
export default useTranslation;
