export default {
  appBar: {
    prefix: 'Session of',
  },
  diagnosis: {
    title: 'Diagnosis',
  },
  followUp: {
    comment: 'Comment',
    date: 'Date',
    rating: 'Rating',
    title: 'Treatment Follow Up',
  },
  fourMethods: {
    title: 'Four Methods',
  },
  info: {
    chiefComplaint: 'Chief Complaint',
    comment: 'Comment',
    date: 'Date',
    title: 'Session information',
  },
  patientNote: {
    title: 'Patient notes',
  },
  treatment: {
    title: 'Treatment',
  },
};
