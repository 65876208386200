import { createTheme } from '@mui/material/styles';
import { createTheme as createThemeV4 } from '@material-ui/core/styles';
import { Theme, ThemeOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    minoritary: Palette['primary'];
  }
  interface PaletteOptions {
    minoritary?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    minoritary: true;
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    minoritary: true;
  }
}

const createThemeV5 = (themeOptions: ThemeOptions): Theme => {
  const defaultTheme = createTheme({
    components: {
      MuiFab: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            backgroundColor:
              ownerState.color === 'minoritary'
                ? theme.palette.minoritary.main
                : undefined,
            '&:hover': {
              backgroundColor:
                ownerState.color === 'minoritary'
                  ? theme.palette.minoritary.dark
                  : undefined,
            },
          }),
        } as any,
      },
      MuiButton: {
        styleOverrides: {
          contained: ({ ownerState, theme }) => ({
            '&.MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
          }),
        } as any,
      },
    },
  });
  return createTheme(defaultTheme, themeOptions);
};

export { createThemeV4, createThemeV5 };
