import { CountryZone, ICountryZoneAttributes } from '../entities/CountryZone';
import { CountryZoneProvider } from './CountryZoneProvider';

export class EditManyCountryZones {
  private countryZoneProvider: CountryZoneProvider;

  public constructor(countryZoneProvider: CountryZoneProvider) {
    this.countryZoneProvider = countryZoneProvider;
  }

  public execute(
    countryZone: Array<ICountryZoneAttributes>,
    userId?: string
  ): Promise<Array<CountryZone>> {
    return this.countryZoneProvider.editMany(countryZone, userId);
  }
}
