import { CountryZoneGraphQLProvider } from '../../dataproviders/graphql-client/CountryZoneGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllCountryZones } from '../../core/use_cases/GetAllCountryZones';
import {
  CountryZone,
  ICountryZoneAttributes,
} from '../../core/entities/CountryZone';
import { EditManyCountryZones } from '../../core/use_cases/EditManyCountryZones';

class CountryZonesGateway {
  protected static instance: CountryZonesGateway;
  protected getAllCountryZones: GetAllCountryZones;
  protected editManyCountryZones: EditManyCountryZones;

  public static getInstance(client: ApolloClient<any>): CountryZonesGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new CountryZonesGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const countryZoneProvider = new CountryZoneGraphQLProvider(client);
    this.getAllCountryZones = new GetAllCountryZones(countryZoneProvider);
    this.editManyCountryZones = new EditManyCountryZones(countryZoneProvider);
    this.findAll = this.findAll.bind(this);
    this.editCountryZones = this.editCountryZones.bind(this);
    CountryZonesGateway.instance = this;
  }

  async findAll(): Promise<CountryZone[]> {
    return await this.getAllCountryZones.execute();
  }

  async editCountryZones(
    countryZones: ICountryZoneAttributes[]
  ): Promise<CountryZone[]> {
    return await this.editManyCountryZones.execute(countryZones);
  }
}

export function useCountryZonesGateway() {
  const client = useApolloClient();
  const countryZonesGateway = CountryZonesGateway.getInstance(client);
  return {
    findAll: countryZonesGateway.findAll,
    editCountryZones: countryZonesGateway.editCountryZones,
  };
}
