export default {
  appointment:
    'Anappointment has been booked online. Click here to see more details.',
  none: 'No new notifications',
  release: 'The version **{{version}}** is now available',
  stripeConnect: {
    created:
      'Your Stripe account has been successfully connected! You are one inch from being able to use our Online Payment service. You probably received an email from Stripe. Please follow instructions to validate your account and you will be good to go!',
    validated:
      'Congrats, your Stripe account has been validated! You can now use online payment.',
  },
};
