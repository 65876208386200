import * as React from 'react';
import {
  Button,
  Chip,
  Grid,
  Paper,
  Table,
  TableContainer,
  Typography,
} from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import config, { links } from '../../../config';
import moment from 'moment';
import { CustomerInfo } from './CustomerInfo';
import { OrderCardInfo } from './OrderCardInfo';
import { OrderDetailsFormula } from './OrderDetailsFormula';
import { Link } from 'react-router-dom';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AccordionItem, Price, Order } from '@kiway/ecommerce-react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MobileSafeArea, Modal } from '@kiway/shared/ui';
import { PharmacoOrder } from '../../../core/entities/PharmacoOrder';
import { getFontAwesome4IconFromPaymentMethod } from '@kiway/shared/utils/string';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { PaymentModalController } from 'libs/ecommerce/src/ui/components/ListPayments/PaymentModalController';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Address } from '@kiway/shared/features/authentication-react-compatible';

export interface OrderDetailsAdminWholesalerProps {
  savePaymentCallback?: (...args: any[]) => any;
  order?: Order;
  pharmacoOrder?: PharmacoOrder;
  handleOrderPreparationClick?: () => void;
  handleOrderShipmentClick?: () => void;
  handleSaveAddress?: (
    shippingAddress: Address,
    invoicingAddress: Address
  ) => Promise<any>;
  renewOrder?: () => void;
  cancelOrder?: () => void;
  handleMailReminderClick?: () => void;
  handleMailTrackingClick?: () => void;
}

export function OrderDetailsAdminWholesaler(
  props: OrderDetailsAdminWholesalerProps
) {
  const { currentLanguage, t } = useTranslation();
  const [expanded, setExpanded] = React.useState<string | false>('patient');
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [addressEditMode, setAddressEditMode] = React.useState<boolean>(false);
  const [openPaymentModal, setOpenPaymentModal] = React.useState<boolean>(
    false
  );
  const [
    openConfirmMailReminderModal,
    setOpenConfirmMailReminderModal,
  ] = React.useState<boolean>(false);
  const [
    openConfirmMailTrackingModal,
    setOpenConfirmMailTrackingModal,
  ] = React.useState<boolean>(false);

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <MobileSafeArea>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Button
              size={'small'}
              color={'inherit'}
              component={Link}
              to={`/${links.main}/${links.listOrders}`}
            >
              {t('ecommerce:backToList')}
            </Button>
            <div>
              {/* {props.order?.canRenewOrder() && (
                <Button
                  size="small"
                  color="inherit"
                  variant="contained"
                  onClick={props.renewOrder}
                  sx={{ marginRight: 1 }}
                >
                  {t('pharmaco:renewOrder')}
                </Button>
              )} */}
              {props.order?.canCancelOrder() && (
                <Button
                  size="small"
                  color="inherit"
                  variant="contained"
                  onClick={() => setOpenModal(true)}
                >
                  {t('pharmaco:cancelOrder.title')}
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography variant="h4" align="center">
                {t(`${config.namespace}:orderDetails.order.label`)} n°
                {props.order?.getOrderNumber()}
              </Typography>
            </Grid>
            <Grid item>
              <Chip
                label={t(
                  `${config.namespace}:orderDetails.order.status.${
                    props.order?.getOrderStatusJSON()?.label
                  }`
                ).toUpperCase()}
                color={props.order?.getOrderStatusJSON()?.color}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <AccordionItem
            labelVariant={'h6'}
            expanded={expanded === 'patient'}
            handleChange={handleChange('patient')}
            hideSecondaryLabelWhenExpanded={true}
            label={t(`${config.namespace}:orderDetails.client.label`)}
            secondaryLabel={
              props.order?.getShippingAddress().getLine2() &&
              props.order?.getShippingAddress().getCity() &&
              props.order?.getShippingAddress().getCountryCode()
                ? `${props.order
                    ?.getShippingAddress()
                    .getLine2()}, ${props.order
                    ?.getShippingAddress()
                    .getCity()}, ${props.order
                    ?.getShippingAddress()
                    .getCountryCode()
                    .toUpperCase()}`
                : ''
            }
          >
            <CustomerInfo
              addressEditMode={addressEditMode}
              customer={props.order?.getCustomer()}
              handleClickAddressEdit={() => setAddressEditMode(true)}
              handleSaveAddress={async (shippingAddress, invoicingAddress) => {
                await props.handleSaveAddress(
                  shippingAddress,
                  invoicingAddress
                );
                setAddressEditMode(false);
              }}
              order={props.order}
            />
          </AccordionItem>
          <AccordionItem
            labelVariant={'h6'}
            expanded={expanded === 'therapist'}
            handleChange={handleChange('therapist')}
            hideSecondaryLabelWhenExpanded={true}
            label={t(`${config.namespace}:orderDetails.therapist.label`)}
            secondaryLabel={`${
              props.order?.getPrescriber()?.getFullName()
                ? props.order?.getPrescriber()?.getFullName()
                : ''
            }${
              props.order?.getPrescriber()?.getEmail()
                ? ', ' + props.order?.getPrescriber()?.getEmail()
                : ''
            }${
              props.order?.getPrescriber()?.getMobilePhone()
                ? ', ' + props.order?.getPrescriber()?.getMobilePhone()
                : ''
            }`}
          >
            <CustomerInfo customer={props.order?.getPrescriber()} />
          </AccordionItem>
          <AccordionItem
            labelVariant={'h6'}
            expanded={expanded === 'formula'}
            handleChange={
              props.pharmacoOrder?.getFormulas()?.length > 0
                ? handleChange('formula')
                : undefined
            }
            hideExpandIcon={
              props.pharmacoOrder?.getFormulas()?.length > 0 ? false : true
            }
            label={t(`${config.namespace}:orderDetails.formula.label`)}
            secondaryLabel={
              props.pharmacoOrder?.getFormulas()?.length > 0
                ? `${t(
                    `${config.namespace}:orderDetails.formula.formulaCount`,
                    {
                      count: props.order?.getNbOfGroups(),
                    }
                  )} ${props.pharmacoOrder
                    ?.getFormulas()
                    ?.map((formula) => formula.getName())
                    .join(', ')}`
                : t(`${config.namespace}:orderDetails.comment.default`)
            }
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={2} style={{ marginBottom: 5 }}>
                  <Grid item xs={12}>
                    <TableContainer component={Paper} elevation={1}>
                      <Table size="small">
                        {props.pharmacoOrder?.getFormulas()?.map((formula) => (
                          <OrderDetailsFormula
                            key={formula.getGroupBy()}
                            formulaName={formula.getName()}
                            formula={formula}
                            rows={props.order.getLineItemsByGroup(
                              formula.getGroupBy()
                            )}
                            totalQuantity={props.order.getTotalQuantityByGroup(
                              formula.getGroupBy()
                            )}
                            totalPrice={props.order.getGrossTotalPrice(
                              formula.getGroupBy()
                            )}
                            getRoundedItemWeight={(id) =>
                              formula.getRoundedItemQuantity(id)
                            }
                          />
                        ))}
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionItem>
          <AccordionItem
            labelVariant={'h6'}
            expanded={expanded === 'preparerComment'}
            handleChange={
              props.order?.getCustom()?.['preparerComment']
                ? handleChange('preparerComment')
                : undefined
            }
            hideExpandIcon={
              props.order?.getCustom()?.['preparerComment'] ? false : true
            }
            hideSecondaryLabelWhenExpanded={true}
            label={t(`${config.namespace}:orderDetails.comment.label`)}
            secondaryLabel={
              props.order?.getCustom()?.['preparerComment']
                ? props.order?.getCustom()?.['preparerComment']
                : t(`${config.namespace}:orderDetails.comment.default`)
            }
          >
            <Typography>
              {props.order?.getCustom()?.['preparerComment']
                ? props.order?.getCustom()?.['preparerComment']
                : t(`${config.namespace}:orderDetails.comment.default`)}
            </Typography>
          </AccordionItem>
        </Grid>
        <Grid item xs={12} md={12}>
          <PaymentModalController
            saveCallback={props.savePaymentCallback}
            open={openPaymentModal}
            setOpen={setOpenPaymentModal}
            selectedPayment={props.order?.getPayment()}
          />
          <Grid container spacing={2} alignItems={'stretch'}>
            <Grid item xs={4}>
              <OrderCardInfo
                actions={
                  <>
                    {props.order?.getPaymentStatus() === 'BALANCE_DUE' &&
                      props.order?.getOrderStatus() === 'OPEN' && (
                        <>
                          <Button
                            size="small"
                            onClick={() =>
                              setOpenConfirmMailReminderModal(true)
                            }
                          >
                            {t(
                              `${config.namespace}:orderDetails.buttons.mailReminder`
                            )}
                          </Button>
                          <Modal
                            actions={
                              <>
                                <Button
                                  variant="contained"
                                  color="inherit"
                                  onClick={() =>
                                    setOpenConfirmMailReminderModal(false)
                                  }
                                >
                                  {t(`pharmaco:button.discard`)}
                                </Button>
                                <Button
                                  onClick={() => {
                                    props.handleMailReminderClick();
                                    setOpenConfirmMailReminderModal(false);
                                  }}
                                  variant="contained"
                                  color="minoritary"
                                >
                                  {t(`common:form.buttons.confirm`)}
                                </Button>
                              </>
                            }
                            handleClose={() =>
                              setOpenConfirmMailReminderModal(false)
                            }
                            open={openConfirmMailReminderModal}
                            title={t(
                              'pharmaco:orderDetails.mailReminder.confirmTitle'
                            )}
                          >
                            {t(
                              'pharmaco:orderDetails.mailReminder.confirmText'
                            )}
                          </Modal>
                        </>
                      )}
                    {props.order?.getPaymentStatus() === 'PAID' &&
                      props.order?.getShipmentStatus() === 'SHIPPED' && (
                        <Button
                          size="small"
                          target="_blank"
                          href={`https://${
                            process.env.NX_API_URL
                          }/storage/display/${
                            props.order?.getInvoice()?.getFile()?.id
                          }`}
                        >
                          {t(
                            `${config.namespace}:orderDetails.buttons.invoice`
                          )}
                        </Button>
                      )}
                    {(props.order?.getPaymentStatus() !== 'BALANCE_DUE' ||
                      'WAITING') && (
                      <Button
                        size="small"
                        onClick={() => setOpenPaymentModal(true)}
                      >
                        {props.order?.getPaymentStatus() === 'PENDING' &&
                          t(
                            `${config.namespace}:orderDetails.buttons.markAsPaid`
                          )}
                        {props.order?.getPaymentStatus() === 'PAID' &&
                          t(
                            `${config.namespace}:orderDetails.buttons.seePayment`
                          )}
                      </Button>
                    )}
                  </>
                }
                lines={[
                  {
                    label: t(`${config.namespace}:orderDetails.payment.amount`),
                    value: props.order?.getTotalPrice()?.toString(),
                  },
                  {
                    label: t(`${config.namespace}:orderDetails.payment.paidAt`),
                    value: props.order?.getPaidAt()
                      ? moment(props.order?.getPaidAt())?.format(
                          t('dates:format.smsSentDate')
                        )
                      : null,
                    hide: !props.order?.getPaidAt(),
                  },
                  // {
                  //   label: t(
                  //     `${config.namespace}:orderDetails.payment.paymentMethod`
                  //   ),
                  //   value: t(
                  //     `pharmaco:orderDetails.payment.forms.${order?.getPaymentMethod()}`
                  //   ),
                  //   hide: order?.getPaymentStatus() !== 'PAID',
                  // },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.payment.paymentMethod`
                    ),
                    value:
                      props.order?.getPaymentMethod() === 'card' ? (
                        <Typography>
                          <i
                            className={
                              `fa-lg ${getFontAwesome4IconFromPaymentMethod(
                                props.order?.getPaymentMethod(),
                                props.order?.getPayment()?.getCustom()?.card
                                  ?.brand
                              )}` as any
                            }
                          ></i>
                          {' ···· '}
                          {props.order?.getPayment()?.getCustom()?.card?.last4}
                        </Typography>
                      ) : (
                        <Typography>
                          {t(
                            `pharmaco:orderDetails.payment.forms.${props.order
                              ?.getPayment()
                              ?.getPaymentMethod()}`
                          )}
                        </Typography>
                      ),
                    hide:
                      (props.order?.getPaymentStatus() !== 'PAID' &&
                        props.order?.getPaymentMethod() === 'card') ||
                      (!['PENDING', 'PAID'].includes(
                        props.order?.getPaymentStatus()
                      ) &&
                        props.order?.getPaymentMethod() !== 'card'),
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.payment.paymentFees`
                    ),
                    value: new Price({
                      centAmount: props.order?.getPayment()?.getCustom()
                        ?.paymentFees,
                    })?.toString(),
                    hide:
                      props.order?.getPaymentStatus() !== 'PAID' ||
                      props.order?.getPaymentMethod() !== 'card',
                  },
                ]}
                title={t(`${config.namespace}:orderDetails.payment.label`)}
                status={t(
                  `${config.namespace}:orderDetails.payment.status.${
                    props.order?.getPaymentStatusJSON()?.label
                  }`
                )}
                statusColor={props.order?.getPaymentStatusJSON()?.color}
              />
            </Grid>
            <Grid item xs={4}>
              <OrderCardInfo
                actions={
                  <>
                    {props.order?.getPackingStatus() !== 'PREPARED' && (
                      <Button
                        size="small"
                        variant="contained"
                        color="minoritary"
                        onClick={props.handleOrderPreparationClick}
                        disabled={!props.order?.canDoPreparation()}
                      >
                        {t(
                          `${config.namespace}:orderDetails.buttons.${
                            props.order?.getStartPreparationAt()
                              ? 'prepare'
                              : 'startPrepare'
                          }`
                        )}
                      </Button>
                    )}
                  </>
                }
                lines={[
                  {
                    label: t(
                      `${config.namespace}:orderDetails.packing.preparedBy`
                    ),
                    value: props.order?.getPreparedBy()?.getFullName(),
                    hide: !props.order?.getPreparedBy(),
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.packing.preparedAt`
                    ),
                    value: props.order?.getEndPreparationAt()
                      ? moment(props.order?.getEndPreparationAt())?.format(
                          t('dates:format.smsSentDate')
                        )
                      : null,
                    hide: !props.order?.getEndPreparationAt(),
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.packing.progress`
                    ),
                    value: `${(
                      props.order?.getPreparationProgression() * 100
                    ).toFixed(2)} %`,
                    hide: !props.order?.canDoPreparation(),
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.packing.weight.content`
                    ),
                    value: `${
                      props.order?.getItemsWeight()?.toFixed(2) ?? '0.00'
                    } g`,
                  },
                ]}
                title={t(`${config.namespace}:orderDetails.packing.label`)}
                status={t(
                  `${config.namespace}:orderDetails.packing.status.${
                    props.order?.getPackingStatusJSON()?.label
                  }`
                )}
                statusColor={props.order?.getPackingStatusJSON()?.color}
              />
            </Grid>
            <Grid item xs={4}>
              <OrderCardInfo
                actions={
                  <>
                    {props.order?.getShipmentStatus() !== 'SHIPPED' && (
                      <Button
                        disabled={!props.order?.canDoShipment()}
                        onClick={props.handleOrderShipmentClick}
                        size="small"
                        variant="contained"
                        color="minoritary"
                      >
                        {t(`${config.namespace}:orderDetails.buttons.dispatch`)}
                      </Button>
                    )}
                    {props.order?.getShipmentStatus() === 'SHIPPED' && (
                      <>
                        <Button
                          size="small"
                          onClick={() => setOpenConfirmMailTrackingModal(true)}
                        >
                          {t(
                            `${config.namespace}:orderDetails.buttons.sendTracking`
                          )}
                        </Button>
                        <Modal
                          actions={
                            <>
                              <Button
                                variant="contained"
                                color="inherit"
                                onClick={() =>
                                  setOpenConfirmMailTrackingModal(false)
                                }
                              >
                                {t(`pharmaco:button.discard`)}
                              </Button>
                              <Button
                                onClick={() => {
                                  props.handleMailTrackingClick();
                                  setOpenConfirmMailTrackingModal(false);
                                }}
                                variant="contained"
                                color="minoritary"
                              >
                                {t(`common:form.buttons.confirm`)}
                              </Button>
                            </>
                          }
                          handleClose={() =>
                            setOpenConfirmMailTrackingModal(false)
                          }
                          open={openConfirmMailTrackingModal}
                          title={t(
                            'pharmaco:orderDetails.mailTracking.confirmTitle'
                          )}
                        >
                          {t('pharmaco:orderDetails.mailTracking.confirmText')}
                        </Modal>
                      </>
                    )}
                  </>
                }
                lines={[
                  {
                    label: t(
                      `${config.namespace}:orderDetails.shipment.sentBy`
                    ),
                    value: props.order?.getSentBy()?.getFullName(),
                    hide: !props.order?.getSentBy(),
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.shipment.sentAt`
                    ),
                    value: props.order?.getEndShipmentAt()
                      ? moment(props.order?.getEndShipmentAt())?.format(
                          t('dates:format.smsSentDate')
                        )
                      : null,
                    hide: !props.order?.getEndShipmentAt(),
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.shipment.shipmentMethod`
                    ),
                    value: props.order?.getShippingMethod()?.getName()?.[
                      currentLanguage?.code
                    ],
                    hide: !props.order?.getShippingMethod(),
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.shipment.shipmentMethodCost`
                    ),
                    value: props.order
                      ?.getShippingMethod()
                      ?.getPrice(
                        props.order.getShippingAddress()?.getCountryCode(),
                        props.order.getTotalWeight()
                      )
                      ?.toString(),
                    hide: !props.order?.getShippingMethod(),
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.packing.weight.packing`
                    ),
                    value: `${
                      props.order?.getPackingWeight()?.toFixed(2) ?? '0.00'
                    } g`,
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.packing.weight.total`
                    ),
                    value: `${
                      props.order?.getTotalWeight()?.toFixed(2) ?? '0.00'
                    } g`,
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.shipment.trackingNumber`
                    ),
                    value: (
                      <a
                        href={`${props.order
                          ?.getShippingMethod()
                          ?.getCarrier()
                          ?.getTrackingLink(
                            props.order?.getShippingTrackingNumber()
                          )}`}
                        target="_blank"
                      >
                        {props.order?.getShippingTrackingNumber()}
                      </a>
                    ),
                    hide: !props.order?.getShippingTrackingNumber(),
                  },
                  {
                    label: t(
                      `${config.namespace}:orderDetails.shipment.carrierLabel.label`
                    ),
                    value: (
                      <a
                        href={props.order?.getShippingLabelStoredFile()?.url}
                        target="_blank"
                      >
                        {t(
                          `${config.namespace}:orderDetails.shipment.carrierLabel.displayLabelNewTab`
                        )}
                      </a>
                    ),
                    hide: !props.order?.getShippingLabelStoredFile()?.url,
                  },
                ]}
                title={t(`${config.namespace}:orderDetails.shipment.label`)}
                status={t(
                  `${config.namespace}:orderDetails.shipment.status.${
                    props.order?.getShipmentStatusJSON()?.label
                  }`
                )}
                statusColor={props.order?.getShipmentStatusJSON()?.color}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {openModal && (
        <Modal
          actions={
            <>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => setOpenModal(false)}
              >
                {t('common:form.buttons.cancel')}
              </Button>
              <Button
                variant="contained"
                color="minoritary"
                onClick={() => {
                  props.cancelOrder();
                  setOpenModal(false);
                }}
              >
                {t('common:form.buttons.confirm')}
              </Button>
            </>
          }
          handleClose={() => setOpenModal(false)}
          open={openModal}
          title={t('pharmaco:cancelOrder.title')}
          disableEnforceFocus
        >
          {t('pharmaco:cancelOrder.message')}
        </Modal>
      )}
    </MobileSafeArea>
  );
}
