import { ShippingMethodPriceGraphQLProvider } from '../../dataproviders/graphql-client/ShippingMethodPriceGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllShippingMethodPrices } from '../../core/use_cases/GetAllShippingMethodPrices';
import {
  ShippingMethodPrice,
  IShippingMethodPriceAttributes,
} from '../../core/entities/ShippingMethodPrice';
import { EditManyShippingMethodPrices } from '../../core/use_cases/EditManyShippingMethodPrices';

class ShippingMethodPricesGateway {
  protected static instance: ShippingMethodPricesGateway;
  protected getAllShippingMethodPrices: GetAllShippingMethodPrices;
  protected editManyShippingMethodPrices: EditManyShippingMethodPrices;

  public static getInstance(
    client: ApolloClient<any>
  ): ShippingMethodPricesGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new ShippingMethodPricesGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const shippingMethodPriceProvider = new ShippingMethodPriceGraphQLProvider(
      client
    );
    this.getAllShippingMethodPrices = new GetAllShippingMethodPrices(
      shippingMethodPriceProvider
    );
    this.editManyShippingMethodPrices = new EditManyShippingMethodPrices(
      shippingMethodPriceProvider
    );
    this.findAll = this.findAll.bind(this);
    this.editShippingMethodPrices = this.editShippingMethodPrices.bind(this);
    ShippingMethodPricesGateway.instance = this;
  }

  async findAll(): Promise<ShippingMethodPrice[]> {
    return await this.getAllShippingMethodPrices.execute();
  }

  async editShippingMethodPrices(
    shippingMethodPrices: IShippingMethodPriceAttributes[]
  ): Promise<ShippingMethodPrice[]> {
    return await this.editManyShippingMethodPrices.execute(
      shippingMethodPrices
    );
  }
}

export function useShippingMethodPricesGateway() {
  const client = useApolloClient();
  const shippingMethodPricesGateway = ShippingMethodPricesGateway.getInstance(
    client
  );
  return {
    findAll: shippingMethodPricesGateway.findAll,
    editShippingMethodPrices:
      shippingMethodPricesGateway.editShippingMethodPrices,
  };
}
