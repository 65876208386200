import { Wholesaler } from '../entities/Wholesaler';
import { WholesalerProvider } from './WholesalerProvider';

export class GetAllWholesalers {
  private wholesalerProvider: WholesalerProvider;

  public constructor(wholesalerProvider: WholesalerProvider) {
    this.wholesalerProvider = wholesalerProvider;
  }

  public execute(): Promise<Wholesaler[]> {
    return this.wholesalerProvider.findAll();
  }
}
