/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { OrderStatPeriod } from '@kiway/ecommerce-react-compatible';
import { useTranslation } from '@kiway/shared/utils/translation';

interface OrderPickerChartProps {
  data: any;
  period: OrderStatPeriod;
  loading?: boolean;
}

const defaultOptions = (period: OrderStatPeriod, loading: boolean): any => ({
  xaxis: {
    tickPlacement: 'between',
    categories: [],
    tickAmount: 0,
    labels: {
      formatter: function (val: string) {
        return ['prevQuarter', 'quarter'].includes(period)
          ? `Sem. ${val}`
          : ['prevYear', 'year'].includes(period)
          ? moment(val, 'YYYY-MM')?.format('MMMM YYYY')
          : val;
      },
    },
  },
  yaxis: {
    labels: {
      formatter: function (val) {
        return val.toFixed(0);
      },
    },
  },
  noData: {
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: '#000000',
      fontSize: '14px',
      fontFamily: 'Helvetica',
    },
  },
});

const surchargeOptions = (
  options: any,
  period: OrderStatPeriod,
  loading: boolean,
  t?: any
): any => {
  return {
    ...defaultOptions(period, loading),
    ...options,
    noData: {
      ...options?.noData,
      text: loading ? t('pharmaco:loading') : t('pharmaco:noData'),
    },
    xaxis: {
      ...(defaultOptions(period, loading)?.xaxis ?? {}),
      ...options?.xaxis,
    },
    yaxis: {
      ...(defaultOptions(period, loading)?.yaxis ?? {}),
      ...options?.yaxis,
    },
  };
};

export const OrderPickerChart = (props: OrderPickerChartProps) => {
  const { t } = useTranslation();

  const [options, setOptions] = React.useState<any>(
    defaultOptions(props.period, props.loading)
  );

  React.useEffect(() => {
    setOptions(props.data?.options);
    return () => {
      setOptions(defaultOptions(props.period, props.loading));
    };
  }, [props.data?.options]);

  return (
    <ReactApexChart
      options={surchargeOptions(
        props.data?.series?.length > 0 && !props.loading
          ? options ?? defaultOptions(props.period, props.loading)
          : defaultOptions(props.period, props.loading),
        props.period,
        props.loading,
        t
      )}
      series={props.data?.series ?? []}
      type="area"
      height={415}
    />
  );
};
