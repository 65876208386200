import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

// We can inject some CSS into the DOM.
const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: (props) => theme.spacing(!props.hasMarginLeft ? 0 : 1),
  },
  minoritary: {
    backgroundColor: theme.palette.minoritary.main,
    color: theme.palette.minoritary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.minoritary.dark,
    },
  },
  minoritaryOutlined: {
    backgroundColor: theme.palette.minoritary.contrastText,
    color: theme.palette.minoritary.main,
    border: `1px solid ${theme.palette.minoritary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.minoritary.dark,
      color: theme.palette.minoritary.contrastText,
    },
  },
  white: {
    borderColor: 'white !important',
    // '&:hover': {
    //   backgroundColor: 'white',
    //   color: 'black',
    // },
  },
  danger: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))

const authorizedColors = [
  'default',
  'inherit',
  'primary',
  'secondary',
  'minoritary',
  'danger',
  'grey',
  'white',
]

function CustomButton(props) {
  const { hasmarginleft } = props
  const classes = useStyles({
    hasMarginLeft: hasmarginleft === 'true' ? true : false,
  })
  const { children, className, color, variant, ...other } = props

  const customProps = authorizedColors.includes(color)
    ? color === 'minoritary'
      ? variant === 'outlined'
        ? {
            className: clsx(
              classes.root,
              classes.minoritaryOutlined,
              className,
            ),
          }
        : {
            className: clsx(classes.root, classes.minoritary, className),
          }
      : color === 'danger'
      ? {
          className: clsx(classes.root, classes.danger, className),
        }
      : color === 'white'
      ? {
          className: clsx(classes.root, classes.white, className),
        }
      : color === 'grey'
      ? {
          className: clsx(classes.root, className),
        }
      : {
          className: clsx(classes.root, className),
          color,
        }
    : {
        className,
      }

  return (
    <Button variant={variant || 'contained'} {...customProps} {...other}>
      {children}
    </Button>
  )
}

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(authorizedColors),
  hasmarginleft: PropTypes.string,
  onClick: PropTypes.func,
}

export default CustomButton
