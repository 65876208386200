import { useLocation } from 'react-router-dom'

function useQuery() {
  const params = useLocation().search?.split('?')[1]?.split('&')
  const queryParams = {}
  if (params && Array.isArray(params)) {
    for (const param of params) {
      queryParams[param.split('=')[0]] = param.split('=')[1]
    }
  }
  return {
    get: (name) => queryParams[name],
  }
}

export default useQuery
