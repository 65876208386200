import * as React from 'react';
import { Box, Chip } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ChipFilter } from '../PharmacoBrowserController';
import { includes } from 'lodash';
export interface FilterProps {
  filter: ChipFilter;
  selectedKey: string;
  handleClick: any;
  complexFilter?: boolean;
}

export function Filter({
  filter,
  selectedKey,
  handleClick,
  complexFilter,
}: FilterProps) {
  const [complexChipOpen, setComplexChipOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    setComplexChipOpen(
      selectedKey === filter?.key ||
        filter.subFilters?.map((item) => item.key).includes(selectedKey)
    );
  }, [selectedKey]);

  const toggleComplexChipDatas = () => {
    if (complexFilter) {
      handleClick(filter.subFilters?.[0]?.key);
    } else {
      handleClick(filter?.key);
    }
  };

  return complexFilter ? (
    <>
      <Chip
        label={filter.label}
        color={selectedKey.includes(filter?.key) ? 'primary' : 'default'}
        variant={'outlined'}
        onClick={toggleComplexChipDatas}
        deleteIcon={
          complexChipOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />
        }
        onDelete={toggleComplexChipDatas}
      />
      {complexChipOpen &&
        filter.subFilters?.map((subfilter) => (
          <Chip
            sx={{ ml: 1 }}
            key={subfilter.key}
            size="small"
            label={subfilter.label}
            color={selectedKey === subfilter.key ? 'primary' : 'default'}
            variant={selectedKey === subfilter.key ? 'filled' : 'outlined'}
            onClick={() => handleClick(subfilter?.key)}
          />
        ))}
    </>
  ) : (
    <Chip
      label={filter.label}
      variant={selectedKey === filter.key ? 'filled' : 'outlined'}
      onClick={() => handleClick(filter.key)}
      color={selectedKey === filter.key ? 'primary' : 'default'}
    />
  );
}
