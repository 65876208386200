import DashboardIcon from '@material-ui/icons/Dashboard';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import ExtensionIcon from '@material-ui/icons/Extension';
import ReceiptIcon from '@material-ui/icons/Receipt';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons';

const menus = (userInfo) => ({
  main: [
    {
      id: 'dashboard',
      text:
        userInfo && userInfo.role && userInfo.role === 'ROLE_PATIENT'
          ? 'menu:appointments'
          : 'menu:dashboard',
      icon: <DashboardIcon fontSize="medium" />,
      link: 'dashboard',
      display: true,
      role: 'ROLE_PATIENT',
      order: 1,
    },
    {
      id: 'agenda',
      text: 'menu:agenda',
      icon: <DateRangeIcon fontSize="medium" />,
      link: 'agenda',
      display: true,
      role: 'ROLE_PRACTITIONER',
      order: 3,
    },
    {
      id: 'files',
      text: 'menu:files',
      icon: <GroupIcon fontSize="medium" />,
      link: 'files',
      display: true,
      role: 'ROLE_PRACTITIONER',
      order: 4,
    },
    {
      id: 'settings',
      text: 'menu:settings.main',
      icon: <SettingsIcon fontSize="medium" />,
      link: 'settings',
      display: true,
      role: 'ROLE_PRACTITIONER',
      order: 5,
      subItems: [
        {
          id: 'global_settings',
          text: 'menu:settings.activity',
          link: 'settings',
          display: true,
          role: 'ROLE_PRACTITIONER',
          order: 1,
        },
      ],
    },
    {
      id: 'invoicing',
      text: 'menu:invoicing',
      icon: <ReceiptIcon fontSize="medium" />,
      link: 'invoicing',
      display: true,
      role: 'ROLE_PRACTITIONER',
      order: 6,
    },
    {
      id: 'services',
      text: 'menu:services',
      icon: <ExtensionIcon fontSize="medium" />,
      link: 'services',
      display: process.env.NX_PREMIUM_SERVICES_ENABLED === 'true',
      role: 'ROLE_PRACTITIONER',
      order: 7,
    },
    {
      id: 'users',
      text: 'menu:users',
      icon: <FontAwesomeIcon size={'lg'} icon={faUsersCog} />,
      link: 'users-admin',
      display: true,
      role: 'ROLE_ADMIN',
      order: 8,
    },
    {
      id: 'notifications',
      text: 'menu:notifications',
      icon: <NotificationsIcon fontSize="medium" />,
      link: 'notifications',
      display: true,
      role: 'ROLE_ADMIN',
      order: 9,
    },
  ],
  user: [
    {
      id: 'affiliation',
      text: 'menu:affiliation',
      link: 'affiliation',
      display: process.env.NX_AFFILIATION_ENABLED === 'true',
      role: 'ROLE_PRACTITIONER',
      order: 2,
    },
    // {
    //   component: (
    //     <MenuItem
    //       key="logout"
    //       onClick={async () => {
    //         const isLogout = await logout();
    //         if (isLogout) {
    //           handleClose();
    //         }
    //       }}
    //     >
    //       {t('user:menu.logout')}
    //     </MenuItem>
    //   ),
    //   role: 'ROLE_PATIENT',
    // }
  ],
});

export default menus;
