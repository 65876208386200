export default {
  buttons: {
    addPatient: 'Ajouter un patient',
    addRDV: 'Ajouter un rendez-vous',
    newOrder: 'Nouvelle commande',
  },
  dataTable: {
    columns: {
      hour: 'Heure',
    },
    title: 'Rendez-vous du jour',
  },
  stats: {
    cards: {
      affiliations: {
        nbCompleted: 'Terminés',
        nbPending: 'En cours',
        nbSent: 'Envoyés',
        title: 'Parrainages',
      },
      consumption: {
        smsCredits: {
          subtitle: 'Soit environ %X% € TTC',
          title: 'Crédits SMS',
        },
        stripeBalance: {
          negativeText:
            'Le solde réduira le montant dû sur la prochaine facture',
          positiveText:
            'Le solde augmentera le montant dû sur la prochaine facture',
          title: 'Solde restant',
        },
        title: 'Consommation de vos ressources',
      },
      kpis: {
        nbManualRDVs: 'RDVs manuel',
        nbOnlineRDVs: 'RDVs pris en ligne',
        nbSubPro: 'Abos pro',
        nbSubSmsTotal: 'Total abos sms',
        nbSubStudent: 'Abos étudiant',
        nbSubTotal: 'Total abos',
        nbTotalFiles: 'Dossiers patients',
        nbTotalRDVs: 'RDVs total',
        title: 'KPIs',
      },
      monthActivity: {
        appointmentsDonePercent: 'Séances effectuées',
        caForecast: 'Prévisionnel',
        nbAppointments: 'Nombre de séances',
        nbNewPatients: 'Nouveaux patients',
        title: 'Votre activité mensuelle',
      },
      users: {
        nbAdmin: 'Administrateurs',
        nbBeta: 'Co-créateurs',
        nbGeneratedPatients: 'Patients générés',
        nbPartner: 'Partenaires',
        nbPatients: 'Patients',
        nbPharmaco: 'Pharmaco',
        nbPractitioners: 'Praticiens',
        nbTotalNoGeneratedPatient: 'Inscrits total',
        title: 'Typologie des utilisateurs',
      },
    },
    filters: 'Filtres',
    graph: {
      bestOfUsers: {
        title: 'Utilisateurs les plus actifs sur la période',
      },
      loading: 'Chargement du graphique',
      nbOfUsers: {
        signins: 'Connexions',
        signinsUnique: 'Connexions uniques',
        signups: 'Inscriptions',
        title: "Nombre d'utilisateurs",
      },
    },
  },
};
