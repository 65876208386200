import { gql } from '@apollo/client'

export const AGENDA_GET_APPOINTMENTS = gql`
  query appointments($filter: AppointmentFilter) {
    appointments(filter: $filter) {
      id
      owner {
        id
      }
      creator {
        id
      }
      startDate
      endDate
      title
      patient {
        id
        firstName
        lastName
      }
      chiefComplaint {
        name
      }
      comment
      status
      eventType
      sms {
        msg_status
        scheduleAt
        ts_event
      }
    }
  }
`

export const DASHBOARD_GET_APPOINTMENTS = gql`
  query appointments($filter: AppointmentFilter) {
    appointments(filter: $filter) {
      id
      startDate
      patient {
        id
        firstName
        lastName
      }
      patientFile {
        id
      }
      chiefComplaint {
        name
      }
      eventType
    }
  }
`
export const FILE_GET_APPOINTMENTS = gql`
  query appointments($filter: AppointmentFilter) {
    appointments(filter: $filter) {
      id
      startDate
      chiefComplaint {
        name
      }
    }
  }
`

export const FILE_GET_SESSION_DATA = gql`
  query appointment($id: ID!) {
    appointment(id: $id) {
      id
      patient {
        firstName
        lastName
      }
      startDate
      endDate
      consultationPlace {
        id
        name
        address {
          description
        }
      }
      chiefComplaint {
        id
        name
      }
      comment
      fourMethods {
        text
      }
      diagnosis {
        text
      }
      treatment {
        text
      }
      patientNote
      sms {
        msg_status
        scheduleAt
        ts_event
      }
    }
  }
`

export const MODAL_GET_APPOINTMENT = gql`
  query appointment($id: ID!) {
    appointment(id: $id) {
      id
      owner {
        id
      }
      creator {
        id
      }
      title
      startDate
      endDate
      status
      eventType
      patient {
        id
        firstName
        lastName
      }
      consultationPlace {
        id
        name
        address {
          description
        }
      }
      chiefComplaint {
        id
        name
        duration
        specialty {
          id
          name
          slug
        }
        consultationPlaces {
          id
          name
        }
      }
      comment
      sms {
        msg_status
        scheduleAt
        ts_event
      }
    }
  }
`
