import * as React from 'react';
import { useUsersGateway } from '../../../entrypoints/react/useUsersGateway';
import { ChangePassword } from './ChangePassword';
import { useSnackbar } from '@kiway/shared/utils/snackbar';
import { useTranslation } from '@kiway/shared/utils/translation';

export function ChangePasswordController() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { sendForgotPassword } = useUsersGateway();
  const { t } = useTranslation();
  const { addErrorSnackbar, addSuccessSnackbar } = useSnackbar();
  const handleEdit = () => {
    setOpen((prev) => !prev);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    setLoading(true);
    sendForgotPassword()
      .then((result) => {
        if (result) {
          addSuccessSnackbar(
            t('authentication:editProfile.changePasswordSuccess')
          );
        } else {
          addErrorSnackbar(t('authentication:editProfile.changePasswordError'));
        }
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
  };
  return (
    <ChangePassword
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      handleEdit={handleEdit}
      loading={loading}
      open={open}
    />
  );
}
