import features from './features';
import routes from './routes';
import { themeV4, themeV5 } from './theme';
import menus from './menus';

export default {
  features,
  menus,
  routes,
  themeV4,
  themeV5,
};
