import { gql } from '@apollo/client';

export const GET_INVOICING_SETTINGS = gql`
  query {
    invoicingSetting {
      user {
        address {
          street_number
          route
          locality
          country
          postal_code
        }
        firstName
        lastName
        mobilePhone
        secondPhone
        gender
      }
      naf
      siret
      tva
      businessName
      specialMentions
      invoicePrefix
      invoiceSuffix
      invoiceDigits
      invoiceNextDigit
    }
  }
`;
