export default {
  fields: {
    appointmentDate: {
      error: 'Appointment start and end dates have to be the same day',
    },
    email: {
      error: 'Please enter a valid email.',
    },
    lastname: {
      error: 'Please enter your name',
    },
    yourMessage: {
      error: 'Please enter a message',
    },
  },
  login: {
    accountNotVerified:
      'Your account has not been activated, please contact support via the chatbox or at contact@kiway.co',
    error: 'Invalid credentials.',
    noRights:
      'You do not have sufficient rights to connect to this space, are you sure you are on the correct page?',
    resetError:
      'An error occurred while sending your request. Please try again.',
  },
  requiredField: {
    empty: 'Required field',
  },
};
