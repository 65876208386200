/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import {
  PharmacoOrder,
  PharmacoOrderFormula,
} from '../../../core/entities/PharmacoOrder';
import { PharmacoBrowserController } from '../PharmacoBrowser';
import { PrescriptionDetailsFormula } from './PrescriptionDetailsFormula';
import { PrescriptionResume } from './PrescriptionResume';
import { OrderStepperController } from '../OrderStepper';
import { CustomerSelectorController } from '../CustomerSelector';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Order } from '@kiway/ecommerce-react-compatible';
import { links } from '../../../config';
import { Link } from 'react-router-dom';
import { Modal } from '@kiway/shared/ui';

export interface PrescriptionDetailsProps {
  order: PharmacoOrder;
  realOrder: Order;
  handleChangeDosage: any;
  handleChangeDosageMode: (formulaId: string, value: 'PS' | 'CP') => void;
  handleChangeFormulaName: any;
  handleChangeIsSeparated: any;
  handleChangePatient: any;
  handleChangeStateOrder: any;
  handleSaveOrder: any;
  handleRemoveLineItem: any;
  handleRemoveFormula: any;
  handleToggleIsPod: (formula: PharmacoOrderFormula) => void;
  handleClickNext: any;
  shopOpen?: boolean;
  setShopOpen: any;
  cancelOrder?: () => void;
  deleteOrder?: () => void;
}

export function PrescriptionDetails(props: PrescriptionDetailsProps) {
  const { t } = useTranslation();
  const [openModalCancel, setOpenModalCancel] = React.useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = React.useState<boolean>(false);

  if (!props.order) {
    return null;
  }

  const addItemButton = (
    <Box sx={{ width: '100%', textAlign: 'center', mt: 4, mb: 2 }}>
      <Button
        size="small"
        color="minoritary"
        variant="contained"
        onClick={() => props.setShopOpen(true)}
      >
        {t('pharmaco:orderDetails.buttons.addItemsToOrder')}
      </Button>
    </Box>
  );

  return (
    <Grid container spacing={2} sx={{ paddingBottom: 2 }}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Button
            size={'small'}
            color="inherit"
            component={Link}
            to={`/${links.main}/${links.listOrders}`}
          >
            {t('pharmaco:backToList')}
          </Button>
          {/* {props.order?.getOrder()?.canCancelOrder() && (
              <Button
                size="small"
                color="inherit"
                variant="contained"
                onClick={() => setOpenModalCancel(true)}
              >
                {t('pharmaco:cancelOrder.title')}
              </Button>
            )} */}
          {props.order?.getOrder()?.canBeDeleted() && (
            <Button
              size="small"
              color="inherit"
              variant="contained"
              onClick={() => setOpenModalDelete(true)}
            >
              {t('pharmaco:deleteOrder.title')}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          {t(`pharmaco:prescription.order.label`)} n°
          {props.order?.getOrder()?.getOrderNumber()}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <OrderStepperController
          currentStep="prescription"
          disableClickOnSteps={
            !props.order?.getOrder()?.isLineAllItemsAvailable() ||
            props.order?.getFormulas()?.length <= 0 ||
            !props.order?.getOrder()?.getCustomer()?.getCustom()?.fileId
          }
          orderId={props.order?.getOrder()?.getId()}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {props.order?.getFormulas()?.length > 0 && addItemButton}
          {props.order?.getFormulas()?.map((formula) => (
            <Grid item xs={12} key={formula.getGroupBy()}>
              <PrescriptionDetailsFormula
                formula={formula}
                handleChangeDosage={props.handleChangeDosage}
                handleChangeDosageMode={props.handleChangeDosageMode}
                handleChangeFormulaName={props.handleChangeFormulaName}
                handleChangeIsSeparated={props.handleChangeIsSeparated}
                handleToggleIsPod={props.handleToggleIsPod}
                handleSaveOrder={props.handleSaveOrder}
                handleRemoveLineItem={props.handleRemoveLineItem}
                handleRemoveFormula={props.handleRemoveFormula}
              />
              {addItemButton}
            </Grid>
          ))}
          <PharmacoBrowserController
            handleChangeStateOrder={props.handleChangeStateOrder}
            order={props.order}
            handleSaveOrder={props.handleSaveOrder}
            shopOpen={props.shopOpen}
            setShopOpen={props.setShopOpen}
          />
        </Grid>
      </Grid>
      {props.order?.getFormulas()?.length <= 0 && (
        <Grid item xs={12}>
          <Typography textAlign="center" fontStyle="italic">
            {t('pharmaco:order.emptyOrder')}
          </Typography>
          {addItemButton}
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <CustomerSelectorController
          handleChangePatient={props.handleChangePatient}
          selectedFileId={
            props.order?.getOrder()?.getCustomer()?.getCustom()?.fileId
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PrescriptionResume
          currentStep="prescription"
          order={props.order}
          realOrder={props.realOrder}
          handleClickNext={props.handleClickNext}
          canDoNext={() => props.order?.canGoToCommentSection()}
          reasonCantGoToCommentSection={props.order?.reasonCantGoToCommentSection()}
        />
      </Grid>
      <Modal
        actions={
          <>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => setOpenModalCancel(false)}
            >
              {t('common:form.buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              color="minoritary"
              onClick={() => {
                props.cancelOrder();
                setOpenModalCancel(false);
              }}
            >
              {t('common:form.buttons.confirm')}
            </Button>
          </>
        }
        handleClose={() => setOpenModalCancel(false)}
        open={openModalCancel}
        title={t('pharmaco:cancelOrder.title')}
        disableEnforceFocus
      >
        {t('pharmaco:cancelOrder.message')}
      </Modal>
      <Modal
        actions={
          <>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => setOpenModalDelete(false)}
            >
              {t('common:form.buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              color="minoritary"
              onClick={() => {
                props.deleteOrder();
                setOpenModalDelete(false);
              }}
            >
              {t('common:form.buttons.confirm')}
            </Button>
          </>
        }
        handleClose={() => setOpenModalDelete(false)}
        open={openModalDelete}
        title={t('pharmaco:deleteOrder.title')}
        disableEnforceFocus
      >
        {t('pharmaco:deleteOrder.message')}
      </Modal>
    </Grid>
  );
}
