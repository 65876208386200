export default {
  checkboxCGA: `By checking this box, I certify having read and accepted the Terms and Conditions of Subscription [TCS](${process.env['NX_CGA_URL']}) *`,
  checkboxDiploma:
    'By checking this box, I certify having ownership of a diploma issued by an establishment recognized by a professional organisation *',
  checkboxSchool:
    'By checking this box, I certify having ownership of a training certificate issued by an establishment recognized by a professional organisation *',
  errorRetrievingCheckoutSessionId:
    'Something went wrong while redirecting to payment platform. If error persists, please contact us at support@kiway.co',
  invoicing: {
    labels: {
      amount_due: 'Amount',
      date: 'Date',
      description: 'Description',
      number: 'Invoice number',
      paid: 'Paid',
    },
    title: 'Invoices',
  },
  language: 'en',
  lastPaymentFailed: 'Last payment failed',
  paymentSources: {
    modalConfirmText:
      "You are going to remove a payment method. This action can't be reversed",
    modalDeleteTitle: 'Remove a payment method',
    noData: 'You have no payment method',
    snackbars: {
      error: 'Something went wrong, please try again later',
      successDefaultCard: 'Default payment method successfully updated',
      successRemoveCard: 'Default payment method successfully removed',
    },
    title: 'Payment Methods',
  },
  servicesOnDemand: {
    balance: 'Applied balance',
    firstPayment: 'First payment',
    ifThreeDSecure:
      'Should your payment require authentication (e.g. 3D secure), a link will be sent by email for each payment (now and upon renewals).',
    licensedServicesTitle: 'Licensed Services',
    licensedServicesTooltip:
      'Licensed Services are billed at the beginning of the period and proratized.',
    logoutScreen: {
      error:
        'Your payment might require authentication (e.g. 3D secure). Please follow this link to proceed to payment :\n\n[%textLink%](%link%)\n\nPlease reconnect after payment in order to activate changes.',
      errorTitle: 'There was an issue with your payment',
      success:
        'Please reconnect in order to benefit from your services or activate your changes.',
      successTitle: 'Subscription has been successfully registered!',
    },
    meteredServicesTitle: 'Metered Services',
    meteredServicesTooltip:
      'Metered services are billed at the end of the period.',
    modalConfirmText:
      'Changes are billed upon renewal hence your next bill will be recalculated consequently.',
    modalTitle: 'Your Services Update',
    needLogout: 'You need to logout in order to activate changes.',
    nextPayments: 'Next payments',
    recapServices: 'Services Summary',
    snackbars: {
      error:
        'There was an issue with your payment, please try again later or contact us',
      errorPayment:
        'There was an issue with your payment, please follow instructions',
      success: 'Subscription has been successfully registered',
    },
    stepper: {
      chooseServices: 'Choose Services',
      confirm: 'Confirm',
      payment: 'Payment',
    },
    subTotal: 'Subtotal',
    title: 'Services List',
    total: 'Total',
    usage: 'usage',
    vat20Included: 'VAT 20% incl.',
    vat20NotIncluded: 'VAT 20% excl.',
  },
  startUsingApp: 'Start using the app',
  subSuccessMessage:
    'Your subscription is active. You can modify your payment method, access your billings and change your subscription in user menu.',
  thanks: 'Thanks!',
  trialPending: 'Currently in free trial',
};
