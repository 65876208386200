export default {
  contactInfo: {
    mobilePhone: 'Phone',
  },
  description: {
    title: 'Description',
  },
  formations: {
    title: 'Trainings',
  },
  gallery: {
    title: 'Pictures gallery',
  },
  noOnlineBooking:
    "Your Practitioner doesn't have enabled online booking, please contact him or her directly",
  places: {
    title: 'Workplaces',
  },
};
