export default {
  affiliation: 'Apadrinhamento',
  agenda: 'Diário',
  appointments: 'Agendamentos',
  beta: 'Beta',
  dashboard: 'Painel de bordo',
  files: 'arquivos',
  forgotPassword: 'Esqueceu a sua senha',
  invoicing: 'Faturação',
  notifications: 'Notificações',
  pageNotFound: 'Página não encontrada',
  profile: 'Editar perfil',
  register: 'Registro',
  services: 'Os meus serviços',
  settings: {
    activity: 'Geral',
    main: 'Definições',
    notifier: 'Notificações',
  },
  signIn: 'Conexão',
  subscription: 'Inscrição',
  tuto: {
    intro: 'Introdução',
    subtitle: 'Tutorial',
  },
  users: 'Utilizadores',
};
