import * as React from 'react';
import { AuthContext } from '../..';
import { ChangeEmail } from './ChangeEmail';
import { useTranslation } from '@kiway/shared/utils/translation';
import { useUsersGateway } from '../../../entrypoints/react/useUsersGateway';
import { useSnackbar } from '@kiway/shared/utils/snackbar';

// eslint-disable-next-line no-useless-escape
const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ChangeEmailControllerProps {}

export function ChangeEmailController(props: ChangeEmailControllerProps) {
  const { authState, updateUserInfo } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const { addErrorSnackbar, addSuccessSnackbar } = useSnackbar();
  const { changeEmail } = useUsersGateway();
  const [editMode, setEditMode] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState('');
  React.useEffect(() => {
    setEmail(authState?.userInfo?.email || '');
    return () => {
      setEmail('');
    };
  }, [authState.userInfo]);
  return (
    <ChangeEmail
      editMode={editMode}
      email={email}
      error={error}
      handleCancel={() => {
        setEmail(authState?.userInfo?.email || '');
        setError('');
        setEditMode(false);
      }}
      handleChange={(event) => {
        setEmail(event.target.value);
        if (!event.target.value.match(emailRegex)) {
          setError(t('authentication:editProfile.changeEmailInvalid'));
        } else {
          setError('');
        }
      }}
      handleConfirm={() => {
        setLoading(true);
        changeEmail(email)
          .then((result) => {
            if (result) {
              addSuccessSnackbar(
                t('authentication:editProfile.changeEmailSuccess')
              );
              updateUserInfo(result, false);
              return true;
            } else {
              addErrorSnackbar(
                t('authentication:editProfile.changeEmailError')
              );
              return false;
            }
          })
          .then((result) => {
            if (result) {
              setEditMode(false);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }}
      handleEdit={() => {
        setEditMode((prev) => !prev);
      }}
      loading={loading}
    />
  );
}
