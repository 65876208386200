import { CustomerFile } from '../entities/CustomerFile';
import { CustomerFileProvider } from './CustomerFileProvider';

export class GetAllCustomerFiles {
  private customerFileProvider: CustomerFileProvider;

  public constructor(customerFileProvider: CustomerFileProvider) {
    this.customerFileProvider = customerFileProvider;
  }

  public execute(): Promise<CustomerFile[]> {
    return this.customerFileProvider.findAll();
  }
}
