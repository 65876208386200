import * as React from 'react';
import {
  Autocomplete,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { getGravatarURL } from '@kiway/shared/utils/string';
import { formatPhoneNumber } from '@kiway/shared/utils-react';
import moment from 'moment';
import { useTranslation } from '@kiway/shared/utils/translation';

type PatientFileOrder = { id: string; patient?: any; practitioner?: any };

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerSelectorProps {
  customers: PatientFileOrder[];
  selected: PatientFileOrder;
  isChanging?: boolean;
  handleChange: (newPatient: any) => void;
}

export function CustomerSelector(props: CustomerSelectorProps) {
  const [input, setInput] = React.useState<string>('');
  const [value, setValue] = React.useState<any>(null);
  const { t } = useTranslation();
  return (
    <Card
      elevation={8}
      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      {props.selected ? (
        <CardHeader
          avatar={
            <Avatar
              src={getGravatarURL(
                props.selected?.patient?.email ??
                  `${props.selected?.patient?.firstName} ${props.selected?.patient?.lastName}`
              )}
              sx={{ color: 'secondary.main', backgroundColor: 'white' }}
            />
          }
          title={
            <Typography>{`${props.selected?.patient?.firstName} ${props.selected?.patient?.lastName}`}</Typography>
          }
        />
      ) : (
        <CardHeader title={t('pharmaco:orderDetails.selectPatient')} />
      )}
      <CardContent sx={{ pt: 0, flex: 1 }}>
        <Grid
          container
          sx={{ display: 'flex', height: '100%' }}
          justifyContent={
            !(
              formatPhoneNumber(props.selected?.patient?.mobilePhone ?? '') !==
                '' || !!props.selected?.patient?.email
            )
              ? undefined
              : 'space-between'
          }
          alignItems={
            !(
              formatPhoneNumber(props.selected?.patient?.mobilePhone ?? '') !==
                '' || !!props.selected?.patient?.email
            )
              ? 'flex-end'
              : undefined
          }
        >
          {(formatPhoneNumber(props.selected?.patient?.mobilePhone ?? '') !==
            '' ||
            !!props.selected?.patient?.email) && (
            <Grid item xs={12}>
              <Grid container>
                {formatPhoneNumber(
                  props.selected?.patient?.mobilePhone ?? ''
                ) !== '' && (
                  <Grid item xs={12}>
                    <Typography color="text.secondary">
                      {formatPhoneNumber(
                        props.selected?.patient?.mobilePhone ?? ''
                      )}
                    </Typography>
                  </Grid>
                )}
                {!!props.selected?.patient?.email && (
                  <Grid item xs={12}>
                    <Typography color="text.secondary">
                      {props.selected?.patient?.email}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sx={{ pt: 1 }}>
            <Autocomplete
              inputValue={input}
              onInputChange={(e, value) => setInput(value)}
              disablePortal
              id="combo-box-demo"
              fullWidth
              options={
                props.customers?.map((item) => ({
                  label: `${item.patient?.firstName} ${item.patient?.lastName}`,
                  id: item.patient?.id,
                  custom: {
                    userId: item.patient.id,
                    fileId: item.id,
                  },
                  birthDate: item.patient.birthDate
                    ? moment(item.patient.birthDate).format('YYYY-MM-DD')
                    : null,
                  gender: item.patient.gender,
                  profession: item.patient.profession,
                  firstName: item.patient?.firstName,
                  lastName: item.patient?.lastName,
                  email: item.patient?.email,
                  mobilePhone: item.patient?.mobilePhone,
                })) ?? []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('pharmaco:orderDetails.changePatient')}
                />
              )}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              onChange={(e, newValue: any, reason: string) => {
                setValue(newValue);
                if (reason !== 'clear') {
                  const { label, ...patient } = newValue;
                  props.handleChange(patient);
                  setInput('');
                  setValue(null);
                }
              }}
              value={value}
              selectOnFocus={true}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
