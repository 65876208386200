import * as React from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from '@kiway/shared/utils/translation';

interface DiscardButtonProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function DiscardButton({ setIsOpen }: DiscardButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      color="inherit"
      onClick={() => setIsOpen(false)}
    >
      {t(`pharmaco:button.discard`)}
    </Button>
  );
}
