import { useOrdersGateway } from '@kiway/ecommerce-react';
import {
  OrderStat,
  OrderStatName,
  OrderStatPeriod,
} from '@kiway/ecommerce-react-compatible';
import React from 'react';
import { StatusChart } from './StatusChart';

interface StatusChartControllerProps {
  period: OrderStatPeriod;
  statType: OrderStatName;
}

export const StatusChartController = (props: StatusChartControllerProps) => {
  const { getOrderStats } = useOrdersGateway();

  const [stats, setStats] = React.useState<OrderStat>();
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setStats(null);
    setLoading(true);
    getOrderStats(props.period, [props.statType])
      .then((result) => setStats(result[0]))
      .finally(() => setLoading(false));
    return () => {
      setStats(null);
      setLoading(false);
    };
  }, [props.period, props.statType]);

  return (
    <StatusChart period={props.period} data={stats?.data} loading={loading} />
  );
};
