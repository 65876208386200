import * as React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { useStyles } from './styles';

export interface ProductDetailsCardProps {
  title: string;
}

export function ProductDetailsCard({
  children,
  title,
}: React.PropsWithChildren<ProductDetailsCardProps>) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.titrePartie}>{title}</Typography>
        {children}
      </CardContent>
    </Card>
  );
}
