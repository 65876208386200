import * as React from 'react';

import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUIDataTableProps,
} from 'mui-datatables';

import EditButton from '../components/EditButton/EditButton';
import { getCommonOptions } from './commonOptions';
import { useTranslation } from '@kiway/shared/utils/translation';
import { IconButton, Tooltip } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { DatatableColumn } from './types';

/**
 * Datatable props
 */
export type DatatablesProps = {
  /**
   * Edit several informations
   */
  bulkEdit?: boolean;
  /**
   * Function to clear datas
   */
  clear: () => void;
  /**
   * Datatable columns to render
   */
  columns: Array<MUIDataTableColumn>;
  /**
   * Data taked from MUIDataTable React component
   */
  data: MUIDataTableProps['data'];
  /**
   * Default sort order set by MUIDataTableOptions
   */
  defaultSortOrder?: MUIDataTableOptions['sortOrder'];
  /**
   * Custom toolbar actions to add at the end
   */
  endCustomToolbar?: React.ReactNode;
  /**
   * Function on click to add data
   */
  handleAddData: () => void;
  /**
   * Callback function that triggers when a cell is clicked. function(colData: any, cellMeta: { colIndex: number, rowIndex: number, dataIndex: number }) => void
   */
  handleCellClick?: (
    colData: any,
    cellMeta: { colIndex: number; rowIndex: number; dataIndex: number }
  ) => void;
  /**
   * Function on click on row
   */
  handleRowClick: (rowIndex: number) => void;
  /**
   * Function on click to save datas
   */
  handleSaveData: () => void;
  /**
   * Id to store datatable datas in local storage
   */
  id: string;
  /**
   * State of datatable component : is in editing mode ?
   */
  isInEditingMode: boolean;
  /**
   * Boolean loading data
   */
  loading?: boolean;
  /**
   * Options available on Datatable component + MUIDataTable React component
   */
  options: any;
  /**
   * Datatable columns to render
   */
  originalColumns?: Array<DatatableColumn>;
  /**
   * Custom toolbar actions to add at the end
   */
  startCustomToolbar?: React.ReactNode;
  /**
   * Title of the datatable
   */
  title: MUIDataTableProps['title'];
  /**
   * Function to switch on edit/read mode
   */
  toggleEditMode: () => void;
};

/**
 * Return datatable taking into account options of the native React MUIDatatableComponent + our own DataTable component's
 */
export function Datatables(props: DatatablesProps): JSX.Element {
  const rowsPerPage = parseFloat(
    props.isInEditingMode
      ? `${props.data?.length}`
      : localStorage.getItem(`${props.id}RowsPerPage`) || '10'
  );
  const sortOrder = props.isInEditingMode
    ? props.defaultSortOrder || {}
    : JSON.parse(
        localStorage.getItem(`${props.id}Sorting`) ||
          JSON.stringify(props.defaultSortOrder || {})
      );
  const { t } = useTranslation();
  const common = getCommonOptions({ translate: t, loading: props.loading });
  const options = {
    ...common,
    columnOrder:
      localStorage
        .getItem(`${props.id}ColumnOder`)
        ?.split(',')
        .map(parseFloat) || undefined,
    onColumnOrderChange: (tab) => {
      localStorage.setItem(`${props.id}ColumnOder`, tab.join(','));
    },
    onCellClick: (data, meta) => {
      if (props.handleRowClick && !props.isInEditingMode) {
        props.handleRowClick(meta.dataIndex);
      }
      props.originalColumns?.map((column, index) => {
        if (column.options && column.options.onCellClick) {
          if (index === meta.colIndex) {
            column.options.onCellClick(data, meta);
          }
        }
      });
    },
    onViewColumnsChange: (changedColumn, action) => {
      const savedColumns = localStorage
        .getItem(`${props.id}ColumnView`)
        ?.split(',')
        ?.filter((item) => item !== '');
      const formerColumns = savedColumns?.length
        ? savedColumns
        : props.columns
            ?.filter(({ options }) => options?.display)
            ?.map(({ name }) => name)
            ?.filter((item) => item !== '');
      if (action === 'add') {
        formerColumns.push(changedColumn);
      } else {
        const index = formerColumns.indexOf(changedColumn);
        if (index > -1) {
          formerColumns.splice(index, 1);
        }
      }
      localStorage.setItem(`${props.id}ColumnView`, formerColumns.join(','));
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      localStorage.setItem(`${props.id}RowsPerPage`, `${numberOfRows}`);
    },
    rowsPerPageOptions:
      props.data?.length > 0 &&
      props.data?.length > Math.max(...common.rowsPerPageOptions)
        ? [...common.rowsPerPageOptions, props.data?.length]
        : common.rowsPerPageOptions,
    rowsPerPage: rowsPerPage || undefined,
    draggableColumns: {
      enabled: true,
    },
    onFilterChange: (
      changedColumn: string,
      filterList: string[],
      type: string,
      changedColumnIndex: number
    ) => {
      localStorage.setItem(
        `${props.id}Filter-${changedColumn}`,
        JSON.stringify(filterList[changedColumnIndex] || [])
      );
    },
    sortOrder,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      localStorage.setItem(
        `${props.id}Sorting`,
        JSON.stringify({
          name: changedColumn,
          direction,
        })
      );
    },
    customToolbar: () => {
      return (
        <>
          {props.startCustomToolbar}
          {props.bulkEdit && props.handleSaveData ? (
            <EditButton
              cancelTooltip={t('kiway-datatables:cancelTooltip')}
              clear={props.clear}
              editTooltip={t('kiway-datatables:editTooltip')}
              handleSave={props.handleSaveData}
              isInEditingMode={props.isInEditingMode}
              saveTooltip={t('kiway-datatables:saveTooltip')}
              toggleEditMode={props.toggleEditMode}
            />
          ) : null}
          {props.bulkEdit &&
          props.handleSaveData &&
          props.handleAddData &&
          !props.isInEditingMode ? (
            <Tooltip title={t('kiway-datatables:addTooltip')}>
              <IconButton onClick={props.handleAddData}>
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {props.endCustomToolbar}
        </>
      );
    },
    ...props.options,
  } as MUIDataTableOptions;

  return (
    <>
      <MUIDataTable
        columns={props.columns}
        data={props.loading ? [] : props.data}
        options={options}
        title={props.title}
      />
    </>
  );
}
