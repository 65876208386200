import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: 'transparent',
    border: '2px solid grey',
    borderRadius: 10,
  },

  titlePart: {
    fontSize: '2em !important',
  },

  summaryCard: {
    backgroundColor: '#ededed !important',
  },

  classicCard: {},

  totalPriceLine: {
    fontSize: '1.4em !important',
  },
}));
