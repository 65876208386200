import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import {
  FileUploaderController,
  IStoredFileAttributes,
} from '@kiway/shared/features/storage-react';

export interface BankTransferProps {
  disabled?: boolean;
  handleBankTransferProofDeleted: (isDeleted?: boolean) => any;
  handleBankTransferProofUploaded: (fileUploaded: any[]) => any;
  file?: IStoredFileAttributes;
}

export function BankTransfer(props: BankTransferProps) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography>Banque</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              <b>Novo Banco</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>IBAN</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              <b>PT50 0007 0000 0034 3111 2142 3</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>SWIFT/BIC</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              <b>BESCPTPL</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>
              {t('pharmaco:orderDetails.payment.forms.address')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid container>
              <Grid item xs={12}>
                <Typography>
                  <b>Yao Dao Chinese Herbs</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Rua Rui de Moura Belo n°2</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>2925-226 Azeitao</Typography>
              </Grid>
              <Grid item>
                <Typography>Portugal</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <FileUploaderController
          accept="image/*,.pdf"
          directory="bankTransfer"
          disabled={props.disabled}
          files={props.file ? [props.file] : undefined}
          handleFileUploaded={props.handleBankTransferProofUploaded}
          handleFileDeleted={props.handleBankTransferProofDeleted}
          buttonText={t('pharmaco:orderDetails.payment.forms.sendProof')}
        />
      </Grid>
    </Grid>
  );
}
