import * as React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import config from '../../../config';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Order, Customer } from '@kiway/ecommerce-react-compatible';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AddressFormController, DisplayAddress } from '@kiway/shared/ui';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Address } from '@kiway/shared/features/authentication-react-compatible';

export interface CustomerInfoProps {
  addressEditMode?: boolean;
  customer?: Customer;
  handleClickAddressEdit?: () => void;
  handleSaveAddress?: (
    shippingAddress: Address,
    invoicingAddress: Address
  ) => void;
  hideButton?: boolean;
  order?: Order;
}

export function CustomerInfo(props: CustomerInfoProps) {
  const { t } = useTranslation();

  const [shippingAddress, setShippingAddress] = React.useState<Address>(
    props.order?.getShippingAddress()
  );
  React.useEffect(() => {
    setShippingAddress(props.order?.getShippingAddress());
    return () => {
      setShippingAddress(null);
    };
  }, [props.order?.getShippingAddress()]);

  const [invoicingAddress, setInvoicingAddress] = React.useState<Address>(
    props.order?.getInvoicingAddress()
  );
  React.useEffect(() => {
    setInvoicingAddress(props.order?.getInvoicingAddress());
    return () => {
      setInvoicingAddress(null);
    };
  }, [props.order?.getInvoicingAddress()]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="body1">
                  <b>
                    {t(`${config.namespace}:orderDetails.client.firstName`)}
                  </b>
                  {' :'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {props?.customer?.getFirstName() || 'Ø'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="body1">
                  <b>{t(`${config.namespace}:orderDetails.client.lastName`)}</b>
                  {' :'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {props?.customer?.getLastName() || 'Ø'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="body1">
                  <b>{t(`${config.namespace}:orderDetails.client.email`)}</b>
                  {' :'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {props?.customer?.getEmail() || 'Ø'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="body1">
                  <b>
                    {t(`${config.namespace}:orderDetails.client.mobilePhone`)}
                  </b>
                  {' :'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {props?.customer?.getMobilePhone() || 'Ø'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {props.order && (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography variant="body1">
                    <b>
                      {t(
                        `${config.namespace}:orderDetails.order.deliveryAddress`
                      )}
                    </b>
                    {' :'}
                  </Typography>
                </Grid>
                <Grid item>
                  {shippingAddress && shippingAddress.validateAddress() ? (
                    <>
                      {!props.addressEditMode ? (
                        <DisplayAddress address={shippingAddress} />
                      ) : (
                        <AddressFormController
                          address={shippingAddress?.toJSON()}
                          setAddress={(newAddress) => {
                            setShippingAddress(new Address(newAddress));
                          }}
                        />
                      )}
                    </>
                  ) : (
                    'Ø'
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography variant="body1">
                    <b>
                      {t(
                        `${config.namespace}:orderDetails.order.paymentAddress`
                      )}
                    </b>
                    {' :'}
                  </Typography>
                </Grid>
                <Grid item>
                  {invoicingAddress && invoicingAddress.validateAddress() ? (
                    <>
                      {!props.addressEditMode ? (
                        <DisplayAddress address={invoicingAddress} />
                      ) : (
                        <AddressFormController
                          address={invoicingAddress?.toJSON()}
                          setAddress={(newAddress) => {
                            setInvoicingAddress(new Address(newAddress));
                          }}
                        />
                      )}
                    </>
                  ) : (
                    'Ø'
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!props.hideButton && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {props?.customer?.getEmail() && (
              <Grid item>
                <Button
                  href={`mailto:${props?.customer?.getEmail()}`}
                  variant={'contained'}
                  color={'minoritary'}
                  size={'small'}
                >
                  {t(`${config.namespace}:orderDetails.sendMail`)}
                </Button>
              </Grid>
            )}
            {props.order?.getShipmentStatus() !== 'SHIPPED' &&
            props.handleClickAddressEdit ? (
              !props.addressEditMode ? (
                <Grid item>
                  <Button
                    variant={'contained'}
                    color={'minoritary'}
                    size={'small'}
                    onClick={props.handleClickAddressEdit}
                  >
                    {t(`${config.namespace}:orderDetails.editAddresses`)}
                  </Button>
                </Grid>
              ) : (
                <Grid item>
                  <Button
                    variant={'contained'}
                    color={'minoritary'}
                    size={'small'}
                    onClick={() =>
                      props.handleSaveAddress(shippingAddress, invoicingAddress)
                    }
                  >
                    {t(`${config.namespace}:orderDetails.confirmAddress`)}
                  </Button>
                </Grid>
              )
            ) : null}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
