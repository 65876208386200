import Grid from '@material-ui/core/Grid';
import React, { useReducer } from 'react';
import { useTranslation } from '@kiway/shared/utils/translation';
import SearchBox from '../../SearchBox';
import GreenPageTextField from '../GreenPageTextField';
import { allCountries } from './CountryData';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const initState = {
  value: {
    name: 'France',
    regions: ['europe', 'european-union'],
    iso2: 'fr',
    dialCode: '33',
    format: '+.. . .. .. .. ..',
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_VALUE':
      return {
        ...state,
        value: action.payload,
      };
    default:
      throw new Error('Unhandled action type');
  }
};

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const renderInput = (t) => (params) => (
  <GreenPageTextField
    label={t('common:form.fields.phone.country')}
    margin={'dense'}
    {...params}
  />
);

function useCountrySelector(props = {}) {
  const { t } = useTranslation();
  const { type } = props;
  const [state, dispatch] = useReducer(reducer, initState, () => initState);
  const updateValue = (value) => {
    const treatedValue = allCountries.find(
      (item) => item.dialCode === '' + value
    );
    dispatch({
      type: 'CHANGE_VALUE',
      payload: treatedValue || initState.value,
    });
  };
  const component = (
    <SearchBox
      data={allCountries}
      width={'auto'}
      label={t('common:form.fields.phone.country')}
      autoComplete={true}
      value={state.value}
      marginZero
      forcePopupIcon={false}
      filterOptions={createFilterOptions({
        matchFrom: 'any',
        stringify: (option) =>
          option.name + ' ' + option.dialCode + ' ' + option.iso2,
      })}
      setValue={(value) =>
        dispatch({ type: 'CHANGE_VALUE', payload: value || initState.value })
      }
      getOptionSelected={(option, value) => {
        return (
          option.name === value.name &&
          option.dialCode === value.dialCode &&
          option.iso2 === value.iso2
        );
      }}
      getString={(value) => {
        return value && value.iso2
          ? `${countryToFlag(value.iso2)} (+${value.dialCode})`
          : '';
      }}
      margin={type === 'green' ? undefined : 'dense'}
      renderInput={type === 'green' ? renderInput(t) : undefined}
      renderOption={(option) => (
        <Grid container alignItems="center" style={{ width: '100%' }}>
          <Grid item xs>
            {`${countryToFlag(option.iso2)} ${option.name} (+${
              option.dialCode
            })`}
          </Grid>
        </Grid>
      )}
      clearOnBlur={true}
      {...props}
    />
  );
  return [state, component, updateValue];
}

export default useCountrySelector;
