export default {
  fields: {
    appointmentDate: {
      error: "Le début et la fin d'un rendez-vous doivent être le même jour",
    },
    email: {
      error: 'Merci de renseigner une adresse mail valide',
    },
    lastname: {
      error: 'Merci de renseigner votre nom',
    },
    yourMessage: {
      error: 'Veuillez écrire un message',
    },
  },
  login: {
    accountNotVerified:
      "Votre compte n'a pas été activé, merci de contacter un administrateur via le chat ou à contact@kiway.co",
    error: 'Identifiants invalides.',
    noRights:
      "Vous n'avez pas les droits suffisants pour vous connecter sur cet espace, êtes-vous sûr d'être sur le bon ?",
    resetError:
      "Une erreur est survenue durant l'envoi de votre requête. Merci d'essayer à nouveau.",
  },
  requiredField: {
    empty: 'Champ requis',
  },
};
