export default {
  addEvent: {
    modal: {
      confirmEmail: 'Send a confirmation email',
      notifyWarning:
        'Please notify your patient of any changes in dates or addresses',
      smsReminder: 'Schedule text message reminder 24h before',
      title: 'Add an appointment',
    },
  },
  caption: 'Caption:',
  deleteAppointmentModal: {
    content:
      'Are you sure you want to delete this appointment? The operation is irreversible.',
    title: 'Delete appointment',
  },
  eventType: {
    other: 'Other',
    session: 'Patient session',
  },
  nextWeek: 'Next',
  prevWeek: 'Previous',
  settings: {
    daysDisplayed: {
      labels: {
        friday: 'Friday',
        monday: 'Monday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        thursday: 'Thursday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
      },
    },
    modal: {
      apply: 'Apply',
      button: 'Settings',
      close: 'Close',
      dayEnds: 'Day Ends At',
      dayStarts: 'Day Starts At',
      displayWeekends: 'Display Weekends',
      hideWeekends: 'Hide Weekends',
      slotDuration: 'Slot Duration',
      title: 'Agenda settings',
    },
    rowsPerHour: {
      title: 'Number of lines per hour',
    },
    timeSlots: {
      title: 'Limits of the displayed time range',
    },
  },
  smsIssue: 'Something went wrong',
  smsNone: 'No text reminder',
  smsScheduledAt: 'An SMS will be sent on',
  smsSentOn: 'SMS sent on',
  snackbars: {
    appointments: {
      error:
        'A problem occurred during the recording of the appointment. Please try again.',
      errorDelete:
        'A problem occurred during the deleting of the appointment. Please try again.',
      missingFields: 'Please fill in required fields',
      overlap: 'Appointments cannot be overlaping',
      pendingClick:
        'This slot is currently being booked online, you cannot edit it yet.',
      successCreate: 'Appointment created successfully',
      successDelete: 'Appointment deleted successfully',
      successUpdate: 'Appointment updated successfully',
    },
    load: {
      error:
        'A problem occurred during the load. Please refresh the app to try again.',
    },
    settings: {
      error:
        'A problem occurred during the recording of the settings. Please try again.',
      successUpdate: 'Settings updated successfully',
    },
  },
  updateEvent: {
    modal: {
      title: 'Update an appointment',
    },
  },
  views: {
    '3days': '3 days',
    day: 'Day',
    list: 'List',
    week: 'Week',
  },
  weekShort: 'Week',
};
