export default {
  asterisks: {
    onReferralFirstPurchase: "Upon referral's first purchase",
    onReferralPurchases: "On all referrals' purchases",
    shareLinkExplanation:
      'Copy the link directly by clicking on it. Your referrals must register via this link for the sponsorship to work',
  },
  buttons: {
    invite: 'Invite',
  },
  dataTable: {
    columns: {
      lastUpdate: 'Last update',
      referral: 'Referral',
      rewardAmount: 'Amount offered',
      rewardedBy: 'Rewarded by',
      rewardType: 'Reward type',
      status: 'Status',
      subType: 'Subscription type',
    },
    enums: {
      rewardType: {
        balance: 'Credits',
        cashback: 'Cashback',
        monthFree: '1 month free',
      },
      status: {
        completed: 'Completed',
        paid: 'Paid',
        pending: 'Pending',
        sent: 'Sent',
      },
    },
    yourReferrer: 'Your referrer',
  },
  errors: {
    global: 'An error has occured, please try again or contact us',
    noFullName:
      'Your first and last name must be filled in to send invitations',
    noReferrerCode:
      'Your account does not have a referral code, contact us via the chatbox or try to reconnect',
    noSub: 'You must have an active subscription to be able to sponsor',
  },
  inviteSuccess: '%X% practitioner(s) invited',
  text: {
    affiliationInstructions: 'To sponsor, 2 solutions:',
    caption: 'You recommend us, we reward you!',
    costPerAcquisitionAmount: '%X%€ free',
    forYou: 'for you',
    forYourReferral: 'for your referral',
    noSub: 'To sponsor, you must be a subscriber',
    oneMonthFree: '5€ free',
    revenueShareAmount: '%X%% free',
    sendMail: 'Send invitations directly by email',
    shareLink: 'Share your referral link',
  },
  titles: {
    affiliations: 'Affiliation',
    myReferrals: 'My referrals',
    myRewards: 'My rewards',
    referrerCode: 'My referrer code',
  },
};
