/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import { DatatablesController } from '@kiway/datatables';
import { Order } from '@kiway/ecommerce-react-compatible';
import { Button, Grid, Typography, Box } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import { AuthContext } from '@kiway/shared/features/authentication-react';
import { isGranted } from '@kiway/shared/utils/access-control-react';

export interface ListOrdersPrescriberProps {
  arrayId?: string;
  handleNewClick?: () => void;
  handleRowClick?: (rowIndex: number) => void;
  handleRemoveOrder?: (id: string) => void;
  hideArrayPagination?: boolean;
  hideArrayTitle?: boolean;
  hideArrayToolbar?: boolean;
  hideCustomer?: boolean;
  loading?: boolean;
  loyaltyPointsBalance?: number;
  orders: Order[];
}

export function ListOrdersPrescriber(
  props: React.PropsWithChildren<ListOrdersPrescriberProps>
) {
  const {
    authState: { userInfo },
  } = React.useContext(AuthContext);
  const { t } = useTranslation();

  const removeOrder = (id: string) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    props.handleRemoveOrder(id);
  };

  return (
    <Grid container spacing={props.hideCustomer ? undefined : 2}>
      <Grid item xs={12}>
        <DatatablesController
          bulkEdit={false}
          columns={[
            {
              name: 'orderNumber',
              label: t('pharmaco:orders.columns.orderNumber'),
              type: 'text',
            },
            {
              name: 'openedAt',
              label: t('pharmaco:orders.columns.openedAt'),
              type: 'simple-date',
            },
            {
              name: 'paidAt',
              label: t('pharmaco:orders.columns.paidAt'),
              type: 'simple-date',
            },
            {
              name: 'customer',
              label: t('pharmaco:orders.columns.patient'),
              type: 'text',
              options: {
                display: props.hideCustomer ? false : true,
              },
            },
            {
              name: 'status',
              label: t('pharmaco:orders.columns.globalStatus'),
              type: 'status',
            },
            {
              name: 'totalGrossPrice',
              label: t('pharmaco:orders.columns.totalGrossPrice'),
              type: 'text',
              options: {
                display: false,
              },
            },
            {
              name: 'totalNetPrice',
              label: t('pharmaco:orders.columns.totalNetPrice'),
              type: 'text',
              options: {
                display: false,
              },
            },
            {
              name: 'totalPrice',
              label: t('pharmaco:orders.columns.totalPrice'),
              type: 'text',
            },
            {
              name: 'actions',
              label: ' ',
              type: 'text',
              options: {
                customBodyRender(value, tableMeta, updateValue) {
                  return isGranted(
                    'ROLE_PRACTITIONER_PHARMACO',
                    userInfo.roles
                  ) && value.isDraft ? (
                    <Button onClick={removeOrder(value.orderId)}>
                      {t('common:form.buttons.delete')}
                    </Button>
                  ) : null;
                },
                sort: false,
                filter: false,
                print: false,
                searchable: false,
                download: false,
                draggable: false,
                viewColumns: false,
                empty: true,
              },
            },
            // {
            //   name: 'shippingTrackingNumber',
            //   label: 'Suivi',
            //   type: 'text',
            // },
          ]}
          data={props.orders
            ?.map((item) => item.toDatatableRow())
            ?.map(
              ({
                orderStatus,
                paymentStatus,
                packingStatus,
                shipmentStatus,
                shippingMethod,
                status,
                ...order
              }) => ({
                ...order,
                // ...flatten({ shippingMethod }),
                'shippingMethod.carrier.name':
                  shippingMethod?.carrier?.name ?? '',
                'shippingMethod.name': shippingMethod?.name ?? '',
                'shippingMethod.price': shippingMethod?.price ?? {},
                status: {
                  ...status,
                  label: `pharmaco:orderDetails.global.status.${status.label}`,
                },
                orderStatus: {
                  ...orderStatus,
                  label: `pharmaco:orderDetails.order.status.${orderStatus.label}`,
                },
                paymentStatus: {
                  ...paymentStatus,
                  label: `pharmaco:orderDetails.payment.status.${paymentStatus.label}`,
                },
                packingStatus: {
                  ...packingStatus,
                  label: `pharmaco:orderDetails.packing.status.${packingStatus.label}`,
                },
                shipmentStatus: {
                  ...shipmentStatus,
                  label: `pharmaco:orderDetails.shipment.status.${shipmentStatus.label}`,
                },
                actions: {
                  isDraft: orderStatus.label === 'DRAFT',
                  orderId: order._id,
                },
              })
            )}
          defaultSortOrder={{ name: 'orderNumber', direction: 'desc' }}
          endCustomToolbar={
            <Button
              variant="contained"
              color="minoritary"
              onClick={props.handleNewClick}
            >
              {t('pharmaco:order.newOrder')}
            </Button>
          }
          handleRowClick={props.handleRowClick}
          id={props.arrayId ?? 'listOrders-prescriber'}
          loading={props.loading}
          refreshData={() => console.log}
          title={
            !props.hideArrayTitle ? (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6">
                  {t('pharmaco:orders.docSubtitle')}
                </Typography>
                <Typography variant="subtitle1">
                  {t('loyaltyProgram:pointsBalance')}:{' '}
                  {Number(props.loyaltyPointsBalance / 100).toFixed(2) ?? 0}
                </Typography>
              </Box>
            ) : (
              ''
            )
          }
          options={{
            filter: false,
            pagination: !props.hideArrayPagination,
          }}
        />
      </Grid>
      <Grid item xs={12} textAlign="right">
        {props.children}
        {props.hideArrayTitle && (
          <Button
            variant="contained"
            color="minoritary"
            onClick={props.handleNewClick}
          >
            {t('pharmaco:order.newOrder')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
