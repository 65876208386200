import { Retailer } from '../entities/Retailer';
import { RetailerProvider } from './RetailerProvider';

export class GetAllRetailers {
  private retailerProvider: RetailerProvider;

  public constructor(retailerProvider: RetailerProvider) {
    this.retailerProvider = retailerProvider;
  }

  public execute(): Promise<Retailer[]> {
    return this.retailerProvider.findAll();
  }
}
