export default {
  address: {
    addAddress: 'Add address',
    address: 'Address',
    city: 'City',
    country: 'Country',
    fullName: 'Full name',
    label: 'Address',
    number: 'Number',
    useAddress: 'Use address',
    useAsDefault: 'Use as default',
    validate: 'Validate modifications',
    zipCode: 'ZIP Code',
  },
  backToList: 'Back to list',
  backToOrderDetails: 'Back to order details',
  browser: {
    addCart: 'Add to cart',
    all: 'All',
    emptyCart: 'Empty cart',
    formula: 'Formulas',
    ingredients: 'Ingredients',
    jingFang: 'Jing Fang',
    noFilter: 'No filter',
    normal: 'Normals',
    noSelected: 'No selected plant',
    plant: 'Plants',
    powder: 'Concentrated powder',
    sentence: {
      all: 'all items',
      contains: 'which contain',
      display: "You're displaying",
      formulas_jingFang: 'Jing Fang formulas',
      formulas_normal: 'normal formulas',
      formulas_xiaoFang: 'Xiao Fang formulas',
      have_action: 'which have the action',
      have_category: 'which have the category',
      have_syndrom: 'treating the syndrom',
      ingredients_all: 'all the ingredients',
      ingredients_plant: 'the ingredients available in dried plants',
      ingredients_powder: 'the ingredients available in concentrated powder',
    },
    xiaoFang: 'Xiao Fang',
  },
  button: {
    discard: 'Discard',
    save: 'Save',
  },
  cancel: 'Cancel',
  cancelOrder: {
    message: 'Are you sure you want to cancel this order?',
    title: 'Cancel order',
  },
  carriers: {
    code: {
      helperText: 'Useful for developer, do not modify',
      label: 'Automation code',
    },
    docSubtitle: 'Carriers list',
    label: {
      en: 'EN name',
      es: 'ES name',
      fr: 'FR name',
      pt: 'PT name',
    },
    linkText: 'Carriers',
    trackingLink: {
      helperTextError:
        'Replace the tracking number in the link with {{trackingNumber}}',
      label: 'Parcel tracking link',
    },
  },
  checkout: {
    acceptation: 'Acceptation',
    address: 'Verify address',
    approvment: 'Approval',
    cardPaymentSuccess: 'Payment successful.',
    confirmAddress: 'I confirm that this information is correct',
    confirmAddressMessage:
      'Please check that the address information is correct. We will not be held responsible for non-delivery of your order.',
    confirmation: 'Confirmation',
    deferredPaymentSuccess: 'Please remember to send your payment.',
    deliveryPayment: 'Delivery and payment',
    errorDeferredPayment:
      'An error occurred during payment. Please contact an administrator.',
    errorSnackbarAddress:
      'Invalid address, please fill in the required fields marked with an *',
    homeReturn: 'Back to home',
    proSpaceReturn: 'Back to your space',
    wish:
      'I wish to receive emails from Yao Dao informing me about offers, promotions, news, internships and training related to Chinese pharmacopoeia and traditional Chinese medicine.',
  },
  composition: {
    dosage: 'Dosage',
    name: 'Name',
    title: 'Composition',
  },
  countries: {
    docSubtitle: 'Countries list',
    label: {
      code: 'ISO Code',
      name: {
        en: 'EN name',
        es: 'ES name',
        fr: 'FR name',
        pt: 'PT name',
      },
      vatPlant: 'Plants VAT',
      vatPowder: 'Concentrated powders VAT',
    },
    linkText: 'Countries',
  },
  countryZones: {
    countries: 'Countries list',
    docSubtitle: 'Country zones list',
    label: {
      name: 'Name',
    },
    linkText: 'Country zones',
  },
  dashboard: {
    analytics: {
      avgCart: 'Average cart',
      charts: {
        orderStatus: 'Order status',
        preparerStats: 'Preparer stats',
      },
      extra: {
        period: {
          month: 'last month',
          prevMonth: 'the previous month',
          prevQuarter: 'the previous quarter',
          prevWeek: 'the previous week',
          prevYear: 'the previous year',
          quarter: 'last quarter',
          today: 'yesterday',
          week: 'last week',
          year: 'last year',
          yesterday: 'the previous day',
        },
        profit: {
          less: 'less than',
          more: 'more than',
        },
        type: {
          clients: 'clients',
          orders: 'orders',
        },
      },
      HT_HT: 'Excluding transport and taxes',
      nbOrder: 'Number of orders',
      orderCancelled: 'Cancelled orders',
      orderCompleted: 'Completed orders',
      periods: {
        lastQuarter: 'Previous quarter',
        month: 'Last month',
        prevMonth: 'Previous month',
        prevWeek: 'Previous week',
        prevYear: 'Previous year',
        quarter: 'Last quarter',
        today: 'Today',
        week: 'Last week',
        year: 'Last year',
        yesterday: 'Yesterday',
      },
      revenue: 'Revenue',
      running: 'Running',
      title: 'Dashboard',
      totalClients: 'Total clients',
      totalOrders: 'Total orders',
    },
  },
  deleteOrder: {
    message: 'Are you sure you want to delete this order?',
    title: 'Delete order',
  },
  filters: {
    carriers: {
      all: 'Tous',
      label: 'Carrier',
    },
    metadatas: {
      action: 'Select an action',
      formulasCategory: 'Select a category of formulas',
      ingredientsCategory: 'Select a category of ingredients',
      syndrom: 'Select a syndrom',
    },
    shippingMethods: {
      all: 'All',
      label: 'Shipping method',
    },
    variations: {
      all: 'All',
      label: 'Variation',
    },
  },
  formula: {
    clickPlantToSeeMore: 'Click on a plant to see its profile',
    concentration: 'Concentration rate',
    description: '{{code}} fang zheng',
    detailsDocSubtitle: 'Formula details',
    detailsLinkText: 'Formulas details',
    detailsSuccessUpdate_one: 'Change saved',
    detailsSuccessUpdate_other: 'Changes saved',
    docSubtitle: 'List formulas',
    dosage: 'Dosage',
    dosagePlant: 'Dosage in dry plants',
    dosagePowder: 'Dosage in concentrated powder',
    equivalentCP: 'Equiv. CP',
    equivalentPS: 'Equiv. DP',
    fangZheng: 'Fang Zheng',
    jingFang: 'Jing Fang',
    legacyId: 'Legacy ID:',
    linkText: 'Formulas',
    listTitle: 'Formulas',
    normal: 'Normal',
    percent: '%',
    pinYinName: 'Pinyin name',
    priceHT: 'Gross price',
    print: 'Print formula',
    published: 'Published',
    separate: 'Separate',
    total: 'Total',
    totalWeight: 'Total weight',
    variation: 'Variation',
    weightPerCapsule: 'Per capsule',
    weightPerPortion: 'Per bag',
    xiaoFang: 'Xiao Fang',
  },
  lastOrders: {
    title: 'Last orders',
  },
  linkText: 'Pharmacopea',
  list: {
    docSubtitle: 'List plants',
    linkText: 'Plants',
  },
  loading: 'Loading...',
  modify: 'Modify',
  next: 'Next',
  noData: 'No data',
  order: {
    addToOrder: 'Add to order',
    aknowledgement: 'Thank you for your order!',
    commandDetails: {
      command: 'Command',
      commandDetails: 'Command details',
      date: 'Date',
      product: 'Product',
      quantity: 'Quantity',
      sentTo: 'Sent to',
    },
    emptyOrder: 'Empty order',
    newOrder: 'New order',
    payNowButton: 'Confirm address and pay',
    resumeMessage:
      'Your order has been placed, the patient will receive an email with a payment link. You can now pay it using the button below.',
    steps: {
      comments: 'Comments',
      prescription: 'Shopping cart',
      resume: 'Confirmation',
      shipping: 'Shipping',
    },
  },
  orderCount: 'Number of orders',
  orderDetails: {
    buttons: {
      addItemsToOrder: 'Add products to order',
      dispatch: 'Dispatch',
      followPackage: 'Follow my package',
      invoice: 'Invoice',
      mailReminder: 'Mail reminder',
      markAsPaid: 'Mark as paid',
      prepare: 'Prepare',
      seePayment: 'See payment',
      sendMail: 'Send mail again',
      sendTracking: 'Send tracking again',
      startPrepare: 'Start prepare',
    },
    cartPrice: 'Cart price',
    changePatient: 'Change patient',
    client: {
      address1: 'Address 1',
      address2: 'Address 2',
      city: 'City',
      country: 'Country',
      email: 'Email',
      firstName: 'First Name',
      indicator: 'Indicator',
      label: 'Patient',
      lastName: 'Last Name',
      mobilePhone: 'Mobile phone',
      nif: 'NIF',
      zip: 'ZIP Code',
    },
    comment: {
      assistantComment: 'Comment for assistant',
      default: 'Ø',
      label: 'Comment',
      none: 'No comment',
      ownComment: 'Comment for you',
      patientComment: 'Comment for patient',
    },
    confirmAddresses: 'Confirm addresses',
    docSubtitle: 'Order details',
    editAddresses: 'Edit addresses',
    formula: {
      formulaCount_one: '{{ count }} formula:',
      formulaCount_other: '{{ count }} formulas:',
      label: 'Formulas',
      name: 'Name',
      pinYin: 'Pin Yin',
      priceHT: 'Gross price',
      quantity: 'Quantity',
      ref: 'Reference',
      separate: 'Separate',
      total: 'Total',
      variation: 'Variation',
    },
    global: {
      label: 'Global status',
      status: {
        ALL: 'All orders',
        CANCELLED: 'Cancelled',
        COMPLETE: 'Complete',
        DRAFT: 'Draft',
        ERROR_PACKING: 'Error',
        ERROR_SYNC_STATUS: 'Error',
        PENDING_PACKING: 'Pending packing',
        PENDING_PAYMENT: 'Pending payment',
        PENDING_SHIPMENT: 'Shipment in progress',
        VALIDATED: 'Validated',
        WAITING_PACKING: 'Waiting packing',
        WAITING_PAYMENT: 'Waiting payment',
        WAITING_SHIPMENT: 'Awaiting shipment',
      },
    },
    information: {
      itemNumber: 'Item Number',
      label: 'Information',
      packaging: 'Packaging',
      paymentMode: 'Payment Mode',
      paymentStatus: 'Payment Status',
      preparationStatus: 'Preparation Status',
      shippingMethod: 'Shipping Method',
      shippingPrice: 'Shipping Price',
      totalWeight: 'Total weight',
      weight: 'Weight',
    },
    mailReminder: {
      confirmError:
        'A problem occurred while sending the email. Please try again.',
      confirmSuccess:
        'The email containing the payment link has been sent to the customer on the email address associated with the delivery address.',
      confirmText:
        'Are you sure you want to email the payment link back to the customer?',
      confirmTitle: 'Resend the payment link by email',
    },
    mailTracking: {
      confirmError:
        'A problem occurred while sending the email. Please try again.',
      confirmSuccess:
        'The email containing the tracking link has been sent to the customer on the email address associated with the delivery address.',
      confirmText:
        'Are you sure you want to email the tracking link back to the customer?',
      confirmTitle: 'Resend the tracking link by email',
    },
    openOrder: 'Open Order',
    order: {
      deliveryAddress: 'Delivery Address',
      finishPreparation: 'Finish preparation',
      finishShipping: 'Finish shipping',
      label: 'Order',
      newAddress: 'New address',
      paymentAddress: 'Payment Address',
      sameAddress: 'Billing address identical to delivery address',
      status: {
        CANCELLED: 'Cancelled',
        COMPLETE: 'Complete',
        DRAFT: 'Draft',
        OPEN: 'Open',
      },
      validAddress: 'Please fill in a valid address',
    },
    packing: {
      label: 'Packing status',
      preparedAt: 'Prepared at',
      preparedBy: 'Prepared by',
      progress: 'Progress',
      status: {
        DELAYED: 'Delayed',
        PENDING: 'In progress',
        PREPARED: 'Prepared',
        READY: 'Waiting packing',
        WAITING: 'Waiting',
      },
      weight: {
        content: 'Content',
        error:
          'The total weight must be greater than or equal to the content weight',
        packing: 'Packing',
        total: 'Package total weight',
      },
    },
    payment: {
      amount: 'Amount',
      commandPaymentTitle: 'Order n°{{ orderNumber }} payment',
      docSubtitle: 'Payments list',
      forms: {
        address: 'Address',
        bank: 'Bank',
        bankTransfer: 'Bank transfer',
        bankTransferDescription: 'Pay with your bank transfer',
        bankTransferInformationLabel: 'Bank transfer information',
        card: 'Credit card',
        cardDescription: 'Pay with your credit card',
        cardNumber: 'Card number',
        check: 'Bank check',
        checkDescription: 'Pay with your bank check',
        checkInformationLabel: 'Check information',
        checkNumber: 'Check number',
        chooseFile: 'Choose file',
        cvv: 'Secret code',
        expirationDate: 'Expiration date',
        firstName: 'First name',
        lastName: 'Last name',
        nameOnCard: 'Name on card',
        order: 'Order',
        resume: 'Resume',
        sendProof: 'Send proof of transfer',
      },
      label: 'Payment status',
      linkText: 'Payments',
      paidAt: 'Paid at',
      pay: 'Pay',
      paymentFees: 'Payment fees',
      paymentMethod: 'Payment method',
      simulate: 'Simulate payment',
      status: {
        BALANCE_DUE: 'Balance due',
        CREDIT_OWED: 'Credit owed',
        DRAFT: 'Draft',
        FAILED: 'Failed',
        PAID: 'Paid',
        PENDING: 'Pending',
        WAITING: 'Waiting',
      },
    },
    reportError: 'Report an error',
    selectPatient: 'Select a patient',
    sendMail: 'Send an email',
    shipment: {
      carrierLabel: {
        canNotGenerateLabel:
          'Automatic label generation is not available for this carrier',
        displayLabelNewTab: 'Open in a new tab',
        generateLabel: 'Generate label',
        label: 'Parcel label',
      },
      coordonates: 'Coordinates',
      deliveryInfo: 'Delivery info',
      label: 'Shipment status',
      selectMethod: 'Select shipment method',
      sentAt: 'Sent at',
      sentBy: 'Sent by',
      shipmentMethod: 'Shipment method',
      shipmentMethodCost: 'Shipment cost',
      status: {
        BACKORDER: 'Backorder',
        DELAYED: 'Delayed',
        PENDING: 'Shipment in progress',
        READY: 'Awaiting shipment',
        SHIPPED: 'Shipped',
        WAITING: 'Waiting',
      },
      trackingNumber: 'Tracking number',
      transporter: {
        label: 'Transport',
        method: 'Shipping mode',
        name: 'Transporter name',
        price: 'Shipping cost',
      },
      weighing: 'Weighing',
    },
    status: 'Status',
    therapist: {
      dispenser: 'Dispenser',
      label: 'Therapist',
      sender: 'Sender',
    },
  },
  orders: {
    columns: {
      carrier: 'Carrier',
      customer: 'Customer',
      customerPhone: 'Customer phone',
      globalStatus: 'Status',
      nbGroups: 'Formulas',
      nbItems: 'Lines',
      nbOfBags: 'Bags',
      openedAt: 'Saved',
      orderNumber: 'Number',
      orderStatus: 'Order status',
      packingStatus: 'Packing status',
      paidAt: 'Paid',
      patient: 'Patient',
      paymentStatus: 'Payment status',
      prescriber: 'Prescriber',
      shipmentStatus: 'Shipment status',
      shipping: 'Shipping',
      shippingMethod: 'Shipping method',
      shippingPrice: 'Shipping cost',
      totalGrossPrice: 'Total gross',
      totalNetPrice: 'Total net',
      totalNetPriceWT: 'Total net (without transport)',
      totalPrice: 'Total to pay',
      variationTypes: 'Type',
    },
    docSubtitle: 'Orders list',
    linkText: 'Orders',
    orderResume: 'Order resume',
  },
  plant: {
    availableConcentratedPowder: 'Available in concentrated powder',
    availablePlant: 'Available in plant',
    chineseName: 'Chinese name',
    commonName: 'Common name',
    concentratedPowder: 'Concentrated powder',
    concentrationRate: 'Concentration rate',
    detailsDocSubtitle: 'Plant details',
    detailsLinkText: 'Plants details',
    detailsSuccessUpdate_one: 'Change saved',
    detailsSuccessUpdate_other: 'Changes saved',
    dryPlant: 'Dry plant',
    latinName: 'Latin name',
    legacyId: 'Legacy ID:',
    listTitle: 'Plants',
    otherName: 'Other names',
    pinYinName: 'Pinyin name',
    plant: 'Plant',
    posology: 'Posology',
    posologyMax: 'Posology max',
    posologyMin: 'Posology min',
    published: 'Published',
    refStock: 'Stock ref',
    refStockPlant: 'Plant stock ref',
    refStockPowder: 'Powder stock ref',
    sellAvailable: 'Available for sale',
    toxic: 'Toxic',
    noToxic: 'No toxic',
    unavailableConcentratedPowder: 'Unavailable in concentrated powder',
    unavailablePlant: 'Unavailable in plant',
  },
  posologies: {
    displayChip: {
      max: 'To {{max}}g',
      min: 'From {{min}}g',
      minMax: 'From {{min}}g to {{max}}g',
    },
    max: 'Maximum',
    min: 'Minimum',
    requirementsNotOK:
      'The min value has to be lower than or equal to the max value',
    title: 'Posologies',
  },
  prescription: {
    addPod: 'Add a measuring spoon',
    decoction: 'Simplified decoction',
    docSubtitle: 'Prescription',
    nbOfBags: 'Number of bags',
    nbOfCapsulesBatch:
      'Number of capsule batches (1 batch = 100 capsules = 50g)',
    order: {
      label: 'Order',
    },
    pharmaceuticalForm: {
      capsule: 'Capsule',
      label: 'Pharmaceutical form',
      small_bag: 'Bag',
    },
    plantPowderGroundSize: {
      big_ground: 'Ground coarse',
      fine_ground: 'Fine ground',
      label: 'Packaging',
      whole: 'Whole plant',
    },
    plantPowderType: {
      label: 'Type of substance',
      plant: 'Plant',
      powder: 'Concentrated powder',
    },
    pod: 'Measuring spoon',
    posology: {
      capsule: {
        plant: {
          resume_one:
            'I want {{nbOfCapsulesBatch}} pack of {{capsulesBatchSize}} capsules of {{capsuleWeight}}g of this formula in dry plants',
          resume_other:
            'I want {{nbOfCapsulesBatch}} packs of {{capsulesBatchSize}} capsules of {{capsuleWeight}}g of this formula in dry plants',
          title: 'DP capsule posology',
        },
        powder: {
          resume_one:
            'I want {{nbOfCapsulesBatch}} pack of {{capsulesBatchSize}} capsules of {{capsuleWeight}}g of this formula in concentrated powder',
          resume_other:
            'I want {{nbOfCapsulesBatch}} packs of {{capsulesBatchSize}} capsules of {{capsuleWeight}}g of this formula in concentrated powder',
          title: 'CP capsule posology',
        },
      },
      plant: {
        resume_one:
          'I want {{nbOfBags}} bag of {{portionWeight}}g of this formula in dry plants ({{variation}})',
        resume_other:
          'I want {{nbOfBags}} bags of {{portionWeight}}g of this formula in dry plants ({{variation}})',
        title: 'DP posology',
      },
      powder: {
        resume:
          'I want 1 bag of {{totalWeight}}g of this formula in concentrated powder',
        title: 'CP posology',
      },
      shortenDescription: {
        capsule: {
          resume_one:
            '{{nbOfCapsulesBatch}} batch {{capsulesBatchSize}} {{abbreviation}}',
          resume_other:
            '{{nbOfCapsulesBatch}} batches {{capsulesBatchSize}} {{abbreviation}}',
        },
        plant: {
          resume_one: '{{nbOfBags}} bag {{portionWeight}}g {{abbreviation}}',
          resume_other: '{{nbOfBags}} bags {{portionWeight}}g {{abbreviation}}',
        },
        powder: {
          resume: '1 bag of {{totalWeight}}g {{abbreviation}}',
        },
      },
    },
    quantity: {
      default: 'Total weight (in grams)',
      portion: 'Weight per bag (in grams)',
      total: 'Total weight (in grams)',
    },
    reasonCantGoToCommentSection: {
      noCustomer: 'todo',
      noFormulas: 'todo',
      notAllItemsAvailable: 'todo',
      unknown: 'todo',
    },
    reasonCantValidateOrder: {
      invalidAddress: 'todo',
      loyaltyProgramError: 'todo',
      noShippingMethod: 'todo',
      notAllItemsAvailable: 'todo',
      unknown: 'todo',
    },
    resume: {
      cartGrossTotal: 'Shopping cart cost excl. tax',
      cartNetTotal: 'Shopping cart cost incl. tax',
      deliveryCost: 'Shipping cost',
      loyaltyOption: {
        label: 'todo',
        addPoints: 'todo',
        pay10Percent: 'todo',
        pay15Percent: 'todo',
        pay20Percent: 'todo',
        pay5Percent: 'todo',
      },
      missingCostsExplanation:
        'Taxes and delivery charges will be calculated at the delivery stage',
      totalToPay: 'Total to pay',
    },
    weightMode: {
      label: 'Weight equivalent to ?',
      portion: 'Weight per bag (in grams)',
      total: 'Total weight',
    },
    withoutPod: 'Without measuring spoon',
    withPod: 'With measuring spoon',
  },
  previous: 'Previous',
  productMetadatas: {
    action: {
      cardTitle: 'Actions',
      groupByLabel: 'Action',
    },
    categoryFormula: {
      cardTitle: 'Categories',
      groupByLabel: 'Category',
    },
    categoryFormulaJingFang: {
      cardTitle: 'Jing Fang categories',
      groupByLabel: 'Jing Fang category',
    },
    categoryFormulaXiaoFang: {
      cardTitle: 'Xiao Fang categories',
      groupByLabel: 'Xiao Fang category',
    },
    categoryPlant: {
      cardTitle: 'Categories',
      groupByLabel: 'Category',
    },
    composition: 'Composition',
    docSubtitle: 'Products metadata',
    linkText: 'Metadata',
    meridian: {
      cardTitle: 'Meridians',
      groupByLabel: 'Meridian',
    },
    name: '{{lngCode}} name',
    nature: {
      cardTitle: 'Natures',
      groupByLabel: 'Nature',
    },
    plant: {
      cardTitle: 'Plants',
      groupByLabel: 'Plant',
    },
    productTypes: {
      formula: 'Formulas',
      plant: 'Plants',
    },
    productVariationType: {
      cardTitle: 'Variations',
      docSubtitle: 'Variations',
      groupByLabel: 'Variation',
      linkText: 'Variations',
      price: 'Gross price',
      shortcode: 'Abbreviation',
    },
    savour: {
      cardTitle: 'Savours',
      groupByLabel: 'Savour',
    },
    sellAvailable: 'Not available for sale',
    syndrom: {
      cardTitle: 'Syndroms',
      groupByLabel: 'Syndrom',
    },
  },
  productVariationTypes: {
    title: 'Variations',
  },
  radioButton: {
    addFormula: 'Add to formula',
    newFormula: 'Create new formula',
    placeholderNF: 'New formula name',
  },
  renewOrder: 'Renew order',
  search: {
    none: 'No options',
    search: 'Search',
  },
  sendVerifToUsePharmaco:
    'In order to place orders, you must send proof of training to contact@yao-dao.com',
  shippingMethodPrices: {
    countryZone: 'Country zone',
    docSubtitle: 'Shipping method prices list',
    label: {
      en: 'EN name',
      es: 'ES name',
      fr: 'FR name',
      pt: 'PT name',
    },
    linkText: 'Shipping method prices',
    maxWeight: 'Max weight',
    minWeight: 'Min weight',
    price: 'Price',
    shippingMethod: 'Shipping method',
  },
  shippingMethods: {
    docSubtitle: 'Shipping methods list',
    label: {
      active: 'Active',
      carrier: 'Carrier',
      displayOrder: 'Display order',
      name: {
        en: 'EN name',
        es: 'ES name',
        fr: 'FR name',
        pt: 'PT name',
      },
      pricesLength: 'Number of related prices',
      productCode: 'Product code',
    },
    linkText: 'Shipping methods',
  },
  shop: {
    title: 'Shop',
  },
  total: 'Total',
  validate: 'Validate',
  variations: {
    available: 'In stock',
    basePrice: 'Base Price',
    name: 'Name',
    price: 'Gross price',
    productRef: 'Product Reference',
    title: 'Variations',
    unavailable: 'Out of stock',
  },
  wholesalerSettings: {
    linkText: 'Settings',
  },
};
