export default {
  editProfile: {
    aboutMe: 'Sobre mim',
    address: {
      add: {
        button: 'Adicionar um endereço',
        modalTitle: 'Adicionar um endereço',
      },
      default: {
        button: 'Definação padrão',
        chip: 'Padrao',
      },
      delete: {
        button: 'Apagar',
        modalMessage: 'Tem certeza de que deseja excluir esta morada?',
        modalTitle: 'Apagar morada',
      },
      edit: {
        button: 'Editar',
        modalTitle: 'Editar morada',
      },
      title: 'Moradas',
    },
    cancelChangePicture: 'Cancelar',
    changeEmailError:
      'Ocorreu um erro durante a gravação. O endereço de email fornecido é inválido. Por favor tente novamente.',
    changeEmailInfo:
      'Um email será enviado para verificação. Clique no link presente no email.',
    changeEmailInvalid: 'Por favor insira um endereço de email válido.',
    changeEmailSuccess:
      'O endereço de email foi modificado, você deve clicar no link recebido por email para validar este endereço e poder usar o aplicativo.',
    changePasswordCancelButton: 'Cancelar',
    changePasswordConfirmButton: 'Validar',
    changePasswordError:
      'Ocorreu um erro durante o envio do email. Por favor tente novamente.',
    changePasswordInfo:
      'Você receberá um email contendo um link para redefinir sua senha.',
    changePasswordSuccess:
      'Um email chegará em breve. Clique no link para redefinir sua senha.',
    changePicture: 'Mude a foto',
    deletePicture: 'Exclua a foto',
    email: 'Email',
    emailInfo:
      'Se você alterar seu endereço de email, precisará verificar para que possa reconectar.',
    helperTextNIF: '',
    helperTextPicture: 'Apenas links seguros (https)',
    imageUrlLabel: 'Link para imagem',
    linkText: 'O meu perfil',
    nif: 'NIF',
    password: 'Senha',
    passwordInfo:
      'Sua senha não é armazenada É por isso que não podemos exibi-la.',
    saveChangePicture: 'Registro',
    saveError:
      'Um problema ocorreu durante a gravação. Por favor tente novamente.',
    saveSuccess: 'Modificações registradas',
    security: 'Segurança',
    sessions: {
      deleteAll: 'Elimine todas as sessões',
      deviceName: 'Nome',
      deviceType: 'Tipo',
      deviceTypes: {
        BROWSER: 'Navegador',
        DESKTOP: 'Área de Trabalho',
        LAPTOP: 'Computador portátil',
        MOBILE: 'Telemóvel',
        OTHER: 'Outro',
        SERVER: 'Servidor',
        TABLET: 'Tablet',
        TV: 'TV',
        UNKNOWN: 'Desconhecido',
      },
      ip: 'IP',
      lastAccessed: 'Ultima atividade',
      title: 'Lista de sessões atuais',
    },
    settings: {
      browserDefaults: 'Valor padrão do navegador',
      language: 'Idioma',
      timezone: 'Fuso horário',
      title: 'Definições',
    },
    title: 'Edição de perfil',
    twoFA: {
      active: 'Ativo',
      back: 'Anterior',
      cancel: 'Cancelar',
      continue: 'Seguinte',
      disable: 'Desativar',
      disableError:
        'Um problema ocorreu, o código pode não ser válido. Por favor tente novamente.',
      disableSuccess: 'Desativação da autenticação dupla bem -sucedida.',
      dlApp: {
        description:
          'Você precisa baixar um aplicativo de autenticação (TOTP) do seu smartphone. Recomendamos o Google Authenticator.',
        label: 'Instale um aplicativo de autenticação',
      },
      enable: 'Permitir',
      enableError:
        'Um problema ocorreu, o código pode não ser válido. Por favor tente novamente.',
      enableSuccess: 'Ativação da autenticação dupla bem-sucedida.',
      enterCode: {
        description:
          'Você deve inserir o código de 6 dígitos gerado pelo aplicativo e clicar em "Validar".',
        label: 'Digite o código',
      },
      finish: 'Validar',
      inactive: 'Inativo',
      info:
        'Proteja a sua conexão adicionando um segundo método de autenticação',
      scanQRCode: {
        description:
          'Para adicionar este site ao Google Authenticator, você deve digitalizar o código QR ou inserir manualmente o código secreto.',
        label: 'Digitalize o código QR',
      },
      secretCode: 'Código secreto: {{ secret }}',
      title: 'Autenticação dupla',
    },
  },
  register: {
    buttons: {
      backToHome: 'Voltar ao Início',
      submit: 'Criar o meu espaço',
    },
    disabled: '',
    errors: {
      birthDate: {
        ofAge: {
          patient:
            'Você deve ter pelo menos {{ age }} anos ou ser acompanhado pelo seu representante legal',
          practitioner:
            'Você deve ter pelo menos {{ age }} anos para usar o aplicativo',
        },
      },
      errorRegistration:
        'A conta não pôde ser criada. Por favor novamente ou entrar em contato com um administrador se o problema persistir.',
      errorRegistrationExternal:
        'Um problema ocorreu durante o registro do seu registro. Obrigado por tentar novamente ou entrar em contato com um administrador se o problema persistir.',
      errorUserRegistrationExternal:
        'Um problema ocorreu durante a criação da sua conta. Obrigado por tentar novamente ou entrar em contato com um administrador se o problema persistir.',
      unknownError:
        'Um problema ocorreu. Obrigado por tentar novamente ou entrar em contato com um administrador se o problema persistir.',
      userExists:
        'Esta conta de email já existe. Se você esqueceu sua senha, pode redefini -la na página de conexão.',
    },
    fields: {
      birthDate: 'Data de nascimento',
      certifyLegalRepresentative:
        'Eu certifico ser acompanhado pelo meu representante legal *',
      email: 'E-mail',
      firstName: 'Primeiro nome',
      lastName: 'nome',
      referrerCode: 'Código de patrocínio',
    },
    success:
      'Sua conta foi criada. Você receberá um email para configurar sua senha e, em seguida, outro email validando seu registro. Vejo você em breve em nossa plataforma!',
    title: 'Criação do seu espaço de {{app}}',
  },
  signIn: {
    title: 'Conexão',
  },
  signOut: {
    linkText: 'Desconexão',
    title: 'Desconexão em andamento',
  },
  users: {
    title: 'Gerenciamento de usuários',
  },
  usersManagement: {
    linkText: 'Admin do usuário',
  },
};
