import { lazy } from 'react';
import { IRoute } from '@kiway/routing-builder';
import PaymentIntentHandler from './oldFrontKiway/shared/PaymentIntentHandler';
import { Redirect } from 'react-router-dom';
import PublicPage from './oldFrontKiway/components/PublicPage/PublicPage';
import OnlineBookingStepper from './oldFrontKiway/components/OnlineBookingStepper';
import Error404 from './oldFrontKiway/components/HttpCodes/Error404';
import SignIn from './oldFrontKiway/components/SignIn/SignIn';
import ResetRouter from './oldFrontKiway/router/reset';
import RegisterRouter from './oldFrontKiway/router/register';
import { ServicesOnDemandContextProvider } from './oldFrontKiway/services/contexts/ServicesContext';
import { TourGuide } from './oldFrontKiway/components/TourGuide/TourGuide';

const ServicesOnDemand = lazy(() =>
  import('./oldFrontKiway/components/ServicesOnDemand/ServicesOnDemand')
);

export const routes: (userInfo: any, t: (str: string) => string) => IRoute[] = (
  userInfo,
  t
) => [
  // Redirection de la racine vers le dashboard (si non connecté, renvoie vers SignIn)
  {
    id: 'home_redirect',
    path: '/',
    exact: true,
    private: false,
    redirect: '/dashboard',
  },
  // 404 not found
  {
    id: '404_not_found',
    path: '/404',
    component: Error404,
    private: false,
    exact: true,
  },
  // // Connexion
  // {
  //   id: 'login',
  //   path: '/signin',
  //   component: SignIn,
  //   private: false,
  //   exact: true,
  // },
  // // Réinitialisation du mot de passe
  // {
  //   id: 'reset_password_router',
  //   path: '/reset',
  //   component: ResetRouter,
  //   private: false,
  //   exact: false,
  //   publicOnly: true,
  // },
  // // Inscription
  // {
  //   id: 'register_router',
  //   path: '/register',
  //   component: RegisterRouter,
  //   private: false,
  //   exact: false,
  //   publicOnly: true,
  // },
  // Page de paiement stripe (pour l'instant implémentée que pour les séances)
  {
    id: 'stripe_payment',
    path: '/pay/:paymentIntentType/:paymentIntentId',
    component: ({ match }: any) => <PaymentIntentHandler {...match.params} />,
    private: false,
    exact: false,
    pageName: '/pay/:paymentIntentType/:paymentIntentId',
  },
  // Redirection de l'ancienne URL public page vers la nouvelle
  {
    id: 'old_public_page_redirect',
    path: '/practitioners/:specialty/:city/:slug',
    component: ({ match }: any) => (
      <Redirect to={`/practitioners/${match.params.slug}`} />
    ),
    private: false,
    exact: true,
    pageName: '/practitioners/:specialty/:city/:slug',
  },
  // Nouvelle URL public page
  {
    id: 'public_page',
    path: '/practitioners/:slug',
    component: ({ match }: any) => <PublicPage slug={match.params.slug} />,
    private: false,
    exact: true,
    pageName: '/practitioners/:slug',
  },
  // Ajout d'un rendez-vous depuis la page publique
  {
    id: 'new_appointment_from_public_page',
    path: '/appointment/new',
    component: () => <OnlineBookingStepper />,
    private: false,
    exact: true,
  },
  // START APP SHELL
  // // Edition du profil utilisateur
  // {
  //   id: 'edit_user_profile',
  //   path: '/profile',
  //   component: lazy(() =>
  //     import('./oldFrontKiway/components/User/EditProfile')
  //   ),
  //   exact: true,
  //   private: true,
  //   role: 'ROLE_USER',
  //   subtitle: t('menu:profile'),
  //   appShell: true,
  // },
  // Tableau de bord (ou liste des rdvs si user est un patient)
  {
    id: 'dashboard',
    path: '/dashboard',
    component: lazy(() => import('./oldFrontKiway/pages/Dashboard')),
    exact: false,
    private: true,
    role: 'ROLE_PATIENT',
    subtitle:
      userInfo && userInfo.role && userInfo.role === 'patient'
        ? t('menu:appointments')
        : t('menu:dashboard'),
    appShell: true,
  },
  // Agenda
  {
    id: 'agenda_router',
    path: '/agenda',
    component: lazy(() => import('./oldFrontKiway/router/pro/agenda.router')),
    exact: false,
    private: true,
    role: 'ROLE_PRACTITIONER',
    subtitle: t('menu:agenda'),
    appShell: true,
  },
  // Dossiers usagers
  {
    id: 'files_router',
    path: '/files',
    component: lazy(() => import('./oldFrontKiway/router/pro/files.router')),
    exact: false,
    private: true,
    role: 'ROLE_PRACTITIONER',
    subtitle: t('menu:files'),
    appShell: true,
  },
  // Paramètres
  {
    id: 'settings_router',
    path: '/settings',
    component: lazy(() => import('./oldFrontKiway/router/pro/settings.router')),
    exact: false,
    private: true,
    role: 'ROLE_PRACTITIONER',
    subtitle: t('menu:settings.main'),
    appShell: true,
  },
  // Paramètres
  {
    id: 'invoicing',
    path: '/invoicing',
    component: lazy(() => import('./oldFrontKiway/pages/Invoicing')),
    exact: false,
    private: true,
    role: 'ROLE_PRACTITIONER',
    subtitle: t('menu:invoicing'),
    appShell: true,
  },
  // Gestion des services payants
  {
    id: 'services',
    path: '/services',
    component: () => (
      <ServicesOnDemandContextProvider>
        <ServicesOnDemand />
      </ServicesOnDemandContextProvider>
    ),
    exact: false,
    private: true,
    role:
      process.env.NX_PREMIUM_SERVICES_ENABLED === 'true'
        ? 'ROLE_PRACTITIONER'
        : 'ROLE_ADMIN',
    subtitle: t('menu:services'),
    appShell: true,
  },
  {
    id: 'tourguide',
    path: '/tutorials',
    component: TourGuide,
    exact: false,
    private: true,
    role: 'ROLE_PRACTITIONER',
    subtitle: 'tutorials',
    appShell: true,
  },
  // Gestion des utilisateurs, accessible uniquement par les admins
  {
    id: 'admin_users_management',
    path: '/users-admin',
    component: lazy(() =>
      import('./oldFrontKiway/components/Admin/UsersManagement')
    ),
    exact: false,
    private: true,
    role: 'ROLE_ADMIN',
    subtitle: t('menu:users'),
    appShell: true,
  },
  // Envoie massif de notifications, accessible uniquement par les admins
  {
    id: 'admin_notifications_bulk',
    path: '/notifications',
    component: lazy(() =>
      import('./oldFrontKiway/components/Admin/NotificationsManagement')
    ),
    exact: false,
    private: true,
    role: 'ROLE_PRACTITIONER',
    subtitle: t('menu:notifications'),
    appShell: true,
  },
  // Gestion des parrainages
  {
    id: 'affiliation',
    path: '/affiliation',
    component: lazy(() =>
      import('./oldFrontKiway/components/Affiliation/Affiliation')
    ),
    exact: false,
    private: true,
    role:
      process.env.NX_AFFILIATION_ENABLED === 'true'
        ? 'ROLE_PRACTITIONER'
        : 'ROLE_ADMIN',
    subtitle: t('menu:affiliation'),
    appShell: true,
  },
  // Par défaut, dans l'AppShell, si aucun match avant ça on redirige vers la page 404 not found
  {
    id: 'redirect_to_404_from_appshell',
    path: '/',
    redirect: '/404',
    exact: false,
    private: true,
    appShell: true,
    order: -1,
  },
  // END APP SHELL
  // En cas qu'il n'y est aucun match, on redirige vers la page 404 not found
  {
    id: 'redirect_to_404_from_global',
    path: '*',
    redirect: '/404',
    exact: false,
    private: false,
  },
];
