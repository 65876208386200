import * as React from 'react';
import { useFormulasGateway } from '../../../entrypoints/react/useFormulasGateway';
import { FormulaDetails } from './FormulaDetails';
import { Formula, FormulaArrays } from '../../../core/entities/Formula';
import { useSnackbar } from '@kiway/shared/utils/snackbar';
import { useTranslation } from '@kiway/shared/utils/translation';
import { ProductMetadata } from '../../../core/entities/ProductMetadata';

export function FormulaDetailsController({ id }) {
  const [loading, setLoading] = React.useState(false);
  const [loadingType, setLoadingType] = React.useState<string>(null);
  const [initialFormula, setInitialFormula] = React.useState<Formula>(null);
  const [formula, setFormula] = React.useState<Formula[]>([null]);
  const {
    addMetadataToFormula,
    editFormulas,
    findOne,
    removeMetadataFromFormula,
    searchFormulaMetadata,
  } = useFormulasGateway();
  const { addSuccessSnackbar } = useSnackbar();
  const { t } = useTranslation();

  React.useEffect(() => {
    setLoading(true);
    if (id) {
      findOne(id)
        .then(async (formula) => {
          if (formula && formula.getId()) {
            setFormula([formula]);
            setInitialFormula(new Formula(JSON.parse(JSON.stringify(formula))));
          }
          // await new Promise((res) => setTimeout(res, 3000));
        })
        .finally(() => setLoading(false));
    }
    return () => {
      setFormula(null);
    };
  }, [id]);

  const handleChange = (type: string) => (value: string | number | boolean) => {
    const editedFormula: Formula = formula?.[0];
    if (type.includes('description.')) {
      const code = type.split('.')?.[1];
      editedFormula?.setDescription({
        ...editedFormula.getDescription(),
        [code]: value as string,
      });
    } else {
      switch (type) {
        case 'pinYinName':
          editedFormula?.setPinYinName(value as string);
          break;
        case 'published':
          editedFormula?.setPublished(value as boolean);
          break;
        case 'normal':
          editedFormula?.setNormal(value as boolean);
          break;
        case 'xiaoFang':
          editedFormula?.setXiaoFang(value as boolean);
          break;
        case 'jingFang':
          editedFormula?.setJingFang(value as boolean);
          break;
        default:
          throw new Error('unknown metadata type');
      }
    }
    setFormula([editedFormula]);
  };

  const handleChangeComposition = (
    id: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    switch (name) {
      case 'dosage':
        setFormula([
          formula?.[0]?.setComposition([
            ...formula?.[0]
              ?.getComposition()
              .map((ingredient) =>
                ingredient.getId() === id && Number(value) >= 0
                  ? ingredient.setDosage(Number(value))
                  : ingredient
              ),
          ]),
        ]);
        break;
      default:
        throw new Error('unknown variation attribute name');
    }
  };

  const handleAdd = (type: FormulaArrays, value: ProductMetadata) => {
    const editedFormula: Formula = formula?.[0];
    setLoading(true);
    setLoadingType(type);
    addMetadataToFormula(editedFormula.getId(), value.getId(), type)
      .then(async (formula) => {
        setInitialFormula(
          new Formula({
            ...JSON.parse(JSON.stringify(formula)),
          })
        );
        setFormula([formula]);
        addSuccessSnackbar(
          t('pharmaco:formula.detailsSuccessUpdate', { count: 1 }),
          null,
          {
            withUpdate: true,
          }
        );
      })
      .finally(() => {
        setLoading(false);
        setLoadingType(null);
      });
  };

  const handleDelete = (type: FormulaArrays) => (id: string) => {
    const editedFormula: Formula = formula?.[0];
    setLoading(true);
    setLoadingType(type);
    removeMetadataFromFormula(editedFormula.getId(), id, type)
      .then(async (formula) => {
        setInitialFormula(
          new Formula({
            ...JSON.parse(JSON.stringify(formula)),
          })
        );
        setFormula([formula]);
        addSuccessSnackbar(
          t('pharmaco:formula.detailsSuccessUpdate', { count: 1 }),
          null,
          {
            withUpdate: true,
          }
        );
      })
      .finally(() => {
        setLoading(false);
        setLoadingType(null);
      });
  };

  const handleSaveOnBlur = (type: string) => (id: string) => {
    let itemToSave: any;
    const editedFormula: Formula = formula?.[0];
    let editedInfo: any;
    let initialItem: any = null;
    if (type.includes('description.')) {
      const code = type.split('.')?.[1];
      editedInfo = editedFormula?.getDescription();
      itemToSave =
        editedInfo?.[code] !== initialFormula?.getDescription()?.[code]
          ? {
              custom: {
                description: editedInfo,
              },
            }
          : null;
    } else {
      switch (type) {
        case 'pinYinName':
          editedInfo = editedFormula?.getPinYinName();
          itemToSave =
            editedInfo !== initialFormula?.getPinYinName()
              ? {
                  custom: {
                    pinYinName: editedInfo,
                  },
                }
              : null;
          break;
        case 'published':
          editedInfo = editedFormula?.isPublished();
          itemToSave =
            editedInfo !== initialFormula?.isPublished()
              ? {
                  published: editedInfo,
                }
              : null;
          break;
        case 'normal':
          editedInfo = editedFormula?.isNormal();
          itemToSave =
            editedInfo !== initialFormula?.isNormal()
              ? {
                  custom: { normal: editedInfo },
                }
              : null;
          break;
        case 'xiaoFang':
          editedInfo = editedFormula?.isXiaoFang();
          itemToSave =
            editedInfo !== initialFormula?.isXiaoFang()
              ? {
                  custom: { xiaoFang: editedInfo },
                }
              : null;
          break;
        case 'jingFang':
          editedInfo = editedFormula?.isJingFang();
          itemToSave =
            editedInfo !== initialFormula?.isJingFang()
              ? {
                  custom: { jingFang: editedInfo },
                }
              : null;
          break;
        case 'composition':
          initialItem = initialFormula
            ?.getComposition()
            .find((ingredient) => ingredient.getId() === id);
          editedInfo = editedFormula
            ?.getComposition()
            .find((ingredient) => ingredient.getId() === id);
          itemToSave =
            `${initialItem?.getDosage() || null}` !==
            `${editedInfo.getDosage()}`
              ? {
                  custom: {
                    composition: editedFormula
                      .getComposition()
                      .map((ingredient) => ({
                        ...JSON.parse(JSON.stringify(ingredient)),
                        plant: ingredient.getPlant()?.getId(),
                        dosage: ingredient.getDosage()?.toString(),
                      })),
                  },
                }
              : null;
          break;
        default:
          throw new Error('unknown metadata type');
      }
    }
    if (itemToSave) {
      setLoading(true);
      setLoadingType(['composition'].includes(type) ? type : 'details');
      editFormulas([
        {
          ...initialFormula.toInput(),
          ...itemToSave,
          custom: {
            ...initialFormula.getCustomInput(),
            ...itemToSave.custom,
            __typename: undefined,
          },
          id: editedFormula.getId(),
        },
      ])
        .then((formulas) => {
          if (
            formulas &&
            formulas?.length &&
            `${formulas?.[0]?.getId()}` === `${editedFormula.getId()}`
          ) {
            addSuccessSnackbar(
              t('pharmaco:formula.detailsSuccessUpdate', { count: 1 }),
              null,
              {
                withUpdate: true,
              }
            );
            // Reset initial formula after saving the changes
            setInitialFormula(
              new Formula({
                ...JSON.parse(JSON.stringify(formulas?.[0])),
              })
            );
            setFormula([formulas?.[0]]);
          }
        })
        .finally(() => {
          setLoading(false);
          setLoadingType(null);
        });
    }
  };

  return (
    <FormulaDetails
      handleAdd={handleAdd}
      handleChange={handleChange}
      handleChangeComposition={handleChangeComposition}
      handleDelete={handleDelete}
      handleSave={handleSaveOnBlur}
      loading={loading}
      loadingType={loadingType}
      formula={formula?.[0]}
      searchCallback={searchFormulaMetadata}
    />
  );
}
