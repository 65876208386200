import { Plant, PlantArrays } from '../entities/Plant';
import { PlantProvider } from './PlantProvider';

export class RemoveItemFromPlantArrays {
  private plantProvider: PlantProvider;

  public constructor(plantProvider: PlantProvider) {
    this.plantProvider = plantProvider;
  }

  public execute(
    plantId: string,
    itemId: string,
    itemType: PlantArrays
  ): Promise<Plant> {
    return this.plantProvider.removeItemFromPlantArrays(
      plantId,
      itemId,
      itemType
    );
  }
}
