import React, { createContext, PropsWithChildren, useState } from 'react';
import { StepType, TourProvider } from '@reactour/tour';
import ReactTourContextProvider from './react-tour-context';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const ReactTourWrapperContext = createContext<undefined | null>(
  undefined
);

const ReactTourWrapperContextProvider = ({
  children,
}: PropsWithChildren<React.ReactNode>) => {
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(true);
  const [subPartSteps, setSubPartSteps] = useState<StepType[]>([]);
  const [readyForTour, setReadyForTour] = React.useState<boolean>(false);

  const disableBody = (target: null | Element) =>
    target && disableBodyScroll(target);
  const enableBody = (target: null | Element) =>
    target && enableBodyScroll(target);

  return (
    <ReactTourWrapperContext.Provider value={null}>
      <TourProvider
        disableKeyboardNavigation={true}
        afterOpen={disableBody}
        beforeClose={enableBody}
        disableFocusLock={true}
        nextButton={
          !isNextButtonEnabled
            ? () => <ArrowForwardIcon color="disabled" />
            : undefined
        }
        steps={[]}
      >
        <ReactTourContextProvider
          isNextButtonEnabled={isNextButtonEnabled}
          setIsNextButtonEnabled={setIsNextButtonEnabled}
          subPartSteps={subPartSteps}
          setSubPartSteps={setSubPartSteps}
          setReadyForTour={setReadyForTour}
          readyForTour={readyForTour}
          setCurrentStep={() => 0}
        >
          {children}
        </ReactTourContextProvider>
      </TourProvider>
    </ReactTourWrapperContext.Provider>
  );
};

export const useReactTourWrapperContext = () => {
  const context = React.useContext(ReactTourWrapperContext);
  if (context === undefined) {
    throw new Error(
      'useReactTourWraperContext must be used within a ReactTourWrapperContextProvider'
    );
  }
  return context;
};

export default ReactTourWrapperContextProvider;
