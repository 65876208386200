export default {
  checkboxCGA: `Ao verificar esta caixa, certifico ter lido e aceito sem reserva o [CGA](${process.env['NX_CGA_URL']}) *`,
  checkboxDiploma:
    'Ao clicar nesta caixa, certifico estar na posse de um diploma emitido por uma escola reconhecida e por uma federação profissional *',
  checkboxSchool:
    'Ao clicar nesta caixa, certifico estar na posse de um certificado escolar de uma escola reconhecida por uma federação profissional *',
  errorRetrievingCheckoutSessionId:
    'Ocorreu um erro durante o redirecionamento para a plataforma de pagamento. Se o erro persistir, entre em contato conosco em support@kiway.co',
  invoicing: {
    labels: {
      amount_due: 'Valor',
      date: 'Data',
      description: 'Descrição',
      number: 'Número da fatura',
      paid: 'Pago',
    },
    title: 'Minhas contas',
  },
  language: 'pt',
  lastPaymentFailed: 'O último pagamento falhou',
  paymentSources: {
    modalConfirmText:
      'Tem certeza de que deseja excluir este método de pagamento? Esta ação é irreversível.',
    modalDeleteTitle: 'Exclusão de um método de pagamento',
    noData: 'Não há métodos de pagamento',
    snackbars: {
      error: 'Ocorreu um problema, por favor tente novamente',
      successDefaultCard: 'O pagamento padrão foi atualizado com sucesso',
      successRemoveCard: 'Métodos de pagamento bem -sucedidos',
    },
    title: 'Métodos de Pagamento',
  },
  servicesOnDemand: {
    balance: 'Balanço aplicado',
    firstPayment: 'Primeiro pagamento',
    ifThreeDSecure:
      'Se seus meios de pagamento exigirem autenticação (por exemplo, 3D Secure),  será enviado um link para cada pagamento para que possa autenticar (agora e antes de cada renovação).',
    licensedServicesTitle: 'Serviços com assinatura',
    licensedServicesTooltip:
      'Os serviços de assinatura são cobrados no início do período.',
    logoutScreen: {
      error:
        'Se os seus meios de pagamento exigirem autenticação (3D seguro, por exemplo). Para resolver o problema e regularizar o pagamento, siga o link abaixo: \n\n [%textLink%](%link%) \n\nUne vezes a conta paga, convidamos você a desconectar. Isso levará em consideração todas as alterações.',
      errorTitle: 'Um problema ocorreu durante o pagamento',
      success:
        'Para se beneficiar de todos os seus serviços, desconecte. Isso levará em consideração todas as alterações.',
      successTitle: 'A sua assinatura foi registrada corretamente.',
    },
    meteredServicesTitle: 'Serviços do Consumidor',
    meteredServicesTooltip:
      'Os serviços do consumidor são cobrados no final do período.',
    modalConfirmText:
      'As mudanças são cobradas durante a renovação. Sua próxima fatura será, portanto, recalculada, levando em consideração essas modificações.',
    modalTitle: 'Atualizando seus serviços',
    needLogout:
      'A desconexão é necessária para levar em consideração as mais recentes modificações',
    nextPayments: 'Pagamentos seguintes',
    recapServices: 'Resumo de seus serviços',
    snackbars: {
      error:
        'Ocorreu um erro, tente novamente mais tarde ou entre em contato com o suporte.',
      errorPayment:
        'Um problema ocorreu durante o pagamento, siga as instruções exibidas na página',
      success: 'A assinatura foi registrada corretamente',
    },
    stepper: {
      chooseServices: 'Escolha seus serviços',
      confirm: 'Confirmação',
      payment: 'Pagamento',
    },
    subTotal: 'Subtotal',
    title: 'Lista de serviços',
    total: 'Total',
    usage: 'consumo',
    vat20Included: 'IVA 23% incl.',
    vat20NotIncluded: 'IVA 23%',
  },
  startUsingApp: 'Comece a usar o aplicativo',
  subSuccessMessage:
    'A sua assinatura está ativa. Pode alterar o seu método de pagamento, encontrar as suas faturas e modificar a sua assinatura em qualquer momento no menu do utilizador.',
  thanks: 'Obrigada !',
  trialPending: 'Período de teste atual',
};
