import * as React from 'react';
import { ProductVariationType } from '../../../core/entities/ProductVariationType';
import { ListProductVariationTypesDatatable } from './ListProductVariationTypesDatatable';

export interface ListProductVariationTypesProps {
  handleAddData?: () => void;
  handleSave?: any;
  productVariationTypes: ProductVariationType[];
  loading?: boolean;
}

export function ListProductVariationTypes({
  handleAddData,
  handleSave,
  loading,
  productVariationTypes,
}: React.PropsWithChildren<ListProductVariationTypesProps>) {
  return (
    <ListProductVariationTypesDatatable
      handleAddData={handleAddData}
      handleSave={handleSave}
      loading={loading}
      productVariationTypes={productVariationTypes}
    />
  );
}
