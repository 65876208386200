import React from 'react';
import { Typography, Grid, Button } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import { useReactTourContext } from './react-tour-context';
import { useHistory } from 'react-router';
import { TourSubSectionProps } from './TourSection';

export function TourSubSection(props: TourSubSectionProps) {
  const { t } = useTranslation();
  const { setSubPartSteps } = useReactTourContext();
  const history = useHistory();

  const prepareSubPartTourGuide = () => {
    setSubPartSteps(props.subSectionSteps);
    history.push({
      pathname: `/${props.subSectionLink}`,
      state: {
        from: 'tourguide',
      },
    });
  };

  return (
    <Grid container sx={{ mb: 1 }}>
      <Grid item xs={4} md={4}>
        <Typography>
          {t(`common:tourGuide.subSection.title.${props.subSectionTitle}`)}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography sx={{ color: 'text.secondary' }}>
          {t(
            `common:tourGuide.subSection.description.${props.subSectionDescription}`
          )}
        </Typography>
      </Grid>
      <Grid item xs={4} md={2} textAlign="right">
        <Button
          variant="contained"
          color="primary"
          onClick={prepareSubPartTourGuide}
        >
          {t('common:tourGuide.demoButton')}
        </Button>
      </Grid>
    </Grid>
  );
}
