import { ApolloClient, useApolloClient } from '@apollo/client';
import { Plant } from '../../core/entities/Plant';
import { PaginatedResults } from '@kiway/shared/react-types';
import { CatalogOptions, GetCatalog } from '../../core/use_cases/GetCatalog';
import { CatalogGraphQLProvider } from '../../dataproviders/graphql-client/CatalogGraphQLProvider';
import { Formula } from '../../core/entities/Formula';

class CatalogGateway {
  protected static instance: CatalogGateway;
  protected getCatalogUC: GetCatalog;

  public static getInstance(client: ApolloClient<any>): CatalogGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new CatalogGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const catalogProvider = new CatalogGraphQLProvider(client);
    this.getCatalogUC = new GetCatalog(catalogProvider);
    this.getCatalog = this.getCatalog.bind(this);
    CatalogGateway.instance = this;
  }

  async getCatalog(
    options?: CatalogOptions,
    page?: number,
    nbResultsPerPage?: number
  ): Promise<PaginatedResults<Plant | Formula>> {
    return await this.getCatalogUC.execute(options, {
      page,
      perPage: nbResultsPerPage,
    });
  }
}

export function useCatalogGateway() {
  const client = useApolloClient();
  const catalogGateway = CatalogGateway.getInstance(client);
  return {
    getCatalog: catalogGateway.getCatalog,
  };
}
