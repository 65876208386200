export default {
  dateTimePicker: {
    buttons: {
      cancel: {
        text: 'Annuler',
      },
      clear: {
        text: 'Effacer',
      },
      ok: {
        text: 'Ok',
      },
      today: {
        text: "Aujourd'hui",
      },
    },
  },
  format: {
    agendaSettingsHour: 'HH:mm:ss',
    date: 'DD/MM/YYYY',
    datePlaceholder: 'JJ/MM/AAAA',
    dayMonthYearHourMinute: 'DD/MM/YYYY HH:mm',
    hour: 'HH:mm',
    hourOnly: 'HH[h]',
    hourTxt: 'HH[h]mm',
    invalid: 'Date invalide',
    isoDate: 'YYYY-MM-DD',
    isoHour: 'HH:mm:ssSSSZ',
    literalComplete: 'Do MMMM YYYY [à] HH[h]mm',
    literalDate: 'Do MMMM YYYY',
    shortDate: 'D MMM YYYY',
    shortDay: 'ddd',
    shortDayMonth: 'D MMM',
    shortYearDate: 'DD/MM/YY',
    smsSentDate: 'DD/MM/YYYY [à] HH:mm',
  },
  hourFormat: '24',
  moment: 'fr',
  month: 'mois',
  strings: {
    currentMonth: 'Ce mois-ci',
    currentWeek: 'Cette semaine',
    previousMonth: 'Mois précédent',
    previousWeek: 'Semaine précédente',
  },
};
