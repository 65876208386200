import * as React from 'react';
import { Typography } from '@material-ui/core';
import { Country } from '../../../core/entities/Country';
import { Skeleton } from '@material-ui/lab';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DatatablesController } from '@kiway/datatables';
import { useTranslation } from '@kiway/shared/utils/translation';
import { deepen } from '@kiway/shared/utils/string';

const prepareToSave = (item) => {
  return {
    ...deepen(item),
    id: item._id,
    _id: undefined,
  };
};

export interface ListCountriesProps {
  countries: Country[];
  handleAddData: () => void;
  handleClearAdd: () => void;
  handleSave?: any;
  loading?: boolean;
}

export function ListCountries(
  props: React.PropsWithChildren<ListCountriesProps>
) {
  const { t } = useTranslation();
  return (
    <DatatablesController
      bulkEdit={true}
      handleAddData={props.handleAddData}
      handleClearAdd={props.handleClearAdd}
      handleSaveData={
        props.handleSave
          ? (updates) => {
              if (props.handleSave) {
                return props.handleSave(updates.updatedData.map(prepareToSave));
              }
            }
          : undefined
      }
      columns={[
        {
          name: '_id',
          label: 'ID',
          type: 'text',
          options: {
            display: false,
            edit: false,
            filter: false,
          },
        },
        {
          name: 'code',
          label: t(`pharmaco:countries.label.code`),
          type: 'text',
        },
        {
          name: 'name.fr',
          label: t(`pharmaco:countries.label.name.fr`),
          type: 'text',
        },
        {
          name: 'name.en',
          label: t(`pharmaco:countries.label.name.en`),
          type: 'text',
        },
        {
          name: 'name.es',
          label: t(`pharmaco:countries.label.name.es`),
          type: 'text',
        },
        {
          name: 'name.pt',
          label: t(`pharmaco:countries.label.name.pt`),
          type: 'text',
        },
        {
          name: 'vatPlant',
          label: t(`pharmaco:countries.label.vatPlant`),
          type: 'number',
        },
        {
          name: 'vatPowder',
          label: t(`pharmaco:countries.label.vatPowder`),
          type: 'number',
        },
      ]}
      data={props.countries?.map((country) => country.toDatatableRow())}
      defaultSortOrder={{ name: 'name.fr', direction: 'asc' }}
      id="listCountries"
      loading={props.loading}
      options={{
        download: false,
        filter: false,
        print: false,
      }}
      title={t(`pharmaco:countries.linkText`)}
    />
  );
}
