export default {
  editProfile: {
    aboutMe: 'A proposito',
    address: {
      add: {
        button: 'Añadir una dirección',
        modalTitle: 'Añadir una dirección',
      },
      default: {
        button: 'Definir por defecto',
        chip: 'Defecto',
      },
      delete: {
        button: 'Borrar',
        modalMessage: '¿Estás seguro de que quieres eliminar esta dirección?',
        modalTitle: 'Eliminación de una dirección',
      },
      edit: {
        button: 'Editar',
        modalTitle: 'Editar dirección',
      },
      title: 'Direcciones',
    },
    cancelChangePicture: 'anular',
    changeEmailError:
      'Se produjo un error durante la grabación. La dirección de correo electrónico proporcionada no es válida. Por favor, intente de nuevo.',
    changeEmailInfo:
      'Se le enviará un correo electrónico para verificación. Haga clic en el enlace presente en el correo electrónico.',
    changeEmailInvalid:
      'Por favor, introduce una dirección de correo electrónico válida.',
    changeEmailSuccess:
      'Su dirección de correo electrónico ha sido modificada, debe hacer clic en el enlace recibido por correo electrónico para validar esta dirección y poder usar la aplicación.',
    changePasswordCancelButton: 'Cancelar',
    changePasswordConfirmButton: 'Validar',
    changePasswordError:
      'Se produjo un error durante el envío del correo electrónico. Por favor intente de nuevo.',
    changePasswordInfo:
      'Recibirá un correo electrónico que contiene un enlace para restablecer su contraseña.',
    changePasswordSuccess:
      'Pronto llegará un correo electrónico. Haga clic en el enlace para restablecer su contraseña.',
    changePicture: 'Cambiar la foto',
    deletePicture: 'Eliminar la foto',
    email: 'Correo electrónico',
    emailInfo:
      'Si cambia su dirección de correo electrónico, deberá verificarla para que pueda volver a conectarse.',
    helperTextNIF: '',
    helperTextPicture: 'Solo enlaces seguros (https)',
    imageUrlLabel: 'Enlace a imagen',
    linkText: 'Mi perfil',
    nif: 'NIF',
    password: 'Contraseña',
    passwordInfo:
      'Su contraseña no se almacena en claro. Por eso no podemos mostrarlo.',
    saveChangePicture: 'Registro',
    saveError:
      'Se produjo un problema durante la grabación. Por favor intente de nuevo.',
    saveSuccess: 'Modificaciones registradas',
    security: 'Seguridad',
    sessions: {
      deleteAll: 'Eliminar todas las sesiones',
      deviceName: 'apellido',
      deviceType: 'Clase',
      deviceTypes: {
        BROWSER: 'Navegador',
        DESKTOP: 'Escritorio',
        LAPTOP: 'Ordenador portátil',
        MOBILE: 'MÓVIL',
        OTHER: 'Otro',
        SERVER: 'Servidor',
        TABLET: 'Tableta',
        TV: 'TELEVISOR',
        UNKNOWN: 'Desconocido',
      },
      ip: 'Ip',
      lastAccessed: 'Última actividad',
      title: 'Lista de sesiones actuales',
    },
    settings: {
      browserDefaults: 'Valor predeterminado del navegador',
      language: 'Idioma',
      timezone: 'Zona horaria',
      title: 'Definiciones',
    },
    title: 'Edición de perfil',
    twoFA: {
      active: 'Activo',
      back: 'Anterior',
      cancel: 'anular',
      continue: 'Próximo',
      disable: 'Desactivar',
      disableError:
        'Se ha producido un problema, el código puede no ser válido. Gracias por intentarlo de nuevo.',
      disableSuccess: 'Desactivación de la autenticación doble exitosa.',
      dlApp: {
        description:
          'Debe descargar una aplicación de autenticación (TOTP) desde su teléfono inteligente. Recomendamos a Google Authenticator.',
        label: 'Instalar una aplicación de autenticación',
      },
      enable: 'Permitir',
      enableError:
        'Se ha producido un problema, el código puede no ser válido. Gracias por intentarlo de nuevo.',
      enableSuccess: 'Activación de la autenticación doble exitosa.',
      enterCode: {
        description:
          'Debe ingresar el código de 6 dígitos generado por la aplicación y hacer clic en "Validar".',
        label: 'introduzca el código',
      },
      finish: 'Validar',
      inactive: 'Inactivo',
      info: 'Asegure su conexión agregando un segundo método de autenticación',
      scanQRCode: {
        description:
          'Para agregar este sitio al autenticador de Google, debe escanear el código QR o ingresar manualmente el código secreto.',
        label: 'Escanear el código QR',
      },
      secretCode: 'Código secreto: {{ secret }}',
      title: 'Doble autenticación',
    },
  },
  register: {
    buttons: {
      backToHome: 'Volver al inicio',
      submit: 'Crea mi espacio',
    },
    disabled: '',
    errors: {
      birthDate: {
        ofAge: {
          patient:
            'Debe tener al menos {{ age }} años o estar acompañado por su representante legal',
          practitioner:
            'Debe tener al menos {{ age }} años para usar la aplicación',
        },
      },
      errorRegistration:
        'La cuenta no se pudo crear. Gracias por intentarlo nuevamente o comunicarse con un administrador si el problema persiste.',
      errorRegistrationExternal:
        'Se produjo un problema durante el registro de su registro. Gracias por intentarlo nuevamente o comunicarse con un administrador si el problema persiste.',
      errorUserRegistrationExternal:
        'Se produjo un problema durante la creación de su cuenta. Gracias por intentarlo nuevamente o comunicarse con un administrador si el problema persiste.',
      unknownError:
        'Se ha producido un problema. Gracias por intentarlo nuevamente o comunicarse con un administrador si el problema persiste.',
      userExists:
        'Esta cuenta de correo electrónico ya existe. Si ha olvidado su contraseña, puede restablecerla a través de la página de conexión.',
    },
    fields: {
      birthDate: 'Fecha de nacimiento',
      certifyLegalRepresentative:
        'Certifico ser acompañado por mi representante legal *',
      email: 'Correo electrónico',
      firstName: 'Nombre de pila',
      lastName: 'apellido',
      referrerCode: 'Código de patrocinio',
    },
    success:
      'Tu cuenta ha sido creada. Recibirá un correo electrónico para configurar su contraseña y luego otro correo electrónico que valida su registro. ¡Nos vemos pronto en nuestra plataforma!',
    title: 'Creación de tu espacio {{app}}',
  },
  signIn: {
    title: 'Conexión',
  },
  signOut: {
    linkText: 'Desconexión',
    title: 'Desconexión en progreso',
  },
  users: {
    title: 'Gestión de usuarios',
  },
  usersManagement: {
    linkText: 'Administrador de usuarios',
  },
};
