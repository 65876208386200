import {
  ProductMetadata,
  IProductMetadataAttributes,
} from '../../core/entities/ProductMetadata';
import { ProductMetadataProvider } from '../../core/use_cases/ProductMetadataProvider';
import { gql, ApolloClient } from '@apollo/client';

export const LIST_PRODUCTMETADATAS = gql`
  query {
    listProductMetadatas {
      id
      name
      metadataType
      # Add here your attributes
    }
  }
`;

export const EDIT_MULTIPLE_PRODUCT_METADATAS = gql`
  mutation editManyProductMetadatas($input: [ProductMetadataInput]) {
    editManyProductMetadatas(input: $input) {
      id
      name
      metadataType
    }
  }
`;

export type IProductMetadataAttributesData = IProductMetadataAttributes & {
  id: string;
};

export interface EditProductMetadatasData {
  editManyProductMetadatas: Array<IProductMetadataAttributesData>;
}

export interface ListProductMetadatasData {
  listProductMetadatas: Array<IProductMetadataAttributesData>;
}

export class ProductMetadataGraphQLProvider implements ProductMetadataProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async findAll(): Promise<ProductMetadata[]> {
    try {
      const result = await this.client?.query<ListProductMetadatasData>({
        query: LIST_PRODUCTMETADATAS,
      });
      return result.data.listProductMetadatas.map(
        (item) => new ProductMetadata(item)
      );
    } catch (e) {
      console.log(e);
    }
  }

  save(
    productMetadata: ProductMetadata,
    userId?: string
  ): Promise<ProductMetadata> {
    throw new Error('Method not implemented.');
  }

  async editMany(
    productMetadatas: IProductMetadataAttributes[],
    userId?: string
  ): Promise<ProductMetadata[]> {
    try {
      const result = await this.client?.mutate<
        EditProductMetadatasData,
        { input: IProductMetadataAttributes[] }
      >({
        mutation: EDIT_MULTIPLE_PRODUCT_METADATAS,
        variables: {
          input: productMetadatas,
        },
      });
      return result.data?.editManyProductMetadatas?.map(
        (item) => new ProductMetadata(item)
      );
    } catch (e) {
      console.log(e);
    }
  }

  search(search: string, full?: boolean): Promise<ProductMetadata[]> {
    throw new Error('Method not implemented.');
  }
}
