import * as React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useTranslation } from '@kiway/shared/utils/translation';
import { FormulaIngredient } from '../../../core/entities/Formula';
import { Skeleton } from '@material-ui/lab';
import { ProductDetailsCard } from '@kiway/ecommerce-react';

interface RowLoaderProps {
  count?: number;
}
const defaultRowLoader: RowLoaderProps = { count: 1 };
function RowLoader({ count } = defaultRowLoader) {
  const lines = new Array(count).fill('');
  return (
    <>
      {lines?.map((_, index) => (
        <TableRow key={index}>
          <TableCell component="th" scope="row">
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}
export interface FormulaDetailsCompositionProps {
  handleChange: (
    id: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => any;
  handleDelete: (id: string) => any;
  handleSave: (id?: string) => any;
  loading?: boolean;
  composition: FormulaIngredient[];
}

interface FormulaDetailsCompositionRowProps {
  handleChange: FormulaDetailsCompositionProps['handleChange'];
  handleDelete: FormulaDetailsCompositionProps['handleDelete'];
  handleSave: FormulaDetailsCompositionProps['handleSave'];
  ingredient?: FormulaIngredient;
}

function FormulaDetailsCompositionRow({
  handleChange,
  handleDelete,
  handleSave,
  ingredient,
}: FormulaDetailsCompositionRowProps) {
  const displayName = ingredient.getPlant().getPinYinName();

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {displayName}
      </TableCell>
      <TableCell>
        <TextField
          type="number"
          name="dosage"
          value={ingredient.getDosage() || ''}
          onChange={(event) => {
            handleChange(ingredient.getId(), event);
          }}
          onBlur={(event) => {
            handleSave(ingredient.getId());
          }}
        />
      </TableCell>
      <TableCell>
        <IconButton
          onClick={() => handleDelete(ingredient.getPlant()?.getId())}
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export function FormulaDetailsComposition({
  handleChange,
  handleDelete,
  handleSave,
  loading,
  composition,
}: React.PropsWithChildren<FormulaDetailsCompositionProps>) {
  const { t } = useTranslation();
  return (
    <ProductDetailsCard title={t('pharmaco:composition.title')}>
      {composition ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('pharmaco:composition.name')}</TableCell>
              <TableCell>{t('pharmaco:composition.dosage')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <RowLoader count={2} />
            ) : composition?.length ? (
              composition.map((row) => (
                <FormulaDetailsCompositionRow
                  handleChange={handleChange}
                  handleDelete={handleDelete}
                  handleSave={handleSave}
                  key={row.getId()}
                  ingredient={row}
                />
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={5}
                  align="center"
                  style={{ fontStyle: 'italic' }}
                >
                  {t('pharmaco:noData')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      ) : null}
    </ProductDetailsCard>
  );
}
