import { ProductMetadataProvider } from './ProductMetadataProvider';
import {
  IProductMetadataAttributes,
  ProductMetadata,
} from '../entities/ProductMetadata';

export class EditManyProductMetadatas {
  private plantMetadataProvider: ProductMetadataProvider;

  public constructor(plantMetadataProvider: ProductMetadataProvider) {
    this.plantMetadataProvider = plantMetadataProvider;
  }

  public execute(
    productMetadatas: Array<IProductMetadataAttributes>,
    userId?: string
  ): Promise<Array<ProductMetadata>> {
    return this.plantMetadataProvider.editMany(productMetadatas, userId);
  }
}
