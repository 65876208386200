import { firstUpperCase } from '@kiway/shared/utils/string';
import {
  flattenTranslatableAttributeToJson,
  KiwayLanguagesType,
  TranslatableAttribute,
} from '@kiway/shared/react-types';

export const displayedMetadataTypes = [
  'action',
  'categoryPlant',
  'categoryFormula',
  'categoryFormulaXiaoFang',
  'categoryFormulaJingFang',
  'meridian',
  'nature',
  'savour',
  'syndrom',
] as const;
export const productMetadataTypes = [
  ...displayedMetadataTypes,
  'productVariationType',
  'plant',
  'formula',
] as const;
export type ProductMetadataType = typeof productMetadataTypes[number];

export const plantMetadataTypes: ProductMetadataType[] = [
  'action',
  'categoryPlant',
  'meridian',
  'nature',
  'savour',
];

export const formulaMetadataTypes: ProductMetadataType[] = [
  'categoryFormula',
  'categoryFormulaXiaoFang',
  'categoryFormulaJingFang',
  'syndrom',
];

export const productTypes = ['plant', 'formula'] as const;
export type ProductType = typeof productTypes[number];

export interface IProductMetadataSearchable {
  getId(): string;
  getMetadataType(): ProductMetadataType;
  parseEntity(
    lng: KiwayLanguagesType
  ): {
    mainDisplayName: string;
    otherDisplayNames: string[];
  };
  toString(lng: KiwayLanguagesType): string;
}

export interface IProductMetadataAttributes {
  typeId?: string;
  legacyId?: string;
  name: TranslatableAttribute;
  metadataType: ProductMetadataType;
}

interface ProductMetadataConstructor<T extends ProductMetadata> {
  new (...args: any[]): T;
  typeId: string;
}

export const productMetadataSearchableAttributes = [
  'name.fr',
  'name.en',
  'name.es',
  'name.pt',
];

export class ProductMetadata implements IProductMetadataSearchable {
  // note 1
  // abstract static readonly typeId: string;

  // note 2
  readonly typeId = (this.constructor as ProductMetadataConstructor<this>)
    .typeId;

  // note 3
  instanceOf<T extends ProductMetadata>(
    ctor: ProductMetadataConstructor<T>
  ): this is T {
    return this.typeId === ctor.typeId;
  }

  static getSearchableAttributes() {
    return ['name.fr', 'name.en', 'name.es', 'name.pt'];
  }

  protected id: string;
  protected legacyId: string;
  protected name: TranslatableAttribute;
  protected metadataType: ProductMetadataType;

  public static filterByMetadataType(productMetadataType: ProductMetadataType) {
    return (productMetadata: ProductMetadata) =>
      productMetadata.getMetadataType() === productMetadataType;
  }

  public constructor(
    obj: Partial<IProductMetadataAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    this.legacyId = obj?.legacyId;
    this.name = obj?.name;
    this.metadataType = obj?.metadataType;
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): ProductMetadata {
    this.id = id;
    return this;
  }

  public getLegacyId(): string {
    return this.legacyId;
  }

  public getName(): TranslatableAttribute {
    return this.name;
  }

  public getMetadataType(): ProductMetadataType {
    return this.metadataType;
  }

  public toDatatableRow(): any {
    return {
      _id: this.getId(),
      ...flattenTranslatableAttributeToJson(this.getName(), 'name'),
    };
  }

  public parseEntity(
    lng: KiwayLanguagesType
  ): {
    mainDisplayName: string;
    otherDisplayNames: string[];
  } {
    const mainDisplayName = firstUpperCase(this?.getName()?.[lng]);
    const otherDisplayNames = Object.entries(this?.getName() || {})
      .filter(([key]) => key !== lng)
      .map(([_, value]) => firstUpperCase(value));
    return {
      mainDisplayName,
      otherDisplayNames,
    };
  }

  public toString(lng: KiwayLanguagesType): string {
    const mainDisplayName = firstUpperCase(this?.getName()?.[lng]);
    const otherDisplayNames = Object.entries(this?.getName() || {})
      .filter(([key]) => key !== lng)
      .map(([_, value]) => firstUpperCase(value));
    return `${mainDisplayName} ${otherDisplayNames.join(' ')}`;
  }
}
