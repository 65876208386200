import React, { createContext, Dispatch, SetStateAction } from 'react';
import { StepType, useTour } from '@reactour/tour';
import { useHistory } from 'react-router-dom';

export type ReactTourContextType = {
  isNextButtonEnabled: boolean;
  setIsNextButtonEnabled: (value: boolean) => void;
  subPartSteps: StepType[];
  setSubPartSteps: Dispatch<SetStateAction<StepType[]>>;
  setReadyForTour: Dispatch<SetStateAction<boolean>>;
  readyForTour: boolean;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  children?: React.ReactNode;
};

export const ReactTourContext = createContext<undefined | ReactTourContextType>(
  undefined
);

const ReactTourContextProvider = (props: ReactTourContextType) => {
  const { setSteps, setCurrentStep, isOpen, setIsOpen, steps } = useTour();

  const history = useHistory();
  const historyState = history.location.state as any;

  React.useEffect(() => {
    setSteps([...props.subPartSteps]); // Pour mettre la valeur de la référence de subPartSteps et pas seulement la copie
    setCurrentStep(0);
    props.setIsNextButtonEnabled(true);
  }, [props.subPartSteps]);

  React.useEffect(() => {
    if (historyState?.from === 'tourguide' && props.readyForTour) {
      setIsOpen(true);
      props.setReadyForTour(false);
    }
  }, [historyState, props.readyForTour]);

  return (
    <ReactTourContext.Provider
      value={{
        isNextButtonEnabled: props.isNextButtonEnabled,
        setIsNextButtonEnabled: props.setIsNextButtonEnabled,
        subPartSteps: props.subPartSteps,
        setSubPartSteps: props.setSubPartSteps,
        setReadyForTour: props.setReadyForTour,
        readyForTour: props.readyForTour,
        setCurrentStep,
      }}
    >
      {props.children}
    </ReactTourContext.Provider>
  );
};

export const useReactTourContext = () => {
  const context = React.useContext(ReactTourContext);
  if (context === undefined) {
    throw new Error(
      'useReactTourContext must be used within a ReactTourContextProvider'
    );
  }
  return context;
};

export default ReactTourContextProvider;
