export const mapKiwayRole = {
  noAuth: 'ROLE_ANONYMOUS',
  user: 'ROLE_USER',
  patient: 'ROLE_PATIENT',
  practitioner: 'ROLE_PRACTITIONER',
  pharmaco: 'ROLE_PRACTITIONER_PHARMACO',
  beta: 'ROLE_PRACTITIONER',
  partner: 'ROLE_PARTNER',
  orderPicker: 'ROLE_ORDER_PICKER',
  wholesalerAdmin: 'ROLE_ADMIN_WHOLESALER',
  admin: 'ROLE_ADMIN',
};

export const roles = [
  'ROLE_ANONYMOUS',
  'ROLE_USER',
  'ROLE_PATIENT',
  'ROLE_PRACTITIONER',
  'ROLE_PRACTITIONER_PHARMACO',
  'ROLE_PARTNER',
  'ROLE_ORDER_PICKER',
  'ROLE_ADMIN_WHOLESALER',
  'ROLE_ADMIN',
  'ROLE_NOTIFIERS_LIST',
  'ROLE_PLANTS_LIST',
  'ROLE_PLANTS_EDIT',
  'ROLE_PACKAGINGS_LIST',
  'ROLE_CATEGORIES_LIST',
  'ROLE_CATEGORIES_SEARCH',
  'ROLE_CATEGORIES_EDIT',
  'ROLE_MERIDIANS_LIST',
  'ROLE_MERIDIANS_SEARCH',
  'ROLE_MERIDIANS_EDIT',
  'ROLE_NATURES_LIST',
  'ROLE_NATURES_SEARCH',
  'ROLE_NATURES_EDIT',
  'ROLE_PRODUCT_VARIATION_TYPES_LIST',
  'ROLE_PRODUCT_VARIATION_TYPES_SEARCH',
  'ROLE_PRODUCT_VARIATION_TYPES_EDIT',
  'ROLE_SAVOURS_LIST',
  'ROLE_SAVOURS_SEARCH',
  'ROLE_SAVOURS_EDIT',
  'ROLE_VARIATION_ATTRIBUTES_LIST',
  'ROLE_VARIATION_ATTRIBUTE_VALUES_LIST',
  'ROLE_PLANTS_GET_ONE',
  'ROLE_PLANTS_SEARCH',
  'ROLE_PLANTS_SEARCH_METADATA',
  'ROLE_PLANTS_EDIT_METADATA',
  'ROLE_ACTIONS_LIST',
  'ROLE_ACTIONS_SEARCH',
  'ROLE_ACTIONS_EDIT',
  'ROLE_USERS_LIST',
  'ROLE_PRODUCT_METADATAS_EDIT',
  'ROLE_FORMULAS_EDIT',
  'ROLE_FORMULAS_LIST',
  'ROLE_FORMULAS_GET_ONE',
  'ROLE_FORMULAS_SEARCH_METADATA',
  'ROLE_FORMULAS_EDIT_METADATA',
  'ROLE_ORDERS_LIST_OWN_CUSTOMER',
  'ROLE_ORDERS_LIST_OWN_PRESCRIBER',
  'ROLE_ORDERS_LIST_ALL',
  'ROLE_ORDERS_CREATE_OWN',
  'ROLE_ORDERS_CREATE_ONE',
  'ROLE_ORDERS_EDIT',
  'ROLE_ORDERS_OPEN',
  'ROLE_ORDERS_GET_ONE',
  'ROLE_ORDERS_GET_OWN',
  'ROLE_ORDERS_ADD_ITEMS',
  'ROLE_ORDERS_CHANGE_LINE_QUANTITY',
  'ROLE_ORDERS_REMOVE_ITEMS',
  'ROLE_ORDERS_REMOVE_ITEMS_BY_GROUP',
  'ROLE_PRODUCTS_LIST',
  'ROLE_COUNTRIES_LIST',
  'ROLE_CARRIERS_LIST',
  'ROLE_SHIPPING_METHODS_LIST',
  'ROLE_PROFILE_EDIT',
  'ROLE_AFFILIATIONS_LIST',
  'ROLE_AFFILIATION_REWARDS_LIST',
  'ROLE_OWN_PATIENT_FILES_LIST',
  'ROLE_DISPLAY_NOTIF',
  'ROLE_SEND_BATCH_NOTIF',
  'ROLE_ORDERS_CHANGE_CUSTOMER_OWN',
  'ROLE_ORDERS_CHANGE_CUSTOMER_ONE',
  'ROLE_LIST_PAYMENTS',
  'ROLE_PRINT_FORMULA_LABEL',
  'ROLE_COUNTRIES_EDIT',
  'ROLE_CARRIERS_EDIT',
  'ROLE_COUNTRY_ZONES_EDIT',
  'ROLE_SHIPPING_METHODS_EDIT',
  'ROLE_SHIPPING_METHOD_PRICES_EDIT',
  'ROLE_UPLOAD_FILE',
  'ROLE_EDIT_PAYMENTS',
  'ROLE_USERS_GET_ONE',
  'ROLE_VIEW_FILE_CONTENT',
  'ROLE_LINK_PATIENT_FILE',
  'ROLE_PHARMACO_SEND_PAY_MAIL_REMINDER',
  'ROLE_INVOICES_LIST',
  'ROLE_PHARMACO_SEND_TRACKING_MAIL',
  'ROLE_DISPLAY_LIST_ORDERS',
  'ROLE_LOYALTY_TRANSACTIONS_LIST',
] as const;

export const role_hierarchy = {
  ROLE_ANONYMOUS: ['ROLE_SHIPPING_METHODS_LIST', 'ROLE_UPLOAD_FILE'],
  ROLE_USER: ['ROLE_ANONYMOUS', 'ROLE_PROFILE_EDIT'],
  ROLE_PATIENT: [
    'ROLE_USER',
    'ROLE_ORDERS_LIST_OWN_CUSTOMER',
    'ROLE_ORDERS_GET_OWN',
  ],
  ROLE_PRACTITIONER: [
    'ROLE_PATIENT',
    'ROLE_NOTIFIERS_LIST',
    'ROLE_OWN_PATIENT_FILES_LIST',
    'ROLE_DISPLAY_NOTIF',
    'ROLE_USERS_GET_ONE',
    'ROLE_LINK_PATIENT_FILE',
  ],
  ROLE_PARTNER: ['ROLE_PRACTITIONER'],
  ROLE_PRACTITIONER_PHARMACO: [
    'ROLE_PRACTITIONER',
    'ROLE_PLANTS_LIST',
    'ROLE_CATEGORIES_LIST',
    'ROLE_CATEGORIES_SEARCH',
    'ROLE_MERIDIANS_LIST',
    'ROLE_MERIDIANS_SEARCH',
    'ROLE_NATURES_LIST',
    'ROLE_NATURES_SEARCH',
    'ROLE_PRODUCT_VARIATION_TYPES_LIST',
    'ROLE_PRODUCT_VARIATION_TYPES_SEARCH',
    'ROLE_PRODUCT_VARIATION_TYPES_EDIT',
    'ROLE_SAVOURS_LIST',
    'ROLE_SAVOURS_SEARCH',
    'ROLE_VARIATION_ATTRIBUTES_LIST',
    'ROLE_VARIATION_ATTRIBUTE_VALUES_LIST',
    'ROLE_PLANTS_GET_ONE',
    'ROLE_PLANTS_SEARCH',
    'ROLE_PLANTS_SEARCH_METADATA',
    'ROLE_ACTIONS_LIST',
    'ROLE_ACTIONS_SEARCH',
    'ROLE_FORMULAS_LIST',
    'ROLE_FORMULAS_GET_ONE',
    'ROLE_FORMULAS_SEARCH_METADATA',
    'ROLE_ORDERS_CREATE_OWN',
    'ROLE_ORDERS_CHANGE_CUSTOMER_OWN',
    'ROLE_ORDERS_LIST_OWN_PRESCRIBER',
    'ROLE_ORDERS_OPEN',
    'ROLE_ORDERS_EDIT',
    'ROLE_ORDERS_ADD_ITEMS',
    'ROLE_ORDERS_CHANGE_LINE_QUANTITY',
    'ROLE_ORDERS_REMOVE_ITEMS',
    'ROLE_ORDERS_REMOVE_ITEMS_BY_GROUP',
    'ROLE_COUNTRIES_LIST',
    'ROLE_LOYALTY_TRANSACTIONS_LIST',
  ],
  ROLE_ORDER_PICKER: [
    'ROLE_PRACTITIONER_PHARMACO',
    'ROLE_ORDERS_LIST_ALL',
    'ROLE_PRODUCTS_LIST',
    'ROLE_ORDERS_GET_ONE',
    'ROLE_ORDERS_CREATE_ONE',
    'ROLE_CARRIERS_LIST',
    'ROLE_ORDERS_CHANGE_CUSTOMER_ONE',
    'ROLE_PRINT_FORMULA_LABEL',
    'ROLE_VIEW_FILE_CONTENT',
  ],
  ROLE_ADMIN_WHOLESALER: [
    'ROLE_ORDER_PICKER',
    'ROLE_PLANTS_EDIT',
    'ROLE_CATEGORIES_EDIT',
    'ROLE_MERIDIANS_EDIT',
    'ROLE_NATURES_EDIT',
    'ROLE_SAVOURS_EDIT',
    'ROLE_PLANTS_EDIT_METADATA',
    'ROLE_PACKAGINGS_LIST',
    'ROLE_ACTIONS_EDIT',
    'ROLE_PRODUCT_METADATAS_EDIT',
    'ROLE_FORMULAS_EDIT',
    'ROLE_FORMULAS_LIST',
    'ROLE_FORMULAS_EDIT_METADATA',
    'ROLE_LIST_PAYMENTS',
    'ROLE_COUNTRIES_EDIT',
    'ROLE_CARRIERS_EDIT',
    'ROLE_COUNTRY_ZONES_EDIT',
    'ROLE_SHIPPING_METHODS_EDIT',
    'ROLE_SHIPPING_METHOD_PRICES_EDIT',
    'ROLE_EDIT_PAYMENTS',
    'ROLE_PHARMACO_SEND_PAY_MAIL_REMINDER',
    'ROLE_INVOICES_LIST',
    'ROLE_PHARMACO_SEND_TRACKING_MAIL',
  ],
  ROLE_ADMIN: [
    'ROLE_PRACTITIONER',
    'ROLE_ADMIN_WHOLESALER',
    'ROLE_USERS_LIST',
    'ROLE_AFFILIATIONS_LIST',
    'ROLE_AFFILIATION_REWARDS_LIST',
    'ROLE_SEND_BATCH_NOTIF',
  ],
};
