/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import { ProductVariationType } from '../../../core/entities/ProductVariationType';
import { useTranslation } from '@kiway/shared/utils/translation';
import { DatatablesController } from '@kiway/datatables';
import { deepen } from '@kiway/shared/utils/string';
import { Price } from '@kiway/ecommerce-react';

const prepareToSave = (item) => {
  return {
    ...deepen(item),
    id: item._id,
    _id: undefined,
    metadataType: 'productVariationType',
    price: new Price(item.price, 1).toInput(true),
  };
};

export interface ListProductVariationTypesDatatableProps {
  handleAddData?: () => void;
  handleSave?: any;
  productVariationTypes: ProductVariationType[];
  loading?: boolean;
  refreshData?: () => void;
}

export function ListProductVariationTypesDatatable({
  handleAddData,
  handleSave,
  loading,
  productVariationTypes,
  refreshData,
}: React.PropsWithChildren<ListProductVariationTypesDatatableProps>) {
  const { t } = useTranslation();
  return (
    <DatatablesController
      bulkEdit={true}
      columns={[
        {
          name: '_id',
          label: 'ID',
          type: 'text',
          options: {
            display: false,
            edit: false,
            filter: false,
          },
        },
        {
          name: 'shortcode',
          label: t(`pharmaco:productMetadatas.productVariationType.shortcode`),
          type: 'text',
        },
        {
          name: 'name.fr',
          label: t(`pharmaco:productMetadatas.name`, { lngCode: 'FR' }),
          type: 'text',
        },
        {
          name: 'name.en',
          label: t(`pharmaco:productMetadatas.name`, { lngCode: 'EN' }),
          type: 'text',
        },
        {
          name: 'name.es',
          label: t(`pharmaco:productMetadatas.name`, { lngCode: 'ES' }),
          type: 'text',
        },
        {
          name: 'name.pt',
          label: t(`pharmaco:productMetadatas.name`, { lngCode: 'PT' }),
          type: 'text',
        },
        {
          name: 'price',
          label: t(`pharmaco:productMetadatas.productVariationType.price`),
          type: 'price',
        },
      ]}
      data={productVariationTypes?.map((item) => item.toDatatableRow())}
      defaultSortOrder={{ name: 'name.fr', direction: 'asc' }}
      handleAddData={handleAddData}
      handleSaveData={
        handleSave
          ? (updates) => {
              if (handleSave) {
                return handleSave(updates.updatedData.map(prepareToSave));
              }
            }
          : undefined
      }
      id={'listProductVariationTypes'}
      loading={loading}
      options={{
        download: false,
        filter: false,
        print: false,
      }}
      refreshData={refreshData}
      title={
        t(`pharmaco:productMetadatas.productVariationType.cardTitle`) ||
        'ProductVariationTypes'
      }
    />
  );
}
