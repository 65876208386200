import * as React from 'react';
import { Typography } from '@material-ui/core';
import { Customer } from '../../../core/entities/Customer';
import { Skeleton } from '@material-ui/lab';

export interface ListCustomersProps {
  customers: Customer[];
  loading?: boolean;
}

const renderCustomer = (item: Customer) => (
  <li key={item.getId()}>{item.getId()}</li>
);

const loadingCustomerScreen = (
  <div>
    <ul>
      <li>
        <Skeleton variant="text" />
      </li>
      <li>
        <Skeleton variant="text" />
      </li>
      <li>
        <Skeleton variant="text" />
      </li>
    </ul>
  </div>
);

export function ListCustomers({
  loading,
  customers,
}: React.PropsWithChildren<ListCustomersProps>) {
  return (
    <div>
      <Typography variant="h5">Customer :</Typography>
      {loading ? (
        loadingCustomerScreen
      ) : (
        <div>
          <ul>{customers?.length ? customers?.map(renderCustomer) : 'Aucune donnée'}</ul>
        </div>
      )}
    </div>
  );
}
