import * as React from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DatatablesController } from '@kiway/datatables';
import { Order } from '@kiway/ecommerce-react-compatible';
import { Grid } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';

export interface ListOrdersCustomerProps {
  arrayId?: string;
  handleRowClick?: (rowIndex: number) => void;
  hideArrayTitle?: boolean;
  hideArrayToolbar?: boolean;
  hideArrayPagination?: boolean;
  loading?: boolean;
  orders: Order[];
}

export function ListOrdersCustomer(props: ListOrdersCustomerProps) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DatatablesController
          bulkEdit={false}
          columns={[
            {
              name: 'orderNumber',
              label: t('pharmaco:orders.columns.orderNumber'),
              type: 'text',
            },
            {
              name: 'openedAt',
              label: t('pharmaco:orders.columns.openedAt'),
              type: 'simple-date',
            },
            {
              name: 'paidAt',
              label: t('pharmaco:orders.columns.paidAt'),
              type: 'simple-date',
            },
            {
              name: 'prescriber',
              label: t('pharmaco:orders.columns.prescriber'),
              type: 'text',
            },
            {
              name: 'status',
              label: t('pharmaco:orders.columns.globalStatus'),
              type: 'status',
            },
            {
              name: 'totalGrossPrice',
              label: t('pharmaco:orders.columns.totalGrossPrice'),
              type: 'text',
              options: {
                display: false,
              },
            },
            {
              name: 'totalNetPrice',
              label: t('pharmaco:orders.columns.totalNetPrice'),
              type: 'text',
              options: {
                display: false,
              },
            },
            {
              name: 'totalPrice',
              label: t('pharmaco:orders.columns.totalPrice'),
              type: 'text',
            },
            // {
            //   name: 'shippingTrackingNumber',
            //   label: 'Suivi',
            //   type: 'text',
            // },
          ]}
          data={props.orders
            ?.filter((item) => item.getOrderStatus() !== 'DRAFT')
            ?.map((item) => item.toDatatableRow())
            ?.map(
              ({
                orderStatus,
                paymentStatus,
                packingStatus,
                shipmentStatus,
                shippingMethod,
                status,
                ...order
              }) => ({
                ...order,
                // ...flatten({ shippingMethod }),
                'shippingMethod.carrier.name':
                  shippingMethod?.carrier?.name ?? '',
                'shippingMethod.name': shippingMethod?.name ?? '',
                'shippingMethod.price': shippingMethod?.price ?? {},
                status: {
                  ...status,
                  label: `pharmaco:orderDetails.global.status.${status.label}`,
                },
                orderStatus: {
                  ...orderStatus,
                  label: `pharmaco:orderDetails.order.status.${orderStatus.label}`,
                },
                paymentStatus: {
                  ...paymentStatus,
                  label: `pharmaco:orderDetails.payment.status.${paymentStatus.label}`,
                },
                packingStatus: {
                  ...packingStatus,
                  label: `pharmaco:orderDetails.packing.status.${packingStatus.label}`,
                },
                shipmentStatus: {
                  ...shipmentStatus,
                  label: `pharmaco:orderDetails.shipment.status.${shipmentStatus.label}`,
                },
              })
            )}
          defaultSortOrder={{ name: 'orderNumber', direction: 'desc' }}
          handleRowClick={props.handleRowClick}
          id={props.arrayId ?? 'listOrders-customer'}
          loading={false}
          refreshData={() => console.log}
          title={!props.hideArrayTitle ? t('pharmaco:orders.docSubtitle') : ''}
          options={{
            filter: false,
            pagination: !props.hideArrayPagination,
          }}
        />
      </Grid>
    </Grid>
  );
}
