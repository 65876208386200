// import ArkhipTtf from '../assets/font/Arkhip_font.ttf'

const arkhip = {
  fontFamily: 'Arkhip',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
        local('Arkhip'),
        local('Arkhip-Regular'),
        url(../assets/font/Arkhip_font.ttf) format('truetype')
    `,
  unicodeRange:
    'U+0000-00FF, ' +
    'U+0131, ' +
    'U+0152-0153, ' +
    'U+02BB-02BC, ' +
    'U+02C6, ' +
    'U+02DA, ' +
    'U+02DC, ' +
    'U+2000-206F, ' +
    'U+2074, ' +
    'U+20AC, ' +
    'U+2122, ' +
    'U+2191, ' +
    'U+2193, ' +
    'U+2212, ' +
    'U+2215, ' +
    'U+FEFF',
};

const primaryColor = process.env.NX_COLOR_PRIMARY_MAIN;
const secondaryColor = process.env.NX_COLOR_SECONDARY_MAIN;
const minoritaryColor = process.env.NX_COLOR_MINORITARY_MAIN;
const minoritaryDarkColor = process.env.NX_COLOR_MINORITARY_DARK;

const theme = {
  palette: {
    primary: { main: primaryColor, contrastText: '#FFFFFF' },
    secondary: { main: secondaryColor, contrastText: '#FFFFFF' },
    minoritary: {
      main: minoritaryColor,
      dark: '#D9BE44',
      contrastText: '#000000',
    },
    backgroundLight: '#333',
    backgroundDark: '#212121',
    fontColor: '#FFFFFF',
  },
  typography: {
    overline: {
      fontFamily: 'Arkhip, Arial',
      fontSize: '1.3rem',
      textTransform: 'none',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [arkhip],
      },
    },
    MuiFab: {
      primary: {
        color: '#000000',
        backgroundColor: minoritaryColor,
        '&:hover': {
          backgroundColor: minoritaryDarkColor,
        },
      },
    },
    MuiSnackbar: {
      root: {
        position: 'relative',
      },
    },
  },
};

export default theme;
