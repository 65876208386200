export default {
  agenda: {
    bookedOnline: 'Réservé en ligne',
  },
  booking: {
    chiefComplaint: 'Motif de consultation',
    more: 'Voir Plus',
    next: 'Prochain RDV',
    slot: 'Choisissez un créneau',
    title: 'Prendre RDV en ligne',
    workplace: 'Lieu de consultation',
  },
  info: {
    reserved:
      'Votre rendez-vous est temporairement réservé, merci de le confirmer dans les 10 minutes ou il ne sera plus disponible.',
  },
  snackbars: {
    error: 'Une erreur est survenue, merci de réessayer plus tard.',
    success: 'Votre RDV a bien été enregistré.',
  },
  stepper: {
    confirmation: {
      button: 'Confirmer',
      comment: 'Ajouter un commentaire (facultatif)',
      dateFormat: 'DD-MM-YYYY à HH:mm',
      text: 'Rendez-vous le',
      title: 'Confirmation',
    },
    identification: {
      login: "J'ai déjà un compte",
      signup: 'Nouveau sur {{app}} ?',
      title: 'Identification',
    },
  },
};
