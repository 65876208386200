import { Carrier, ICarrierAttributes } from '../entities/Carrier';
import { CarrierProvider } from './CarrierProvider';

export class EditManyCarriers {
  private carrierProvider: CarrierProvider;

  public constructor(carrierProvider: CarrierProvider) {
    this.carrierProvider = carrierProvider;
  }

  public execute(
    carriers: Array<ICarrierAttributes>,
    userId?: string
  ): Promise<Array<Carrier>> {
    return this.carrierProvider.editMany(carriers, userId);
  }
}
