import * as React from 'react';
import { Typography } from '@material-ui/core';
import { toast } from 'react-toastify';

const Message = ({ message, customButton }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '15px',
      }}
    >
      <Typography
        variant="subtitle1"
        style={{
          width: '100%',
          marginBottom: customButton ? '8px' : undefined,
        }}
      >
        {message}
      </Typography>
      {customButton}
    </div>
  );
};

export function useSnackbar() {
  const toastIdError = React.useRef(null);
  const toastIdSuccess = React.useRef(null);

  const addSnackbar = (type: 'error' | 'success') => (
    message,
    customButton?,
    options?
  ) => {
    if (
      options?.withUpdate &&
      ((type === 'success' && toastIdSuccess.current) ||
        (type === 'error' && toastIdError.current))
    ) {
      updateSnackbar(
        type === 'success' ? toastIdSuccess.current : toastIdError.current
      );
    } else {
      const messageOption = (
        <Message message={message} customButton={customButton} />
      );
      const toastOptions = {
        onClose:
          type === 'success'
            ? () => (toastIdSuccess.current = null)
            : () => (toastIdError.current = null),
        ...options,
      };
      if (type === 'success') {
        toastIdSuccess.current = toast.success(messageOption, toastOptions);
        return toastIdSuccess.current;
      } else if (type === 'error') {
        toastIdError.current = toast.error(messageOption, toastOptions);
        return toastIdError.current;
      }
    }
  };

  const addSuccessSnackbar = addSnackbar('success');

  const addErrorSnackbar = addSnackbar('error');

  const updateSnackbar = (toastId) => {
    toast.update(toastId);
  };

  return { addSuccessSnackbar, addErrorSnackbar };
}
