import { Box, Grid, Step, StepLabel, Stepper } from '@mui/material';
import * as React from 'react';
import { useTranslation } from '@kiway/shared/utils/translation';

export interface OrderStepperProps {
  currentIndex: number;
  disableClickOnSteps?: boolean;
  steps: string[];
  handleClickOnStep?: any;
}

export function OrderStepper(props: OrderStepperProps) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            pt: 2,
            pb: 2,
            maxWidth: '1000px',
            margin: '0 auto',
          }}
        >
          <Stepper>
            {props.steps.map((label, index) => (
              <Step
                key={label}
                active={props.currentIndex === index}
                completed={props.currentIndex > index}
                onClick={
                  props.handleClickOnStep
                    ? () => props.handleClickOnStep(label)
                    : undefined
                }
              >
                <StepLabel
                  sx={{
                    '&:hover':
                      props.disableClickOnSteps ||
                      label === 'resume' ||
                      props.currentIndex === index
                        ? undefined
                        : {
                            cursor: 'pointer',
                            '& .MuiStepLabel-label': {
                              color: 'text.primary',
                              textDecoration: 'underline',
                            },
                          },
                  }}
                >
                  {t(`pharmaco:order.steps.${label}`)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Grid>
    </Grid>
  );
}
