import {
  LoyaltyTransaction,
  ILoyaltyTransactionAttributes,
} from '../../core/entities/LoyaltyTransaction';
import { LoyaltyTransactionProvider } from '../../core/use_cases/LoyaltyTransactionProvider';
import { gql, ApolloClient } from '@apollo/client';

export const LIST_LOYALTYTRANSACTIONS = gql`
  query listLoyaltyTransactions($user: ID) {
    listLoyaltyTransactions(user: $user) {
      id
      nbPoints
      comment
      user
      # Add here your attributes
    }
  }
`;

export type ILoyaltyTransactionAttributesData = ILoyaltyTransactionAttributes & {
  id: string;
};

export interface ListLoyaltyTransactionsData {
  listLoyaltyTransactions: Array<ILoyaltyTransactionAttributesData>;
}

export class LoyaltyTransactionGraphQLProvider
  implements LoyaltyTransactionProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async findAll(user?: string): Promise<LoyaltyTransaction[]> {
    try {
      const result = await this.client?.query<
        ListLoyaltyTransactionsData,
        { user?: string }
      >({
        query: LIST_LOYALTYTRANSACTIONS,
        variables: {
          user,
        },
      });
      return result.data.listLoyaltyTransactions.map(
        (item) => new LoyaltyTransaction(item)
      );
    } catch (e) {
      console.log(e);
    }
  }
  save(
    loyaltyTransaction: LoyaltyTransaction,
    userId?: string
  ): Promise<LoyaltyTransaction> {
    throw new Error('Method not implemented.');
  }
  editMany(
    entities: ILoyaltyTransactionAttributes[],
    userId?: string
  ): Promise<LoyaltyTransaction[]> {
    throw new Error('Method not implemented.');
  }
  getLoyaltyTransactionsByUser(user: string): Promise<LoyaltyTransaction[]> {
    return this.findAll(user);
  }
}
