export default {
  editProfile: {
    aboutMe: 'A propos',
    address: {
      add: {
        button: 'Ajouter une adresse',
        modalTitle: 'Ajouter une adresse',
      },
      default: {
        button: 'Définir par défaut',
        chip: 'Défaut',
      },
      delete: {
        button: 'Effacer',
        modalMessage: 'Êtes-vous sûr de vouloir supprimer cette adresse ?',
        modalTitle: "Suppression d'une adresse",
      },
      edit: {
        button: 'Modifier',
        modalTitle: 'Modifier une adresse',
      },
      title: 'Adresses',
    },
    cancelChangePicture: 'Annuler',
    changeEmailError:
      "Une erreur est survenue durant l'enregistrement. Il se peut que l'adresse email renseignée soit invalide. Merci d'essayer à nouveau.",
    changeEmailInfo:
      "Un email vous sera envoyé afin de vérifier que vous possédez bien cette adresse mail. Merci de cliquer sur le lien présent dans l'email.",
    changeEmailInvalid: 'Veuillez renseigner une adresse mail valide.',
    changeEmailSuccess:
      "Votre adresse mail a bien été modifiée, vous devez cliquer sur le lien reçu par email afin de valider cette adresse et pouvoir utiliser l'application.",
    changePasswordCancelButton: 'Annuler',
    changePasswordConfirmButton: 'Valider',
    changePasswordError:
      "Une erreur est survenue durant l'envoi du mail. Merci d'essayer à nouveau.",
    changePasswordInfo:
      'Vous allez recevoir un email contenant un lien pour réinitialiser votre mot de passe.',
    changePasswordSuccess:
      'Un email va bientôt arriver. Merci de cliquer sur le lien afin de réinitialiser votre mot de passe.',
    changePicture: 'Changer la photo',
    deletePicture: 'Supprimer la photo',
    email: 'Email',
    emailInfo:
      'Si vous changez votre adresse email, vous devrez la vérifier afin de pouvoir vous reconnecter.',
    helperTextNIF: "Numéro d'identification fiscale",
    helperTextPicture: 'Uniquement les liens sécurisés (https)',
    imageUrlLabel: "Lien vers l'image",
    linkText: 'Mon profil',
    nif: 'NIF',
    password: 'Mot de passe',
    passwordInfo:
      "Votre mot de passe n'est pas stocké en clair. C'est pourquoi nous ne pouvons vous l'afficher.",
    saveChangePicture: 'Enregistrer',
    saveError:
      "Un problème est survenu lors de l'enregistrement. Merci d'essayer à nouveau.",
    saveSuccess: 'Modifications enregistrées',
    security: 'Sécurité',
    sessions: {
      deleteAll: 'Supprimer toutes les sessions',
      deviceName: 'Nom',
      deviceType: 'Type',
      deviceTypes: {
        BROWSER: 'Navigateur',
        DESKTOP: 'DESKTOP',
        LAPTOP: 'LAPTOP',
        MOBILE: 'MOBILE',
        OTHER: 'OTHER',
        SERVER: 'SERVER',
        TABLET: 'TABLET',
        TV: 'TV',
        UNKNOWN: 'UNKNOWN',
      },
      ip: 'IP',
      lastAccessed: 'Dernière activité',
      title: 'Liste des sessions en cours',
    },
    settings: {
      browserDefaults: 'Valeur par défaut du navigateur',
      language: 'Langue',
      timezone: 'Timezone',
      title: 'Réglages',
    },
    title: 'Edition du profil',
    twoFA: {
      active: 'Actif',
      back: 'Précédent',
      cancel: 'Annuler',
      continue: 'Suivant',
      disable: 'Désactiver',
      disableError:
        "Un problème est survenu, il se peut que le code ne soit pas valide. Merci d'essayer à nouveau.",
      disableSuccess: 'Désactivation de la double authentification réussie.',
      dlApp: {
        description:
          "Il vous faut télécharger depuis le store de votre smartphone une application d'authentification (TOTP). Nous vous conseillons Google Authenticator.",
        label: "Installez une application d'authentification",
      },
      enable: 'Activer',
      enableError:
        "Un problème est survenu, il se peut que le code ne soit pas valide. Merci d'essayer à nouveau.",
      enableSuccess: 'Activation de la double authentification réussie.',
      enterCode: {
        description:
          'Vous devez entrez le code à 6 chiffres généré par l\'application et cliquer sur "Valider".',
        label: 'Entrez le code',
      },
      finish: 'Valider',
      inactive: 'Inactif',
      info:
        "Sécurisez votre connexion en ajoutant une deuxième méthode d'authentification",
      scanQRCode: {
        description:
          'Pour ajouter ce site à Google Authenticator, vous devez scannez le QR code ou rentrer manuellement le code secret.',
        label: 'Scannez le QR code',
      },
      secretCode: 'Code secret : {{ secret }}',
      title: 'Double authentification',
    },
  },
  register: {
    buttons: {
      backToHome: "Retour à l'accueil",
      submit: 'Créer mon espace',
    },
    disabled:
      'La création de compte est désactivée. Merci de contacter un administrateur.',
    errors: {
      birthDate: {
        ofAge: {
          patient:
            'Vous devez avoir au moins {{ age }} ans ou être accompagné par votre représentant légal',
          practitioner:
            "Vous devez avoir au moins {{ age }} ans pour utiliser l'application",
        },
      },
      errorRegistration:
        "Le compte n'a pas pu être créé. Merci d'essayer à nouveau ou de contacter un administrateur si le problème persiste.",
      errorRegistrationExternal:
        "Un problème est survenu durant l'enregistrement de votre inscription. Merci d'essayer à nouveau ou de contacter un administrateur si le problème persiste.",
      errorUserRegistrationExternal:
        "Un problème est survenu durant la création de votre compte. Merci d'essayer à nouveau ou de contacter un administrateur si le problème persiste.",
      unknownError:
        "Un problème est survenu. Merci d'essayer à nouveau ou de contacter un administrateur si le problème persiste.",
      userExists:
        'Ce compte email existe déjà. Si vous avez oublié votre mot de passe, vous pouvez le réinitialiser via la page de connexion.',
    },
    fields: {
      birthDate: 'Date de naissance',
      certifyLegalRepresentative:
        'Je certifie être accompagné par mon représentant légal *',
      email: 'Email',
      firstName: 'Prénom',
      lastName: 'Nom',
      referrerCode: 'Code parrainage',
    },
    success:
      'Votre compte a bien été créé. Vous allez recevoir un email pour paramétrer votre mot de passe puis un autre email validant votre inscription. A très vite sur notre plateforme !',
    title: 'Création de votre espace {{app}}',
  },
  signIn: {
    title: 'Connexion',
  },
  signOut: {
    linkText: 'Déconnexion',
    title: 'Déconnexion en cours',
  },
  users: {
    title: 'Gestion des utilisateurs',
  },
  usersManagement: {
    linkText: 'Admin utilisateurs',
  },
};
