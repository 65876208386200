/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  OrderStat,
  OrderStatName,
  OrderStatPeriod,
  useOrdersGateway,
} from '@kiway/ecommerce-react';
import React from 'react';
import { OrderPickerChart } from './OrderPickerChart';

interface OrderPickerChartController {
  period: OrderStatPeriod;
  displayType: OrderStatName;
}

export const OrderPickerChartController = (
  props: OrderPickerChartController
) => {
  const { getOrderStats } = useOrdersGateway();

  const [stats, setStats] = React.useState<OrderStat>();
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setStats(null);
    setLoading(true);
    getOrderStats(props.period, [props.displayType])
      .then((result) => setStats(result[0]))
      .finally(() => setLoading(false));
    return () => {
      setStats(null);
      setLoading(false);
    };
  }, [props.period, props.displayType]);

  return (
    <OrderPickerChart
      loading={loading}
      period={props.period}
      data={stats?.data}
    />
  );
};
