import { Order } from '@kiway/ecommerce-react-compatible';
import { MobileSafeArea, Modal } from '@kiway/shared/ui';
import { useTranslation } from '@kiway/shared/utils/translation';
import {
  Box,
  Button,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { links } from '../../../config';
import { PharmacoOrder } from '../../../core/entities/PharmacoOrder';
import { Summary } from '../Checkout/SummaryAndValidation/Summary';
import { CustomerInfo } from './CustomerInfo';

interface OrderDetailsCustomerProps {
  cancelOrder: (order: Order) => void;
  handlePayClick: () => void;
  order?: Order;
  pharmacoOrder?: PharmacoOrder;
}

export const OrderDetailsCustomer = (props: OrderDetailsCustomerProps) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    checkActiveStep();
  }, [props.order?.getStatus()]);

  const steps = [
    { id: 0, label: 'WAITING_PAYMENT' },
    { id: 1, label: 'WAITING_PACKING' },
    { id: 2, label: 'PENDING_PACKING' },
    { id: 3, label: 'WAITING_SHIPMENT' },
    { id: 4, label: 'PENDING_SHIPMENT' },
    {
      id: 5,
      label: 'COMPLETE',
    },
  ];

  const checkActiveStep = () => {
    switch (props.order?.getStatus()) {
      case 'WAITING_PAYMENT':
        setActiveStep(0);
        break;
      case 'WAITING_PACKING':
        setActiveStep(1);
        break;
      case 'PENDING_PACKING':
        setActiveStep(2);
        break;
      case 'WAITING_SHIPMENT':
        setActiveStep(3);
        break;
      case 'PENDING_SHIPMENT':
        setActiveStep(4);
        break;
      case 'COMPLETE':
        setActiveStep(5);
        break;
      default:
        setActiveStep(0);
        break;
    }
  };

  return (
    <MobileSafeArea>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Button
              size={'small'}
              color={'inherit'}
              component={Link}
              to={`/${links.main}/${links.listOrders}`}
            >
              {t('pharmaco:backToList')}
            </Button>
            {props.order?.canCancelOrder() && (
              <Button
                size="small"
                color="inherit"
                variant="contained"
                onClick={() => setOpenModal(true)}
              >
                {t('pharmaco:cancelOrder.title')}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography variant="h4" align="center">
                {t(`pharmaco:orderDetails.order.label`)} n°
                {props.order?.getOrderNumber()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {props.order?.getShipmentStatus() === 'SHIPPED' &&
          props.order?.getShippingMethod()?.getCarrier()?.getTrackingLink() && (
            <Grid item xs={12}>
              <Button
                color="minoritary"
                variant="contained"
                href={`${props.order
                  ?.getShippingMethod()
                  ?.getCarrier()
                  ?.getTrackingLink(props.order?.getShippingTrackingNumber())}`}
                target="_blank"
              >
                {t('pharmaco:orderDetails.buttons.followPackage')}
              </Button>
            </Grid>
          )}

        <Grid item xs={12} sx={{ mt: 5, mb: 5 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step) => (
              <Step key={step.id}>
                <StepLabel>
                  {step.label === 'COMPLETE'
                    ? t('pharmaco:orderDetails.shipment.status.SHIPPED')
                    : t(`pharmaco:orderDetails.global.status.${step.label}`)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>

        {props.order?.getPaymentStatus() === 'BALANCE_DUE' &&
          props.order?.getOrderStatus() === 'OPEN' && (
            <Grid item xs={12}>
              <Button
                color="minoritary"
                variant="contained"
                onClick={props.handlePayClick}
              >
                {t('pharmaco:orderDetails.payment.pay')}
              </Button>
            </Grid>
          )}

        <Grid item xs={12}>
          <Box component={Paper} sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ pb: 1 }}>
              {t(`pharmaco:orderDetails.therapist.label`)}
            </Typography>
            <CustomerInfo
              customer={props.order?.getPrescriber()}
              hideButton={true}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box component={Paper} sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ pb: 1 }}>
              {t(`pharmaco:orderDetails.comment.patientComment`)}
            </Typography>
            <Typography>
              {props.order?.getCustom()?.['patientComment']
                ? props.order?.getCustom()?.['patientComment']
                : t(`pharmaco:orderDetails.comment.default`)}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box component={Paper} sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ pb: 1 }}>
              {t(`pharmaco:orders.orderResume`)}
            </Typography>
            <Summary order={props.order} />
          </Box>
        </Grid>
      </Grid>

      {openModal && (
        <Modal
          actions={
            <>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => setOpenModal(false)}
              >
                {t('common:form.buttons.cancel')}
              </Button>
              <Button
                variant="contained"
                color="minoritary"
                onClick={() => {
                  props.cancelOrder(props.order);
                  setOpenModal(false);
                }}
              >
                {t('common:form.buttons.confirm')}
              </Button>
            </>
          }
          handleClose={() => setOpenModal(false)}
          open={openModal}
          title={t('pharmaco:cancelOrder.title')}
          disableEnforceFocus
        >
          {t('pharmaco:cancelOrder.message')}
        </Modal>
      )}
    </MobileSafeArea>
  );
};
