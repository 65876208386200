import { WholesalerGraphQLProvider } from '../../dataproviders/graphql-client/WholesalerGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllWholesalers } from '../../core/use_cases/GetAllWholesalers';
import { Wholesaler } from '../../core/entities/Wholesaler';

class WholesalersGateway {
  protected static instance: WholesalersGateway;
  protected getAllWholesalers: GetAllWholesalers;

  public static getInstance(client: ApolloClient<any>): WholesalersGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new WholesalersGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const wholesalerProvider = new WholesalerGraphQLProvider(client);
    this.getAllWholesalers = new GetAllWholesalers(wholesalerProvider);
    this.findAll = this.findAll.bind(this);
    WholesalersGateway.instance = this;
  }

  async findAll(): Promise<Wholesaler[]> {
    return await this.getAllWholesalers.execute();
  }
}

export function useWholesalersGateway() {
  const client = useApolloClient();
  const wholesalersGateway = WholesalersGateway.getInstance(client);
  return {
    findAll: wholesalersGateway.findAll,
  };
}
