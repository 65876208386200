export const getCommonOptions = (options) => {
  const { translate, disableHover, loading } = options;

  return {
    selectableRows: 'none',
    responsive: 'standard',
    rowHover: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50, 100],
    setRowProps: () => {
      return {
        className: !disableHover && 'MuiTableRow-hover',
      };
    },
    textLabels: {
      body: {
        noMatch: loading
          ? translate('common:loading')
          : translate('datatable:body.noMatch'),
        toolTip: translate('datatable:body.toolTip'),
        columnHeaderTooltip: (column) =>
          translate('datatable:body.columnHeaderTooltip', {
            columnLabel: column.label,
          }),
      },
      pagination: {
        next: translate('datatable:pagination.next'),
        previous: translate('datatable:pagination.previous'),
        rowsPerPage: translate('datatable:pagination.rowsPerPage'),
        displayRows: translate('datatable:pagination.displayRows'),
      },
      toolbar: {
        search: translate('datatable:toolbar.search'),
        downloadCsv: translate('datatable:toolbar.downloadCsv'),
        print: translate('datatable:toolbar.print'),
        viewColumns: translate('datatable:toolbar.viewColumns'),
        filterTable: translate('datatable:toolbar.filterTable'),
      },
      filter: {
        all: translate('datatable:filter.all'),
        title: translate('datatable:filter.title'),
        reset: translate('datatable:filter.reset'),
      },
      viewColumns: {
        title: translate('datatable:viewColumns.title'),
        titleAria: translate('datatable:viewColumns.titleAria'),
      },
      selectedRows: {
        text: translate('datatable:selectedRows.text'),
        delete: translate('datatable:selectedRows.delete'),
        deleteAria: translate('datatable:selectedRows.deleteAria'),
      },
    },
  };
};
