import * as React from 'react';
import { useRetailersGateway } from '../../../entrypoints/react/useRetailersGateway';
import { ListRetailers } from './ListRetailers';

export function ListRetailersController() {
  const [loading, setLoading] = React.useState(false);
  const [retailers, setRetailers] = React.useState([]);
  const { findAll } = useRetailersGateway();

  React.useEffect(() => {
    setLoading(true);
    findAll()
      .then((retailers) => {
        setRetailers(retailers);
      })
      .finally(() => setLoading(false));
    return () => {
      setRetailers([]);
    };
  }, []);

  return (
    <ListRetailers loading={loading} retailers={retailers} />
  );
}
