import fr from './translations/fr';
import en from './translations/en';
import pt from './translations/pt';
import es from './translations/es';

const namespace = 'ecommerce';

export const links = {
  main: 'ecommerce',
};

export default {
  namespace,
  routes: [],
  menus: {
    main: [],
  },
  translations: {
    fr,
    en,
    pt,
    es,
  },
};
