/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import { SafeArea } from '@kiway/shared/ui';
import { Box, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { Country, Order, ShippingMethod, Price } from '@kiway/ecommerce-react';
import { useTranslation } from '@kiway/shared/utils/translation';
import { PaymentMethod } from '@kiway/ecommerce-react-compatible';
import { Confirmation } from './PaymentSteps/Confirmation';
import { Verification } from './PaymentSteps/Verification';
import { DeliveryPayment } from './PaymentSteps/DeliveryPayment';
import { makeStyles } from '@material-ui/core/styles';

type PublicOrder = {
  _id: string;
  availableShippingMethods?: any[];
  custom: any;
  lineItems: any[];
  paymentAvailableTypes: string[];
  shippingAddress?: any;
  shippingMethodPicked?: any;
  shippingPrice: Price;
  totalGrossPrice: Price;
  totalGrossPriceByGroup: Price[];
  totalNetPrice: Price;
  totalNetPriceByGroup: Price[];
  totalPrice: Price;
  totalWeight: number;
};

export interface CheckoutProps {
  activeStep: number;
  availableCountries: Country[];
  availableShippingMethods?: ShippingMethod[];
  handleBankTransferProofDeleted: (isDeleted?: boolean) => void;
  handleBankTransferProofUploaded: (files: any[]) => any;
  handleChangeBankInfo: (name: string) => (e: any) => void;
  handleChangeCheckInfo: (name: string) => (e: any) => void;
  handleChangePaymentMethod: (paymentMethod: PaymentMethod) => void;
  handleChangeShippingAddress: (address: any) => void;
  handleChangeShippingMethod: (shippingMethodId: string) => void;
  handlePayClick: () => void;
  handleSavePayment: () => void;
  handleSaveShippingAddress: () => void;
  isPayButtonDisabled: () => boolean;
  order: Order;
  payLoading?: boolean;
  steps: string[];
  loading?: boolean;
  isRedirection?: boolean;
}

const useStyles = makeStyles({
  root: {
    '@media print': {
      backgroundColor: 'white',
      height: '100%',
    },
  },
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
});

export function Checkout(props: CheckoutProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState<PaymentMethod | null>(
    props.order?.getPaymentMethod()
  );

  React.useEffect(() => {
    setExpanded(props.order?.getPaymentMethod());
  }, [props.order?.getPaymentMethod()]);

  const handleChange = (name: PaymentMethod) => {
    if (expanded !== name) {
      setExpanded(name);
      props.handleChangePaymentMethod(name);
    }
  };

  if (!props.order) {
    return null;
  }

  const returnComponent = () => {
    switch (props.activeStep) {
      case 0:
        return (
          <Verification
            availableCountries={props.availableCountries}
            order={props.order}
            handleChangeShippingAddress={props.handleChangeShippingAddress}
            handleSaveShippingAddress={props.handleSaveShippingAddress}
          />
        );
      case 1:
        return (
          <DeliveryPayment
            order={props.order}
            availableShippingMethods={props.availableShippingMethods}
            handleChangeShippingMethod={props.handleChangeShippingMethod}
            handleChange={handleChange}
            expanded={expanded}
            handleBankTransferProofDeleted={
              props.handleBankTransferProofDeleted
            }
            handleBankTransferProofUploaded={
              props.handleBankTransferProofUploaded
            }
            handleChangeBankInfo={props.handleChangeBankInfo}
            handleChangeCheckInfo={props.handleChangeCheckInfo}
            handleSavePayment={props.handleSavePayment}
            payLoading={props.payLoading}
            isPayButtonDisabled={props.isPayButtonDisabled}
            handlePayClick={props.handlePayClick}
            loading={props.loading}
          />
        );
      case 2:
        return (
          <Confirmation
            order={props.order}
            isRedirection={props.isRedirection}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <SafeArea>
        {process.env.NX_PRIMARY_PAGE_LOGO_URL && (
          <Box
            sx={{ width: '100%', textAlign: 'center' }}
            className={classes.noPrint}
          >
            <img
              alt="logo"
              width="200px"
              src={process.env.NX_PRIMARY_PAGE_LOGO_URL}
            />
          </Box>
        )}
        <Box sx={{ my: 1 }}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography variant="h4" align="center">
                {t(`pharmaco:orderDetails.order.label`)} n°
                {props.order?.getOrderNumber()}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: '100%' }} className={classes.noPrint}>
          <Stepper activeStep={props.activeStep} alternativeLabel>
            {props.steps.map((label) => (
              <Step key={label}>
                <StepLabel>{t(`pharmaco:checkout.${label}`)}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box
          sx={{ width: '100%', maxWidth: '1200px', margin: '0 auto', mt: 4 }}
        >
          {returnComponent()}
        </Box>
      </SafeArea>
    </div>
  );
}
