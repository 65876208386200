const CURRENT_VERSION = process.env['NX_GIT_VERSION'];
const GOOGLE_API_KEY = process.env['NX_GOOGLE_API_KEY'];
const BASE_URL = process.env['NX_API_URL']
  ? `https://${process.env['NX_API_URL']}/v1`
  : '/api/v1';

const getHeaders = (options = {}) => {
  const { noContentType, version } = options;
  const myHeaders = new Headers();
  myHeaders.append('Accept', 'application/json');
  if (noContentType === undefined) {
    myHeaders.append('Content-Type', 'application/json');
  }
  if (version) {
    myHeaders.append('x-client-version', version);
  }
  return myHeaders;
};

export const fetchAPI = async (
  { url, init, headersOptions },
  check = true,
  noHeaders = false
) => {
  return fetch(
    url,
    Object.assign(
      init,
      noHeaders
        ? {}
        : {
            headers: getHeaders({
              ...headersOptions,
              version: CURRENT_VERSION,
            }),
            credentials: 'include',
          }
    )
  )
    .then((response) => {
      return response;
    })
    .catch(() => {
      // DO NOTHING
    });
};

export const runBasicFetch = (request, refreshIfTokenExpired = false) => {
  return fetchAPI(request).then(async (response) => {
    if (response && response.ok) {
      return response.json();
    } else if (refreshIfTokenExpired && response && response.status === 401) {
      const result = await response.json();
      if (result.message === 'jwt expired') {
        window.location.reload();
      }
    }
    return false;
  });
};

export const runBasicFetchV2 = (request, refreshIfTokenExpired = false) => {
  return fetchAPI(request).then(async (response) => {
    if (response && response.ok) {
      return response.json();
    } else if (refreshIfTokenExpired && response && response.status === 401) {
      const result = await response.json();
      if (result.message === 'jwt expired') {
        window.location.reload();
      }
    }
    return response;
  });
};

export const runBlobFetch = (request) => {
  return fetchAPI(request, true, true)
    .then((response) => {
      if (response && response.ok) {
        return response.blob();
      }
      return false;
    })
    .then((data) => {
      return data ? URL.createObjectURL(data) : false;
    });
};

export const runFileUploadFetch = (request) => {
  return fetchAPI(
    Object.assign(request, { headersOptions: { noContentType: true } })
  ).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return false;
  });
};

export const toDataUrl = (url, callback) => {
  return runBlobFetch(basicGetRequest(url)).then((data) => {
    if (callback) {
      callback(data);
    } else {
      return data;
    }
  });
};

export const getBlobUrl = (coordinates) => {
  const formattedCoordinates = `${coordinates.lat},${coordinates.lng}`;
  // eslint-disable-next-line max-len
  return `https://maps.googleapis.com/maps/api/staticmap?zoom=15&size=300x200&maptype=roadmap&markers=color:red|${formattedCoordinates}&key=${GOOGLE_API_KEY}`;
};

export const basicGetRequest = (url) => {
  return {
    url: `${url}`,
    init: {
      method: 'GET',
    },
  };
};

export const basicPutRequest = (url, data) => {
  return {
    url: `${url}`,
    init: {
      method: 'PUT',
      body: JSON.stringify(data),
    },
  };
};

export const basicPostRequest = (url, data) => {
  return {
    url: `${url}`,
    init: {
      method: 'POST',
      body: JSON.stringify(data),
    },
  };
};

export const basicDeleteRequest = (url) => {
  return {
    url: `${url}`,
    init: {
      method: 'DELETE',
    },
  };
};

/**
 * Prends en entrée des paramètres sous forme d'objet à passer sous forme de query string
 * Cette fonction permet de retourner un string encodé pour l'URL
 * @param {Object} obj Les paramètres à encoder
 */
export const serializeQueryString = (obj) => {
  const str = [];
  for (const p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }
  return str.join('&');
};

export const getBaseURL = () => {
  return BASE_URL;
};

export const getTimeZoneFromCoordinates = (lat, lng) => {
  let queryParams = { lat, lng };
  const url = `${BASE_URL}/utils/timezone${
    Object.keys(queryParams).length > 0
      ? '?' + serializeQueryString(queryParams)
      : ''
  }`;
  return runBasicFetch(basicGetRequest(url));
};
