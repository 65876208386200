/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react';
import { Dashboard } from './Dashboard';
import {
  OrderStatName,
  OrderStatPeriod,
} from '@kiway/ecommerce-react-compatible';
import { isGranted } from '@kiway/shared/utils/access-control-react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '@kiway/shared/features/authentication-react';

export const DashboardController = () => {
  const [orderPickerStatType, setOrderPickerStatType] = React.useState<
    OrderStatName
  >('nbPreparedByOrderPicker');
  const [period, setPeriod] = React.useState<OrderStatPeriod>('week');
  const {
    authState: { userInfo },
  } = React.useContext(AuthContext);

  return isGranted('ROLE_ADMIN_WHOLESALER', userInfo.roles) ? (
    <Dashboard
      period={period}
      setPeriod={setPeriod}
      orderPickerStatType={orderPickerStatType}
      setOrderPickerStatType={setOrderPickerStatType}
    />
  ) : isGranted('ROLE_ORDER_PICKER', userInfo.roles) ? (
    <Redirect to="/pharmaco/orders" />
  ) : null;
};
