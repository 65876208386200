import {
  ShippingMethodPrice,
  IShippingMethodPriceAttributes,
} from '../entities/ShippingMethodPrice';
import { ShippingMethodPriceProvider } from './ShippingMethodPriceProvider';

export class EditManyShippingMethodPrices {
  private shippingMethodPriceProvider: ShippingMethodPriceProvider;

  public constructor(shippingMethodPriceProvider: ShippingMethodPriceProvider) {
    this.shippingMethodPriceProvider = shippingMethodPriceProvider;
  }

  public execute(
    shippingMethodPrice: Array<IShippingMethodPriceAttributes>,
    userId?: string
  ): Promise<Array<ShippingMethodPrice>> {
    return this.shippingMethodPriceProvider.editMany(
      shippingMethodPrice,
      userId
    );
  }
}
