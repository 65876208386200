import { User, IUserAttributes } from '../../core/entities/User';
import {
  LoginResult,
  RefreshToken,
  Setup2FA,
  UserProvider,
  UserRegister,
  UserRegisterType,
} from '../../core/use_cases/UserProvider';
import { gql, ApolloClient } from '@apollo/client';

const userDefaultAttributes = `
  id
  email
  imageUrl
  defaultImageUrl
  mainRole
  roles
  firstName
  lastName
  gender
  verified
  birthDate
  profession
  twoFactor
  timezone
  language
  mobilePhone
  secondPhone
  referrerCode
  addresses {
    id
    city
    default
    line0
    line1
    line2
    line3
    firstName
    lastName
    zipCode
    countryCode
    mobilePhone
    email
    country {
      id
      code
      name
    }
    email
    mobilePhone
    nif
  }
  custom
`;

export const LIST_USERS = gql`
  query {
    listUsers {
      ${userDefaultAttributes}
      # Add here your attributes
    }
  }
`;

export const GET_2FA_URL = gql`
  query get2FAUrl($email: String) {
    get2FAUrl(email: $email) {
      qrCode
      secret
    }
  }
`;

export const SEND_FORGOT_PASSWORD = gql`
  mutation sendForgotPassword($email: String) {
    sendForgotPassword(email: $email)
  }
`;

export const CHANGE_EMAIL = gql`
  mutation changeEmail($email: String!) {
    changeEmail(email: $email) {
      ${userDefaultAttributes}
    }
  }
`;

export const ENABLE_2FA = gql`
  mutation enable2FA($code: String!, $secret: String!) {
    enable2FA(code: $code, secret: $secret) {
      ${userDefaultAttributes}
    }
  }
`;

export const DISABLE_2FA = gql`
  mutation disable2FA($code: String!, $methodId: String!) {
    disable2FA(code: $code, methodId: $methodId) {
      ${userDefaultAttributes}
    }
  }
`;

export const DELETE_REFRESH_TOKEN = gql`
  mutation deleteRefreshToken($tokenId: ID!) {
    deleteRefreshToken(tokenId: $tokenId) {
      id
      deviceName
      deviceType
      ipAddress
      lastAccessed
    }
  }
`;

export const DELETE_ALL_REFRESH_TOKENS = gql`
  mutation {
    deleteAllRefreshTokens {
      id
      deviceName
      deviceType
      ipAddress
      lastAccessed
    }
  }
`;

export const LOGIN = gql`
  mutation login($email: String!, $password: String!, $rememberMe: Boolean) {
    login(email: $email, password: $password, rememberMe: $rememberMe) {
      expiresAt
      token
      user {
        ${userDefaultAttributes}
        # Add here your attributes
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation {
    logout
  }
`;

export const CHECK_TOKEN = gql`
  query {
    checkToken {
      expiresAt
      user {
        ${userDefaultAttributes}
        # Add here your attributes
      }
    }
  }
`;

export const LIST_REFRESH_TOKENS = gql`
  query {
    listRefreshTokens {
      id
      deviceName
      deviceType
      ipAddress
      lastAccessed
    }
  }
`;

export const EDIT_MY_PROFILE = gql`
  mutation editMyProfile($userInput: UserProfileInput) {
    editMyProfile(user: $userInput) {
      ${userDefaultAttributes}
      # Add here your attributes
    }
  }
`;

export const REGISTER_USER = gql`
  mutation registerUser($user: UserRegisterInput!, $type: String!) {
    registerUser(user: $user, type: $type)
  }
`;

export const FIND_ONE = gql`
  query getOneUser($userId: String!) {
    getOneUser(userId: $userId) {
      ${userDefaultAttributes}
    }
  }
`;

export type IUserAttributesData = IUserAttributes & {
  id: string;
};

export interface ListUsersData {
  listUsers: Array<IUserAttributesData>;
}

export interface OneUserData {
  getOneUser: IUserAttributesData;
}

export interface LoginData {
  login: {
    expiresAt: string;
    token: string;
    user: IUserAttributesData;
  };
}

export interface CheckTokenData {
  checkToken: {
    expiresAt: string;
    token: string;
    user: IUserAttributesData;
  };
}

export interface ListRefreshTokensData {
  listRefreshTokens: RefreshToken[];
}

export interface Get2FAUrlData {
  get2FAUrl: Setup2FA;
}

export interface SendForgotPasswordData {
  sendForgotPassword: boolean;
}

export interface ChangeEmailData {
  changeEmail: IUserAttributesData;
}

export interface Enable2FAData {
  enable2FA: IUserAttributesData;
}

export interface Disable2FAData {
  disable2FA: IUserAttributesData;
}

export interface EditMyProfileData {
  editMyProfile: IUserAttributesData;
}

export interface DeleteRefreshTokenData {
  deleteRefreshToken: Array<RefreshToken>;
}

export interface DeleteAllRefreshTokensData {
  deleteAllRefreshTokens: Array<RefreshToken>;
}

export interface RegisterUserData {
  registerUser: string;
}

export class UserGraphQLProvider implements UserProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }
  findOneBy(find: Partial<IUserAttributes>): Promise<User> {
    throw new Error('Method not implemented.');
  }

  findOneByEmail(email: string): Promise<User> {
    throw new Error('Method not implemented.');
  }

  async findAll(): Promise<User[]> {
    try {
      const result = await this.client?.query<ListUsersData>({
        query: LIST_USERS,
      });
      return result.data.listUsers.map((item) => new User(item));
    } catch (e) {
      console.log(e);
    }
  }

  async login(
    email: string,
    password: string,
    rememberMe?: boolean
  ): Promise<LoginResult> {
    try {
      const result = await this.client?.mutate<
        LoginData,
        { email: string; password: string; rememberMe?: boolean }
      >({
        mutation: LOGIN,
        variables: {
          email,
          password,
          rememberMe,
        },
      });
      return result.data.login
        ? { ...result.data.login, user: new User(result.data.login.user) }
        : null;
    } catch (e) {
      console.log(e);
    }
  }

  async logout(): Promise<boolean> {
    try {
      const result = await this.client?.mutate<{ logout: boolean }>({
        mutation: LOGOUT,
      });
      return result.data.logout;
    } catch (e) {
      console.log(e);
    }
  }

  async checkToken(): Promise<LoginResult> {
    try {
      const result = await this.client?.query<CheckTokenData>({
        query: CHECK_TOKEN,
      });
      return result.data.checkToken
        ? {
            ...result.data.checkToken,
            user: new User(result.data.checkToken.user),
          }
        : null;
    } catch (e) {
      console.log(e);
    }
  }

  async listRefreshTokens(): Promise<RefreshToken[]> {
    try {
      const result = await this.client?.query<ListRefreshTokensData>({
        query: LIST_REFRESH_TOKENS,
      });
      return result.data.listRefreshTokens;
    } catch (e) {
      console.log(e);
    }
  }

  async get2FAUrl(email?: string): Promise<Setup2FA> {
    try {
      const result = await this.client?.query<
        Get2FAUrlData,
        { email?: string }
      >({
        query: GET_2FA_URL,
        variables: {
          email,
        },
      });
      return result.data.get2FAUrl;
    } catch (e) {
      console.log(e);
    }
  }

  async sendForgotPassword(email?: string): Promise<boolean> {
    try {
      const result = await this.client?.mutate<
        SendForgotPasswordData,
        { email?: string }
      >({
        mutation: SEND_FORGOT_PASSWORD,
        variables: {
          email,
        },
      });
      return result.data.sendForgotPassword;
    } catch (e) {
      console.log(e);
    }
  }

  async changeEmail(email: string): Promise<User> {
    try {
      const result = await this.client?.mutate<
        ChangeEmailData,
        { email: string }
      >({
        mutation: CHANGE_EMAIL,
        variables: {
          email,
        },
      });
      return result.data.changeEmail ? new User(result.data.changeEmail) : null;
    } catch (e) {
      console.log(e);
    }
  }

  async enable2FA(code: string, secret: string): Promise<User> {
    try {
      const result = await this.client?.mutate<
        Enable2FAData,
        { code: string; secret: string }
      >({
        mutation: ENABLE_2FA,
        variables: {
          code,
          secret,
        },
      });
      return result.data.enable2FA ? new User(result.data.enable2FA) : null;
    } catch (e) {
      console.log(e);
    }
  }

  async disable2FA(code: string, methodId: string): Promise<User> {
    try {
      const result = await this.client?.mutate<
        Disable2FAData,
        { code: string; methodId: string }
      >({
        mutation: DISABLE_2FA,
        variables: {
          code,
          methodId,
        },
      });
      return result.data.disable2FA ? new User(result.data.disable2FA) : null;
    } catch (e) {
      console.log(e);
    }
  }

  async deleteRefreshToken(tokenId: string): Promise<Array<RefreshToken>> {
    try {
      const result = await this.client?.mutate<
        DeleteRefreshTokenData,
        { tokenId: string }
      >({
        mutation: DELETE_REFRESH_TOKEN,
        variables: {
          tokenId,
        },
      });
      return result.data.deleteRefreshToken;
    } catch (e) {
      console.log(e);
    }
  }

  async deleteAllRefreshTokens(): Promise<Array<RefreshToken>> {
    try {
      const result = await this.client?.mutate<DeleteAllRefreshTokensData>({
        mutation: DELETE_ALL_REFRESH_TOKENS,
      });
      return result.data.deleteAllRefreshTokens;
    } catch (e) {
      console.log(e);
    }
  }

  async save(user: User, userId?: string): Promise<User> {
    try {
      const result = await this.client?.mutate<
        EditMyProfileData,
        { userInput: IUserAttributes }
      >({
        mutation: EDIT_MY_PROFILE,
        variables: {
          userInput: user.toJSON(true),
        },
      });
      return result.data.editMyProfile
        ? new User(result.data.editMyProfile)
        : null;
    } catch (e) {
      console.log(e);
    }
  }

  async registerUser(
    user: UserRegister,
    type: UserRegisterType
  ): Promise<string> {
    try {
      const result = await this.client?.mutate<
        RegisterUserData,
        { user: UserRegister; type: UserRegisterType }
      >({
        mutation: REGISTER_USER,
        variables: {
          user,
          type,
        },
      });
      return result.data.registerUser;
    } catch (e) {
      console.log(e);
    }
  }

  editMany(entities: IUserAttributes[], userId?: string): Promise<User[]> {
    throw new Error('Method not implemented.');
  }

  async findOneById(userId: string): Promise<User> {
    try {
      const result = await this.client?.query<OneUserData, { userId: string }>({
        query: FIND_ONE,
        variables: {
          userId,
        },
      });
      return result.data.getOneUser ? new User(result.data.getOneUser) : null;
    } catch (e) {
      console.log(e);
    }
  }
}
