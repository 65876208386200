import { RetailerGraphQLProvider } from '../../dataproviders/graphql-client/RetailerGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllRetailers } from '../../core/use_cases/GetAllRetailers';
import { Retailer } from '../../core/entities/Retailer';

class RetailersGateway {
  protected static instance: RetailersGateway;
  protected getAllRetailers: GetAllRetailers;

  public static getInstance(client: ApolloClient<any>): RetailersGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new RetailersGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const retailerProvider = new RetailerGraphQLProvider(client);
    this.getAllRetailers = new GetAllRetailers(retailerProvider);
    this.findAll = this.findAll.bind(this);
    RetailersGateway.instance = this;
  }

  async findAll(): Promise<Retailer[]> {
    return await this.getAllRetailers.execute();
  }
}

export function useRetailersGateway() {
  const client = useApolloClient();
  const retailersGateway = RetailersGateway.getInstance(client);
  return {
    findAll: retailersGateway.findAll,
  };
}
