import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from '@kiway/shared/utils/translation';
import {
  PharmacoOrderFormula,
  PharmacoOrderIngredient,
} from '../../../core/entities/PharmacoOrder';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { LineItem } from '@kiway/ecommerce-react-compatible';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CheckboxInput } from '@kiway/shared/ui';

interface OrderPreparationFormulaProps {
  formula: PharmacoOrderFormula;
  getRoundedItemWeight: (itemId: string) => number;
  getRoundedItemPortionWeight: (itemId: string) => number;
  handlePrepareClick: (itemId: string) => void;
  items: Array<{
    item: LineItem;
    ingredient: PharmacoOrderIngredient;
    prepared: boolean;
  }>;
  totalWeight: number;
  portionWeight: number;
  variationType: any;
  nbOfBags: string;
  handlePodPreparedChange: (formulaId: string) => void;
  loading?: boolean;
}

export function OrderPreparationFormula({
  formula,
  getRoundedItemWeight,
  getRoundedItemPortionWeight,
  handlePrepareClick,
  items,
  totalWeight,
  portionWeight,
  variationType,
  nbOfBags,
  handlePodPreparedChange,
  loading,
}: OrderPreparationFormulaProps) {
  const { t, currentLanguage } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Grid container columnSpacing={2} sx={{ p: 2 }} alignItems="center">
        <Grid item>
          <Typography variant="h5">{formula.getName()}</Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ color: 'text.secondary' }}>
            {variationType.getName()[currentLanguage?.code]}
          </Typography>
        </Grid>
        {formula.needToAddPod() && (
          <Grid item>
            <CheckboxInput
              disabled={loading}
              onChange={() => handlePodPreparedChange(formula.getGroupBy())}
              value={formula.getCustom().podPrepared ?? false}
              label={t('pharmaco:prescription.pod')}
            />
          </Grid>
        )}
      </Grid>
      {formula.getPosologyDescription(t) && (
        <Typography sx={{ px: 2, py: 0.5 }} variant="subtitle1">
          {formula.getShortenPosologyDescription(t)}
        </Typography>
      )}
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>
                <b>{t('pharmaco:plant.refStock')}</b>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <b>{t('pharmaco:orderDetails.formula.ref')}</b>
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography>
                <b>{t('pharmaco:orderDetails.formula.name')}</b>
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography>
                <b>{t('pharmaco:orderDetails.formula.quantity')}</b>
              </Typography>
            </TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(({ item, prepared }) => {
            return (
              <TableRow
                key={item.getProductVariation().getProductRef()}
                sx={() => ({
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor: prepared ? 'success.main' : undefined,
                  '& td, & th': {
                    color: prepared ? 'primary.contrastText' : undefined,
                  },
                  '&:nth-of-type(odd)': {
                    backgroundColor: prepared ? undefined : '#F0F0F0',
                  },
                })}
                onClick={(e) => {
                  if (!loading) {
                    handlePrepareClick(item.getId());
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              >
                <TableCell width="20%">
                  {item
                    .getProductVariation()
                    .getProductVariationType()
                    .getVariationAttributesValue()
                    ?.find(
                      (value) =>
                        value.getVariationAttribute().getName() ===
                        'plantPowderType'
                    )
                    .getValue() === 'plant'
                    ? item.getProduct().getCustom().referenceStockPlant
                    : item.getProduct().getCustom().referenceStockPowder}
                </TableCell>
                <TableCell width="20%">
                  {item.getProductVariation().getProductRef()}
                </TableCell>
                <TableCell width="40%" align="left">
                  {`${item.getProduct().getCustom().pinYinName} `}
                  {formula.getPlantPowderType() === 'plant' && (
                    <Typography
                      sx={{
                        display: 'inline',
                        color: 'error.main',
                        fontWeight: 'bold',
                      }}
                    >
                      {`${
                        item.getCustom()?.isSeparated
                          ? `(${t('pharmaco:formula.separate')})`
                          : ''
                      }`}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="left" width="30%">
                  {item
                    .getProductVariation()
                    .getProductVariationType()
                    .getVariationAttributesValue()
                    ?.find(
                      (value) =>
                        value.getVariationAttribute().getName() ===
                        'plantPowderType'
                    )
                    .getValue() === 'plant' &&
                  item
                    .getProductVariation()
                    .getProductVariationType()
                    .getVariationAttributesValue()
                    ?.find(
                      (value) =>
                        value.getVariationAttribute().getName() ===
                        'pharmaceuticalForm'
                    )
                    .getValue() === 'small_bag'
                    ? nbOfBags +
                      ' x ' +
                      parseFloat(
                        `${getRoundedItemPortionWeight(item.getId())}`
                      ).toFixed(2) +
                      ' g = ' +
                      parseFloat(
                        `${getRoundedItemWeight(item.getId())}`
                      ).toFixed(2)
                    : parseFloat(
                        `${getRoundedItemWeight(item.getId())}`
                      ).toFixed(2)}{' '}
                  g
                </TableCell>
                <TableCell align="right" width="10%">
                  <Checkbox
                    sx={{
                      p: 0,
                      color: prepared ? 'primary.contrastText' : undefined,
                      '&.Mui-checked': {
                        color: prepared ? 'primary.contrastText' : undefined,
                      },
                    }}
                    icon={<AddIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    checked={prepared ?? false}
                    onChange={() => handlePrepareClick(item.getId())}
                  />
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell width="50%" colSpan={3} align="right">
              <b>{t('pharmaco:total')}</b>
            </TableCell>
            <TableCell width="10%" align="left">
              <b>
                {variationType
                  .getVariationAttributesValue()
                  ?.find(
                    (value) =>
                      value.getVariationAttribute().getName() ===
                      'plantPowderType'
                  )
                  .getValue() === 'plant' &&
                variationType
                  .getVariationAttributesValue()
                  ?.find(
                    (value) =>
                      value.getVariationAttribute().getName() ===
                      'pharmaceuticalForm'
                  )
                  .getValue() === 'small_bag'
                  ? `${
                      nbOfBags +
                      ' x ' +
                      parseFloat(`${portionWeight}`).toFixed(2) +
                      ' g = ' +
                      parseFloat(`${totalWeight}`).toFixed(2)
                    }`
                  : `${parseFloat(`${totalWeight}`)?.toFixed(2)} g`.replace(
                      /\s/g,
                      '\u00A0'
                    )}
              </b>
            </TableCell>
            <TableCell width="40%" colSpan={2}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
