import { PaginatedResults, PaginationOptions } from '@kiway/shared/react-types';
import { Formula } from '../entities/Formula';
import { FormulaProvider } from './FormulaProvider';

export class GetAllFormulas {
  private formulaProvider: FormulaProvider;

  public constructor(formulaProvider: FormulaProvider) {
    this.formulaProvider = formulaProvider;
  }

  public execute(
    find?: any,
    pagination?: PaginationOptions
  ): Promise<PaginatedResults<Formula>> {
    return this.formulaProvider.findAll(find, pagination);
  }
}
