export default {
  body: {
    columnHeaderTooltip: 'Ordenar por {{columnLabel}}',
    noMatch: 'ningún resultado',
    toolTip: 'ordenar',
  },
  filter: {
    all: 'Todos',
    reset: 'Reiniciar',
    title: 'Filtros',
  },
  pagination: {
    displayRows: 'en',
    next: 'Página siguiente ',
    previous: 'página anterior',
    rowsPerPage: 'Líneas por página:',
  },
  selectedRows: {
    delete: 'Borrar',
    deleteAria: 'Eliminar líneas seleccionadas',
    text: 'Línea (s) seleccionada (s)',
  },
  toolbar: {
    downloadCsv: 'Exportar en CSV',
    filterTable: 'Filtros',
    print: 'Imprimir',
    search: 'Buscar',
    viewColumns: 'Selección de columnas',
  },
  viewColumns: {
    title: 'Columnas mostradas',
    titleAria: 'Mostrar/ocultar columnas',
  },
};
