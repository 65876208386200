export default {
  fields: {
    appointmentDate: {
      error: 'El inicio y el final de una cita debe ser el mismo día',
    },
    email: {
      error: 'Gracias por indicar una dirección de correo electrónico válida',
    },
    lastname: {
      error: 'por favor, escriba su nombre',
    },
    yourMessage: {
      error: 'Por favor escriba un mensaje',
    },
  },
  login: {
    accountNotVerified:
      'Su cuenta no ha sido activada, comuníquese con un administrador a través de cat o contact@kiway.co',
    error: 'Identificadores no válidos.',
    noRights:
      'No tiene suficientes derechos para conectarse a este espacio, ¿está seguro de que está en el correcto?',
    resetError:
      'Se produjo un error durante el envío de su solicitud. Por favor intente de nuevo.',
  },
  requiredField: {
    empty: 'Campo requerido',
  },
};
