import './checkbox-input.module.css';
import * as React from 'react';
import {
  Checkbox,
  FormControlLabel,
  Typography,
  TypographyProps,
} from '@mui/material';

export interface CheckboxInputProps {
  disabled?: boolean;
  displayOnPrimaryPage?: boolean;
  error?: string;
  label: string | React.ReactNode;
  labelAlign?: TypographyProps['align'];
  labelVariant?: TypographyProps['variant'];
  name?: string;
  noMargin?: boolean;
  onChange?: any;
  required?: boolean;
  value?: boolean;
}

export function CheckboxInput(props: CheckboxInputProps) {
  return (
    <FormControlLabel
      disabled={props.disabled}
      sx={{
        mt: props.noMargin ? 0 : 1,
        color:
          props.error && props.error !== '' && !props.value
            ? 'error.main'
            : !props.displayOnPrimaryPage
            ? undefined
            : 'primary.contrastText',
        borderColor:
          (props.error && props.error !== '' && !props.value) ||
          !props.displayOnPrimaryPage
            ? undefined
            : 'primary.contrastText',
        '& a': {
          color:
            (props.error && props.error !== '' && !props.value) ||
            !props.displayOnPrimaryPage
              ? undefined
              : 'primary.contrastText',
        },
      }}
      control={
        <Checkbox
          value={props.value}
          checked={props.value}
          name={props.name}
          sx={() => {
            return props.error && props.error !== ''
              ? {
                  color: 'error.main',
                  '&.Mui-checked': { color: 'error.main' },
                }
              : props.displayOnPrimaryPage
              ? {
                  color: 'primary.contrastText',
                  '&.Mui-checked': { color: 'primary.contrastText' },
                }
              : {};
          }}
          onChange={props.onChange}
          required={props.required}
        />
      }
      label={
        <Typography
          component="div"
          variant={props.labelVariant}
          align={props.labelAlign}
        >
          {props.label}
        </Typography>
      }
    />
  );
}

export default CheckboxInput;
