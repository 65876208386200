import * as React from 'react';
import { useStoredFilesGateway } from '../../../entrypoints/react/useStoredFilesGateway';
import { ListStoredFiles } from './ListStoredFiles';

export function ListStoredFilesController() {
  const [loading, setLoading] = React.useState(false);
  const [storedFiles, setStoredFiles] = React.useState([]);
  const { findAll } = useStoredFilesGateway();

  React.useEffect(() => {
    setLoading(true);
    findAll()
      .then((storedFiles) => {
        setStoredFiles(storedFiles);
      })
      .finally(() => setLoading(false));
    return () => {
      setStoredFiles([]);
    };
  }, []);

  return <ListStoredFiles loading={loading} storedFiles={storedFiles} />;
}
