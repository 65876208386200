import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Modal } from '@kiway/shared/ui';
import { Plant } from '../../../../core/entities/Plant';

import { Formula } from '../../../../core/entities/Formula';
import { InfoPlant } from './InfoPlant';
import {
  Paper,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
} from '@mui/material';
import SpaIcon from '@mui/icons-material/Spa';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import { kiwayLanguages, KiwayLanguagesType } from '@kiway/shared/react-types';
import { useTranslation } from '@kiway/shared/utils/translation';
import { zip } from 'lodash';

export interface InfoModalProps {
  open: boolean;
  plant?: Plant;
  formula?: Formula;
  handleClose: (e?: any) => void;
  type: 'plant' | 'formula';
}

export function InfoModal(props: InfoModalProps) {
  const [clickedPlant, setClickedPlant] = useState<Plant>(undefined);
  const { addTranslationDynamically, t } = useTranslation();

  const translations = {};

  props.type === 'formula' &&
    Object.keys(props.formula?.getDescription()).map((lng) => {
      translations[lng] = {
        ...(translations?.[lng] ?? {}),
        [props.formula?.getId()]: {
          ...(translations[lng]?.[props.formula?.getId()] ?? {}),
          description: props.formula?.getDescription()[lng],
        },
      };
    });

  kiwayLanguages.map((lng) => {
    addTranslationDynamically(
      lng as KiwayLanguagesType,
      'formula',
      translations[lng],
      true
    );
  });

  return (
    <Modal
      title={
        props.type === 'plant'
          ? `${props.plant.getPinYinName()} - ${props.plant.getChineseName()}`
          : `${props.formula.getPinYinName()}`
      }
      open={props.open}
      handleClose={props.handleClose}
      maxWidth={props.type === 'plant' ? 'sm' : 'md'}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Grid container textAlign="center">
        {props.type === 'formula' ? (
          <Grid container>
            <Grid item xs={12} textAlign="start">
              <Paper sx={{ padding: 2 }}>
                <Typography sx={{ textDecoration: 'underline' }}>
                  {t('pharmaco:formula.fangZheng')}
                </Typography>
                <Typography color="text.secondary">
                  {t(`formula:${props.formula.getId()}.description`)}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4} padding={3}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>
                    {t('pharmaco:productMetadatas.composition')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider variant="middle" />
                </Grid>
                <Grid item xs={12}>
                  <List dense disablePadding>
                    {props.formula.getComposition().map((compo) => {
                      return (
                        <ListItem
                          key={compo.getId()}
                          onClick={() => {
                            setClickedPlant(compo.getPlant());
                          }}
                          sx={{
                            cursor: 'pointer',
                            padding: '0 15px',
                            borderRadius: '5px',
                            backgroundColor:
                              clickedPlant === compo.getPlant()
                                ? 'secondary.main'
                                : 'none',
                            '&:hover': {
                              backgroundColor: 'lightgray',
                            },
                          }}
                        >
                          <ListItemText
                            primary={compo.getPlant().getPinYinName()}
                          />
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            {compo.getDosage()}g
                          </Typography>
                          {compo.getPlant().isPlant() && (
                            <IconButton edge="end">
                              <SpaIcon />
                            </IconButton>
                          )}
                          {compo.getPlant().isConcentratedPowder() && (
                            <IconButton size="small">
                              <BlurOnIcon />
                            </IconButton>
                          )}
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={8}
              padding={3}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {clickedPlant ? (
                <>
                  <Typography variant="h5" sx={{ mb: 3 }}>
                    {clickedPlant.getCustom().pinYinName}
                  </Typography>
                  <InfoPlant plant={clickedPlant} />
                </>
              ) : (
                <>
                  <Typography fontStyle={'italic'}>
                    {t('pharmaco:formula.clickPlantToSeeMore')}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <InfoPlant plant={props.plant} />
          </Grid>
        )}
      </Grid>
    </Modal>
  );
}
