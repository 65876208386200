export default {
  agenda: {
    bookedOnline: 'Booked Online',
  },
  booking: {
    chiefComplaint: 'Chief Complaint',
    more: 'Show More',
    next: 'Next Available',
    slot: 'Pick A Slot',
    title: 'Book An Appointment',
    workplace: 'Consultation Place',
  },
  info: {
    reserved:
      "Your appointment is temporarily booked, please confirm within 10 minutes or it won't be available any longer.",
  },
  snackbars: {
    error: 'Something went wrong, please try again later.',
    success: 'Your appointment has been successfully booked.',
  },
  stepper: {
    confirmation: {
      button: 'Confirm',
      comment: 'Please add a comment (optional)',
      dateFormat: 'YYYY-MM-DD at HH:mm',
      text: 'Appointment on',
      title: 'Confirmation',
    },
    identification: {
      login: 'I already have an account',
      signup: 'No account on {{app}}?',
      title: 'Identification',
    },
  },
};
