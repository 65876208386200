import { StoredFileGraphQLProvider } from '../../dataproviders/graphql-client/StoredFileGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllStoredFiles } from '../../core/use_cases/GetAllStoredFiles';
import { StoredFile } from '../../core/entities/StoredFile';
import { StoredFileProvider } from '../../core/use_cases/StoredFileProvider';

class StoredFilesGateway {
  protected static instance: StoredFilesGateway;
  protected getAllStoredFiles: GetAllStoredFiles;
  protected storedFileProvider: StoredFileProvider;

  public static getInstance(client: ApolloClient<any>): StoredFilesGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new StoredFilesGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    this.storedFileProvider = new StoredFileGraphQLProvider(client);
    this.getAllStoredFiles = new GetAllStoredFiles(this.storedFileProvider);
    this.findAll = this.findAll.bind(this);
    this.deleteOneStoredFile = this.deleteOneStoredFile.bind(this);
    StoredFilesGateway.instance = this;
  }

  async findAll(): Promise<StoredFile[]> {
    return await this.getAllStoredFiles.execute();
  }

  async deleteOneStoredFile(fileId: string): Promise<boolean> {
    return await this.storedFileProvider.deleteOne(fileId);
  }
}

export function useStoredFilesGateway() {
  const client = useApolloClient();
  const storedFilesGateway = StoredFilesGateway.getInstance(client);
  return {
    findAll: storedFilesGateway.findAll,
    deleteOneStoredFile: storedFilesGateway.deleteOneStoredFile,
  };
}
