import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductDetailsBooleanType } from './ProductDetailsBooleanType';
import { ProductDetailsCheckboxType } from './ProductDetailsCheckboxType';
import { ProductDetailsField } from './ProductDetailsField';

export interface ProductDetailsFieldWrapperProps {
  fullWidth?: boolean;
  handleChange: (value: string | number | boolean) => any;
  handleSave: (id?: string) => void;
  inputType: 'text' | 'number' | 'checkbox' | 'boolean';
  label: string | { label: string; params: any };
  loading?: boolean;
  style?: any;
  value?: string | boolean | number;
}

export const ProductDetailsFieldWrapper = ({
  fullWidth,
  handleChange,
  handleSave,
  inputType,
  label,
  loading,
  style,
  value,
}: React.PropsWithChildren<ProductDetailsFieldWrapperProps>) => {
  const { t } = useTranslation();

  const checkforDisplay = () => {
    switch (inputType) {
      case 'boolean':
        return (
          <ProductDetailsBooleanType
            label={
              typeof label !== 'string'
                ? t(label.label, label.params)
                : t(label)
            }
            loading={loading}
            value={value as boolean}
          />
        );
      case 'checkbox':
        return (
          <ProductDetailsCheckboxType
            handleChange={handleChange}
            handleSave={handleSave}
            label={
              typeof label !== 'string'
                ? t(label.label, label.params)
                : t(label)
            }
            loading={loading}
            value={value as boolean}
          />
        );
      default:
        return (
          <ProductDetailsField
            fullWidth={fullWidth}
            handleChange={handleChange}
            handleSave={handleSave}
            inputType={inputType}
            label={
              typeof label !== 'string'
                ? t(label.label, label.params)
                : t(label)
            }
            loading={loading}
            style={style}
            value={value}
          />
        );
    }
  };

  return checkforDisplay();
};
