export default {
  dateTimePicker: {
    buttons: {
      cancel: {
        text: 'Cancel',
      },
      clear: {
        text: 'Clear',
      },
      ok: {
        text: 'Ok',
      },
      today: {
        text: 'Today',
      },
    },
  },
  format: {
    agendaSettingsHour: 'HH:mm:ss',
    date: 'MM/DD/YYYY',
    datePlaceholder: 'DD/MM/YYYY',
    dayMonthYearHourMinute: 'MM/DD/YYYY h:mm a',
    hour: 'h:mm a',
    hourOnly: 'h a',
    hourTxt: 'h:mm a',
    invalid: 'Invalid date',
    isoDate: 'YYYY-MM-DD',
    isoHour: 'HH:mm:ssSSSZ',
    literalComplete: 'Do [of] MMMM YYYY [at] h:mm a',
    literalDate: 'Do [of] MMMM YYYY',
    shortDate: 'MMM Do YYYY',
    shortDay: 'ddd',
    shortDayMonth: 'MMM Do',
    shortYearDate: 'MM/DD/YY',
    smsSentDate: 'M/D/YYYY [at] h:mm A',
  },
  hourFormat: '12',
  moment: 'en-gb',
  month: 'month',
  strings: {
    currentMonth: 'This month',
    currentWeek: 'This week',
    previousMonth: 'Previous month',
    previousWeek: 'Previous week',
  },
};
