import * as React from 'react';
import { Logout } from './Logout';
import { AuthContext } from '../../../react';
import { useHistory } from 'react-router-dom';

export function LogoutController() {
  const history = useHistory();
  const { logout } = React.useContext(AuthContext);
  React.useEffect(() => {
    logout()?.then(() => {
      history.push('/');
    });
  }, []);
  return <Logout />;
}
