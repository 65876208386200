/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import { Register } from './Register';
import moment, { Moment } from 'moment';
import { useQuery } from '@kiway/routing-builder';
import ReactGA from 'react-ga';
import { gaEvents } from '@kiway/shared/utils-react';
import { useTranslation } from '@kiway/shared/utils/translation';
import { useUsersGateway } from '../../../entrypoints/react/useUsersGateway';
import { RegisterDisabled } from '../..';

export interface RegisterControllerProps {
  type: 'patient' | 'practitioner';
  [name: string]: any;
}

export function RegisterController(props: RegisterControllerProps) {
  const query = useQuery();
  const { t } = useTranslation();
  const { registerUser } = useUsersGateway();
  const [result, setResult] = React.useState(['none', '']);

  const affiliationId = query?.get('affiliationId');
  const [birthDate, setBirthDate] = React.useState<Moment | null>(null);
  const [
    certifyLegalRepresentative,
    setCertifyLegalRepresentative,
  ] = React.useState<boolean>(false);
  const [CGU, setCGU] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>(
    query.get('email')?.toLowerCase() ?? ''
  );
  const [errors, setErrors] = React.useState<any>({});
  const [firstName, setFirstName] = React.useState<string>('');
  const [GDPR, setGDPR] = React.useState<boolean>(false);
  const [lastName, setLastName] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [ofAge, setOfAge] = React.useState<boolean>(false);
  const [referrerCode, setReferrerCode] = React.useState<string>(
    query?.get('referrer') ?? ''
  );

  const handleEmailError = (error: string) =>
    setErrors((prev: any) => ({ ...prev, email: error }));

  const isOfAge = (newDate: Moment): boolean => {
    const years = moment().diff(newDate, 'years', true);
    return years >= (process.env.NX_REGISTER_MIN_AGE ?? 18);
  };

  const handleBirthDateError = (newDate) => {
    if (!isOfAge(newDate)) {
      setOfAge(false);
      setErrors((prev: any) => ({
        ...prev,
        birthDate: t(
          `authentication:register.errors.birthDate.ofAge.${props.type}`,
          {
            age: process.env.NX_REGISTER_MIN_AGE ?? 18,
          }
        ),
      }));
    } else {
      setOfAge(true);
      setErrors((prev: any) => ({ ...prev, birthDate: '' }));
    }
  };

  const handleChange = (name: string) => (event: any) => {
    switch (name) {
      case 'birthDate':
        setBirthDate(event);
        handleBirthDateError(event);
        break;
      case 'certifyLegalRepresentative':
        setCertifyLegalRepresentative((prev) => {
          if (prev) {
            if (!isOfAge(birthDate)) {
              // User uncheck this checkbox and is not of age so we setup the error
              setErrors((prev: any) => ({
                ...prev,
                birthDate: t(
                  `authentication:register.errors.birthDate.ofAge.${props.type}`,
                  {
                    age: process.env.NX_REGISTER_MIN_AGE ?? 18,
                  }
                ),
              }));
            } else {
              // User uncheck this checkbox and is of age so we remove the error
              setErrors((prev: any) => ({
                ...prev,
                birthDate: '',
              }));
            }
          } else {
            // User check this checkbox so we remove the error
            setErrors((prev: any) => ({
              ...prev,
              birthDate: '',
            }));
          }
          return !prev;
        });
        break;
      case 'CGU':
        setCGU((prev) => !prev);
        break;
      case 'email':
        setEmail(event?.target?.value?.toLowerCase() ?? '');
        break;
      case 'firstName':
        setFirstName(event?.target?.value ?? '');
        break;
      case 'GDPR':
        setGDPR((prev) => !prev);
        break;
      case 'lastName':
        setLastName(event?.target?.value ?? '');
        break;
      case 'referrerCode':
        setReferrerCode(event?.target?.value ?? '');
        break;
    }
  };

  const backToHome = () => props.history.push('/');

  const isSubmitDisabled = () => {
    if (errors.email && errors.email !== '') {
      return true;
    }
    if (email === '' || !birthDate?.isValid()) {
      return true;
    }
    if (!GDPR || !CGU) {
      return true;
    }
    if (!ofAge && props.type === 'practitioner') {
      return true;
    }
    if (displayCertifyLegalRepresentative() && !certifyLegalRepresentative) {
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    handleEventGA(props.type);
    setLoading(true);
    registerUser(
      {
        affiliationId,
        birthDate: birthDate?.format('yyyy-MM-DD'),
        certifyLegalRepresentative,
        CGU,
        email,
        firstName,
        GDPR,
        lastName,
        referrerCode,
      },
      props.type
    )
      .then((result) => {
        if (result !== 'ok') {
          setResult(['error', t(`authentication:register.errors.${result}`)]);
        } else {
          setResult(['success', t('authentication:register.success')]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEventGA = (userType: 'patient' | 'practitioner') => {
    if (userType === 'patient') {
      ReactGA.event({
        category: gaEvents.SIGNUP_CAT,
        action: gaEvents.CREATE_ACCOUNT_ACTION,
        label: gaEvents.PATIENT_LABEL,
      });
    } else if (userType === 'practitioner') {
      ReactGA.event({
        category: gaEvents.SIGNUP_CAT,
        action: gaEvents.CREATE_ACCOUNT_ACTION,
        label: gaEvents.PRACTITIONER_LABEL,
      });
    }
  };

  const displayCertifyLegalRepresentative = () => {
    if (props.type === 'practitioner' || !birthDate?.isValid() || ofAge) {
      return false;
    }
    return true;
  };

  return process.env.NX_REGISTER_ENABLED === 'true' ? (
    <Register
      backToHome={backToHome}
      birthDate={birthDate}
      birthDateError={errors.birthDate ?? ''}
      certifyLegalRepresentative={certifyLegalRepresentative}
      CGU={CGU}
      displayCertifyLegalRepresentative={displayCertifyLegalRepresentative()}
      email={email}
      firstName={firstName}
      GDPR={GDPR}
      handleChange={handleChange}
      handleEmailError={handleEmailError}
      handleSubmit={handleSubmit}
      lastName={lastName}
      loading={loading}
      message={result[1]}
      messageType={result[0] as 'none' | 'error' | 'success'}
      referrerCode={referrerCode}
      submitDisabled={isSubmitDisabled()}
      type={props.type}
    />
  ) : (
    <RegisterDisabled />
  );
}
