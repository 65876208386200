import * as React from 'react';
import {
  CircularProgress,
  Grid,
  Typography,
  Tooltip,
  InputAdornment,
  IconButton,
  TextField,
} from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export interface ChangeEmailProps {
  editMode?: boolean;
  email: string;
  error?: string;
  handleCancel: (...args: any[]) => any;
  handleChange: (...args: any[]) => any;
  handleConfirm: (...args: any[]) => any;
  handleEdit: (...args: any[]) => any;
  loading?: boolean;
}

export function ChangeEmail(props: ChangeEmailProps) {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={5} sx={{ paddingTop: '0 !important' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" style={{ marginTop: '2px' }}>
                {t('authentication:editProfile.email')}
              </Typography>
              <Tooltip
                title={t('authentication:editProfile.emailInfo')}
                placement="right"
              >
                <InfoIcon color="secondary" style={{ marginLeft: '8px' }} />
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={12} md={7} sx={{ paddingTop: '0 !important' }}>
            <TextField
              fullWidth
              variant="outlined"
              error={props.error !== ''}
              helperText={props.error}
              InputProps={{
                readOnly: !props.editMode,
                endAdornment: (
                  <InputAdornment position="end">
                    {props.loading ? (
                      <CircularProgress size={26} />
                    ) : props.editMode ? (
                      <>
                        <IconButton
                          onClick={props.handleConfirm}
                          disabled={props.error !== ''}
                          edge="end"
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton onClick={props.handleCancel} edge="end">
                          <CloseIcon />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton onClick={props.handleEdit} edge="end">
                        <EditIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              disabled={!props.editMode}
              margin="dense"
              size="small"
              value={props.email || ''}
              onChange={props.handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      {props.editMode && (
        <Grid item xs={12}>
          <Typography variant="body2">
            {t('authentication:editProfile.changeEmailInfo')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
