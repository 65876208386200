export default {
  buttons: {
    addPatient: 'Add patient',
    addRDV: 'Add appointment',
    newOrder: 'New order',
  },
  dataTable: {
    columns: {
      hour: 'Time',
    },
    title: 'Today Appointments',
  },
  stats: {
    cards: {
      affiliations: {
        nbCompleted: 'Completed',
        nbPending: 'Pending',
        nbSent: 'Sent',
        title: 'Affiliations',
      },
      consumption: {
        smsCredits: {
          subtitle: 'Or about %X% € incl. tax',
          title: 'SMS credits',
        },
        stripeBalance: {
          negativeText: 'The balance will be reduced from the next billing',
          positiveText: 'The balance will be added to the next billing',
          title: 'Remaining balance',
        },
        title: 'Consumption of your resources',
      },
      kpis: {
        nbManualRDVs: 'Manual appointments',
        nbOnlineRDVs: 'Online appointments',
        nbSubPro: 'Pro subs',
        nbSubSmsTotal: 'Sms subs',
        nbSubStudent: 'Student subs',
        nbSubTotal: 'Subs',
        nbTotalFiles: 'Total files',
        nbTotalRDVs: 'Total appointments',
        title: 'KPIs',
      },
      monthActivity: {
        appointmentsDonePercent: 'Sessions done',
        caForecast: 'Revenue forecast',
        nbAppointments: 'Number of sessions',
        nbNewPatients: 'New patients',
        title: "Your month's activity",
      },
      users: {
        nbAdmin: 'Admins',
        nbBeta: 'Betas',
        nbGeneratedPatients: 'Generated patients',
        nbPartner: 'Partners',
        nbPatients: 'Patients',
        nbPharmaco: 'Pharmaco',
        nbPractitioners: 'Practitioners',
        nbTotalNoGeneratedPatient: 'Total registrants',
        title: 'User typology',
      },
    },
    filters: 'Filters',
    graph: {
      bestOfUsers: {
        title: 'Most active users over the period',
      },
      loading: 'Loading the graph',
      nbOfUsers: {
        signins: 'Signins',
        signinsUnique: 'Unique signins',
        signups: 'Signups',
        title: 'Number of users',
      },
    },
  },
};
