/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import { Formula } from '../../../core/entities/Formula';
import { DatatablesController } from '@kiway/datatables';
import { useTranslation } from '@kiway/shared/utils/translation';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';

export interface ListFormulasProps {
  handleAddData?: () => void;
  handleClearAdd?: () => void;
  handleRowClick?: (rowIndex: number) => void;
  handleSave?: any;
  loading?: boolean;
  formulas: Formula[];
  refreshData?: () => void;
}

export function ListFormulas({
  handleAddData,
  handleClearAdd,
  handleRowClick,
  handleSave,
  loading,
  formulas,
  refreshData,
}: React.PropsWithChildren<ListFormulasProps>) {
  const { t, currentLanguage } = useTranslation();
  return (
    <DatatablesController
      bulkEdit={true}
      columns={[
        {
          name: '_id',
          label: 'ID',
          type: 'text',
          options: {
            display: false,
            edit: false,
            filter: false,
          },
        },
        {
          name: 'pinYinName',
          label: t(`pharmaco:formula.pinYinName`),
          type: 'text',
        },
        {
          name: `description.${currentLanguage?.code ?? getFallbackLanguage()}`,
          label: t(`pharmaco:formula.description`, {
            code: (
              currentLanguage?.code ?? getFallbackLanguage()
            )?.toUpperCase(),
          }),
          type: 'text',
        },
        {
          name: 'published',
          label: t(`pharmaco:formula.published`),
          type: 'boolean',
        },
      ]}
      data={formulas}
      defaultSortOrder={{ name: 'pinYinName', direction: 'asc' }}
      handleAddData={handleAddData}
      handleClearAdd={handleClearAdd}
      handleRowClick={handleRowClick}
      handleSaveData={handleSave}
      id={`listFormulas`}
      loading={loading}
      options={{
        download: false,
        filter: false,
        print: false,
      }}
      refreshData={refreshData}
      title={t(`pharmaco:formula.listTitle`)}
    />
  );
}
