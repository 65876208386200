import { CountryGraphQLProvider } from '../../dataproviders/graphql-client/CountryGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllCountries } from '../../core/use_cases/GetAllCountries';
import { Country, ICountryAttributes } from '../../core/entities/Country';
import { EditManyCountries } from '../../core/use_cases/EditManyCountries';

class CountriesGateway {
  protected static instance: CountriesGateway;
  protected getAllCountries: GetAllCountries;
  protected editManyCountries: EditManyCountries;

  public static getInstance(client: ApolloClient<any>): CountriesGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new CountriesGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const countryProvider = new CountryGraphQLProvider(client);
    this.getAllCountries = new GetAllCountries(countryProvider);
    this.editManyCountries = new EditManyCountries(countryProvider);
    this.findAll = this.findAll.bind(this);
    this.editCountries = this.editCountries.bind(this);
    CountriesGateway.instance = this;
  }

  async findAll(): Promise<Country[]> {
    return await this.getAllCountries.execute();
  }

  async editCountries(countries: ICountryAttributes[]): Promise<Country[]> {
    return await this.editManyCountries.execute(countries);
  }
}

export function useCountriesGateway() {
  const client = useApolloClient();
  const countriesGateway = CountriesGateway.getInstance(client);
  return {
    findAll: countriesGateway.findAll,
    editCountries: countriesGateway.editCountries,
  };
}
