import {
  flattenTranslatableAttributeToJson,
  KiwayLanguagesType,
  TranslatableAttribute,
} from '@kiway/shared/react-types';

export interface ICarrierAttributes {
  name: TranslatableAttribute;
  code: string;
  trackingLink: string;
}

export class Carrier {
  protected id: string;
  protected name: TranslatableAttribute;
  protected code: string;
  protected trackingLink: string;

  public constructor(
    obj: Partial<ICarrierAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    this.name = obj?.name;
    this.code = obj?.code;
    this.trackingLink = obj?.trackingLink;
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): Carrier {
    this.id = id;
    return this;
  }

  public getName(): TranslatableAttribute {
    return this.name;
  }

  public getCode(): string {
    return this.code;
  }

  public setCode(code: string): Carrier {
    this.code = code;
    return this;
  }

  public getTrackingLink(trackingNumber?: string): string {
    if (trackingNumber) {
      if (this.code === 'nacex') {
        const agency = trackingNumber.split('/')?.[0];
        const numero = trackingNumber.split('/')?.[1];
        return this.trackingLink
          ?.replace('{{trackingNumber0}}', agency)
          ?.replace('{{trackingNumber1}}', numero);
      } else {
        return (
          this.trackingLink?.replace('{{trackingNumber}}', trackingNumber) ??
          this.trackingLink
        );
      }
    }
    return this.trackingLink;
  }

  public setTrackingLink(trackingLink: string): Carrier {
    this.trackingLink = trackingLink;
    return this;
  }

  public isTrackingLinkValid(): boolean {
    return this.getTrackingLink()?.includes('{{trackingNumber}}');
  }

  static isTrackingLinkValid(trackingLink: string): boolean {
    return trackingLink?.includes('{{trackingNumber}}');
  }

  public toJSON(): any {
    return {
      id: this.getId(),
      name: this.getName(),
      code: this.getCode(),
      trackingLink: this.getTrackingLink(),
    };
  }

  public toDatatableRow(): any {
    return {
      _id: this.getId(),
      code: this.getCode(),
      trackingLink: this.getTrackingLink(),
      ...flattenTranslatableAttributeToJson(this.getName(), 'name'),
    };
  }

  public toDropdownValue(lng: KiwayLanguagesType): any {
    return {
      label: this.getName()?.[lng],
      value: this.getId(),
    };
  }
}
