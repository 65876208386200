import { ShippingMethodGraphQLProvider } from '../../dataproviders/graphql-client/ShippingMethodGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllShippingMethods } from '../../core/use_cases/GetAllShippingMethods';
import {
  ShippingMethod,
  IShippingMethodAttributes,
} from '../../core/entities/ShippingMethod';
import { EditManyShippingMethods } from '../../core/use_cases/EditManyShippingMethods';

class ShippingMethodsGateway {
  protected static instance: ShippingMethodsGateway;
  protected getAllShippingMethods: GetAllShippingMethods;
  protected editManyShippingMethods: EditManyShippingMethods;

  public static getInstance(client: ApolloClient<any>): ShippingMethodsGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new ShippingMethodsGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const shippingMethodProvider = new ShippingMethodGraphQLProvider(client);
    this.getAllShippingMethods = new GetAllShippingMethods(
      shippingMethodProvider
    );
    this.editManyShippingMethods = new EditManyShippingMethods(
      shippingMethodProvider
    );
    this.findAll = this.findAll.bind(this);
    this.findActive = this.findActive.bind(this);
    this.editShippingMethods = this.editShippingMethods.bind(this);
    ShippingMethodsGateway.instance = this;
  }

  async findAll(find?: any): Promise<ShippingMethod[]> {
    return await this.getAllShippingMethods.execute(find);
  }

  async findActive(find?: any): Promise<ShippingMethod[]> {
    return await this.findAll({
      active: true,
      ...find,
    });
  }
  async editShippingMethods(
    shippingMethods: IShippingMethodAttributes[]
  ): Promise<ShippingMethod[]> {
    return await this.editManyShippingMethods.execute(shippingMethods);
  }
}

export function useShippingMethodsGateway() {
  const client = useApolloClient();
  const shippingMethodsGateway = ShippingMethodsGateway.getInstance(client);
  return {
    findAll: shippingMethodsGateway.findAll,
    findActive: shippingMethodsGateway.findActive,
    editShippingMethods: shippingMethodsGateway.editShippingMethods,
  };
}
