// import * as React from 'react';
import { FeatureNotifier } from '@kiway/shared/features/notifier-ui';
import { FeaturePharmacoClient } from '@kiway/shared/features/pharmaco-react';
import { FeatureAuthentication } from '@kiway/shared/features/authentication-react';
import { FeatureStorage } from '@kiway/shared/features/storage-react';
import { FeatureLoyaltyProgram } from '@kiway/shared/loyalty-program-react';

export default [
  FeatureNotifier,
  FeaturePharmacoClient,
  FeatureAuthentication,
  FeatureStorage,
  FeatureLoyaltyProgram,
];
