import * as React from 'react';
import { Typography } from '@material-ui/core';
import { User } from '../../../core/entities/User';
import { Skeleton } from '@material-ui/lab';

export interface ListUsersProps {
  users: User[];
  loading?: boolean;
}

const renderUser = (item: User) => <li key={item.getId()}>{item.getId()}</li>;

const loadingUserScreen = (
  <div>
    <ul>
      <li>
        <Skeleton variant="text" />
      </li>
      <li>
        <Skeleton variant="text" />
      </li>
      <li>
        <Skeleton variant="text" />
      </li>
    </ul>
  </div>
);

export function ListUsers({
  loading,
  users,
}: React.PropsWithChildren<ListUsersProps>) {
  return (
    <div>
      <Typography variant="h5">User :</Typography>
      {loading ? (
        loadingUserScreen
      ) : (
        <div>
          <ul>{users?.length ? users?.map(renderUser) : 'Aucune donnée'}</ul>
        </div>
      )}
    </div>
  );
}
