export const accessControlOptions = {
  roleHierarchy: [
    {
      role: 'admin',
      extends: [
        'admin',
        'orderPicker',
        'beta',
        'partner',
        'practitioner',
        'pharmaco',
        'patient',
        'noAuth',
      ],
    },
    {
      role: 'wholesalerAdmin',
      extends: ['wholesalerAdmin', 'orderPicker', 'noAuth'],
    },
    {
      role: 'orderPicker',
      extends: ['orderPicker', 'noAuth'],
    },
    {
      role: 'partner',
      extends: ['partner', 'practitioner', 'patient', 'noAuth'],
    },
    {
      role: 'beta',
      extends: ['beta', 'practitioner', 'patient', 'noAuth'],
    },
    {
      role: 'pharmaco',
      extends: ['pharmaco', 'practitioner', 'patient', 'noAuth'],
    },
    {
      role: 'practitioner',
      extends: ['practitioner', 'patient', 'noAuth'],
    },
    {
      role: 'patient',
      extends: ['patient', 'noAuth'],
    },
    {
      role: 'noAuth',
      extends: ['noAuth'],
    },
  ],
  publicResources: [],
  privateResources: [
    {
      name: 'upgrade',
      rights: [
        {
          role: 'admin',
          actions: ['update'],
        },
      ],
    },
    {
      name: 'notification',
      rights: [
        {
          role: 'admin',
          actions: ['read', 'create', 'update', 'delete', 'sendBatch'],
        },
        {
          role: 'practitioner',
          actions: ['read', 'update'],
        },
      ],
    },
    {
      name: 'onlinePayment',
      rights: [
        {
          role: 'practitioner',
          actions: ['createAccount', 'payWithCustomMethod'],
        },
      ],
    },
    {
      name: 'release',
      rights: [
        {
          role: 'admin',
          actions: ['read', 'create', 'update', 'delete'],
        },
        {
          role: 'practitioner',
          actions: ['read'],
        },
      ],
    },
    {
      name: 'specialty',
      rights: [
        {
          role: 'admin',
          actions: ['read', 'create', 'update', 'delete'],
        },
        {
          role: 'patient',
          actions: ['read'],
        },
      ],
    },
    {
      name: 'users',
      rights: [
        {
          role: 'admin',
          actions: ['create', 'update', 'list', 'readOwn'],
        },
        {
          role: 'patient',
          actions: ['readOwn'],
        },
      ],
    },
    {
      name: 'specialties',
      rights: [
        {
          role: 'practitioner',
          actions: ['read', 'update'],
        },
      ],
    },
    {
      name: 'patientFile',
      rights: [
        {
          role: 'practitioner',
          actions: ['read', 'create', 'update', 'delete', 'link'],
        },
      ],
    },
    {
      name: 'appointment',
      rights: [
        {
          role: 'practitioner',
          actions: ['read', 'create', 'update', 'delete'],
        },
      ],
    },
    {
      name: 'settingsAppointment',
      rights: [
        {
          role: 'practitioner',
          actions: ['read', 'update'],
        },
      ],
    },
    {
      name: 'minisiteSettings',
      rights: [
        {
          role: 'practitioner',
          actions: ['read', 'update'],
        },
      ],
    },
    {
      name: 'weekTemplate',
      rights: [
        {
          role: 'practitioner',
          actions: ['read', 'create', 'update', 'delete'],
        },
      ],
    },
    {
      name: 'weekTemplateSlot',
      rights: [
        {
          role: 'practitioner',
          actions: ['read', 'create', 'update', 'delete'],
        },
      ],
    },
    {
      name: 'dashboardPractitionerStats',
      rights: [
        {
          role: 'practitioner',
          actions: ['read'],
        },
      ],
    },
    {
      name: 'affiliation',
      rights: [
        {
          role: 'practitioner',
          actions: ['read', 'create'],
        },
      ],
    },
    {
      name: 'consultationPlace',
      rights: [
        {
          role: 'practitioner',
          actions: ['read', 'create', 'update', 'delete'],
        },
        {
          role: 'noAuth',
          actions: ['read'],
        },
      ],
    },
    {
      name: 'publicPagePictures',
      rights: [
        {
          role: 'practitioner',
          actions: ['read', 'create', 'update', 'delete'],
        },
        {
          role: 'noAuth',
          actions: ['read'],
        },
      ],
    },
    {
      name: 'chiefComplaint',
      rights: [
        {
          role: 'practitioner',
          actions: ['read', 'create', 'update', 'delete'],
        },
        {
          role: 'noAuth',
          actions: ['read'],
        },
      ],
    },
    {
      name: 'profile',
      rights: [
        {
          role: 'patient',
          actions: ['read', 'update'],
        },
      ],
    },
    {
      name: 'profilePicture',
      rights: [
        {
          role: 'patient',
          actions: ['read', 'create', 'delete'],
        },
      ],
    },
    {
      name: 'patientAppointments',
      rights: [
        {
          role: 'patient',
          actions: ['read', 'create', 'update'],
        },
        {
          role: 'noAuth',
          actions: ['create', 'update'],
        },
      ],
    },
    {
      name: 'slots',
      rights: [
        {
          role: 'noAuth',
          actions: ['read'],
        },
      ],
    },
    {
      name: 'sendSMS',
      rights: [
        {
          role: 'practitioner',
        },
      ],
      service: 'sms',
    },
    {
      name: 'serviceOnDemand',
      rights: [
        {
          role: 'admin',
          actions: [
            'read',
            'readAll',
            'readPublic',
            'readUser',
            'create',
            'update',
            'togglePublic',
            'editList',
          ],
        },
        {
          role: 'practitioner',
          actions: ['read', 'readPublic', 'editList'],
        },
      ],
    },
    {
      name: 'paymentSource',
      rights: [
        {
          role: 'practitioner',
          actions: ['read', 'create', 'default', 'delete'],
        },
      ],
    },
    {
      name: 'subscriptionInvoices',
      rights: [
        {
          role: 'practitioner',
          actions: ['read'],
        },
      ],
    },
    {
      name: 'invoicingSettings',
      rights: [
        {
          role: 'practitioner',
          actions: ['read', 'update'],
        },
      ],
    },
    {
      name: 'invoicing',
      rights: [
        {
          role: 'practitioner',
          actions: ['read', 'create', 'update'],
        },
        {
          role: 'patient',
          actions: ['read'],
        },
      ],
    },
  ],
};
