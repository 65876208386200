import { Model, Schema, UpdateQuery } from 'mongoose';
import * as mongoose from 'mongoose';
import { getSearchMatch, Searchable, setSearchIndex } from './shared-types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const mongooseUniqueValidator = require('mongoose-unique-validator');
import paginate from 'mongoose-paginate-v2';
import aggregatePaginate from 'mongoose-aggregate-paginate-v2';

export type CustomOptions = {
  aggregate?: any[];
  populate?: any;
  find?: any;
  sort?: any;
};

export type PaginationOptions = {
  customLabels?: any;
  enabled?: boolean;
  page?: number;
  perPage?: number;
};

export type PaginatedResults<Entity> = {
  items: Entity[];
  currentPage: number;
  totalItems: number;
  totalPages: number;
  prevPage: number;
  nextPage: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
};

export function DatabaseProviderFactory<
  Entity extends { getId: () => string; setId: (id: string) => Entity },
  IEntity extends { save: () => Promise<IEntity>; _id?: any },
  IEntityModel extends Model<IEntity>,
  IEntityAttributes
>(globalPopulate?: any): any {
  abstract class DatabaseProvider {
    protected model: IEntityModel;

    protected static instance: DatabaseProvider;

    public static getInstance(): DatabaseProvider {
      throw new Error('Method not implemented.');
    }

    protected constructor(
      EntitySchema: Schema,
      entityName: string,
      collectionName: string
    ) {
      EntitySchema.plugin(mongooseUniqueValidator);
      EntitySchema.plugin(paginate);
      EntitySchema.plugin(aggregatePaginate);
      this.model = mongoose.model<IEntity, IEntityModel>(
        entityName,
        EntitySchema,
        collectionName
      );
      DatabaseProvider.instance = this;
    }

    async save(entity: Entity, userId?: string): Promise<Entity> {
      return await this.createOrUpdate(entity, userId);
    }

    async find(
      options?: CustomOptions,
      pagination?: PaginationOptions
    ): Promise<Entity[] | PaginatedResults<Entity>> {
      let entities: any;
      const isPaginated: boolean = pagination?.enabled;
      if (options?.aggregate) {
        entities = await this.model.aggregate(options?.aggregate);
      } else {
        if (isPaginated) {
          entities = await (this.model as any).paginate(options.find, {
            customLabels: {
              docs: 'items',
              limit: 'perPage',
              page: 'currentPage',
              totalDocs: 'totalItems',
            },
            page: pagination.page,
            limit:
              pagination.perPage === -1 ? 1000000000 : pagination.perPage ?? 10,
            sort: options?.sort,
          });
        } else {
          entities = await this.model.find(options?.find || {});
        }
      }
      if (options?.populate) {
        await this.model.populate(
          isPaginated ? entities.items : entities,
          options?.populate
        );
      } else if (globalPopulate) {
        await this.model.populate(
          isPaginated ? entities.items : entities,
          globalPopulate
        );
      }
      if (isPaginated) {
        return {
          ...entities,
          items: entities?.items?.map(this.toEntity),
        };
      } else if (Array.isArray(entities)) {
        return entities.map(this.toEntity);
      }
      return entities;
    }

    async findOne(options?: CustomOptions): Promise<Entity> {
      let entity: IEntity = null;
      if (options?.aggregate) {
        entity = (await this.model.aggregate(options?.aggregate))?.[0];
      } else {
        entity = await this.model
          .findOne(options?.find || {})
          ?.sort(options?.sort);
      }
      if (options?.populate) {
        await this.model.populate(entity, options?.populate);
      } else if (globalPopulate) {
        await this.model.populate(entity, globalPopulate);
      }
      return this.toEntity(entity);
    }

    async findAll(
      options?: CustomOptions,
      pagination?: PaginationOptions
    ): Promise<Entity[] | PaginatedResults<Entity>> {
      if (options?.aggregate) {
        return this.find(options, pagination);
      } else {
        return this.find({ ...options, find: options?.find || {} }, pagination);
      }
    }

    async editMany(
      entities: IEntityAttributes[],
      userId?: string
    ): Promise<Entity[]> {
      return await Promise.all(
        entities
          .map((item) => this.toEntity((item as unknown) as IEntity))
          .map((item) => this.createOrUpdate(item, userId))
      );
    }

    async createOrUpdate(entity: Entity, userId?: string): Promise<Entity> {
      const entityMongo = await this.toEntityMongo(
        entity,
        entity.getId() ? null : userId,
        userId
      );
      const updatedEntity: IEntity = await this.model.findOneAndUpdate(
        { _id: entityMongo._id },
        entityMongo,
        {
          upsert: true,
          new: true,
        }
      );
      await this.model.populate(updatedEntity, globalPopulate || []);

      return this.toEntity(updatedEntity);
    }

    async deleteOneById(_id: string): Promise<boolean> {
      const result = await (this.model as any).deleteOne({ _id });
      return result.deletedCount > 0;
    }

    protected abstract toEntity(entityMongo: IEntity): Entity;

    protected abstract toEntityMongo(
      entity: Entity,
      createdBy?: string,
      updatedBy?: string
    ): IEntity & UpdateQuery<IEntity>;
  }
  return DatabaseProvider;
}

export function SearchableDatabaseProviderFactory<
  Entity extends { getId: () => string; setId: (id: string) => Entity },
  IEntity extends { save: () => Promise<IEntity>; _id?: any },
  IEntityModel extends Model<IEntity>,
  IEntityAttributes
>(searchableAttributes: string[], globalPopulate?: any): any {
  /** Define the Searchable class for DatabaseProviders */
  abstract class SearchableDatabaseProvider
    extends DatabaseProviderFactory<
      Entity,
      IEntity,
      IEntityModel,
      IEntityAttributes
    >(globalPopulate)
    implements Searchable<Entity> {
    protected constructor(
      EntitySchema: Schema,
      entityName: string,
      collectionName: string
    ) {
      setSearchIndex(EntitySchema, searchableAttributes);
      super(EntitySchema, entityName, collectionName);
    }
    async search(search: string, options?: CustomOptions): Promise<Entity[]> {
      const fullEntities: IEntity[] = await this.model.aggregate([
        {
          $match: getSearchMatch(searchableAttributes, search, true),
        },
        ...(options?.aggregate || []),
      ]);
      const partialEntities: IEntity[] = await this.model.aggregate([
        {
          $match: {
            _id: {
              $nin: fullEntities.map((entity: any) => entity._id),
            },
          },
        },
        {
          $match: getSearchMatch(searchableAttributes, search, false),
        },
        ...(options?.aggregate || []),
      ]);
      const entities: IEntity[] = [...fullEntities, ...partialEntities];
      if (options?.populate) {
        await this.model.populate(entities, options?.populate);
      }
      return entities.map(this.toEntity);
    }
  }
  return SearchableDatabaseProvider;
}
