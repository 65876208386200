import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField/TextField'
import Alert from '@material-ui/lab/Alert/Alert'
import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import './MarkdownTextArea.css'

export const doubleLineBreak = (value) => {
  if (!value) {
    return ''
  }
  if (value === '') {
    return value
  }
  return [...value].map((char) => (char === '\n' ? `${char}\n` : char)).join('')
}

function MarkdownTextArea(props) {
  const {
    label,
    handleChange,
    preview,
    previewLabel,
    previewSide,
    value,
  } = props

  const previewJsx = (
    <>
      <Alert severity="info">{previewLabel}</Alert>
      <div style={{ height: '140px', overflowY: 'scroll' }}>
        <ReactMarkdown
          className="markdownPreview"
          children={doubleLineBreak(value)}
          allowedElements={['p', 'ul', 'li', 'strong']}
        />
      </div>
    </>
  )

  const textAreaJsx = (
    <TextField
      label={label}
      multiline
      rows={6}
      fullWidth
      value={value}
      onChange={handleChange}
      variant="outlined"
    />
  )

  return preview ? (
    previewSide === 'left' ? (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {previewJsx}
        </Grid>
        <Grid item xs={12} sm={6}>
          {textAreaJsx}
        </Grid>
      </Grid>
    ) : (
      // preview on right side
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {textAreaJsx}
        </Grid>
        <Grid item xs={12} sm={6}>
          {previewJsx}
        </Grid>
      </Grid>
    ) // no preview
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        textAreaJsx
      </Grid>
    </Grid>
  )
}

MarkdownTextArea.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  preview: PropTypes.bool,
  previewLabel: PropTypes.string,
  previewSide: PropTypes.oneOf(['left', 'right']),
  value: PropTypes.string.isRequired,
}

MarkdownTextArea.defaultProps = {
  previewSide: 'left',
  previewLabel: 'Preview :',
}

export default MarkdownTextArea
