import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from '@kiway/shared/utils/snackbar';
import { useTranslation } from '@kiway/shared/utils/translation';
import { useSignInForm } from '../../shared/Forms/SignIn';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  TextField,
} from '@material-ui/core';
import { CustomButton } from '../../shared/Buttons';
import { BookingContext } from '../../../BookingContext';
import { AuthContext } from '@kiway/shared/features/authentication-react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import { useSignUpForm } from '../../shared/Forms/SignUp';
import { Alert } from '@material-ui/lab';
import { useMutation } from '@apollo/client';
import { PUBLIC_PAGE_UPDATE_APPOINTMENT } from '../../graphql/appointment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    boxSizing: 'border-box',
  },
  container: {
    padding: '15px',
    boxSizing: 'border-box',
  },
  card: {
    // minWidth: '275px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#eff1ee',
  },
  cardHeader: {
    paddingBottom: '0',
  },
  avatar: {
    width: '50px',
    height: '50px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  comment: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const SIGN_IN_IDENTITY_TYPE = 'signIn';
const SIGN_UP_IDENTITY_TYPE = 'signUp';

/**
 * This component is implemented when a public user (patient)
 * is booking an appointment with a practitionner through his
 * {@link PublicPage} component.
 *
 * The component is mounted when a user is clicking on one of the
 * Slots displayed by {@link SlotPickerColumn}.
 *
 * @param {*} props
 * @returns
 */
function OnlineBookingStepper(props) {
  const { history } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(1);
  const [identityType, setIdentityType] = useState(SIGN_IN_IDENTITY_TYPE);
  const { addSuccessSnackbar, addErrorSnackbar } = useSnackbar();

  const { state, dispatch } = useContext(BookingContext);

  const { authState } = useContext(AuthContext);

  const [SignInComponent, signinState] = useSignInForm({
    redirectURI: '',
    type: '',
  }); //  type has to be empty string or 'green'

  const [SignUpComponent, signupState] = useSignUpForm({
    proOrPatient: 'patient',
    submitColor: 'primary',
    // type: 'green',
    // customProps: {
    //   style: {
    //     backgroundColor: 'green',
    //   }
    // }
  });

  const [updateAppointment] = useMutation(PUBLIC_PAGE_UPDATE_APPOINTMENT, {
    onError: (error) => {
      if (error.graphQLErrors[0].message === 'overlap') {
        addErrorSnackbar(t('agenda:snackbars.appointments.overlap'));
      } else if (error.graphQLErrors[0].message === 'missingFields') {
        addErrorSnackbar(t('agenda:snackbars.appointments.missingFields'));
      } else {
        addErrorSnackbar(t('onlineBooking:snackbars.error'));
      }
    },
    update: (store, response) => {
      addSuccessSnackbar(t('onlineBooking:snackbars.success'));
      history.push('/');
    },
  });

  useEffect(() => {
    const user = authState.userInfo;
    if (user && user._id) {
      dispatch({
        type: 'PATIENT_LOGIN',
        payload: {
          id: user.patientId,
          fullname: `${user.firstName} ${user.lastName}`,
        },
      });
      setActiveStep(2);
    }
  }, [signinState.loginComplete, signupState.signUpComplete]);

  function handleBookAppointment() {
    const appointmentToSend = {
      id: state.appointment.id,
      comment: state.appointment.comment,
      patient: state.patient.id,
      creator: state.patient.id,
      owner: state.practitioner.id,
      startDate: state.appointment.startDate,
      endDate: state.appointment.endDate,
      chiefComplaint: state.chiefComplaint.id,
      consultationPlace: state.consultationPlace.id,
      status: 'CONFIRMED',
      eventType: 'SESSION',
      sms: {
        msg_status: 'pending',
      },
    };

    updateAppointment({ variables: { data: appointmentToSend } });
  }

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
      >
        <Step key={0}>
          <StepLabel>{t('onlineBooking:booking.chiefComplaint')}</StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel>
            {t('onlineBooking:stepper.identification.title')}
          </StepLabel>
        </Step>
        <Step key={2}>
          <StepLabel>{t('onlineBooking:stepper.confirmation.title')}</StepLabel>
        </Step>
      </Stepper>
      <Grid container justifyContent="center">
        {/**********************
         *** CONNECTION STEP ***
         ***********************/}
        {activeStep === 1 && (
          <Grid
            container
            className={classes.container}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              style={{ marginTop: '30px' }}
            >
              <Alert severity="info">{t('onlineBooking:info.reserved')}</Alert>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              style={{ marginTop: '30px' }}
            >
              <Paper className={classes.paper} elevation={4}>
                <Typography variant="subtitle1">
                  {t('onlineBooking:stepper.identification.login')}
                </Typography>
                <Grid container direction="column">
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={12} sm={8} md={6} lg={6}>
                      {identityType === SIGN_IN_IDENTITY_TYPE &&
                        SignInComponent}
                    </Grid>
                  </Grid>
                  {identityType !== SIGN_IN_IDENTITY_TYPE && (
                    <Grid container direction="row" justifyContent="center">
                      <Grid item xs={12} sm={8} md={6} lg={6}>
                        <CustomButton
                          color="minoritary"
                          style={{ width: '100%' }}
                          onClick={() => setIdentityType(SIGN_IN_IDENTITY_TYPE)}
                        >
                          {t('user:form.signInButton')}
                        </CustomButton>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              style={{ marginTop: '30px' }}
            >
              <Paper className={classes.paper} elevation={4}>
                <Typography variant="subtitle1">
                  {t('onlineBooking:stepper.identification.signup', {
                    app: process.env.NX_APPBAR_NAME,
                  })}
                </Typography>
                <Grid container direction="column">
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={12} sm={8} md={6} lg={6}>
                      {identityType === SIGN_UP_IDENTITY_TYPE &&
                        SignUpComponent}
                    </Grid>
                  </Grid>
                  {identityType !== SIGN_UP_IDENTITY_TYPE && (
                    <Grid container direction="row" justifyContent="center">
                      <Grid item xs={12} sm={8} md={6} lg={6}>
                        <CustomButton
                          color="primary"
                          style={{ width: '100%' }}
                          onClick={() => setIdentityType(SIGN_UP_IDENTITY_TYPE)}
                        >
                          {t('user:form.signUpButton')}
                        </CustomButton>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
        {/************************
         *** CONFIRMATION STEP ***
         *************************/}
        {activeStep === 2 && (
          <Grid
            container
            className={classes.container}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              style={{ marginTop: '30px' }}
            >
              <Alert severity="info">{t('onlineBooking:info.reserved')}</Alert>
              {state.sanitaryInfo?.display &&
                state.sanitaryInfo?.content &&
                state.sanitaryInfo?.content.length && (
                  <Alert severity="warning" style={{ marginTop: '8px' }}>
                    {state.sanitaryInfo.content}
                  </Alert>
                )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              style={{ marginTop: '30px' }}
            >
              <Paper className={classes.paper} elevation={4}>
                <Typography variant="h5" className={classes.title}>
                  {`${t('onlineBooking:stepper.confirmation.text')} ${moment(
                    state.appointment.startDate
                  ).format(
                    t('onlineBooking:stepper.confirmation.dateFormat')
                  )}`}
                </Typography>
                <Grid container direction="column">
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                      {/** PRACTITIONER CARD */}
                      <Card className={classes.card}>
                        <CardHeader
                          className={classes.cardHeader}
                          avatar={
                            <Avatar
                              aria-label="practitioner"
                              className={classes.avatar}
                              src={state.practitioner.pic}
                            ></Avatar>
                          }
                          title={state.practitioner.fullname}
                        />
                        <CardContent>
                          <Typography variant="body1">
                            {state.consultationPlace.name}
                          </Typography>
                          <Typography variant="body1">
                            {state.consultationPlace.address}
                          </Typography>
                          <Typography variant="body1">
                            {`${state.consultationPlace.zipCode} ${state.consultationPlace.city}`}
                          </Typography>
                          {state.consultationPlace.complement && (
                            <Typography variant="subtitle2">
                              {state.consultationPlace.complement}
                            </Typography>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container direction="column">
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                      {/** CHIEF COMPLAINT CARD */}
                      <Card className={classes.card}>
                        <CardContent>
                          <Typography variant="body1">
                            {state.chiefComplaint.specialty}
                          </Typography>
                          <Typography variant="subtitle2">
                            {state.chiefComplaint.label}
                          </Typography>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <span
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <EuroSymbolIcon fontSize={'small'} />
                              <span>{state.chiefComplaint.price}</span>
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginLeft: '12px',
                              }}
                            >
                              <AccessTimeIcon fontSize={'small'} />
                              <span>{state.chiefComplaint.duration}</span>
                            </span>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction="column">
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                      <TextField
                        className={classes.comment}
                        rows={3}
                        value={state.appointment.comment}
                        onChange={(event) =>
                          dispatch({
                            type: 'SET_COMMENT',
                            payload: { comment: event.target.value || '' },
                          })
                        }
                        id="comment"
                        variant="outlined"
                        placeholder={t(
                          'onlineBooking:stepper.confirmation.comment'
                        )}
                        multiline
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction="column">
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                      <CustomButton
                        className={classes.button}
                        fullWidth
                        color="minoritary"
                        onClick={handleBookAppointment}
                      >
                        {t('onlineBooking:stepper.confirmation.button')}
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

OnlineBookingStepper.propTypes = {
  // slot: PropTypes.object.isRequired,
  // duration: PropTypes.number.isRequired,
  // chiefComplaint: PropTypes.object.isRequired,
  // consultationPlace: PropTypes.object.isRequired,
};

export default withRouter(OnlineBookingStepper);
