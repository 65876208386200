import React from 'react';
import { Grid, Typography } from '@mui/material';
import { TourSection } from './TourSection';
import { useTranslation } from '@kiway/shared/utils/translation';
import { tourSections } from './tourSections';
import { useReactTourContext } from './react-tour-context';
import { useTour } from '@reactour/tour';
import { ReactEventEmitter } from '@kiway/react-event-emitter';
import { useHistory } from 'react-router';

export function TourGuide() {
  const { t } = useTranslation();
  const { setIsNextButtonEnabled } = useReactTourContext();
  const { setCurrentStep } = useTour();
  const history = useHistory();

  const params = {
    setIsNextButtonEnabled,
    t,
    setCurrentStep,
    ReactEventEmitter,
    history,
  };

  return (
    <Grid container spacing={1} justifyContent="center">
      <Typography variant="h4">{t('common:tourGuide.title')}</Typography>
      {tourSections(params).map((section) => {
        return <TourSection key={section.name} section={section} />;
      })}
    </Grid>
  );
}
