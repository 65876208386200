export default {
  appointment:
    'Se realizó una nueva reunión en línea. Haga clic en la notificación para consultarlo.',
  none: 'No hay una nueva notificación',
  release: 'La versión **{{version}}** ahora está disponible',
  stripeConnect: {
    created:
      '¡Su cuenta de rayas ha sido conectada! Ahora debe validarlo para usar el pago en línea. Debe haber recibido un correo electrónico de rayas, solo tiene que seguir las instrucciones.',
    validated:
      '¡Felicitaciones, su cuenta de Stripe ha sido validada! Ahora puede usar el pago en línea.',
  },
};
