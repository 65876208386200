import React, { useEffect, useState } from 'react'
import GreenPageWithAppVersion from './GreenPageWithAppVersion'
import { GET_CHECKOUT_SESSION_ID } from '../graphql/payment'
import { loadStripe } from '@stripe/stripe-js'
import client from '../graphql/client'
import CustomRoute from './CustomRoute'
import { Switch, withRouter } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { useTranslation } from '@kiway/shared/utils/translation'
import CustomButton from './Buttons/CustomButton'

function PaymentIntentRedirect({ paymentIntentId, paymentIntentType }) {
  const [error, setError] = useState('')
  const { t } = useTranslation()
  useEffect(() => {
    client
      .query({
        query: GET_CHECKOUT_SESSION_ID,
        variables: {
          paymentIntentId,
          paymentIntentType,
        },
      })
      .then(async ({ data }) => {
        if (
          data?.getCheckoutSessionId &&
          data?.getCheckoutSessionId?.sessionId &&
          data?.getCheckoutSessionId?.stripeAccountId &&
          data?.getCheckoutSessionId?.sessionId !== 'bad_status' &&
          data?.getCheckoutSessionId?.sessionId !== 'no_connected_account'
        ) {
          const stripe = await loadStripe(
            process.env['NX_STRIPE_PUBLIC_KEY'],
            {
              stripeAccount: data?.getCheckoutSessionId?.stripeAccountId,
            },
          )
          stripe.redirectToCheckout({
            sessionId: data?.getCheckoutSessionId?.sessionId,
          })
        } else {
          if (data?.getCheckoutSessionId?.stripeAccountId) {
            setError(
              t('invoicing:payment.checkout.redirectPage.noConnectedAccount'),
            )
          } else {
            switch (data?.getCheckoutSessionId?.sessionId) {
              case 'bad_status':
                setError(t('invoicing:payment.checkout.redirectPage.badStatus'))
                break
              case 'no_connected_account':
                setError(
                  t(
                    // eslint-disable-next-line max-len
                    'invoicing:payment.checkout.redirectPage.noConnectedAccount',
                  ),
                )
                break
              default:
                setError(
                  t('invoicing:payment.checkout.redirectPage.unknownError'),
                )
                break
            }
          }
        }
      })
  }, [])
  return (
    <GreenPageWithAppVersion
      title={t('invoicing:payment.checkout.redirectPage.title')}
    >
      <Typography
        variant="subtitle1"
        style={{ color: 'white', marginTop: '16px' }}
      >
        {error !== ''
          ? error
          : t('invoicing:payment.checkout.redirectPage.redirecting')}
      </Typography>
    </GreenPageWithAppVersion>
  )
}

function PaymentIntentSuccess() {
  const { t } = useTranslation()
  return (
    <GreenPageWithAppVersion
      title={t('invoicing:payment.checkout.success.title')}
    >
      <Typography
        variant="subtitle1"
        style={{ color: 'white', marginTop: '16px' }}
      >
        {t('invoicing:payment.checkout.success.text')}
      </Typography>
      <CustomButton style={{ marginTop: '16px' }} color="minoritary" href="/">
        {t('user:form.backToHome')}
      </CustomButton>
    </GreenPageWithAppVersion>
  )
}

function PaymentIntentCancel() {
  const { t } = useTranslation()
  return (
    <GreenPageWithAppVersion
      title={t('invoicing:payment.checkout.cancel.title')}
    >
      <Typography
        variant="subtitle1"
        style={{ color: 'white', marginTop: '16px' }}
      >
        {t('invoicing:payment.checkout.cancel.text')}
      </Typography>
      <CustomButton style={{ marginTop: '16px' }} color="minoritary" href="/">
        {t('user:form.backToHome')}
      </CustomButton>
    </GreenPageWithAppVersion>
  )
}

function PaymentIntentHandler({ match, ...props }) {
  return (
    <Switch>
      <CustomRoute
        exact
        path={`${match.url}/success`}
        render={() => <PaymentIntentSuccess />}
      />
      <CustomRoute
        exact
        path={`${match.url}/cancel`}
        render={() => <PaymentIntentCancel />}
      />
      <CustomRoute
        exact
        path={match.url}
        render={() => <PaymentIntentRedirect {...props} />}
      />
    </Switch>
  )
}

export default withRouter(PaymentIntentHandler)
