export default {
  appBar: {
    prefix: 'Sesión de',
  },
  diagnosis: {
    title: 'Balance de energía',
  },
  followUp: {
    comment: 'Observación',
    date: 'Fecha',
    rating: 'Valoración',
    title: 'Monitoreo del tratamiento',
  },
  fourMethods: {
    title: 'Cuatro veces del diagnóstico',
  },
  info: {
    chiefComplaint: 'Razón de consulta',
    comment: 'Observación',
    date: 'Fecha',
    title: 'Información general',
  },
  patientNote: {
    title: 'Notas del paciente',
  },
  treatment: {
    title: 'Tratamiento',
  },
};
