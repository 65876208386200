/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react';
import { Alert, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';
import { Order, Country } from '@kiway/ecommerce-react';
import { AddressFormController } from '@kiway/shared/ui';

interface VerificationProps {
  availableCountries: Country[];
  order: Order;
  handleChangeShippingAddress: (address: any) => void;
  handleSaveShippingAddress: () => void;
}

export const Verification = (props: VerificationProps) => {
  const { currentLanguage, t } = useTranslation();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h5">
          {t('pharmaco:orderDetails.order.deliveryAddress')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info">
          {t('pharmaco:checkout.confirmAddressMessage')}
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <AddressFormController
          address={props.order?.getShippingAddress()?.toJSON() as any}
          availableCountries={props.availableCountries?.map((country) =>
            country.toCountryOption(
              currentLanguage?.code ?? getFallbackLanguage()
            )
          )}
          setAddress={props.handleChangeShippingAddress}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          color="minoritary"
          variant="contained"
          onClick={props.handleSaveShippingAddress}
          disabled={!props.order?.getShippingAddress()?.validateAddress()}
        >
          {t('pharmaco:checkout.confirmAddress')}
        </Button>
      </Grid>
    </Grid>
  );
};
