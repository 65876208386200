export default {
  dateTimePicker: {
    buttons: {
      cancel: {
        text: 'Anular',
      },
      clear: {
        text: 'Apagar',
      },
      ok: {
        text: 'Ok',
      },
      today: {
        text: 'Hoje',
      },
    },
  },
  format: {
    agendaSettingsHour: 'HH:mm:ss',
    date: 'DD/MM/YYYY',
    datePlaceholder: 'JJ/MM/AAAA',
    dayMonthYearHourMinute: 'DD/MM/YYYY HH:mm',
    hour: 'HH:mm',
    hourOnly: 'HH[h]',
    hourTxt: 'HH[h]mm',
    invalid: 'Data inválida',
    isoDate: 'YYYY-MM-DD',
    isoHour: 'HH:mm:ssSSSZ',
    literalComplete: 'Do MMMM YYYY [à] HH[h]mm',
    literalDate: 'Do MMMM YYYY',
    shortDate: 'D MMM YYYY',
    shortDay: 'ddd',
    shortDayMonth: 'D MMM',
    shortYearDate: 'DD/MM/YY',
    smsSentDate: 'DD/MM/YYYY [à] HH:mm',
  },
  hourFormat: '24',
  moment: 'pt',
  month: 'mês',
  strings: {
    currentMonth: 'Este mês',
    currentWeek: 'Esta semana',
    previousMonth: 'Mês anterior',
    previousWeek: 'Semana anterior',
  },
};
