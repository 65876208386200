import * as React from 'react';
import { PrimaryPageWithAppVersion, TextDivider } from '@kiway/shared/ui';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Link } from 'react-router-dom';
import { Grid, Button } from '@mui/material';

export interface RegisterChoiceProps {
  handleEventGA: (name: string) => () => void;
  handleReturnHome: () => void;
  matchUrl: string;
}

export function RegisterChoice(props: RegisterChoiceProps) {
  const { t } = useTranslation();

  return (
    <PrimaryPageWithAppVersion
      maxWidth={'xs'}
      title={t('authentication:register.title', {
        app: process.env.NX_APPBAR_NAME,
      })}
    >
      <Grid
        container
        spacing={3}
        style={{ boxSizing: 'border-box', marginTop: '16px' }}
      >
        <Grid item xs={12}>
          <Button
            color="minoritary"
            variant="contained"
            fullWidth
            component={Link}
            to={props.matchUrl + '/patient'}
            onClick={props.handleEventGA('patient')}
          >
            {t('user:form.createPatient')}
          </Button>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <TextDivider
            title={t('common:or').toUpperCase()}
            color="white"
            textColor="white"
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <Button
            color="minoritary"
            variant="contained"
            fullWidth
            component={Link}
            to={props.matchUrl + '/practitioner'}
            onClick={props.handleEventGA('practitioner')}
          >
            {t('user:form.createPractitioner')}
          </Button>
        </Grid>
      </Grid>
      <Button
        color="inherit"
        variant="contained"
        fullWidth
        onClick={props.handleReturnHome}
        style={{ marginTop: '68px', marginBottom: '8px' }}
      >
        {t('user:form.backToHome')}
      </Button>
    </PrimaryPageWithAppVersion>
  );
}
