import React from 'react'
import GreenPageWithAppVersion from './shared/GreenPageWithAppVersion'

function CookiesDisabled() {
  return (
    <GreenPageWithAppVersion title="Les cookies sont désactivés">
    </GreenPageWithAppVersion>
  )
}

export default CookiesDisabled
