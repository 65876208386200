import './primary-page-with-app-version.module.css';
import * as React from 'react';
import { Container, Typography, Box, useTheme } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import { styled } from '@mui/system';

const FormStyled = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));

const PStyled = styled('p')(({ theme }) => ({
  width: '100%',
  color: theme.palette.primary.contrastText,
}));

/* eslint-disable-next-line */
export interface PrimaryPageWithAppVersionProps {
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  title?: string;
}

export function PrimaryPageWithAppVersion(
  props: React.PropsWithChildren<PrimaryPageWithAppVersionProps>
) {
  const { renderLanguageChoice } = useTranslation();
  const gitVersion = process.env['NX_GIT_VERSION'] || '';

  const { children, title } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      }}
    >
      <Box
        style={{
          flex: 1,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Container maxWidth={props.maxWidth}>
          <FormStyled noValidate autoComplete="off">
            {title && (
              <Typography
                component="h1"
                variant="h5"
                sx={(theme) => ({
                  color: theme.palette.primary.contrastText,
                  textAlign: 'center',
                })}
              >
                {title}
              </Typography>
            )}
            {children}
          </FormStyled>
        </Container>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        {renderLanguageChoice()}
      </Box>
      <Box style={{ width: '100%', textAlign: 'center' }}>
        {process.env.NX_PRIMARY_PAGE_LOGO_URL &&
          process.env.NX_PRIMARY_PAGE_LINK_URL && (
            <a
              href={process.env.NX_PRIMARY_PAGE_LINK_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={process.env.NX_PRIMARY_PAGE_LOGO_URL}
                alt="logo kiway blanc"
                style={{ width: '100px' }}
              />
            </a>
          )}
        {gitVersion && <PStyled>{gitVersion}</PStyled>}
      </Box>
    </Box>
  );
}

export default PrimaryPageWithAppVersion;
