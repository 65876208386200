import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: '2vh',
  },
  title: {
    fontSize: 35,
    fontWeight: 'bold',
  },
  tabTitles: {
    fontWeight: 'bold',
  },
  important: {
    color: 'green',
    textDecoration: 'underline',
    textAlign: 'right',
    cursor: 'pointer',
  },
  titrePartie: {
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '2vh',
  },
  dataSideLineWrapper: {
    display: 'flex',
  },
  dataSideLine: {
    fontSize: 15,
    fontWeight: 'bold',
    margin: 'auto 0',
  },
  card: {
    height: '100%',
  },
  itemTextField: {
    borderRadius: '25px',
    height: '3vh',
    textAlign: 'center',
    backgroundColor: 'red',
  },
});
