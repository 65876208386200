import * as React from 'react';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Grid, Card, CardContent, CardHeader } from '@mui/material';
import { ChangePasswordController } from '../ChangePassword';
import { ChangeEmailController } from '../ChangeEmail';
import { TwoFAController } from '../TwoFA';
import { ListRefreshTokensController } from '../ListRefreshTokens';
import { AboutUserController } from '../AboutUser';
import { UserGlobalSettingsController } from '../UserGlobalSettings';
import { UserAddressController } from '../UserAddress/UserAddressController';

export function EditProfile() {
  const { t } = useTranslation();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card elevation={2}>
              <CardHeader title={t('authentication:editProfile.aboutMe')} />
              <CardContent>
                <AboutUserController />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={2}>
              <CardHeader
                title={t('authentication:editProfile.settings.title')}
              />
              <CardContent>
                <UserGlobalSettingsController />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card elevation={2}>
              <CardHeader
                title={t('authentication:editProfile.address.title')}
              />
              <CardContent>
                <UserAddressController enableUpdates />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={2}>
              <CardHeader title={t('authentication:editProfile.security')} />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ChangeEmailController />
                  </Grid>
                  <Grid item xs={12}>
                    <ChangePasswordController />
                  </Grid>
                  <Grid item xs={12}>
                    <TwoFAController />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={2}>
              <CardHeader
                title={t('authentication:editProfile.sessions.title')}
              />
              <CardContent>
                <ListRefreshTokensController />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
