// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Carrier, useCarriersGateway } from '@kiway/ecommerce-react';
import { useTranslation } from '@kiway/shared/utils/translation';
import * as React from 'react';
import { CountryZone } from '../../../core/entities/CountryZone';
import { useCountriesGateway } from '../../../entrypoints/react/useCountriesGateway';
import { useCountryZonesGateway } from '../../../entrypoints/react/useCountryZonesGateway';
import { ListCountryZones } from './ListCountryZones';

export function ListCountryZonesController() {
  const [loading, setLoading] = React.useState(false);
  const [countryZones, setCountryZones] = React.useState([]);
  const { editCountryZones, findAll } = useCountryZonesGateway();
  const [countries, setCountries] = React.useState([]);
  const { findAll: findAllCountries } = useCountriesGateway();
  const { findAll: findAllCarriers } = useCarriersGateway();

  const { currentLanguage } = useTranslation();

  React.useEffect(() => {
    setLoading(true);
    findAll()
      .then((countryZones) => {
        setCountryZones(countryZones);
      })
      .finally(() => setLoading(false));
    return () => {
      setCountryZones([]);
    };
  }, []);

  React.useEffect(() => {
    setLoading(true);
    findAllCountries()
      .then((countries) => {
        setCountries(countries);
      })
      .finally(() => setLoading(false));
    return () => {
      setCountries([]);
    };
  }, []);

  const handleAddData = () => {
    const data = new CountryZone({});
    setCountryZones((prev) => [data, ...prev]);
  };

  const handleClearAdd = () => {
    setCountryZones((prev) =>
      prev.filter((CountryZone) => CountryZone.getId())
    );
  };

  return (
    <ListCountryZones
      availableCountries={countries?.map((country) =>
        country.toDropdownValue(currentLanguage?.code)
      )}
      countryZones={countryZones}
      handleAddData={handleAddData}
      handleClearAdd={handleClearAdd}
      handleSave={async (updates) => {
        if (editCountryZones) {
          const editedCountryZones = await editCountryZones(updates);
          setCountryZones((prev) => [
            ...prev.filter(
              (countryZone) =>
                countryZone.getId() &&
                !editedCountryZones
                  .map((countryZone) => countryZone.getId())
                  .includes(countryZone.getId())
            ),
            ...editedCountryZones,
          ]);
        }
      }}
    />
  );
}
