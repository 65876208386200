import { gql } from '@apollo/client'

export const GET_CHECKOUT_SESSION_ID = gql`
  query getCheckoutSessionId(
    $paymentIntentId: String
    $paymentIntentType: String
  ) {
    getCheckoutSessionId(
      paymentIntentId: $paymentIntentId
      paymentIntentType: $paymentIntentType
    ) {
      sessionId
      stripeAccountId
    }
  }
`

export const GET_STRIPE_CONNECT_URL = gql`
  query {
    getStripeConnectURL
  }
`
