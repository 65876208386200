import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import Loader from './Loader';

interface KpiPros {
  title: string;
  data: number;
  loading: boolean;
}

export const Kpi = (props: KpiPros) => {
  return (
    <Paper
      elevation={0}
      sx={{ p: 2.25, borderRadius: 2, border: '1px solid lightgrey' }}
    >
      {props.loading ? (
        <Loader />
      ) : (
        <>
          <Typography variant="body1" color="textSecondary">
            {props.title}
          </Typography>
          <Grid container sx={{ pt: 0.5 }} alignItems="center">
            <Grid item>
              <Typography variant="h4">{props.data}</Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
};
