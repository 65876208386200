import * as React from 'react';
import { Plant } from '../../../core/entities/Plant';
import { useTranslation } from '@kiway/shared/utils/translation';
import { DatatablesController } from '@kiway/datatables';

export interface ListPlantsProps {
  handleAddData?: () => void;
  handleClearAdd?: () => void;
  handleRowClick?: (rowIndex: number) => void;
  handleSave?: any;
  loading?: boolean;
  plants: Plant[];
  refreshData?: () => void;
}

export function ListPlants({
  handleAddData,
  handleClearAdd,
  handleRowClick,
  handleSave,
  loading,
  plants,
  refreshData,
}: React.PropsWithChildren<ListPlantsProps>) {
  const { t } = useTranslation();
  return (
    <DatatablesController
      bulkEdit={true}
      columns={[
        {
          name: '_id',
          label: 'ID',
          type: 'text',
          options: {
            display: false,
            edit: false,
            filter: false,
          },
        },
        {
          name: 'custom.pinYinName',
          label: t(`pharmaco:plant.pinYinName`),
          type: 'text',
        },
        {
          name: 'custom.latinName',
          label: t(`pharmaco:plant.latinName`),
          type: 'text',
        },
        {
          name: 'custom.chineseName',
          label: t(`pharmaco:plant.chineseName`),
          type: 'text',
        },
        {
          name: 'custom.commonName',
          label: t(`pharmaco:plant.commonName`),
          type: 'text',
        },
        {
          name: 'custom.otherName',
          label: t(`pharmaco:plant.otherName`),
          type: 'text',
        },
        {
          name: 'published',
          label: t(`pharmaco:plant.published`),
          type: 'boolean',
        },
      ]}
      data={plants.map((item) => item.toDatatableRow())}
      defaultSortOrder={{ name: 'custom.pinYinName', direction: 'asc' }}
      handleAddData={handleAddData}
      handleClearAdd={handleClearAdd}
      handleRowClick={handleRowClick}
      handleSaveData={handleSave}
      id={`listPlants`}
      loading={loading}
      options={{
        download: false,
        filter: false,
        print: false,
      }}
      refreshData={refreshData}
      title={t(`pharmaco:plant.listTitle`)}
    />
  );
}
