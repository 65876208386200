import * as React from 'react';
import CheckboxInput from '../components/CheckboxInput';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { MenuItem, Select, Tooltip, TextField } from '@material-ui/core';
import moment from 'moment';
import { firstUpperCase } from '@kiway/shared/utils/string';
import { Price } from '@kiway/ecommerce-react';
import { Chip, Typography } from '@mui/material';

/**
 * Return a cell of data, called in column while Datatable is mounting
 */
const Cell = ({
  columnType,
  showInput,
  tableMeta,
  value,
  options,
  columnOptions,
  onInputChange,
  t,
  currentLanguage,
}) => {
  let localValue;
  const isValueDefined = () => {
    return value !== undefined && value !== null && value !== 'null';
  };
  const [editedValue, setEditedValue] = React.useState('');
  switch (columnType) {
    case 'text':
      return showInput ? (
        <TextField
          type="text"
          defaultValue={value}
          error={
            columnOptions?.editValidCallback
              ? !columnOptions.editValidCallback(editedValue)
              : false
          }
          helperText={
            !(columnOptions?.editValidCallback
              ? !columnOptions.editValidCallback(editedValue)
              : false)
              ? columnOptions?.editHelperText
              : columnOptions?.editHelperTextError
          }
          name={`${tableMeta.rowIndex}-${tableMeta.columnData.name}`}
          onChange={(e) => {
            onInputChange(
              options.setModifiedData,
              tableMeta.rowData[0],
              tableMeta.columnData.name,
              e
            );
            setEditedValue(e?.target?.value ?? '');
          }}
        />
      ) : isValueDefined() ? (
        value
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ClearIcon fontSize="small" style={{ color: '#e57373' }} />
        </div>
      );
    case 'boolean':
      return showInput ? (
        <CheckboxInput
          defaultChecked={value}
          callback={(e) =>
            onInputChange(
              options.setModifiedData,
              tableMeta.rowData[0],
              tableMeta.columnData.name,
              e
            )
          }
        />
      ) : value ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckIcon fontSize="small" style={{ color: '#81c784' }} />
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ClearIcon fontSize="small" style={{ color: '#e57373' }} />
        </div>
      );
    case 'dropdown':
      return showInput ? (
        <Select
          fullWidth
          defaultValue={value ?? ''}
          onChange={(e) =>
            onInputChange(
              options.setModifiedData,
              tableMeta.rowData[0],
              tableMeta.columnData.name,
              e
            )
          }
        >
          {columnOptions?.availableValues
            ?.filter(
              columnOptions?.customFilterAvailableValues
                ? columnOptions?.customFilterAvailableValues(
                    tableMeta.rowData[0]
                  )
                : () => true
            )
            ?.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
        </Select>
      ) : isValueDefined() ? (
        columnOptions?.availableValues
          ?.filter(
            columnOptions?.customFilterAvailableValues
              ? columnOptions?.customFilterAvailableValues(tableMeta.rowData[0])
              : () => true
          )
          ?.find(({ value: mapValue }) => value === mapValue)?.label ?? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ClearIcon fontSize="small" style={{ color: '#e57373' }} />
          </div>
        )
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ClearIcon fontSize="small" style={{ color: '#e57373' }} />
        </div>
      );
    case 'multi-select':
      return showInput ? (
        <Select
          fullWidth
          multiple
          defaultValue={value ?? []}
          onChange={(e) =>
            onInputChange(
              options.setModifiedData,
              tableMeta.rowData[0],
              tableMeta.columnData.name,
              e
            )
          }
        >
          {columnOptions?.availableValues
            ?.filter(
              columnOptions?.customFilterAvailableValues
                ? columnOptions?.customFilterAvailableValues(
                    tableMeta.rowData[0]
                  )
                : () => true
            )
            ?.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
        </Select>
      ) : isValueDefined() && Array.isArray(value) && value?.length > 0 ? (
        value
          ?.map(
            (val) =>
              columnOptions?.availableValues
                ?.filter(
                  columnOptions?.customFilterAvailableValues
                    ? columnOptions?.customFilterAvailableValues(
                        tableMeta.rowData[0]
                      )
                    : () => true
                )
                ?.find(({ value: mapValue }) => val === mapValue)?.label ?? val
          )
          ?.join(', ') ?? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ClearIcon fontSize="small" style={{ color: '#e57373' }} />
          </div>
        )
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ClearIcon fontSize="small" style={{ color: '#e57373' }} />
        </div>
      );
    case 'date':
      localValue = moment(value).locale(t('dates:moment'));
      return value ? (
        <Tooltip
          arrow
          title={localValue.format(t('dates:format.literalComplete'))}
        >
          <span>{firstUpperCase(localValue.fromNow())}</span>
        </Tooltip>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ClearIcon fontSize="small" style={{ color: '#e57373' }} />
        </div>
      );
    case 'simple-date':
      localValue = moment(value).locale(t('dates:moment'));
      return value ? (
        <Tooltip arrow title={firstUpperCase(localValue.fromNow())}>
          <span>{localValue.format(t('dates:format.smsSentDate'))}</span>
        </Tooltip>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ClearIcon fontSize="small" style={{ color: '#e57373' }} />
        </div>
      );
    case 'short-date':
      localValue = moment(value).locale(t('dates:moment'));
      return value ? (
        <Tooltip arrow title={firstUpperCase(localValue.fromNow())}>
          <span>{localValue.format(t('dates:format.shortYearDate'))}</span>
        </Tooltip>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ClearIcon fontSize="small" style={{ color: '#e57373' }} />
        </div>
      );
    case 'price':
      return showInput ? (
        <TextField
          type="number"
          defaultValue={value?.centAmount / 100 || ''}
          name={`${tableMeta.rowIndex}-${tableMeta.columnData.name}`}
          onChange={(e) =>
            onInputChange(
              options.setModifiedData,
              tableMeta.rowData[0],
              tableMeta.columnData.name,
              {
                target: {
                  value: new Price(
                    {
                      ...value,
                      centAmount: parseFloat(e.target.value) * 100,
                    },
                    1
                  ).toJSON(),
                },
              }
            )
          }
        />
      ) : value && value !== 'null' ? (
        new Price(
          value,
          columnOptions.priceDisableCentDecimals ? null : 1
        ).toString()
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ClearIcon fontSize="small" style={{ color: '#e57373' }} />
        </div>
      );
    case 'number':
      return showInput ? (
        <TextField
          type="number"
          defaultValue={isValueDefined() ? `${value}` : ''}
          name={`${tableMeta.rowIndex}-${tableMeta.columnData.name}`}
          onChange={(e) =>
            onInputChange(
              options.setModifiedData,
              tableMeta.rowData[0],
              tableMeta.columnData.name,
              {
                target: {
                  value: parseFloat(e.target.value),
                },
              }
            )
          }
        />
      ) : isValueDefined() ? (
        value
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ClearIcon fontSize="small" style={{ color: '#e57373' }} />
        </div>
      );
    case 'status':
      return value ? (
        typeof value === 'string' ? (
          <Chip label={t(value)} variant="outlined" />
        ) : (
          <Chip label={t(value.label)} color={value.color} variant="outlined" />
        )
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ClearIcon fontSize="small" style={{ color: '#e57373' }} />
        </div>
      );
    case 'status-borderless':
      return value ? (
        typeof value === 'string' ? (
          <span>{value}</span>
        ) : (
          <Typography variant="body2" sx={{ color: `${value.color}.main` }}>
            {t(value.label)}
          </Typography>
        )
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ClearIcon fontSize="small" style={{ color: '#e57373' }} />
        </div>
      );
    case 'translatable':
      return value ? (
        currentLanguage &&
        currentLanguage?.code &&
        value?.[currentLanguage?.code] ? (
          value[currentLanguage?.code]
        ) : (
          value
        )
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ClearIcon fontSize="small" style={{ color: '#e57373' }} />
        </div>
      );
    case 'array-translatable':
      return Array.isArray(value) && value?.length > 0 ? (
        value
          ?.map((item) =>
            item &&
            currentLanguage &&
            currentLanguage?.code &&
            item?.[currentLanguage?.code]
              ? item[currentLanguage?.code]
              : item
          )
          ?.join(', ')
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ClearIcon fontSize="small" style={{ color: '#e57373' }} />
        </div>
      );
    case 'standard':
      return isValueDefined() ? value : null;
    default:
      return value;
  }
};

export default Cell;
