import { gql } from '@apollo/client'

export const AGENDA_ADD_APPOINTMENT = gql`
  mutation addAppointment(
    $data: InputAppointment!
    $options: InputAppointmentOptions
  ) {
    addAppointment(data: $data, options: $options) {
      id
      owner {
        id
      }
      creator {
        id
      }
      startDate
      endDate
      title
      patient {
        firstName
        lastName
      }
      chiefComplaint {
        name
      }
      status
      eventType
      sms {
        msg_status
        scheduleAt
        ts_event
      }
    }
  }
`
export const MODAL_UPDATE_APPOINTMENT = gql`
  mutation updateAppointment($data: InputAppointment!) {
    updateAppointment(data: $data) {
      id
      owner {
        id
      }
      creator {
        id
      }
      title
      startDate
      endDate
      status
      eventType
      patient {
        id
        firstName
        lastName
      }
      consultationPlace {
        id
        name
        address {
          description
        }
      }
      chiefComplaint {
        id
        name
        duration
        specialty {
          id
          name
          slug
        }
        consultationPlaces {
          id
          name
        }
      }
      comment
    }
  }
`

export const PUBLIC_PAGE_ADD_APPOINTMENT = gql`
  mutation addAppointmentFromPatient($data: InputAppointment!) {
    addAppointmentFromPatient(data: $data) {
      id
    }
  }
`
export const PUBLIC_PAGE_UPDATE_APPOINTMENT = gql`
  mutation updateAppointmentFromPatient($data: InputAppointment!) {
    updateAppointmentFromPatient(data: $data) {
      id
    }
  }
`

export const DELETE_APPOINTMENT = gql`
  mutation deleteAppointment($id: ID!) {
    deleteAppointment(id: $id)
  }
`
