import { gql } from '@apollo/client'

export const ACTION_ADD = 'ADD'
export const ACTION_UPDATE = 'UPDATE'
export const ACTION_DELETE = 'DELETE'

export const DASHBOARD_ON_APPOINTMENT_EVENT = gql`
  subscription onAppointmentEvent($filter: AppointmentFilter) {
    onAppointmentEvent(filter: $filter) {
      appointment {
        id
        startDate
        patient {
          firstName
          lastName
        }
        chiefComplaint {
          name
        }
        eventType
      }
      action
    }
  }
`

export const AGENDA_ON_APPOINTMENT_EVENT = gql`
  subscription onAppointmentEvent($filter: AppointmentFilter) {
    onAppointmentEvent(filter: $filter) {
      appointment {
        id
        owner {
          id
        }
        creator {
          id
        }
        startDate
        endDate
        title
        patient {
          id
          firstName
          lastName
        }
        chiefComplaint {
          name
        }
        comment
        status
        eventType
        sms {
          msg_status
          scheduleAt
          ts_event
        }
      }
      action
    }
  }
`
