import { Tooltip, IconButton } from '@mui/material';
import * as React from 'react';
import { Plant } from '../../../../core/entities/Plant';
import SpaIcon from '@mui/icons-material/Spa';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import { useTranslation } from '@kiway/shared/utils/translation';

export interface ItemIconsProps {
  plant: Plant;
}

export function ItemIcon({ plant }: ItemIconsProps) {
  const { t } = useTranslation();

  const renderIcon = () => {
    return (
      <>
        {plant.isPlant() && (
          <Tooltip title={t('pharmaco:plant.availablePlant')}>
            <IconButton>
              <SpaIcon />
            </IconButton>
          </Tooltip>
        )}
        {plant.isConcentratedPowder() && (
          <Tooltip title={t('pharmaco:plant.availableConcentratedPowder')}>
            <IconButton>
              <BlurOnIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  };

  return <>{renderIcon()}</>;
}
