import * as React from 'react';
import { usePlantsGateway } from '../../../entrypoints/react/usePlantsGateway';
import { ListPlants } from './ListPlants';
import { Plant } from '../../../core/entities/Plant';
import { deepen } from '@kiway/shared/utils/string';
import { useHistory } from 'react-router-dom';
import { links } from '../../../config';

const prepareToSave = (item) => {
  const deepenItem: any = deepen(item);
  return {
    ...deepenItem,
    custom: {
      ...deepenItem.custom,
      concentrationRate: `${deepenItem.custom?.concentrationRate}`,
      posologyMin: `${deepenItem.custom?.posologyMin}`,
      posologyMax: `${deepenItem.custom?.posologyMax}`,
    },
    id: item._id,
    _id: undefined,
  };
};

export function ListPlantsController() {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [plants, setPlants] = React.useState<Plant[]>([]);
  const { editPlants, findAll } = usePlantsGateway();

  const updateData = () => {
    setLoading(true);
    findAll()
      .then(({ items: plantMetadatas }: { items: Plant[] }) => {
        setPlants([...plantMetadatas]);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    updateData();
    return () => {
      setPlants([]);
    };
  }, []);

  const handleAddData = () => {
    const data: Plant = new Plant({ custom: { latinName: '' } });
    setPlants((prev) => [data, ...prev]);
  };

  const handleClearAdd = () => {
    setPlants((prev) => [...prev.filter((plant) => plant.getId())]);
  };

  return (
    <ListPlants
      handleAddData={handleAddData}
      handleClearAdd={handleClearAdd}
      handleRowClick={(rowIndex) => {
        if (rowIndex !== undefined) {
          history.push(links.getPlantDetailsById(plants?.[rowIndex]?.getId()));
        }
      }}
      handleSave={async (updates) => {
        if (editPlants) {
          const editedPlants = await editPlants(
            updates.updatedData.map(prepareToSave)
          );
          setPlants((prev) => [
            ...prev.filter(
              (plant) =>
                plant.getId() &&
                !editedPlants
                  .map((plant) => plant.getId())
                  .includes(plant.getId())
            ),
            ...editedPlants,
          ]);
        }
      }}
      loading={loading}
      plants={plants}
      // refreshData={updateData}
    />
  );
}
