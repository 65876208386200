import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from '@kiway/shared/utils/translation'
import CustomButton from './CustomButton'

function DeleteButton(props) {
  const { children, className, ...other } = props
  const { t } = useTranslation()

  return (
    <CustomButton
      color="minoritary"
      hasmarginleft="true"
      className={className}
      {...other}
    >
      {children || t('common:form.buttons.delete')}
    </CustomButton>
  )
}

DeleteButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default DeleteButton
