import * as React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Edit button props
 */
type EditButtonProps = {
  /**
   * Function to clear added datas
   */
  clear: () => void;
  /**
   * Function to save datas
   */
  handleSave: () => void;
  isInEditingMode: boolean;
  /**
   * Function to switch on edit/read mode
   */
  toggleEditMode: () => void;
  /**
   * Tooltip displaying cancel
   */
  cancelTooltip: string;
  /**
   * Tooltip displaying edit
   */
  editTooltip: string;
  /**
   * Tooltip displaying save
   */
  saveTooltip: string;
};

const useStyles = makeStyles((theme) => ({
  save: {
    '& .MuiSvgIcon-root path': {
      fill: theme.palette.primary.main,
    },
  },
  cancel: {
    '& .MuiSvgIcon-root path': {
      fill: theme.palette.error.main,
    },
  },
}));

/**
 * Button to switch datatable mode from display to edit and vice versa
 */
const EditButton = (props: EditButtonProps) => {
  const classes = useStyles();
  return (
    <>
      <Tooltip
        title={props.isInEditingMode ? props.saveTooltip : props.editTooltip}
      >
        <IconButton
          onClick={
            props.isInEditingMode ? props.handleSave : props.toggleEditMode
          }
          color="primary"
          className={props.isInEditingMode ? classes.save : undefined}
        >
          {props.isInEditingMode ? <SaveIcon /> : <CreateIcon />}
        </IconButton>
      </Tooltip>
      {props.isInEditingMode ? (
        <Tooltip title={props.cancelTooltip}>
          <IconButton className={classes.cancel} onClick={props.clear}>
            <CancelIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );
};

export default EditButton;
