import * as React from 'react';
import { OrderComments } from './OrderComments';
import { useOrdersGateway } from '@kiway/ecommerce-react';
import { PharmacoOrder } from '../../../core/entities/PharmacoOrder';
import { links } from '../../../config';
import { Redirect } from 'react-router-dom';

export function OrderCommentsController(props) {
  const { findOne, editOrders } = useOrdersGateway();
  const [loading, setLoading] = React.useState(false);
  const [order, setOrder] = React.useState<PharmacoOrder[]>([]);

  React.useEffect(() => {
    setLoading(true);
    if (props.id) {
      findOne(props.id)
        .then(async (order) => {
          if (order && order.getId()) {
            setOrder([
              new PharmacoOrder({
                order,
                saveCallback: handleSaveOrder,
              }).createNewTempFormula([]),
            ]);
          }
        })
        .finally(() => setLoading(false));
    }
    return () => {
      setOrder([]);
    };
  }, [props.id]);

  const handleSaveOrder = async (forcedOrder?: PharmacoOrder) => {
    return await editOrders([
      (forcedOrder ?? order[0])?.getOrder()?.toInput(true),
    ]).then((order) => {
      if (order) {
        setOrder([
          new PharmacoOrder({
            order: order?.[0],
            saveCallback: handleSaveOrder,
          }).createNewTempFormula([]),
        ]);
      }
    });
  };

  const handleChangeStateOrder = (order: PharmacoOrder) => {
    setOrder([order]);
  };

  const handleChange = (
    commentType: 'preparerComment' | 'patientComment' | 'practitionerComment'
  ) => (e) => {
    switch (commentType) {
      case 'practitionerComment':
        order[0]?.setPractitionerComment(e?.target?.value ?? '');
        break;
      case 'patientComment':
        order[0]?.setPatientComment(e?.target?.value ?? '');
        break;
      case 'preparerComment':
        order[0]?.setPreparerComment(e?.target?.value ?? '');
        break;
    }
    handleChangeStateOrder(order[0]);
  };

  return order?.[0]?.getOrder()?.isDeleted() ? (
    <Redirect to={`/${links.main}/${links.listOrders}`} />
  ) : (
    <OrderComments
      handleNext={async () => {
        await handleSaveOrder();
        props.history.push(links.getOrderShippingScreen(props.id));
      }}
      handlePrev={() =>
        props.history.push(links.getOrderPrescriptionScreen(props.id))
      }
      handleChange={handleChange}
      order={order?.[0]}
      realOrder={order?.[0]?.getOrder()}
    />
  );
}
