import * as React from 'react';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Button, CircularProgress, Grid, IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export interface FileUploaderProps {
  accept?: string;
  buttonText?: string;
  disabled?: boolean;
  handleChangeFile?: (e: any) => void;
  handleRemoveFile?: (id: string) => void;
  loading?: boolean;
  multiple?: boolean;
  nbMaxFiles?: number;
  selectedFiles?: any[];
}

export function FileUploader(props: FileUploaderProps) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Button
          component="label"
          fullWidth
          disabled={
            props.disabled || props.nbMaxFiles <= props.selectedFiles?.length
          }
          variant="contained"
        >
          {props.loading ? (
            <CircularProgress size={25} />
          ) : (
            props.buttonText ?? t('storage:chooseFile')
          )}
          <input
            accept={props.accept}
            hidden
            multiple={props.multiple}
            onChange={props.handleChangeFile}
            type="file"
            // accept="image/*,.pdf"
          />
        </Button>
      </Grid>
      {props.selectedFiles?.map((item, index) => (
        <Grid item xs={12} key={index}>
          <Grid container alignItems="center">
            {props.handleRemoveFile && (
              <Grid item>
                <IconButton onClick={() => props.handleRemoveFile(item.id)}>
                  <DeleteForeverIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item>{item.name}</Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
