export default {
  addEvent: {
    modal: {
      confirmEmail: 'Envie um e -mail de confirmação',
      notifyWarning:
        'Lembre-se de prevenir o seu paciente em situações de alteração de horário ou endereço',
      smsReminder: 'Programe o SMS de lembrança para 24 horas antes',
      title: 'Adicione uma consulta',
    },
  },
  caption: 'Legenda :',
  deleteAppointmentModal: {
    content:
      'Tem certeza de que deseja excluir esta consulta? A operação é irreversível.',
    title: 'Eliminar uma consulta',
  },
  eventType: {
    other: 'Outro',
    session: 'Marcação com o paciente',
  },
  nextWeek: 'Próximo',
  prevWeek: 'Anterior',
  settings: {
    daysDisplayed: {
      labels: {
        friday: 'Sexta-feira',
        monday: 'Segunda-feira',
        saturday: 'Sábado',
        sunday: 'Domingo',
        thursday: 'Quinta-feira',
        tuesday: 'Terça-feira',
        wednesday: 'Quarta-feira',
      },
    },
    modal: {
      apply: 'Aplicar',
      button: 'Preferências',
      close: 'Fechar',
      dayEnds: 'O dia termina em',
      dayStarts: 'O dia começa em',
      displayWeekends: 'Exibir fins de semana',
      hideWeekends: 'Ocultar fins de semana',
      slotDuration: 'Duração de um intervalo',
      title: 'Preferências da agenda',
    },
    rowsPerHour: {
      title: 'Número de linhas por hora',
    },
    timeSlots: {
      title: 'Limites do horário exibido',
    },
  },
  smsIssue: 'SMS não recebido',
  smsNone: 'Sem lembrete SMS',
  smsScheduledAt: 'Um SMS será enviado ',
  smsSentOn: 'SMS enviado',
  snackbars: {
    appointments: {
      error:
        'Um problema ocorreu durante a gravação da nomeação. Por favor tente novamente.',
      errorDelete:
        'Um problema ocorreu durante a remoção da nomeação.  Por favor tente novamente',
      missingFields: 'Por favor, preencha todos os campos necessários',
      overlap: 'Não pode sobrepor vários agendamentos',
      pendingClick:
        'Um paciente está a marcar uma consulta on-line, não pode editar neste momento.',
      successCreate: 'Reunião bem -sucedida',
      successDelete: 'Excluído com sucesso',
      successUpdate: 'Nomeação bem -sucedida',
    },
    load: {
      error:
        'Um problema ocorreu durante o carregamento. Atualize o aplicativo para tentar novamente.',
    },
    settings: {
      error:
        'Um problema ocorreu durante o registro dos parâmetros. Obrigado por tentar novamente.',
      successUpdate: 'Configurações gravadas com sucesso',
    },
  },
  updateEvent: {
    modal: {
      title: 'Modificar uma marcação',
    },
  },
  views: {
    '3days': '3 dias',
    day: 'Dia',
    list: 'Listagem',
    week: 'Semana',
  },
  weekShort: 'SEM.',
};
