export default {
  bill: 'Facturar',
  billingInProgress: 'Factura en progresso...',
  dataTable: {
    columns: {
      actions: 'Comportamiento',
      amount_due: 'Valor pagado',
      createdAt: 'Fecha de emisión',
      description: 'Descripción',
      number: 'Número',
      paid: 'Pagado',
      patient: 'Paciente',
      viewPDF: 'Factura pdf',
    },
    title: 'Lista de facturas',
  },
  doYouHaveTaxes: '¿Aplica un impuesto?',
  invoicingAlert:
    'Una vez facturado, ya no puedes volver. Estás seguro de que quieres continuar ?',
  noReceiptData: 'Lo siento, no hay recibo disponible para este pago',
  paidAt: 'Pago el',
  patientEmailRequired:
    'El correo electrónico del paciente es obligatorio para poder enviar el enlace de pago',
  pay: 'Efetuar el pago',
  payInvoice: 'Efetuar el pago',
  payment: {
    checkout: {
      cancel: {
        text:
          'Vaya, te fuiste un poco rápidamente ... lo invitamos a renovar la solicitud para realizar el pago.',
        title: 'Pago de su factura cancelada',
      },
      redirectPage: {
        badStatus: 'Su factura ya parece pagada.',
        noConnectedAccount:
          'El módulo de pago encuentra un problema de configuración, comuníquese con su profesional.',
        redirecting: 'Serás redirigido ...',
        title: 'Pago de su factura',
        unknownError:
          'Se ha producido un error, comuníquese con su profesional.',
      },
      success: {
        text: 'Gracias por su pago, ahora puede regresar a la aplicación.',
        title: 'Pago de su factura exitosa',
      },
    },
    connectWithStripeText:
      'Para completar la activación del servicio de "pago en línea", debe crear o conectar una cuenta de stripe. Esta cuenta le permitirá recibir y eliminar sus fondos. Solo tiene que hacer clic en el botón de abajo y será redirigido a la plataforma Stripe.',
  },
  paymentDate: 'Fecha de pago',
  paymentMethod: {
    card: 'Tarjeta de crédito',
    cash: 'Especies',
    check: 'Cheque',
    kiway: 'Pago en línea',
    label: 'Medios de pago',
    title: 'Otros medios de pago',
  },
  paymentRef: 'Referencia de pago',
  paymentSaved: 'Pago con éxito',
  payOnline: 'Paga en linea',
  preview: 'Visualizar',
  receiptModalTitle: 'Recibo de pago',
  receiptToString:
    'La factura pagó el %paymentDate% en "%paymentMethod%", referencia de pago: %paymentRef%',
  savePayment: 'Registrar ',
  scanMeToPay: 'Escanearme para pagar con su teléfono smartphone',
  seeInvoice: 'Ver la factura',
  seeReceipt: 'Ver el recibo de pago',
  sendPaymentLink:
    '¿Desea enviar un enlace de pago en línea por correo electrónico al paciente?',
  sessionInvoicing: 'Facturación de la sesión',
  sessionInvoicingConfirm: 'Confirmación de la finalización de la factura',
  sessionToBill: 'Acta para la factura:',
  subTotal: 'Total parcial:',
  successInvoiceFinalized: 'Se ha emitido la factura',
  taxes: {
    country: 'País de aplicación del impuesto',
    modes: {
      excl: 'Sí, se excluye del precio arriba',
      incl: 'Sí, se incluye al precio arriba',
      no: 'No',
    },
    name: 'Nombre del impuesto',
    percent: 'valor del impuesto (porcentaje)',
    percentLimits: 'Entre 0 y 100',
    render: 'Impuesto aplicado:',
  },
  toBill: 'Facturación',
  total: 'Total :',
  youHaveToActivateStripe:
    'Debe activar su cuenta de stripe para poder usar pagos en línea',
};
