import React, { useContext, useEffect, Suspense } from 'react';
import Splashscreen from './Splashscreen';
import { AuthContext } from '@kiway/shared/features/authentication-react';
// import AppShell from './AppShell';
import { AppShell } from '@kiway/shared/ui';
import { useTranslation } from '@kiway/shared/utils/translation';
import CookiesDisabled from './CookiesDisabled';
import StripeConnect from './shared/StripeConnect';
import { RoutingBuilder } from '@kiway/routing-builder';
import { routes } from '../routes.config';
import { BookingContextProvider } from '../BookingContext';
import { CssBaseline } from '@mui/material';
import { MenuBuilder } from '@kiway/menu-builder';
import Alert from '@material-ui/lab/Alert';
import ReactTourWrapperContextProvider from './components/TourGuide/react-tour-wrapper-context';

export default function AppRoutes(props) {
  // const {
  //   authState: { userInfo },
  // } = useContext(AuthContext);
  const authUtils = useContext(AuthContext);
  const { authState, isRoleAllowed } = authUtils ?? {};
  const { userInfo } = authState ?? {};

  const { t } = useTranslation(['menu']);

  useEffect(() => {
    const subdomains = window.location.host.split('.');
    if (subdomains[0] === 'pro') {
      subdomains[0] = 'app';
      window.location.href = 'https://' + subdomains.join('.');
    }
  }, []);

  const mainMenuBuilder = MenuBuilder.getBuilder('main');
  mainMenuBuilder.setCustomItemsFilter(
    ({ display, role }) =>
      display && authUtils?.isRoleAllowed(role) && authUtils?.isEmailVerified()
  );
  mainMenuBuilder.setItems(props.config.menus(userInfo)?.['main'] || []);

  const userMenuBuilder = MenuBuilder.getBuilder('user');
  userMenuBuilder.setCustomItemsFilter(
    ({ display, role }) => display && authUtils?.isRoleAllowed(role)
  );
  userMenuBuilder.setItems(props.config.menus(userInfo)?.['user'] || []);

  const routing = RoutingBuilder.getBuilder();
  routing.addRoutes(routes(userInfo, t));
  routing.setAuthUtils(authUtils);
  routing.setAppShellComponent(({ children }) => (
    <Suspense fallback={<Splashscreen />}>
      <AppShell
        userId={authUtils?.authState?.userInfo?.id}
        userFirstName={authUtils?.authState?.userInfo?.firstName}
        userLastName={authUtils?.authState?.userInfo?.lastName}
        userImageUrl={
          authUtils?.authState?.userInfo?.imageUrl ||
          authUtils?.authState?.userInfo?.defaultImageUrl
        }
        userRoles={authUtils?.authState?.userInfo?.roles}
        version={process.env['NX_GIT_VERSION'] ?? ''}
        logo={
          process.env.NX_PRIMARY_PAGE_LOGO_URL && (
            <img
              alt="logo"
              width="200px"
              src={process.env.NX_PRIMARY_PAGE_LOGO_URL}
            />
          )
        }
      >
        {userInfo &&
          ['practitioner', 'pharmaco', 'partner', 'beta'].includes(
            userInfo?.role
          ) &&
          userInfo?.custom?.showStripeConnect && <StripeConnect />}
        {userInfo?.custom?.stripeSubscription?.lastPaymentFailed && (
          <Alert severity="error" style={{ marginBottom: '16px' }}>
            {t('subscription:lastPaymentFailed')}
          </Alert>
        )}
        {userInfo?.needLogout && (
          <Alert severity="warning" style={{ marginBottom: '16px' }}>
            {t('subscription:servicesOnDemand.needLogout')}
          </Alert>
        )}
        {userInfo &&
          ['practitioner', 'pharmaco'].includes(userInfo?.mainRole) &&
          !isRoleAllowed('ROLE_DISPLAY_LIST_ORDERS') &&
          process.env.NX_PHARMACO_ENABLED === 'true' && (
            <Alert severity="error" style={{ marginBottom: '16px' }}>
              {t('pharmaco:sendVerifToUsePharmaco')}
            </Alert>
          )}
        {children}
      </AppShell>
    </Suspense>
  ));

  return navigator.cookieEnabled ? (
    <ReactTourWrapperContextProvider>
      <BookingContextProvider>
        <CssBaseline />
        {routing.render()}
      </BookingContextProvider>
    </ReactTourWrapperContextProvider>
  ) : (
    <CookiesDisabled />
  );
}
