import * as CryptoJS from 'crypto-js';
import { getGravatarURL } from '@kiway/shared/utils/string';
import PhoneNumber from 'awesome-phonenumber';
import { emailRegex } from '@kiway/shared/utils/string';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Country, ICountryAttributes } from '@kiway/ecommerce-react-compatible';
import { KiwayLanguagesType } from '@kiway/shared/react-types';
import {
  getFallbackCountryCode,
  getFallbackLanguage,
} from '@kiway/shared/utils-react-compatible';

const objectsEqual = (o1, o2) =>
  typeof o1 === 'object' && Object.keys(o1 ?? {}).length > 0
    ? Object.keys(o1 ?? {}).length === Object.keys(o2 ?? {}).length &&
      Object.keys(o1 ?? {}).every((p) => objectsEqual(o1[p] ?? {}, o2[p] ?? {}))
    : o1 === o2;

const arraysEqual = (a1, a2) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

export interface CountryOption {
  name: string;
  code: string;
}

export interface IAddressAttributes {
  id?: string;
  _id?: string;
  city: string;
  default?: boolean;
  line0?: string;
  line1?: string;
  line2: string;
  line3?: string;
  countryCode: string;
  country?: string | ICountryAttributes | Country;
  firstName: string;
  lastName: string;
  zipCode: string;
  email: string;
  mobilePhone: string;
  nif: string;
}

export class Address {
  protected id: string;
  protected city: string;
  protected default: boolean;
  protected zipCode: string;
  protected firstName: string;
  protected lastName: string;
  protected line0: string; // étage, couloir, escalier, appartement
  protected line1: string; // entrée, bâtiment, immeuble, résidence
  protected line2: string; // numéro et libellé de voie
  protected line3: string; // lieu-dit ou autre mention
  protected countryCode: string; // iso 2 code, uppercase
  protected country: Country;
  protected email: string;
  protected mobilePhone: string;
  protected nif: string;

  public getId(): string {
    return this.id;
  }

  public setId(id: string): Address {
    this.id = id;
    return this;
  }

  public getCity(): string {
    return this.city;
  }

  public setCity(city: string): Address {
    this.city = city;
    return this;
  }

  public isDefault(): boolean {
    return this.default;
  }

  public setDefault(isDefault: boolean): Address {
    this.default = isDefault;
    return this;
  }

  public getLine0(): string {
    return this.line0;
  }

  public setLine0(line0: string): Address {
    this.line0 = line0;
    return this;
  }

  public getLine1(): string {
    return this.line1;
  }

  public setLine1(line1: string): Address {
    this.line1 = line1;
    return this;
  }

  public getLine2(): string {
    return this.line2;
  }

  public setLine2(line2: string): Address {
    this.line2 = line2;
    return this;
  }

  public getLine3(): string {
    return this.line3;
  }

  public setLine3(line3: string): Address {
    this.line3 = line3;
    return this;
  }

  public getFirstName(): string {
    return this.firstName;
  }

  public setFirstName(firstName: string): Address {
    this.firstName = firstName;
    return this;
  }

  public getLastName(): string {
    return this.lastName;
  }

  public setLastName(lastName: string): Address {
    this.lastName = lastName;
    return this;
  }

  public getZipCode(): string {
    return this.zipCode;
  }

  public setZipCode(zipCode: string): Address {
    this.zipCode = zipCode;
    return this;
  }

  public getCountryCode(): string {
    return this.countryCode;
  }

  public setCountryCode(countryCode: string): Address {
    this.countryCode = countryCode;
    return this;
  }

  public getEmail(): string {
    return this.email;
  }

  public setEmail(email: string): Address {
    this.email = email;
    return this;
  }

  public getMobilePhone(): string {
    return this.mobilePhone;
  }

  public setMobilePhone(mobilePhone: string): Address {
    this.mobilePhone = mobilePhone;
    return this;
  }

  public getNif(): string {
    return this.nif;
  }

  public setNif(nif: string): Address {
    this.nif = nif;
    return this;
  }

  public getFullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  equals(other: any): boolean {
    if (!(other instanceof Address)) {
      return false;
    }
    if (other.getCity() !== this.city) {
      return false;
    }
    if (other.getId() !== this.id) {
      return false;
    }
    if (other.isDefault() !== this.default) {
      return false;
    }
    if (other.getLine0() !== this.line0) {
      return false;
    }
    if (other.getLine1() !== this.line1) {
      return false;
    }
    if (other.getLine2() !== this.line2) {
      return false;
    }
    if (other.getLine3() !== this.line3) {
      return false;
    }
    if (other.getFirstName() !== this.firstName) {
      return false;
    }
    if (other.getLastName() !== this.lastName) {
      return false;
    }
    if (other.getZipCode() !== this.zipCode) {
      return false;
    }
    if (other.getCountryCode() !== this.countryCode) {
      return false;
    }
    if (other.getEmail() !== this.email) {
      return false;
    }
    if (other.getMobilePhone() !== this.mobilePhone) {
      return false;
    }
    return true;
  }

  public constructor(
    obj: Partial<IAddressAttributes> & { _id?: string; id?: string }
  ) {
    this.changeAllAttributes(obj);
  }

  public changeAllAttributes(
    obj: Partial<IAddressAttributes> & { _id?: string; id?: string }
  ): Address {
    this.id = obj?.id || obj?._id;
    this.city = obj?.city;
    this.default = obj?.default;
    this.line0 = obj?.line0;
    this.line1 = obj?.line1;
    this.line2 = obj?.line2;
    this.line3 = obj?.line3;
    this.firstName = obj?.firstName;
    this.lastName = obj?.lastName;
    this.countryCode = obj?.countryCode ?? getFallbackCountryCode();
    this.zipCode = obj?.zipCode;
    this.email = obj?.email;
    this.mobilePhone = obj?.mobilePhone;
    this.nif = obj?.nif;
    if (obj?.country) {
      if (typeof obj?.country === 'string') {
        this.country = new Country({ id: obj?.country });
      } else if (obj?.country instanceof Country) {
        this.country = obj?.country;
      } else {
        this.country = new Country(obj?.country);
      }
    }
    return this;
  }

  getFormattedAddress(lng?: KiwayLanguagesType): string {
    return `${this.firstName} ${this.lastName}, ${this.line2}, ${this.city} ${
      this.zipCode
    } (${
      this.country?.getName()
        ? this.country?.getName()?.[lng ?? getFallbackLanguage()] ??
          this.countryCode?.toUpperCase()
        : this.countryCode?.toUpperCase()
    })`;
  }

  public validateAddress(): boolean {
    const phone = new PhoneNumber(
      this.mobilePhone ?? '',
      this.countryCode?.toUpperCase()
    );
    return (
      this.city &&
      this.city !== '' &&
      this.countryCode &&
      this.countryCode !== '' &&
      this.line2 &&
      this.line2 !== '' &&
      this.zipCode &&
      this.zipCode !== '' &&
      this.firstName &&
      this.firstName !== '' &&
      this.lastName &&
      this.lastName !== '' &&
      this.mobilePhone &&
      this.mobilePhone !== '' &&
      phone.isValid() &&
      phone.getRegionCode()?.toUpperCase() ===
        this.countryCode?.toUpperCase() &&
      this.email &&
      this.email !== '' &&
      !!this.email.match(emailRegex)
    );
  }

  public static findIfDefault(addressId: string) {
    return (address: Address) =>
      address.getId() === addressId && address.isDefault();
  }

  getCountry(): Country {
    return this.country;
  }

  public toJSON(removeCountry?: boolean): any {
    return {
      id: this.id,
      city: this.city,
      default: this.default,
      line0: this.line0,
      line1: this.line1,
      line2: this.line2,
      line3: this.line3,
      firstName: this.firstName,
      lastName: this.lastName,
      countryCode: this.countryCode,
      country: removeCountry ? undefined : this.country?.toJSON(),
      zipCode: this.zipCode,
      email: this.email,
      mobilePhone: this.mobilePhone,
      nif: this.nif,
    };
  }
}

export interface IUserAttributes {
  email: string;
  imageUrl?: string;
  defaultImageUrl?: string;
  mainRole: string;
  roles: string[];
  firstName: string;
  lastName: string;
  gender: string;
  verified: boolean;
  salt?: string;
  hash?: string;
  password?: string;
  birthDate: string;
  profession: string;
  twoFactor: any;
  language: string;
  timezone: string;
  mobilePhone: string;
  secondPhone: string;
  addresses: Array<Address | IAddressAttributes | string>;
  referrerCode: string;
  custom: any;
}

export const enumUserRoles: string[] = [
  'admin',
  'wholesalerAdmin',
  'orderPicker',
  'beta',
  'partner',
  'pharmaco',
  'practitioner',
  'patient',
  'generatedPatient',
];

export class User {
  protected id: string;
  protected email: string;
  protected imageUrl: string;
  protected defaultImageUrl: string;
  protected mainRole: string;
  protected roles: string[];
  protected firstName: string;
  protected lastName: string;
  protected gender: string;
  protected verified: boolean;
  protected salt: string;
  protected hash: string;
  protected birthDate: string;
  protected profession: string;
  protected twoFactor: any;
  protected language: string;
  protected timezone: string;
  protected mobilePhone: string;
  protected secondPhone: string;
  protected addresses: Address[];
  protected referrerCode: string;
  protected custom: any;

  public constructor(
    obj: Partial<IUserAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    this.email = obj?.email;
    this.imageUrl = obj?.imageUrl;
    if (obj?.defaultImageUrl) {
      this.defaultImageUrl = obj?.defaultImageUrl;
    } else {
      this.defaultImageUrl = getGravatarURL(obj?.email);
    }
    this.setMainRole(obj?.mainRole);
    (this.roles = obj?.roles), (this.firstName = obj?.firstName);
    this.lastName = obj?.lastName;
    this.gender = obj?.gender;
    this.verified = obj?.verified;
    if (obj?.password) {
      this.setPassword(obj?.password);
    } else {
      this.salt = obj?.salt;
      this.hash = obj?.hash;
    }
    this.birthDate = obj?.birthDate;
    this.profession = obj?.profession;
    this.twoFactor = obj?.twoFactor;
    this.language = obj?.language;
    this.timezone = obj?.timezone;
    this.mobilePhone = obj?.mobilePhone;
    this.secondPhone = obj?.secondPhone;
    this.addresses =
      obj?.addresses?.map((address) =>
        address instanceof Address
          ? address
          : typeof address === 'string'
          ? new Address({ id: address })
          : new Address(address)
      ) || [];
    this.referrerCode = obj?.referrerCode;
    this.custom = obj?.custom ?? {};
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): User {
    this.id = id;
    return this;
  }

  public getFullName(): string {
    return `${this.getFirstName()} ${this.getLastName()}`;
  }

  public getEmail(): string {
    return this.email;
  }

  public getImageUrl(): string {
    return this.imageUrl;
  }

  public setImageUrl(imageUrl: string): User {
    this.imageUrl = imageUrl;
    return this;
  }

  public getDefaultImageUrl(): string {
    return this.defaultImageUrl;
  }

  public getMainRole(): string {
    return this.mainRole;
  }

  public setMainRole(role: string): User {
    if (role && !enumUserRoles.includes(role)) {
      throw new Error(`Role should be one of : ${enumUserRoles.join(', ')}.`);
    }
    this.mainRole = role;
    return this;
  }

  public getRoles(): string[] {
    return this.roles;
  }

  public getFirstName(): string {
    return this.firstName;
  }

  public setFirstName(firstName: string): User {
    this.firstName = firstName;
    return this;
  }

  public getLastName(): string {
    return this.lastName;
  }

  public setLastName(lastName: string): User {
    this.lastName = lastName;
    return this;
  }

  public getGender(): string {
    return this.gender;
  }

  public setGender(gender: string): User {
    this.gender = gender;
    return this;
  }

  public isVerified(): boolean {
    return this.verified;
  }

  private getHashFromSaltAndPassword(salt: string, password: string): string {
    return CryptoJS.SHA512(salt + password).toString();
  }

  public setPassword(password: string): User {
    this.salt = CryptoJS.lib.WordArray.random(16).toString(); //crypto.randomBytes(16).toString('hex');
    this.hash = this.getHashFromSaltAndPassword(this.salt, password);
    return this;
  }

  public validatePassword(password: string): boolean {
    if (this.salt && this.hash) {
      const hash = this.getHashFromSaltAndPassword(this.salt, password);
      return this.hash === hash;
    }
    return false;
  }

  public getBirthDate(): string {
    return this.birthDate;
  }

  public setBirthDate(birthDate: string): User {
    this.birthDate = birthDate;
    return this;
  }

  public getProfession(): string {
    return this.profession;
  }

  public setProfession(profession: string): User {
    this.profession = profession;
    return this;
  }

  public getTwoFactor(): any {
    return this.twoFactor;
  }

  public setTwoFactor(twoFactor: any): User {
    this.twoFactor = twoFactor;
    return this;
  }

  public getLanguage(): string {
    return this.language;
  }

  public setLanguage(language: string): User {
    this.language = language;
    return this;
  }

  public getTimezone(): string {
    return this.timezone;
  }

  public setTimezone(timezone: string): User {
    this.timezone = timezone;
    return this;
  }

  public getMobilePhone(): string {
    return this.mobilePhone;
  }

  public setMobilePhone(mobilePhone: string): User {
    this.mobilePhone = mobilePhone;
    return this;
  }

  public getSecondPhone(): string {
    return this.secondPhone;
  }

  public setSecondPhone(secondPhone: string): User {
    this.secondPhone = secondPhone;
    return this;
  }

  public getAddresses(): Address[] {
    return this.addresses;
  }

  public setAddresses(addresses: Address[]): User {
    this.addresses = addresses;
    return this;
  }

  public addAddress(address: Address): User {
    this.addresses = [
      ...this.addresses,
      address.setDefault(this.addresses?.length === 0),
    ];
    return this;
  }

  public updateAddress(addressId: string, newAddress: Address): User {
    this.addresses = this.addresses.map((address) => {
      if (address.getId() === addressId) {
        return newAddress;
      }
      return address;
    });
    return this;
  }

  public removeAddress(addressId: string): User {
    const isDefault = this.addresses.find(Address.findIfDefault(addressId));
    this.addresses = this.addresses.filter(
      (address) => address.getId() !== addressId
    );
    if (isDefault) {
      this.addresses = this.addresses.map((address, index) => {
        if (index === 0) {
          return new Address({
            ...address,
            default: true,
          });
        }
        return address;
      });
    }
    return this;
  }

  public setDefaultAddress(addressId: string): User {
    this.addresses = this.addresses.map((address) => {
      if (address.getId() === addressId) {
        return new Address({
          ...address,
          default: true,
        });
      }
      return new Address({
        ...address,
        default: false,
      });
    });
    return this;
  }

  public getReferrerCode(): string {
    return this.referrerCode;
  }

  public setReferrerCode(referrerCode: string): User {
    this.referrerCode = referrerCode;
    return this;
  }

  public getCustom(): any {
    return this.custom;
  }

  public setCustom(custom: any): User {
    this.custom = custom;
    return this;
  }

  public canSendSMS(): boolean {
    return false;
  }

  equals(other: any): boolean {
    if (!(other instanceof User)) {
      return false;
    }
    if (other.getId() !== this.id) {
      return false;
    }
    if (other.getEmail() !== this.email) {
      return false;
    }
    if (other.getFirstName() !== this.firstName) {
      return false;
    }
    if (other.getLastName() !== this.lastName) {
      return false;
    }
    if (other.getGender() !== this.gender) {
      return false;
    }
    if (other.getBirthDate() !== this.birthDate) {
      return false;
    }
    if (other.getRoles() !== this.roles) {
      return false;
    }
    if (other.getMainRole() !== this.mainRole) {
      return false;
    }
    if (other.getProfession() !== this.profession) {
      return false;
    }
    if (other.getImageUrl() !== this.imageUrl) {
      return false;
    }
    if (other.getLanguage() !== this.language) {
      return false;
    }
    if (other.getTimezone() !== this.timezone) {
      return false;
    }
    if (other.getMobilePhone() !== this.mobilePhone) {
      return false;
    }
    if (other.getAddresses()?.length !== this.addresses.length) {
      return false;
    } else if (!arraysEqual(other.getAddresses(), this.addresses)) {
      return false;
    }
    if (other.getReferrerCode() !== this.referrerCode) {
      return false;
    }
    if (
      Object.keys(other.getCustom() ?? {})?.length !==
      Object.keys(this.custom ?? {})?.length
    ) {
      return false;
    } else if (
      !arraysEqual(
        Object.keys(other.getCustom() ?? {}),
        Object.keys(this.custom ?? {})
      )
    ) {
      return false;
    }
    return true;
  }

  public toJSON(client?: boolean): any {
    return {
      id: this.getId(),
      email: this.getEmail(),
      imageUrl: this.getImageUrl(),
      defaultImageUrl: this.getDefaultImageUrl(),
      mainRole: this.getMainRole(),
      roles: this.getRoles(),
      firstName: this.getFirstName(),
      lastName: this.getLastName(),
      gender: this.getGender(),
      verified: this.isVerified(),
      birthDate: this.getBirthDate(),
      profession: this.getProfession(),
      language: this.getLanguage(),
      timezone: this.getTimezone(),
      mobilePhone: this.getMobilePhone(),
      secondPhone: this.getSecondPhone(),
      addresses: this.getAddresses()?.map((address: any) => {
        const { __typename, ...rest } = address;
        let result = rest;
        if (client && rest.country) {
          result = {
            ...result,
            country: {
              ...result.country,
              __typename: undefined,
            },
          };
        }
        return client ? result : address;
      }),
      referrerCode: this.getReferrerCode(),
      custom: this.getCustom(),
    };
  }
}
