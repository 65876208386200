export default {
  and: 'et',
  browserDefaults: 'Par défaut',
  chips: {
    app: 'App',
    pro: 'Pro',
    proFeature: 'Service payant',
    proTrial: 'Pro - Essai en cours',
    student: 'Etudiant',
  },
  comingSoon: 'Bientôt disponible ! 😉',
  connectWith: 'Connexion avec',
  controlButtons: {
    finish: 'Terminer',
    later: 'Plus tard',
    next: 'Suivant',
    previous: 'Précédent',
    skip: 'Passer',
  },
  copied: 'Copié dans le presse-papiers',
  default: 'Défaut',
  fileSize: {
    byte: 'octets',
    giga: 'Go',
    kilo: 'ko',
    mega: 'Mo',
  },
  form: {
    buttons: {
      cancel: 'Annuler',
      charge: 'Facturer',
      confirm: 'Confirmer',
      delete: 'Supprimer',
      download: 'Télécharger',
      edit: 'Modifier',
      filter: 'Filtrer',
      loadMore: 'Voir plus',
      logout: 'Déconnexion',
      save: 'Enregistrer',
      send: 'Envoyer',
      setDefault: 'Définir par défaut',
    },
    errors: {
      tooLongString: 'Maximum %X% caractères',
      tooShortString: 'Minimum %X% caractères',
    },
    fields: {
      address: {
        label: 'Adresse',
      },
      addressComplement: {
        label: "Complément d'adresse",
      },
      CGU: {
        label: `En cochant cette case, je certifie avoir lu et accepté sans réserve les [CGU](${process.env['NX_CGU_URL']}), la [charte d'utilisation des cookies](${process.env['NX_COOKIES_POLICY_URL']}) ainsi que la [charte de confidentialité des données de CIAM](${process.env['NX_DATA_POLICY_URL']}) *`,
      },
      chiefComplaint: {
        label: 'Motif de consultation',
      },
      comment: {
        label: 'Commentaire',
      },
      consultationPlace: {
        label: 'Lieu de consultation',
      },
      email: {
        label: 'Email',
      },
      endDate: {
        label: 'Date de fin',
      },
      endHour: {
        label: 'Heure de fin',
      },
      GDPR: {
        label: `En cochant cette case, je consens au traitement de mes données personnelles tel que prévu dans la [Politique de confidentialité](${process.env['NX_PRIVACY_POLICY_URL']}) et en accord avec le RGPD *`,
      },
      gender: {
        default: 'Aucun',
        female: 'Femme',
        male: 'Homme',
        other: 'Autre',
      },
      label: {
        label: 'Nom',
      },
      lastname: {
        label: 'Nom',
      },
      patient: {
        global: {
          label: 'Patient',
        },
      },
      phone: {
        areaCodeInfo: "Vous devez préciser l'indicatif",
        country: 'Code pays',
        error: {
          possibility: {
            default: 'Vous devez renseigner un numéro de téléphone valide',
            'invalid-country-code':
              'Vous devez renseigner un indicatif valide (+33 pour la France, +351 pour le Portugal)',
            'is-possible': 'Le numéro de téléphone semble être possible',
            'too-long': 'Le numéro de téléphone semble être trop long',
            'too-short': 'Le numéro de téléphone semble être trop court',
            unknown: 'Vous devez renseigner un numéro de téléphone valide',
            wrongRegionCode: 'Vous devez renseigner un numéro local',
          },
          specificType: {
            'fixed-line': 'Vous devez renseigner un numéro de téléphone fixe',
            'fixed-line-or-mobile':
              'Vous devez renseigner un numéro de téléphone fixe ou portable',
            mobile: 'Vous devez renseigner un numéro de téléphone portable',
          },
        },
        errorNotValid: 'Renseignez un numéro de téléphone valide',
        errorNotValidOrNotMobile: 'Renseignez un numéro mobile valide',
        label: 'Téléphone',
        mobileLabel: 'Portable',
      },
      startDate: {
        label: 'Date de début',
      },
      startHour: {
        label: 'Heure de début',
      },
      title: {
        label: 'Titre',
      },
      yourMessage: {
        label: 'Votre message',
      },
    },
    uploads: {
      dragNDrop: {
        accept: 'Déposez vos fichiers ici',
        label: 'Glissez vos fichiers ici, ou cliquez pour les sélectionner',
        reject: 'Le ou les fichiers ne respectent pas les conditions',
        snackbars: {
          error: {
            invalidType: "Le type du fichier n'est pas autorisé",
            maxFilesNumber:
              'Vous ne pouvez télécharger plus de %maxFilesNumber% fichiers.',
            multipleFalse:
              'Un seul fichier peut être téléchargé. Veuillez supprimer celui déjà présent pour en télécharger un nouveau',
            tooLarge: 'La taille du fichier ne doit pas dépasser',
            unique: 'Un fichier avec le même nom existe déjà',
          },
        },
      },
    },
  },
  from: 'de',
  fromDate: 'Du',
  ht: 'HT',
  httpCodes: {
    buttons: {
      goBack: 'Retour',
      goHome: 'Accueil',
    },
    error404: {
      content: "Nous n'avons pas pu trouver la page que vous cherchez.",
      title: 'Oops !',
    },
  },
  legalDocuments: 'Documents légaux',
  loading: 'Chargement...',
  menuToolbar: {
    chatbox: 'Chatbox',
    faq: 'FAQ',
    serviceDesk: 'Faites-nous part de vos commentaires',
    tutorials: 'Tutoriels',
  },
  no: 'non',
  noLoggedIn:
    'Vous semblez être déconnecté, merci de vous identifier à nouveau.',
  noOptionsText: 'Aucune proposition',
  noResult: 'Aucun résultat',
  of: 'sur',
  or: 'ou',
  payment: {
    buttons: {
      validateCreditCard: 'Valider la carte',
    },
    labels: {
      billingCountry: 'Pays de facturation',
      creditCard: 'Carte bancaire',
      name: 'Nom',
    },
    messages: {
      cardAccepted: 'Carte bancaire acceptée',
    },
  },
  practices: {
    acupressure: 'Acupression',
    acupuncture: 'Acupuncture',
    error: 'Veuillez en choisir au moins un',
    other: 'Autre',
    otherStr: 'Précisez',
    pharmacopoeia: 'Pharmacopée',
    title: 'Que pratiquez-vous ?',
    tuinaMassage: 'Massage Tuina',
  },
  readOnly: 'Lecture seule',
  redirectPending: 'Vous allez être redirigé',
  to: 'à',
  toDate: 'Au',
  tourGuide: {
    demoButton: 'Voir la démo',
    description: {
      agenda: 'Description agenda',
      dashboard: 'Description tableau de bord',
      files: 'Description dossiers',
      invoicing: 'Description factures',
      pharmaco: 'Description pharmacopée',
      services: 'Description services',
      settings: 'Description paramètres',
    },
    name: {
      agenda: 'Agenda',
      dashboard: 'Tableau de bord',
      files: 'Dossiers',
      invoicing: 'Factures',
      pharmaco: 'Pharmacopée',
      services: 'Services',
      settings: 'Paramètres',
    },
    subSection: {
      description: {
        addPatientDescription: 'Découvrez comment ajouter un patient.',
        agendaGeneralDescription:
          "La page Agenda va vous permettre de planifier des rendez-vous et de les gérer. En un coup d'oeil, vous saurez de quoi est faîte votre journée.",
        agendaSettingsDescription:
          'La page Agenda est paramétrable pour répondre au mieux à vos besoins.',
        dashboardGeneralDescription:
          "Le tableau de bord est la page d'accueil du site. Vous y trouverez en un coup d'oeil les rendez-vous du jour, le rapport du nombre de séances effectuées sur le nombre de séances prévues, les crédits SMS, etc.",
        filesGeneralDescription:
          'Sur Dossiers, vous aurez un aperçu de vos patients et de quelques informations les concernant.',
        invoicingGeneralDescription:
          "Vous trouverez ici la liste de toutes les factures émises, leur montant, leur date d'émission, etc.",
        patientInfoDescription:
          'Pour plus de détails, consultez cette page, vous trouverez les informations relatives à vos patients : adresses, commandes, antécédents, etc.',
        pharmacoGeneralDescription:
          'Depuis cette page, vous pourrez créer et suivre vos commandes.',
        removePatientDescription: 'Découvrez comment supprimer un patient.',
        servicesGeneralDescription:
          "C'est ici que vous retrouverez les options payantes/services premium du logiciel.",
        settingsActivityDescription: 'Paramétrez la nature de vos acitivités.',
        settingsAgendaDescription:
          'Vous allez pouvoir paramétrer les semaines types pour la prise de rdv en ligne.',
        settingsGeneralDescription:
          "La page Paramètres va vous permettre de compléter la personnalisation de votre interface. C'est votre outil : vous pourrez donc le paramétrer comme vous le souhaitez.",
        settingsInvoicingDescription:
          'Toutes les informations relatives à la facturation de vos services doivent être renseignées depuis cette page.',
        settingsPublicPageDescription:
          'A quoi ressemble votre page publique ? Sur cette page, vous le décidez.',
        settingsWorkplacesDescription: 'Paramétrez les lieux de consultation.',
      },
      title: {
        addPatient: 'Ajouter un patient',
        agendaGeneral: 'Agenda',
        agendaSettings: "Paramètres de l'agenda",
        dashboardGeneral: 'Tableau de bord',
        filesGeneral: 'Dossiers',
        invoicingGeneral: 'Facturation',
        patientInfo: 'Fiche patient',
        pharmacoGeneral: 'Pharmacopée',
        removePatient: 'Supprimer un patient',
        servicesGeneral: 'Services',
        settingsActivity: "Paramètres lié à l'activité",
        settingsAgenda: "Paramètres de l'agenda",
        settingsGeneral: 'Paramètres',
        settingsInvoicing: 'Paramètres de facturation',
        settingsPublicPage: 'Paramètres de la page publique',
        settingsWorkplaces: 'Paramètres des lieux de consultation',
      },
    },
    title: 'Tutoriels',
  },
  ttc: 'TTC',
  VAT: 'TVA',
  welcome: 'Bienvenue',
  yes: 'oui',
};
