// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Country } from '@kiway/ecommerce-react';
import * as React from 'react';
import { useCountriesGateway } from '../../../entrypoints/react/useCountriesGateway';
import { ListCountries } from './ListCountries';

export function ListCountriesController() {
  const [loading, setLoading] = React.useState(false);
  const [countries, setCountries] = React.useState([]);
  const { editCountries, findAll } = useCountriesGateway();

  React.useEffect(() => {
    setLoading(true);
    findAll()
      .then((countries) => {
        setCountries(countries);
      })
      .finally(() => setLoading(false));
    return () => {
      setCountries([]);
    };
  }, []);

  const handleAddData = () => {
    const data = new Country({});
    setCountries((prev) => [data, ...prev]);
  };

  const handleClearAdd = () => {
    setCountries((prev) => prev.filter((country) => country.getId()));
  };

  return (
    <ListCountries
      handleAddData={handleAddData}
      handleClearAdd={handleClearAdd}
      handleSave={async (updates) => {
        if (editCountries) {
          const editedCountries = await editCountries(updates);
          setCountries((prev) => [
            ...prev.filter(
              (country) =>
                country.getId() &&
                !editedCountries
                  .map((country) => country.getId())
                  .includes(country.getId())
            ),
            ...editedCountries,
          ]);
        }
      }}
      loading={loading}
      countries={countries}
    />
  );
}
