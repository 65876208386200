import { PaginatedResults } from '@kiway/shared/types';
import { PaginationOptions } from '@kiway/shared/react-types';
import { CatalogProvider } from './CatalogProvider';
import { Formula } from '../entities/Formula';
import { Plant } from '../entities/Plant';

type CatalogFilterType =
  | 'model'
  | 'plantPowderType'
  | 'formulaFamily'
  | 'metadata';

type CatalogFilter = {
  type: CatalogFilterType;
  value: string;
};

export type CatalogOptions = {
  filters?: CatalogFilter[];
  search?: string;
};

export class GetCatalog {
  private catalogProvider: CatalogProvider;

  public constructor(catalogProvider: CatalogProvider) {
    this.catalogProvider = catalogProvider;
  }

  public async execute(
    options: CatalogOptions,
    pagination?: PaginationOptions
  ): Promise<PaginatedResults<Plant | Formula>> {
    return await this.catalogProvider.getCatalog(options, {
      ...pagination,
      enabled: true,
    });
  }
}
