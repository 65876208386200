/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import { ListLastOrders } from './ListLastOrders';
import { useOrdersGateway } from '@kiway/ecommerce-react';
import { useHistory } from 'react-router-dom';
import { links } from '../../../config';
import { AuthContext } from '@kiway/shared/features/authentication-react';
import { useLoyaltyTransactionsGateway } from '@kiway/shared/loyalty-program-react';

export function ListLastOrdersController() {
  const history = useHistory();
  const { createOrder, getLastOrders, editOrders } = useOrdersGateway();
  const [orders, setOrders] = React.useState([]);
  const { getLoyaltyPointsBalance } = useLoyaltyTransactionsGateway();
  const [loyaltyPointsBalance, setLoyaltyPointsBalance] = React.useState<
    number
  >(0);
  const {
    authState: { userInfo },
  } = React.useContext(AuthContext);

  const handleNewClick = () => {
    createOrder({
      id: userInfo.practitionerId,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      birthDate: userInfo.birthDate,
      gender: userInfo.gender,
      mobilePhone: userInfo.mobilePhone,
      profession: userInfo.profession,
      email: userInfo.email,
      timezone: userInfo.timezone,
      language: userInfo.language,
      custom: {
        userId: userInfo.practitionerId,
      },
    }).then((order) => {
      history.push(links.getOrderPrescriptionScreen(order.getId()));
    });
  };

  React.useEffect(() => {
    getLastOrders('prescriber').then((orders) => {
      setOrders(orders);
    });
    return () => {
      setOrders([]);
    };
  }, []);

  React.useEffect(() => {
    getLoyaltyPointsBalance(userInfo?.id).then(
      (loyaltyPointsBalance: number) => {
        setLoyaltyPointsBalance(loyaltyPointsBalance);
      }
    );
    return () => {
      setLoyaltyPointsBalance(0);
    };
  }, []);

  const handleRemoveOrder = async (id) => {
    const order = orders.find((order) => order.getId() === id);
    await editOrders([order?.deleteOrder()?.toInput(true)]);
    setOrders((prev) => prev.filter((order) => order.getId() !== id));
  };

  return (
    <ListLastOrders
      orders={orders}
      handleNewClick={handleNewClick}
      handleClickSeeMore={() => {
        history.push(`/${links.main}/${links.listOrders}`);
      }}
      handleRemoveOrder={handleRemoveOrder}
      handleRowClick={(rowIndex) => {
        if (rowIndex !== undefined) {
          history.push(
            orders?.[rowIndex]?.getOrderStatus() === 'DRAFT'
              ? links.getOrderPrescriptionScreen(orders?.[rowIndex]?.getId())
              : links.getOrderDetailsScreen(orders?.[rowIndex]?.getId())
          );
        }
      }}
      loyaltyPointsBalance={loyaltyPointsBalance}
    />
  );
}
