export default {
  affiliation: 'Patrocinio',
  agenda: 'Diario',
  appointments: 'Mis citas',
  beta: 'Beta',
  dashboard: 'Panel de control',
  files: 'Archivos',
  forgotPassword: 'contraseña olvidada',
  invoicing: 'Facturación',
  notifications: 'Notificaciones',
  pageNotFound: 'Página no encontrada',
  profile: 'Edición de perfil',
  register: 'Registro',
  services: 'Mis servicios',
  settings: {
    activity: 'General',
    main: 'configuraciones',
    notifier: 'Notificaciones',
  },
  signIn: 'Conexión',
  subscription: 'Suscripción',
  tuto: {
    intro: 'Introducción',
    subtitle: 'Tutorial',
  },
  users: 'Usuarios',
};
