import { AddressFormController, Modal, DisplayAddress } from '@kiway/shared/ui';
import * as React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Typography,
} from '@mui/material';
import { Address } from '../../../core/entities/User';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from '@kiway/shared/utils/translation';
import { IAddressAttributes } from '../../../core/entities/User';

export interface UserAddressProps {
  addresses: Address[];
  confirmNewAddress: () => void;
  confirmRemoveAddress: () => void;
  confirmUpdateAddress: () => void;
  deleteModalOpen?: boolean;
  editAddress: (addressId: string) => void;
  enableUpdates?: boolean;
  handleChangeAddress: (updates: IAddressAttributes) => void;
  modalAddress?: Address;
  modalState: string;
  newAddress: () => void;
  removeAddress: (addressId: string) => void;
  resetModal: () => void;
  setDefaultAddress: (addressId: string) => void;
}

export function UserAddress(props: UserAddressProps) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Modal
        actions={
          <>
            <Button
              variant="contained"
              color="inherit"
              onClick={props.resetModal}
            >
              {t('common:form.buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              color="minoritary"
              onClick={
                props.modalState === 'create'
                  ? props.confirmNewAddress
                  : props.modalState === 'update'
                  ? props.confirmUpdateAddress
                  : undefined
              }
              disabled={!props.modalAddress.validateAddress()}
            >
              {t('common:form.buttons.save')}
            </Button>
          </>
        }
        handleClose={props.resetModal}
        open={props.modalState !== ''}
        title={
          props.modalState === 'update'
            ? t('authentication:editProfile.address.edit.modalTitle')
            : t('authentication:editProfile.address.add.modalTitle')
        }
        disableEnforceFocus
      >
        <AddressFormController
          address={props.modalAddress.toJSON()}
          setAddress={props.handleChangeAddress}
        />
      </Modal>
      <Modal
        actions={
          <>
            <Button
              variant="contained"
              color="inherit"
              onClick={props.resetModal}
            >
              {t('common:form.buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              color="minoritary"
              onClick={props.confirmRemoveAddress}
            >
              {t('common:form.buttons.confirm')}
            </Button>
          </>
        }
        handleClose={props.resetModal}
        open={props.deleteModalOpen}
        title={t('authentication:editProfile.address.delete.modalTitle')}
        disableEnforceFocus
      >
        {t('authentication:editProfile.address.delete.modalMessage')}
      </Modal>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {props.addresses?.map((address) => (
            <Grid key={address.getId()} item xs={12} sm={6}>
              <Card
                elevation={0}
                sx={{
                  border: '1px solid',
                  borderColor: 'text.secondary',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CardContent sx={{ flex: 1 }}>
                  <DisplayAddress address={address} displayDefault={true} />
                </CardContent>
                {props.enableUpdates && (
                  <CardActions>
                    <Button
                      onClick={() => props.editAddress(address.getId())}
                      sx={{ textTransform: 'none' }}
                      size="small"
                    >
                      {t('authentication:editProfile.address.edit.button')}
                    </Button>
                    <Button
                      onClick={() => props.removeAddress(address.getId())}
                      sx={{ textTransform: 'none' }}
                      size="small"
                    >
                      {t('authentication:editProfile.address.delete.button')}
                    </Button>
                    {!address.isDefault() && (
                      <Button
                        onClick={() => props.setDefaultAddress(address.getId())}
                        sx={{ textTransform: 'none' }}
                        size="small"
                      >
                        {t('authentication:editProfile.address.default.button')}
                      </Button>
                    )}
                  </CardActions>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {props.enableUpdates && (
        <Grid item xs={12}>
          <Grid container justifyContent={'flex-end'}>
            <Button
              color="minoritary"
              variant="contained"
              onClick={props.newAddress}
              startIcon={<AddIcon />}
            >
              {t('authentication:editProfile.address.add.button')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
