import {
  basicDeleteRequest,
  basicGetRequest,
  basicPutRequest,
  getBaseURL,
  runBasicFetch,
  runFileUploadFetch,
} from '../utils/api';

const BASE_URL = getBaseURL();

export const getUserGlobalPublicPageSettings = () => {
  const url = `${BASE_URL}/minisite/settings`;
  return runBasicFetch(basicGetRequest(url), true);
};

export const editUserGlobalPublicPageSettings = (settings) => {
  const url = `${BASE_URL}/minisite/settings`;
  return runBasicFetch(basicPutRequest(url, settings), true);
};

export const verifySlug = (slug) => {
  const url = `${BASE_URL}/minisite/settings/slug/verify/${slug}`;
  return runBasicFetch(basicGetRequest(url), true);
};

export const editUserPublicPageDisplaySetting = (checked) => {
  const url = `${BASE_URL}/minisite/settings/display`;
  return runBasicFetch(basicPutRequest(url, { isEnable: checked }), true);
};

export const editUserContactDisplaySetting = (checked) => {
  const url = `${BASE_URL}/minisite/settings/contact`;
  return runBasicFetch(basicPutRequest(url, { isEnable: checked }), true);
};

export const editUserPhoneDisplaySetting = (value) => {
  const url = `${BASE_URL}/minisite/settings/phone`;
  return runBasicFetch(basicPutRequest(url, { phoneToDisplay: value }), true);
};

export const editUserPublicPageContent = (content) => {
  const url = `${BASE_URL}/minisite/settings/content`;
  return runBasicFetch(basicPutRequest(url, content), true);
};

export const deleteProfilePicture = () => {
  // eslint-disable-next-line max-len
  const url = `${BASE_URL}/storage/practitioners/publicPage/profile`;
  return runBasicFetch(basicDeleteRequest(url), true);
};

export const deleteGalleryPicture = (fileName) => {
  // eslint-disable-next-line max-len
  const url = `${BASE_URL}/storage/practitioners/publicPage/gallery/${fileName}`;
  return runBasicFetch(basicDeleteRequest(url), true);
};

export const getProfilePicture = (practitionerId = false) => {
  // eslint-disable-next-line max-len
  const url = `${BASE_URL}/storage/practitioners/publicPage/profile/raw${
    practitionerId ? '?practitionerId=' + practitionerId : ''
  }`;
  return runBasicFetch(basicGetRequest(url));
};

export const getGalleryPictures = (practitionerId = false) => {
  // eslint-disable-next-line max-len
  const url = `${BASE_URL}/storage/practitioners/publicPage/gallery${
    practitionerId ? '?practitionerId=' + practitionerId : ''
  }`;
  return runBasicFetch(basicGetRequest(url));
};

export const uploadProfilePicture = (formData) => {
  // eslint-disable-next-line max-len
  const url = `${BASE_URL}/storage/practitioners/publicPage/profile`;
  const request = {
    url: `${url}`,
    init: {
      method: 'POST',
      body: formData,
    },
  };
  return runFileUploadFetch(request);
};

export const uploadGalleryPictures = (formData) => {
  // eslint-disable-next-line max-len
  const url = `${BASE_URL}/storage/practitioners/publicPage/gallery`;
  const request = {
    url: `${url}`,
    init: {
      method: 'POST',
      body: formData,
    },
  };
  return runFileUploadFetch(request);
};

export const getMinisite = (slug) => {
  const url = `${BASE_URL}/minisite/display/${slug}`;
  return runBasicFetch(basicGetRequest(url));
};
