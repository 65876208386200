/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  TranslatableAttribute,
  KiwayLanguagesType,
} from '@kiway/shared/react-types';
import { Currency, ICurrencyAttributes } from './Currency';
import {
  IUserAttributes,
  User,
} from '@kiway/shared/features/authentication-react-compatible';
import {
  IProductAttributes,
  IProductVariationAttributes,
  Product,
  ProductVariation,
  ProductVariationType,
} from './Product';
import { CurrencyEUR } from './Currency.fixtures';
import {
  Address,
  IAddressAttributes,
} from '@kiway/shared/features/authentication-react-compatible';
import { IShippingMethodAttributes, ShippingMethod } from './ShippingMethod';
import { Customer, ICustomerAttributes } from './Customer';
import { IPaymentAttributes, Payment, PaymentMethod } from './Payment';
import { isCustomNumber } from '@kiway/shared/utils-react-compatible';
import { Country } from './Country';
import {
  IInvoiceAttributes,
  Invoice,
} from '@kiway/shared/features/invoice-react-compatible';

export type OrderStatName =
  | 'revenue'
  | 'nbOrders'
  | 'avgCart'
  | 'nbDistinctCustomers'
  | 'nbPreparedByOrderPicker'
  | 'nbShippedByOrderPicker'
  | 'ordersStatus'
  | 'ordersCompleted'
  | 'ordersCancelled';

export type OrderStatType = 'kpi' | 'currency' | 'lineChart' | 'barChart';

export type OrderStatPeriod =
  | 'none'
  | 'today'
  | 'yesterday'
  | 'week'
  | 'prevWeek'
  | 'month'
  | 'prevMonth'
  | 'quarter'
  | 'prevQuarter'
  | 'year'
  | 'prevYear';

export type OrderStat = {
  type: OrderStatType;
  name: OrderStatName;
  fromDate: Date;
  toDate: Date;
  data: any;
  prevData?: any;
  delta?: number;
  deltaValue?: number;
  period: OrderStatPeriod;
};

export interface IPriceAttributes {
  currency: ICurrencyAttributes | Currency | string;
  centAmount: number;
}
export class Price {
  protected id: string;
  protected currency: Currency;
  protected centAmount: number;
  protected allowCentDecimals: number;

  public getId(): string {
    return this.id;
  }

  public setId(id: string): Price {
    this.id = id;
    return this;
  }

  public getCurrency(): Currency {
    return this.currency;
  }

  public setCurrency(currency: Currency): Price {
    this.currency = currency;
    return this;
  }

  public getCentAmount(): number {
    return this.centAmount || 0;
  }

  public setCentAmount(centAmount: number, allowCentDecimals = 0): Price {
    this.centAmount = Number(centAmount?.toFixed(allowCentDecimals));
    return this;
  }

  public getAllowCentDecimals(): number {
    return this.allowCentDecimals;
  }

  constructor(
    obj: Partial<IPriceAttributes> & { _id?: string; id?: string },
    allowCentDecimals = 0
  ) {
    this.id = obj?.id || obj?._id;
    if (obj?.currency instanceof Currency) {
      this.currency = obj?.currency;
    } else if (typeof obj?.currency === 'string') {
      this.currency = new Currency({ id: obj?.currency });
    } else {
      this.currency = obj?.currency ? new Currency(obj?.currency) : null;
    }
    this.allowCentDecimals = allowCentDecimals;
    if (
      !obj?.currency &&
      process.env.NX_DEFAULT_CURRENCY_ID &&
      process.env.NX_DEFAULT_CURRENCY_ID !== ''
    ) {
      this.currency = CurrencyEUR.setId(process.env.NX_DEFAULT_CURRENCY_ID);
    }
    this.centAmount = isCustomNumber(obj?.centAmount)
      ? Number(obj?.centAmount?.toFixed(allowCentDecimals))
      : obj?.centAmount;
  }

  public toJSON(): any {
    return {
      _id: this.getId(),
      id: this.getId(),
      currency: this.getCurrency()?.toJSON(),
      centAmount: this.getCentAmount(),
    };
  }

  public toInput(
    avoidMultiplyBy100 = false,
    divideBy100 = false,
    gql = false
  ): any {
    return {
      _id: gql ? undefined : this.getId(),
      id: gql ? undefined : this.getId(),
      currency: this.getCurrency()?.getId(),
      centAmount: `${
        (parseFloat(
          this.getCentAmount().toFixed
            ? this.getCentAmount().toFixed(2)
            : `${this.getCentAmount()}`
        ) *
          (avoidMultiplyBy100 ? 1 : 100)) /
        (divideBy100 ? 100 : 1)
      }`,
    };
  }

  public toString(): string {
    return this.getCurrency().toString(this).replace(/\s/g, '\u00A0');
  }
}

export interface ILineItemAttributes {
  groupBy: string; // group items e.g pharmaco formula
  product:
    | string
    | (Partial<IProductAttributes> & { _id?: string; id?: string })
    | Product;
  productVariation:
    | string
    | (Partial<IProductVariationAttributes> & {
        _id?: string;
        id?: string;
      })
    | ProductVariation;
  quantity: number;
  custom?: CustomAttributes;
  modelProduct?: string;
}

export class LineItem {
  protected id: string;
  protected groupBy: string; // group items e.g pharmaco formula
  protected product: Product;
  protected productVariation: ProductVariation;
  protected quantity: number;
  protected custom: CustomAttributes;
  protected modelProduct: string;

  public getModelProduct(): string {
    return this.modelProduct;
  }

  public setModelProduct(modelProduct: string): LineItem {
    this.modelProduct = modelProduct;
    return this;
  }

  public getCustom(): CustomAttributes {
    return this.custom;
  }

  public setCustom(custom: CustomAttributes): LineItem {
    this.custom = custom;
    return this;
  }

  getId(): string {
    return this.id;
  }

  setId(id: string): LineItem {
    this.id = id;
    return this;
  }

  getGroupBy(): string {
    return this.groupBy;
  }

  setGroupBy(groupBy: string): LineItem {
    this.groupBy = groupBy;
    return this;
  }

  getProduct(): Product {
    return this.product;
  }

  setProduct(product: Product): LineItem {
    this.product = product;
    return this;
  }

  getProductVariation(): ProductVariation {
    return this.productVariation;
  }

  setProductVariation(productVariation: ProductVariation): LineItem {
    this.productVariation = productVariation;
    return this;
  }

  getQuantity(): number {
    return this.quantity;
  }

  setQuantity(quantity: number): LineItem {
    this.quantity = quantity;
    return this;
  }

  incrementQuantity(): LineItem {
    this.quantity = this.quantity + 1;
    return this;
  }

  decrementQuantity(): LineItem {
    this.quantity = this.quantity - 1;
    return this;
  }

  constructor(
    obj: Partial<ILineItemAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    this.groupBy = obj?.groupBy;
    if (typeof obj?.product === 'string') {
      this.product = new Product({ _id: obj?.product });
    } else if (obj?.product instanceof Product) {
      this.product = obj?.product;
    } else {
      this.product = new Product(obj?.product);
    }
    if (typeof obj?.productVariation === 'string') {
      this.productVariation = new ProductVariation({
        _id: obj?.productVariation,
      });
    } else if (obj?.productVariation instanceof ProductVariation) {
      this.productVariation = obj?.productVariation;
    } else {
      this.productVariation = new ProductVariation(obj?.productVariation);
    }
    this.quantity = obj?.quantity;
    this.custom = obj?.custom;
    this.modelProduct = obj?.modelProduct;
  }

  toString(lng: KiwayLanguagesType, country: Country): string {
    const price = this.getTaxedPrice(country);
    return `${this.getName()[lng]} | ${
      this.quantity
    } | ${price.totalGross.getCentAmount()} | ${price.totalNet.getCentAmount()}`;
  }

  public toInput(): any {
    return {
      _id: this.getId(),
      groupBy: this.getGroupBy(),
      quantity: `${this.getQuantity()}`,
      product: this.getProduct()?.getId(),
      productVariation: this.getProductVariation()?.getId(),
      custom: this.getCustom(),
      modelProduct: this.getModelProduct(),
    };
  }

  public toJSON(): any {
    return {
      _id: this.getId(),
      id: this.getId(),
      groupBy: this.getGroupBy(),
      quantity: this.getQuantity(),
      product: this.getProduct()?.toJSON(),
      productVariation: this.getProductVariation()?.toJSON(),
      modelProduct: this.getModelProduct(),
      custom: this.getCustom(),
    };
  }

  // Return the name to display on each line item
  getName(): TranslatableAttribute {
    return this.productVariation?.getName();
  }

  // Return the product tax rates
  getTaxRates(): TaxRate[] {
    return this.product.getTaxRates();
  }

  getTotalNetPrice(country: Country): Price {
    return this.productVariation?.getTotalNetPrice(this.quantity, country);
  }

  getTotalGrossPrice(): Price {
    return this.productVariation?.getTotalGrossPrice(this.quantity);
  }

  getTaxes(
    country: Country
  ): Array<{
    taxPercent: number;
    taxAmount: number;
    taxType: 'reduce' | 'normal';
    taxCountryCode: string;
  }> {
    return this.productVariation?.getTaxes(this.quantity, country);
  }

  // Montant à payer
  // total price with potential discount
  getTotalPrice(country: Country): Price {
    // Aujourd'hui, nous ne gérons pas les promotions donc le total à payer est égal au total net
    return this.productVariation?.getTotalNetPrice(this.quantity, country);
  }

  // price x quantity with or without tax
  getTaxedPrice(country: Country): TaxedItemPrice {
    return this.productVariation?.getTaxedPrice(this.quantity, country);
  }

  isAvailable(): boolean {
    return this.getProductVariation()?.isAvailable() ?? false;
  }
}

export type TaxedItemPrice = {
  totalNet: Price;
  totalGross: Price;
};

export type Tax = {
  taxRate: TaxRate;
  taxAmount: Price;
};

export type TaxRate = {
  name: string;
  country: string;
  percent: number;
  includes: boolean;
};

export type Coordinates = {
  lat: number;
  lng: number;
};

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface IOrderAttributes {
  orderNumber: string;
  openedAt: Date | string;
  paidAt: Date | string;
  cancelledAt: Date | string;
  startPreparationAt: Date | string;
  endPreparationAt: Date | string;
  startShipmentAt: Date | string;
  endShipmentAt: Date | string;
  shippedAt: Date | string;
  lineItems: Array<ILineItemAttributes>;
  invoicingAddress: IAddressAttributes;
  shippingAddress: IAddressAttributes;
  shippingPrice: Price; // TODO check if we can remove it
  shippingLabelStoredFile: any;
  shippingTrackingNumber: string;
  orderStatus: OrderStatus;
  paymentStatus: PaymentStatus;
  packingStatus: PackingStatus;
  shipmentStatus: ShipmentStatus;
  customer: string | ICustomerAttributes | Customer;
  prescriber: string | ICustomerAttributes | Customer;
  custom?: CustomAttributes;
  packingWeight?: number;
  shippingMethod: string | IShippingMethodAttributes | ShippingMethod;
  payment: string | IPaymentAttributes | Payment;
  preparedBy: string | IUserAttributes | User;
  sentBy: string | IUserAttributes | User;
  freezeOrder?: Partial<IOrderAttributes> & { _id?: string; id?: string };
  invoice?: string | IInvoiceAttributes | Invoice;
  deleted?: boolean;
}

export type Status =
  | GlobalStatus
  | OrderStatus
  | PaymentStatus
  | PackingStatus
  | ShipmentStatus;

export type GlobalStatus =
  | 'DRAFT'
  | 'WAITING_PAYMENT'
  | 'PENDING_PAYMENT'
  | 'WAITING_PACKING'
  | 'PENDING_PACKING'
  | 'WAITING_SHIPMENT'
  | 'PENDING_SHIPMENT'
  | 'COMPLETE'
  | 'ERROR_PACKING'
  | 'ERROR_SYNC_STATUS'
  | 'CANCELLED';
const getGlobalStatusColor = (status: GlobalStatus): StatusColor => {
  switch (status) {
    case 'COMPLETE':
      return 'success';
    case 'PENDING_PAYMENT':
    case 'PENDING_PACKING':
    case 'PENDING_SHIPMENT':
      return 'info';
    case 'CANCELLED':
    case 'ERROR_PACKING':
    case 'ERROR_SYNC_STATUS':
      return 'error';
    case 'WAITING_PAYMENT':
    case 'WAITING_PACKING':
    case 'WAITING_SHIPMENT':
      return 'warning';
    case 'DRAFT':
    default:
      return 'default';
  }
};

export type OrderStatus = 'DRAFT' | 'OPEN' | 'COMPLETE' | 'CANCELLED';
const getOrderStatusColor = (status: OrderStatus): StatusColor => {
  switch (status) {
    case 'COMPLETE':
      return 'success';
    case 'OPEN':
      return 'warning';
    case 'CANCELLED':
      return 'error';
    case 'DRAFT':
    default:
      return 'default';
  }
};

export type PaymentStatus =
  | 'WAITING'
  | 'BALANCE_DUE'
  | 'FAILED'
  | 'PENDING'
  | 'PAID'
  | 'CREDIT_OWED';
const getPaymentStatusColor = (status: PaymentStatus): StatusColor => {
  switch (status) {
    case 'PAID':
      return 'success';
    case 'CREDIT_OWED':
      return 'info';
    case 'PENDING':
    case 'BALANCE_DUE':
      return 'warning';
    case 'FAILED':
      return 'error';
    case 'WAITING':
    default:
      return 'default';
  }
};

export type PackingStatus =
  | 'WAITING'
  | 'READY'
  | 'PENDING'
  | 'PREPARED'
  | 'DELAYED';
const getPackingStatusColor = (status: PackingStatus): StatusColor => {
  switch (status) {
    case 'PREPARED':
      return 'success';
    case 'READY':
      return 'info';
    case 'PENDING':
      return 'warning';
    case 'DELAYED':
      return 'error';
    case 'WAITING':
    default:
      return 'default';
  }
};

export type ShipmentStatus =
  | 'WAITING'
  | 'READY'
  | 'PENDING'
  | 'SHIPPED'
  | 'DELAYED'
  | 'BACKORDER';
const getShipmentStatusColor = (status: ShipmentStatus): StatusColor => {
  switch (status) {
    case 'SHIPPED':
      return 'success';
    case 'READY':
      return 'info';
    case 'PENDING':
      return 'warning';
    case 'DELAYED':
    case 'BACKORDER':
      return 'error';
    case 'WAITING':
    default:
      return 'default';
  }
};

export type StatusColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

export type StatusJson<TStatus> = {
  label: TStatus;
  color: StatusColor;
};

export type CustomAttributes = {
  [name: string]: any;
};

export const ERROR_MULTIPLE_CURRENCIES =
  "Can't have multiple currencies in one Order";

export class Order {
  protected id: string;
  protected openedAt: Date;
  protected paidAt: Date;
  protected cancelledAt: Date;
  protected startPreparationAt: Date;
  protected endPreparationAt: Date;
  protected startShipmentAt: Date;
  protected endShipmentAt: Date;
  protected shippedAt: Date;
  protected orderNumber: string;
  protected lineItems: Array<LineItem>;
  protected invoicingAddress: Address;
  protected shippingAddress: Address;
  protected shippingPrice: Price;
  protected shippingLabelStoredFile: any;
  protected shippingTrackingNumber: string;
  protected orderStatus: OrderStatus;
  protected paymentStatus: PaymentStatus;
  protected packingStatus: PackingStatus;
  protected shipmentStatus: ShipmentStatus;
  protected customer: Customer;
  protected prescriber: Customer;
  protected custom: CustomAttributes;
  protected packingWeight: number;
  protected shippingMethod: ShippingMethod;
  protected payment: Payment;
  protected preparedBy: User;
  protected sentBy: User;
  protected invoice: Invoice;
  protected deleted: boolean;

  protected freezeOrder: Partial<IOrderAttributes> & {
    _id?: string;
    id?: string;
  };

  public getPackingWeight(): number {
    return this.packingWeight;
  }

  public setPackingWeight(packingWeight: number): Order {
    this.packingWeight = packingWeight;
    return this;
  }

  public getCustom(): CustomAttributes {
    return this.custom;
  }

  public setCustom(custom: CustomAttributes): Order {
    this.custom = custom;
    return this;
  }

  public getCustomer(): Customer {
    return this.customer;
  }

  public setCustomer(customer: Customer): Order {
    this.customer = customer;
    return this;
  }

  public getPrescriber(): Customer {
    return this.prescriber;
  }

  public setPrescriber(prescriber: Customer): Order {
    this.prescriber = prescriber;
    return this;
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): Order {
    this.id = id;
    return this;
  }

  public getOpenedAt(): Date {
    return this.openedAt;
  }

  public setOpenedAt(openedAt: Date): Order {
    this.openedAt = openedAt;
    return this;
  }

  public getPaidAt(): Date {
    return this.paidAt;
  }

  public setPaidAt(paidAt: Date): Order {
    this.paidAt = paidAt;
    return this;
  }

  public getCancelledAt(): Date {
    return this.cancelledAt;
  }

  public setCancelledAt(cancelledAt: Date): Order {
    this.cancelledAt = cancelledAt;
    return this;
  }

  public getStartPreparationAt(): Date {
    return this.startPreparationAt;
  }

  public setStartPreparationAt(startPreparationAt: Date): Order {
    this.startPreparationAt = startPreparationAt;
    return this;
  }

  public getEndPreparationAt(): Date {
    return this.endPreparationAt;
  }

  public setEndPreparationAt(endPreparationAt: Date): Order {
    this.endPreparationAt = endPreparationAt;
    return this;
  }

  public getStartShipmentAt(): Date {
    return this.startShipmentAt;
  }

  public setStartShipmentAt(startShipmentAt: Date): Order {
    this.startShipmentAt = startShipmentAt;
    return this;
  }

  public getEndShipmentAt(): Date {
    return this.endShipmentAt;
  }

  public setEndShipmentAt(endShipmentAt: Date): Order {
    this.endShipmentAt = endShipmentAt;
    return this;
  }

  public getShippedAt(): Date {
    return this.shippedAt;
  }

  public setShippedAt(shippedAt: Date): Order {
    this.shippedAt = shippedAt;
    return this;
  }

  public getOrderNumber(): string {
    return this.orderNumber;
  }

  public setOrderNumber(orderNumber: string): Order {
    this.orderNumber = orderNumber;
    return this;
  }

  public getLineItems(): Array<LineItem> {
    return this.lineItems;
  }

  public setLineItems(lineItems: Array<LineItem>): Order {
    this.lineItems = lineItems;
    return this;
  }

  public updateLineItemQuantity(lineItemId: string, quantity: number): Order {
    return this.setLineItems(
      this.lineItems?.map((line) => {
        if (`${line.getId()}` === `${lineItemId}`) {
          return line.setQuantity(quantity);
        }
        return line;
      })
    );
  }

  public addLineItem(
    lineItem: LineItem,
    existingCallback?: (item: LineItem) => any
  ): Order {
    const existingLine = this.lineItems?.find(
      (line) =>
        `${line.getProduct()?.getId()}` ===
          `${lineItem.getProduct()?.getId()}` &&
        `${line.getProductVariation()?.getId()}` ===
          `${lineItem.getProductVariation()?.getId()}` &&
        line.getGroupBy() === lineItem.getGroupBy()
    );
    if (existingLine) {
      if (existingCallback) {
        existingCallback(existingLine);
      }
      return this.updateLineItemQuantity(
        existingLine.getId(),
        parseFloat(`${existingLine.getQuantity()}`) +
          parseFloat(`${lineItem.getQuantity()}`)
      );
    }
    this.lineItems?.push(lineItem);
    return this;
  }

  public removeLineItem(lineItemId: string): Order {
    this.lineItems = this.lineItems?.filter(
      (lineItem) => `${lineItem.getId()}` !== lineItemId
    );
    return this;
  }

  public removeLineItemsByGroup(group: string): Order {
    this.lineItems = this.lineItems?.filter(
      (lineItem) => lineItem.getGroupBy() !== group
    );
    return this;
  }

  public getInvoicingAddress(): Address {
    return this.invoicingAddress;
  }

  public setInvoicingAddress(invoicingAddress: Address): Order {
    this.invoicingAddress = invoicingAddress;
    return this;
  }

  public getShippingAddress(): Address {
    return this.shippingAddress;
  }

  public setShippingAddress(shippingAddress: Address): Order {
    this.shippingAddress = shippingAddress;
    return this;
  }

  public getShippingPrice(): Price {
    return this.getShippingMethod()?.getPrice(
      this.getShippingAddress()?.getCountryCode(),
      this.getTotalWeight()
    );
    return this.shippingPrice;
  }

  public setShippingPrice(shippingPrice: Price): Order {
    this.shippingPrice = shippingPrice;
    return this;
  }

  public getShippingLabelStoredFile(): any {
    return this.shippingLabelStoredFile;
  }

  public setShippingLabelStoredFile(shippingLabelStoredFile: any): Order {
    this.shippingLabelStoredFile = shippingLabelStoredFile;
    return this;
  }

  public getShippingTrackingNumber(): string {
    return this.shippingTrackingNumber;
  }

  public setShippingTrackingNumber(shippingTrackingNumber: string): Order {
    this.shippingTrackingNumber = shippingTrackingNumber;
    return this;
  }

  public getNetTotalPrice(group?: string): Price {
    const nullPrice: Price = new Price({
      currency: CurrencyEUR,
      centAmount: 0,
    });
    const lineItems = group
      ? this.getLineItemsByGroup(group)
      : this.getLineItems();
    if (!lineItems || lineItems?.length <= 0) {
      return nullPrice;
    }
    return lineItems?.reduce((prev, current) => {
      const currentPrice = current.getTotalNetPrice(
        this.getShippingAddress()?.getCountry()
      );
      if (!currentPrice) {
        return prev;
      }
      if (
        prev.getCurrency()?.isDefined() &&
        currentPrice.getCurrency()?.isDefined() &&
        !currentPrice.getCurrency().equals(prev.getCurrency())
      ) {
        throw Error(ERROR_MULTIPLE_CURRENCIES);
      }
      return new Price({
        ...prev,
        currency: prev.getCurrency()?.isDefined()
          ? prev.getCurrency()
          : currentPrice.getCurrency(),
        centAmount: prev.getCentAmount() + (currentPrice?.getCentAmount() ?? 0),
      });
    }, nullPrice);
  }

  public getGrossTotalPrice(group?: string): Price {
    const nullPrice: Price = new Price({
      currency: CurrencyEUR,
      centAmount: 0,
    });
    const lineItems = group
      ? this.getLineItemsByGroup(group)
      : this.getLineItems();
    if (!lineItems || lineItems?.length <= 0) {
      return nullPrice;
    }
    return lineItems?.reduce((prev, current) => {
      const currentPrice = current.getTotalGrossPrice();
      if (!currentPrice) {
        return prev;
      }
      if (
        prev.getCurrency()?.isDefined() &&
        currentPrice.getCurrency()?.isDefined() &&
        !currentPrice.getCurrency().equals(prev.getCurrency())
      ) {
        throw Error(ERROR_MULTIPLE_CURRENCIES);
      }
      return new Price({
        ...prev,
        currency: prev.getCurrency()?.isDefined()
          ? prev.getCurrency()
          : currentPrice.getCurrency(),
        centAmount: prev.getCentAmount() + (currentPrice?.getCentAmount() ?? 0),
      });
    }, nullPrice);
  }

  public getTaxes(
    group?: string,
    discountRatio?: number
  ): Array<{
    taxPercent: number;
    taxAmount: number;
    taxType: 'reduce' | 'normal';
    taxCountryCode: string;
  }> {
    const lineItems = group
      ? this.getLineItemsByGroup(group)
      : this.getLineItems();
    if (
      !lineItems ||
      lineItems?.length <= 0 ||
      !this.getShippingAddress()?.getCountry()
    ) {
      return [];
    }
    return lineItems?.reduce(
      (prev, current) => {
        const currentTaxes = current.getTaxes(
          this.getShippingAddress()?.getCountry()
        );
        currentTaxes?.map((tax) => {
          const existingTaxIndex = prev.findIndex(
            (t) =>
              t.taxPercent === tax.taxPercent &&
              t.taxCountryCode === tax.taxCountryCode
          );
          if (existingTaxIndex >= 0) {
            prev[existingTaxIndex] = {
              ...prev[existingTaxIndex],
              taxAmount:
                prev[existingTaxIndex].taxAmount +
                tax.taxAmount * (1 - (discountRatio ?? 0)),
            };
          } else {
            prev.push(tax as any);
          }
        });
        return prev;
      },
      group
        ? []
        : [
            {
              taxPercent: 23,
              taxAmount: Math.round(
                this.getShippingPrice()?.getCentAmount() -
                  this.getShippingPrice()?.getCentAmount() / 1.23
              ),
              taxType: 'normal',
              taxCountryCode: 'PT',
            },
          ]
    );
  }

  public getStatus(): GlobalStatus {
    if (this.orderStatus === 'DRAFT') {
      if (
        this.paymentStatus === 'WAITING' &&
        this.packingStatus === 'WAITING' &&
        this.shipmentStatus === 'WAITING'
      ) {
        return 'DRAFT';
      } else {
        return 'ERROR_SYNC_STATUS';
      }
    } else if (this.orderStatus === 'OPEN') {
      if (
        this.paymentStatus === 'BALANCE_DUE' &&
        this.packingStatus === 'WAITING' &&
        this.shipmentStatus === 'WAITING'
      ) {
        return 'WAITING_PAYMENT';
      } else if (
        this.paymentStatus === 'PENDING' &&
        this.packingStatus === 'WAITING' &&
        this.shipmentStatus === 'WAITING'
      ) {
        return 'PENDING_PAYMENT';
      } else {
        if (
          this.paymentStatus === 'PAID' &&
          this.packingStatus === 'READY' &&
          this.shipmentStatus === 'WAITING'
        ) {
          return 'WAITING_PACKING';
        } else if (
          this.paymentStatus === 'PAID' &&
          this.packingStatus === 'PENDING' &&
          this.shipmentStatus === 'WAITING'
        ) {
          return 'PENDING_PACKING';
        } else if (
          this.paymentStatus === 'PAID' &&
          this.packingStatus === 'PREPARED' &&
          this.shipmentStatus === 'READY'
        ) {
          return 'WAITING_SHIPMENT';
        } else if (
          this.paymentStatus === 'PAID' &&
          this.packingStatus === 'PREPARED' &&
          this.shipmentStatus === 'PENDING'
        ) {
          return 'PENDING_SHIPMENT';
        } else if (
          this.paymentStatus === 'PAID' &&
          this.packingStatus === 'PREPARED' &&
          this.shipmentStatus === 'SHIPPED'
        ) {
          return 'COMPLETE';
        } else {
          return 'ERROR_SYNC_STATUS';
        }
      }
    } else if (this.orderStatus === 'CANCELLED') {
      return 'CANCELLED';
    } else if (this.orderStatus === 'COMPLETE') {
      if (
        this.paymentStatus === 'PAID' &&
        this.packingStatus === 'PREPARED' &&
        this.shipmentStatus === 'SHIPPED'
      ) {
        return 'COMPLETE';
      } else {
        return 'ERROR_SYNC_STATUS';
      }
    } else {
      return 'ERROR_SYNC_STATUS';
    }
    return;
  }

  public getStatusJSON(): StatusJson<Status> {
    return {
      label: this.getStatus(),
      color: getGlobalStatusColor(this.getStatus()),
    };
  }

  public getOrderStatus(): OrderStatus {
    return this.orderStatus;
  }

  public getOrderStatusJSON(): StatusJson<OrderStatus> {
    return {
      label: this.orderStatus,
      color: getOrderStatusColor(this.orderStatus),
    };
  }

  public setOrderStatus(orderStatus: OrderStatus): Order {
    this.orderStatus = orderStatus;
    return this;
  }

  public getPaymentStatus(): PaymentStatus {
    return this.paymentStatus;
  }

  public getPaymentStatusJSON(): StatusJson<PaymentStatus> {
    return {
      label: this.paymentStatus,
      color: getPaymentStatusColor(this.paymentStatus),
    };
  }

  public setPaymentStatus(paymentStatus: PaymentStatus): Order {
    this.paymentStatus = paymentStatus;
    return this;
  }

  public getPackingStatus(): PackingStatus {
    return this.packingStatus;
  }

  public getPackingStatusJSON(): StatusJson<PackingStatus> {
    return {
      label: this.packingStatus,
      color: getPackingStatusColor(this.packingStatus),
    };
  }

  public setPackingStatus(packingStatus: PackingStatus): Order {
    this.packingStatus = packingStatus;
    return this;
  }

  public getShipmentStatus(): ShipmentStatus {
    return this.shipmentStatus;
  }

  public getShipmentStatusJSON(): StatusJson<ShipmentStatus> {
    return {
      label: this.shipmentStatus,
      color: getShipmentStatusColor(this.shipmentStatus),
    };
  }

  public setShipmentStatus(shipmentStatus: ShipmentStatus): Order {
    this.shipmentStatus = shipmentStatus;
    return this;
  }

  public getShippingMethod(): ShippingMethod {
    return this.shippingMethod;
  }

  public setShippingMethod(shippingMethod: ShippingMethod): Order {
    this.shippingMethod = shippingMethod;
    return this;
  }

  public getPayment(): Payment {
    return this.payment;
  }

  public setPayment(payment: Payment): Order {
    this.payment = payment;
    return this;
  }

  public getPaymentMethod(): PaymentMethod {
    return this.payment?.getPaymentMethod();
  }

  public getPreparedBy(): User {
    return this.preparedBy;
  }

  public setPreparedBy(preparedBy: User | string | IUserAttributes): Order {
    if (preparedBy) {
      if (typeof preparedBy === 'string') {
        this.preparedBy = new User({ id: preparedBy });
      } else if (preparedBy instanceof User) {
        this.preparedBy = preparedBy;
      } else {
        this.preparedBy = new User(preparedBy);
      }
    }
    return this;
  }

  public getSentBy(): User {
    return this.sentBy;
  }

  public setSentBy(sentBy: User | string | IUserAttributes): Order {
    if (sentBy) {
      if (typeof sentBy === 'string') {
        this.sentBy = new User({ id: sentBy });
      } else if (sentBy instanceof User) {
        this.sentBy = sentBy;
      } else {
        this.sentBy = new User(sentBy);
      }
    }
    return this;
  }

  public doFreezeOrder(): Order {
    this.freezeOrder = this.toJSON();
    return this;
  }

  public getFreezeOrder(): Partial<IOrderAttributes> & {
    _id?: string;
    id?: string;
  } {
    return this.freezeOrder;
  }

  public getInvoice(): Invoice {
    return this.invoice;
  }

  public setInvoice(invoice: Invoice): Order {
    this.invoice = invoice;
    return this;
  }

  public isDeleted(): boolean {
    return this.deleted;
  }

  public setDeleted(deleted: boolean): Order {
    this.deleted = deleted;
    return this;
  }

  public canBeDeleted(): boolean {
    return this.orderStatus === 'DRAFT';
  }

  public deleteOrder(): Order {
    this.deleted = true;
    return this;
  }

  constructor(
    baseObj: Partial<IOrderAttributes> & { _id?: string; id?: string }
  ) {
    let obj = baseObj;
    if (
      baseObj?.freezeOrder &&
      (baseObj?.freezeOrder?._id || baseObj?.freezeOrder?.id)
    ) {
      const customer = baseObj?.freezeOrder?.customer;
      // const invoicingAddress = baseObj?.freezeOrder?.invoicingAddress;
      const lineItems = baseObj?.freezeOrder?.lineItems;
      const orderNumber = baseObj?.freezeOrder?.orderNumber;
      const prescriber = baseObj?.freezeOrder?.prescriber;
      // const shippingAddress = baseObj?.freezeOrder?.shippingAddress;
      const shippingMethod = baseObj?.freezeOrder?.shippingMethod;
      obj = {
        ...baseObj,
        customer,
        // invoicingAddress,
        lineItems,
        orderNumber,
        prescriber,
        // shippingAddress,
        shippingMethod,
      };
    }
    this.id = obj?.id || obj?._id;
    this.orderNumber = obj?.orderNumber;
    this.openedAt =
      typeof obj?.openedAt === 'string'
        ? new Date(obj?.openedAt)
        : obj?.openedAt;
    this.paidAt =
      typeof obj?.paidAt === 'string' ? new Date(obj?.paidAt) : obj?.paidAt;
    this.cancelledAt =
      typeof obj?.cancelledAt === 'string'
        ? new Date(obj?.cancelledAt)
        : obj?.cancelledAt;
    this.startPreparationAt =
      typeof obj?.startPreparationAt === 'string'
        ? new Date(obj?.startPreparationAt)
        : obj?.startPreparationAt;
    this.endPreparationAt =
      typeof obj?.endPreparationAt === 'string'
        ? new Date(obj?.endPreparationAt)
        : obj?.endPreparationAt;
    this.startShipmentAt =
      typeof obj?.startShipmentAt === 'string'
        ? new Date(obj?.startShipmentAt)
        : obj?.startShipmentAt;
    this.endShipmentAt =
      typeof obj?.endShipmentAt === 'string'
        ? new Date(obj?.endShipmentAt)
        : obj?.endShipmentAt;
    this.shippedAt =
      typeof obj?.shippedAt === 'string'
        ? new Date(obj?.shippedAt)
        : obj?.shippedAt;
    this.lineItems = obj?.lineItems?.map((item) => new LineItem(item));
    this.invoicingAddress = new Address(obj?.invoicingAddress);
    this.shippingAddress = new Address(obj?.shippingAddress);
    this.shippingPrice = obj?.shippingPrice;
    this.shippingLabelStoredFile = obj?.shippingLabelStoredFile;
    this.shippingTrackingNumber = obj?.shippingTrackingNumber;
    this.orderStatus = obj?.orderStatus ?? 'DRAFT';
    this.paymentStatus = obj?.paymentStatus ?? 'WAITING';
    this.packingStatus = obj?.packingStatus ?? 'WAITING';
    this.shipmentStatus = obj?.shipmentStatus ?? 'WAITING';
    if (obj?.customer) {
      if (typeof obj?.customer === 'string') {
        this.customer = new Customer({ id: obj?.customer });
      } else if (obj?.customer instanceof Customer) {
        this.customer = obj?.customer;
      } else {
        this.customer = new Customer(obj?.customer);
      }
    }
    if (obj?.prescriber) {
      if (typeof obj?.prescriber === 'string') {
        this.prescriber = new Customer({ id: obj?.prescriber });
      } else if (obj?.prescriber instanceof Customer) {
        this.prescriber = obj?.prescriber;
      } else {
        this.prescriber = new Customer(obj?.prescriber);
      }
    }
    this.custom = obj?.custom;
    this.packingWeight = obj?.packingWeight ?? 0;
    if (obj?.shippingMethod) {
      if (typeof obj?.shippingMethod === 'string') {
        this.shippingMethod = new ShippingMethod({ id: obj?.shippingMethod });
      } else if (obj?.shippingMethod instanceof ShippingMethod) {
        this.shippingMethod = obj?.shippingMethod;
      } else {
        this.shippingMethod = new ShippingMethod(obj?.shippingMethod);
      }
    } else {
      this.shippingMethod = null;
    }
    if (obj?.payment) {
      if (typeof obj?.payment === 'string') {
        this.payment = new Payment({ id: obj?.payment });
      } else if (obj?.payment instanceof Payment) {
        this.payment = obj?.payment;
      } else {
        this.payment = new Payment(obj?.payment);
      }
    }
    if (obj?.invoice) {
      if (typeof obj?.invoice === 'string') {
        this.invoice = new Invoice({ id: obj?.invoice });
      } else if (obj?.invoice instanceof Invoice) {
        this.invoice = obj?.invoice;
      } else {
        this.invoice = new Invoice(obj?.invoice);
      }
    }
    this.setPreparedBy(obj?.preparedBy);
    this.setSentBy(obj?.sentBy);
    this.deleted = obj?.deleted ?? false;
  }

  // Montant à payer
  getTotalPrice(group?: string, withoutShipping?: boolean): Price {
    const price = this.getDiscountedNetTotalPrice(group);
    if (
      group ||
      withoutShipping ||
      !this.getShippingMethod() ||
      !this.getShippingMethod()?.getPrice(
        this.getShippingAddress()?.getCountryCode(),
        this.getTotalWeight()
      )
    ) {
      return price;
    }
    return new Price({
      centAmount:
        price.getCentAmount() +
        this.getShippingMethod()
          ?.getPrice(
            this.getShippingAddress()?.getCountryCode(),
            this.getTotalWeight()
          )
          ?.getCentAmount(),
      currency: price.getCurrency(),
    });
  }

  getTaxedTotalPrice(): TaxedItemPrice {
    return {
      totalGross: this.getGrossTotalPrice(),
      totalNet: this.getNetTotalPrice(),
    };
  }

  getNbOfGroups(): number {
    return this.getGroups()?.length;
  }

  getGroups(): Array<string> {
    return this.lineItems
      ?.reduce((prev, current) => [...prev, current.getGroupBy()], [])
      ?.filter((value, index, self) => self.indexOf(value) === index);
  }

  getLineItemsByGroup(group: string): Array<LineItem> {
    return this.lineItems?.filter((item) => item.getGroupBy() === group);
  }

  getTotalQuantityByGroup(group: string): number {
    return this.getLineItemsByGroup(group)?.reduce((prev, current) => {
      return prev + current.getQuantity();
    }, 0);
  }

  getItemsWeight(): number {
    return this.lineItems?.reduce((prev, current) => {
      return (
        prev +
        (current.getProductVariation()?.getWeight() || 1) *
          current.getQuantity()
      );
    }, 0);
  }

  getTotalWeight(): number {
    return this.getPackingWeight() + this.getItemsWeight();
  }

  togglePreparedItem(lineItemId: string): void {
    this.setCustom({
      ...(this.getCustom() ?? {}),
      preparedItems: {
        ...(this.getCustom()?.preparedItems ?? {}),
        [lineItemId]:
          this.getCustom()?.preparedItems?.[lineItemId] !== undefined
            ? !this.getCustom()?.preparedItems?.[lineItemId]
            : true,
      },
    });
  }

  canFinishPreparation(): boolean {
    const lineItemIds = this.getLineItems()?.map((lineItem) =>
      lineItem.getId()
    );
    const orderCustomPrepared = this?.getCustom()?.preparedItems ?? {};

    return (
      lineItemIds.reduce((prev, current) => {
        return prev && orderCustomPrepared?.[current];
      }, true) &&
      this.orderStatus === 'OPEN' &&
      this.paymentStatus === 'PAID' &&
      this.packingStatus === 'PENDING' &&
      this.shipmentStatus === 'WAITING'
    );
  }

  isLineAllItemsAvailable(): boolean {
    return this.getLineItems()?.reduce(
      (prev, current) => prev && current.isAvailable(),
      true
    );
  }

  canOpenOrder(): boolean {
    return (
      this.isLineAllItemsAvailable() &&
      this.getShippingAddress().validateAddress() &&
      this.getInvoicingAddress().validateAddress() &&
      !!this.getShippingMethod()?.getId()
    );
  }

  openOrder(): Order {
    if (this.canOpenOrder()) {
      this.openedAt = new Date();
      this.orderStatus = 'OPEN';
      this.paymentStatus = 'BALANCE_DUE';
    }
    return this;
  }

  canCancelOrder(): boolean {
    if (
      (this.orderStatus === 'DRAFT' ||
        (this.orderStatus === 'OPEN' &&
          this.paymentStatus === 'BALANCE_DUE')) &&
      this.packingStatus === 'WAITING' &&
      this.shipmentStatus === 'WAITING' &&
      (!this.getPayment()?.getId() ||
        this.getPayment()?.getStatus() === 'DRAFT')
    ) {
      return true;
    }
    return false;
  }

  cancelOrder(): Order {
    if (this.canCancelOrder()) {
      this.orderStatus = 'CANCELLED';
      this.cancelledAt = new Date();
    }
    return this;
  }

  canRenewOrder(): boolean {
    if (this.orderStatus !== 'DRAFT') {
      return true;
    }
    return false;
  }

  renewOrder(): Order {
    if (this.canRenewOrder()) {
      return (
        // Pour éviter des bugs lors du renouvellement on enlève quelques infos sur la livraison et le customer
        new Order({})
          .setLineItems(this.getLineItems())
          // .setInvoicingAddress(this.getInvoicingAddress())
          // .setShippingAddress(this.getShippingAddress())
          // .setShippingMethod(this.getShippingMethod())
          .setPrescriber(this.getPrescriber())
          // .setCustomer(this.getCustomer())
          .setCustom({
            ...this.getCustom(),
            preparedItems: undefined,
            customerId: undefined,
            invoicingAddressId: undefined,
            shippingAddressId: undefined,
            loyaltyProgramOption: undefined,
          })
      );
    }
    return this;
  }

  isInvoicingAddressSameAsShippingAddress(): boolean {
    return this.custom?.sameInvoicingAndShippingAddress ?? true;
  }

  getShippingMethodFilter(): (shippingMethod?: ShippingMethod) => boolean {
    if (!this.getShippingAddress()?.validateAddress()) {
      return () => false;
    }
    return (shippingMethod: ShippingMethod) => {
      return (
        shippingMethod
          ?.getCountryZone()
          ?.getCountries()
          ?.map((country) => country.getCode()?.toLowerCase())
          ?.includes(
            this.getShippingAddress()?.getCountryCode()?.toLowerCase()
          ) &&
        shippingMethod.getPrice(
          this.getShippingAddress()?.getCountryCode(),
          this.getTotalWeight()
        ) !== undefined
      );
    };
  }

  canStartPreparation(): boolean {
    return (
      this.orderStatus === 'OPEN' &&
      this.paymentStatus === 'PAID' &&
      this.packingStatus === 'READY' &&
      this.shipmentStatus === 'WAITING'
    );
  }

  canDoPreparation(): boolean {
    return (
      this.orderStatus === 'OPEN' &&
      this.paymentStatus === 'PAID' &&
      (this.packingStatus === 'READY' || this.packingStatus === 'PENDING') &&
      this.shipmentStatus === 'WAITING'
    );
  }

  startPreparation(): Order {
    if (this.canStartPreparation()) {
      this.startPreparationAt = new Date();
      this.packingStatus = 'PENDING';
    }
    return this;
  }

  endPreparation(user: string | User | IUserAttributes): Order {
    if (this.canFinishPreparation()) {
      this.endPreparationAt = new Date();
      this.packingStatus = 'PREPARED';
      this.shipmentStatus = 'READY';
      this.setPreparedBy(user);
    }
    return this;
  }

  getPreparationProgression(): number {
    const result =
      (Object.values(this.getCustom()?.preparedItems ?? {})?.filter(
        (item) => item
      ).length ?? 1) / (this.getLineItems()?.length ?? 1);
    return Number.isNaN(result) || !Number.isFinite(result) ? 0 : result;
  }

  canStartShipment(): boolean {
    return (
      this.orderStatus === 'OPEN' &&
      this.paymentStatus === 'PAID' &&
      this.packingStatus === 'PREPARED' &&
      this.shipmentStatus === 'READY'
    );
  }

  canFinishShipment(): boolean {
    return (
      (this.getTotalWeight() ?? 0) > 0 &&
      (this.getPackingWeight() ?? 0) >= 0 &&
      this.getTotalWeight() >= this.getItemsWeight() &&
      (this.shippingTrackingNumber ?? '') !== ''
    );
  }

  canDoShipment(): boolean {
    return (
      this.orderStatus === 'OPEN' &&
      this.paymentStatus === 'PAID' &&
      this.packingStatus === 'PREPARED' &&
      (this.shipmentStatus === 'READY' || this.shipmentStatus === 'PENDING')
    );
  }

  startShipment(): Order {
    if (this.canStartShipment()) {
      this.startShipmentAt = new Date();
      this.shipmentStatus = 'PENDING';
    }
    return this;
  }

  endShipment(user: string | User | IUserAttributes): Order {
    if (this.canFinishShipment()) {
      this.endShipmentAt = new Date();
      this.shipmentStatus = 'SHIPPED';
      this.orderStatus = 'COMPLETE';
      this.setSentBy(user);
    }
    return this;
  }

  canSendPackage(): boolean {
    return (
      this.orderStatus === 'OPEN' &&
      this.paymentStatus === 'PAID' &&
      this.packingStatus === 'PREPARED' &&
      this.shipmentStatus === 'READY'
    );
  }

  sendPackage(): Order {
    if (this.canSendPackage()) {
      this.shippedAt = new Date();
      this.shipmentStatus = 'SHIPPED';
    }
    return this;
  }

  getVariationTypes(): ProductVariationType[] {
    return this.getLineItems()
      ?.map((item) => item?.getProductVariation()?.getProductVariationType())
      ?.reduce((prev, current) => {
        if (prev.map((item) => item.getId()).includes(current.getId())) {
          return prev;
        }
        return [...prev, current];
      }, []);
  }

  public toDatatableRow(): any {
    return {
      _id: this.getId(),
      openedAt: this.getOpenedAt(),
      paidAt: this.getPaidAt(),
      cancelledAt: this.getCancelledAt(),
      customer: this.getCustomer()?.getFullName(),
      prescriber: this.getPrescriber()?.getFullName(),
      orderNumber: this.getOrderNumber(),
      status: this.getStatusJSON(),
      orderStatus: this.getOrderStatusJSON(),
      paymentStatus: this.getPaymentStatusJSON(),
      packingStatus: this.getPackingStatusJSON(),
      shipmentStatus: this.getShipmentStatusJSON(),
      totalPrice: this.getTotalPrice()?.toString(),
      totalGrossPrice: this.getGrossTotalPrice()?.toString(),
      totalNetPrice: this.getNetTotalPrice()?.toString(),
      shippingPrice: this.getShippingPrice()?.toString(),
      shippingLabelStoredFile: this.getShippingLabelStoredFile(),
      shippingTrackingNumber: this.getShippingTrackingNumber(),
      shippingMethod: this.getShippingMethod()?.toJSON(),
      paymentMethod: this.getPaymentMethod(),
      payment: this.getPayment()?.toJSON(),
      variationTypes: this.getVariationTypes()
        ?.map((item) => item.getShortcode())
        ?.sort()
        ?.join(', '),
      custom: this.getCustom(),
      nbItems: this.getLineItems()?.length,
      nbGroups: this.getNbOfGroups(),
      nbOfBags: this.getGroups()
        ?.map((group) =>
          this.getCustom()?.[group]?.pharmaceuticalForm === 'small_bag'
            ? (this.getCustom()?.[group]?.simplifiedDecoction
                ? parseInt(this.getCustom()?.[group]?.simplifiedDecoctionValue)
                : this.getCustom()?.[group]?.nbOfBags) ?? 1
            : 0
        )
        ?.reduce((prev, current) => Number(prev) + Number(current), 0),
      deleted: this.isDeleted() ?? false,
    };
  }

  public toInput(client?: boolean): any {
    const treatAddress = (address) => {
      const { __typename, ...rest } = address;
      let result = rest;
      if (client && rest.country) {
        result = {
          ...result,
          country: undefined,
        };
      }
      return client ? result : address;
    };
    return {
      _id: this.getId(),
      openedAt: this.getOpenedAt(),
      paidAt: this.getPaidAt(),
      cancelledAt: this.getCancelledAt(),
      startPreparationAt: this.getStartPreparationAt(),
      endPreparationAt: this.getEndPreparationAt(),
      startShipmentAt: this.getStartShipmentAt(),
      endShipmentAt: this.getEndShipmentAt(),
      orderNumber: this.getOrderNumber(),
      orderStatus: this.getOrderStatus(),
      paymentStatus: this.getPaymentStatus(),
      packingStatus: this.getPackingStatus(),
      shipmentStatus: this.getShipmentStatus(),
      lineItems: this.getLineItems()?.map((item) => item.toInput()),
      invoicingAddress: treatAddress(this.getInvoicingAddress()?.toJSON()),
      shippingAddress: treatAddress(this.getShippingAddress()?.toJSON()),
      shippingLabelStoredFile: this.getShippingLabelStoredFile(),
      shippingTrackingNumber: this.getShippingTrackingNumber(),
      customer: this.getCustomer()?.getId(),
      prescriber: this.getPrescriber()?.getId(),
      custom: this.getCustom(),
      packingWeight: `${this.getPackingWeight()}`,
      shippingMethod: this.getShippingMethod()?.getId() ?? null,
      payment: this.getPayment()?.getId(),
      preparedBy: this.getPreparedBy()?.getId(),
      sentBy: this.getSentBy()?.getId(),
      deleted: this.isDeleted() ?? false,
    };
  }

  public toJSON(): any {
    return {
      _id: this.getId(),
      id: this.getId(),
      orderNumber: this.getOrderNumber(),
      openedAt: this.getOpenedAt(),
      cancelledAt: this.getCancelledAt(),
      lineItems: this.getLineItems()?.map((item) => item.toJSON()),
      invoicingAddress: this.getInvoicingAddress()?.toJSON(),
      shippingAddress: this.getShippingAddress()?.toJSON(),
      shippingMethod: this.getShippingMethod(),
      shippingPrice: this.getShippingPrice(),
      shippingLabelStoredFile: this.getShippingLabelStoredFile(),
      shippingTrackingNumber: this.getShippingTrackingNumber(),
      orderStatus: this.getOrderStatus(),
      paymentStatus: this.getPaymentStatus(),
      packingStatus: this.getPackingStatus(),
      shipmentStatus: this.getShipmentStatus(),
      customer: this.getCustomer(),
      prescriber: this.getPrescriber(),
      paymentMethod: this.getPaymentMethod(),
      payment: this.getPayment(),
      custom: this.getCustom(),
      packingWeight: this.getPackingWeight(),
      invoice: this.getInvoice()?.toJSON(),
      deleted: this.isDeleted() ?? false,
    };
  }

  public canGoToCommentSection(): boolean {
    return this.isLineAllItemsAvailable();
  }

  public reasonCantGoToCommentSection(): string | null {
    if (this.canGoToCommentSection()) {
      return null;
    }
    if (!this.isLineAllItemsAvailable()) {
      return 'notAllItemsAvailable';
    }
    return 'unknown';
  }

  canValidateOrder(): boolean {
    return this.canOpenOrder();
  }

  reasonCantValidateOrder(): string | null {
    if (this.canValidateOrder()) {
      return null;
    }
    if (!this.isLineAllItemsAvailable()) {
      return 'notAllItemsAvailable';
    }
    if (
      !this.getShippingAddress().validateAddress() ||
      !this.getInvoicingAddress().validateAddress()
    ) {
      return 'invalidAddress';
    }
    if (!this.getShippingMethod()?.getId()) {
      return 'noShippingMethod';
    }
    return 'unknown';
  }

  getAvailableLoyaltyProgramOptions(pointsBalance: number): string[] {
    const options = [
      'pay5Percent',
      'pay10Percent',
      'pay15Percent',
      'pay20Percent',
    ];
    const optionsAmount = options.map((option) => ({
      label: option,
      value: this.getDiscountAmountFromLoyaltyProgramOption(option),
    }));
    return [
      'addPoints',
      ...optionsAmount
        .filter((option) => Math.round(option.value) <= pointsBalance)
        .map((option) => option.label),
    ];
  }

  getLoyaltyProgramOption(): string {
    return this.getCustom()?.loyaltyProgramOption ?? 'addPoints';
  }

  getDiscountAmountFromLoyaltyProgramOption(
    discountType: string,
    group?: string
  ): number {
    const netTotalPrice = this.getNetTotalPrice(group);
    switch (discountType) {
      case 'addPoints':
        return 0;
      case 'pay5Percent':
        return netTotalPrice.getCentAmount() * 0.05;
      case 'pay10Percent':
        return netTotalPrice.getCentAmount() * 0.1;
      case 'pay15Percent':
        return netTotalPrice.getCentAmount() * 0.15;
      case 'pay20Percent':
        return netTotalPrice.getCentAmount() * 0.2;
      default:
        return 0;
    }
  }

  getDiscountAmount(
    numberOrPrice: 'price' | 'number',
    group?: string
  ): Price | number {
    const discountType = this.getLoyaltyProgramOption();
    const netTotalPrice = this.getNetTotalPrice(group);
    const amount = this.getDiscountAmountFromLoyaltyProgramOption(
      discountType,
      group
    );
    return numberOrPrice === 'number'
      ? amount
      : new Price({
          centAmount: amount,
          currency: netTotalPrice.getCurrency(),
        });
  }

  getDiscountedNetTotalPrice(group?: string): Price {
    const netTotalPrice = this.getNetTotalPrice(group);
    const discountAmount: number = this.getDiscountAmount(
      'number',
      group
    ) as number;
    return new Price({
      centAmount: netTotalPrice.getCentAmount() - discountAmount,
      currency: netTotalPrice.getCurrency(),
    });
  }

  getDiscountedGrossTotalPrice(group?: string): Price {
    const grossTotalPrice = this.getGrossTotalPrice(group);
    const discountAmount: number = this.getDiscountAmount(
      'number',
      group
    ) as number;
    return new Price({
      centAmount: grossTotalPrice.getCentAmount() - discountAmount,
      currency: grossTotalPrice.getCurrency(),
    });
  }
}
