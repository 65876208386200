import { UserGraphQLProvider } from '../../dataproviders/graphql-client/UserGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllUsers } from '../../core/use_cases/GetAllUsers';
import { User } from '../../core/entities/User';
import { Login } from '../../core/use_cases/Login';
import { Logout } from '../../core/use_cases/Logout';
import {
  LoginResult,
  RefreshToken,
  Setup2FA,
  UserRegister,
  UserRegisterType,
} from '../../core/use_cases/UserProvider';
import { EditMyProfile } from '../../core/use_cases/EditMyProfile';

class UsersGateway {
  protected static instance: UsersGateway;
  protected userProvider: UserGraphQLProvider;
  protected getAllUsers: GetAllUsers;
  protected login: Login;
  protected logout: Logout;
  protected editMyProfile: EditMyProfile;

  public static getInstance(client: ApolloClient<any>): UsersGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new UsersGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const userProvider = new UserGraphQLProvider(client);
    this.userProvider = userProvider;
    this.getAllUsers = new GetAllUsers(userProvider);
    this.login = new Login(userProvider);
    this.logout = new Logout(userProvider);
    this.editMyProfile = new EditMyProfile(userProvider);
    this.findAll = this.findAll.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.checkToken = this.checkToken.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.get2FAUrl = this.get2FAUrl.bind(this);
    this.enable2FA = this.enable2FA.bind(this);
    this.disable2FA = this.disable2FA.bind(this);
    this.sendForgotPassword = this.sendForgotPassword.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.listRefreshTokens = this.listRefreshTokens.bind(this);
    this.deleteRefreshToken = this.deleteRefreshToken.bind(this);
    this.deleteAllRefreshTokens = this.deleteAllRefreshTokens.bind(this);
    this.registerUser = this.registerUser.bind(this);
    this.findOneById = this.findOneById.bind(this);
    UsersGateway.instance = this;
  }

  async findAll(): Promise<User[]> {
    return await this.getAllUsers.execute();
  }

  async signIn(
    email: string,
    password: string,
    rememberMe?: boolean
  ): Promise<LoginResult> {
    return await this.login.execute(email, password, rememberMe);
  }

  async signOut(): Promise<boolean> {
    return await this.logout.execute();
  }

  async checkToken(): Promise<LoginResult> {
    return await this.userProvider.checkToken();
  }

  async listRefreshTokens(): Promise<Array<RefreshToken>> {
    return await this.userProvider.listRefreshTokens();
  }

  async editProfile(user: User): Promise<User> {
    return await this.editMyProfile.execute(user);
  }

  async get2FAUrl(email?: string): Promise<Setup2FA> {
    return await this.userProvider.get2FAUrl(email);
  }

  async sendForgotPassword(email?: string): Promise<boolean> {
    return await this.userProvider.sendForgotPassword(email);
  }

  async changeEmail(email: string): Promise<User> {
    return await this.userProvider.changeEmail(email);
  }

  async enable2FA(code: string, secret: string): Promise<User> {
    return await this.userProvider.enable2FA(code, secret);
  }

  async disable2FA(code: string, methodId: string): Promise<User> {
    return await this.userProvider.disable2FA(code, methodId);
  }

  async deleteRefreshToken(tokenId: string): Promise<Array<RefreshToken>> {
    return await this.userProvider.deleteRefreshToken(tokenId);
  }

  async deleteAllRefreshTokens(): Promise<Array<RefreshToken>> {
    return await this.userProvider.deleteAllRefreshTokens();
  }

  async registerUser(
    user: UserRegister,
    type: UserRegisterType
  ): Promise<string> {
    return await this.userProvider.registerUser(user, type);
  }

  async findOneById(id: string): Promise<User> {
    return await this.userProvider.findOneById(id);
  }
}

export function useUsersGateway() {
  const client = useApolloClient();
  const usersGateway = UsersGateway.getInstance(client);
  return {
    findAll: usersGateway.findAll,
    signIn: usersGateway.signIn,
    signOut: usersGateway.signOut,
    checkToken: usersGateway.checkToken,
    editProfile: usersGateway.editProfile,
    get2FAUrl: usersGateway.get2FAUrl,
    sendForgotPassword: usersGateway.sendForgotPassword,
    changeEmail: usersGateway.changeEmail,
    enable2FA: usersGateway.enable2FA,
    disable2FA: usersGateway.disable2FA,
    listRefreshTokens: usersGateway.listRefreshTokens,
    deleteRefreshToken: usersGateway.deleteRefreshToken,
    deleteAllRefreshTokens: usersGateway.deleteAllRefreshTokens,
    registerUser: usersGateway.registerUser,
    findOneById: usersGateway.findOneById,
  };
}
