import React, { useState, useEffect } from 'react';
import { Divider, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { Plant } from '../../../../core/entities/Plant';
import { MetadataCard } from './MetadataCard';
import { useTranslation } from '@kiway/shared/utils/translation';
import { kiwayLanguages, KiwayLanguagesType } from '@kiway/shared/react-types';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SpaIcon from '@mui/icons-material/Spa';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import { ProductMetadata } from '../../../../core/entities/ProductMetadata';

interface InfoPlantProps {
  plant: Plant;
}

export function InfoPlant(props: InfoPlantProps) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1} textAlign="center">
      <Grid item xs={6}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: '10px 30px',
                height: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {props.plant.getCustom()?.sellAvailable ? (
                <>
                  <Tooltip
                    title={t(
                      props.plant.isPlant()
                        ? 'pharmaco:plant.availablePlant'
                        : 'pharmaco:plant.unavailablePlant'
                    )}
                  >
                    <SpaIcon
                      color={props.plant.isPlant() ? 'primary' : 'disabled'}
                    />
                  </Tooltip>

                  <Tooltip
                    title={t(
                      props.plant.isConcentratedPowder()
                        ? 'pharmaco:plant.availableConcentratedPowder'
                        : 'pharmaco:plant.unavailableConcentratedPowder'
                    )}
                  >
                    <BlurOnIcon
                      color={
                        props.plant.isConcentratedPowder()
                          ? 'primary'
                          : 'disabled'
                      }
                    />
                  </Tooltip>

                  <Tooltip
                    title={t(
                      props.plant.isToxic()
                        ? 'pharmaco:plant.toxic'
                        : 'pharmaco:plant.noToxic'
                    )}
                  >
                    <DangerousOutlinedIcon
                      color={props.plant.isToxic() ? 'primary' : 'disabled'}
                    />
                  </Tooltip>

                  <Tooltip
                    title={t(
                      props.plant
                        .getVariations()
                        ?.map((variation) => variation.isAvailable() === true)
                        ? 'pharmaco:variations.available'
                        : 'pharmaco:variations.unavailable'
                    )}
                  >
                    <ShoppingCartIcon
                      color={
                        props.plant
                          .getVariations()
                          ?.map((variation) => variation.isAvailable() === true)
                          ? 'primary'
                          : 'disabled'
                      }
                    />
                  </Tooltip>
                </>
              ) : (
                <Grid container columnSpacing={1}>
                  <Grid item xs={9} sx={{ display: 'contents' }}>
                    <Typography>
                      {t('pharmaco:productMetadatas.sellAvailable')}
                    </Typography>
                  </Grid>

                  <Grid item xs={1}>
                    <Divider orientation="vertical" />
                  </Grid>

                  <Grid item xs={2} sx={{ display: 'flex' }}>
                    <Tooltip
                      title={t(
                        props.plant.isToxic()
                          ? 'pharmaco:plant.toxic'
                          : 'pharmaco:plant.noToxic'
                      )}
                    >
                      <DangerousOutlinedIcon
                        color={props.plant.isToxic() ? 'primary' : 'disabled'}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <MetadataCard
              datas={props.plant.getMetadatas('categoryPlant')}
              type="text"
              title={t('pharmaco:productMetadatas.categoryPlant.cardTitle')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <MetadataCard
          datas={props.plant.getMetadatas('action')}
          type="text"
          title={t('pharmaco:productMetadatas.action.cardTitle')}
        />
      </Grid>
      <Grid item xs={6}>
        <MetadataCard
          datas={props.plant.getMetadatas('meridian')}
          type="chip"
          title={t('pharmaco:productMetadatas.meridian.cardTitle')}
        />
      </Grid>
      <Grid item xs={6}>
        <MetadataCard
          datas={props.plant.getMetadatas('nature')}
          type="chip"
          title={t('pharmaco:productMetadatas.nature.cardTitle')}
        />
      </Grid>
      <Grid item xs={6}>
        <MetadataCard
          datas={props.plant.getMetadatas('savour')}
          type="chip"
          title={t('pharmaco:productMetadatas.savour.cardTitle')}
        />
      </Grid>
      <Grid item xs={6}>
        <Paper sx={{ p: 1, height: '100%' }}>
          <Typography variant="subtitle2">
            {t('pharmaco:plant.posology')}
          </Typography>
          <Typography color="text.secondary">
            {t('pharmaco:posologies.displayChip.minMax', {
              min: props.plant.getCustom().posologyMin,
              max: props.plant.getCustom().posologyMax,
            }).replace(/\s/g, '\u00A0')}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}
