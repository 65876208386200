import * as React from 'react';
import { PharmacoBrowser } from './PharmacoBrowser';
import { Plant } from '../../../core/entities/Plant';
import { Formula } from '../../../core/entities/Formula';
import {
  PharmacoOrder,
  TempFormulaMode,
  TempFormulaType,
} from '../../../core/entities/PharmacoOrder';
import { PaginatedResults } from '@kiway/shared/react-types';
import { useCatalogGateway } from '../../../entrypoints/react/useCatalogGateway';
import { useProductMetadatasGateway } from '../../../entrypoints/react/useProductMetadatasGateway';
import { ProductMetadata } from '../../../core/entities/ProductMetadata';
import { useTranslation } from '@kiway/shared/utils/translation';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';

export type ChipFilter = {
  label: string;
  key: string;
  subFilters?: ChipFilter[];
};

export interface PharmacoBrowserControllerProps {
  order: PharmacoOrder;
  handleChangeStateOrder: any;
  handleSaveOrder: any;
  shopOpen?: boolean;
  setShopOpen: any;
}

export function PharmacoBrowserController(
  props: PharmacoBrowserControllerProps
) {
  const { currentLanguage, t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const { getCatalog } = useCatalogGateway();
  const [
    isValidateButtonDisabled,
    setIsValidateButtonDisabled,
  ] = React.useState<boolean>(true);
  const [selectedItems, setSelectedItems] = React.useState<
    Array<Plant | Formula>
  >([]);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>('');
  const [selectedFilter, setSelectedFilter] = React.useState<string>(
    'ingredients_all'
  );
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [itemPerPage, setItemPerPage] = React.useState<number>(12);
  const [productPaginatedResults, setProductPaginatedResults] = React.useState<
    PaginatedResults<Plant | Formula>
  >();
  const { findAll } = useProductMetadatasGateway();
  const [allMetadatas, setAllMetadatas] = React.useState<
    Array<ProductMetadata>
  >([]);
  const [
    selectedFormulasCategoriesMetadataId,
    setSelectedFormulasCategoriesMetadataId,
  ] = React.useState<string>('');
  const [
    selectedIngredientsCategoriesMetadataId,
    setSelectedIngredientsCategoriesMetadataId,
  ] = React.useState<string>('');
  const [
    selectedIngredientsActionsMetadataId,
    setSelectedIngredientsActionsMetadataId,
  ] = React.useState<string>('');
  const [
    selectedFormulasSyndromsMetadataId,
    setSelectedFormulasSyndromsMetadataId,
  ] = React.useState<string>('');

  const updateCatalog = (forcePage?: number) => {
    return getCatalog(
      {
        search,
        filters: [
          {
            type: 'model',
            value: selectedFilter.includes('ingredients_')
              ? 'Plant'
              : selectedFilter.includes('formulas_')
              ? 'Formula'
              : '',
          },
          {
            type: 'plantPowderType',
            value:
              selectedFilter === 'ingredients_plant'
                ? 'plant'
                : selectedFilter === 'ingredients_powder'
                ? 'concentratedPowder'
                : '',
          },
          {
            type: 'formulaFamily',
            value: selectedFilter.includes('formulas_')
              ? selectedFilter?.split('formulas_')?.[1] ?? ''
              : '',
          },
          {
            type: 'metadata',
            value: [
              selectedFormulasCategoriesMetadataId,
              selectedIngredientsCategoriesMetadataId,
              selectedIngredientsActionsMetadataId,
              selectedFormulasSyndromsMetadataId,
            ]
              ?.filter((item) => item !== '')
              .join(','),
          },
        ],
      },
      forcePage ?? currentPage,
      itemPerPage
    ).then((productMetadatas) => {
      setProductPaginatedResults(productMetadatas);
    });
  };

  React.useEffect(() => {
    setLoading(true);
    findAll().then((metadata) => {
      setAllMetadatas(metadata);
    });
    updateCatalog()
      .then(() => {
        props.order?.createNewTempFormula([]);
        props.handleChangeStateOrder(props.order);
      })
      .finally(() => setLoading(false));
    return () => {
      setProductPaginatedResults(null);
    };
  }, []);

  const reset = () => {
    setSelectedItems([]);
    setIsOpen(false);
    props.setShopOpen(false);
  };

  React.useEffect(() => {
    if (productPaginatedResults) {
      setCurrentPage(productPaginatedResults?.currentPage);
    }
  }, [productPaginatedResults]);

  React.useEffect(() => {
    setLoading(true);
    updateCatalog().finally(() => setLoading(false));
  }, [currentPage]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    pageNumber: number
  ) => {
    setProductPaginatedResults({
      ...productPaginatedResults,
      currentPage: pageNumber,
    });
  };

  React.useEffect(() => {
    if (
      [
        'all',
        'ingredients_all',
        'ingredients_plant',
        'formulas_normal',
        'formulas_xiaofang',
        'formulas_jingfang',
      ].includes(selectedFilter)
    ) {
      props.order.getTempFormula().setType('plant');
    } else {
      props.order.getTempFormula().setType('powder');
    }
    setLoading(true);
    updateCatalog(1).finally(() => setLoading(false));
  }, [selectedFilter]);

  React.useEffect(() => {
    setLoading(true);
    updateCatalog(1).finally(() => setLoading(false));
  }, [search]);

  React.useEffect(() => {
    setLoading(true);
    updateCatalog(1).finally(() => setLoading(false));
  }, [
    selectedFormulasCategoriesMetadataId,
    selectedIngredientsCategoriesMetadataId,
    selectedIngredientsActionsMetadataId,
    selectedFormulasSyndromsMetadataId,
  ]);

  React.useEffect(() => {
    const plantOnly: Plant[] = [];
    selectedItems
      .filter((item) => item instanceof Plant)
      .map((item: Plant) => plantOnly.push(item));
    props.order?.getTempFormula()?.setPlants(plantOnly);
    props.order?.getTempFormula()?.initDosages();
    selectedItems
      .filter((item) => item instanceof Formula)
      .map((item: Formula) => props.order?.getTempFormula()?.addFormula(item));
    props.handleChangeStateOrder(props.order);
  }, [selectedItems]);

  React.useEffect(() => {
    const results = selectedItems.filter((item) => item instanceof Plant);
    selectedItems.map((item) => {
      if (item instanceof Formula) {
        props.order
          .getTempFormula()
          ?.setName(
            results.length > 0
              ? `${item.getPinYinName()} modifiée`
              : item.getPinYinName()
          );
        setIsValidateButtonDisabled(false);
      } else {
        props.order
          .getTempFormula()
          ?.setName(`Formule ${props.order.getFormulas().length + 1}`);
        setIsValidateButtonDisabled(true);
      }
    });
  }, [selectedItems]);

  const handleClickItem = (plantOrFormula: Plant | Formula) => () => {
    if (
      selectedItems
        .map((item) => `${item.getId()}`)
        .includes(plantOrFormula.getId())
    ) {
      setSelectedItems((prev) =>
        prev.filter((item) => `${item.getId()}` !== `${plantOrFormula.getId()}`)
      );
    } else {
      setSelectedItems([...selectedItems, plantOrFormula]);
    }
  };

  const removePlant = (plantId) => {
    setSelectedItems((prev) =>
      prev.filter((item) => `${item.getId()}` !== `${plantId}`)
    );
  };

  const changeFormulaName = (formulaName: string) => {
    props.order.getTempFormula()?.setName(formulaName);
    props.handleChangeStateOrder(props.order);
  };

  const toggleFormulaMode = (mode: TempFormulaMode) => {
    props.order.getTempFormula()?.setMode(mode);
    props.handleChangeStateOrder(props.order);
  };

  const changeFormulaSelection = (
    groupBy: string,
    formulaName: string,
    type: TempFormulaType
  ) => {
    props.order
      .getTempFormula()
      ?.setSelectedFormula({ key: groupBy, name: formulaName, type });
    props.handleChangeStateOrder(props.order);
  };

  const changeFormulaType = (type: TempFormulaType) => {
    props.order.getTempFormula()?.setType(type);
    props.handleChangeStateOrder(props.order);
  };

  const handleFormulaSubmit = () => {
    props.order.transformTempFormula();
    props.handleChangeStateOrder(props.order);
    props.handleSaveOrder();
    reset();
  };

  const handleChangeFilter = (value: string) => {
    if (
      (selectedFilter.includes('ingredients') &&
        !value.includes('ingredients')) ||
      (selectedFilter.includes('formulas') && !value.includes('formulas')) ||
      value.includes('formulas')
    ) {
      setSelectedIngredientsCategoriesMetadataId('');
      setSelectedFormulasCategoriesMetadataId('');
      setSelectedIngredientsActionsMetadataId('');
      setSelectedFormulasSyndromsMetadataId('');
    }
    setSelectedFilter(value);
  };

  const filterSentence = (() => {
    let sentence = `${t('pharmaco:browser.sentence.display')} ${t(
      `pharmaco:browser.sentence.${selectedFilter}`
    )} `;
    if (
      selectedIngredientsCategoriesMetadataId &&
      selectedIngredientsCategoriesMetadataId !== ''
    ) {
      const ingredientMetadata = allMetadatas.find(
        (meta) => meta.getId() === selectedIngredientsCategoriesMetadataId
      );
      if (ingredientMetadata) {
        sentence += `${t('pharmaco:browser.sentence.have_category')} "${
          ingredientMetadata.getName()?.[
            currentLanguage?.code ?? getFallbackLanguage()
          ]
        }" `;
      }
    }
    if (
      selectedFormulasCategoriesMetadataId &&
      selectedFormulasCategoriesMetadataId !== ''
    ) {
      const formulaMetadata = allMetadatas.find(
        (meta) => meta.getId() === selectedFormulasCategoriesMetadataId
      );
      if (formulaMetadata) {
        sentence += `${t('pharmaco:browser.sentence.have_category')} "${
          formulaMetadata.getName()?.[
            currentLanguage?.code ?? getFallbackLanguage()
          ]
        }" `;
      }
    }
    if (
      selectedIngredientsActionsMetadataId &&
      selectedIngredientsActionsMetadataId !== ''
    ) {
      const actionsMetadatas = allMetadatas.find(
        (meta) => meta.getId() === selectedIngredientsActionsMetadataId
      );
      if (actionsMetadatas) {
        if (selectedIngredientsCategoriesMetadataId) {
          sentence += `${t('common:and')} `;
        }
        sentence += `${t('pharmaco:browser.sentence.have_action')} "${
          actionsMetadatas.getName()?.[
            currentLanguage?.code ?? getFallbackLanguage()
          ]
        }" `;
      }
    }
    if (
      selectedFormulasSyndromsMetadataId &&
      selectedFormulasSyndromsMetadataId !== ''
    ) {
      const syndromsMetadata = allMetadatas.find(
        (meta) => meta.getId() === selectedFormulasSyndromsMetadataId
      );
      if (syndromsMetadata) {
        if (selectedIngredientsCategoriesMetadataId) {
          sentence += `${t('common:and')} `;
        }
        sentence += `${t('pharmaco:browser.sentence.have_syndrom')} "${
          syndromsMetadata.getName()?.[
            currentLanguage?.code ?? getFallbackLanguage()
          ]
        }" `;
      }
    }
    if (search && search !== '') {
      if (
        (selectedIngredientsCategoriesMetadataId &&
          selectedIngredientsCategoriesMetadataId !== '') ||
        (selectedFormulasCategoriesMetadataId &&
          selectedFormulasCategoriesMetadataId !== '')
      ) {
        sentence += `${t('common:and')} `;
      }
      sentence += `${t('pharmaco:browser.sentence.contains')} "${search}" `;
    }
    return sentence;
  })();

  return (
    <PharmacoBrowser
      itemPerPage={itemPerPage}
      paginatedResults={productPaginatedResults}
      handleChangePage={handleChangePage}
      isBrowserOpen={props.shopOpen}
      isDosageModalOpen={isOpen}
      order={props.order}
      handleChangeStateOrder={props.handleChangeStateOrder}
      removePlant={removePlant}
      changeFormulaName={changeFormulaName}
      selectedItems={selectedItems}
      setIsBrowserOpen={props.setShopOpen}
      setIsDosageModalOpen={setIsOpen}
      handleClickItem={handleClickItem}
      toggleFormulaMode={toggleFormulaMode}
      changeFormulaSelection={changeFormulaSelection}
      changeFormulaType={changeFormulaType}
      handleFormulaSubmit={handleFormulaSubmit}
      search={search}
      setSearch={setSearch}
      handleChangeFilter={handleChangeFilter}
      selectedFilter={selectedFilter}
      isButtonDisabled={isValidateButtonDisabled}
      setIsButtonDisabled={setIsValidateButtonDisabled}
      filteredIngredientsMetadatas={allMetadatas
        .filter((meta) => meta.getMetadataType() === 'categoryPlant')
        .sort((a, b) =>
          a
            .getName()
            ?.[currentLanguage?.code ?? getFallbackLanguage()]?.localeCompare(
              b.getName()?.[currentLanguage?.code ?? getFallbackLanguage()]
            )
        )}
      filteredFormulasMetadatas={allMetadatas
        .filter(
          ProductMetadata.filterByMetadataType(
            `categoryFormula${
              selectedFilter === 'formulas_jingFang'
                ? 'JingFang'
                : selectedFilter === 'formulas_xiaoFang'
                ? 'XiaoFang'
                : ''
            }` as any
          )
        )
        .sort((a, b) =>
          a
            .getName()
            ?.[currentLanguage?.code ?? getFallbackLanguage()]?.localeCompare(
              b.getName()?.[currentLanguage?.code ?? getFallbackLanguage()]
            )
        )}
      filteredActionsMetadatas={allMetadatas
        .filter(ProductMetadata.filterByMetadataType('action'))
        .sort((a, b) =>
          a
            .getName()
            ?.[currentLanguage?.code ?? getFallbackLanguage()]?.localeCompare(
              b.getName()?.[currentLanguage?.code ?? getFallbackLanguage()]
            )
        )}
      filteredSyndromsMetadatas={allMetadatas
        .filter(ProductMetadata.filterByMetadataType('syndrom'))
        .sort((a, b) =>
          a
            .getName()
            ?.[currentLanguage?.code ?? getFallbackLanguage()]?.localeCompare(
              b.getName()?.[currentLanguage?.code ?? getFallbackLanguage()]
            )
        )}
      selectedIngredientsCategoriesMetadataId={
        selectedIngredientsCategoriesMetadataId
      }
      setSelectedIngredientsCategoriesMetadataId={
        setSelectedIngredientsCategoriesMetadataId
      }
      selectedFormulasCategoriesMetadataId={
        selectedFormulasCategoriesMetadataId
      }
      setSelectedFormulasCategoriesMetadataId={
        setSelectedFormulasCategoriesMetadataId
      }
      selectedFormulasSyndromsMetadataId={selectedFormulasSyndromsMetadataId}
      setSelectedFormulasSyndromsMetadataId={
        setSelectedFormulasSyndromsMetadataId
      }
      selectedIngredientsActionsMetadataId={
        selectedIngredientsActionsMetadataId
      }
      setSelectedIngredientsActionsMetadataId={
        setSelectedIngredientsActionsMetadataId
      }
      filterSentence={filterSentence}
    />
  );
}
