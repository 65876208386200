import { useMediaQuery } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import React, { useContext, useEffect, useReducer } from 'react'
import { useTranslation } from '@kiway/shared/utils/translation'
import { CustomButton } from '../Buttons'
import SlotPickerColumn from '../SlotPickerColumn'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'

const DEFAULT_RANGE_SIZE = 4 // nb days
const MAX_SLOTS_DISPLAYED = 4

const initState = {
  loading: false,
  fromDate: moment(),
  toDate: moment().add(DEFAULT_RANGE_SIZE - 1, 'days'),
  slotsDays: [],
  slots: {},
  slotSelected: null,
  maxSlotsDisplayed: MAX_SLOTS_DISPLAYED,
}

const updateRange = (fromDate, toDate, mode, RANGE_SIZE) => {
  let newFrom, newTo
  if (mode === 'increment') {
    newFrom = moment(toDate).add(1, 'days')
    newTo = moment(toDate).add(RANGE_SIZE, 'days')
  } else if (mode === 'decrement') {
    newTo = moment(fromDate).subtract(1, 'days')
    newFrom = moment(fromDate).subtract(RANGE_SIZE, 'days')
  }
  return [newFrom, newTo]
}

const reducer = (state, action) => {
  let RANGE_SIZE = DEFAULT_RANGE_SIZE
  switch (action.type) {
    case 'INIT_FROM_TO':
      return {
        ...state,
        ...action.payload,
      }
    case 'FETCH_INIT':
      return {
        ...state,
        loading: true,
      }
    case 'FETCH_COMPLETE':
      if (action.payload) {
        return {
          ...state,
          loading: false,
          slotsDays: action.payload.slotsDays
            ? [...action.payload.slotsDays]
            : state.slotsDays,
          slots: action.payload.slots || {},
          maxSlotsDisplayed: MAX_SLOTS_DISPLAYED,
        }
      } else {
        return {
          ...state,
        }
      }
    case 'SELECT_CHANGE':
      return {
        ...state,
        slotSelected: action.payload,
      }
    case 'INCREMENT_RANGE':
      RANGE_SIZE = action.payload.RANGE_SIZE
      let [nextFrom, nextTo] = updateRange(
        state.fromDate,
        state.toDate,
        'increment',
        RANGE_SIZE,
      )
      return {
        ...state,
        fromDate: nextFrom,
        toDate: nextTo,
        slotsDays: getSlotsDaysFromTo(nextFrom, nextTo),
        maxSlotsDisplayed: MAX_SLOTS_DISPLAYED,
      }
    case 'DECREMENT_RANGE':
      RANGE_SIZE = action.payload.RANGE_SIZE
      let [prevFrom, prevTo] = updateRange(
        state.fromDate,
        state.toDate,
        'decrement',
        RANGE_SIZE,
      )
      if (prevFrom.diff(moment(), 'days') >= 0) {
        return {
          ...state,
          fromDate: prevFrom,
          toDate: prevTo,
          slotsDays: getSlotsDaysFromTo(prevFrom, prevTo),
          maxSlotsDisplayed: MAX_SLOTS_DISPLAYED,
        }
      } else {
        return {
          ...state,
        }
      }
    case 'LOAD_MORE':
      return {
        ...state,
        maxSlotsDisplayed: null,
      }
    default:
      throw new Error('Unhandled action type')
  }
}

const getSlotPickerColumnsInfos = (
  oneColumn,
  twoColumns,
  threeColumns,
  fourColumns,
  sixColumns,
) => {
  return fourColumns
    ? { range: 4, grid: 3 }
    : threeColumns
    ? { range: 3, grid: 4 }
    : twoColumns
    ? { range: 2, grid: 6 }
    : sixColumns
    ? { range: 6, grid: 2 }
    : { range: 1, grid: 12 }
}

export function useSlotPicker(
  chiefComplaintId,
  consultationPlaceId,
  practitionerId,
) {
  /**
   * < 360px => 1 column
   * > 360px => 2 columns
   * > 430px => 3 columns
   * > 560px => 4 columns
   * > 740px => 6 columns
   * > 960px => 3 columns
   * > 1200px => 4 columns
   */
  const oneColumn = useMediaQuery('(max-width:359px)')
  const twoColumns = useMediaQuery('(min-width:360px) and (max-width:429px)')
  const threeColumnsPartOne = useMediaQuery(
    // eslint-disable-next-line max-len
    '(min-width:430px) and (max-width:559px)',
  )
  const threeColumnsPartTwo = useMediaQuery(
    '(min-width:960px) and (max-width:1199px)',
  )
  const threeColumns = threeColumnsPartOne || threeColumnsPartTwo
  const fourColumnsPartOne = useMediaQuery(
    '(min-width:560px) and (max-width:739px)',
  )
  const fourColumnsPartTwo = useMediaQuery('(min-width:1200px)')
  const fourColumns = fourColumnsPartOne || fourColumnsPartTwo
  const sixColumns = useMediaQuery('(min-width:740px) and (max-width:959px)')
  let rangeAndGrid = getSlotPickerColumnsInfos(
    oneColumn,
    twoColumns,
    threeColumns,
    fourColumns,
    sixColumns,
  )

  const [state, dispatch] = useReducer(reducer, initState, () => initState)

  useEffect(() => {
    rangeAndGrid = getSlotPickerColumnsInfos(
      oneColumn,
      twoColumns,
      threeColumns,
      fourColumns,
      sixColumns,
    )
    const newFrom = moment(state.fromDate)
    const newTo = moment(state.fromDate).add(rangeAndGrid.range - 1, 'days')
    dispatch({
      type: 'INIT_FROM_TO',
      payload: {
        fromDate: newFrom,
        toDate: newTo,
        slotsDays: getSlotsDaysFromTo(newFrom, newTo),
      },
    })
  }, [oneColumn, twoColumns, threeColumns, fourColumns, sixColumns])

  const component = (
    <SlotPicker
      chiefComplaintId={chiefComplaintId}
      consultationPlaceId={consultationPlaceId}
      dispatch={dispatch}
      practitionerId={practitionerId}
      rangeAndGrid={rangeAndGrid}
      state={state}
    />
  )
  return [state.slotSelected, component]
}

const getSlotsDaysFromTo = (from, to) => {
  const slotsDays = []
  for (let d = moment(from); d <= moment(to); d.add(1, 'days')) {
    slotsDays.push(d.format('YYYY-MM-DD'))
  }
  return slotsDays
}

function SlotPicker(props) {
  const {
    chiefComplaintId,
    consultationPlaceId,
    dispatch,
    practitionerId,
    rangeAndGrid,
    state,
  } = props

  const { t } = useTranslation()

  useEffect(() => {
    dispatch({
      type: 'FETCH_COMPLETE',
      payload: {
        slotsDays: getSlotsDaysFromTo(state.fromDate, state.toDate),
      },
    })
  }, [])

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: '32px' }}>
        <Grid
          container
          direction={'row'}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
        >
          <Grid item xs={1}>
            <div
              style={{
                minHeight: '55px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                size={'small'}
                onClick={() =>
                  dispatch({
                    type: 'DECREMENT_RANGE',
                    payload: { RANGE_SIZE: rangeAndGrid.range },
                  })
                }
                disabled={state.fromDate.diff(moment(), 'days') <= 0}
              >
                <NavigateBeforeIcon />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={10}>
            <div style={{ minHeight: '55px', paddingTop: '8px' }}>
              <Grid container spacing={1} direction={'row'}>
                {state.slotsDays &&
                  state.slotsDays.map((slotKey) => {
                    const currentDay = moment(slotKey).locale(t('dates:moment'))
                    return (
                      <Grid key={slotKey} item xs={rangeAndGrid.grid}>
                        <SlotPickerColumn
                          key={slotKey}
                          slotDay={currentDay}
                          maxSlotsDisplayed={state.maxSlotsDisplayed}
                          chiefComplaintId={chiefComplaintId}
                          consultationPlaceId={consultationPlaceId}
                          practitionerId={practitionerId}
                        />
                      </Grid>
                    )
                  })}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={1}>
            <div
              style={{
                minHeight: '55px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                size={'small'}
                onClick={() =>
                  dispatch({
                    type: 'INCREMENT_RANGE',
                    payload: { RANGE_SIZE: rangeAndGrid.range },
                  })
                }
              >
                <NavigateNextIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            visibility: state.maxSlotsDisplayed ? 'visible' : 'hidden',
          }}
        >
          <CustomButton
            color="minoritary"
            onClick={() => dispatch({ type: 'LOAD_MORE' })}
          >
            {t('common:form.buttons.loadMore')}
          </CustomButton>
        </div>
      </Grid>
    </Grid>
  )
}

export default SlotPicker
