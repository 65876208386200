import * as React from 'react';
import { usePlantsGateway } from '../../../entrypoints/react/usePlantsGateway';
import { PlantDetails } from './PlantDetails';
import { Plant, PlantArrays } from '../../../core/entities/Plant';
import { useSnackbar } from '@kiway/shared/utils/snackbar';
import { useTranslation } from '@kiway/shared/utils/translation';
import { ProductMetadata } from '../../../core/entities/ProductMetadata';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CurrencyEUR, Price } from '@kiway/ecommerce-react';

export function PlantDetailsController({ id }) {
  const [loading, setLoading] = React.useState(false);
  const [loadingType, setLoadingType] = React.useState<string>(null);
  const [initialPlant, setInitialPlant] = React.useState<Plant>(null);
  const [plant, setPlant] = React.useState<Plant[]>([null]);
  const {
    addMetadataToPlant,
    editPlants,
    findOne,
    removeMetadataFromPlant,
    searchPlantMetadata,
  } = usePlantsGateway();
  const { addErrorSnackbar, addSuccessSnackbar } = useSnackbar();
  const { t } = useTranslation();

  React.useEffect(() => {
    setLoading(true);
    if (id) {
      findOne(id)
        .then(async (plant) => {
          if (plant && plant.getId()) {
            setPlant([plant]);
            setInitialPlant(new Plant(JSON.parse(JSON.stringify(plant))));
          }
          // await new Promise((res) => setTimeout(res, 3000));
        })
        .finally(() => setLoading(false));
    }
    return () => {
      setPlant(null);
    };
  }, [id]);

  const handleChange = (type: string) => (value: string | number | boolean) => {
    const editedPlant: Plant = plant?.[0];
    switch (type) {
      case 'pinYinName':
        editedPlant?.setPinYinName(value as string);
        break;
      case 'latinName':
        editedPlant?.setLatinName(value as string);
        break;
      case 'commonName':
        editedPlant?.setCommonName(value as string);
        break;
      case 'chineseName':
        editedPlant?.setChineseName(value as string);
        break;
      case 'otherName':
        editedPlant?.setOtherName(value as string);
        break;
      case 'concentrationRate':
        editedPlant?.setConcentrationRate(value as number);
        break;
      case 'toxic':
        editedPlant?.setToxic(value as boolean);
        break;
      case 'published':
        editedPlant?.setPublished(value as boolean);
        break;
      case 'sellAvailable':
        editedPlant?.setSellAvailable(value as boolean);
        break;
      case 'posologyMin':
        editedPlant?.setPosologyMin(value as number);
        break;
      case 'posologyMax':
        editedPlant?.setPosologyMax(value as number);
        break;
      case 'referenceStockPlant':
        editedPlant?.setReferenceStockPlant(value as string);
        break;
      case 'referenceStockPowder':
        editedPlant?.setReferenceStockPowder(value as string);
        break;
      default:
        throw new Error('unknown metadata type');
    }
    setPlant([editedPlant]);
  };

  const handleChangeVariations = (
    id: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> & {
      target: { checked: boolean };
    }
  ) => {
    const { name, value, checked } = event.target;
    switch (name) {
      case 'available':
        setPlant([
          plant?.[0]?.setVariations([
            ...plant?.[0]
              ?.getVariations()
              .map((variation) =>
                variation.getId() === id
                  ? variation.setAvailable(checked)
                  : variation
              ),
          ]),
        ]);
        break;
      case 'price':
        setPlant([
          plant?.[0]?.setVariations([
            ...plant?.[0]?.getVariations().map((variation) =>
              variation.getId() === id && Number(value) >= 0
                ? variation.setPrice(
                    new Price(
                      {
                        currency: CurrencyEUR,
                        centAmount: Number(value) * 100,
                      },
                      1
                    )
                  )
                : variation
            ),
          ]),
        ]);
        break;
      case 'productRef':
        setPlant([
          plant?.[0]?.setVariations([
            ...plant?.[0]
              ?.getVariations()
              .map((variation) =>
                variation.getId() === id
                  ? variation.setProductRef(value)
                  : variation
              ),
          ]),
        ]);
        break;
      default:
        throw new Error('unknown variation attribute name');
    }
  };

  const handleAdd = (type: PlantArrays, value: ProductMetadata) => {
    const editedPlant: Plant = plant?.[0];
    setLoading(true);
    setLoadingType(type);
    addMetadataToPlant(editedPlant.getId(), value.getId(), type)
      .then(async (plant) => {
        setInitialPlant(
          new Plant({
            ...JSON.parse(JSON.stringify(plant)),
          })
        );
        setPlant([plant]);
        addSuccessSnackbar(
          t('pharmaco:plant.detailsSuccessUpdate', { count: 1 }),
          null,
          {
            withUpdate: true,
          }
        );
      })
      .finally(() => {
        setLoading(false);
        setLoadingType(null);
      });
  };

  const handleDelete = (type: PlantArrays) => (id: string) => {
    const editedPlant: Plant = plant?.[0];
    setLoading(true);
    setLoadingType(type);
    removeMetadataFromPlant(editedPlant.getId(), id, type)
      .then(async (plant) => {
        setInitialPlant(
          new Plant({
            ...JSON.parse(JSON.stringify(plant)),
          })
        );
        setPlant([plant]);
        addSuccessSnackbar(
          t('pharmaco:plant.detailsSuccessUpdate', { count: 1 }),
          null,
          {
            withUpdate: true,
          }
        );
      })
      .finally(() => {
        setLoading(false);
        setLoadingType(null);
      });
  };

  const handleSaveOnBlur = (type: string) => (id: string) => {
    let itemToSave: any;
    const editedPlant: Plant = plant?.[0];
    let editedInfo: any;
    let initialItem: any = null;
    switch (type) {
      case 'pinYinName':
        editedInfo = editedPlant?.getPinYinName();
        itemToSave =
          editedInfo !== initialPlant?.getPinYinName()
            ? {
                custom: {
                  ...editedPlant?.getCustomInput(),
                  pinYinName: editedInfo,
                },
              }
            : null;
        break;
      case 'latinName':
        editedInfo = editedPlant?.getLatinName();
        itemToSave =
          editedInfo !== initialPlant?.getLatinName()
            ? {
                custom: {
                  ...editedPlant?.getCustomInput(),
                  latinName: editedInfo,
                },
              }
            : null;
        break;
      case 'commonName':
        editedInfo = editedPlant?.getCommonName();
        itemToSave =
          editedInfo !== initialPlant?.getCommonName()
            ? {
                custom: {
                  ...editedPlant?.getCustomInput(),
                  commonName: editedInfo,
                },
              }
            : null;
        break;
      case 'chineseName':
        editedInfo = editedPlant?.getChineseName();
        itemToSave =
          editedInfo !== initialPlant?.getChineseName()
            ? {
                custom: {
                  ...editedPlant?.getCustomInput(),
                  chineseName: editedInfo,
                },
              }
            : null;
        break;
      case 'otherName':
        editedInfo = editedPlant?.getOtherName();
        itemToSave =
          editedInfo !== initialPlant?.getOtherName()
            ? {
                custom: {
                  ...editedPlant?.getCustomInput(),
                  otherName: editedInfo,
                },
              }
            : null;
        break;
      case 'concentrationRate':
        editedInfo = editedPlant?.getConcentrationRate();
        itemToSave =
          editedInfo !== initialPlant?.getConcentrationRate()
            ? {
                custom: {
                  ...editedPlant?.getCustomInput(),
                  concentrationRate: `${editedInfo}`,
                },
              }
            : null;
        break;
      case 'toxic':
        editedInfo = editedPlant?.isToxic();
        itemToSave =
          editedInfo !== initialPlant?.isToxic()
            ? {
                custom: {
                  ...editedPlant?.getCustomInput(),
                  toxic: editedInfo,
                },
              }
            : null;
        break;
      case 'published':
        editedInfo = editedPlant?.isPublished();
        itemToSave =
          editedInfo !== initialPlant?.isPublished()
            ? {
                published: editedInfo,
              }
            : null;
        break;
      case 'sellAvailable':
        editedInfo = editedPlant?.isSellAvailable();
        itemToSave =
          editedInfo !== initialPlant?.isSellAvailable()
            ? {
                custom: {
                  ...editedPlant?.getCustomInput(),
                  sellAvailable: editedInfo,
                },
              }
            : null;
        break;
      case 'posologyMin':
        editedInfo = editedPlant?.getPosologyMin();
        if (
          !Plant.checkPosologyRequirements({
            min: editedPlant?.getPosologyMin(),
            max: editedPlant?.getPosologyMax(),
          })
        ) {
          addErrorSnackbar(t('pharmaco:posologies.requirementsNotOK'), null, {
            withUpdate: true,
          });
        } else {
          itemToSave =
            editedInfo !== initialPlant?.getPosologyMin()
              ? {
                  custom: {
                    ...editedPlant?.getCustomInput(),
                    posologyMin: editedInfo,
                  },
                }
              : null;
        }
        break;
      case 'posologyMax':
        editedInfo = editedPlant?.getPosologyMax();
        if (
          !Plant.checkPosologyRequirements({
            min: editedPlant?.getPosologyMin(),
            max: editedPlant?.getPosologyMax(),
          })
        ) {
          addErrorSnackbar(t('pharmaco:posologies.requirementsNotOK'), null, {
            withUpdate: true,
          });
        } else {
          itemToSave =
            editedInfo !== initialPlant?.getPosologyMax()
              ? {
                  custom: {
                    ...editedPlant?.getCustomInput(),
                    posologyMax: editedInfo,
                  },
                }
              : null;
        }
        break;
      case 'variations':
        initialItem = initialPlant
          ?.getVariations()
          .find((variation) => variation.getId() === id);
        editedInfo = editedPlant
          ?.getVariations()
          .find((variation) => variation.getId() === id);
        itemToSave =
          `${initialItem?.getPrice()?.getCentAmount() || null}` !==
            `${editedInfo.getPrice()?.getCentAmount()}` ||
          `${initialItem?.getProductRef() || null}` !==
            `${editedInfo.getProductRef()}` ||
          `${initialItem?.isAvailable()}` !== `${editedInfo.isAvailable()}`
            ? {
                variations: editedPlant.getVariations().map((variation) => {
                  return {
                    id: variation.getId(),
                    productVariationType: variation
                      .getProductVariationType()
                      ?.getId(),
                    price: variation.getPrice()?.toInput(true),
                    productRef: variation.getProductRef(),
                    available: variation.isAvailable(),
                  };
                }),
              }
            : null;
        break;
      case 'referenceStockPlant':
        editedInfo = editedPlant?.getReferenceStockPlant();
        itemToSave =
          editedInfo !== initialPlant?.getReferenceStockPlant()
            ? {
                custom: {
                  ...editedPlant?.getCustomInput(),
                  referenceStockPlant: editedInfo,
                },
              }
            : null;
        break;
      case 'referenceStockPowder':
        editedInfo = editedPlant?.getReferenceStockPowder();
        itemToSave =
          editedInfo !== initialPlant?.getReferenceStockPowder()
            ? {
                custom: {
                  ...editedPlant?.getCustomInput(),
                  referenceStockPowder: editedInfo,
                },
              }
            : null;
        break;
      default:
        throw new Error('unknown metadata type');
    }
    if (itemToSave) {
      setLoading(true);
      setLoadingType(['variations'].includes(type) ? type : 'details');
      editPlants([{ ...itemToSave, id: editedPlant.getId() }])
        .then((plants) => {
          if (
            plants &&
            plants?.length &&
            `${plants?.[0]?.getId()}` === `${editedPlant.getId()}`
          ) {
            addSuccessSnackbar(
              t('pharmaco:plant.detailsSuccessUpdate', { count: 1 }),
              null,
              {
                withUpdate: true,
              }
            );
            // Reset initial plant after saving the changes
            setInitialPlant(
              new Plant({
                ...JSON.parse(JSON.stringify(plants?.[0])),
              })
            );
            setPlant([plants?.[0]]);
          }
        })
        .finally(() => {
          setLoading(false);
          setLoadingType(null);
        });
    }
  };

  return (
    <PlantDetails
      handleAdd={handleAdd}
      handleChange={handleChange}
      handleChangeVariations={handleChangeVariations}
      handleDelete={handleDelete}
      handleSave={handleSaveOnBlur}
      loading={loading}
      loadingType={loadingType}
      plant={plant?.[0]}
      searchCallback={searchPlantMetadata}
    />
  );
}
