export default {
  editProfile: {
    aboutMe: 'About me',
    address: {
      add: {
        button: 'Add address',
        modalTitle: 'Add address',
      },
      default: {
        button: 'Set default',
        chip: 'Default',
      },
      delete: {
        button: 'Delete',
        modalMessage: 'Are you sure you want to delete this address?',
        modalTitle: 'Delete address',
      },
      edit: {
        button: 'Edit',
        modalTitle: 'Edit address',
      },
      title: 'Addresses',
    },
    cancelChangePicture: 'Cancel',
    changeEmailError:
      'An error occurred while saving. The email address provided may be invalid. Please try again.',
    changeEmailInfo:
      'An email will be sent to you to verify that you own this email address. Please click on the link in the email.',
    changeEmailInvalid: 'Please enter a valid email address.',
    changeEmailSuccess:
      'Your email address has been updated, you must click on the link received by email in order to validate this address and be able to use the application.',
    changePasswordCancelButton: 'Cancel',
    changePasswordConfirmButton: 'Submit',
    changePasswordError:
      'An error occurred while sending the email. Please try again.',
    changePasswordInfo:
      'You will receive an email containing a link to reset your password.',
    changePasswordSuccess:
      'An email will be coming soon. Please click on the link to reset your password.',
    changePicture: 'Change picture',
    deletePicture: 'Delete picture',
    email: 'Email',
    emailInfo:
      'If you change your email address, you will need to verify it in order to log in again.',
    helperTextNIF: 'Taxpayer identification number',
    helperTextPicture: 'Only secure links (https)',
    imageUrlLabel: 'Link to picture',
    linkText: 'My profile',
    nif: 'NIF',
    password: 'Password',
    passwordInfo:
      "Your password is not stored in plain text. That's why we can't show it to you.",
    saveChangePicture: 'Save',
    saveError: 'A problem occurred while saving. Please try again.',
    saveSuccess: 'Changes saved',
    security: 'Security',
    sessions: {
      deleteAll: 'Delete all sessions',
      deviceName: 'Name',
      deviceType: 'Type',
      deviceTypes: {
        BROWSER: 'Browser',
        DESKTOP: 'DESKTOP',
        LAPTOP: 'LAPTOP',
        MOBILE: 'MOBILE',
        OTHER: 'OTHER',
        SERVER: 'SERVER',
        TABLET: 'TABLET',
        TV: 'TV',
        UNKNOWN: 'UNKNOWN',
      },
      ip: 'IP',
      lastAccessed: 'Last used',
      title: 'Pending sessions lits',
    },
    settings: {
      browserDefaults: 'Browser default value',
      language: 'Language',
      timezone: 'Timezone',
      title: 'Settings',
    },
    title: 'Profile edit',
    twoFA: {
      active: 'Active',
      back: 'Previous',
      cancel: 'Cancel',
      continue: 'Next',
      disable: 'Disable',
      disableError:
        'A problem has occurred, the code may not be valid. Please try again.',
      disableSuccess: 'Two-factor authentication deactivation successful.',
      dlApp: {
        description:
          'You need to download an authentication application (TOTP) from your smartphone store. We recommend Google Authenticator.',
        label: 'Install an authenticator app',
      },
      enable: 'Enable',
      enableError:
        'A problem has occurred, the code may not be valid. Please try again.',
      enableSuccess: 'Two-factor authentication successfully activated',
      enterCode: {
        description:
          'You must enter the 6-digit code generated by the application and click on "Validate".',
        label: 'Enter code',
      },
      finish: 'Submit',
      inactive: 'Inactive',
      info: 'Secure your login by adding a second authentication method',
      scanQRCode: {
        description:
          'To add this site to Google Authenticator, you must scan the QR code or manually enter the secret code.',
        label: 'Scan QR code',
      },
      secretCode: 'Secret code: {{ secret }}',
      title: 'Two-factor authentication',
    },
  },
  register: {
    buttons: {
      backToHome: 'Back to home',
      submit: 'Create my space',
    },
    disabled: 'Account creation is disabled. Please contact an administrator.',
    errors: {
      birthDate: {
        ofAge: {
          patient:
            'You must be over {{ age }} or be represented by a legal guardian',
          practitioner:
            'You must be over {{ age }} to be allowed to use the application',
        },
      },
      errorRegistration:
        'The account could not be created. Please try again or contact an administrator if the problem persists.',
      errorRegistrationExternal:
        'A problem occurred during your registration. Please try again or contact an administrator if the problem persists.',
      errorUserRegistrationExternal:
        'A problem occurred while creating your account. Please try again or contact an administrator if the problem persists.',
      unknownError:
        'A problem has occurred. Please try again or contact an administrator if the problem persists.',
      userExists:
        'This email account already exists. If you have forgotten your password, you can reset it via the login page.',
    },
    fields: {
      birthDate: 'Birthdate',
      certifyLegalRepresentative:
        'I certified being represented by my legal guardian *',
      email: 'Email',
      firstName: 'Firstname',
      lastName: 'Lastname',
      referrerCode: 'Referrer code',
    },
    success:
      'Your account has been created. You will receive an email to set your password and then another email confirming your registration. See you soon on our platform!',
    title: 'Creation of your {{app}} space',
  },
  signIn: {
    title: 'Sign In',
  },
  signOut: {
    linkText: 'Logout',
    title: 'Logout in progress',
  },
  users: {
    title: 'Users management',
  },
  usersManagement: {
    linkText: 'Users management',
  },
};
