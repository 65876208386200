import { Avatar, Button, Grid, TextField } from '@mui/material';
import * as React from 'react';
import { DatePicker, GenderPicker, PhoneInput } from '@kiway/shared/ui';
import moment from 'moment';
import { User } from '../../../core/entities/User';
import { useTranslation } from '@kiway/shared/utils/translation';

export interface AboutUserProps {
  avatarSrc: string;
  changeImageUrl?: boolean;
  deleteImageUrl: (...args: any[]) => any;
  errors: any;
  handleChange: (field: string) => (...args: any[]) => any;
  handleClickChangeCancelPicture: (...args: any[]) => any;
  handleSave: (...args: any[]) => any;
  handleSaveImageUrl: (...args: any[]) => any;
  localImageUrl: string;
  saveChangePictureDisabled?: boolean;
  user: User;
}

export function AboutUser(props: AboutUserProps) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={4}>
            <Avatar src={props.avatarSrc} sx={{ width: 100, height: 100 }} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container direction="column">
              <Grid item>
                {props.changeImageUrl && (
                  <TextField
                    inputRef={(input) => {
                      if (input != null) {
                        input.focus();
                      }
                    }}
                    name="imageUrl"
                    size="small"
                    label={t('authentication:editProfile.imageUrlLabel')}
                    sx={{
                      display: props.changeImageUrl ? undefined : 'none',
                      marginTop: 1,
                    }}
                    fullWidth
                    variant="outlined"
                    helperText={t(
                      'authentication:editProfile.helperTextPicture'
                    )}
                    error={props.errors?.imageUrl !== ''}
                    value={props.localImageUrl || ''}
                    onChange={props.handleChange('imageUrl')}
                  />
                )}
                <Button
                  color={props.changeImageUrl ? 'inherit' : undefined}
                  onClick={props.handleClickChangeCancelPicture}
                >
                  {props.changeImageUrl
                    ? t('authentication:editProfile.cancelChangePicture')
                    : t('authentication:editProfile.changePicture')}
                </Button>
                {props.changeImageUrl && (
                  <Button
                    disabled={props.saveChangePictureDisabled}
                    onClick={props.handleSaveImageUrl}
                  >
                    {t('authentication:editProfile.saveChangePicture')}
                  </Button>
                )}
              </Grid>
              {!props.changeImageUrl && (
                <Grid item>
                  <Button
                    onClick={props.deleteImageUrl}
                    disabled={!props.user?.getImageUrl()}
                  >
                    {t('authentication:editProfile.deletePicture')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              required
              variant="outlined"
              id="firstName"
              name="firstName"
              label={t('file:addPatientModal.firstName')}
              value={props.user?.getFirstName() || ''}
              onChange={props.handleChange('firstName')}
              onBlur={props.handleSave}
              margin="dense"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              required
              variant="outlined"
              id="lastName"
              name="lastName"
              label={t('file:addPatientModal.lastName')}
              value={props.user?.getLastName() || ''}
              onChange={props.handleChange('lastName')}
              onBlur={props.handleSave}
              margin="dense"
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <GenderPicker
          value={props.user?.getGender()}
          handleChange={props.handleChange('gender')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          id="profession"
          name="profession"
          label={t('file:details.info.profession')}
          value={props.user?.getProfession() || ''}
          onChange={props.handleChange('profession')}
          onBlur={props.handleSave}
          margin="dense"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          id="nif"
          name="nif"
          helperText={t('authentication:editProfile.helperTextNIF')}
          label={t('authentication:editProfile.nif')}
          value={props.user?.getCustom()?.nif ?? ''}
          onChange={props.handleChange('nif')}
          onBlur={props.handleSave}
          margin="dense"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <DatePicker
              value={
                props.user?.getBirthDate()
                  ? moment(props.user?.getBirthDate(), 'yyyy-MM-DD')
                  : null
              }
              handleChange={props.handleChange('birthDate')}
              handleSave={props.handleSave}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <PhoneInput
              forcePhoneNumberType={'mobile'}
              onBlur={props.handleSave}
              onChange={props.handleChange('mobilePhone')}
              value={props.user?.getMobilePhone() || ''}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* 
  <GoogleMaps
  inputValue={
  user.address && user.address.description
  ? user.address.description
  : ''
  }
  setAddressComponent={(value) =>
  setUser((prevState) => ({ ...prevState, address: value }))
  }
  setAddress={(value) =>
  setUser((prevState) => ({
  ...prevState,
  address: { description: value },
  }))
  }
  margin="dense"
  />
  {PhoneComponent}
  {['admin', 'beta', 'partner', 'practitioner'].includes(userInfo.role) && LandlineComponent}
  {globalError !== '' && (
  <Alert severity="error" className={classes.formControl}>
  {globalError}
  </Alert>
  )}
  {(!['admin', 'beta', 'partner', 'practitioner'].includes(userInfo.role) && user.birthDate?.isValid() && !ofAge) && (
  <CustomCheckbox
  error=''
  label={t('user:register.certifyLegalRepresentative')}
  value={certifyLegalRepresentative}
  handleChange={() => setCertifyLegalRepresentative(prev => !prev)}
  />
  )}
  <SubmitButton disabled={isConfirmButtonDisabled()} onClick={handleSubmit}>
  {t('common:form.buttons.save')}
  </SubmitButton> */}
    </Grid>
  );
}
