/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { ShippingMethod } from '@kiway/ecommerce-react';

export function ShippingMethodSelector(props: {
  availableMethods?: ShippingMethod[];
  disabled?: boolean;
  handleChangeShippingMethod?: (shippingMethodId: string) => void;
  selectedMethodId?: string;
  countryCode: string;
  weight: number;
}) {
  return (
    <FormControl disabled={props.disabled}>
      <RadioGroup
        onChange={(e) => {
          props.handleChangeShippingMethod(e.target.value);
        }}
        value={props?.selectedMethodId}
      >
        {props.availableMethods
          ?.sort(ShippingMethod.sortByDisplayOrder())
          ?.map((deliver) => {
            return (
              <FormControlLabel
                key={deliver.getId()}
                value={deliver.getId()}
                control={<Radio />}
                label={
                  deliver.getName()?.fr +
                  ' ' +
                  deliver.getPrice(props.countryCode, props.weight)?.toString()
                }
              />
            );
          })}
      </RadioGroup>
    </FormControl>
  );
}
