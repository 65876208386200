import { TaxMode } from '../entities/TaxMode';
import { TaxModeProvider } from './TaxModeProvider';

export class GetAllTaxModes {
  private taxModeProvider: TaxModeProvider;

  public constructor(taxModeProvider: TaxModeProvider) {
    this.taxModeProvider = taxModeProvider;
  }

  public execute(): Promise<TaxMode[]> {
    return this.taxModeProvider.findAll();
  }
}
