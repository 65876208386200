import * as React from 'react';
import { useFormulasGateway } from '../../../entrypoints/react/useFormulasGateway';
import { ListFormulas } from './ListFormulas';
import { Formula } from '../../../core/entities/Formula';
import { deepen } from '@kiway/shared/utils/string';
import { useHistory } from 'react-router-dom';
import { links } from '../../../config';

const prepareToSave = (item) => {
  return {
    ...deepen(item),
    id: item._id,
    _id: undefined,
  };
};

export function ListFormulasController() {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [formulas, setFormulas] = React.useState<Formula[]>([]);
  const { editFormulas, findAll } = useFormulasGateway();

  const updateData = () => {
    setLoading(true);
    findAll()
      .then(({ items: formulas }: { items: Formula[] }) => {
        setFormulas([...formulas]);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    updateData();
    return () => {
      setFormulas([]);
    };
  }, []);

  const handleAddData = () => {
    const data: Formula = new Formula({ custom: { pinYinName: '' } });
    setFormulas((prev) => [data, ...prev]);
  };

  const handleClearAdd = () => {
    setFormulas((prev) => [...prev.filter((plant) => plant.getId())]);
  };

  return (
    <ListFormulas
      formulas={formulas.map((item) => item.toDatatableRow())}
      handleAddData={handleAddData}
      handleClearAdd={handleClearAdd}
      handleRowClick={(rowIndex) => {
        if (rowIndex !== undefined) {
          history.push(
            links.getFormulaDetailsById(formulas?.[rowIndex]?.getId())
          );
        }
      }}
      handleSave={async (updates) => {
        if (editFormulas) {
          const editedFormulas = await editFormulas(
            updates.updatedData.map(prepareToSave)
          );
          setFormulas((prev) => [
            ...prev.filter(
              (plant) =>
                plant.getId() &&
                !editedFormulas
                  .map((plant) => plant.getId())
                  .includes(plant.getId())
            ),
            ...editedFormulas,
          ]);
        }
      }}
      loading={loading}
      // refreshData={updateData}
    />
  );
}
