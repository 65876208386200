import { ShippingMethodPrice } from '../entities/ShippingMethodPrice';
import { ShippingMethodPriceProvider } from './ShippingMethodPriceProvider';

export class GetAllShippingMethodPrices {
  private shippingMethodPriceProvider: ShippingMethodPriceProvider;

  public constructor(shippingMethodPriceProvider: ShippingMethodPriceProvider) {
    this.shippingMethodPriceProvider = shippingMethodPriceProvider;
  }

  public execute(): Promise<ShippingMethodPrice[]> {
    return this.shippingMethodPriceProvider.findAll();
  }
}
