import {
  CountryZone,
  ICountryZoneAttributes,
} from '../../core/entities/CountryZone';
import { CountryZoneProvider } from '../../core/use_cases/CountryZoneProvider';
import { gql, ApolloClient } from '@apollo/client';

export const LIST_COUNTRY_ZONES = gql`
  query {
    listCountryZones {
      id
      name
      countries {
        id
        name
        code
      }
      # Add here your attributes
    }
  }
`;

export const EDIT_MULTIPLE_COUNTRY_ZONES = gql`
  mutation editManyCountryZones($input: [CountryZoneInput]) {
    editManyCountryZones(input: $input) {
      id
      name
      countries {
        id
        name
        code
      }
    }
  }
`;

export type ICountryZoneAttributesData = ICountryZoneAttributes & {
  id: string;
};

export interface ListCountryZonesData {
  listCountryZones: Array<ICountryZoneAttributesData>;
}

export interface EditManyCountryZonesData {
  editManyCountryZones: Array<ICountryZoneAttributesData>;
}

export class CountryZoneGraphQLProvider implements CountryZoneProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async findAll(): Promise<CountryZone[]> {
    try {
      const result = await this.client?.query<ListCountryZonesData>({
        query: LIST_COUNTRY_ZONES,
      });
      return result.data.listCountryZones.map((item) => new CountryZone(item));
    } catch (e) {
      console.log(e);
    }
  }
  save(countryZone: CountryZone, userId?: string): Promise<CountryZone> {
    throw new Error('Method not implemented.');
  }
  async editMany(
    entities: ICountryZoneAttributes[],
    userId?: string
  ): Promise<CountryZone[]> {
    try {
      const result = await this.client?.mutate<
        EditManyCountryZonesData,
        { input: ICountryZoneAttributes[] }
      >({
        mutation: EDIT_MULTIPLE_COUNTRY_ZONES,
        variables: {
          input: entities,
        },
      });
      return result.data?.editManyCountryZones?.map(
        (item) => new CountryZone(item)
      );
    } catch (e) {
      console.log(e);
    }
  }
}
