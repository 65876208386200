export default {
  availableTypes: {
    email: 'Por email',
    push: 'En la aplicacion',
  },
  docSubtitle: 'configuraciones',
  greetings: 'Buenos dias',
  linkText: 'Notificaciones',
  notifierLabel: 'Ser notificado cuando ...',
  snackbars: {
    okEmailDisabled:
      'Notificación por correo electrónico Desactivado correctamente',
    okEmailEnabled: 'Notificación de correo electrónico exitosa',
    okPushDisabled: 'Notificación en la aplicación desactivada correctamente',
    okPushEnabled: 'Notificación en la aplicación activada con éxito',
  },
};
