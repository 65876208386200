import { Carrier, ICarrierAttributes } from '../../core/entities/Carrier';
import { CarrierProvider } from '../../core/use_cases/CarrierProvider';
import { gql, ApolloClient } from '@apollo/client';

export const LIST_CARRIERS = gql`
  query {
    listCarriers {
      id
      name
      code
      trackingLink
      # Add here your attributes
    }
  }
`;

export const EDIT_MULTIPLE_CARRIERS = gql`
  mutation editManyCarriers($input: [CarrierInput]) {
    editManyCarriers(input: $input) {
      id
      name
      code
      trackingLink
    }
  }
`;

export type ICarrierAttributesData = ICarrierAttributes & {
  id: string;
};

export interface ListCarriersData {
  listCarriers: Array<ICarrierAttributesData>;
}

export interface EditManyCarriersData {
  editManyCarriers: Array<ICarrierAttributesData>;
}

export class CarrierGraphQLProvider implements CarrierProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async findAll(): Promise<Carrier[]> {
    try {
      const result = await this.client?.query<ListCarriersData>({
        query: LIST_CARRIERS,
      });
      return result.data.listCarriers.map((item) => new Carrier(item));
    } catch (e) {
      console.log(e);
    }
  }
  save(carrier: Carrier, userId?: string): Promise<Carrier> {
    throw new Error('Method not implemented.');
  }
  async editMany(
    entities: ICarrierAttributes[],
    userId?: string
  ): Promise<Carrier[]> {
    try {
      const result = await this.client?.mutate<
        EditManyCarriersData,
        { input: ICarrierAttributes[] }
      >({
        mutation: EDIT_MULTIPLE_CARRIERS,
        variables: {
          input: entities,
        },
      });
      return result.data?.editManyCarriers?.map((item) => new Carrier(item));
    } catch (e) {
      console.log(e);
    }
  }
}
