export default {
  addUserTitle: 'Ajouter un utilisateur',
  dataTable: {
    columns: {
      createdAt: 'Inscription',
      docStatus: 'Status du document',
      docType: 'Type de document',
      email: 'Email',
      GDPR: 'RGPD',
      lastLogin: 'Dernière connexion',
      partnerRevenueAmount: 'Montant de la récompense',
      partnerRevenuePlan: 'Plan de récompense',
      role: 'Rôle',
      subStatus: 'Statut abo',
      verified: 'Email vérifié',
    },
    values: {
      empty: 'Null',
    },
  },
  editUserTitle: 'Modifier un utilisateur',
  enum: {
    docStatus: {
      contact: 'Relancé',
      pending: 'En attente',
      received: 'Reçu',
      verified: 'Vérifié',
    },
    docType: {
      diploma: 'Diplôme',
      trainingCertificate: 'Certificat de scolarité',
    },
    partnerRevenuePlan: {
      costPerAcquisition: 'Coût par acquisition',
      revenueShare: 'Partage du revenu',
    },
  },
  errors: {
    requiredField: 'Champ obligatoire',
  },
  notificationsManagement: {
    form: {
      metadata: {
        link: 'Lien',
        text: 'Contenu de la notification',
      },
      sendTo: 'Envoyer à',
      sendToOptions: {
        admin: 'Rôle admin',
        all: 'Tous les utilisateurs',
        beta: 'Rôle bêta',
        partner: 'Rôle partenaire',
        patient: 'Rôle patient',
        pharmaco: 'Rôle pharmaco',
        practitioner: 'Rôle praticien',
      },
      type: 'Type de notification',
    },
    title: 'Envoyer des notifications',
  },
  snackbars: {
    errorEdit: "Une erreur est survenue durant l'enregistrement",
    successAdd: 'Ajouté avec succès',
    successEdit: 'Modifié avec succès',
  },
  usersManagement: {
    title: 'Gestion des utilisateurs',
  },
};
