import { TranslatableAttribute } from '@kiway/shared/react-types';

export interface IVariationAttributeAttributes {
  name: string;
  displayName: TranslatableAttribute;
  description: TranslatableAttribute;
}

export class VariationAttribute {
  static getSearchableAttributes() {
    return [
      'displayName.fr',
      'displayName.en',
      'displayName.es',
      'displayName.pt',
      'name',
    ];
  }

  protected id: string;
  protected name: string;
  protected displayName: TranslatableAttribute;
  protected description: TranslatableAttribute;

  public constructor(
    obj: Partial<IVariationAttributeAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    this.name = obj?.name;
    this.displayName = obj?.displayName;
    this.description = obj?.description;
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): VariationAttribute {
    this.id = id;
    return this;
  }

  public getName(): string {
    return this.name;
  }

  public getDisplayName(): TranslatableAttribute {
    return this.displayName;
  }

  public getDescription(): TranslatableAttribute {
    return this.description;
  }

  public toDatatableRow(): any {
    return {
      _id: this.getId(),
      name: this.getName(),
      'displayName.fr': this.getDisplayName()?.fr,
      'displayName.en': this.getDisplayName()?.en,
      'displayName.es': this.getDisplayName()?.es,
      'displayName.pt': this.getDisplayName()?.pt,
    };
  }
}
