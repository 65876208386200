type EventName = string;
type AnyCallback = (...args: any[]) => any;

export class EventEmitter {
  protected static instance: EventEmitter;
  protected listeners = {};

  static getInstance(): EventEmitter {
    if (!EventEmitter.instance) {
      new EventEmitter();
    }
    return EventEmitter.instance;
  }

  private constructor() {
    EventEmitter.instance = this;
  }

  addListener(eventName: EventName, fn: AnyCallback) {
    this.listeners[eventName] = this.listeners[eventName] || [];
    this.listeners[eventName].push(fn);
    return this;
  }

  on(eventName: EventName, fn: AnyCallback) {
    return this.addListener(eventName, fn);
  }

  once(eventName: EventName, fn: AnyCallback) {
    this.listeners[eventName] = this.listeners[eventName] || [];
    const onceWrapper = () => {
      fn();
      this.off(eventName, onceWrapper);
    };
    this.listeners[eventName].push(onceWrapper);
    return this;
  }

  off(eventName: EventName, fn: AnyCallback) {
    return this.removeListener(eventName, fn);
  }

  removeListener(eventName: EventName, fn: AnyCallback) {
    const lis = this.listeners[eventName];
    if (!lis) return this;
    for (let i = lis.length; i > 0; i--) {
      if (lis[i] === fn) {
        lis.splice(i, 1);
        break;
      }
    }
    return this;
  }

  emit(eventName: EventName, ...args) {
    const fns = this.listeners[eventName];
    if (!fns) return false;
    fns.forEach((f) => {
      f(...args);
    });
    return true;
  }

  listenerCount(eventName: EventName) {
    const fns = this.listeners[eventName] || [];
    return fns.length;
  }

  rawListeners(eventName: EventName) {
    return this.listeners[eventName];
  }
}
