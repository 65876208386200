import * as React from 'react';
import { OrderValidation } from './OrderValidation';
import { useOrdersGateway } from '@kiway/ecommerce-react';
import { PharmacoOrder } from '../../../core/entities/PharmacoOrder';
import { links } from '../../../config';
import { Redirect } from 'react-router-dom';

export function OrderValidationController(props) {
  const { findOne, editOrders } = useOrdersGateway();
  const [loading, setLoading] = React.useState(false);
  const [order, setOrder] = React.useState<PharmacoOrder[]>([]);

  React.useEffect(() => {
    setLoading(true);
    if (props.id) {
      findOne(props.id)
        .then(async (order) => {
          if (order && order.getId()) {
            setOrder([
              new PharmacoOrder({
                order,
                saveCallback: handleSaveOrder,
              }).createNewTempFormula([]),
            ]);
          }
        })
        .finally(() => setLoading(false));
    }
    return () => {
      setOrder([]);
    };
  }, [props.id]);

  const handleSaveOrder = (forcedOrder?: PharmacoOrder) => {
    editOrders([(forcedOrder ?? order[0])?.getOrder()?.toInput(true)]).then(
      (order) => {
        if (order) {
          setOrder([
            new PharmacoOrder({
              order: order?.[0],
              saveCallback: handleSaveOrder,
            }).createNewTempFormula([]),
          ]);
        }
      }
    );
  };

  return order?.[0]?.getOrder()?.isDeleted() ? (
    <Redirect to={`/${links.main}/${links.listOrders}`} />
  ) : (
    <OrderValidation
      order={order?.[0]?.getOrder()}
      formulas={order?.[0]?.getFormulas()}
      handlePayNow={() => {
        props.history.push(
          links.getOrderCheckoutScreen(order?.[0]?.getOrder()?.getId())
        );
      }}
    />
  );
}
