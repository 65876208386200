import { Currency } from './Currency';

export const CurrencyEUR: Currency = new Currency({
  symbol: '€',
  code: 'EUR',
  format: '{{amount}} {{symbol}}',
});

export const CurrencyUSD: Currency = new Currency({
  symbol: '$',
  code: 'USD',
  format: '{{symbol}} {{amount}}',
});
