export default {
  chiefComplaint: {
    buttons: {
      add: 'Add chief complaint',
      update: 'Update chief complaint',
    },
    deleteModal: {
      content:
        'Are you sure you want to delete this chief complaint? The operation is irreversible.',
      title: 'Delete chief complaint',
    },
    fields: {
      consultationPlaces: 'Consultation places',
      duration: 'Duration',
      name: 'Chief complaint',
      price: 'Price',
      specialty: 'Specialty',
    },
    label: {
      plural: 'Chief Complaints',
    },
    list: 'List of your chief complaints',
    none: 'You have no chief complaint at the moment',
    requirements:
      'You must fill in at least one specialty and one workplace to add chief complaints',
    slotInfo:
      'When editing or removing a chief complaint, please keep in mind that you have to updade your week templates accordingly.',
    snackbars: {
      error: {
        create:
          'A problem occurred while saving the chief complaint. Please try again.',
        delete:
          'A problem occurred while deleting the chief complaint. Please try again.',
        deleteExistingChilds:
          "This chief complaint is used by appointments or week templates, you can't delete it.",
        update:
          'A problem occurred while updating the chief complaint. Please try again.',
        validation: 'You must fill in all the mandatory fields (*)',
      },
      success: {
        create: 'Chief complaint successfully saved',
        delete: 'Chief complaint successfully deleted',
        update: 'Chief complaint successfully updated',
      },
    },
    subtitle:
      'You can associate chief complaints with specialties and workplaces. Chief complaints will be displayed during online booking process once the specialty and place are chosen.\n\nEach chief complaint can be associated with different session duration and price, e.g. :\n\n*  "First Acupuncture session" lasts 90min and costs 60€ \n*  "Follow up Acupuncture session" lasts 60min and costs 50€',
    title: 'Manage your main chief complaints',
  },
  invoicing: {
    addressInfo: 'Head Office Address',
    goToProfile: 'Edit this information',
    goToSettings: 'Set up invoicing',
    invoiceAddressInfo: 'Invoicing Address',
    invoiceAddressInfoExplain:
      'Invoicing address depends on consultation place',
    invoiceNumbering: 'Invoice Numbering',
    labels: {
      businessName: 'Business Name',
      invoiceDigits: 'Number Of Digits',
      invoiceNextDigit: 'Next Invoice Digit',
      invoicePrefix: 'Invoice Number Prefix',
      invoiceSuffix: 'Invoice Number Suffix',
      naf: 'NAF Code',
      siret: 'Number SIRET/SIREN',
      specialMentions: 'Special Mentions',
      tva: 'VAT',
    },
    legalInfo: 'Legal Information',
    legalInfoSubtitle:
      'This information will be displayed at the bottom of the invoice',
    needInvoicingDataToBillSession:
      'You need to complete invoicing settings before',
    nextInvoiceNumberExample: 'Next invoice will be numbered as follow',
    placeholders: {
      specialMentions: 'Any tax systems',
    },
    resultBottomInvoice: 'Invoice bottom preview',
    saveChangesBeforeEditUser:
      'Save or cancel your changes before edit this information',
    snackbars: {
      errorUpdate: 'Something went wrong, please retry later',
      successUpdate: 'Settings have been successfully updated',
    },
  },
  minisite: {
    contactInfo: {
      label:
        'Enable/Disable displaying of your contact information (phone number)',
      phoneChoice: 'Choice of phone number to display on your public page',
      snackbars: {
        error: {
          update:
            'Something went wrong while updating your contact information status. Please try again later.',
        },
        success: {
          update:
            'Your contact information status has been successfully updated',
        },
      },
    },
    content: {
      description: {
        label: 'Description',
        previewLabel: 'Description preview',
      },
      displaySanitary: 'Display sanitary instructions',
      formation: {
        label: 'Education',
        previewLabel: 'Education preview',
      },
      placeholderSanitary: 'Write your instructions here...*',
      snackbars: {
        error: {
          sanitary:
            'Please fill out or disable sanitary information before saving',
          update:
            'Something went wrong while updating your public page content. Please try again later.',
        },
        success: {
          update: 'Your public page content has been successfully updated',
        },
      },
      styling:
        'Available styles: \n* **Bold**: surround your text by two asteriks on each side: &ast;&ast;bold text&ast;&ast;\n* Unordered list: start the line with an asterisk followed by at least one space: &ast; Item \n',
      subtitle: 'Here you can describe your practice and tell more about you.',
      title: 'Add content to your public page',
      titleInformation: 'Professional Information',
      titleSanitary: 'Sanitary Instructions (COVID 19)',
    },
    displayMinisite: {
      label: 'Enable/Disable your public page',
      snackbars: {
        error: {
          update:
            'Something went wrong while updating your public page status. Please try again later.',
        },
        success: {
          update: 'Your public page status has been successfully updated',
        },
      },
    },
    subtitle:
      'This page will improve your exposure and allows online booking.\n\nShould you prefer not to display this page, you can disable it at any time',
    title: 'Settings of your public page',
    uploads: {
      gallery: {
        snackbars: {
          error: {
            delete:
              'Something went wrong while deleting files. Please try again later.',
            upload:
              'Something went wrong while uploading files. Please try again later.',
          },
          success: {
            delete: 'Files have been successfully deleted',
            upload: 'Files have been successfully uploaded',
          },
        },
        subtitle:
          'These pictures will be displayed in a gallery on your public page',
        title: 'Pictures gallery',
      },
      profile: {
        snackbars: {
          error: {
            delete:
              'Something went wrong while deleting this file. Please try again later.',
            upload:
              'Something went wrong while uploading this file. Please try again later.',
          },
          success: {
            delete: 'File has been successfully deleted',
            upload: 'File has been successfully uploaded',
          },
        },
        subtitle: 'This picture will be displayed as your profile on your page',
        title: 'Profile picture',
      },
      subtitle:
        'Please note that uploaded pictures will be public as long as your page is enabled. You can add or remove pictures at any time.',
      title: 'Upload your pictures',
    },
    urlSettings: {
      confirmModalContent:
        'Any changes to the slug will make the previous URL obsolete. Do you want to proceed?',
      confirmModalTitle: 'Confirm',
      doubleSlug: 'Already exists',
      errorSave: 'Something went wrong while saving. Please try again later.',
      lock:
        'Enable/Disable modifications (if you change settings please remember that your URL will be different)',
      mainSpecialty: {
        label: 'Main specialty',
      },
      mainWorkplace: {
        label: 'Main workplace',
      },
      preview: {
        label: 'Preview of your URL',
      },
      refresh:
        'If any "worplace" and "specialty" are displayed, please refresh.',
      slug: {
        label: 'Customized name',
      },
      subtitle: 'The customized name will allow to have a unique URL',
      successSave: 'Successfully saved',
      title: 'Settings of your public page URL',
    },
  },
  onBoardingInfo:
    'Welcome to {{app}}.\n\nPlease fill in your professional information in order to take full advantage of our platform.\n\nYou just have to follow the steps below and you will be ready to go.\n\nSincerely, the {{app}} team !',
  places: {
    buttons: {
      add: 'Add workplace',
      update: 'Update workplace',
    },
    deleteModal: {
      content:
        'Are you sure you want to delete this workplace? The operation is irreversible.',
      title: 'Delete workplace',
    },
    label: {
      plural: 'Consultation Places',
    },
    list: 'List of your consultation places',
    none: 'You have no consultation place at the moment',
    previewInfo: 'Preview the result on your public page',
    slotInfo:
      'When editing or removing a workplace, please keep in mind that you have to updade your week templates accordingly.',
    snackbars: {
      error: {
        create:
          'A problem occurred while saving the workplace. Please try again.',
        delete:
          'A problem occurred while deleting the workplace. Please try again.',
        deleteExistingChilds:
          "This workplace is used by chief complaints, appointments or week templates, you can't delete it.",
        update:
          'A problem occurred while updating the workplace. Please try again.',
      },
      success: {
        create: 'Workplace successfully saved',
        delete: 'Workplace successfully deleted',
        update: 'Workplace successfully updated',
      },
    },
    subtitle:
      'Please enter your different workplaces, they will be suggested for online booking.',
    title: 'Manage your workplaces',
  },
  snackbars: {
    error: {
      form: {
        errorsNotResolved: 'You must resolve the errors and submit again.',
        noAddress:
          'Please enter a valid address. Make sure to click on the suggestion corresponding to your address when searching.',
        noName: 'Please enter a name for your workspace.',
      },
      rightsIssue:
        'An error prevents the sending of the request, it seems to be a problem of rights',
    },
  },
  specialties: {
    info:
      'Specialties are saved upon completion. When removing a specialty, please keep in mind that you have to updade your week templates accordingly.',
    label: {
      plural: 'Specialties',
      singular: 'Specialty',
    },
    pickOne: 'Choose specialty',
    snackbars: {
      error: {
        deleteExistingChilds:
          "This specialty is used by chief complaints, you can't delete it.",
        update:
          'A problem occurred while saving your specialties. Please try again.',
      },
      success: {
        update: 'Specialties successfully saved',
      },
    },
    subtitle: 'Please select specialties that you want to offer.',
    title: 'Manage your specialties',
  },
  subtitle:
    'These information are required for the platform to work properly. They will be displayed on your public page to introduce your professional activity. They will also allow us to set up online booking.',
  tabs: {
    activity: 'Activity',
    agenda: 'Online Booking',
    invoicing: 'Invoicing',
    publicPage: 'Public page',
    workplaces: 'Workplaces',
  },
  title: 'Professional information settings',
};
