import * as React from 'react';
import { TableCell, TableRow } from '@mui/material';
import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useStyles } from '../../shared/styles';
import { useTranslation } from '@kiway/shared/utils/translation';
import { TimelineDot } from '@mui/lab';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CAPSULE_WEIGHT } from '../../../../core/entities/PharmacoOrder';

export function FormulaPowderNav(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [type, setType] = React.useState<'CP' | 'PS'>(props.dosageMode);

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    if (newValue === 'PS' || newValue === 'CP') {
      setType(newValue);
      props.handleChangeDosageMode(props.formulaId, newValue);
    }
  };

  return (
    <>
      <TableCell
        width="2%"
        className={classes.tabTitles}
        align="left"
      ></TableCell>
      <TableCell
        width="3%"
        className={classes.tabTitles}
        align="left"
      ></TableCell>
      <TableCell width="25%" className={classes.tabTitles} align="left">
        <b>{t('pharmaco:formula.pinYinName')}</b>
      </TableCell>
      <TableCell width="15%" className={classes.tabTitles} align="right">
        <Select
          value={type}
          onChange={handleChange}
          size="small"
          variant="standard"
          fullWidth
          style={{ fontWeight: 700, fontSize: '0.875rem' }}
        >
          <MenuItem value={'PS'}>{t('pharmaco:formula.dosagePlant')}</MenuItem>
          <MenuItem value={'CP'}>{t('pharmaco:formula.dosagePowder')}</MenuItem>
        </Select>
      </TableCell>
      <TableCell width="10%" className={classes.tabTitles} align="center">
        <b>{t('pharmaco:formula.concentration')}</b>
      </TableCell>
      {props.dosageMode === 'PS' ? (
        <TableCell width="10%" className={classes.tabTitles} align="right">
          <b>{t('pharmaco:formula.equivalentCP')}</b>
        </TableCell>
      ) : (
        <TableCell width="10%" className={classes.tabTitles} align="right">
          <b>{t('pharmaco:formula.equivalentPS')}</b>
        </TableCell>
      )}
      <TableCell width="10%" className={classes.tabTitles} align="right">
        <b>{t('pharmaco:formula.percent')}</b>
      </TableCell>
      <TableCell width="10%" className={classes.tabTitles} align="right">
        <b>
          {props.isCapsule
            ? t('pharmaco:formula.weightPerCapsule')
            : t('pharmaco:formula.weightPerPortion')}
        </b>
      </TableCell>
      <TableCell width="10%" className={classes.tabTitles} align="right">
        <b>{t('pharmaco:formula.totalWeight')}</b>
      </TableCell>
      <TableCell width="10%" className={classes.tabTitles} align="right">
        <b>{t('pharmaco:formula.priceHT')}</b>
      </TableCell>
    </>
  );
}

export function FormulaPowderBody(props) {
  const { t } = useTranslation();

  return (
    <>
      {props.rows.map((row, index) => (
        <RowFormula
          key={index}
          dosageMode={props.dosageMode}
          row={row}
          handleChangeDosage={props.handleChangeDosage}
          handleSaveDosage={props.handleSaveDosage}
          available={props.available}
          handleRemoveLineItem={props.handleRemoveLineItem}
          getRoundedItemTotalWeight={props.getRoundedItemTotalWeight}
          getRoundedItemPortionWeight={props.getRoundedItemPortionWeight}
        />
      ))}
      <TableRow>
        <TableCell style={{ padding: '6px' }} align="right">
          <Grid container justifyContent="center">
            <Grid item>
              {props.available ? (
                <TimelineDot color="success" />
              ) : (
                <TimelineDot color="error" />
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell colSpan={2} style={{ padding: '6px' }} align="right">
          <b>{t('pharmaco:formula.total')}</b>
        </TableCell>
        <TableCell style={{ padding: '6px' }} align="right">
          <b>
            {`${parseFloat(`${props.totalDosage}`)?.toFixed(1)}`.replace(
              /\s/g,
              '\u00A0'
            )}
          </b>
        </TableCell>
        <TableCell></TableCell>
        {props.dosageMode === 'PS' ? (
          <TableCell style={{ padding: '6px' }} align="right">
            <b>
              {`${parseFloat(`${props.equivalentCP}`)?.toFixed(2)} g`.replace(
                /\s/g,
                '\u00A0'
              )}
            </b>
          </TableCell>
        ) : (
          <TableCell style={{ padding: '6px' }} align="right">
            <b>
              {`${parseFloat(`${props.equivalentPS}`)?.toFixed(2)} g`.replace(
                /\s/g,
                '\u00A0'
              )}
            </b>
          </TableCell>
        )}
        <TableCell style={{ padding: '6px' }} align="right">
          <b>{props.totalPercent}</b>
        </TableCell>
        <TableCell style={{ padding: '6px' }} align="right">
          <b>
            {`${parseFloat(
              `${props.isCapsule ? CAPSULE_WEIGHT : props.portionWeight}`
            )?.toFixed(2)} g`.replace(/\s/g, '\u00A0')}
          </b>
        </TableCell>
        <TableCell style={{ padding: '6px' }} align="right">
          <b>
            {`${parseFloat(`${props.totalWeight}`)?.toFixed(2)} g`.replace(
              /\s/g,
              '\u00A0'
            )}
          </b>
        </TableCell>
        <TableCell style={{ padding: '6px' }} align="right">
          <b>{props.totalPrice?.toString()}</b>
        </TableCell>
      </TableRow>
    </>
  );
}

function RowFormula(props) {
  const classes = useStyles();
  const lineItem = props.row.getLineItem();

  return (
    <TableRow key={lineItem.getId()}>
      <TableCell style={{ padding: '6px' }} align="right">
        <Grid container justifyContent="center">
          <Grid item>
            {props.row.isAvailable() ? (
              <TimelineDot color="success" />
            ) : (
              <TimelineDot color="error" />
            )}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell scope="row" align="left">
        <IconButton
          onClick={() => props.handleRemoveLineItem(lineItem?.getId())}
        >
          <DeleteForeverIcon />
        </IconButton>
      </TableCell>
      <TableCell style={{ padding: '6px' }} scope="row">
        {lineItem?.getProduct()?.getName()?.fr}
      </TableCell>
      <TableCell style={{ padding: '6px' }} scope="row" align="right">
        <TextField
          type="number"
          className={classes.noArrowInputNumber}
          value={lineItem.getCustom()?.dosage || '0'}
          variant="outlined"
          size="small"
          onChange={props.handleChangeDosage(lineItem.getId())}
          onBlur={() => props.handleSaveDosage()}
        />
      </TableCell>
      <TableCell style={{ padding: '6px' }} scope="row" align="center">
        {`${lineItem.getProduct().getCustom()?.concentrationRate} : 1`}
      </TableCell>
      {props.dosageMode === 'PS' ? (
        <TableCell style={{ padding: '6px' }} scope="row" align="right">
          {props.row.getEquivalentCP()}
        </TableCell>
      ) : (
        <TableCell style={{ padding: '6px' }} scope="row" align="right">
          {props.row.getEquivalentPS()}
        </TableCell>
      )}
      <TableCell style={{ padding: '6px' }} scope="row" align="right">
        {props.row.getPercent()}
      </TableCell>
      <TableCell style={{ padding: '6px' }} scope="row" align="right">
        {`${parseFloat(
          `${props.getRoundedItemPortionWeight(lineItem?.getId())}`
        )?.toFixed(2)} g`.replace(/\s/g, '\u00A0')}
      </TableCell>
      <TableCell style={{ padding: '6px' }} scope="row" align="right">
        {`${parseFloat(
          `${props.getRoundedItemTotalWeight(lineItem?.getId())}`
        )?.toFixed(2)} g`.replace(/\s/g, '\u00A0')}
      </TableCell>
      <TableCell style={{ padding: '6px' }} scope="row" align="right">
        {lineItem.getTotalPrice()?.toString()}
      </TableCell>
    </TableRow>
  );
}
