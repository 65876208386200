import {
  StoredFile,
  IStoredFileAttributes,
} from '../../core/entities/StoredFile';
import { StoredFileProvider } from '../../core/use_cases/StoredFileProvider';
import { gql, ApolloClient } from '@apollo/client';

export const LIST_STOREDFILES = gql`
  query {
    listStoredFiles {
      id
      path
      bucket
      name
      contentType
      size
      encrypted
      custom
      # Add here your attributes
    }
  }
`;

export const DELETE_ONE_STORED_FILE = gql`
  mutation deleteOneStoredFile($fileId: String!) {
    deleteOneStoredFile(fileId: $fileId)
  }
`;

export type IStoredFileAttributesData = IStoredFileAttributes & {
  id: string;
};

export interface ListStoredFilesData {
  listStoredFiles: Array<IStoredFileAttributesData>;
}

export interface DeleteOneStoredFileData {
  deleteOneStoredFile: boolean;
}

export class StoredFileGraphQLProvider implements StoredFileProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async deleteOne(fileId: string): Promise<boolean> {
    try {
      const result = await this.client?.mutate<DeleteOneStoredFileData>({
        mutation: DELETE_ONE_STORED_FILE,
        variables: {
          fileId,
        },
      });
      return result.data.deleteOneStoredFile;
    } catch (e) {
      console.log(e);
    }
  }

  findOneById(id: string): Promise<StoredFile> {
    throw new Error('Method not implemented.');
  }

  async findAll(): Promise<StoredFile[]> {
    try {
      const result = await this.client?.query<ListStoredFilesData>({
        query: LIST_STOREDFILES,
      });
      return result.data.listStoredFiles.map((item) => new StoredFile(item));
    } catch (e) {
      console.log(e);
    }
  }
  save(storedFile: StoredFile, userId?: string): Promise<StoredFile> {
    throw new Error('Method not implemented.');
  }
  editMany(
    entities: IStoredFileAttributes[],
    userId?: string
  ): Promise<StoredFile[]> {
    throw new Error('Method not implemented.');
  }
}
