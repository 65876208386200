/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import { OrderShipment } from './OrderShipment';
import { useOrdersGateway } from '@kiway/ecommerce-react';
import { Order } from '@kiway/ecommerce-react-compatible';
import { links } from '../../../config';
import { AuthContext } from '@kiway/shared/features/authentication-react';

export function OrderShipmentController(props) {
  const { editOrders, findOne, generateLabel } = useOrdersGateway();
  const [loading, setLoading] = React.useState(false);
  const [loadingGenerateLabel, setLoadingGenerateLabel] = React.useState(false);
  const [order, setOrder] = React.useState<Order[]>([]);
  const {
    authState: { userInfo },
  } = React.useContext(AuthContext);

  React.useEffect(() => {
    setLoading(true);
    if (props.id) {
      findOne(props.id)
        .then(async (order) => {
          if (order && order.getId()) {
            setOrder([order]);
          }
        })
        .finally(() => setLoading(false));
    }
    return () => {
      setOrder([]);
    };
  }, [props.id]);

  const handleSaveOrder = async () => {
    return await editOrders([order?.[0]?.toInput(true)]).then((order) =>
      setOrder(order)
    );
  };

  const handleGenerateLabel = () => {
    setLoadingGenerateLabel(true);
    generateLabel(order?.[0]?.getId())
      .then((order) => setOrder([order]))
      .finally(() => setLoadingGenerateLabel(false));
  };

  const handleClickFinish = async () => {
    order?.[0]?.endShipment(userInfo?._id);
    setOrder([order?.[0]]);
    await handleSaveOrder();
    window.location.href = links.getOrderDetailsScreen(props.id);
  };

  return (
    <OrderShipment
      handleClickFinish={handleClickFinish}
      handleGenerateLabel={handleGenerateLabel}
      handleSave={handleSaveOrder}
      order={order?.[0]}
      loading={loading}
      loadingGenerateLabel={loadingGenerateLabel}
    />
  );
}
