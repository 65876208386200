import { Card, CardContent } from '@mui/material';
import React from 'react';

interface ChartCardProps {
  sx: any;
  children: any;
}

export const ChartCard = (props: React.PropsWithChildren<ChartCardProps>) => {
  return (
    <Card
      elevation={0}
      sx={{
        ...props.sx,
        border: '1px solid lightgrey',
      }}
    >
      {<CardContent>{props.children}</CardContent>}
    </Card>
  );
};
