import * as React from 'react';
import { Typography } from '@material-ui/core';
import { TaxMode } from '../../../core/entities/TaxMode';
import { Skeleton } from '@material-ui/lab';

export interface ListTaxModesProps {
  taxModes: TaxMode[];
  loading?: boolean;
}

const renderTaxMode = (item: TaxMode) => (
  <li key={item.getId()}>{item.getId()}</li>
);

const loadingTaxModeScreen = (
  <div>
    <ul>
      <li>
        <Skeleton variant="text" />
      </li>
      <li>
        <Skeleton variant="text" />
      </li>
      <li>
        <Skeleton variant="text" />
      </li>
    </ul>
  </div>
);

export function ListTaxModes({
  loading,
  taxModes,
}: React.PropsWithChildren<ListTaxModesProps>) {
  return (
    <div>
      <Typography variant="h5">TaxMode :</Typography>
      {loading ? (
        loadingTaxModeScreen
      ) : (
        <div>
          <ul>{taxModes?.length ? taxModes?.map(renderTaxMode) : 'Aucune donnée'}</ul>
        </div>
      )}
    </div>
  );
}
