import { useTranslation } from '@kiway/shared/utils/translation';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

// chart options
const barChartOptions = {
  chart: {
    type: 'bar',
    height: 365,
    toolbar: {
      show: false,
    },
  },
  colors: ['#A9A9A9', '#1D90D6', '#34D548', '#D53535'],
  plotOptions: {
    bar: {
      columnWidth: '50%',
      borderRadius: 4,
      distributed: true,
    },
  },
  legend: {
    show: false,
  },
  xaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
  },
  grid: {
    show: false,
  },
  noData: {
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: '#000000',
      fontSize: '14px',
      fontFamily: 'Helvetica',
    },
  },
};
interface StatusChartProps {
  period: string;
  data: any;
  loading: boolean;
}

export const StatusChart = (props: StatusChartProps) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<any>(barChartOptions);
  const [series, setSeries] = useState<any[]>([]);

  useEffect(() => {
    if (!props.loading) {
      setSeries(props.data?.series ?? []);
    }
  }, [props.loading, props.data?.series]);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      ...barChartOptions,
      xaxis: {
        categories:
          props.loading || series?.length <= 0
            ? ['']
            : [
                t('pharmaco:orderDetails.order.status.DRAFT'),
                t('pharmaco:orders.columns.openedAt'),
                t('pharmaco:orderDetails.global.status.VALIDATED'),
                t('pharmaco:orderDetails.packing.status.PREPARED'),
              ],
        labels: {
          show: props.loading ? false : true,
        },
      },
      noData: {
        ...options?.noData,
        text: props.loading ? t('pharmaco:loading') : t('pharmaco:noData'),
      },
    }));
  }, [props.loading, series]);

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={props.loading ? [] : series}
        type="bar"
        height={250}
      />
    </div>
  );
};
