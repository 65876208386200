// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DatatablesController, DropdownValue } from '@kiway/datatables';
import { deepen } from '@kiway/shared/utils/string';
import { useTranslation } from '@kiway/shared/utils/translation';
import * as React from 'react';
import { ShippingMethod } from '../../../core/entities/ShippingMethod';
import { CountryZone } from '../../../core/entities/CountryZone';

export interface ListShippingMethodsProps {
  availableCountryZones: DropdownValue[];
  availableCarriers: DropdownValue[];
  shippingMethods: ShippingMethod[];
  handleAddData: () => void;
  handleClearAdd: () => void;
  handleSave?: any;
  loading?: boolean;
  rawAvailableCountryZones?: CountryZone[];
}

const prepareToSave = (item) => {
  return {
    ...deepen(item),
    id: item._id,
    _id: undefined,
    displayOrder: Number(item.displayOrder),
    pricesLength: undefined,
    prices: item.prices?.map((price) => price.getId()) ?? [],
  };
};

export function ListShippingMethods(
  props: React.PropsWithChildren<ListShippingMethodsProps>
) {
  const { t } = useTranslation();
  return (
    <DatatablesController
      bulkEdit={true}
      handleAddData={props.handleAddData}
      handleClearAdd={props.handleClearAdd}
      handleSaveData={
        props.handleSave
          ? (updates) => {
              if (props.handleSave) {
                return props.handleSave(updates.updatedData.map(prepareToSave));
              }
            }
          : undefined
      }
      columns={[
        {
          name: '_id',
          label: 'ID',
          type: 'text',
          options: {
            display: false,
            edit: false,
            filter: false,
          },
        },
        {
          name: 'name.fr',
          label: t(`pharmaco:shippingMethods.label.name.fr`),
          type: 'text',
        },
        {
          name: 'name.en',
          label: t(`pharmaco:shippingMethods.label.name.en`),
          type: 'text',
        },
        {
          name: 'name.es',
          label: t(`pharmaco:shippingMethods.label.name.es`),
          type: 'text',
        },
        {
          name: 'name.pt',
          label: t(`pharmaco:shippingMethods.label.name.pt`),
          type: 'text',
        },
        {
          name: 'active',
          label: t(`pharmaco:shippingMethods.label.active`),
          type: 'boolean',
        },
        {
          name: 'displayOrder',
          label: t(`pharmaco:shippingMethods.label.displayOrder`),
          type: 'number',
        },
        {
          name: 'productCode',
          label: t(`pharmaco:shippingMethods.label.productCode`),
          type: 'text',
        },
        {
          name: 'carrier',
          label: t(`pharmaco:shippingMethods.label.carrier`),
          type: 'dropdown',
          options: {
            availableValues: props.availableCarriers,
          },
        },
        {
          name: 'countryZone',
          label: t(`pharmaco:shippingMethodPrices.countryZone`),
          type: 'dropdown',
          options: {
            availableValues: props.availableCountryZones,
          },
        },
        {
          name: 'pricesLength',
          label: t(`pharmaco:shippingMethods.label.pricesLength`),
          type: 'standard',
        },
      ]}
      data={props.shippingMethods?.map((method) => method.toDatatableRow())}
      defaultSortOrder={{ name: 'name.fr', direction: 'asc' }}
      id="listShippingMethods"
      loading={props.loading}
      options={{
        download: false,
        filter: false,
        print: false,
      }}
      title={t(`pharmaco:shippingMethods.linkText`)}
    />
  );
}
