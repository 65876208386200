import * as React from 'react';
import { Typography } from '@material-ui/core';
import { StoredFile } from '../../../core/entities/StoredFile';
import { Skeleton } from '@material-ui/lab';

export interface ListStoredFilesProps {
  storedFiles: StoredFile[];
  loading?: boolean;
}

const renderStoredFile = (item: StoredFile) => (
  <li key={item.getId()}>{item.getId()}</li>
);

const loadingStoredFileScreen = (
  <div>
    <ul>
      <li>
        <Skeleton variant="text" />
      </li>
      <li>
        <Skeleton variant="text" />
      </li>
      <li>
        <Skeleton variant="text" />
      </li>
    </ul>
  </div>
);

export function ListStoredFiles({
  loading,
  storedFiles,
}: React.PropsWithChildren<ListStoredFilesProps>) {
  return (
    <div>
      <Typography variant="h5">StoredFile :</Typography>
      {loading ? (
        loadingStoredFileScreen
      ) : (
        <div>
          <ul>
            {storedFiles?.length
              ? storedFiles?.map(renderStoredFile)
              : 'Aucune donnée'}
          </ul>
        </div>
      )}
    </div>
  );
}
