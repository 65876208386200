export default {
  appointment:
    'Un nouveau rendez-vous a été pris en ligne. Cliquez sur la notification pour le consulter.',
  none: 'Aucune nouvelle notification',
  release: 'La version **{{version}}** est maintenant disponible',
  stripeConnect: {
    created:
      "Votre compte stripe a bien été connecté ! Il vous faut maintenant le valider pour utiliser le paiement en ligne. Vous avez du recevoir un email de Stripe, vous n'avez qu'à suivre les instructions.",
    validated:
      'Félicitations, votre compte stripe a été validé ! Vous pouvez dès à présent utiliser le paiement en ligne.',
  },
};
