import {
  OrderStat,
  OrderStatName,
  useOrdersGateway,
} from '@kiway/ecommerce-react';
import React from 'react';
import { Kpi } from './Kpi';

interface KpiControllerProps {
  title: string;
  statType: OrderStatName;
}

export const KpiController = (props: KpiControllerProps) => {
  const { getOrderStats } = useOrdersGateway();

  const [stats, setStats] = React.useState<OrderStat>();
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    getOrderStats('none', [props.statType])
      .then((result) => setStats(result[0]))
      .finally(() => setLoading(false));
    return () => {
      setStats(null);
      setLoading(false);
    };
  }, []);

  return (
    <Kpi title={props.title} data={stats?.data?.value} loading={loading} />
  );
};
