import * as React from 'react';
import PhoneNumber from 'awesome-phonenumber';
import { TextField } from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import { countryToFlag } from '@kiway/shared/utils/enums';

export type PhoneNumberType = 'fixed-line' | 'fixed-line-or-mobile' | 'mobile';
// | 'pager'
// | 'personal-number'
// | 'premium-rate'
// | 'shared-cost'
// | 'toll-free'
// | 'uan'
// | 'voip'
// | 'unknown';

export interface PhoneInputProps {
  disabled?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  forcePhoneNumberType?: PhoneNumberType;
  forceRegionCode?: string;
  label?: string;
  /**
   * Default to 5
   */
  minLengthBeforeThrowError?: number;
  onBlur?: (...args: any[]) => any;
  onChange: (newValue: string) => void;
  setIsValid?: (isValid: boolean) => void;
  value: string;
}

export function PhoneInput(props: PhoneInputProps) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(
    new PhoneNumber(props.value ?? '', props.forceRegionCode)
  );
  const input = value?.toJSON()?.number?.input ?? '';
  const getErrorWithText = () => {
    if (input?.length < (props.minLengthBeforeThrowError ?? 5)) {
      if (props.setIsValid) {
        props.setIsValid(true);
      }
      return [false, ''];
    }
    if (!value?.isValid()) {
      if (props.setIsValid) {
        props.setIsValid(false);
      }
      return [true, `possibility.${value.toJSON()?.possibility ?? 'default'}`];
    }
    if (
      props.forceRegionCode &&
      value?.getRegionCode()?.toUpperCase() !==
        props.forceRegionCode?.toUpperCase()
    ) {
      if (props.setIsValid) {
        props.setIsValid(false);
      }
      return [true, `possibility.wrongRegionCode`];
    }
    if (
      props.forcePhoneNumberType &&
      value.getType() !== props.forcePhoneNumberType
    ) {
      if (props.setIsValid) {
        props.setIsValid(false);
      }
      return [true, `specificType.${props.forcePhoneNumberType}`];
    }
    if (props.setIsValid) {
      props.setIsValid(true);
    }
    return [false, ''];
  };
  const [error, helperText] = getErrorWithText();
  React.useEffect(() => {
    setValue(new PhoneNumber(props.value, props.forceRegionCode));
  }, [props.value]);
  return (
    <TextField
      margin="dense"
      label={
        props.label ??
        (props.forcePhoneNumberType && props.forcePhoneNumberType === 'mobile')
          ? t('common:form.fields.phone.mobileLabel')
          : t('common:form.fields.phone.label')
      }
      fullWidth={props.fullWidth}
      size="small"
      value={value.getNumber('international') ?? input}
      error={error as boolean}
      InputProps={{
        startAdornment:
          props.forceRegionCode || value.getRegionCode() ? (
            <div style={{ marginRight: 5 }}>
              {countryToFlag(
                props.forceRegionCode
                  ? props.forceRegionCode
                  : value.getRegionCode()
              )}
            </div>
          ) : undefined,
      }}
      onBlur={
        !value.isValid() && (value.toJSON()?.number?.input ?? '')?.length > 0
          ? undefined
          : props.onBlur
      }
      onChange={(event) => {
        const phone = new PhoneNumber(
          event?.target?.value ?? '',
          props.forceRegionCode
        );
        setValue(phone);
        if (props.onChange) {
          props.onChange(
            (phone.toJSON()?.number?.input ?? '')?.replace(/ /g, '')
          );
        }
      }}
      helperText={
        helperText !== ''
          ? t(`common:form.fields.phone.error.${helperText}`)
          : value?.getRegionCode()
          ? ''
          : t('common:form.fields.phone.areaCodeInfo')
      }
      disabled={props.disabled}
      required={props.required}
    />
  );
}

export default PhoneInput;
