import { TranslatableAttribute } from '@kiway/shared/types';
import {
  VariationAttribute,
  IVariationAttributeAttributes,
} from './VariationAttribute';

export interface IVariationAttributeValueAttributes {
  value: any;
  displayValue: TranslatableAttribute;
  variationAttribute: IVariationAttributeAttributes;
}

export class VariationAttributeValue {
  protected id: string;
  protected value: any;
  protected displayValue: TranslatableAttribute;
  protected variationAttribute: VariationAttribute;

  public constructor(
    obj: IVariationAttributeValueAttributes & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    this.value = obj?.value;
    this.displayValue = obj?.displayValue;
    this.variationAttribute = new VariationAttribute(obj?.variationAttribute);
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): VariationAttributeValue {
    this.id = id;
    return this;
  }

  public isConcentratedPowder(): boolean {
    return (
      this.variationAttribute.getName() === 'plantPowderType' &&
      this.value === 'powder'
    );
  }

  public isPlant(): boolean {
    return (
      this.variationAttribute.getName() === 'plantPowderType' &&
      this.value === 'plant'
    );
  }
}
