import React from 'react';
import {
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TourSubSection } from './TourSubSection';
import { StepType } from '@reactour/tour';

export interface TourSubSectionProps {
  subSectionTitle: string;
  subSectionDescription: string;
  subSectionLink: string;
  subSectionSteps: Array<StepType>;
}
interface TourSectionProps {
  section: {
    name: string;
    description: string;
    subSections?: Array<TourSubSectionProps>;
  };
}

export function TourSection(props: TourSectionProps) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid item xs={12}>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">
            {t(`common:tourGuide.name.${props.section.name}`)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {props.section.subSections?.map((subSection) => {
                return (
                  <TourSubSection
                    key={subSection.subSectionTitle}
                    subSectionTitle={subSection.subSectionTitle}
                    subSectionDescription={subSection.subSectionDescription}
                    subSectionLink={subSection.subSectionLink}
                    subSectionSteps={subSection.subSectionSteps}
                  />
                );
              })}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
