export default {
  form: {
    backToHome: 'Volver al inicio',
    createPatient: 'Soy un paciente',
    createPractitioner: 'Soy un practicante',
    emailField: 'Correo electrónico',
    forgotPassword: 'contraseña olvidada',
    newPasswordField: 'Nueva contraseña',
    noAccount: 'Sin cuenta? Registro',
    oldPasswordField: 'antigua contraseña',
    passwordConfirmField: 'Confirmación',
    passwordField: 'Contraseña',
    passwordsNotGoodEnough:
      'La contraseña debe hacer al menos 8 caracteres, contener 1 capital, 1 pequeña, 1 figura, 1 carácter especial y no contener ningún espacio',
    passwordsNotMatch: 'Las contraseñas no son idénticas',
    referrerCodeField: 'Código de patrocinio',
    rememberMe: 'recordarse de mi',
    resetCredentialsNotOK: 'Solicitud vencida o ya completada',
    resetPasswordButton: 'Cambia la contraseña',
    resetPasswordEmailSent:
      'Si su dirección de correo electrónico es válida y presente en la base de datos, se le enviará un correo electrónico pronto. Haga clic en el enlace dentro del correo electrónico para restablecer su contraseña',
    resetPasswordFailTitle:
      'Se ha producido un problema, contraseña sin cambios',
    resetPasswordRequestButton: 'Correo electrónico',
    resetPasswordRequestTitle: 'Restablecer la contraseña',
    resetPasswordSuccessTitle: 'Contraseña cambiada correctamente',
    signInButton: 'Conexión',
    signInTitle: 'Conexión',
    signUpButton: 'Inscribirse',
    yesAccount: 'tengo una cuenta',
  },
  menu: {
    logout: 'Desconexión',
    profile: 'Mi perfil',
    tutorial: 'Tutorial',
  },
  onBoarding: {
    explain: {
      address:
        'Ingrese la dirección de su ubicación de consulta, se utilizará en correos electrónicos de confirmación para el paciente.',
      password:
        'Si aún no lo ha hecho, cambie su contraseña por razones de seguridad.',
      practices:
        'Sus prácticas son útiles para nosotros con fines estadísticos. Por el momento no están vinculados a las reuniones.',
    },
    save: {
      error:
        'Un problema ocurrió durante la Ilustración, gracias por intentarlo de nuevo',
      errorGDPR:
        'Debe verificar el cuadro GDPR para poder guardar su información',
      success: 'Modificaciones registradas',
    },
    steps: {
      address: {
        city: 'Ciudad',
        country: 'País',
        countrySearch: 'Buscar un país',
        search: 'Buscar una dirección',
        street: 'Calle',
        title: 'Ubicación de consulta',
        zipCode: 'Código postal',
      },
      changePassword: {
        title: 'cambia tu contraseña',
      },
      personalData: {
        title: 'Información personal',
      },
      practices: {
        title: 'Práctica',
      },
    },
    subTitleInfo:
      'Esta información es muy importante para nosotros y para el funcionamiento adecuado de la aplicación. Tómese 5 minutos para informarles.',
    title: '¡Cuéntanos además de ti!',
  },
  profile: {
    updateError: 'Ocurrió un error, por favor intente de nuevo',
    updateSuccess: 'Modificaciones registradas',
  },
  register: {
    button: 'Crea mi espacio',
    buttonResend: 'reenviar el código',
    certifyLegalRepresentative:
      'Certifico ser acompañado por mi representante legal *',
    disablePro:
      'El registro solo es posible como paciente o desde https://kiway.co para profesionales que desean ser parte del programa de creación co-creación',
    error: {
      birthDate: {
        ofAgePatient:
          'Debe ser mayor de edad o acompañado de su representante legal',
        ofAgePract: 'Debe ser mayor de edad para usar la aplicación',
      },
      email: 'Por favor, introduce una dirección de correo electrónico válida',
      emailExists: 'Ya existe una cuenta para este correo electrónico',
      emailVerif:
        'Se produjo un problema durante el envío del código para verificar su correo electrónico. Por favor intente de nuevo.',
      global: 'Se produjo un error, gracias por intentarlo nuevamente.',
      password: 'La contraseña no es válida',
      requiredFieldsEmpty:
        'Llene los campos requeridos informados por un asterisco (*)',
      verifCode: 'código incorrecto',
    },
    stepper: {
      credentials: 'Identificadores',
      emailVerification: 'Verificacion de email',
      personalData: 'Datos personales',
    },
    success:
      'Tu cuenta ha sido creada. Recibirá un correo electrónico en la dirección que ha informado para verificar que depende de usted. Solo tendrá que hacer clic en el enlace para validar su cuenta y que posteriormente puede conectarse.',
    title: 'Creación de tu espacio {{app}}',
    validation: {
      error:
        'Se produjo un error durante la verificación, intente nuevamente. Es posible que ya haya revisado su correo electrónico.',
      missingParams:
        'No se pudo verificar su correo electrónico, carece de parámetros de su solicitud. Verifique que haya copiado el enlace recibido en el correo electrónico.',
      ok:
        'Su correo electrónico ha sido verificado. Ahora puede conectarse haciendo clic en el botón de abajo.',
      title: 'Comprobando su correo electrónico',
    },
    verifCodeExplaination: 'Ingrese el código recibido por correo electrónico',
  },
};
