import React from 'react';
import { PaymentModal } from './PaymentModal';
import { Payment } from '../../../core/entities/Payment';
import moment, { Moment } from 'moment';
import { usePaymentsGateway } from '../../../entrypoints/react/usePaymentsGateway';

interface PaymentModalControllerProps {
  saveCallback?: (...args: any[]) => any;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPayment: Payment;
}

export const PaymentModalController = (props: PaymentModalControllerProps) => {
  const [tempDate, setTempDate] = React.useState<Moment>();
  const [paymentRef, setPaymentRef] = React.useState<string>();
  const { save } = usePaymentsGateway();

  React.useEffect(() => {
    setTempDate(moment(props.selectedPayment?.getCompletedAt()));
  }, [props.selectedPayment]);

  React.useEffect(() => {
    props.selectedPayment?.setBankTransferInfo('ref', paymentRef);
  }, [paymentRef]);

  const saveData = async () => {
    props.selectedPayment?.setStatus('PAID');
    const saved = await save(props.selectedPayment);
    if (props.saveCallback) {
      props.saveCallback();
    }
    return saved;
  };

  const setPaymentDate = (completedAt) => {
    props.selectedPayment?.setCompletedAt(completedAt);
  };

  return (
    <PaymentModal
      open={props.open}
      setOpen={props.setOpen}
      saveData={saveData}
      selectedPayment={props.selectedPayment}
      setPaymentDate={setPaymentDate}
      setTempDate={setTempDate}
      tempDate={tempDate}
      setPaymentRef={setPaymentRef}
      paymentRef={paymentRef}
    />
  );
};
