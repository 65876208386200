/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { OrderGraphQLProvider } from '../../dataproviders/graphql-client/OrderGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllOrders } from '../../core/use_cases/GetAllOrders';
import {
  IOrderAttributes,
  Order,
  OrderStat,
  OrderStatName,
  OrderStatPeriod,
} from '../../core/entities/Order';
import { GetOrder } from '../../core/use_cases/GetOrder';
import { EditManyOrders } from '../../core/use_cases/EditManyOrders';
import { CreateOrder, CustomerInput } from '../../core/use_cases/CreateOrder';
import { UserLastOrdersType } from '../../core/use_cases/OrderProvider';
import { IAddressAttributes } from '@kiway/shared/features/authentication-react-compatible';

class OrdersGateway {
  protected static instance: OrdersGateway;
  protected getAllOrders: GetAllOrders;
  protected getOrder: GetOrder;
  protected editManyOrders: EditManyOrders;
  protected orderProvider: OrderGraphQLProvider;
  protected createOrder: CreateOrder;

  public static getInstance(client: ApolloClient<any>): OrdersGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new OrdersGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const orderProvider = new OrderGraphQLProvider(client);
    this.orderProvider = orderProvider;
    this.getAllOrders = new GetAllOrders(orderProvider);
    this.getOrder = new GetOrder(orderProvider);
    this.editManyOrders = new EditManyOrders(orderProvider);
    this.createOrder = new CreateOrder(orderProvider);
    this.findAll = this.findAll.bind(this);
    this.findOne = this.findOne.bind(this);
    this.editOrders = this.editOrders.bind(this);
    this.createNewOrder = this.createNewOrder.bind(this);
    this.onOrderCreate = this.onOrderCreate.bind(this);
    this.changeOrderCustomer = this.changeOrderCustomer.bind(this);
    this.getLastOrders = this.getLastOrders.bind(this);
    this.getOrderPublicCheckout = this.getOrderPublicCheckout.bind(this);
    this.getOrderStripeCheckoutSession = this.getOrderStripeCheckoutSession.bind(
      this
    );
    this.getOrderSystempayCheckoutSession = this.getOrderSystempayCheckoutSession.bind(
      this
    );
    this.changeShippingMethod = this.changeShippingMethod.bind(this);
    this.changeShippingAddress = this.changeShippingAddress.bind(this);
    this.generateLabel = this.generateLabel.bind(this);
    this.sendPaymentReminderByMail = this.sendPaymentReminderByMail.bind(this);
    this.sendTrackingByMail = this.sendTrackingByMail.bind(this);
    this.getOrderStats = this.getOrderStats.bind(this);
    OrdersGateway.instance = this;
  }

  onOrderCreate(callback: (order: Order) => any): void {
    this.orderProvider.onOrderCreate(callback);
  }

  async changeOrderCustomer(
    orderId: string,
    customer: CustomerInput
  ): Promise<Order> {
    return await this.orderProvider.changeOrderCustomer(orderId, customer);
  }

  async createNewOrder(
    prescriber: CustomerInput,
    customer?: CustomerInput
  ): Promise<Order> {
    return await this.orderProvider.createOrder(prescriber, customer);
  }

  async findAll(): Promise<Order[]> {
    return await this.getAllOrders.execute();
  }

  async findOne(orderId: string): Promise<Order> {
    return await this.getOrder.execute(orderId);
  }

  async editOrders(orders: IOrderAttributes[]): Promise<Order[]> {
    return await this.editManyOrders.execute(orders, null);
  }

  async getLastOrders(
    userType: UserLastOrdersType,
    lastNb?: number,
    userId?: string
  ): Promise<Order[]> {
    return await this.orderProvider.findLastOrders(userType, lastNb, userId);
  }

  async getOrderPublicCheckout(
    orderId: string,
    checkEmail?: string
  ): Promise<Order> {
    return await this.orderProvider.getOrderPublicCheckout(orderId, checkEmail);
  }

  async changeShippingMethod(
    orderId: string,
    shippingMethodId: string
  ): Promise<any> {
    return await this.orderProvider.changeShippingMethod(
      orderId,
      shippingMethodId
    );
  }

  async changeShippingAddress(
    orderId: string,
    shippingAddress: IAddressAttributes
  ): Promise<any> {
    return await this.orderProvider.changeShippingAddress(
      orderId,
      shippingAddress
    );
  }

  async getOrderStripeCheckoutSession(
    orderId: string,
    successUrl: string,
    cancelUrl: string
  ): Promise<string> {
    return await this.orderProvider.getOrderStripeCheckoutSession(
      orderId,
      successUrl,
      cancelUrl
    );
  }

  async getOrderSystempayCheckoutSession(
    orderId: string,
    successUrl: string,
    cancelUrl: string,
    refusedUrl: string,
    errorUrl: string
  ): Promise<string> {
    return await this.orderProvider.getOrderSystempayCheckoutSession(
      orderId,
      successUrl,
      cancelUrl,
      refusedUrl,
      errorUrl
    );
  }

  async generateLabel(orderId: string): Promise<Order> {
    return await this.orderProvider.generateLabel(orderId);
  }

  async sendPaymentReminderByMail(orderId: string): Promise<boolean> {
    return await this.orderProvider.sendPaymentReminderByMail(orderId);
  }

  async sendTrackingByMail(orderId: string): Promise<boolean> {
    return await this.orderProvider.sendTrackingByMail(orderId);
  }

  async getOrderStats(
    period: OrderStatPeriod,
    statsNames: OrderStatName[]
  ): Promise<OrderStat[]> {
    return await this.orderProvider.getOrderStats(period, statsNames);
  }
}

export function useOrdersGateway() {
  const client = useApolloClient();
  const ordersGateway = OrdersGateway.getInstance(client);
  return {
    findAll: ordersGateway.findAll,
    findOne: ordersGateway.findOne,
    editOrders: ordersGateway.editOrders,
    createOrder: ordersGateway.createNewOrder,
    onOrderCreate: ordersGateway.onOrderCreate,
    changeOrderCustomer: ordersGateway.changeOrderCustomer,
    getLastOrders: ordersGateway.getLastOrders,
    getOrderPublicCheckout: ordersGateway.getOrderPublicCheckout,
    getOrderStripeCheckoutSession: ordersGateway.getOrderStripeCheckoutSession,
    getOrderSystempayCheckoutSession:
      ordersGateway.getOrderSystempayCheckoutSession,
    changeShippingMethod: ordersGateway.changeShippingMethod,
    changeShippingAddress: ordersGateway.changeShippingAddress,
    generateLabel: ordersGateway.generateLabel,
    sendPaymentReminderByMail: ordersGateway.sendPaymentReminderByMail,
    sendTrackingByMail: ordersGateway.sendTrackingByMail,
    getOrderStats: ordersGateway.getOrderStats,
  };
}
