import './email-input.module.css';
import * as React from 'react';
import { TextInput } from '../..';
import { useTranslation } from '@kiway/shared/utils/translation';
import translations from './translations';
import { KiwayLanguagesType } from '@kiway/shared/react-types';
import { emailRegex } from '@kiway/shared/utils/string';

export interface EmailInputProps {
  disabled?: boolean;
  displayOnPrimaryPage?: boolean;
  error?: string;
  fullWidth?: boolean;
  handleCustomError?: (emailValue: string) => any;
  label?: string;
  name?: string;
  onChange?: (...args: any[]) => any;
  required?: boolean;
  setError?: (error: string) => void;
  value?: string;
}

export function EmailInput(props: EmailInputProps) {
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState('');

  const { addTranslationDynamically, t } = useTranslation();
  Object.entries(translations).map(([lng, translation]) => {
    addTranslationDynamically(
      lng as KiwayLanguagesType,
      'email-input',
      translation,
      true
    );
  });

  const checkEmailError = (emailValue: string) => {
    if (props.handleCustomError) {
      props.handleCustomError(emailValue);
    } else {
      let errorMessage = '';
      if (emailValue?.length > 0 && !emailValue.match(emailRegex)) {
        errorMessage = t('email-input:invalid');
      }
      setError(errorMessage);
      if (props.setError) {
        props.setError(errorMessage);
      }
    }
  };

  React.useEffect(() => {
    checkEmailError(value ?? '');
  }, [value]);

  React.useEffect(() => {
    checkEmailError(props.value ?? '');
  }, [props.value]);

  const handleChange = (event: any) => {
    setValue(event?.target?.value ?? '');
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <TextInput
      disabled={props.disabled}
      displayOnPrimaryPage={props.displayOnPrimaryPage}
      error={(props.error ?? error) !== ''}
      fullWidth={props.fullWidth}
      helperText={props.error ?? error}
      label={props.label ?? t('email-input:label')}
      name={props.name}
      onChange={handleChange}
      required={props.required}
      value={props.value ?? value}
    />
  );
}

export default EmailInput;
