import { Country, ICountryAttributes } from '../../core/entities/Country';
import { CountryProvider } from '../../core/use_cases/CountryProvider';
import { gql, ApolloClient } from '@apollo/client';

export const LIST_COUNTRYS = gql`
  query {
    listCountries {
      id
      name
      code
      vatPlant
      vatPowder
      # Add here your attributes
    }
  }
`;

export const EDIT_MULTIPLE_PRODUCT_METADATAS = gql`
  mutation editManyCountries($input: [CountryInput]) {
    editManyCountries(input: $input) {
      id
      name
      code
      vatPlant
      vatPowder
    }
  }
`;

export type ICountryAttributesData = ICountryAttributes & {
  id: string;
};

export interface ListCountriesData {
  listCountries: Array<ICountryAttributesData>;
}

export interface EditManyCountriesData {
  editManyCountries: Array<ICountryAttributesData>;
}

export class CountryGraphQLProvider implements CountryProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async findAll(): Promise<Country[]> {
    try {
      const result = await this.client?.query<ListCountriesData>({
        query: LIST_COUNTRYS,
      });
      return result.data.listCountries.map((item) => new Country(item));
    } catch (e) {
      console.log(e);
    }
  }
  save(country: Country, userId?: string): Promise<Country> {
    throw new Error('Method not implemented.');
  }
  async editMany(
    entities: ICountryAttributes[],
    userId?: string
  ): Promise<Country[]> {
    try {
      const result = await this.client?.mutate<
        EditManyCountriesData,
        { input: ICountryAttributes[] }
      >({
        mutation: EDIT_MULTIPLE_PRODUCT_METADATAS,
        variables: {
          input: entities,
        },
      });
      return result.data?.editManyCountries?.map((item) => new Country(item));
    } catch (e) {
      console.log(e);
    }
  }
}
