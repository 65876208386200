import { Formula } from '../entities/Formula';
import { FormulaProvider } from './FormulaProvider';

export class GetFormula {
  private formulaProvider: FormulaProvider;

  public constructor(formulaProvider: FormulaProvider) {
    this.formulaProvider = formulaProvider;
  }

  public execute(formulaId: string): Promise<Formula> {
    return this.formulaProvider.findOneById(formulaId);
  }
}
