import * as React from 'react';
import {
  getCookie,
  setCookie,
  deleteCookie,
} from '@kiway/shared/utils/cookies';
import { useApolloClient } from '@apollo/client';
import {
  AccessControl,
  mapKiwayRole,
} from '@kiway/shared/utils/access-control-react';
import { useUsersGateway } from '../../react';

const AuthContext = React.createContext(null);
const { Provider } = AuthContext;

type AuthState = {
  expiresAt?: any;
  userInfo?: any;
};

const AuthProvider = ({ children }) => {
  const client = useApolloClient();
  const userInfo = getCookie('userInfo');
  const expiresAt = getCookie('expiresAt');
  const ac = new AccessControl();
  const { signOut, checkToken } = useUsersGateway();
  const [ready, setReady] = React.useState<boolean>(false);

  const [authState, setAuthState] = React.useState<AuthState>({
    expiresAt,
    userInfo: userInfo ? JSON.parse(userInfo) : {},
  });

  const setAuthInfo = ({ userInfo, expiresAt }: AuthState) => {
    // setCookie('userInfo', userInfo, parseFloat(expiresAt));
    // setCookie('expiresAt', expiresAt, parseFloat(expiresAt));
    setAuthState({
      userInfo,
      expiresAt,
    });
  };

  const updateUserInfo = (newInfos, resetRoles = true) => {
    setAuthInfo({
      userInfo: {
        ...authState.userInfo,
        ...newInfos,
        ...(resetRoles ? {} : { roles: authState.userInfo?.roles }),
      },
      expiresAt: authState.expiresAt,
    });
  };

  const logout = () => {
    return signOut().then((data) => {
      if (data) {
        deleteCookie('userInfo');
        deleteCookie('expiresAt');
        setAuthState({});
        client.resetStore();
      }
      return data;
    });
    // return runBasicFetch(basicPostRequest(getBaseURL() + '/auth/logout')).then(
    //   (data) => {
    //     if (data.logout) {
    //       deleteCookie('userInfo');
    //       deleteCookie('expiresAt');
    //       setAuthState({});
    //       client.resetStore();
    //       return true;
    //     }
    //     return false;
    //   }
    // );
  };

  const isAuthenticated = () => {
    // return true
    // if (!getCookie('expiresAt') || !getCookie('userInfo')) {
    //   return false;
    // }
    if (!ready) {
      return checkToken()
        .then((data) => {
          if (data?.user?.getId()) {
            setAuthState({
              userInfo: {
                ...data.user?.toJSON(),
                practitionerId: data?.user?.getId(),
                patientId: data?.user?.getId(),
                _id: data?.user?.getId(),
              },
              expiresAt: data.expiresAt,
            });
            return (
              data.expiresAt === 'session' ||
              new Date().getTime() / 1000 < parseInt(data.expiresAt)
            );
          }
          return false;
        })
        .catch(() => false)
        .finally(() => setReady(true));
    } else {
      if (!authState.expiresAt || !authState.userInfo) {
        return false;
      }
      return (
        authState.expiresAt === 'session' ||
        new Date().getTime() / 1000 < authState.expiresAt
      );
    }
  };

  const isAdmin = () => {
    return (
      authState.userInfo?.role === 'admin' ||
      authState.userInfo?.roles?.includes('ROLE_ADMIN')
    );
  };

  const isRoleAllowed = (targetRole) => {
    return ac.isRoleEquivalent(
      authState.userInfo?.roles || authState.userInfo?.mainRole || 'noAuth',
      targetRole
    );
  };

  const isAllowedToAccess = (route) => {
    return ac.hasAccess({
      resource: route.resource,
      role:
        authState.userInfo?.role || authState.userInfo?.mainRole || 'noAuth',
      roles:
        authState.userInfo?.roles?.length > 0
          ? authState.userInfo?.roles
          : [
              mapKiwayRole[
                authState.userInfo?.role ||
                  authState.userInfo?.mainRole ||
                  'noAuth'
              ],
            ],
      route,
    });
  };

  const isEmailVerified = () => {
    return authState.userInfo?.verified ?? false;
  };

  const isAllowedToSendSMS = (): boolean => {
    return (
      authState?.userInfo?.custom?.stripeSubscription?.status === 'active' &&
      authState?.userInfo?.custom?.stripeServices?.find(
        (service) => service?.shortname === 'sms'
      ) !== undefined
    );
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo: AuthState) => setAuthInfo(authInfo),
        logout,
        isAuthenticated,
        isAdmin,
        onBoardingComplete: () => true,
        updateUserInfo,
        isAllowedToAccess,
        isRoleAllowed,
        isEmailVerified,
        isAllowedToSendSMS,
        ready,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
