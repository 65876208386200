export default {
  chiefComplaint: {
    buttons: {
      add: 'Agregar una razón de consulta',
      update: 'Modificar esta razón de consulta',
    },
    deleteModal: {
      content:
        '¿Estás seguro de que quieres eliminar este motivo de consulta? La operación es irreversible.',
      title: 'Eliminar un motivo de consulta',
    },
    fields: {
      consultationPlaces: 'Lugares de consulta',
      duration: 'Duración',
      name: 'Razón de consulta',
      price: 'Precios',
      specialty: 'Especialidad',
    },
    label: {
      plural: 'Razones de consulta',
    },
    list: 'Lista de sus razones de consulta',
    none: 'No tienes razón para consultar por el momento',
    requirements:
      'Debe ingresar al menos una especialidad y un lugar de consulta para agregar razones de consulta',
    slotInfo:
      'Cuando cambie o elimine un patrón, recuerde actualizar sus semanas típicas en consecuencia.',
    snackbars: {
      error: {
        create:
          'Se produjo un problema durante la creación del motivo de consulta. Gracias por intentarlo de nuevo.',
        delete:
          'Se produjo un problema durante la eliminación del motivo de consulta. Gracias por intentarlo de nuevo.',
        deleteExistingChilds:
          'Este motivo de consulta es utilizado por reuniones o plantillas semanales, no puede eliminarlo.',
        update:
          'Se produjo un problema durante la actualización del motivo de consulta. Gracias por intentarlo de nuevo.',
        validation: 'Debe completar todos los campos obligatorios (*)',
      },
      success: {
        create: 'Se ha agregado el motivo de la consulta',
        delete: 'El motivo de la consulta se ha eliminado',
        update: 'El motivo de la consulta se ha actualizado',
      },
    },
    subtitle:
      'Puede asociar razones para consultar con una especialidad y lugares de práctica. Las razones se mostrarán al hacer citas en línea, una vez que se eligen los lugares y la especialidad. y cuesta 60 € \n* "consulta de seguimiento de acupuntura" dura 60 minutos y cuesta 50 €',
    title: 'Gestión de sus razones de consulta',
  },
  invoicing: {
    addressInfo: 'Domicilio social',
    goToProfile: 'Modificar esta información',
    goToSettings: 'Configurar la facturación',
    invoiceAddressInfo: 'Dirección de facturación',
    invoiceAddressInfoExplain:
      'La dirección de facturación depende de la ubicación de consulta',
    invoiceNumbering: 'Factura',
    labels: {
      businessName: 'Nombre Comercial',
      invoiceDigits: 'Número de cifras',
      invoiceNextDigit: 'Siguiente secuencia de facturas',
      invoicePrefix: 'Prefijo de factura',
      invoiceSuffix: 'Sufijo de factura',
      naf: 'Código NAF',
      siret: 'Número Fiscal',
      specialMentions: 'Menciones especiales',
      tva: 'IVA',
    },
    legalInfo: 'Información legal',
    legalInfoSubtitle: 'Esta información se mostrará al final de la factura.',
    needInvoicingDataToBillSession:
      'Primero debe configurar la facturación antes de poder usarla',
    nextInvoiceNumberExample: 'La próxima factura usará el siguiente número',
    placeholders: {
      specialMentions:
        'Ejemplo, si regime fiscal de IVA: no aplicable IVA, art. 293 b del código tributario general',
    },
    resultBottomInvoice: 'Resultado en la parte inferior de la factura:',
    saveChangesBeforeEditUser:
      'Guarde o cancele sus cambios antes de que pueda modificar esta información',
    snackbars: {
      errorUpdate: 'Ocurrió un problema, por favor intente de nuevo',
      successUpdate: 'Configuración grabada con éxito',
    },
  },
  minisite: {
    contactInfo: {
      label:
        'Active/desactive la visualización de su información de contacto (teléfono)',
      phoneChoice: 'Elección del número para mostrar en su página',
      snackbars: {
        error: {
          update:
            'Se produjo un problema durante el registro del ajuste de su información de contacto. Gracias por intentarlo de nuevo.',
        },
        success: {
          update:
            'El ajuste de su información de contacto se ha actualizado correctamente',
        },
      },
    },
    content: {
      description: {
        label: 'Descripción',
        previewLabel: 'Vista previa de su descripción',
      },
      displaySanitary: 'Mostrar instrucciones sanitarias',
      formation: {
        label: 'Capacitación',
        previewLabel: 'Vista previa de su entrenamiento',
      },
      placeholderSanitary: 'Agregue sus instrucciones aquí ...*',
      snackbars: {
        error: {
          sanitary:
            'Llene o desactive las instrucciones de salud antes de ahorrar',
          update:
            'Se produjo un problema durante la grabación del contenido de su página pública. Gracias por intentarlo de nuevo.',
        },
        success: {
          update:
            'El contenido de su página pública se ha actualizado con éxito',
        },
      },
      styling:
        'Diseño: \n* **Fat**: rodea la cadena de caracteres por dos asteriscos en cada lado: &ast;&ast;bold text&ast;&ast;\n* Lista desordenada: comience la línea con un asterisco seguido de al menos un espacio: &ast; Item \n',
      subtitle:
        'Aquí es donde puede detallar su práctica y decir más sobre usted.',
      title: 'Escriba el contenido de su página pública',
      titleInformation: 'Información profesional',
      titleSanitary: 'Instrucciones de salud (Covid 19)',
    },
    displayMinisite: {
      label: 'Active/desactive su página pública',
      snackbars: {
        error: {
          update:
            'Se produjo un problema durante el registro del ajuste de su página pública. Gracias por intentarlo de nuevo.',
        },
        success: {
          update:
            'El ajuste de visualización de su página pública se ha actualizado correctamente',
        },
      },
    },
    subtitle:
      'Esta página aumentará su visibilidad y ofrecerá la cita en línea a sus pacientes.',
    title: 'Configuración de su página pública',
    uploads: {
      gallery: {
        snackbars: {
          error: {
            delete:
              'Se produjo un problema durante la eliminación de los archivos (s), por favor intente nuevamente',
            upload:
              'Se produjo un problema durante la descarga de los archivos (s),  por favor intente nuevamente',
          },
          success: {
            delete: 'Fichero borrado com exito',
            upload: 'Fichero grabado com exito',
          },
        },
        subtitle:
          'Estas fotos se mostrarán en forma de una galería en su página pública.',
        title: 'Galería de fotos',
      },
      profile: {
        snackbars: {
          error: {
            delete:
              'Se produjo un problema durante la eliminación del archivo, por favor intente nuevamente',
            upload:
              'Se produjo un problema durante la descarga del archivo, por favor intente nuevamente',
          },
          success: {
            delete: 'Fichero borrado com exito',
            upload: 'Fichero grabado com exito',
          },
        },
        subtitle:
          'Esta foto servirá como una foto de perfil en su página pública',
        title: 'Foto de perfil',
      },
      subtitle:
        'Las fotos que descargan son públicas siempre que se active su página pública. Puede eliminarlos en cualquier momento o agregarlos.',
      title: 'Descarga tus fotos',
    },
    urlSettings: {
      confirmModalContent:
        'Cualquier cambio en el Slug dará como resultado la revocación de la URL anterior. Estás seguro de que quieres continuar ?',
      confirmModalTitle: 'Confirma tus cambios',
      doubleSlug: 'Ya existe',
      errorSave:
        'Se produjo un problema durante la grabación. Por favor intente de nuevo.',
      lock:
        'Active/desactive los cambios (si cambia estas configuraciones, piense que la URL de su página puede cambiar)',
      mainSpecialty: {
        label: 'Especialidad principal',
      },
      mainWorkplace: {
        label: 'Lugar principal de consulta',
      },
      preview: {
        label: 'Vista previa de la URL de su página pública',
      },
      refresh:
        'Si no se muestra "lugar" o "especialidad", actualice la página.',
      slug: {
        label: 'Su nombre personalizado para URL',
      },
      subtitle: 'El nombre personalizado le permite obtener una URL única',
      successSave: 'Grabado con exito',
      title: 'Configuración de la dirección de su página pública (URL)',
    },
  },
  onBoardingInfo:
    'Hola y bienvenido a {{app}}. \n\n nafin para comenzar en las mejores condiciones lo invitamos a configurar la información sobre su actividad. \n\n pour que, nada podría ser más simple, solo siga los pasos a continuación. ¡Déjese guiarse por nuestra pequeña guía. \n\n Cordialmente, el equipo de {{app}}!',
  places: {
    buttons: {
      add: 'Agregue un lugar de consulta',
      update: 'Editar este lugar de consulta',
    },
    deleteModal: {
      content:
        '¿Estás seguro de que quieres eliminar este lugar de consulta? La operación es irreversible.',
      title: 'Eliminar un lugar de consulta',
    },
    label: {
      plural: 'Lugares de consulta',
    },
    list: 'Lista de sus lugares de consulta',
    none: 'No tienes local para consulta en este momento',
    previewInfo: 'Vista previa del resultado en su página pública',
    slotInfo:
      'Cuando cambie o elimine un lugar, recuerde actualizar sus semanas típicas en consecuencia.',
    snackbars: {
      error: {
        create:
          'Se produjo un problema durante la creación del lugar de consulta. Gracias por intentarlo de nuevo.',
        delete:
          'Se produjo un problema durante la eliminación del lugar de consulta. Por favor tente de nuevo.',
        deleteExistingChilds:
          'Este lugar de consulta se utiliza por razones de consulta, citas o plantillas semanales, no puede eliminarlo.',
        update:
          'Se produjo un problema durante la actualización del lugar de consulta. Gracias por intentarlo de nuevo.',
      },
      success: {
        create: 'Se ha agregado el lugar de consulta',
        delete: 'El lugar de consulta se ha eliminado',
        update: 'El lugar de consulta se ha actualizado',
      },
    },
    subtitle:
      'Ingrese sus diferentes lugares de prárica. Se ofrecerán en la cita en línea.',
    title: 'Gestión de sus lugares de consulta',
  },
  snackbars: {
    error: {
      form: {
        errorsNotResolved: 'Debe resolver errores y validar nuevamente.',
        noAddress:
          'Debes introducir una dirección válida. Asegúrese de hacer clic en la sugerencia correspondiente a su dirección durante la investigación.',
        noName: 'Debe ingresar un nombre para su lugar de consulta.',
      },
      rightsIssue:
        'Un error evita enviar la solicitud, parece ser un problema de derechos',
    },
  },
  specialties: {
    info:
      'La copia de seguridad es automática para este campo. Cuando elimine una especialidad, recuerde actualizar sus semanas típicas en consecuencia.',
    label: {
      plural: 'Especialidades',
      singular: 'Especialidad',
    },
    pickOne: 'Elija una especialidad',
    snackbars: {
      error: {
        deleteExistingChilds:
          'Esta especialidad se utiliza por razones de consulta, no puede eliminarla.',
        update:
          'Se produjo un problema durante la grabación de sus especialidades. Gracias por intentarlo de nuevo.',
      },
      success: {
        update: 'Especialidades grabadas con exito',
      },
    },
    subtitle:
      'Seleccione las especialidades que practica y desea ofrecer a sus pacientes.',
    title: 'Gestión de sus especialidades',
  },
  subtitle:
    'Esta información es necesaria para el funcionamiento adecuado de la plataforma. Se mostrarán en su página pública para presentar su actividad. También le permitirán configurar la cita en línea.',
  tabs: {
    activity: 'Actividad',
    agenda: 'Cita en línea',
    invoicing: 'Facturación',
    publicPage: 'Página pública',
    workplaces: 'Lugares de consulta',
  },
  title: 'Configuración de su información profesional',
};
