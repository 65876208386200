export default {
  addUserTitle: 'Agregar un usuario',
  dataTable: {
    columns: {
      createdAt: 'Registro',
      docStatus: 'Estado del documento',
      docType: 'Tipo de Documento',
      email: 'Correo electrónico',
      GDPR: 'GDPR',
      lastLogin: 'Última conexión',
      partnerRevenueAmount: 'Cantidad del premio',
      partnerRevenuePlan: 'Plan de recompensa',
      role: 'Role',
      subStatus: 'Estado de subscrición',
      verified: 'Correo verificado',
    },
    values: {
      empty: 'Nulo',
    },
  },
  editUserTitle: 'Modificar a un usuario',
  enum: {
    docStatus: {
      contact: 'Relanzar',
      pending: 'Esperando',
      received: 'Recibido',
      verified: 'Comprobado',
    },
    docType: {
      diploma: 'Diploma',
      trainingCertificate: 'Certificado de Formación',
    },
    partnerRevenuePlan: {
      costPerAcquisition: 'Costo por adquisición',
      revenueShare: 'Intercambio de ingresos',
    },
  },
  errors: {
    requiredField: 'Campo obligatorio',
  },
  notificationsManagement: {
    form: {
      metadata: {
        link: 'Enlace',
        text: 'Contenido de notificación',
      },
      sendTo: 'Enviar a',
      sendToOptions: {
        admin: 'Rol de administrador',
        all: 'Todos los usuarios',
        beta: 'Papel beta',
        partner: 'Rol de Compañero',
        patient: 'Rol de paciente',
        pharmaco: 'Papel farmaco',
        practitioner: 'Rol de Practicante',
      },
      type: 'Tipo de notificación',
    },
    title: 'Enviar notificaciones',
  },
  snackbars: {
    errorEdit: 'Se produjo un error durante la grabación',
    successAdd: 'Agregado exitosamente',
    successEdit: 'Modificado con éxito',
  },
  usersManagement: {
    title: 'Gestión de usuarios',
  },
};
