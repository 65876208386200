import { UserProvider } from './UserProvider';
import { User, IUserAttributes } from '../entities/User';

export class EditMyProfile {
  private userProvider: UserProvider;

  public constructor(userProvider: UserProvider) {
    this.userProvider = userProvider;
  }

  public execute(userInput: User | IUserAttributes): Promise<User> {
    const user = userInput instanceof User ? userInput : new User(userInput);
    return this.userProvider.save(user);
  }
}
