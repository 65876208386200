import { TaxModeGraphQLProvider } from '../../dataproviders/graphql-client/TaxModeGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllTaxModes } from '../../core/use_cases/GetAllTaxModes';
import { TaxMode } from '../../core/entities/TaxMode';

class TaxModesGateway {
  protected static instance: TaxModesGateway;
  protected getAllTaxModes: GetAllTaxModes;

  public static getInstance(client: ApolloClient<any>): TaxModesGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new TaxModesGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const taxModeProvider = new TaxModeGraphQLProvider(client);
    this.getAllTaxModes = new GetAllTaxModes(taxModeProvider);
    this.findAll = this.findAll.bind(this);
    TaxModesGateway.instance = this;
  }

  async findAll(): Promise<TaxMode[]> {
    return await this.getAllTaxModes.execute();
  }
}

export function useTaxModesGateway() {
  const client = useApolloClient();
  const taxModesGateway = TaxModesGateway.getInstance(client);
  return {
    findAll: taxModesGateway.findAll,
  };
}
