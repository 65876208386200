import Typography from '@material-ui/core/Typography';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from '@kiway/shared/utils/translation';
import { CustomButton } from '../../shared/Buttons';
import GreenPageWithAppVersion from '../../shared/GreenPageWithAppVersion';

function Error404(props) {
  const { t } = useTranslation();
  return (
    <GreenPageWithAppVersion title={t('common:httpCodes.error404.title')}>
      <Typography
        variant="body1"
        style={{ color: 'white', marginBottom: '16px' }}
      >
        {t('common:httpCodes.error404.content')}
      </Typography>
      <CustomButton
        color="minoritary"
        onClick={() => {
          props.history.goBack();
        }}
      >
        {t('common:httpCodes.buttons.goBack')}
      </CustomButton>
      <CustomButton
        color="minoritary"
        onClick={() => {
          props.history.push('/');
        }}
        hasmarginleft="true"
      >
        {t('common:httpCodes.buttons.goHome')}
      </CustomButton>
    </GreenPageWithAppVersion>
  );
}

export default withRouter(Error404);
