export default {
  availableTypes: {
    email: 'By email',
    push: 'In-App',
  },
  docSubtitle: 'Settings',
  greetings: 'Hello',
  linkText: 'Notifications',
  notifierLabel: 'Be notified when...',
  snackbars: {
    okEmailDisabled: 'Email notification disabled successfully',
    okEmailEnabled: 'Email notification enabled successfully',
    okPushDisabled: 'In-App notification disabled successfully',
    okPushEnabled: 'In-App notification enabled successfully',
  },
};
