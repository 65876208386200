export default {
  addPatientModal: {
    firstName: 'Firstname',
    lastName: 'Lastname',
    title: 'Add New Patient',
  },
  appBar: {
    suffix: 'years',
  },
  dataTable: {
    columns: {
      firstName: 'Firstname',
      lastName: 'Lastname',
      lastSession: 'Last Session',
      mobilePhone: 'Phone',
      nextSession: 'Next Session',
      numberOfSessions: 'Number Of Sessions',
    },
    title: 'Patients',
  },
  deletePatientModal: {
    content:
      'Are you sure you want to delete this patient? The operation is irreversible.',
    title: 'Delete patient',
  },
  details: {
    actions: {
      alert: 'Alert',
      appointment: 'Add an appointment',
      export: 'Export patient data',
    },
    alerts: {
      label: 'Contextual Alerts',
    },
    background: {
      label: 'Background',
      redFlag: 'Red Flag',
    },
    dataTable: {
      columns: {
        chiefComplaint: 'Chief Complaint',
        date: 'Date',
        evaluation: 'Evaluation',
      },
      title: 'Sessions',
    },
    info: {
      address: 'Addresses',
      birthDate: 'Birthdate',
      city: 'City',
      country: 'Country',
      email: 'Email',
      female: 'Female',
      firstName: 'Firstname',
      gender: 'Gender',
      label: 'General Information',
      lastName: 'Lastname',
      male: 'Male',
      mobilePhone: 'Mobile phone',
      other: 'Other',
      profession: 'Profession',
      street: 'Street',
      zipCode: 'Zip Code',
    },
  },
  export: {
    chiefComplaint: 'Chief complaint',
    comment: 'Comment',
    consultationPlace: 'Workplace',
    diagnosis: {
      text: 'Diagnosis',
    },
    endDate: 'End date',
    followUpEval: {
      comment: 'Treatment comment',
      date: 'Date of the treatment follow up',
      treatmentEfficiency: 'Treatment efficiency',
    },
    fourMethods: {
      text: '4 methods of diagnosis',
    },
    modalContent: 'Your download is ready, please click on the button below.',
    modalTitle: 'Export patient data',
    patientNote: 'Note to the attention of patient',
    startDate: 'Start data',
    treatment: {
      text: 'Treatment',
    },
  },
  last: {
    legacy: {
      order: 'Last legacy order',
    },
  },
  link: {
    contentModal:
      'By clicking on "Link this file" you will associate the patient file to the patient\'s user account.\n\nThis will be useful for the patient to handle **its personal data** and get access to **its appointments** list.\nThanks to the **"Patient notes"** you will be able to provide your patient with a session summary.\n\nPlease ensure that you enter the right email address to link the file to the right patient.',
    textButton: 'Link this file',
    titleModal: "Link the file to the patient's user account",
  },
  snackbars: {
    patient: {
      cantLinkEmailEmpty:
        'You must enter an email address to try to link the patient file',
      errorDelete: 'A problem occurred during the deleting. Please try again.',
      errorLink:
        "Something went wrong. The file might already be linked to the patient account or the patient hasn't created an account yet.",
      errorNames: 'Both firstname and lastname are mandatory',
      errorPhone: 'Phone number is invalid',
      successCreate: 'Patient created successfully',
      successDelete: 'Patient deleted successfully',
      successLink:
        'This file has been successfully linked. Your patient will be able to get access to its appointments list and the session notes.',
      successUpdate: 'Patient updated successfully',
    },
    session: {
      successCreate: 'Session created successfully',
      successUpdate: 'Session updated successfully',
    },
  },
};
