export default {
  body: {
    columnHeaderTooltip: 'Trier par {{columnLabel}}',
    noMatch: 'Aucun résultat',
    toolTip: 'Trier',
  },
  filter: {
    all: 'Tous',
    reset: 'Réinitialiser',
    title: 'Filtres',
  },
  pagination: {
    displayRows: 'sur',
    next: 'Page suivante',
    previous: 'Page précédente',
    rowsPerPage: 'Lignes par page :',
  },
  selectedRows: {
    delete: 'Supprimer',
    deleteAria: 'Supprimer les lignes sélectionnées',
    text: 'ligne(s) sélectionnée(s)',
  },
  toolbar: {
    downloadCsv: 'Exporter en CSV',
    filterTable: 'Filtres',
    print: 'Imprimer',
    search: 'Rechercher',
    viewColumns: 'Sélection des colonnes',
  },
  viewColumns: {
    title: 'Colonnes affichées',
    titleAria: 'Afficher/Cacher colonnes',
  },
};
