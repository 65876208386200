import React from 'react';
import NumberFormat from 'react-number-format';

export interface NumberInputProps {
  allowNegative: boolean;
}

export function NumberInput(props: NumberInputProps) {
  const [value, setValue] = React.useState<string>();

  return (
    <NumberFormat
      decimalSeparator=","
      allowNegative={props.allowNegative}
      onValueChange={(e) => setValue(e.value)}
    />
  );
}

export default NumberInput;
