import './mobile-safe-area.module.css';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useTheme } from '@mui/material';

/* eslint-disable-next-line */
export interface MobileSafeAreaProps {}

export function MobileSafeArea(
  props: React.PropsWithChildren<MobileSafeAreaProps>
) {
  const theme = useTheme();
  return (
    <div
      style={{
        padding: isMobile ? theme.spacing(3) : undefined,
        minHeight: '100%',
      }}
    >
      {props.children}
    </div>
  );
}

export default MobileSafeArea;
