import React from 'react';
import {
  Grid,
  Typography,
  Button,
  Stack,
  TextField,
  MenuItem,
  Box,
} from '@mui/material';
import { useTranslation } from '@kiway/shared/utils/translation';
import { ChartCard } from './ChartCard';
import { StatusChart } from './StatusChart';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  OrderStatName,
  OrderStatPeriod,
} from '@kiway/ecommerce-react-compatible';
import { AnalyticEcommerceController } from './AnalyticsEcommerceController';
import { OrderPickerChartController } from './OrderPickerController';
import { KpiController } from './KpiController';
import { StatusChartController } from './StatusChartController';

interface DashboardProps {
  setPeriod: React.Dispatch<React.SetStateAction<OrderStatPeriod>>;
  period: OrderStatPeriod;
  setOrderPickerStatType: React.Dispatch<React.SetStateAction<OrderStatName>>;
  orderPickerStatType: OrderStatName;
}

export const Dashboard = (props: DashboardProps) => {
  const { t } = useTranslation();

  const listPeriods = [
    {
      value: 'today',
      label: t('pharmaco:dashboard.analytics.periods.today'),
    },
    {
      value: 'yesterday',
      label: t('pharmaco:dashboard.analytics.periods.yesterday'),
    },
    {
      value: 'week',
      label: t('pharmaco:dashboard.analytics.periods.week'),
    },
    {
      value: 'prevWeek',
      label: t('pharmaco:dashboard.analytics.periods.prevWeek'),
    },
    {
      value: 'month',
      label: t('pharmaco:dashboard.analytics.periods.month'),
    },
    {
      value: 'prevMonth',
      label: t('pharmaco:dashboard.analytics.periods.prevMonth'),
    },
    {
      value: 'quarter',
      label: t('pharmaco:dashboard.analytics.periods.quarter'),
    },
    {
      value: 'prevQuarter',
      label: t('pharmaco:dashboard.analytics.periods.prevQuarter'),
    },
    {
      value: 'year',
      label: t('pharmaco:dashboard.analytics.periods.year'),
    },
    {
      value: 'prevYear',
      label: t('pharmaco:dashboard.analytics.periods.prevYear'),
    },
  ];

  const handleChangePeriod = (newPeriod) => {
    props.setPeriod(newPeriod);
  };

  return (
    <Grid container rowSpacing={3} columnSpacing={2.75}>
      {/* Dashboard title and select menu */}
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">
              {t('pharmaco:dashboard.analytics.title')}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              id="standard-select-currency"
              size="small"
              select
              value={props.period}
              onChange={(e) => handleChangePeriod(e.target.value)}
              sx={{
                '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' },
              }}
            >
              {listPeriods.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      {/* E-commerce analytics cards */}
      <Grid item xs={12} md={6} lg={3}>
        <AnalyticEcommerceController
          title="totalOrders"
          statType={'nbOrders'}
          period={props.period}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <AnalyticEcommerceController
          title="totalClients"
          statType={'nbDistinctCustomers'}
          period={props.period}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <AnalyticEcommerceController
          title="revenue"
          statType={'revenue'}
          period={props.period}
          subtitle={'HT_HT'}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <AnalyticEcommerceController
          title="avgCart"
          statType={'avgCart'}
          period={props.period}
        />
      </Grid>

      {/* Order pickers stats title, select toggle and card */}
      <Grid item xs={12} md={7} lg={8}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">
              {t('pharmaco:dashboard.analytics.charts.preparerStats')}
            </Typography>
          </Grid>
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={0}>
              <Button
                size="small"
                onClick={() =>
                  props.setOrderPickerStatType('nbPreparedByOrderPicker')
                }
                color={
                  props.orderPickerStatType === 'nbPreparedByOrderPicker'
                    ? 'primary'
                    : 'secondary'
                }
                variant={
                  props.orderPickerStatType === 'nbPreparedByOrderPicker'
                    ? 'outlined'
                    : 'text'
                }
              >
                {t('pharmaco:orderDetails.packing.status.PREPARED')}
              </Button>
              <Button
                size="small"
                onClick={() =>
                  props.setOrderPickerStatType('nbShippedByOrderPicker')
                }
                color={
                  props.orderPickerStatType === 'nbShippedByOrderPicker'
                    ? 'primary'
                    : 'secondary'
                }
                variant={
                  props.orderPickerStatType === 'nbShippedByOrderPicker'
                    ? 'outlined'
                    : 'text'
                }
              >
                {t('pharmaco:orderDetails.shipment.status.SHIPPED')}
              </Button>
            </Stack>
          </Grid>
        </Grid>

        <ChartCard sx={{ mt: 1.5 }}>
          <OrderPickerChartController
            period={props.period}
            displayType={props.orderPickerStatType}
          />
        </ChartCard>
      </Grid>

      {/* Order status title and card */}
      <Grid item xs={12} md={5} lg={4}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">
              {t('pharmaco:dashboard.analytics.charts.orderStatus')}
            </Typography>
          </Grid>
          <Grid item />
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ChartCard sx={{ mt: 1.5 }}>
              <Box sx={{ p: 3, pb: 0 }}>
                <Typography variant="body1" color="textSecondary">
                  {t('pharmaco:dashboard.analytics.running')}
                </Typography>
              </Box>
              <StatusChartController
                period={props.period}
                statType={'ordersStatus'}
              />
            </ChartCard>
          </Grid>
          <Grid item xs={6}>
            <KpiController
              title={t('pharmaco:dashboard.analytics.orderCompleted')}
              statType={'ordersCompleted'}
            />
          </Grid>
          <Grid item xs={6}>
            <KpiController
              title={t('pharmaco:dashboard.analytics.orderCancelled')}
              statType={'ordersCancelled'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
