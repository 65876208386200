import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import './GalleryCarousel.css';

function GalleryCarousel(props) {
  const [index, setIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(null);
  const [initDone, setInitDone] = useState(false);

  const { galleryItems, itemStyle, thumbItemStyle, previews, ...other } = props;

  const next = () => {
    if (index >= galleryItems.length - 1) {
      setIndex(0);
    } else {
      setIndex((prev) => prev + 1);
    }
  };

  const prev = () => {
    if (index <= 0) {
      setIndex(galleryItems.length - 1);
    } else {
      setIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    let isPending = true;
    try {
      (async () => {
        if (isPending) {
          if (!initDone) {
            setInitDone(true);
            setCurrentImage(galleryItems[0]);
          } else {
            setCurrentImage(galleryItems[index]);
          }
        }
      })();
    } catch (error) {
      // DO NOTHING
    }
    return () => (isPending = false);
  }, [index]);

  return (
    galleryItems && (
      <div
        style={{ width: '100%', marginTop: '16px' /*minWidth: '380px'*/ }}
        {...other}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: '300px',
            width: '100%',
          }}
        >
          <IconButton
            size="small"
            style={{
              backgroundColor: process.env.NX_COLOR_PRIMARY_MAIN,
              color: 'white',
            }}
            onClick={prev}
          >
            <ArrowLeftIcon fontSize="large" />
          </IconButton>
          {currentImage && (
            <div
              style={{
                width: '100%',
                maxHeight: '300px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                style={itemStyle}
                src={currentImage.preview}
                alt={currentImage.name}
              />
            </div>
          )}
          <IconButton
            size="small"
            style={{
              backgroundColor: process.env.NX_COLOR_PRIMARY_MAIN,
              color: 'white',
            }}
            onClick={next}
          >
            <ArrowRightIcon fontSize="large" />
          </IconButton>
        </div>
        {previews && (
          <Grid container spacing={1} style={{ marginTop: '16px' }}>
            {galleryItems.map((item, itemIndex) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={2}
                onClick={() => setIndex(itemIndex)}
                key={item._id}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '50px',
                  // minWidth: '100px',
                }}
                className={itemIndex === index && 'galleryActive'}
              >
                <img
                  style={thumbItemStyle}
                  src={item.preview}
                  alt={item.name}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    )
  );
}

GalleryCarousel.propTypes = {
  galleryItems: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      preview: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  itemStyle: PropTypes.object,
  thumbItemStyle: PropTypes.object,
  previews: PropTypes.bool,
};

GalleryCarousel.defaultProps = {
  itemStyle: { height: 'auto', maxWidth: '100%', maxHeight: '300px' },
  thumbItemStyle: { maxWidth: '200px', maxHeight: '100px' },
  previews: false,
};

export default GalleryCarousel;
