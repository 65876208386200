import { IProductMetadataAttributes, ProductMetadata } from './ProductMetadata';
import {
  VariationAttributeValue,
  IVariationAttributeValueAttributes,
} from './VariationAttributeValue';
import { IPriceAttributes, Price } from '@kiway/ecommerce-react-compatible';

export interface IProductVariationTypeAttributes
  extends IProductMetadataAttributes {
  shortcode: string;
  price: IPriceAttributes;
  variationAttributesValue: IVariationAttributeValueAttributes[];
}

export class ProductVariationType extends ProductMetadata {
  static readonly typeId = 'ProductVariationType';

  protected shortcode: string;
  protected price: Price;
  protected variationAttributesValue: VariationAttributeValue[];

  public constructor(
    obj: Partial<IProductVariationTypeAttributes> & {
      _id?: string;
      id?: string;
    }
  ) {
    super(obj);
    this.shortcode = obj?.shortcode;
    this.price = new Price(obj?.price, 1);
    this.variationAttributesValue = obj?.variationAttributesValue?.map(
      (item) => new VariationAttributeValue(item)
    );
  }

  public setId(id: string): ProductVariationType {
    super.setId(id);
    return this;
  }

  public getShortcode(): string {
    return this.shortcode;
  }

  public getPrice(): Price {
    return this.price;
  }

  public getVariationAttributesValue(): VariationAttributeValue[] {
    return this.variationAttributesValue;
  }

  public isConcentratedPowder(): boolean {
    return this.variationAttributesValue?.reduce(
      (prev: boolean, current: VariationAttributeValue) =>
        current.isConcentratedPowder() || prev,
      false
    );
  }

  public isPlant(): boolean {
    return this.variationAttributesValue?.reduce(
      (prev: boolean, current: VariationAttributeValue) =>
        current.isPlant() || prev,
      false
    );
  }

  public toDatatableRow(): any {
    return {
      ...super.toDatatableRow(),
      shortcode: this.getShortcode(),
      price: this.getPrice()?.toJSON(),
    };
  }
}
