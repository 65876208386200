import * as React from 'react';
import { useProductVariationTypesGateway } from '../../../entrypoints/react/useProductVariationTypesGateway';
import { ListProductVariationTypes } from './ListProductVariationTypes';

export function ListProductVariationTypesController() {
  const [loading, setLoading] = React.useState(false);
  const [productVariationTypes, setProductVariationTypes] = React.useState([]);
  const { editMany, findAll } = useProductVariationTypesGateway();

  React.useEffect(() => {
    setLoading(true);
    findAll()
      .then((productVariationTypes) => {
        setProductVariationTypes(productVariationTypes);
      })
      .finally(() => setLoading(false));
    return () => {
      setProductVariationTypes([]);
    };
  }, []);

  return (
    <ListProductVariationTypes
      handleSave={async (updates) => {
        if (editMany) {
          const editedProductMetadatas = await editMany(updates);
          if (editedProductMetadatas) {
            setProductVariationTypes((prev) => [
              ...prev.filter(
                (productVariationType) =>
                  productVariationType.getId() &&
                  !editedProductMetadatas
                    ?.map((productVariationType) =>
                      productVariationType.getId()
                    )
                    ?.includes(productVariationType.getId())
              ),
              ...editedProductMetadatas,
            ]);
          } else {
            return false;
          }
        }
        return true;
      }}
      loading={loading}
      productVariationTypes={productVariationTypes}
    />
  );
}
