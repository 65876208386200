import * as React from 'react';
import { PrimaryPageWithAppVersion } from '@kiway/shared/ui';
import { useTranslation } from '@kiway/shared/utils/translation';
import { useHistory } from 'react-router-dom';
import { Grid, Button, Typography } from '@mui/material';

export function RegisterDisabled() {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <PrimaryPageWithAppVersion
      maxWidth={'xs'}
      title={t('authentication:register.title', {
        app: process.env.NX_APPBAR_NAME,
      })}
    >
      <Grid
        container
        spacing={3}
        style={{ boxSizing: 'border-box', marginTop: '16px' }}
      >
        <Grid item xs={12}>
          <Typography sx={{ color: 'primary.contrastText' }}>
            {t('authentication:register.disabled')}
          </Typography>
        </Grid>
      </Grid>
      <Button
        color="inherit"
        variant="contained"
        fullWidth
        onClick={() => history.push('/')}
        style={{ marginTop: '68px', marginBottom: '8px' }}
      >
        {t('user:form.backToHome')}
      </Button>
    </PrimaryPageWithAppVersion>
  );
}
