import { Formula, IFormulaAttributes } from '../entities/Formula';
import { FormulaProvider } from './FormulaProvider';

export class EditManyFormulas {
  private formulaProvider: FormulaProvider;

  public constructor(formulaProvider: FormulaProvider) {
    this.formulaProvider = formulaProvider;
  }

  public execute(
    formulas: Array<IFormulaAttributes>,
    userId?: string
  ): Promise<Array<Formula>> {
    return this.formulaProvider.editMany(formulas, userId);
  }
}
