export interface IStoredFileAttributes {
  path: string;
  bucket: string;
  name: string;
  contentType: string;
  size: string;
  createdAt: Date;
  encrypted: boolean;
  custom: any;
}

export class StoredFile {
  protected id: string;
  protected path: string;
  protected bucket: string;
  protected name: string;
  protected contentType: string;
  protected size: string;
  protected createdAt: Date;
  protected encrypted: boolean;
  protected custom: any;

  public constructor(
    obj: Partial<IStoredFileAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    this.path = obj?.path;
    this.bucket = obj?.bucket;
    this.name = obj?.name;
    this.contentType = obj?.contentType;
    this.size = obj?.size;
    this.createdAt = obj?.createdAt;
    this.encrypted = obj?.encrypted;
    this.custom = obj?.custom;
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): StoredFile {
    this.id = id;
    return this;
  }

  public getPath(): string {
    return this.path;
  }

  public setPath(path: string): StoredFile {
    this.path = path;
    return this;
  }

  public getBucket(): string {
    return this.bucket;
  }

  public setBucket(bucket: string): StoredFile {
    this.bucket = bucket;
    return this;
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string): StoredFile {
    this.name = name;
    return this;
  }

  public getContentType(): string {
    return this.contentType;
  }

  public setContentType(contentType: string): StoredFile {
    this.contentType = contentType;
    return this;
  }

  public getSize(): string {
    return this.size;
  }

  public setSize(size: string): StoredFile {
    this.size = size;
    return this;
  }

  public getCreatedAt(): Date {
    return this.createdAt;
  }

  public setCreatedAt(createdAt: Date): StoredFile {
    this.createdAt = createdAt;
    return this;
  }

  public isEncrypted(): boolean {
    return this.encrypted;
  }

  public setEncrypted(encrypted: boolean): StoredFile {
    this.encrypted = encrypted;
    return this;
  }

  public getCustom(): any {
    return this.custom;
  }

  public setCustom(custom: any): StoredFile {
    this.custom = custom;
    return this;
  }
}
