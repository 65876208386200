import { useTranslation } from '@kiway/shared/utils/translation';
import { Grid, Typography } from '@material-ui/core';
import * as React from 'react';
import { Skeleton } from '@material-ui/lab';

interface ProductDetailsBooleanTypeProps {
  label: string;
  loading?: boolean;
  value: boolean;
}

export function ProductDetailsBooleanType({
  label,
  loading,
  value,
}: ProductDetailsBooleanTypeProps) {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography style={{ fontWeight: 'bold' }}>{t(label)}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{loading ? <Skeleton /> : value ? ' ✔' : ' ✘'}</Typography>
      </Grid>
    </Grid>
  );
}
