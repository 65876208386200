import { gql } from '@apollo/client';
import { INotifierSettingAttributesData } from './queries';

export interface EditMyNotifierSettingData {
  editMyNotifierSetting: Array<INotifierSettingAttributesData>;
}

export const EDIT_MY_NOTIFIER_SETTING = gql`
  mutation editMyNotifierSetting($input: EditNotifierSettingArgs) {
    editMyNotifierSetting(input: $input) {
      notifier {
        slug
      }
      email
      push
    }
  }
`;
