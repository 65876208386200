import React from 'react';
import { Chip, Grid, TextField, Typography } from '@mui/material';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Payment } from 'libs/ecommerce/src/core/entities/Payment';
import { useTranslation } from '@kiway/shared/utils/translation';
import moment, { Moment } from 'moment';
import { CheckInformation } from './CheckInformation';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DatePicker } from '@kiway/shared/ui';
import { BankTransferInformation } from './BankTransferInformation';

interface ModalContentCheckProps {
  selectedPayment: Payment;
  setTempDate: React.Dispatch<React.SetStateAction<Moment>>;
  tempDate: Moment;
  paymentRef: string;
  setPaymentRef: React.Dispatch<React.SetStateAction<string>>;
}

export const ModalContentCheck = (props: ModalContentCheckProps) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <b>{t(`pharmaco:orderDetails.status`)}</b>
          </Grid>
          <Grid item>
            <Chip
              label={t(
                `pharmaco:orderDetails.payment.status.${
                  props.selectedPayment.getPaymentStatusJSON().label
                }`
              ).toUpperCase()}
              color={props.selectedPayment.getPaymentStatusJSON()?.color}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <b>{t(`pharmaco:orderDetails.payment.amount`)}</b>
          </Grid>
          <Grid item>{props.selectedPayment.getAmount().toString()}</Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          <Grid item flex={1}>
            <b>{t(`pharmaco:orderDetails.payment.paidAt`)}</b>
          </Grid>
          <Grid item>
            {props.selectedPayment.getStatus() === 'PAID' ? (
              moment(props.selectedPayment.getCompletedAt())?.format(
                t('dates:format.smsSentDate')
              )
            ) : (
              <DatePicker
                handleChange={(e) => props.setTempDate(e)}
                value={props.tempDate ? props.tempDate : null}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <b>{t(`pharmaco:orderDetails.payment.paymentMethod`)}</b>
          </Grid>
          <Grid item>
            {t(
              `pharmaco:orderDetails.payment.forms.${props.selectedPayment.getPaymentMethod()}`
            )}
          </Grid>
        </Grid>
      </Grid>

      {props.selectedPayment.getPaymentMethod() === 'bankTransfer' && (
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item flex={1}>
              <b>{t(`pharmaco:orderDetails.formula.ref`)}</b>
            </Grid>
            <Grid item>
              {props.selectedPayment.getCompletedAt() ? (
                props.selectedPayment.getCustom()?.bank?.ref
              ) : (
                <TextField
                  size="small"
                  onChange={(e) => props.setPaymentRef(e.target.value)}
                  value={props.paymentRef ?? ''}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12}>
            <b>
              {props.selectedPayment.getPaymentMethod() === 'check'
                ? t('pharmaco:orderDetails.payment.forms.checkInformationLabel')
                : t(
                    'pharmaco:orderDetails.payment.forms.bankTransferInformationLabel'
                  )}
            </b>
          </Grid>
          {props.selectedPayment.getPaymentMethod() === 'check' && (
            <Grid
              item
              xs={11}
              sx={{
                backgroundColor: '#F2F1F1',
                borderRadius: 1,
                m: 1,
              }}
            >
              <CheckInformation selectedPayment={props.selectedPayment} />
            </Grid>
          )}
          {props.selectedPayment.getPaymentMethod() === 'bankTransfer' && (
            <Grid item xs={11} textAlign="center">
              <BankTransferInformation
                selectedPayment={props.selectedPayment}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
