import { gql } from '@apollo/client';

export const NOTIFICATION_UPDATES = gql`
  subscription notificationUpdates($filter: NotificationUpdatesFilter) {
    notificationUpdates(filter: $filter) {
      notification {
        id
        type
        user {
          id
        }
        isRead
        readAt
        metadata {
          appointment {
            appointmentId
          }
          release {
            releaseObj {
              id
              title
              version
              date
              notes
            }
          }
          stripeConnect {
            action
          }
          text
          link
        }
      }
      action
    }
  }
`;
