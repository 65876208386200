import './text-divider.module.css';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { Theme } from '@mui/material';

/* eslint-disable-next-line */
export interface TextDividerProps {
  color?: string;
  textColor?: string;
  title?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '15px',
    marginBottom: '15px',
    flex: 1,
  },
  hairline: {
    backgroundColor: (props: any) =>
      (props.color === 'primary' ? theme.palette.primary.main : props.color) ||
      '#A2A2A2',
    height: '2px',
    flex: 1,
    minWidth: '10px',
  },
  textDivider: {
    marginLeft: '10px',
    marginRight: '10px',
    color: (props: any) =>
      props.textColor === 'primary'
        ? theme.palette.primary.main
        : props.textColor,
  },
}));

export function TextDivider(props: TextDividerProps) {
  const { color, textColor, title } = props;
  const classes = useStyles({ color, textColor });
  return (
    <div className={classes.divider}>
      <span className={classes.hairline}>&nbsp;</span>
      <span className={classes.textDivider}>{title}</span>
      <span className={classes.hairline}>&nbsp;</span>
    </div>
  );
}
