import './address-form.module.css';
import * as React from 'react';
import { Grid } from '@mui/material';
import { CountryOption, CountrySelect, TextInput } from '../../';
// import GoogleMapAddressSearch from '../google-map-address-search/google-map-address-search';
import PhoneInput from '../phone-input/phone-input';
import EmailInput from '../email-input/email-input';
import { useTranslation } from '@kiway/shared/utils/translation';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';
import translations from './translations';
import { KiwayLanguagesType } from '@kiway/shared/react-types';

export interface AddressFormControllerProps {
  address: Address;
  availableCountries?: CountryOption[];
  readonly?: boolean;
  setAddress: (updates: any) => void;
}

export function AddressFormController(props: AddressFormControllerProps) {
  const handleChange = (event: any) => {
    props.setAddress({
      ...props.address,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <AddressForm
      address={props.address}
      availableCountries={props.availableCountries}
      handleChange={handleChange}
      handleChangeCountry={(value) =>
        props.setAddress({ ...props.address, countryCode: value.code })
      }
      handleChangeGoogleAddress={(value) => {
        props.setAddress({ ...props.address, line2: value });
      }}
      handleConfirmGoogleAddress={(value) => {
        const prev = props.address;
        props.setAddress({
          ...prev,
          city: value.locality ?? '',
          line2: value.address1,
          zipCode: value.postcode,
        });
      }}
      readonly={props.readonly}
    />
  );
}

export type Address = {
  id: string;
  city: string;
  default?: boolean;
  line0?: string;
  line1?: string;
  line2: string;
  line3?: string;
  zipCode: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  email: string;
  mobilePhone: string;
  nif: string;
};

/* eslint-disable-next-line */
export interface AddressFormProps {
  address: Address;
  availableCountries?: CountryOption[];
  handleChange: (event: any) => void;
  handleChangeCountry: (value: CountryOption) => void;
  handleChangeGoogleAddress: (value: string) => void;
  handleConfirmGoogleAddress: (value: any) => void;
  readonly?: boolean;
}

export function AddressForm(props: AddressFormProps) {
  const { addTranslationDynamically, t } = useTranslation();
  Object.entries(translations).map(([lng, translation]) => {
    addTranslationDynamically(
      lng as KiwayLanguagesType,
      'address-form',
      translation,
      true
    );
  });
  return (
    <Grid container>
      <Grid item xs={12}>
        <CountrySelect
          countries={props.availableCountries}
          disabled={props.readonly}
          label={t('address-form:country')}
          margin="dense"
          onChange={props.handleChangeCountry}
          required
          size="small"
          value={props.address?.countryCode ?? getFallbackLanguage()}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={6}>
            <TextInput
              disabled={props.address?.countryCode === '' || props.readonly}
              fullWidth
              label={t('address-form:firstName')}
              name="firstName"
              onChange={props.handleChange}
              required
              value={props.address?.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              disabled={props.address?.countryCode === '' || props.readonly}
              fullWidth
              label={t('address-form:lastName')}
              name="lastName"
              onChange={props.handleChange}
              required
              value={props.address?.lastName}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          disabled={props.address?.countryCode === '' || props.readonly}
          fullWidth
          label={t('address-form:line0')}
          name="line0"
          onChange={props.handleChange}
          value={props.address?.line0}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          disabled={props.address?.countryCode === '' || props.readonly}
          fullWidth
          label={t('address-form:line1')}
          name="line1"
          onChange={props.handleChange}
          value={props.address?.line1}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          disabled={props.address?.countryCode === '' || props.readonly}
          fullWidth
          label={t('address-form:line2')}
          name="line2"
          onChange={props.handleChange}
          value={props.address?.line2}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          disabled={props.address?.countryCode === '' || props.readonly}
          fullWidth
          label={t('address-form:line3')}
          name="line3"
          onChange={props.handleChange}
          value={props.address?.line3}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <GoogleMapAddressSearch
          country={props.address?.country?.code}
          label="Ligne d'adresse 1"
          inputValue={props.address?.line1 ?? ''}
          setAddressComponent={props.handleConfirmGoogleAddress}
          setAddress={props.handleChangeGoogleAddress}
          readOnly={props.address?.countryCode === '' || props.readonly}
          required
        />
      </Grid> */}
      <Grid item xs={12}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={6}>
            <TextInput
              disabled={props.address?.countryCode === '' || props.readonly}
              fullWidth
              label={t('address-form:city')}
              name="city"
              onChange={props.handleChange}
              required
              value={props.address?.city}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              disabled={props.address?.countryCode === '' || props.readonly}
              fullWidth
              label={t('address-form:zipCode')}
              name="zipCode"
              onChange={props.handleChange}
              required
              value={props.address?.zipCode}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={6}>
            <EmailInput
              disabled={props.address?.countryCode === '' || props.readonly}
              fullWidth
              label={t('address-form:email')}
              name="email"
              onChange={props.handleChange}
              required
              value={props.address?.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneInput
              disabled={props.address?.countryCode === '' || props.readonly}
              value={props.address?.mobilePhone ?? ''}
              forceRegionCode={props.address?.countryCode}
              onChange={(value) =>
                props.handleChange({ target: { name: 'mobilePhone', value } })
              }
              fullWidth
              label={t('address-form:mobilePhone')}
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          disabled={props.address?.countryCode === '' || props.readonly}
          fullWidth
          label={t('address-form:nif')}
          helperText={t('address-form:helperTextNif')}
          name="nif"
          onChange={props.handleChange}
          value={props.address?.nif}
        />
      </Grid>
    </Grid>
  );
}

export default AddressForm;
