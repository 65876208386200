import * as React from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from '@kiway/shared/utils/translation';

interface SaveButtonProps {
  isDisabled: boolean;
  onClick: any;
}

export function SaveButton(props: SaveButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      onClick={props.onClick}
      disabled={props.isDisabled}
      variant="contained"
      color="minoritary"
    >
      {t(`pharmaco:button.save`)}
    </Button>
  );
}
