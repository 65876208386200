/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import { OrderPreparation } from './OrderPreparation';
import { useOrdersGateway } from '@kiway/ecommerce-react';
import { Order } from '@kiway/ecommerce-react-compatible';
import { links } from '../../../config';
import { PharmacoOrder } from '../../../core/entities/PharmacoOrder';
import { AuthContext } from '@kiway/shared/features/authentication-react';

export function OrderPreparationController(props) {
  const { editOrders, findOne } = useOrdersGateway();
  const [loading, setLoading] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const [order, setOrder] = React.useState<Order[]>([]);
  const [pharmacoOrder, setPharmacoOrder] = React.useState<PharmacoOrder[]>([]);
  const {
    authState: { userInfo },
  } = React.useContext(AuthContext);

  React.useEffect(() => {
    setLoading(true);
    if (props.id) {
      findOne(props.id)
        .then(async (order) => {
          if (order && order.getId()) {
            setOrder([order]);
            setPharmacoOrder([
              new PharmacoOrder({
                order,
                saveCallback: handleSaveOrder,
              }),
            ]);
          }
        })
        .finally(() => {
          setReady(true);
          setLoading(false);
        });
    }
    return () => {
      setOrder([]);
      setReady(false);
      setPharmacoOrder([]);
    };
  }, [props.id]);

  const handleSaveOrder = async () => {
    setLoading(true);
    return await editOrders([order?.[0]?.toInput(true)])
      .then((order) => {
        setPharmacoOrder([
          new PharmacoOrder({
            order: order?.[0],
            saveCallback: handleSaveOrder,
          }),
        ]);
        setOrder([order[0]]);
      })
      .finally(() => setLoading(false));
  };

  const handlePrepareClick = (itemId: string) => {
    order?.[0].togglePreparedItem(itemId);
    setOrder([order?.[0]]);
    handleSaveOrder();
  };

  const handleClickFinish = async () => {
    order?.[0]?.endPreparation(userInfo?._id);
    setOrder([order?.[0]]);
    await handleSaveOrder();
    window.location.href = links.getOrderDetailsScreen(props.id);
  };

  const handlePodPreparedChange = (formulaId: string) => {
    order?.[0]?.setCustom({
      ...(order?.[0]?.getCustom() ?? {}),
      [formulaId]: {
        ...(order?.[0]?.getCustom()?.[formulaId] ?? {}),
        podPrepared: !order?.[0]?.getCustom()?.[formulaId]?.podPrepared,
      },
    });
    setOrder([order?.[0]]);
    handleSaveOrder();
  };

  return (
    <OrderPreparation
      handlePrepareClick={handlePrepareClick}
      handleClickFinish={handleClickFinish}
      order={order?.[0]}
      pharmacoOrder={pharmacoOrder?.[0]}
      loading={loading}
      ready={ready}
      handlePodPreparedChange={handlePodPreparedChange}
    />
  );
}
