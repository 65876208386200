import * as React from 'react';
import { Autocomplete, Grid, MenuItem, Select, TextField } from '@mui/material';
import { User } from '../../../core/entities/User';
import { useTranslation } from '@kiway/shared/utils/translation';

export interface UserGlobalSettingsProps {
  availableLanguages: Array<{ key: string; code: string; label: string }>;
  handleChange: (field: string) => (...args: any[]) => any;
  timezones: string[];
  user: User;
}

export function UserGlobalSettings(props: UserGlobalSettingsProps) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container alignItems={'center'}>
          <Grid item xs={12} sm={5}>
            {t('authentication:editProfile.settings.timezone')}
          </Grid>
          <Grid item xs={12} sm={7}>
            <Autocomplete
              disablePortal
              fullWidth
              id="select-timezone"
              options={props.timezones}
              value={
                props.user?.getTimezone() ||
                t('authentication:editProfile.settings.browserDefaults')
              }
              onChange={(_event, value) =>
                props.handleChange('timezone')(value)
              }
              renderInput={(params) => (
                <TextField {...params} size="small" margin="dense" fullWidth />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems={'center'}>
          <Grid item xs={12} sm={5}>
            {t('authentication:editProfile.settings.language')}
          </Grid>
          <Grid item xs={12} sm={7}>
            <Select
              id="select-language"
              fullWidth
              size="small"
              margin="dense"
              defaultValue="0"
              value={props.user.getLanguage() || '0'}
              onChange={(event) =>
                props.handleChange('language')(event.target.value || '0')
              }
            >
              <MenuItem value={'0'}>
                {t('authentication:editProfile.settings.browserDefaults')}
              </MenuItem>
              {props.availableLanguages.map(({ key, label, code }) => (
                <MenuItem key={key} value={code}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
