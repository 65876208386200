import { Country } from '../entities/Country';
import { CountryProvider } from './CountryProvider';

export class GetAllCountries {
  private countryProvider: CountryProvider;

  public constructor(countryProvider: CountryProvider) {
    this.countryProvider = countryProvider;
  }

  public execute(): Promise<Country[]> {
    return this.countryProvider.findAll();
  }
}
