import {
  flattenTranslatableAttributeToJson,
  TranslatableAttribute,
  KiwayLanguagesType,
} from '@kiway/shared/react-types';
import { ShippingMethod } from './ShippingMethod';

export interface ICountryAttributes {
  name: TranslatableAttribute;
  code: string;
  vatPlant: number;
  vatPowder: number;
}

export class Country {
  protected id: string;
  protected name: TranslatableAttribute;
  protected code: string;
  protected vatPlant: number;
  protected vatPowder: number;

  public constructor(
    obj: Partial<ICountryAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    this.name = obj?.name;
    this.code = obj?.code;
    this.vatPlant = obj?.vatPlant;
    this.vatPowder = obj?.vatPowder;
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): Country {
    this.id = id;
    return this;
  }

  public getName(): TranslatableAttribute {
    return this.name;
  }

  public setName(name: TranslatableAttribute): Country {
    this.name = name;
    return this;
  }

  public getCode(): string {
    return this.code?.toUpperCase();
  }

  public setCode(code: string): Country {
    this.code = code?.toUpperCase();
    return this;
  }

  public getVatPlant(): number {
    return this.vatPlant;
  }

  public setVatPlant(vatPlant: number): Country {
    this.vatPlant = vatPlant;
    return this;
  }

  public isVatPlantDefined(): boolean {
    return (
      this.vatPlant !== undefined &&
      this.vatPlant !== null &&
      this.vatPlant >= 0
    );
  }

  public getVatPowder(): number {
    return this.vatPowder;
  }

  public setVatPowder(vatPowder: number): Country {
    this.vatPowder = vatPowder;
    return this;
  }

  public isVatPowderDefined(): boolean {
    return (
      this.vatPowder !== undefined &&
      this.vatPowder !== null &&
      this.vatPowder >= 0
    );
  }

  toJSON(lng?: KiwayLanguagesType): any {
    return {
      id: this.getId(),
      name: lng ? this.getName()?.[lng] ?? this.getName() : this.getName(),
      code: this.getCode(),
      vatPlant: this.getVatPlant(),
      vatPowder: this.getVatPowder(),
    };
  }

  public toDatatableRow(): any {
    return {
      _id: this.getId(),
      code: this.getCode()?.toUpperCase(),
      vatPlant: this.getVatPlant(),
      vatPowder: this.getVatPowder(),
      ...flattenTranslatableAttributeToJson(this.getName(), 'name'),
    };
  }

  public toDropdownValue(lng: KiwayLanguagesType): any {
    return {
      value: this.getId(),
      label: this.getName()?.[lng],
    };
  }

  public toCountryOption(lng: KiwayLanguagesType): any {
    return {
      code: this.getCode(),
      name: this.getName()?.[lng],
    };
  }

  static filterByAvailabilityInShippingMethods(
    shippingMethods: ShippingMethod[]
  ) {
    return (country: Country) =>
      shippingMethods?.reduce((prev, shippingMethod) => {
        return (
          prev ||
          shippingMethod
            ?.getCountryZone()
            ?.getCountries()
            ?.map((country) => country.getCode()?.toLowerCase())
            ?.includes(country?.getCode()?.toLowerCase())
        );
      }, false);
  }
}
