import * as React from 'react';
import { Typography, Grid, ButtonGroup, Button } from '@material-ui/core';
import {
  plantMetadataTypes,
  ProductMetadata,
  ProductMetadataType,
  ProductType,
  productTypes,
  displayedMetadataTypes,
  formulaMetadataTypes,
} from '../../../core/entities/ProductMetadata';
import { ListProductMetadatasDatatable } from './ListProductMetadatasDatatable';
import { useTranslation } from '@kiway/shared/utils/translation';

export interface ListProductMetadatasProps {
  handleAddData?: (type: ProductMetadataType) => () => void;
  handleSave?: any;
  productMetadatas: ProductMetadata[];
  loading?: boolean;
}

export function ListProductMetadatas({
  handleAddData,
  handleSave,
  loading,
  productMetadatas,
}: React.PropsWithChildren<ListProductMetadatasProps>) {
  const [displayedProductType, setDisplayedProductType] = React.useState<
    ProductType
  >(productTypes?.[0]);
  const [displayedType, setDisplayedType] = React.useState<ProductMetadataType>(
    productTypes?.[0] === 'plant'
      ? plantMetadataTypes?.[0]
      : productTypes?.[0] === 'formula'
      ? formulaMetadataTypes?.[0]
      : null
  );
  React.useEffect(() => {
    setDisplayedType(
      displayedProductType === 'plant'
        ? plantMetadataTypes?.[0]
        : displayedProductType === 'formula'
        ? formulaMetadataTypes?.[0]
        : null
    );
  }, [displayedProductType]);
  const { t } = useTranslation();
  const getButtonMetadata = (type: ProductMetadataType, index) => (
    <Button
      key={index}
      onClick={() => setDisplayedType(type)}
      variant={type === displayedType ? 'contained' : undefined}
    >
      {t(`pharmaco:productMetadatas.${type}.cardTitle`)}
    </Button>
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">
          {t('pharmaco:productMetadatas.docSubtitle')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ButtonGroup color="primary">
          {productTypes?.map((type: ProductType, index) => (
            <Button
              key={index}
              onClick={() => setDisplayedProductType(type)}
              variant={type === displayedProductType ? 'contained' : undefined}
            >
              {t(`pharmaco:productMetadatas.productTypes.${type}`)}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <ButtonGroup color="primary">
          {displayedProductType === 'plant'
            ? plantMetadataTypes?.map(getButtonMetadata)
            : displayedProductType === 'formula'
            ? formulaMetadataTypes?.map(getButtonMetadata)
            : null}
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {displayedMetadataTypes?.map((type: ProductMetadataType) => (
            <Grid
              item
              xs={12}
              key={type}
              style={{
                display:
                  type === displayedType &&
                  (displayedProductType === 'plant'
                    ? plantMetadataTypes
                    : displayedProductType === 'formula'
                    ? formulaMetadataTypes
                    : []
                  ).includes(type)
                    ? undefined
                    : 'none',
              }}
            >
              <ListProductMetadatasDatatable
                handleAddData={handleAddData(type)}
                handleSave={handleSave}
                loading={loading}
                productMetadatas={productMetadatas?.filter(
                  (p) => p?.getMetadataType() === type
                )}
                type={type}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
