/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Order, ShippingMethod } from '@kiway/ecommerce-react';
import { PaymentMethod } from '@kiway/ecommerce-react-compatible';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { BankTransfer } from '../Payment/PaymentForms/BankTransfer';
import { PaymentMethodsAccordion } from '../Payment/PaymentMethodsAccordion';
import { ShippingMethodSelector } from '../Shipping/ShippingMethod';
import { Summary } from '../SummaryAndValidation/Summary';
import { Check } from '../Payment/PaymentForms/Check';
import PrintIcon from '@mui/icons-material/Print';

interface DeliveryPaymentProps {
  order: Order;
  availableShippingMethods?: ShippingMethod[];
  handleChangeShippingMethod: (shippingMethodId: string) => void;
  handleChange: (name: PaymentMethod) => void;
  expanded: PaymentMethod;
  handleBankTransferProofDeleted: (isDeleted?: boolean) => void;
  handleBankTransferProofUploaded: (file: any[]) => any;
  handleChangeBankInfo: (name: string) => (e: any) => void;
  handleChangeCheckInfo: (name: string) => (e: any) => void;
  handleSavePayment: () => void;
  payLoading?: boolean;
  isPayButtonDisabled: () => boolean;
  handlePayClick: () => void;
  loading?: boolean;
}

const useStyles = makeStyles({
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
});

export const DeliveryPayment = (props: DeliveryPaymentProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} className={classes.noPrint}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {t('pharmaco:orderDetails.shipment.selectMethod')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ShippingMethodSelector
              availableMethods={props.availableShippingMethods}
              disabled={props.loading}
              handleChangeShippingMethod={props.handleChangeShippingMethod}
              selectedMethodId={props.order?.getShippingMethod()?.getId()}
              countryCode={props.order?.getShippingAddress().getCountryCode()}
              weight={props.order?.getTotalWeight()}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {t('pharmaco:orderDetails.payment.label')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <PaymentMethodsAccordion
                  disabled={props.loading}
                  handleChange={props.handleChange}
                  targetExpand={props.expanded}
                  name="card"
                  summaryTitle={t('pharmaco:orderDetails.payment.forms.card')}
                  summaryDescription={t(
                    'pharmaco:orderDetails.payment.forms.cardDescription'
                  )}
                ></PaymentMethodsAccordion>
                <PaymentMethodsAccordion
                  disabled={props.loading}
                  handleChange={props.handleChange}
                  targetExpand={props.expanded}
                  name={'check'}
                  summaryTitle={t('pharmaco:orderDetails.payment.forms.check')}
                  summaryDescription={t(
                    'pharmaco:orderDetails.payment.forms.checkDescription'
                  )}
                >
                  <Typography sx={{ mb: 2 }}>
                    {t('pharmaco:orderDetails.payment.forms.checkDescription')}
                  </Typography>
                  <Check
                    disabled={props.loading}
                    handleChange={props.handleChangeCheckInfo}
                    handleSave={props.handleSavePayment}
                    order={props.order}
                  />
                </PaymentMethodsAccordion>
                <PaymentMethodsAccordion
                  disabled={props.loading}
                  handleChange={props.handleChange}
                  targetExpand={props.expanded}
                  name="bankTransfer"
                  summaryTitle={t(
                    'pharmaco:orderDetails.payment.forms.bankTransfer'
                  )}
                  summaryDescription={t(
                    'pharmaco:orderDetails.payment.forms.bankTransferDescription'
                  )}
                >
                  <BankTransfer
                    disabled={props.loading}
                    file={
                      props.order?.getPayment()?.getCustom()?.bankTransfer
                        ?.proof
                    }
                    handleBankTransferProofDeleted={
                      props.handleBankTransferProofDeleted
                    }
                    handleBankTransferProofUploaded={
                      props.handleBankTransferProofUploaded
                    }
                  />
                </PaymentMethodsAccordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Summary order={props.order} />
          </Grid>
          <Grid item xs={1} className={classes.noPrint}>
            <Tooltip title={t('pharmaco:formula.print')}>
              <IconButton onClick={() => window.print()}>
                <PrintIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={11} className={classes.noPrint}>
            <Button
              fullWidth
              variant="contained"
              color="minoritary"
              onClick={props.handlePayClick}
              disabled={props.loading || props.isPayButtonDisabled()}
            >
              {props.payLoading ? (
                <CircularProgress size={25} />
              ) : (
                t('pharmaco:orderDetails.payment.pay')
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
