export default {
  address: {
    addAddress: 'Ajouter une adresse',
    address: 'Adresse',
    city: 'Ville',
    country: 'Pays',
    fullName: 'Nom complet',
    label: 'Adresse',
    number: 'Numéro de rue',
    useAddress: 'Utiliser cette adresse',
    useAsDefault: 'Utiliser comme adresse par défaut',
    validate: 'Valider les modifications',
    zipCode: 'Code postal',
  },
  backToList: 'Retour à la liste',
  backToOrderDetails: 'Retour aux détails de la commande',
  browser: {
    addCart: 'Ajouter au panier',
    all: 'Tous',
    emptyCart: 'Votre panier est vide',
    formula: 'Formules',
    ingredients: 'Ingrédients',
    jingFang: 'Jing Fang',
    noFilter: 'Aucun filtre',
    normal: 'Normales',
    noSelected: 'Aucune plante sélectionnée',
    plant: 'Plantes',
    powder: 'Poudre concentrée',
    sentence: {
      all: 'tous les articles',
      contains: 'qui contiennent',
      display: 'Vous affichez',
      formulas_jingFang: 'les formules Jing Fang',
      formulas_normal: 'les formules normales',
      formulas_xiaoFang: 'les formules Xiao Fang',
      have_action: 'qui ont pour action',
      have_category: 'qui ont pour catégorie',
      have_syndrom: 'traitant contre le syndrome',
      ingredients_all: 'tous les ingrédients',
      ingredients_plant: 'les ingrédients disponibles en plantes sèches',
      ingredients_powder: 'les ingrédients disponibles en poudre concentrée',
    },
    xiaoFang: 'Xiao Fang',
  },
  button: {
    discard: 'Annuler',
    save: 'Enregistrer',
  },
  cancel: 'Annuler',
  cancelOrder: {
    message: 'Êtes-vous sûr de vouloir annuler cette commande ?',
    title: 'Annuler la commande',
  },
  carriers: {
    code: {
      helperText: 'Utile pour le développeur, ne pas modifier',
      label: "Code d'automatisation",
    },
    docSubtitle: 'Liste des transporteurs',
    label: {
      en: 'Nom EN',
      es: 'Nom ES',
      fr: 'Nom FR',
      pt: 'Nom PT',
    },
    linkText: 'Transporteurs',
    trackingLink: {
      helperTextError:
        'Remplacez le numéro de suivi dans le lien par {{trackingNumber}}',
      label: 'Lien de suivi du colis',
    },
  },
  checkout: {
    acceptation: "J'accepte la politique de confidentialité de Yao Dao",
    address: 'Vérification adresse',
    approvment: "J'approuve les conditions de vente",
    cardPaymentSuccess: 'Votre paiement a bien été pris en compte.',
    confirmAddress: "Je confirme l'exactitude de ces informations",
    confirmAddressMessage:
      "Merci de vérifier que les informations de l'adresse sont exactes. Nous ne serons pas tenu responsables en cas de non livraison de votre commande.",
    confirmation: 'Confirmation',
    deferredPaymentSuccess: 'Pensez à bien envoyer votre paiement.',
    deliveryPayment: 'Livraison et paiement',
    errorDeferredPayment:
      'Une erreur est survenue durant le paiement. Merci de contacter un administrateur.',
    errorSnackbarAddress:
      'Adresse invalide, veuillez remplir les champs obligatoires signalés par un *',
    homeReturn: "Retour à l'accueil",
    proSpaceReturn: 'Retour à votre espace professionnel',
    wish:
      "Je souhaite recevoir des mails de Yao Dao qui m'informent sur les offres, les promotions, les nouveautés, les stages et formations en rapport avec la pharmacopée chinoise et la médecine traditionnelle chinoise.",
  },
  composition: {
    dosage: 'Dosage',
    name: 'Nom',
    title: 'Composition',
  },
  countries: {
    docSubtitle: 'Liste des pays',
    label: {
      code: 'Code iso',
      name: {
        en: 'Nom EN',
        es: 'Nom ES',
        fr: 'Nom FR',
        pt: 'Nom PT',
      },
      vatPlant: 'TVA plantes',
      vatPowder: 'TVA poudres concentrées',
    },
    linkText: 'Pays',
  },
  countryZones: {
    countries: 'Liste des pays',
    docSubtitle: 'Liste des zones pays',
    label: {
      name: 'Nom',
    },
    linkText: 'Zones pays',
  },
  dashboard: {
    analytics: {
      avgCart: 'Panier moyen',
      charts: {
        orderStatus: 'Statut des commandes',
        preparerStats: 'Statistiques des préparatrices',
      },
      extra: {
        period: {
          month: 'que le mois précédent',
          prevMonth: "que le mois d'avant",
          prevQuarter: 'que le trismestre dernier',
          prevWeek: "que la semaine d'avant",
          prevYear: "que l'année d'avant",
          quarter: 'que le trimestre dernier',
          today: "qu'hier",
          week: 'que la semaine dernière',
          year: "que l'année dernière",
          yesterday: 'que la veille',
        },
        profit: {
          less: 'de moins',
          more: 'de plus',
        },
        type: {
          clients: 'clients',
          orders: 'commandes',
        },
      },
      HT_HT: 'Hors taxes et transport',
      nbOrder: 'Nombre de commandes',
      orderCancelled: 'Commandes annulées',
      orderCompleted: 'Commandes terminées',
      periods: {
        lastQuarter: 'Le trimestre dernier',
        month: 'Ce mois',
        prevMonth: 'Le mois dernier',
        prevWeek: 'La semaine dernière',
        prevYear: "L'année dernière",
        quarter: 'Ce trimestre',
        today: "Aujourd'hui",
        week: 'Cette semaine',
        year: 'Cette année',
        yesterday: 'Hier',
      },
      revenue: "Chiffre d'affaires",
      running: 'En cours',
      title: 'Tableau de bord',
      totalClients: 'Clients totaux',
      totalOrders: 'Commandes totales',
    },
  },
  deleteOrder: {
    message: 'Êtes-vous sûr de vouloir supprimer cette commande ?',
    title: 'Supprimer la commande',
  },
  filters: {
    carriers: {
      all: 'Tous',
      label: 'Transporteur',
    },
    metadatas: {
      action: 'Sélectionnez une action',
      formulasCategory: 'Sélectionnez une catégorie de formules',
      ingredientsCategory: "Sélectionnez une catégorie d'ingrédients",
      syndrom: 'Sélectionnez un syndrome',
    },
    shippingMethods: {
      all: 'Tous',
      label: 'Méthode de livraison',
    },
    variations: {
      all: 'Tous',
      label: 'Conditionnement',
    },
  },
  formula: {
    clickPlantToSeeMore: 'Cliquez sur une plante pour voir sa fiche',
    concentration: 'Taux de concentration',
    description: 'Fang zheng {{code}}',
    detailsDocSubtitle: 'Détails de la formule',
    detailsLinkText: 'Détails des formules',
    detailsSuccessUpdate_one: 'Modification enregistrée',
    detailsSuccessUpdate_other: 'Modifications enregistrées',
    docSubtitle: 'Liste des formules',
    dosage: 'Dosage',
    dosagePlant: 'Dosage en plantes sèches',
    dosagePowder: 'Dosage en poudre concentrée',
    equivalentCP: 'Equiv. CP',
    equivalentPS: 'Equiv. PS',
    fangZheng: 'Fang Zheng',
    jingFang: 'Jing Fang',
    legacyId: 'Ancient ID :',
    linkText: 'Formules',
    listTitle: 'Formules',
    normal: 'Normal',
    percent: '%',
    pinYinName: 'Nom pinyin',
    priceHT: 'Prix HT',
    print: 'Imprimer la formule',
    published: 'Publiée',
    separate: 'Séparé',
    total: 'Total',
    totalWeight: 'Poids total',
    variation: 'Variation',
    weightPerCapsule: 'Par gélule',
    weightPerPortion: 'Par sachet',
    xiaoFang: 'Xiao Fang',
  },
  lastOrders: {
    title: 'Dernières commandes',
  },
  linkText: 'Pharmacopée',
  list: {
    docSubtitle: 'Liste des plantes',
    linkText: 'Plantes',
  },
  loading: 'Chargement...',
  modify: 'Modifier',
  next: 'Suivant',
  noData: 'Aucune donnée',
  order: {
    addToOrder: 'Ajouter la sélection à la commande',
    aknowledgement: 'Merci pour votre commande !',
    commandDetails: {
      command: 'Commande',
      commandDetails: 'Détails de la commande',
      date: 'Date',
      product: 'Produit',
      quantity: 'Quantité',
      sentTo: 'Envoyé à',
    },
    emptyOrder: 'Commande vide',
    newOrder: 'Nouvelle commande',
    payNowButton: "Confirmer l'adresse et payer",
    resumeMessage:
      'Votre commande a été enregistrée, le patient recevra un email avec un lien de paiement. Vous pouvez dès à présent la payer en utlisant le bouton ci-dessous.',
    steps: {
      comments: 'Commentaires',
      prescription: 'Panier',
      resume: 'Validation',
      shipping: 'Livraison',
    },
  },
  orderCount: 'Nombre de commandes',
  orderDetails: {
    buttons: {
      addItemsToOrder: 'Ajouter des produits à la commande',
      dispatch: 'Expédier',
      followPackage: 'Suivre mon colis',
      invoice: 'Facture',
      mailReminder: 'Mail de rappel',
      markAsPaid: 'Marquer comme payé',
      prepare: 'Préparer',
      seePayment: 'Voir le paiement',
      sendMail: "Renvoyer l'email",
      sendTracking: 'Renvoyer suivi',
      startPrepare: 'Démarrer la préparation',
    },
    cartPrice: 'Prix du panier',
    changePatient: 'Changer de patient',
    client: {
      address1: 'Adresse 1',
      address2: 'Adresse 2',
      city: 'Ville',
      country: 'Pays',
      email: 'Email',
      firstName: 'Prénom',
      indicator: 'Indicateur',
      label: 'Patient',
      lastName: 'Nom',
      mobilePhone: 'Téléphone portable',
      nif: 'NIF',
      zip: 'Code ZIP',
    },
    comment: {
      assistantComment: 'Commentaire pour le/la préparateur/trice',
      default: 'Ø',
      label: 'Commentaire',
      none: 'Aucun commentaire',
      ownComment: 'Commentaire pour vous-même',
      patientComment: 'Commentaire pour le patient',
    },
    confirmAddresses: 'Confirmer les adresses',
    docSubtitle: 'Détails de la commande',
    editAddresses: 'Modifier les adresses',
    formula: {
      formulaCount_one: '{{ count }} formule :',
      formulaCount_other: '{{ count }} formules :',
      label: 'Formules',
      name: 'Nom',
      pinYin: 'Pin Yin',
      priceHT: 'Prix HT',
      quantity: 'Quantité',
      ref: 'Référence',
      separate: 'Séparé',
      total: 'Total',
      variation: 'Variation',
    },
    global: {
      label: 'Statut général',
      status: {
        ALL: 'Toutes les commandes',
        CANCELLED: 'Annulée',
        COMPLETE: 'Terminée',
        DRAFT: 'Brouillon',
        ERROR_PACKING: 'Erreur',
        ERROR_SYNC_STATUS: 'Erreur',
        PENDING_PACKING: 'En cours de préparation',
        PENDING_PAYMENT: 'En cours de paiement',
        PENDING_SHIPMENT: "En cours d'expédition",
        VALIDATED: 'Validée',
        WAITING_PACKING: 'En attente de préparation',
        WAITING_PAYMENT: 'En attente de paiement',
        WAITING_SHIPMENT: "En attente d'expédition",
      },
    },
    information: {
      itemNumber: 'Item',
      label: 'Informations',
      packaging: 'Emballage',
      paymentMode: 'Mode de paiement',
      paymentStatus: 'Statut de paiement',
      preparationStatus: 'Statut de préparation',
      shippingMethod: "Type d'envoi",
      shippingPrice: "Prix d'envoi",
      totalWeight: 'Poids total',
      weight: 'Poids',
    },
    mailReminder: {
      confirmError:
        "Un problème est survenu durant l'envoi du mail. Merci d'essayer à nouveau.",
      confirmSuccess:
        "Le mail contenant le lien du paiement a été renvoyé au client sur l'adresse mail associée à l'adresse de livraison.",
      confirmText:
        'Êtes-vous sûr de vouloir renvoyer le lien de paiement par mail au client ?',
      confirmTitle: 'Renvoi du lien de paiement par mail',
    },
    mailTracking: {
      confirmError:
        "Un problème est survenu durant l'envoi du mail. Merci d'essayer à nouveau.",
      confirmSuccess:
        "Le mail contenant le lien de suivi a été renvoyé au client sur l'adresse mail associée à l'adresse de livraison.",
      confirmText:
        'Êtes-vous sûr de vouloir renvoyer le lien de suivi par mail au client ?',
      confirmTitle: 'Renvoi du lien de suivi par mail',
    },
    openOrder: 'Ouvrir la commande',
    order: {
      deliveryAddress: 'Adresse de livraison',
      finishPreparation: 'Terminer la préparation et quitter',
      finishShipping: "Terminer l'expédition et quitter",
      label: 'Commande',
      newAddress: 'Nouvelle adresse',
      paymentAddress: 'Adresse de facturation',
      sameAddress: 'Adresse de facturation identique à celle de livraison',
      status: {
        CANCELLED: 'Annulée',
        COMPLETE: 'Terminée',
        DRAFT: 'Brouillon',
        OPEN: 'Ouverte',
      },
      validAddress: 'Veuillez renseigner une adresse valide',
    },
    packing: {
      label: 'Préparation',
      preparedAt: 'Préparé le',
      preparedBy: 'Préparé par',
      progress: 'Progression',
      status: {
        DELAYED: 'Retardée',
        PENDING: 'En cours',
        PREPARED: 'Préparée',
        READY: 'En attente de préparation',
        WAITING: 'En attente',
      },
      weight: {
        content: 'Contenu',
        error: 'Le poids total doit être supérieur ou égal à celui du contenu',
        packing: 'Emballage',
        total: 'Poids total du colis',
      },
    },
    payment: {
      amount: 'Montant',
      commandPaymentTitle: 'Paiment de la commande n°{{ orderNumber }}',
      docSubtitle: 'Liste des paiements',
      forms: {
        address: 'Adresse',
        bank: 'Banque',
        bankTransfer: 'Virement bancaire',
        bankTransferDescription:
          'Après avoir effectué le transfert bancaire, joindre la preuve du virement (justificatif édité par votre banque) en pièce jointe via le bouton Choisissez un fichier',
        bankTransferInformationLabel: 'Information du virement',
        card: 'Carte bancaire',
        cardDescription: 'Paiement par carte bancaire : facile et sécurisé',
        cardNumber: 'Numéro de carte',
        check: 'Chèque bancaire',
        checkDescription:
          "Réservé uniquement aux chèques français. Complétez obligatoirement le formulaire ci-dessous avant l'envoi du chèque.",
        checkInformationLabel: 'Information du chèque',
        checkNumber: 'Numéro de chèque',
        chooseFile: 'Choisissez un fichier',
        cvv: 'CVV',
        expirationDate: "Date d'expiration",
        firstName: 'Prénom',
        lastName: 'Nom',
        nameOnCard: 'Nom sur la carte',
        order: 'Ordre',
        resume: 'Résumé',
        sendProof: 'Envoyer la preuve de virement',
      },
      label: 'Paiement',
      linkText: 'Paiements',
      paidAt: 'Payé le',
      pay: 'Payer',
      paymentFees: 'Frais de paiement',
      paymentMethod: 'Méthode de paiment',
      simulate: 'Simuler un paiement',
      status: {
        BALANCE_DUE: 'Dû',
        CREDIT_OWED: 'Remboursement dû',
        DRAFT: 'Brouillon',
        FAILED: 'Echoué',
        PAID: 'Payé',
        PENDING: 'En cours',
        WAITING: 'En attente',
      },
    },
    reportError: 'Signaler une erreur',
    selectPatient: 'Sélectionnez un patient',
    sendMail: 'Envoyer un email',
    shipment: {
      carrierLabel: {
        canNotGenerateLabel:
          "La génération automatique d'étiquette n'est pas disponible pour ce transporteur",
        displayLabelNewTab: 'Ouvrir dans un nouvel onglet',
        generateLabel: "Générer l'étiquette",
        label: 'Etiquette colis',
      },
      coordonates: 'Coordonnées',
      deliveryInfo: 'Informations de livraison',
      label: 'Expédition',
      selectMethod: 'Sélectionnez une méthode de livraison',
      sentAt: 'Expédié le',
      sentBy: 'Expédié par',
      shipmentMethod: 'Mode de livraison',
      shipmentMethodCost: 'Coût de livraison',
      status: {
        BACKORDER: 'Expédition différée',
        DELAYED: 'Retardée',
        PENDING: 'Expédition en cours',
        READY: "En attente d'expédition",
        SHIPPED: 'Expédiée',
        WAITING: 'En attente',
      },
      trackingNumber: 'Numéro de suivi',
      transporter: {
        label: 'Transport',
        method: 'Mode de livraison',
        name: 'Nom du transporteur',
        price: 'Coût de la livraison',
      },
      weighing: 'Pesée',
    },
    status: 'Statut',
    therapist: {
      dispenser: 'Préparateur',
      label: 'Thérapeute',
      sender: 'Expéditeur',
    },
  },
  orders: {
    columns: {
      carrier: 'Transporteur',
      customer: 'Client',
      customerPhone: 'Portable du client',
      globalStatus: 'Statut',
      nbGroups: 'Formules',
      nbItems: 'Lignes',
      nbOfBags: 'Sachets',
      openedAt: 'Enregistrée',
      orderNumber: 'Numéro',
      orderStatus: 'Statut commande',
      packingStatus: 'Statut préparation',
      paidAt: 'Payée',
      patient: 'Patient',
      paymentStatus: 'Statut paiement',
      prescriber: 'Prescripteur',
      shipmentStatus: 'Statut expédition',
      shipping: 'Livraison',
      shippingMethod: 'Méthode de livraison',
      shippingPrice: 'Prix livraison',
      totalGrossPrice: 'Total HT',
      totalNetPrice: 'Total TTC',
      totalNetPriceWT: 'Total TTC',
      totalPrice: 'Total à payer',
      variationTypes: 'Type',
    },
    docSubtitle: 'Liste des commandes',
    linkText: 'Commandes',
    orderResume: 'Résumé de la commande',
  },
  plant: {
    availableConcentratedPowder: 'Disponible en poudre concentrée',
    availablePlant: 'Disponible en plante',
    chineseName: 'Nom chinois',
    commonName: 'Nom commun',
    concentratedPowder: 'Poudre concentrée',
    concentrationRate: 'Taux de concentration',
    detailsDocSubtitle: 'Détails de la plante',
    detailsLinkText: 'Détails des plantes',
    detailsSuccessUpdate_one: 'Modification enregistrée',
    detailsSuccessUpdate_other: 'Modifications enregistrées',
    dryPlant: 'Plante sèche',
    latinName: 'Nom latin',
    legacyId: 'Ancient ID :',
    listTitle: 'Plantes',
    otherName: 'Autres noms',
    pinYinName: 'Nom pinyin',
    plant: 'Plante',
    posology: 'Posologie',
    posologyMax: 'Posologie max',
    posologyMin: 'Posologie min',
    published: 'Publiée',
    refStock: 'Ref. stock',
    refStockPlant: 'Ref. stock plante',
    refStockPowder: 'Ref. stock poudre',
    sellAvailable: 'Disponible à la vente',
    toxic: 'Toxique',
    noToxic: 'Non toxique',
    unavailableConcentratedPowder: 'Indisponible en poudre concentrée',
    unavailablePlant: 'Indisponible en plante',
  },
  posologies: {
    displayChip: {
      max: "Jusqu'à {{max}}g",
      min: 'A partir de {{min}}g',
      minMax: 'De {{min}}g à {{max}}g',
    },
    max: 'Maximum',
    min: 'Minimum',
    requirementsNotOK: 'Le min doit être inférieur ou égal au max',
    title: 'Posologies',
  },
  prescription: {
    addPod: 'Ajouter une dosette',
    decoction: 'Décoction simplifiée',
    docSubtitle: 'Prescription',
    nbOfBags: 'Nombre de sachets',
    nbOfCapsulesBatch: 'Nombre de lots de gélules (1 lot = 100 gélules = 50g)',
    order: {
      label: 'Commande',
    },
    pharmaceuticalForm: {
      capsule: 'Gélule',
      label: 'Forme galénique',
      small_bag: 'Sachet',
    },
    plantPowderGroundSize: {
      big_ground: 'Moulue gros',
      fine_ground: 'Moulue fin',
      label: 'Conditionnement',
      whole: 'Plante entière',
    },
    plantPowderType: {
      label: 'Type de substance',
      plant: 'Plante',
      powder: 'Poudre concentrée',
    },
    pod: 'Dosette',
    posology: {
      capsule: {
        plant: {
          resume_one:
            'Je veux {{nbOfCapsulesBatch}} lot de {{capsulesBatchSize}} gélules de {{capsuleWeight}}g de cette formule en plantes sèches',
          resume_other:
            'Je veux {{nbOfCapsulesBatch}} lots de {{capsulesBatchSize}} gélules de {{capsuleWeight}}g de cette formule en plantes sèches',
          title: 'Posologie gélules PS',
        },
        powder: {
          resume_one:
            'Je veux {{nbOfCapsulesBatch}} lot de {{capsulesBatchSize}} gélules de {{capsuleWeight}}g de cette formule en poudre concentrée',
          resume_other:
            'Je veux {{nbOfCapsulesBatch}} lots de {{capsulesBatchSize}} gélules de {{capsuleWeight}}g de cette formule en poudre concentrée',
          title: 'Posologie gélules CP',
        },
      },
      plant: {
        resume_one:
          'Je veux {{nbOfBags}} sachet de {{portionWeight}}g de cette formule en plantes sèches ({{variation}})',
        resume_other:
          'Je veux {{nbOfBags}} sachets de {{portionWeight}}g de cette formule en plantes sèches ({{variation}})',
        title: 'Posologie PS',
      },
      powder: {
        resume:
          'Je veux 1 sachet de {{totalWeight}}g de cette formule en poudre concentrée',
        title: 'Posologie CP',
      },
      shortenDescription: {
        capsule: {
          resume_one:
            '{{nbOfCapsulesBatch}} lot {{capsulesBatchSize}} {{abbreviation}}',
          resume_other:
            '{{nbOfCapsulesBatch}} lots {{capsulesBatchSize}} {{abbreviation}}',
        },
        plant: {
          resume_one:
            '{{nbOfBags}} sachet de {{portionWeight}}g {{abbreviation}}',
          resume_other:
            '{{nbOfBags}} sachets de {{portionWeight}}g {{abbreviation}}',
        },
        powder: {
          resume: '1 sachet de {{totalWeight}}g {{abbreviation}}',
        },
      },
    },
    quantity: {
      default: 'Poids total (en grammes)',
      portion: 'Poids par sachet (en grammes)',
      total: 'Poids total (en grammes)',
    },
    reasonCantGoToCommentSection: {
      noCustomer: 'Vous devez sélectionner un patient pour passer à la suite',
      noFormulas:
        'Vous devez créer au moins une formule pour passer à la suite',
      notAllItemsAvailable:
        'Vous devez vérifier que tous les ingrédients soient disponibles pour passer à la suite',
      unknown:
        'Une erreur vous empêche de passer à la suite, vérifier la commande sinon contactez un administateur',
    },
    reasonCantValidateOrder: {
      invalidAddress:
        'Vous devez renseigner des adresses valides pour valider la commande',
      loyaltyProgramError:
        'Vous devez sélectionner une option remise fidélité valide pour finir la commande',
      noShippingMethod:
        'Vous devez sélectionner une méthode de livraison pour valider la commande',
      notAllItemsAvailable:
        'Vous devez vérifier que tous les ingrédients sont disponibles pour valider la commande',
      unknown:
        'Une erreur vous empêche de valider la commande, vérifier la commande sinon contactez un administateur',
    },
    resume: {
      cartGrossTotal: 'Coût HT du panier',
      cartNetTotal: 'Coût TTC du panier',
      deliveryCost: 'Frais de livraison',
      loyaltyOption: {
        label: 'Remise fidélité',
        addPoints: 'Cumuler mes points',
        pay10Percent: 'Payer 10% de la commande avec mes points',
        pay15Percent: 'Payer 15% de la commande avec mes points',
        pay20Percent: 'Payer 20% de la commande avec mes points',
        pay5Percent: 'Payer 5% de la commande avec mes points',
      },
      missingCostsExplanation:
        "Les taxes et les frais de livraison seront calculés à l'étape de livraison",
      totalToPay: 'Total à payer',
    },
    weightMode: {
      label: 'Poids équivalent à ?',
      portion: 'Poids par sachet (en grammes)',
      total: 'Poids total',
    },
    withoutPod: 'Sans dosette',
    withPod: 'Avec dosette',
  },
  previous: 'Précédent',
  productMetadatas: {
    action: {
      cardTitle: 'Actions',
      groupByLabel: 'Action',
    },
    categoryFormula: {
      cardTitle: 'Catégories',
      groupByLabel: 'Catégorie',
    },
    categoryFormulaJingFang: {
      cardTitle: 'Catégories Jing Fang',
      groupByLabel: 'Catégorie Jing Fang',
    },
    categoryFormulaXiaoFang: {
      cardTitle: 'Catégories Xiao Fang',
      groupByLabel: 'Catégorie Xiao Fang',
    },
    categoryPlant: {
      cardTitle: 'Catégories',
      groupByLabel: 'Catégorie',
    },
    composition: 'Composition',
    docSubtitle: 'Metadata des produits',
    linkText: 'Metadata',
    meridian: {
      cardTitle: 'Méridiens',
      groupByLabel: 'Méridien',
    },
    name: 'Nom {{lngCode}}',
    nature: {
      cardTitle: 'Natures',
      groupByLabel: 'Nature',
    },
    plant: {
      cardTitle: 'Plantes',
      groupByLabel: 'Plante',
    },
    productTypes: {
      formula: 'Formules',
      plant: 'Plantes',
    },
    productVariationType: {
      cardTitle: 'Variations',
      docSubtitle: 'Variations',
      groupByLabel: 'Variation',
      linkText: 'Variations',
      price: 'Prix HT',
      shortcode: 'Abréviation',
    },
    savour: {
      cardTitle: 'Saveurs',
      groupByLabel: 'Saveur',
    },
    sellAvailable: 'Indisponible à la vente',
    syndrom: {
      cardTitle: 'Syndromes',
      groupByLabel: 'Syndrome',
    },
  },
  productVariationTypes: {
    title: 'Variations',
  },
  radioButton: {
    addFormula: 'Ajouter à une formule existante',
    newFormula: 'Créer une nouvelle formule',
    placeholderNF: 'Nom de la formule',
  },
  renewOrder: 'Renouveler la commande',
  search: {
    none: 'Aucun résultat',
    search: 'Recherche',
  },
  sendVerifToUsePharmaco:
    "Afin de pouvoir réaliser des commandes, vous devez envoyer un justificatif de formation à l'adresse mail contact@yao-dao.com",
  shippingMethodPrices: {
    countryZone: 'Zone pays',
    docSubtitle: 'Liste des prix des méthodes de livraison',
    label: {
      en: 'Nom EN',
      es: 'Nom ES',
      fr: 'Nom FR',
      pt: 'Nom PT',
    },
    linkText: 'Prix des méthodes de livraison',
    maxWeight: 'Poids maximum',
    minWeight: 'Poids minimum',
    price: 'Prix',
    shippingMethod: 'Méthode de livraison',
  },
  shippingMethods: {
    docSubtitle: 'Liste des méthodes de livraison',
    label: {
      active: 'Actif',
      carrier: 'Transporteur',
      displayOrder: "Ordre d'affichage",
      name: {
        en: 'Nom EN',
        es: 'Nom ES',
        fr: 'Nom FR',
        pt: 'Nom PT',
      },
      pricesLength: 'Nombre de prix associés',
      productCode: 'Code produit',
    },
    linkText: 'Méthodes de livraison',
  },
  shop: {
    title: 'Boutique',
  },
  total: 'Total',
  validate: 'Valider',
  variations: {
    available: 'En stock',
    basePrice: 'Prix de base',
    name: 'Nom',
    price: 'Prix HT',
    productRef: 'Référence Produit',
    title: 'Variations',
    unavailable: 'En rupture de stock',
  },
  wholesalerSettings: {
    linkText: 'Réglages',
  },
};
