import {
  basicGetRequest,
  basicPostRequest,
  basicPutRequest,
  getBaseURL,
  runBasicFetch,
} from '../utils/api';

const BASE_URL = getBaseURL() + '/user';

export const updateUserTokenInfo = (waitUntilSubActive = false) => {
  return runBasicFetch(
    basicGetRequest(waitUntilSubActive ? BASE_URL + '/true' : BASE_URL),
    true
  );
};

export const getProfileInfo = () => {
  const url = `${BASE_URL}/profile`;
  return runBasicFetch(basicGetRequest(url), true);
};

export const editProfileInfo = (data) => {
  const url = `${BASE_URL}/profile`;
  return runBasicFetch(basicPutRequest(url, data), true);
};

export const changePassword = (oldPassword, newPassword) => {
  const url = `${BASE_URL}/password`;
  return runBasicFetch(
    basicPutRequest(url, { oldPassword, newPassword }),
    true
  );
};

export const sendEmailVerificationRequest = (email) => {
  const url = `${BASE_URL}/sendVerification/email`;
  return runBasicFetch(basicPostRequest(url, { email }));
};

export const verifyEmail = (email, token) => {
  const url = `${BASE_URL}/verify/email`;
  return runBasicFetch(basicPostRequest(url, { email, token }));
};

export const userRegister = (userType, data) => {
  if (!['patient', 'practitioner'].includes(userType)) {
    return false;
  }
  const url = `${BASE_URL}/register`;
  return runBasicFetch(
    basicPostRequest(url, {
      ...data,
      type: userType,
    })
  );
};

export const getOnBoarding = () => {
  const url = `${BASE_URL}/onboarding`;
  return runBasicFetch(basicGetRequest(url), true);
};

export const editOnBoarding = (data) => {
  const url = `${BASE_URL}/onboarding`;
  return runBasicFetch(basicPutRequest(url, data), true);
};

export const listAll = () => {
  const url = `${BASE_URL}/list`;
  return runBasicFetch(basicGetRequest(url), true);
};

export const adminEdit = (userId, updates) => {
  const url = `${BASE_URL}/list/edit/${userId}`;
  return runBasicFetch(basicPutRequest(url, updates), true);
};

export const adminAdd = (updates) => {
  const url = `${BASE_URL}`;
  return runBasicFetch(basicPostRequest(url, updates), true);
};
