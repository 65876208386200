export default {
  addUserTitle: 'Adicione um utilizador',
  dataTable: {
    columns: {
      createdAt: 'Inscrição',
      docStatus: 'Status do documento',
      docType: 'Tipo de documento',
      email: 'E-mail',
      GDPR: 'GDPR',
      lastLogin: 'Última conexão',
      partnerRevenueAmount: 'Quantidade do prêmio',
      partnerRevenuePlan: 'Plano de recompensa',
      role: 'Função',
      subStatus: 'Status da Subscrição',
      verified: 'Email verificado',
    },
    values: {
      empty: 'Nulo',
    },
  },
  editUserTitle: 'Modificar um utilizador',
  enum: {
    docStatus: {
      contact: 'Relançar',
      pending: 'Em espera',
      received: 'Recebido',
      verified: 'Verificado',
    },
    docType: {
      diploma: 'Diploma',
      trainingCertificate: 'Certificado de Formação',
    },
    partnerRevenuePlan: {
      costPerAcquisition: 'Custo por aquisição',
      revenueShare: 'Partilha de lucros',
    },
  },
  errors: {
    requiredField: 'Campo obrigatório',
  },
  notificationsManagement: {
    form: {
      metadata: {
        link: 'Link',
        text: 'Conteúdo de notificação',
      },
      sendTo: 'Enviar para',
      sendToOptions: {
        admin: 'Função de administrador',
        all: 'Todos os utilizadores',
        beta: 'Papel beta',
        partner: 'Função de parceiro',
        patient: 'Papel do paciente',
        pharmaco: 'Papel farmaco',
        practitioner: 'Função profissional',
      },
      type: 'Tipo de notificação',
    },
    title: 'Enviar notificações',
  },
  snackbars: {
    errorEdit: 'Ocorreu um erro durante a gravação',
    successAdd: 'Adicionado com sucesso',
    successEdit: 'Modificado com sucesso ',
  },
  usersManagement: {
    title: 'Gestão de utilizadores',
  },
};
