import { Retailer, IRetailerAttributes } from '../../core/entities/Retailer';
import { RetailerProvider } from '../../core/use_cases/RetailerProvider';
import { gql, ApolloClient } from '@apollo/client';

export const LIST_RETAILERS = gql`
  query {
    listRetailers {
      id
      name
      # Add here your attributes
    }
  }
`;

export type IRetailerAttributesData = IRetailerAttributes & {
  id: string;
};

export interface ListRetailersData {
  listRetailers: Array<IRetailerAttributesData>;
}

export class RetailerGraphQLProvider implements RetailerProvider {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  async findAll(): Promise<Retailer[]> {
    try {
      const result = await this.client?.query<ListRetailersData>({
        query: LIST_RETAILERS,
      });
      return result.data.listRetailers.map((item) => new Retailer(item));
    } catch (e) {
      console.log(e);
    }
  }
  save(retailer: Retailer, userId?: string): Promise<Retailer> {
    throw new Error('Method not implemented.');
  }
  editMany(entities: IRetailerAttributes[], userId?: string): Promise<Retailer[]> {
    throw new Error('Method not implemented.');
  }
}
