import {
  IOrderAttributes,
  IPriceAttributes,
  Order,
  Price,
  StatusColor,
  StatusJson,
} from './Order';

export type PaymentMethod = 'card' | 'check' | 'bankTransfer';

type PaymentStatus = 'FAILED' | 'DRAFT' | 'PENDING' | 'PAID' | 'CREDIT_OWED';

const getPaymentStatusColor = (status: PaymentStatus): StatusColor => {
  switch (status) {
    case 'PAID':
      return 'success';
    case 'CREDIT_OWED':
      return 'info';
    case 'PENDING':
      return 'warning';
    case 'FAILED':
      return 'error';
    case 'DRAFT':
    default:
      return 'default';
  }
};

export interface IPaymentAttributes {
  amount: string | IPriceAttributes | Price;
  paymentMethod: PaymentMethod;
  order: string | (IOrderAttributes & { _id?: string; id?: string }) | Order;
  status: PaymentStatus;
  createdAt: Date;
  validatedAt: Date;
  completedAt: Date;
  custom: any;
}

export class Payment {
  protected id: string;
  protected amount: Price;
  protected paymentMethod: PaymentMethod;
  protected order: Order;
  protected status: PaymentStatus;
  protected createdAt: Date;
  protected validatedAt: Date;
  protected completedAt: Date;
  protected custom: any;

  public constructor(
    obj: Partial<IPaymentAttributes> & { _id?: string; id?: string }
  ) {
    this.id = obj?.id || obj?._id;
    if (obj?.amount) {
      if (typeof obj?.amount === 'string') {
        this.amount = new Price({ id: obj?.amount });
      } else if (obj?.amount instanceof Price) {
        this.amount = obj?.amount;
      } else {
        this.amount = new Price(obj?.amount);
      }
    }
    this.paymentMethod = obj?.paymentMethod;
    if (obj?.order) {
      if (typeof obj?.order === 'string') {
        this.order = new Order({ id: obj?.order });
      } else if (obj?.order instanceof Order) {
        this.order = obj?.order;
      } else {
        this.order = new Order(obj?.order);
      }
    }
    this.status = obj?.status;
    this.createdAt = obj?.createdAt;
    this.validatedAt = obj?.validatedAt;
    this.completedAt = obj?.completedAt;
    this.custom = obj?.custom;
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): Payment {
    this.id = id;
    return this;
  }

  public getAmount(): Price {
    return this.amount;
  }

  public setAmount(amount: Price): Payment {
    this.amount = amount;
    return this;
  }

  public getPaymentMethod(): PaymentMethod {
    return this.paymentMethod;
  }

  public setPaymentMethod(paymentMethod: PaymentMethod): Payment {
    this.paymentMethod = paymentMethod;
    return this;
  }

  public getOrder(): Order {
    return this.order;
  }

  public setOrder(order: Order): Payment {
    this.order = order;
    return this;
  }

  public getStatus(): PaymentStatus {
    return this.status;
  }

  public getPaymentStatusJSON(): StatusJson<PaymentStatus> {
    return {
      label: this.status,
      color: getPaymentStatusColor(this.status),
    };
  }

  public setStatus(status: PaymentStatus): Payment {
    this.status = status;
    return this;
  }

  public getCreatedAt(): Date {
    return this.createdAt;
  }

  public setCreatedAt(createdAt: Date): Payment {
    this.createdAt = createdAt;
    return this;
  }

  public getValidatedAt(): Date {
    return this.validatedAt;
  }

  public setValidatedAt(validatedAt: Date): Payment {
    this.validatedAt = validatedAt;
    return this;
  }

  public getCompletedAt(): Date {
    return this.completedAt;
  }

  public setCompletedAt(completedAt: Date): Payment {
    this.completedAt = completedAt;
    return this;
  }

  public getCustom(): any {
    return this.custom;
  }

  public setCustom(custom: any): Payment {
    this.custom = custom;
    return this;
  }

  public setCheckInfo(checkInfoKey: string, checkInfoValue: any): Payment {
    this.custom = {
      ...(this.custom ?? {}),
      check: {
        ...(this.custom?.check ?? {}),
        [checkInfoKey]: checkInfoValue,
      },
    };
    return this;
  }

  public setBankTransferInfo(
    bankTransferInfoKey: string,
    bankTransferInfoValue: any
  ): Payment {
    this.custom = {
      ...(this.custom ?? {}),
      bankTransfer: {
        ...(this.custom?.bankTransfer ?? {}),
        [bankTransferInfoKey]: bankTransferInfoValue,
      },
    };
    return this;
  }

  toJSON(): any {
    return {
      _id: this.getId(),
      id: this.getId(),
      amount: this.getAmount(),
      paymentMethod: this.getPaymentMethod(),
      order: this.getOrder()?.toJSON(),
      status: this.getStatus(),
      createdAt: this.getCreatedAt(),
      validatedAt: this.getValidatedAt(),
      completedAt: this.getCompletedAt(),
      custom: this.getCustom(),
    };
  }

  toInput(): any {
    return {
      id: this.getId(),
      amount: this.getAmount()?.toInput(true, false, true),
      paymentMethod: this.getPaymentMethod(),
      order: this.getOrder()?.getId(),
      status: this.getStatus(),
      createdAt: this.getCreatedAt(),
      validatedAt: this.getValidatedAt(),
      completedAt: this.getCompletedAt(),
      custom: this.getCustom(),
    };
  }

  public toDatatableRow(): any {
    const customerPhone = [
      this.getOrder()?.getCustomer()?.getMobilePhone(),
      this.getOrder()?.getInvoicingAddress()?.getMobilePhone(),
      this.getOrder()?.getShippingAddress()?.getMobilePhone(),
    ]
      ?.filter((phone) => phone)
      ?.filter((value, index, self) => self.indexOf(value) === index)
      ?.join(', ');
    return {
      _id: this.getId(),
      amount: this.getAmount()?.toJSON(),
      paymentMethod: this.getPaymentMethod(),
      order: this.getOrder()?.toJSON(),
      status: this.getPaymentStatusJSON(),
      completedAt: this.getCompletedAt(),
      customer: this.getOrder()?.getCustomer()?.getFullName(),
      customerPhone,
    };
  }
}
