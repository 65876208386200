import { UserProvider } from './UserProvider';

export class Logout {
  private userProvider: UserProvider;

  public constructor(userProvider: UserProvider) {
    this.userProvider = userProvider;
  }

  public execute(): Promise<boolean> {
    return this.userProvider.logout();
  }
}
