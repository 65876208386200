import * as React from 'react';
import { AboutUser } from './AboutUser';
import { User } from '../../../core/entities/User';
import { useUsersGateway } from '../../../entrypoints/react/useUsersGateway';
import { AuthContext } from '../../contexts';
import { useSnackbar } from '@kiway/shared/utils/snackbar';
import { useTranslation } from '@kiway/shared/utils/translation';

export function AboutUserController() {
  const [user, setUser] = React.useState([new User({})]);
  const [initialUser, setInitialUser] = React.useState<User>();
  const { checkToken, editProfile } = useUsersGateway();
  const { updateUserInfo } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const { addErrorSnackbar, addSuccessSnackbar } = useSnackbar();

  // useEffect(() => {
  //   if (moment(user.birthDate).isValid()) {
  //     const isOfAge = moment().diff(moment(user.birthDate), 'years') >= 18
  //     if (isOfAge || certifyLegalRepresentative) {
  //       setBirthDateError('')
  //     } else {
  //       setBirthDateError(t('user:register.error.birthDate.ofAge' + (['admin', 'beta', 'partner', 'practitioner'].includes(userInfo.role) ? 'Pract' : 'Patient')))
  //     }
  //     setOfAge(isOfAge)
  //   }
  //   if (user.birthDate === null) {
  //     setBirthDateError('')
  //   }
  // }, [user.birthDate, certifyLegalRepresentative])

  // const isConfirmButtonDisabled = () => {
  //   if (user.firstName !== '' && user.lastName !== '' && user.mobilePhone !== '' && user.birthDate && moment(user.birthDate).isValid() && (
  //     ofAge || (!['admin', 'beta', 'partner', 'practitioner'].includes(userInfo.role) ? certifyLegalRepresentative : false)
  //   )) {
  //     return false
  //   } else {
  //     return true
  //   }
  // }

  React.useEffect(() => {
    checkToken().then(({ user }) => {
      setUser([user]);
      setLocalImageUrl(user?.getImageUrl() || '');
      setInitialUser(new User(user.toJSON()));
    });
    return () => {
      setUser([new User({})]);
      setLocalImageUrl('');
      setInitialUser(new User({}));
    };
  }, []);

  // React.useEffect(() => {
  //   if (
  //     user[0]?.getId() &&
  //     initialUser?.getId() &&
  //     !user[0]?.equals(initialUser)
  //   ) {
  //     editProfile(user[0]);
  //   }
  // }, [user]);

  const handleSave = () => {
    if (!user[0]?.equals(initialUser)) {
      editProfile(user[0]).then((newUser) => {
        if (newUser?.getId()) {
          updateUserInfo(newUser.toJSON(), false);
          addSuccessSnackbar(t('authentication:editProfile.saveSuccess'));
          setInitialUser(newUser);
        } else {
          addErrorSnackbar(t('authentication:editProfile.saveError'));
        }
      });
    }
  };

  const [changeImageUrl, setChangeImageUrl] = React.useState(false);
  const [localImageUrl, setLocalImageUrl] = React.useState('');
  const handleSaveImageUrl = () => {
    setChangeImageUrl(false);
    if (localImageUrl !== user[0]?.getImageUrl()) {
      setUser([user[0]?.setImageUrl(localImageUrl)]);
      handleSave();
    }
  };
  const deleteImageUrl = () => {
    setUser([user[0]?.setImageUrl(null)]);
    handleSave();
  };

  const [errors, setErrors] = React.useState<any>({
    imageUrl: '',
  });

  const handleChange = (name: string) => (event) => {
    let updated;
    const imageUrlRegex = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gm;
    switch (name) {
      case 'nif':
        updated = user[0]?.setCustom({
          ...user[0]?.getCustom(),
          nif: event?.target?.value ?? '',
        });
        break;
      case 'firstName':
        updated = user[0]?.setFirstName(event?.target?.value);
        break;
      case 'lastName':
        updated = user[0]?.setLastName(event?.target?.value);
        break;
      case 'profession':
        updated = user[0]?.setProfession(event?.target?.value);
        break;
      case 'gender':
        updated = user[0]?.setGender(event?.target?.value);
        handleSave();
        break;
      case 'imageUrl':
        setLocalImageUrl(event?.target?.value);
        if (
          event?.target?.value?.length < 5 ||
          event?.target?.value?.match(imageUrlRegex)
        ) {
          setErrors((prev) => ({ ...prev, imageUrl: '' }));
        } else {
          setErrors((prev) => ({ ...prev, imageUrl: 'Invalid url' }));
        }
        break;
      case 'birthDate':
        if (event && event.isValid()) {
          updated = user[0]?.setBirthDate(event.format('yyyy-MM-DD'));
        }
        break;
      case 'mobilePhone':
        updated = user[0]?.setMobilePhone(event);
        break;
    }
    if (updated) {
      setUser([updated]);
    }
  };

  const handleClickChangeCancelPicture = () =>
    setChangeImageUrl((prev) => !prev);

  return (
    <AboutUser
      avatarSrc={user[0]?.getImageUrl() || user[0]?.getDefaultImageUrl()}
      changeImageUrl={changeImageUrl}
      deleteImageUrl={deleteImageUrl}
      errors={errors}
      handleChange={handleChange}
      handleClickChangeCancelPicture={handleClickChangeCancelPicture}
      handleSave={handleSave}
      handleSaveImageUrl={handleSaveImageUrl}
      localImageUrl={localImageUrl}
      saveChangePictureDisabled={
        errors?.imageUrl !== '' || localImageUrl === user[0]?.getImageUrl()
      }
      user={user[0]}
    />
  );
}
