import * as React from 'react';
import { useTranslation } from '@kiway/shared/utils/translation';
import {
  KiwayLanguagesType,
  TranslatableAttribute,
} from '@kiway/shared/react-types';
import { Chip, Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useStyles } from './styles';

export interface ProductDetailsProps {
  handleDelete?: (id: string) => any;
  id: string;
  label: string | TranslatableAttribute;
  loading?: boolean;
}

export function ProductDetailsMetadataChipLoader() {
  return (
    <Skeleton animation={'pulse'} width={'50%'}>
      <Chip />
    </Skeleton>
  );
}

const CHIP_MAX_WIDTH = 200;
const CHIP_ICON_WIDTH = 30;

const EllipsisText = (props: { children: string }) => {
  const { children } = props;

  return (
    <Tooltip title={children}>
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: CHIP_MAX_WIDTH - CHIP_ICON_WIDTH,
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export function ProductDetailsMetadataChip({
  handleDelete,
  id,
  label,
  loading,
}: React.PropsWithChildren<ProductDetailsProps>) {
  const classes = useStyles();
  const { addTranslationDynamically, t } = useTranslation();

  if (label && typeof label !== 'string') {
    Object.entries(label).map(([lng, translation]) => {
      addTranslationDynamically(
        lng as KiwayLanguagesType,
        'pharmaco',
        {
          metadataChip: {
            [`${id}`]: translation,
          },
        },
        true
      );
    });
  }

  return loading ? (
    <ProductDetailsMetadataChipLoader />
  ) : (
    <Chip
      className={classes.metadataChip}
      label={
        <EllipsisText>
          {typeof label === 'string' ? label : t(`pharmaco:metadataChip.${id}`)}
        </EllipsisText>
      }
      onDelete={handleDelete ? () => handleDelete(id) : undefined}
    />
  );
}
