/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import {
  Alert,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { OrderStepperController } from '../OrderStepper';
import { Order } from '@kiway/ecommerce-react-compatible';
import { useTranslation } from '@kiway/shared/utils/translation';
import { PharmacoOrderFormula } from '../../../core/entities/PharmacoOrder';
import moment from 'moment';
import { DisplayAddress } from '@kiway/shared/ui';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';

export interface OrderValidationProps {
  order: Order;
  formulas: PharmacoOrderFormula[];
  handlePayNow: () => void;
}

export function OrderValidation(props: OrderValidationProps) {
  const { currentLanguage, t } = useTranslation();

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          {t(`pharmaco:prescription.order.label`)} n°
          {props.order?.getOrderNumber()}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <OrderStepperController
          currentStep="resume"
          orderId={props.order?.getId()}
        />
      </Grid>
      <Grid item xs={12} md={10} lg={8}>
        <Grid
          container
          justifyContent="center"
          spacing={3}
          sx={{ padding: 2, borderRadius: '15px' }}
        >
          <Grid item xs={12} textAlign="center">
            <Typography variant="h4" sx={{ textTransform: 'uppercase' }}>
              {t(`pharmaco:order.aknowledgement`)}
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Alert severity="info">{t(`pharmaco:order.resumeMessage`)}</Alert>
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Button
              color={'minoritary'}
              variant={'contained'}
              onClick={props.handlePayNow}
            >
              {t(`pharmaco:order.payNowButton`)}
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            textAlign="center"
            sx={{
              margin: 3,
              padding: '12px !important',
              backgroundColor: 'white',
              borderRadius: '5px',
            }}
          >
            <Typography
              sx={{
                textDecoration: 'underline',
                mb: 1,
                fontWeight: 'bold',
                color: 'primary.main',
              }}
            >
              {t(`pharmaco:order.commandDetails.commandDetails`)}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <b> {t(`pharmaco:order.commandDetails.command`)} :</b>
                {` ${props.order?.getOrderNumber()}`}
              </Grid>
              <Grid item xs={6}>
                <b>{t(`pharmaco:order.commandDetails.date`)} :</b>
                {` ${moment(props.order?.getOpenedAt()).format(
                  t('dates:format.date')
                )}`}
              </Grid>
              <Grid item xs={12}>
                <Divider variant="middle" />
              </Grid>
              <Grid item xs={12}>
                {props.formulas?.map((formula) => (
                  <Grid container key={formula.getGroupBy()}>
                    <Grid item xs={4} textAlign="start" sx={{ p: '6px 16px' }}>
                      <Typography>{formula.getCustom().name}</Typography>
                      <Typography color="text.secondary" fontSize={14}>
                        {
                          formula
                            .getIngredients()[0]
                            .getLineItem()
                            .getProductVariation()
                            .getProductVariationType()
                            .getName()?.[
                            currentLanguage?.code ?? getFallbackLanguage()
                          ]
                        }
                        {formula.needToAddPod()
                          ? ` - ${t('pharmaco:prescription.withPod')}`
                          : ` - ${t('pharmaco:prescription.withoutPod')}`}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        sx={{ fontStyle: 'italic' }}
                        fontSize={12}
                      >
                        {formula.getPosologyDescription(t)}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                {t(`pharmaco:order.commandDetails.product`)}
                              </TableCell>
                              <TableCell align="right">
                                {t(`pharmaco:order.commandDetails.quantity`)}
                                &nbsp;(g)
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {formula.getIngredients().map((item) => (
                              <TableRow
                                key={item.getLineItem().getId()}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {`${
                                    item.getLineItem().getProduct().getCustom()
                                      .pinYinName
                                  }`}{' '}
                                  {formula.getPlantPowderType() === 'plant' && (
                                    <i>
                                      {`${
                                        item.getLineItem().getCustom()
                                          ?.isSeparated
                                          ? `(${t(
                                              'pharmaco:formula.separate'
                                            )})`
                                          : ''
                                      }`}
                                    </i>
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {`${formula
                                    .getRoundedItemTotalWeight(
                                      item.getLineItem().getId()
                                    )
                                    ?.toFixed(1)} g`}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Divider variant="middle" />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ textDecoration: 'underline' }}>
                  {t(`pharmaco:order.commandDetails.sentTo`)}
                </Typography>
                <DisplayAddress address={props.order?.getShippingAddress()} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
