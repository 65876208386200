import * as React from 'react';
import { RefreshToken } from '../../../core/use_cases/UserProvider';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { useTranslation } from '@kiway/shared/utils/translation';

export interface ListRefreshTokensProps {
  deleteAllTokens: () => void;
  deleteToken: (tokenId: string) => void;
  tokens?: RefreshToken[];
}

export function ListRefreshTokens(props: ListRefreshTokensProps) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>{t('authentication:editProfile.sessions.deviceName')}</b>
              </TableCell>
              <TableCell>
                <b>{t('authentication:editProfile.sessions.deviceType')}</b>
              </TableCell>
              <TableCell>
                <b>{t('authentication:editProfile.sessions.ip')}</b>
              </TableCell>
              <TableCell>
                <b>{t('authentication:editProfile.sessions.lastAccessed')}</b>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tokens?.map((token: RefreshToken) => (
              <TableRow key={token?.id}>
                <TableCell>{token?.deviceName}</TableCell>
                <TableCell>
                  {t(
                    `authentication:editProfile.sessions.deviceTypes.${token?.deviceType}`
                  )}
                </TableCell>
                <TableCell>{token?.ipAddress}</TableCell>
                <Tooltip
                  title={moment
                    .unix(token?.lastAccessed / 1000)
                    ?.format(t('dates:format.smsSentDate'))}
                >
                  <TableCell>
                    {moment.unix(token?.lastAccessed / 1000)?.fromNow()}
                  </TableCell>
                </Tooltip>
                <TableCell>
                  <IconButton onClick={() => props.deleteToken(token?.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent={'flex-end'}>
          <Grid item>
            <Button
              startIcon={<DeleteIcon />}
              color="inherit"
              variant="contained"
              onClick={props.deleteAllTokens}
            >
              {t('authentication:editProfile.sessions.deleteAll')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
