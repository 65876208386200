import React, { useContext, useEffect, useReducer, createContext } from 'react';
import { getPractitionerChiefComplaints } from '../../services/chiefComplaint/chiefComplaintService';
import { getPractitionerConsultationPlaces } from '../../services/consultationPlace/consultationPlaceService';
import { useApolloClient } from '@apollo/client';
import { GET_INVOICING_SETTINGS } from '../../graphql/settings';
import { AuthContext } from '@kiway/shared/features/authentication-react';

function initGlobalState() {
  return {
    consultationPlaces: [],
    chiefComplaints: [],
    invoicingData: [],
    needUpdate: true,
  };
}

function globalReducer(state, action) {
  switch (action.type) {
    case 'SET_CC_CP':
      return {
        ...state,
        consultationPlaces: action.payload?.consultationPlaces || [],
        chiefComplaints: action.payload?.chiefComplaints || [],
        needUpdate: false,
      };
    case 'SET_NEED_UPDATE':
      return {
        ...state,
        needUpdate: true,
      };
    case 'SET_INVOICING':
      return {
        ...state,
        invoicingData: action.payload,
      };
    default:
      throw new Error('Unhandled action type');
  }
}

/**
 * Create and initialize a Context object to maintain
 *  booking states accross components tree
 */
const GlobalContext = createContext(initGlobalState());

function GlobalContextProvider(props) {
  const { children } = props;

  /**
   * Create and initialize our global states management system
   */
  const initialGlobalState = useContext(GlobalContext);
  const [state, dispatch] = useReducer(globalReducer, initialGlobalState);

  /**
   * Check if user is authenticated to avoid unnecessary API calls
   */
  const {
    isAuthenticated,
    authState: { userInfo },
  } = useContext(AuthContext);

  const [firstLoad, setFirstLoad] = React.useState(false);

  const updateCCAndCPIfAuthenticated = async () => {
    const authenticated = await isAuthenticated();
    if (!authenticated) {
      return;
    }
    if (
      ['practitioner', 'pharmaco', 'beta', 'partner', 'admin'].includes(
        userInfo?.mainRole
      )
    ) {
      Promise.all([
        getPractitionerChiefComplaints(),
        getPractitionerConsultationPlaces(),
      ]).then(([chiefComplaints, consultationPlaces]) => {
        dispatch({
          type: 'SET_CC_CP',
          payload: {
            consultationPlaces,
            chiefComplaints,
          },
        });
      });
    }
  };

  /**
   * Load practitioner's CC and CP
   */
  useEffect(() => {
    if (state.needUpdate) {
      updateCCAndCPIfAuthenticated();
    }
  }, [state.needUpdate]);

  useEffect(() => {
    if (userInfo?.mainRole && !firstLoad) {
      updateCCAndCPIfAuthenticated();
      setFirstLoad(true);
    }
  }, [userInfo]);

  /**
   * On ajoute les paramètres de facturation dans le context global
   * afin de pouvoir bloquer la facturation s'il manque certains paramètres
   */
  const client = useApolloClient();
  const getInvoicingData = async () => {
    return await client.query({ query: GET_INVOICING_SETTINGS });
  };

  const updateInvoicingData = async () => {
    const authenticated = await isAuthenticated();
    if (!authenticated) {
      return;
    }
    if (
      ['practitioner', 'pharmaco', 'beta', 'partner', 'admin'].includes(
        userInfo?.mainRole
      )
    ) {
      getInvoicingData().then(({ data }) => {
        const { invoicingSetting } = data || {};
        if (invoicingSetting) {
          dispatch({ type: 'SET_INVOICING', payload: invoicingSetting });
        }
      });
    }
  };

  useEffect(() => {
    // Au premier chargement, on exécute la query graphql et on met à jour le state
    updateInvoicingData();
    return () => {
      dispatch({ type: 'SET_INVOICING', payload: {} });
    };
  }, [userInfo]);

  const canInvoiceSession = () => {
    return (
      state.invoicingData?.naf &&
      state.invoicingData?.siret &&
      state.invoicingData?.invoiceNextDigit
    );
  };

  return (
    <GlobalContext.Provider value={{ state, dispatch, canInvoiceSession }}>
      {children}
    </GlobalContext.Provider>
  );
}

export { GlobalContextProvider, GlobalContext };
