import Divider from '@material-ui/core/Divider';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState, useContext } from 'react';
import { useChiefComplaintPicker } from '../../shared/Forms/ChiefComplaintPicker';
import { useConsultationPlacePicker } from '../../shared/Forms/ConsultationPlacePicker';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import { useSlotPicker } from '../../shared/Forms/SlotPicker';
// import { BookingContext } from '../../AppRoutes'
import { BookingContext } from '../../../BookingContext';
import { useTranslation } from '@kiway/shared/utils/translation';

function Booking(props) {
  const { dispatch } = useContext(BookingContext);
  const { practitionerId } = props;
  const { t } = useTranslation();

  const [
    consultationPlace,
    ConsultationPlacePicker,
  ] = useConsultationPlacePicker(practitionerId, true);

  const [chiefComplaint, ChiefComplaintPicker] = useChiefComplaintPicker(
    consultationPlace ? consultationPlace._id : null,
    practitionerId,
    true
  );

  const [slots, SlotPicker] = useSlotPicker(
    chiefComplaint ? chiefComplaint._id : null,
    consultationPlace ? consultationPlace._id : null,
    practitionerId
  );
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (consultationPlace) {
      const { street_number, route } = consultationPlace.address;
      const address = `${street_number} ${route}`;
      dispatch({
        type: 'SELECT_CONSULTATION_PLACE',
        payload: {
          id: consultationPlace._id,
          name: consultationPlace.name,
          address,
          zipCode: consultationPlace.address.postal_code,
          city: consultationPlace.address.locality,
          complement: consultationPlace.complement,
        },
      });
      setActiveStep(1);
    } else {
      setActiveStep(0);
    }
  }, [consultationPlace]);

  useEffect(() => {
    if (chiefComplaint) {
      dispatch({
        type: 'SELECT_CHIEF_COMPLAINT',
        payload: {
          id: chiefComplaint._id,
          label: chiefComplaint.name,
          duration: chiefComplaint.duration,
          price: chiefComplaint.price,
          specialty: chiefComplaint.specialty.name,
        },
      });

      setActiveStep(2);
    } else if (consultationPlace) {
      setActiveStep(1);
    }
  }, [chiefComplaint]);

  return (
    <div style={{ marginTop: '16px' }}>
      <Typography variant="h6">{t('onlineBooking:booking.title')}</Typography>
      <Divider style={{ backgroundColor: process.env.NX_COLOR_PRIMARY_MAIN }} />
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step expanded key={0}>
          <StepLabel>{t('onlineBooking:booking.workplace')}</StepLabel>
          <StepContent onFocus={() => setActiveStep(0)}>
            {ConsultationPlacePicker}
          </StepContent>
        </Step>
        <Step expanded key={1}>
          <StepLabel>{t('onlineBooking:booking.chiefComplaint')}</StepLabel>
          <StepContent onFocus={() => setActiveStep(1)}>
            {consultationPlace && ChiefComplaintPicker}
            {chiefComplaint && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <EuroSymbolIcon fontSize={'small'} />
                  <span>{chiefComplaint.price}</span>
                </span>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: '12px',
                  }}
                >
                  <AccessTimeIcon fontSize={'small'} />
                  <span>{chiefComplaint.duration}</span>
                </span>
              </div>
            )}
          </StepContent>
        </Step>
        <Step expanded key={2}>
          <StepLabel>{t('onlineBooking:booking.slot')}</StepLabel>
          <StepContent onFocus={() => setActiveStep(2)}>
            {practitionerId &&
              consultationPlace &&
              chiefComplaint &&
              SlotPicker}
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
}

export default Booking;
