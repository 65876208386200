export default {
  agenda: {
    bookedOnline: 'Reservado online',
  },
  booking: {
    chiefComplaint: 'Motivo de consulta',
    more: 'Ver mais',
    next: 'Próximo compromisso',
    slot: 'Escolha um horário',
    title: 'Marcação online',
    workplace: 'Localização da consulta',
  },
  info: {
    reserved:
      'A sua consulta foi reservada temporariamente, confirme nos próximos 10 minutos, ou não estará mais disponível.',
  },
  snackbars: {
    error: 'Ocorreu um erro. Por favor tente novamente mais tarde.',
    success: 'Sua consulta foi registrada.',
  },
  stepper: {
    confirmation: {
      button: 'Confirmar',
      comment: 'Adicione um comentário (opcional)',
      dateFormat: 'DD-MM-YYYY à HH:mm',
      text: 'Nomeação em',
      title: 'Confirmação',
    },
    identification: {
      login: 'Eu já tenho uma conta',
      signup: 'Novo na {{app}}?',
      title: 'Identificação',
    },
  },
};
