import { ShippingMethod, IShippingMethodAttributes } from '../entities/ShippingMethod';
import { ShippingMethodProvider } from './ShippingMethodProvider';

export class EditManyShippingMethods {
  private shippingMethodProvider: ShippingMethodProvider;

  public constructor(shippingMethodProvider: ShippingMethodProvider) {
    this.shippingMethodProvider = shippingMethodProvider;
  }

  public execute(
    shippingMethod: Array<IShippingMethodAttributes>,
    userId?: string
  ): Promise<Array<ShippingMethod>> {
    return this.shippingMethodProvider.editMany(shippingMethod, userId);
  }
}