/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react';
import { Card, Grid, Divider, Typography, Hidden } from '@mui/material';
import { useStyles } from '../checkout.style';
import { useTranslation } from '@kiway/shared/utils/translation';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';
import { Order } from '@kiway/ecommerce-react';
import { PharmacoOrder } from '../../../../core/entities/PharmacoOrder';
import { DisplayAddress } from '@kiway/shared/ui';

export interface SummaryProps {
  order: Order;
}

export function Summary(props: SummaryProps) {
  const classes = useStyles();
  const { t, currentLanguage } = useTranslation();

  const pharmacoOrder = new PharmacoOrder({
    order: props.order,
    saveCallback: () => {
      /**/
    },
  });

  return (
    <Card className={classes.summaryCard}>
      <Grid container justifyContent="center" spacing={2} sx={{ p: 2 }}>
        {props.order?.getGroups()?.map((group) => {
          const formula = pharmacoOrder
            ?.getFormulas()
            .find((formula) => formula.getGroupBy() === group);
          const items = props.order.getLineItemsByGroup(group);
          return (
            <Grid item xs={12} key={group}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={9}>
                      <Grid container columnSpacing={2}>
                        <Grid item>
                          <Typography>
                            <b>{props.order?.getCustom()?.[group]?.name}</b>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            <i>{`(${
                              items?.[0]
                                ?.getProductVariation()
                                ?.getProductVariationType()
                                ?.getName()?.[currentLanguage?.code]
                            } - ${
                              formula.needToAddPod()
                                ? `${t('pharmaco:prescription.withPod')}`
                                : `${t('pharmaco:prescription.withoutPod')}`
                            })`}</i>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography
                        color="text.secondary"
                        sx={{ fontStyle: 'italic' }}
                        fontSize={14}
                      >
                        {formula.getPosologyDescription(t)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} textAlign="end">
                      <Typography>
                        {props.order?.getTotalPrice(group)?.toString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {items?.map((lineItem) => (
                      <Grid item xs={12} key={lineItem.getId()}>
                        <Grid container spacing={2}>
                          <Grid item xs={1}></Grid>
                          <Grid item>
                            <Typography>
                              {`${
                                lineItem.getProduct()?.getName()?.[
                                  currentLanguage?.code ?? getFallbackLanguage()
                                ]
                              } - ${formula
                                .getRoundedItemTotalWeight(lineItem.getId())
                                ?.toFixed(1)} g`}
                            </Typography>
                          </Grid>
                          {formula.getPlantPowderType() === 'plant' &&
                            lineItem.getCustom()?.isSeparated && (
                              <Grid item>
                                <Typography>
                                  <i>({t('pharmaco:formula.separate')})</i>
                                </Typography>
                              </Grid>
                            )}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8}>
              <Typography>
                <b>{t('pharmaco:orderDetails.cartPrice')}</b>
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="end">
              <Typography>
                {props.order?.getTotalPrice(null, true)?.toString()}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                <b>{t('pharmaco:orderDetails.shipment.transporter.price')}</b>
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="end">
              <Typography>
                {props.order?.getShippingPrice()?.toString()}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                {
                  props.order?.getShippingMethod()?.getName()[
                    currentLanguage?.code
                  ]
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent={'space-between'}
          >
            <Grid item xs={12} sm={5.5}>
              <Typography>
                <b>{t('pharmaco:orderDetails.order.deliveryAddress')}</b>
              </Typography>
              <DisplayAddress address={props.order?.getShippingAddress()} />
            </Grid>
            <Hidden xsDown>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item xs={12}>
                <Divider variant="fullWidth" />
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={5.5}>
              <Typography>
                <b>{t('pharmaco:orderDetails.order.paymentAddress')}</b>
              </Typography>
              {props.order?.getCustom()?.sameInvoicingAndShippingAddress ? (
                <Typography>
                  {t('pharmaco:orderDetails.order.sameAddress')}
                </Typography>
              ) : (
                <DisplayAddress address={props.order?.getInvoicingAddress()} />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8}>
              <Typography className={classes.totalPriceLine}>
                <b> {t('pharmaco:total')}</b>
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="end">
              <Typography className={classes.totalPriceLine}>
                <b>{props.order?.getTotalPrice()?.toString()}</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
