/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Document, Model, Schema } from 'mongoose';
import {
  IOrderAttributes,
  Order,
  ILineItemAttributes,
} from '../../core/entities/Order';
import {
  DEFAULT_LAST_ORDERS_NB,
  OrderProvider,
  UserLastOrdersType,
} from '../../core/use_cases/OrderProvider';
import {
  EntityProvider,
  DatabaseProviderFactory,
} from '@kiway/shared/react-types';
import { EventEmitter } from '@kiway/event-emitter';
import {
  AddressSchema,
  IAddressAttributes,
} from '@kiway/shared/features/authentication-react-compatible';
import { padLeft } from '@kiway/shared/utils/string';
import { CustomerInput } from '../../core/use_cases/CreateOrder';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Country } from '@kiway/ecommerce-react-compatible';
import * as mongoose from 'mongoose';
import { getFallbackLanguage } from '@kiway/shared/utils-react-compatible';

/* Declare methods */
export interface IOrder extends IOrderAttributes, Document {}
export interface ILineItem extends ILineItemAttributes, Document {}

const orderGlobalPopulate = [
  {
    path: 'lineItems.product',
    populate: {
      path: 'variations',
      populate: [
        {
          path: 'productVariationType',
          populate: [
            {
              path: 'price',
              populate: 'currency',
            },
            {
              path: 'variationAttributesValue',
              populate: {
                path: 'variationAttribute',
              },
            },
          ],
        },
        {
          path: 'price',
          populate: 'currency',
        },
      ],
    },
  },
  {
    path: 'lineItems.productVariation',
    populate: {
      path: 'productVariationType',
      populate: [
        {
          path: 'price',
          populate: 'currency',
        },
        {
          path: 'variationAttributesValue',
          populate: {
            path: 'variationAttribute',
          },
        },
      ],
    },
  },
  'customer',
  'prescriber',
  {
    path: 'shippingMethod',
    populate: [
      {
        path: 'carrier',
      },
      {
        path: 'prices',
        populate: [{ path: 'price', populate: 'currency' }],
      },
      { path: 'countryZone', populate: ['countries'] },
    ],
  },
  { path: 'payment', populate: ['order', 'amount.currency'] },
  'preparedBy',
  'sentBy',
  'invoice',
  // {
  //   path: 'lineItems.productVariation',
  //   populate: ['price.currency', 'product', 'productVariationType'],
  // },
];

const getLabelSignedUrl = (order: any): string => {
  if (!order.shippingLabelStoredFile) {
    return null;
  }
  return `https://${process.env.NX_API_URL}/storage/display/${order.shippingLabelStoredFile.id}`;
};

export const PriceSchema: Schema = new Schema(
  {
    currency: { type: Schema.Types.ObjectId, ref: 'Currency' }, // group items e.g pharmaco formula
    centAmount: { type: Number },
  },
  { timestamps: true }
);

/* Declare statics */
export interface IOrderModel
  extends Model<IOrder>,
    EntityProvider<IOrder, IOrderAttributes> {
  findOneById(orderId: string): Promise<Order>;
}

export class OrderDatabaseProvider
  extends DatabaseProviderFactory<Order, IOrder, IOrderModel, IOrderAttributes>(
    orderGlobalPopulate
  )
  implements OrderProvider {
  public static getInstance(): OrderDatabaseProvider {
    if (!OrderDatabaseProvider.instance) {
      new OrderDatabaseProvider();
      const eventEmitter = EventEmitter.getInstance();
      eventEmitter?.on('OPEN_ORDER', ({ orderId }) =>
        console.log(`Order with id ${orderId} was opened right now`)
      );
    }
    return OrderDatabaseProvider.instance;
  }

  private constructor() {
    const LineItemSchema: Schema = new Schema(
      {
        groupBy: { type: String }, // group items e.g pharmaco formula
        product: {
          type: Schema.Types.ObjectId,
          refPath: 'lineItems.modelProduct',
        },
        modelProduct: { type: String },
        productVariation: {
          type: Schema.Types.ObjectId,
          refPath: 'lineItems.modelProductVariation',
        },
        modelProductVariation: { type: String },
        quantity: { type: Number },
        custom: {
          type: Schema.Types.Mixed,
        },
      },
      { timestamps: true }
    );
    const OrderSchema: Schema<IOrder> = new Schema(
      {
        /* Order schema definition here : see mongoose lib */
        openedAt: { type: Date },
        paidAt: { type: Date },
        cancelledAt: { type: Date },
        startPreparationAt: { type: Date },
        endPreparationAt: { type: Date },
        startShipmentAt: { type: Date },
        endShipmentAt: { type: Date },
        orderNumber: { type: String },
        lineItems: [LineItemSchema],
        invoicingAddress: AddressSchema,
        shippingAddress: AddressSchema,
        shippingPrice: PriceSchema,
        shippingLabelStoredFile: { type: Schema.Types.Mixed },
        shippingTrackingNumber: { type: String },
        orderStatus: { type: String },
        paymentStatus: { type: String },
        packingStatus: { type: String },
        shipmentStatus: { type: String },
        customer: {
          type: Schema.Types.ObjectId,
          ref: 'Customer',
        },
        prescriber: {
          type: Schema.Types.ObjectId,
          ref: 'Customer',
        },
        custom: {
          type: Schema.Types.Mixed,
        },
        packingWeight: {
          type: Number,
        },
        shippingMethod: {
          type: Schema.Types.ObjectId,
          ref: 'ShippingMethod',
        },
        payment: {
          type: Schema.Types.ObjectId,
          ref: 'Payment2',
        },
        invoice: {
          type: Schema.Types.ObjectId,
          ref: 'NewInvoice',
        },
        preparedBy: {
          type: Schema.Types.ObjectId,
          ref: 'NewUser',
        },
        sentBy: {
          type: Schema.Types.ObjectId,
          ref: 'NewUser',
        },
        freezeOrder: {
          type: Schema.Types.Mixed,
        },
        deleted: { type: Boolean },
      },
      { timestamps: true }
    );
    const transformOrderWithAddress = async (
      doc: any,
      addressKey: string,
      countries: any
    ) => {
      const countryModel = mongoose.model('Country');
      const countryCode = doc[addressKey]?.countryCode ?? getFallbackLanguage();
      let country;
      if (!countries?.[countryCode]) {
        country = await countryModel.findOne({
          code: countryCode?.toUpperCase(),
        });
        countries[countryCode] = country;
      } else {
        country = countries?.[countryCode];
      }
      doc[addressKey].country = new Country(country)?.toJSON();
    };
    const hookOrder = async (doc, countries) => {
      await transformOrderWithAddress(doc, 'shippingAddress', countries);
      await transformOrderWithAddress(doc, 'invoicingAddress', countries);
      if (doc.shippingLabelStoredFile) {
        doc.shippingLabelStoredFile.url = getLabelSignedUrl(doc);
      }
    };
    OrderSchema.post('find', async (docs: any) => {
      try {
        const countries = {};
        if (Array.isArray(docs)) {
          for (let index = 0; index < docs.length; index++) {
            const doc = docs[index];
            hookOrder(doc, countries);
          }
        } else {
          hookOrder(docs, countries);
        }
      } catch (e) {
        // DO NOTHING
      }
      return docs;
    });
    OrderSchema.post('findOne', async (doc: any) => {
      const countries = {};
      hookOrder(doc, countries);
      return doc;
    });
    OrderSchema.post('findOneAndUpdate', async (doc: any) => {
      const countries = {};
      hookOrder(doc, countries);
      return doc;
    });
    OrderSchema.post('aggregate', async (docs: any) => {
      try {
        const countries = {};
        if (Array.isArray(docs)) {
          for (let index = 0; index < docs.length; index++) {
            const doc = docs[index];
            hookOrder(doc, countries);
          }
        } else {
          hookOrder(docs, countries);
        }
      } catch (e) {
        // DO NOTHING
      }
      return docs;
    });
    super(OrderSchema, 'Order', 'orders');
  }
  generateLabel(orderId: string): Promise<Order> {
    throw new Error('Method not implemented.');
  }
  getOrderPublicCheckout(orderId: string, checkEmail?: string): Promise<Order> {
    throw new Error('Method not implemented.');
  }
  getOrderStripeCheckoutSession(
    orderId: string,
    successUrl: string,
    cancelUrl: string
  ): Promise<string> {
    throw new Error('Method not implemented.');
  }
  changeShippingMethod(
    orderId: string,
    shippingMethod: string
  ): Promise<Order> {
    throw new Error('Method not implemented.');
  }
  changeShippingAddress(
    orderId: string,
    shippingAddress: IAddressAttributes
  ): Promise<Order> {
    throw new Error('Method not implemented.');
  }

  findLastOrders(
    userType: UserLastOrdersType,
    lastNb?: number,
    userId?: string
  ): Promise<Order[]> {
    let aggregate: any[] = [
      {
        $sort: {
          orderNumber: -1,
        },
      },
    ];
    if (lastNb !== -1) {
      aggregate.push({
        $limit: lastNb ?? DEFAULT_LAST_ORDERS_NB,
      });
    }
    if (userType === 'admin') {
      return super.find({
        aggregate,
        populate: orderGlobalPopulate,
      });
    }
    if (!userId) {
      return new Promise((res) => res([]));
    }
    aggregate = [
      {
        $match: {
          [`custom.${
            userType === 'customer' ? 'customerId' : 'prescriberId'
          }`]: userId,
          deleted: { $ne: true },
        },
      },
      ...aggregate,
    ];
    return super.find({
      aggregate,
      populate: orderGlobalPopulate,
    });
  }

  createOrder(
    prescriber: CustomerInput,
    customer?: CustomerInput
  ): Promise<Order> {
    throw new Error('Method not implemented.');
  }
  changeOrderCustomer(
    orderId: string,
    customer: CustomerInput
  ): Promise<Order> {
    throw new Error('Method not implemented.');
  }
  onOrderCreate(callback: (order: Order) => any): void {
    throw new Error('Method not implemented.');
  }

  async open(orderId: string): Promise<Order> {
    const eventEmitter = EventEmitter.getInstance();
    eventEmitter.emit('OPEN_ORDER', { orderId });
    return await this.model.findOneAndUpdate(
      { _id: orderId, orderStatus: 'DRAFT' },
      { orderStatus: 'OPEN', paymentStatus: 'BALANCE_DUE' },
      { new: true }
    );
  }

  async save(order: Order, userId?: string): Promise<Order> {
    return super.save(order, userId);
  }

  async findOneById(orderId: string): Promise<Order> {
    return super.findOne({
      find: { _id: orderId },
      populate: orderGlobalPopulate,
    });
  }

  async findAll(find?: any): Promise<Order[]> {
    return await super.findAll({
      find,
      populate: orderGlobalPopulate,
    });
  }

  async findByCustomerId(customerId: string): Promise<Order[]> {
    return await super.find({ find: { customer: customerId } });
  }

  async editMany(
    orders: IOrderAttributes[],
    userId?: string
  ): Promise<Order[]> {
    return super.editMany(orders, userId);
  }

  private toEntity(orderMongo: any): Order {
    return new Order({
      ...(orderMongo?._doc ?? orderMongo),
      lineItems: (orderMongo?._doc ?? orderMongo)?.lineItems?.map((item) => {
        const docItem = item?._doc ?? item;
        const variation = docItem.product?.variations?.find(
          ({ _id }) => `${_id}` === `${docItem.productVariation}`
        );
        const docVariation =
          variation?._doc ?? variation ?? docItem.productVariation;
        const docProduct = docItem?.product?._doc ?? docItem?.product;
        return {
          ...docItem,
          product: {
            ...(typeof docProduct === 'string'
              ? { _id: docProduct }
              : docProduct),
            // taxRates: [
            //   {
            //     name: 'TVA',
            //     country: 'France',
            //     percent: 20,
            //     includes: true,
            //   },
            // ],
          },
          productVariation: {
            weight: 1,
            ...(typeof docVariation === 'string'
              ? { _id: docVariation }
              : docVariation),
            product: {
              ...(typeof docProduct === 'string'
                ? { _id: docProduct }
                : docProduct),
              // taxRates: [
              //   {
              //     name: 'TVA',
              //     country: 'France',
              //     percent: 20,
              //     includes: true,
              //   },
              // ],
            },
          },
        };
      }),
    });
  }

  private async toEntityMongo(
    order: Order,
    createdBy?: string,
    updatedBy?: string
  ): Promise<IOrder> {
    if (!order.getOrderNumber()) {
      const lastOrder = await this.model.findOne({}, null, {
        sort: { orderNumber: -1 },
      });
      if (lastOrder && lastOrder.orderNumber) {
        order.setOrderNumber(
          padLeft(
            Number(lastOrder.orderNumber) + 1,
            Number(process.env.NX_ORDER_NUMBER_MIN_LENGTH ?? 9),
            '0'
          )
        );
      } else {
        order.setOrderNumber(
          padLeft(
            Number(process.env.NX_ORDER_NUMBER_BASE ?? '1'),
            Number(process.env.NX_ORDER_NUMBER_MIN_LENGTH ?? 9),
            '0'
          )
        );
      }
    }
    const entityMongo = new this.model({
      _id: order.getId(),
      openedAt: order.getOpenedAt(),
      paidAt: order.getPaidAt(),
      cancelledAt: order.getCancelledAt(),
      startPreparationAt: order.getStartPreparationAt(),
      endPreparationAt: order.getEndPreparationAt(),
      startShipmentAt: order.getStartShipmentAt(),
      endShipmentAt: order.getEndShipmentAt(),
      orderStatus: order.getOrderStatus(),
      paymentStatus: order.getPaymentStatus(),
      packingStatus: order.getPackingStatus(),
      shipmentStatus: order.getShipmentStatus(),
      orderNumber: order.getOrderNumber(),
      lineItems: order.getLineItems()?.map((item) => item.toInput()),
      invoicingAddress: order.getInvoicingAddress()?.toJSON(),
      shippingAddress: order.getShippingAddress()?.toJSON(),
      shippingPrice: order.getShippingPrice()?.toJSON(),
      shippingMethod: order.getShippingMethod()?.getId() ?? null,
      shippingLabelStoredFile: order.getShippingLabelStoredFile(),
      shippingTrackingNumber: order.getShippingTrackingNumber(),
      netTotalPrice: order.getNetTotalPrice()?.toJSON(),
      grossTotalPrice: order.getGrossTotalPrice()?.toJSON(),
      createdBy: createdBy ? createdBy : undefined,
      updatedBy: updatedBy ? updatedBy : undefined,
      customer: order.getCustomer()?.getId(),
      prescriber: order.getPrescriber()?.getId(),
      custom: order.getCustom(),
      payment: order.getPayment()?.getId(),
      packingWeight: order.getPackingWeight(),
      preparedBy: order.getPreparedBy()?.getId(),
      sentBy: order.getSentBy()?.getId(),
      freezeOrder: order.getFreezeOrder(),
      invoice: order.getInvoice()?.getId(),
      deleted: order.isDeleted(),
    });
    // if (!order.getLineItems()?.length) {
    //   entityMongo.lineItems = undefined;
    // }
    // console.log(entityMongo);
    return entityMongo;
  }
}
