export default {
  actions: {
    delete: {
      title: 'Delete a week template',
      warning: 'Do you want to delete this week template?',
    },
    deleteSlot: {
      title: 'Delete a slot',
      warning: 'Do you want to delete this slot?',
    },
    preview: {
      title: 'Preview your week templates',
    },
  },
  caption: {
    colors:
      'Colors allow you to better identify different slots, i.e. one kind of slot = one color.',
    onlineDisplay: 'Available online. Respectively online and offline.',
    slotMode:
      'Slots filling mode with appointment times. Respectively from beginning, from end and from both directions.',
    title: 'Caption',
  },
  disable: 'Disable',
  eachTwoWeeks: 'One week every two weeks',
  eachWeek: 'Each week',
  enable: 'Enable',
  endDate: 'End date',
  endHour: "Slot's end hour",
  form: {
    title: {
      add: 'Add new template',
      addSlot: 'Add new slot',
      edit: 'Edit template',
      editSlot: 'Edit slot',
    },
  },
  from: 'From',
  info:
    'Week templates allow to make slots available for online booking. For each template, you can create slots with different worplaces and chief complaints.',
  name: "Template's name",
  online: {
    available: 'Available online',
    unavailable: 'Not available online',
  },
  pause: 'Pause between two appointments',
  recurrence: 'Recurrence',
  slot: {
    days: 'Days',
    endHour: 'End hour',
    noPreview: 'Please add a slot to preview your week',
    noWorkplaceSelected:
      'Please select a workplace to display associated chief complaints.',
    pause: 'Pause',
    preview: 'My week preview',
    startHour: 'Start hour',
    title: 'Slot',
    workplace: 'Workplace',
  },
  slotChiefComplaints: 'Chief complaints',
  slotConsultationPlace: 'Workplace',
  slotDays: 'Active days of the slot',
  slotMode: 'Filling Mode',
  slotModes: {
    down: 'From end of the slot',
    up: 'From beginning of the slot',
    'up&down': 'From both',
  },
  slotModesInfo: {
    down:
      'Generates appointments from the end of the slot. e.g. for a 50 min long chief complaint and a slot from 10h to 12h, appointments will be proposed at 10h20 and 11h10.',
    up:
      'Generates appointments from the beginning of the slot. e.g. for a 50 min long chief complaint and a slot from 10h to 12h, appointments will be proposed at 10h and 10h50.',
    'up&down':
      'Generates as much appointments as possible between two slot boundaries. e.g. for a 50 min long chief complaint and a slot from 10h to 12h, appointments will be proposed at 10h, 10h20, 10h50 and 11h10. Those proposals will be updated as soon as a patient books an appointment. If an appointment is booked at 10h20 only that of 11h10 will remain available.',
  },
  snackbars: {
    error: {
      delete: 'Something went wrong, please try again',
      save: 'Something went wrong, please try again',
      slotOverlap: 'Slots cannot be overlaping for the same template',
    },
    success: {
      delete: 'Successfully deleted',
      save: 'Successfully saved',
    },
  },
  startDate: 'Start date',
  startHour: "Slot's start hour",
  title: 'My week templates',
  to: 'to',
  warningTemplate:
    "Only one template can be active during a week. For a given week, the higher template in the list trumps. You can drag'n drop templates to change the order.",
};
