// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DatatablesController, DropdownValue } from '@kiway/datatables';
import { deepen } from '@kiway/shared/utils/string';
import { useTranslation } from '@kiway/shared/utils/translation';
import { Typography } from '@material-ui/core';
import * as React from 'react';
import { CountryZone } from '../../../core/entities/CountryZone';

export interface ListCountryZonesProps {
  availableCountries: DropdownValue[];
  countryZones: CountryZone[];
  handleAddData: () => void;
  handleClearAdd: () => void;
  handleSave?: any;
  loading?: boolean;
}

const prepareToSave = (item) => {
  return {
    ...deepen(item),
    id: item._id,
    _id: undefined,
    countries: item.countries,
  };
};

export function ListCountryZones(
  props: React.PropsWithChildren<ListCountryZonesProps>
) {
  const { t } = useTranslation();
  return (
    <DatatablesController
      bulkEdit={true}
      handleAddData={props.handleAddData}
      handleClearAdd={props.handleClearAdd}
      handleSaveData={
        props.handleSave
          ? (updates) => {
              if (props.handleSave) {
                return props.handleSave(updates.updatedData.map(prepareToSave));
              }
            }
          : undefined
      }
      columns={[
        {
          name: '_id',
          label: 'ID',
          type: 'text',
          options: {
            display: false,
            edit: false,
            filter: false,
          },
        },
        {
          name: 'name',
          label: t(`pharmaco:countryZones.label.name`),
          type: 'text',
        },
        {
          name: 'countries',
          label: t(`pharmaco:countryZones.countries`),
          type: 'multi-select',
          options: {
            availableValues: props.availableCountries,
          },
        },
      ]}
      data={props.countryZones?.map((zone) => zone.toDatatableRow())}
      defaultSortOrder={{ name: 'name.fr', direction: 'asc' }}
      id="listCountryZones"
      loading={props.loading}
      options={{
        download: false,
        filter: false,
        print: false,
      }}
      title={t(`pharmaco:countryZones.linkText`)}
    />
  );
}
