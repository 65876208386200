import fr from './translations/fr';
import en from './translations/en';
import pt from './translations/pt';
import es from './translations/es';
import {
  EditProfileController,
  ListUsersController,
  LoginController,
  LogoutController,
  RegisterChoiceController,
  RegisterController,
} from './ui/components';

export const links = {
  main: 'authentication',
  users: 'users',
  signIn: 'signin',
  signOut: 'oauth/logout',
  editProfile: 'profile',
  registerChoice: 'register',
};

export default {
  routes: [
    {
      id: 'list_users',
      path: `/${links.users}`,
      subtitle: 'authentication:users.title',
      exact: false,
      private: true,
      component: ListUsersController,
      appShell: true,
      role: 'ROLE_ADMIN',
    },
    {
      id: 'signin',
      path: `/${links.signIn}`,
      subtitle: 'authentication:signIn.title',
      exact: false,
      private: false,
      component: LoginController,
    },
    {
      id: 'signout',
      path: `/${links.signOut}`,
      subtitle: 'authentication:signOut.title',
      exact: false,
      private: false,
      appShell: true,
      component: LogoutController,
    },
    {
      id: 'profile',
      path: `/${links.editProfile}`,
      subtitle: 'authentication:editProfile.title',
      exact: false,
      private: true,
      appShell: true,
      role: 'ROLE_USER',
      component: EditProfileController,
    },
    {
      id: 'register_choice',
      path: `/${links.registerChoice}`,
      exact: true,
      private: false,
      appShell: false,
      component: RegisterChoiceController,
    },
    {
      id: 'register',
      path: `/${links.registerChoice}/:type`,
      exact: true,
      private: false,
      appShell: false,
      component: RegisterController,
      parseMatchParams: (matchParams: any) => ({ type: matchParams?.type }),
    },
  ],
  menus: {
    main: [
      // {
      //   id: 'listUsers',
      //   link: `${links.users}`,
      //   text: 'authentication:listUsers.linkText',
      //   display: true,
      //   role: 'admin',
      //   icon: GroupIcon,
      // },
    ],
    user: [
      {
        id: 'editUserProfile',
        link: `${links.editProfile}`,
        text: 'authentication:editProfile.linkText',
        display: true,
        role: 'ROLE_USER',
        order: 1,
      },
      {
        id: 'usersManagement',
        link: `${process.env.NX_FA_SERVER_URI}/admin`,
        external: true,
        openNewTab: true,
        text: 'authentication:usersManagement.linkText',
        display: true,
        role: 'ROLE_ADMIN',
        order: 9999,
      },
      {
        id: 'logoutUser',
        link: `https://${process.env.NX_API_URL}/${links.signOut}`, //`${links.signOut}`,
        external: true,
        text: 'authentication:signOut.linkText',
        display: true,
        role: 'ROLE_USER',
        order: 10000,
      },
    ],
  },
  translations: {
    fr,
    en,
    pt,
    es,
  },
};
