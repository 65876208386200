import { ProductVariationType } from '../entities/ProductVariationType';
import { ProductVariationTypeProvider } from './ProductVariationTypeProvider';

export class GetAllProductVariationTypes {
  private productVariationTypeProvider: ProductVariationTypeProvider;

  public constructor(
    productVariationTypeProvider: ProductVariationTypeProvider
  ) {
    this.productVariationTypeProvider = productVariationTypeProvider;
  }

  public execute(): Promise<ProductVariationType[]> {
    return this.productVariationTypeProvider.findAll();
  }
}
