import PhoneNumber from 'awesome-phonenumber';

export function formatPhoneNumber(phone: string) {
  let pn = new PhoneNumber(phone);
  if (pn.isValid()) {
    return pn.getNumber('international');
  } else {
    pn = new PhoneNumber('+' + phone);
    if (pn.isValid()) {
      return pn.getNumber('international');
    }
  }
  return phone;
}
