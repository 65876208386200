export const firstUpperCase = (value: string) => {
  return value ? value[0].toUpperCase() + value.slice(1) : '';
};

interface NumberFormatOptions {
  localeMatcher?: string;
  style?: string;
  currency?: string;
  currencyDisplay?: string;
  currencySign?: string;
  useGrouping?: boolean;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
}

export const currencyFormat = (
  value: number,
  locale = 'fr-FR',
  options?: NumberFormatOptions
) => {
  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    ...options,
  });
  // {
  //   style: 'currency',
  //   currency: 'EUR',
  //   // These options are needed to round to whole numbers if that's what you want.
  //   //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //   //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  // });
  return currencyFormatter.format(value);
};

export function executeFunctionByName(functionName, context, args) {
  // const args = Array.prototype.slice.call(arguments, 2);
  const namespaces = functionName.split('.');
  const func = namespaces.pop();
  for (let i = 0; i < namespaces.length; i++) {
    context = context[namespaces[i]];
  }
  // eslint-disable-next-line prefer-spread
  return context[func].apply(context, args);
}

export function deepen(obj) {
  const result = {};

  // For each object path (property key) in the object
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split('.');

    // Create sub-objects along path as needed
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      target = target[part] = target[part] || {};
    }

    // Set value at end of path
    target[parts[0]] = obj[objectPath];
  }

  return result;
}

export const flatten = (ob) => {
  // The object which contains the
  // final result
  const result = {};

  // loop through the object "ob"
  for (const i in ob) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
      const temp = flatten(ob[i]);
      for (const j in temp) {
        // Store temp in result
        result[i + '.' + j] = temp[j];
      }
    }

    // Else store ob[i] in result directly
    else {
      result[i] = ob[i];
    }
  }
  return result;
};

export function truncate(str: string, n: number): string {
  return str.length > n ? str.substr(0, n - 1) + '...' : str;
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const md5 = require('md5');

export function getGravatarURL(email) {
  // Trim leading and trailing whitespace from
  // an email address and force all characters
  // to lower case
  const address = String(email).trim().toLowerCase();

  // Create an MD5 hash of the final string
  const hash = md5(address);

  // Grab the actual image URL
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
}

import ObjectID from 'bson-objectid';
export function generateObjectId(): string {
  return ObjectID().toHexString();
}

export const padLeft = (
  nb: number,
  nbOfDigits: number,
  replaceWith?: string
) => {
  const z: string = replaceWith || '0';
  const n: string = nb + '';
  return n.length >= nbOfDigits
    ? n
    : new Array(nbOfDigits - n.length + 1).join(z) + n;
};

export const getFontAwesome4IconFromPaymentMethod = (
  paymentMethod: string,
  cardBrand: string
) => {
  if (paymentMethod === 'card') {
    if (cardBrand === 'visa') {
      return 'fa-brands fa-cc-visa';
    } else if (cardBrand === 'mastercard') {
      return 'fa-brands fa-cc-mastercard';
    } else if (cardBrand === 'amex') {
      return 'fa-brands fa-cc-amex';
    } else if (cardBrand === 'discover') {
      return 'fa-brands fa-cc-discover';
    } else if (cardBrand === 'jcb') {
      return 'fa-brands fa-cc-jcb';
    } else if (cardBrand === 'diners') {
      return 'fa-brands fa-cc-diners-club';
    } else if (cardBrand === 'unionpay') {
      return 'fa-solid fa-credit-card';
    } else {
      return 'fa-solid fa-credit-card';
    }
  } else if (paymentMethod === 'check') {
    return 'fa-solid fa-money-check';
  } else if (paymentMethod === 'bankTransfer') {
    return 'fa-solid fa-money-bill-transfer';
  }
};

// eslint-disable-next-line no-useless-escape
export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const multipleReplace = (
  source: string,
  mapping: { [name: string]: string },
  delimiter = '%'
) => {
  let replacedSource = source;
  for (const [searchValue, replaceValue] of Object.entries(mapping)) {
    replacedSource = replacedSource
      .split(`${delimiter}${searchValue}${delimiter}`)
      .join(replaceValue);
  }
  return replacedSource;
};

export const randomString = (length: number, chars: string): string => {
  let mask = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
  let result = '';
  for (let i = length; i > 0; --i)
    result += mask[Math.floor(Math.random() * mask.length)];
  return result;
};
