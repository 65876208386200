// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  Carrier,
  CountryZone,
  ShippingMethod,
  useCountryZonesGateway,
  useCarriersGateway,
} from '@kiway/ecommerce-react';
import { useTranslation } from '@kiway/shared/utils/translation';
import * as React from 'react';
import { useShippingMethodsGateway } from '../../../entrypoints/react/useShippingMethodsGateway';
import { ListShippingMethods } from './ListShippingMethods';

export function ListShippingMethodsController() {
  const [loading, setLoading] = React.useState(false);
  const [shippingMethods, setShippingMethods] = React.useState([]);
  const [carriers, setCarriers] = React.useState<Array<Carrier>>([]);
  const { currentLanguage } = useTranslation();
  const {
    editShippingMethods,
    findAll: findAllMethods,
  } = useShippingMethodsGateway();
  const { findAll: findAllCarriers } = useCarriersGateway();
  const { findAll: findAllCountryZones } = useCountryZonesGateway();
  const [countryZonesList, setCountryZonesList] = React.useState<
    Array<CountryZone>
  >([]);

  React.useEffect(() => {
    setLoading(true);
    findAllMethods()
      .then((shippingMethods) => {
        setShippingMethods(shippingMethods);
      })
      .finally(() => setLoading(false));
    return () => {
      setShippingMethods([]);
    };
  }, []);

  React.useEffect(() => {
    findAllCarriers().then((carriers) => {
      setCarriers(carriers);
    });
    return () => {
      setCarriers([]);
    };
  }, []);

  React.useEffect(() => {
    findAllCountryZones().then((countryZones) => {
      setCountryZonesList(countryZones);
    });
    return () => {
      setCountryZonesList([]);
    };
  }, []);

  const handleAddData = () => {
    const data = new ShippingMethod({});
    setShippingMethods((prev) => [data, ...prev]);
  };

  const handleClearAdd = () => {
    setShippingMethods((prev) =>
      prev.filter((shippingMethod) => shippingMethod.getId())
    );
  };

  return (
    <ListShippingMethods
      loading={loading}
      availableCountryZones={countryZonesList.map((countryZone) =>
        countryZone.toDropdownValue()
      )}
      availableCarriers={carriers.map((carrier) =>
        carrier.toDropdownValue(currentLanguage?.code)
      )}
      shippingMethods={shippingMethods}
      handleAddData={handleAddData}
      handleClearAdd={handleClearAdd}
      handleSave={async (updates) => {
        if (editShippingMethods) {
          const editedShippingMethods = await editShippingMethods(updates);
          setShippingMethods((prev) => [
            ...prev.filter(
              (shippingMethod) =>
                shippingMethod.getId() &&
                !editedShippingMethods
                  .map((shippingMethod) => shippingMethod.getId())
                  .includes(shippingMethod.getId())
            ),
            ...editedShippingMethods,
          ]);
        }
      }}
      rawAvailableCountryZones={countryZonesList}
    />
  );
}
