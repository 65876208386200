import { FormulaGraphQLProvider } from '../../dataproviders/graphql-client/FormulaGraphQLProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GetAllFormulas } from '../../core/use_cases/GetAllFormulas';
import {
  Formula,
  FormulaArrays,
  FormulaMetadataSearchReturn,
} from '../../core/entities/Formula';
import { SearchFormulaMetadata } from '../../core/use_cases/SearchFormulaMetadata';
import { GetFormula } from '../../core/use_cases/GetFormula';
import { EditManyFormulas } from '../../core/use_cases/EditManyFormulas';
import { IFormulaAttributes } from '../../core/entities/Formula';
import { AddItemToFormulaArrays } from '../../core/use_cases/AddItemToFormulaArrays';
import { RemoveItemFromFormulaArrays } from '../../core/use_cases/RemoveItemFromFormulaArrays';
import { PaginatedResults } from '@kiway/shared/react-types';

class FormulasGateway {
  protected static instance: FormulasGateway;
  protected addItemToFormulaArrays: AddItemToFormulaArrays;
  protected editManyFormulas: EditManyFormulas;
  protected getAllFormulas: GetAllFormulas;
  protected getFormula: GetFormula;
  protected removeItemFromFormulaArrays: RemoveItemFromFormulaArrays;
  protected useCaseSearchFormulaMetadata: SearchFormulaMetadata;

  public static getInstance(client: ApolloClient<any>): FormulasGateway {
    if (!this.instance) {
      if (!client) {
        return null;
      }
      new FormulasGateway(client);
    }
    return this.instance;
  }

  private constructor(client: ApolloClient<any>) {
    const formulaProvider = new FormulaGraphQLProvider(client);
    this.addItemToFormulaArrays = new AddItemToFormulaArrays(formulaProvider);
    this.getAllFormulas = new GetAllFormulas(formulaProvider);
    this.getFormula = new GetFormula(formulaProvider);
    this.editManyFormulas = new EditManyFormulas(formulaProvider);
    this.removeItemFromFormulaArrays = new RemoveItemFromFormulaArrays(
      formulaProvider
    );
    this.useCaseSearchFormulaMetadata = new SearchFormulaMetadata(
      formulaProvider
    );
    this.addMetadataToFormula = this.addMetadataToFormula.bind(this);
    this.editFormulas = this.editFormulas.bind(this);
    this.findAll = this.findAll.bind(this);
    this.findOne = this.findOne.bind(this);
    this.removeMetadataFromFormula = this.removeMetadataFromFormula.bind(this);
    this.searchFormulaMetadata = this.searchFormulaMetadata.bind(this);
    this.listCatalogFormulas = this.listCatalogFormulas.bind(this);
    FormulasGateway.instance = this;
  }

  async addMetadataToFormula(
    formulaId: string,
    itemId: string,
    itemType: FormulaArrays
  ): Promise<Formula> {
    return await this.addItemToFormulaArrays.execute(
      formulaId,
      itemId,
      itemType
    );
  }

  async editFormulas(formulas: IFormulaAttributes[]): Promise<Formula[]> {
    return await this.editManyFormulas.execute(formulas, null);
  }

  async findAll(): Promise<PaginatedResults<Formula>> {
    return await this.getAllFormulas.execute({}, { page: 1, perPage: -1 });
  }

  async listCatalogFormulas(
    page?: number,
    nbResultsPerPage?: number
  ): Promise<PaginatedResults<Formula>> {
    return await this.getAllFormulas.execute(
      { published: true },
      { page, perPage: nbResultsPerPage }
    );
  }

  async findOne(formulaId: string): Promise<Formula> {
    return await this.getFormula.execute(formulaId);
  }

  async removeMetadataFromFormula(
    formulaId: string,
    itemId: string,
    itemType: FormulaArrays
  ): Promise<Formula> {
    return await this.removeItemFromFormulaArrays.execute(
      formulaId,
      itemId,
      itemType
    );
  }

  async searchFormulaMetadata(
    search: string
  ): Promise<FormulaMetadataSearchReturn> {
    return await this.useCaseSearchFormulaMetadata.execute(search);
  }
}

export function useFormulasGateway() {
  const client = useApolloClient();
  const formulasGateway = FormulasGateway.getInstance(client);
  return {
    addMetadataToFormula: formulasGateway.addMetadataToFormula,
    editFormulas: formulasGateway.editFormulas,
    findAll: formulasGateway.findAll,
    findOne: formulasGateway.findOne,
    removeMetadataFromFormula: formulasGateway.removeMetadataFromFormula,
    searchFormulaMetadata: formulasGateway.searchFormulaMetadata,
    listCatalogFormulas: formulasGateway.listCatalogFormulas,
  };
}
