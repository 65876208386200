import { Plant, IPlantAttributes } from '../entities/Plant';
import { PlantProvider } from './PlantProvider';

export class EditManyPlants {
  private plantProvider: PlantProvider;

  public constructor(plantProvider: PlantProvider) {
    this.plantProvider = plantProvider;
  }

  public execute(
    plants: Array<IPlantAttributes>,
    userId?: string
  ): Promise<Array<Plant>> {
    return this.plantProvider.editMany(plants, userId);
  }
}
