import Cookies from 'js-cookie';

const AUTH_COOKIES = [
  'user',
  'accessToken',
  'refreshToken',
  'rememberMeToken',
  'onBoarding',
];

export const getCookie = (name: string) => {
  const cookie = Cookies.get(name);
  return cookie ? cookie : false;
};

export const getCookieAsObject = (name: string) => {
  const cookie = getCookie(name);
  if (cookie) {
    return JSON.parse(cookie);
  }
  return false;
};

export const setCookie = (name: string, value: string, expiresAt: any) => {
  Cookies.set(name, value || '', {
    expires: new Date(expiresAt),
    path: '/',
    secure: true,
    sameSite: 'none',
  });
};

export const setUserCookie = (data: any, expiresAt: any) => {
  setCookie('user', JSON.stringify(data), expiresAt);
};

export const deleteCookie = (name: string) => {
  Cookies.remove(name, {
    path: '/',
    secure: true,
    sameSite: 'none',
  });
};

export const deleteAllAuthCookies = () => {
  for (const i in AUTH_COOKIES) {
    deleteCookie(AUTH_COOKIES[i]);
  }
};
